import React, { Component } from 'react';
import SelfConceptChart from './SelfConceptChart/SelfConceptChart';
import { normalizarPorMaximo } from 'Services/utilsFn';
/**
 * Gera o gráfico de autoconceito no trabalho
 *
 * @export
 * @class AutoconceitoNoTrabalhoGraph
 * @extends {Component}
 */
export default class AutoconceitoNoTrabalhoGraph extends Component {
  getGraphData() {
    const dataArray = [
      {
        name: 'Autoavaliação',
        data: [
          Math.round(
            100 * normalizarPorMaximo(5, this.props.resposta['autonomia']),
          ) / 100,
          Math.round(
            100 * normalizarPorMaximo(5, this.props.resposta['realizacao']),
          ) / 100,
          Math.round(
            100 * normalizarPorMaximo(5, this.props.resposta['competencia']),
          ) / 100,
          Math.round(
            100 * normalizarPorMaximo(5, this.props.resposta['saude']),
          ) / 100,
          Math.round(
            100 * normalizarPorMaximo(5, this.props.resposta['seguranca']),
          ) / 100,
          Math.round(
            100 * normalizarPorMaximo(5, this.props.resposta['ajustamentos']),
          ) / 100,
        ],
      },
    ];

    if (this.props.respondentes && this.props.respondentes.length) {
      this.props.respondentes
      .filter(respondente => respondente.resposta)
      .map((respondente, i) => {

        dataArray.unshift({
          name: this.props.isConsultor
          ? `${respondente.nome} ${respondente.relacao}`
          : `${respondente.relacao} ${i + 1}`,
          data: [
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['autonomia']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['realizacao']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['competencia']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['saude']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['seguranca']),
            ) / 100,
            Math.round(
              100 *
                normalizarPorMaximo(5, respondente.resposta['ajustamentos']),
            ) / 100,
          ],
        })
      })
    }
    return dataArray
  }

  render() {
    const graphData = this.getGraphData();
    return (
      <div>
        <SelfConceptChart id={`self-concept-${this.props.idAssincrono}`}
          rodadasData={this.getGraphData()}
          title='Autoconceito no Trabalho' />
      </div>
    );
  }
}
