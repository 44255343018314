export default function formatAnswer(answer) {
  if (answer.resposta) {
    answer = { ...answer, ...answer.resposta };
  }
  return {
    ...answer,
    E: answer['sumE'],
    S: answer['sumES'] + answer['sumS'],
    T: answer['sumST'] + answer['sumT'],
    J: answer['sumTJ'] + answer['sumJ'],
    I: answer['sumI'],
    N: answer['sumIN'] + answer['sumN'],
    F: answer['sumNF'] + answer['sumF'],
    P: answer['sumFP'] + answer['sumP'],
  };
}