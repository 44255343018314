import React from 'react';
import Chart from "chart.js";

import * as EscutaAtivaGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import EscutaAtivaGraphUtils from './EscutaAtivaGraphUtils';

export default class EscutaAtivaGraph extends React.Component {

  state = {
    userResult: null
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, EscutaAtivaGraphUtils.graphConfig());
  }

  drawParticipant(state) {
    const [datasets, result] = EscutaAtivaGraphUtils.drawParticipant({
      result: state.userResult,
    });

    const labels = [['Leitura de', 'Contexto', `Alto: ${result.media2Normalized}`],
      ['Comunicação', 'Centrada no', `Interlocutor: ${result.media1SubNormalized}`],
      ['Leitura de', 'Contexto', `Baixa: ${result.media2SubNormalized}`],
      ['Comunicação', 'Centrada em si:', `${result.media1Normalized}`]];

    this.chart.data.labels = [
      ...this.chart.data.labels,
      ...labels
    ];

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  componentDidMount() {
    let state = {};

    EscutaAtivaGraphService.loadUserResult({
      idProjeto: this.props.projectId,
      idParticipante: this.props.participantId,
      idAssincrono: this.props.assincronoId,
      idInstrumento: this.props.instrumentId
    })
    .then(res => {
      state.userResult = res;
    })
    .catch(err => {
      this.handleError(err);
      notifyError('Não foi possível montar o gráfico individual. Por favor, recarregue a página e tente novamente');
    }).finally(_ => {
      this.setState({ state });
      var canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };
      this.draw(state);
      this.drawParticipant(state);
    });
  }

  render() {
    return <canvas
      id={this.props.id}
      />;
  }
}