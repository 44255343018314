import { toast } from "react-toastify";

export function notifyError(mensagem, autoClose = true) {
  toast.error(mensagem, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose
  });
}

export function notifyInfo(mensagem) {
  toast.info(mensagem, {
    position: toast.POSITION.TOP_RIGHT
  });
}

export function notifySuccess(mensagem) {
  toast.success(mensagem, {
    position: toast.POSITION.TOP_RIGHT
  });
}

export const Notificar = {

  info: function (msg) { notifyInfo(msg) },
  success: function (msg) { notifySuccess(msg) },
  error: function (msg) { notifyError(msg) }
}
