import React, { Component } from "react";
import {
  Button,
  Card,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup,
  Label
} from "reactstrap";
import api from "../../../Services/api";
import { toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";

class Register extends Component {
  state = {
    name: "",
    email: "",
    cpf: "",
    telefone: "",
    dtaNasc: "",
    password: "",
    confirmpassword: "",
    empresas: []
  };

  componentDidMount = async () => {
    const response = await api.get("api/enterprise/consultoria");
    console.log(response);

    for (var i = 0; i < response.data.length; i++) {
      const val = {
        label: response.data[i].nomeFantasia,
        value: response.data[i]._id
      };
      this.state.empresas.push(val);
    }

    console.log(this.state.empresas);
    if (localStorage.getItem("cpf")) {
      this.setState({ cpf: localStorage.getItem("cpf") });
    }
  };
  MyComponent = () => (
    <Select isClearable={true} options={this.state.empresas} />
  );
  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
    //  console.log(e.target.value)
  };
  notify = () =>
    toast.error("Senhas não Conferem", {
      position: toast.POSITION.TOP_RIGHT
    });

  handleSend = async e => {
    e.preventDefault();
    const email = this.state.email;
    const name = this.state.name;
    const telefone = this.state.telefone;
    const dtaNasc = this.state.dtaNasc;
    const password = this.state.password;
    const role = "consultor";
    console.log(name);

    if (this.state.password === this.state.confirmpassword) {
      const response = await api.put(
        "api/user/" + localStorage.getItem("userId"),
        { email, name, telefone, dtaNasc, password, role }
      );
      sessionStorage.setItem("user", response.data.user._id);
      //console.log(response.data.user.name)
      this.props.history.push("/");
    } else {
      this.notify();
    }
  };

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Form onSubmit={this.handleSend}>
            <Row className="justify-content-center">
              <Col md="12">
                <Card className="mx-12">
                  <Container>
                    <h1>Registro</h1>
                    <p className="text-muted">
                      Complete seus dados preenchendo o formulário
                    </p>
                  </Container>
                  <Row>
                    <Col xs="12" sm="6">
                      <Container>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Nome Completo"
                            autoComplete="name"
                            name="name"
                            onChange={this.handleInput}
                            required
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>@</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Cpf"
                            autoComplete="cpf"
                            name="cpf"
                            value={this.state.cpf}
                            onChange={this.handleInput}
                            disabled
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>@</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            autoComplete="email"
                            type="email"
                            name="email"
                            onChange={this.handleInput}
                            required
                          />
                        </InputGroup>

                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Senha"
                            autoComplete="new-password"
                            name="password"
                            onChange={this.handleInput}
                            required
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Repetir Senha"
                            autoComplete="new-password"
                            name="confirmpassword"
                            onChange={this.handleInput}
                            required
                          />
                        </InputGroup>
                      </Container>
                    </Col>
                    <Col xs="12" sm="6">
                      <Container>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="icon-phone" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Telefone"
                            autoComplete="telefone"
                            name="telefone"
                            onChange={this.handleInput}
                            required
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              {" "}
                              <i className="icon-calendar" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Data de Aniversário"
                            autoComplete="dtaNasc"
                            name="dtaNasc"
                            onChange={this.handleInput}
                            required
                          />
                        </InputGroup>
                        <FormGroup>
                          <Label htmlFor="ccyear">
                            Deseja se vincular a uma empresa?
                          </Label>
                          {this.MyComponent()}
                        </FormGroup>
                        <Button color="success" block>
                          Entrar
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                  <CardFooter className="p-4">
                    <Row>
                      <Col xs="12" sm="6">
                        <Button className="btn-facebook" block>
                          <span>facebook</span>
                        </Button>
                      </Col>
                      <Col xs="12" sm="6">
                        <Button className="btn-twitter" block>
                          <span>twitter</span>
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
        
      </div>
    );
  }
}

export default Register;
