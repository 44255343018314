import { Colors } from 'utils';
import { PLOT_COLORS2 } from 'Services/Constantes';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DrawTicksOverPlugin from './DrawTicksOverPlugin';

const BACKGROUND_COLORS = [
  Colors.setOpacity(PLOT_COLORS2[0], 0.8),
  Colors.setOpacity(PLOT_COLORS2[1], 0.8),
  Colors.setOpacity(PLOT_COLORS2[2], 0.8),
  Colors.setOpacity(PLOT_COLORS2[3], 0.8),
  Colors.setOpacity(PLOT_COLORS2[4], 0.8),
  Colors.setOpacity(PLOT_COLORS2[5], 0.8),
  Colors.setOpacity(PLOT_COLORS2[6], 0.8),
  Colors.setOpacity(PLOT_COLORS2[7], 0.8),
];

const graphConfig = () => ({
  type: 'horizontalBar',
  data: {},
  plugins: [DrawTicksOverPlugin, ChartDataLabels],
  options: {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        align: 'left',
        anchor: 'start',
        offset: 380,
        formatter: (value, context) => {
          if (value !== '')
            return context.dataset.label;
        }
      }
    },
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false
        },
        ticks: {
          fontColor: 'black',
          max: 100,
          min: -100,
          callback: function (value) {
            return Math.abs(value);
          }
        }
      }],
      yAxes: [
        {
          labels: ['E', 'S', 'T', 'J'],
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false
          },
          ticks: {
            display: true,
            fontColor: 'black',
          }
        },
        {
          labels: ['I', 'N', 'F', 'P'],
          position: 'right',
          type: 'category',
          offset: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false
          },
          ticks: {
            fontColor: 'black'
          }
        }
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Tipos de Personalidade - Stakeholders',
      fontColor: 'black'
    },
    tooltips: {
      enabled: false
    }
  }
});

function drawParticipant({ result }) {
  let datasets = [];

  let greyColor = 'rgba(0, 0, 0, 0.1)';

  Object.keys(result).map((key, i) => {

    let colors = {
      E: result[key].bestResults.indexOf('E') === -1 ? greyColor : BACKGROUND_COLORS[i],
      S: result[key].bestResults.indexOf('S') === -1 ? greyColor : BACKGROUND_COLORS[i],
      T: result[key].bestResults.indexOf('T') === -1 ? greyColor : BACKGROUND_COLORS[i],
      J: result[key].bestResults.indexOf('J') === -1 ? greyColor : BACKGROUND_COLORS[i],
      I: result[key].bestResults.indexOf('I') === -1 ? greyColor : BACKGROUND_COLORS[i],
      N: result[key].bestResults.indexOf('N') === -1 ? greyColor : BACKGROUND_COLORS[i],
      F: result[key].bestResults.indexOf('F') === -1 ? greyColor : BACKGROUND_COLORS[i],
      P: result[key].bestResults.indexOf('P') === -1 ? greyColor : BACKGROUND_COLORS[i],
    }

    datasets.push({
      label: key,
      type: "horizontalBar",
      stack: key,
      backgroundColor: [colors.I, colors.N, colors.F, colors.P],
      categoryPercentage: 0.8,
      barPercentage: 0.8,
      data: [result[key].I, result[key].N, result[key].F, result[key].P]
    }, {
      label: '',
      type: "horizontalBar",
      stack: key,
      backgroundColor: [colors.E, colors.S, colors.T, colors.J],
      categoryPercentage: 0.8,
      barPercentage: 0.8,
      data: [result[key].E * -1, result[key].S * -1, result[key].T * -1, result[key].J * -1]
    });
  });

  return datasets;
}

export default {

  graphConfig,
  drawParticipant
}