import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel, ButtonSave } from '../../Pages/Assets/Assets';
import Input from '../RegisterModal/components/Input';
import { notifyError, notifySuccess } from "Services/Notificacoes";

import { validarCPF } from "Services/Validador";

import apiCalls from 'config/apiCalls';

function ForgotEmailModal(props) {
  const [user, setUser] = useState(null);
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [novoEmail, setNovoEmail] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [telefone, setTelefone] = useState('');
  const [motivo, setMotivo] = useState('');

  const [cpfSearching, setCpfSearching] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [disableNome, setDisableNome] = useState(true);
  const [disableEmail, setDisableEmail] = useState(true);
  const [disableNovoEmail, setDisableNovoEmail] = useState(true);
  const [disableNascimento, setDisableNascimento] = useState(true);
  const [disableTelefone, setDisableTelefone] = useState(true);
  const [disableMotivo, setDisableMotivo] = useState(true);

  const [validCpf, setValidCpf] = useState(true);
  const [validNome, setValidNome] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validNovoEmail, setValidNovoEmail] = useState(true);
  const [validNascimento, setValidNascimento] = useState(true);
  const [validTelefone, setValidTelefone] = useState(true);
  const [validMotivo, setValidMotivo] = useState(true);

  const [emailExistente, setEmailExistente] = useState(false);
  const [oldEmailExistente, setOldEmailExistente] = useState({ exists: false, verified: false });

  const [formLoading, setFormLoading] = useState(false);

  const dateMask = (value) => {

    let v=value.replace(/\D/g,"");

    v=v.replace(/(\d{2})(\d)/,"$1/$2")

    v=v.replace(/(\d{2})(\d)/,"$1/$2")

    return v;

  }

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const mask = (o, f, erased) => {
    setTimeout(function() {
      var v = f(o.value, erased);
      if (v != o.value) {
        o.value = v;
      }
    }, 1);
  }

  const mphone = (v, erased) => {

    if (erased) return v;
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    }  else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  const onCpfChange = async(e) => {
    setCpf(cpfMask(e.target.value));

    const cpfReplaced = e.target.value && e.target.value.replaceAll('.', '').replace('-','');
    const validCpf = validarCPF(cpfReplaced);
    if (cpfReplaced && cpfReplaced.length === 11 && !validCpf) notifyError('Cpf inválido.');

    if (cpfReplaced && cpfReplaced.length === 11 && validCpf) {
      setCpfSearching(true);
      const res = await apiCalls.users.getUserByCpf(cpfReplaced);
      const { user } = res.data;
      setUser(user);
      if (user && !validCpf) setValidCpf(true);
      if (!user) {
        notifyError('CPF não encontrado no sistema.');
        setValidCpf(false);
        setCpfSearching(false);
        setDisableNome(true);
        setDisableTelefone(true);
        setDisableConfirm(true);
        setDisableEmail(true);
        setDisableNovoEmail(true);
        setDisableNascimento(true);
        setDisableMotivo(true);
      } else {
        setCpfSearching(false);
        setDisableNome(false);
        setDisableTelefone(false);
        setDisableConfirm(false);
        setDisableEmail(false);
        setDisableNovoEmail(false);
        setDisableNascimento(false);
        setDisableMotivo(false);
      }
    } else {
      setUser(null);
      setDisableNome(true);
      setDisableTelefone(true);
      setDisableConfirm(true);
      setDisableEmail(true);
      setDisableNovoEmail(true);
      setDisableNascimento(true);
      setDisableMotivo(true);
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validate = () => {

    setValidCpf(true);
    setValidEmail(true);
    setValidNome(true);
    setValidTelefone(true);
    setValidNovoEmail(true);
    setValidNascimento(true);
    setValidMotivo(true);

    let isValid = true;

    if (!user) {
      setValidCpf(false);
      isValid = false;
    }

    if (!cpf || cpf.length < 14) {
      setValidCpf(false);
      isValid = false;
    }


    const removedCpfPontuations = cpf && cpf.replaceAll('.', '').replace('-','');
    if (cpf && !validarCPF(removedCpfPontuations)) {
      setValidCpf(false);
      isValid = false;
    }

    if (!nome) {
      setValidNome(false);
      isValid = false;
    }

    if (!email || !validateEmail(email)) {
      setValidEmail(false);
      isValid = false;
    }

    if (!novoEmail || !validateEmail(novoEmail)) {
      setValidNovoEmail(false);
      isValid = false;
    }

    if (telefone && telefone.length < 14) {
      setValidTelefone(false);
      isValid = false;
    }

    if (!motivo) {
      setValidMotivo(false);
      isValid = false;
    }

    if (!nascimento || nascimento.length < 10) {
      setValidNascimento(false);
      isValid = false;
    }


    if (!isValid) notifyError('Há campos que precisam ser preenchidos corretamente.');

    return isValid;
  }

  async function handleOldEmail() {
    try {
      if (validateEmail(email)) {
        const res = await apiCalls.users.verifyUserEmail(email);

        const { haveUser } = res.data;

        setOldEmailExistente({ exists: !!haveUser, verified: true });
      } else {
        setOldEmailExistente({ exists: false, verified: false });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function handleNovoEmail() {
    try {
      if (validateEmail(novoEmail)) {
        const res = await apiCalls.users.verifyUserEmail(novoEmail);

        const { haveUser } = res.data;

        setEmailExistente(!!haveUser);
        if (haveUser) {
          setValidNovoEmail(false);
          setDisableConfirm(true);
        } else {
          setValidNovoEmail(true);
          setDisableConfirm(false);
        }
      }
    } catch (err) {
      console.error(err);
      setValidNovoEmail(false);
    }
  }

  async function handleConfirm() {
    try {
      if (!validate() || formLoading) return;

      setFormLoading(true);

      await apiCalls.users.recoverEmail({ cpf, nome, emailAtual: email, emailNovo: novoEmail, nascimento, telefone, motivo });

      notifySuccess('Solicitação enviada com sucesso.');

      setFormLoading(false);
      props.toggle();
    } catch (err) {
      setFormLoading(false);
      console.error(err);
      notifyError('Erro ao enviar solicitação.');
    }
  }

  function resetState() {
    setUser(null);
    setCpf('');
    setNome('');
    setEmail('');
    setNovoEmail('');
    setTelefone('');
    setMotivo('');
    setNascimento('');
    setEmailExistente(false);
    setOldEmailExistente({ exists: false, verified: false });
    setCpfSearching(false);
    setDisableConfirm(true);
    setDisableNome(true);
    setDisableEmail(true);
    setValidNovoEmail(true);
    setDisableNovoEmail(true);
    setDisableNascimento(true);
    setDisableMotivo(true);
    setValidCpf(true);
    setValidNome(true);
    setValidEmail(true);
    setValidNovoEmail(true);
    setValidNascimento(true);
    setValidTelefone(true);
    setValidMotivo(true);
  }

  return(
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      size='xl'
      onOpened={() => {resetState()}}
    >
      <ModalHeader toggle={() => props.toggle()}>
        Solicitação de alteração de e-mail
      </ModalHeader>
      <ModalBody>
        <div style={{ fontSize: 12 }}>
          <p>Precisamos do seu CPF para validar o cadastro no IAPP.</p>

          <Input
            disabled={cpfSearching}
            valid={validCpf}
            style={{ marginTop: 5 }}
            name='cpf'
            label={'CPF*:'}
            placeholder={'xxx.xxx.xxx-xx'}
            onChange={onCpfChange}
            value={cpf}
          />

          <p style={{ background: '#002a4c', padding: 20, color: '#fff', textAlign: 'left', marginBottom: 20, marginTop: 40 }}><strong>Dados pessoais</strong></p>

          <Input
            valid={validNome}
            disabled={disableNome}
            whiteSpace={'nowrap'}
            style={{ marginTop: 5, width: '89%' }}
            name='nome'
            label={'Nome Completo*:'}
            optionsStyle={{ width: "90%" }}
            onChange={(e)=>{
              setNome(e.target.value)
            }}
            value={nome}
          />

          <Input
            whiteSpace={'nowrap'}
            disabled={disableEmail}
            valid={validEmail}
            style={{ marginTop: 45, width: '89%' }}
            name='email'
            label={'E-Mail atual*:'}
            optionsStyle={{ width: "90%" }}
            onBlur={handleOldEmail}
            onChange={(e)=>{
              setEmail(e.target.value)
            }}
            value={email}
          />
          { oldEmailExistente.verified && !oldEmailExistente.exists ? <span style={{ color: '#f00' }}>E-Mail não encontrado no Iapp.</span> : null }

          <Input
            whiteSpace={'nowrap'}
            disabled={disableNovoEmail}
            valid={validNovoEmail}
            style={{ marginTop: 45, width: '89%' }}
            name='email'
            label={'Novo E-Mail*:'}
            optionsStyle={{ width: "90%" }}
            onBlur={handleNovoEmail}
            onChange={(e)=>{
              setNovoEmail(e.target.value)
            }}
            value={novoEmail}
          />
          { emailExistente ? <span style={{ color: '#f00' }}>Este e-mail já está cadastrado no Iapp.</span> : null }

          <div style={{ marginTop: 40, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Input
              disabled={disableNascimento}
              style={{ marginTop: 5, width: 100 }}
              name='nascimento'
              valid={validNascimento}
              label={'Data de Nascimento*:'}
              placeholder={'xx/xx/xxxx'}
              onChange={(e)=>{
                setNascimento(dateMask(e.target.value));
              }}
              value={nascimento}
              maxLength={10}
            />
            <Input
              disabled={disableTelefone}
              style={{ marginTop: 5, width: 200 }}
              name='telefone'
              valid={validTelefone}
              label={'Telefone:'}
              placeholder={'(xx) xxxx-xxxx'}
              onChange={(e)=>{
                let erased = false;
                if (e.target.value.length < telefone.length) erased = true
                mask(e.target, mphone, erased)
                setTelefone(e.target.value)
              }}
              value={telefone}
            />
          </div>
          <div style={{ width: '100%', marginTop: 45 }}>
            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Descreva o motivo da solicitação*:</span><br />
              <textarea disabled={disableMotivo} onChange={(e) => setMotivo(e.target.value)} value={motivo} maxLength={255} style={validMotivo ? { fontSize: 12, height: 200, width: '100%' } : { fontSize: 12, height: 200, width: '100%', border: validMotivo ? 'none' : '1px solid #f00' }}>

              </textarea>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={ () => {
        props.onCancel()
      } }>Cancelar</ButtonCancel>
        <ButtonSave disabled={disableConfirm} onClick={handleConfirm}>Enviar</ButtonSave>
      </ModalFooter>
    </Modal>
  );
}

export default ForgotEmailModal;