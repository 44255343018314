const Messages = {
  Projects: {
    msgSelectSuperiorError: 'Não foi possível mudar o cargo superior. Por favor, tente novamente',
    msgParticipantsReceivedAccessPassword: 'Os participantes selecionados já receberam a senha de acesso',
    msgParticipantsMissingEmail: 'Existem participantes selecionados que não possuem e-mail cadastrado',
    msgParticipantMissingEmail: 'O participante não possui e-mail cadastrado',
    msgEmailSent: 'E-mails enviados com sucesso!'
  },
  Assincronos: {
    msgCannotMountGraph: 'Não foi possível montar o gráfico individual. Por favor, recarregue a página e tente novamente',
    msgFeedbackTextFetchFailed: 'Não foi possível carregar o texto de feedback. Por favor, recarregue a página',
    msgSendAnswerSuccess: "Resposta enviada!",
    msgSaveAnswerSuccess: `Atividade salva com sucesso.
      Caso queira, você poderá modificar a atividade até o prazo final`,

    msgErrorSendAnswer: "Não foi possível enviar resposta. Por favor, tente novamente",
    msgErrorSaveAnswer: "Não foi possível salvar resposta. Por favor, tente novamente",

    msgErrorLoadResult:
      "Não foi possível carregar resultados. Contate o consultor. Por favor, recarregue a página",
    msgErrorLoadDados:
      "Não foi possível carregar instrumento. Contate o consultor. Por favor, recarregue a página",

    msgErrorFetchAssincData:
      "Não foi possível carregar dados do grupo de instrumentos. Por favor, recarregue a página",

    /**
     * exibida quando o participante não respondeu todas as questões
    */
    msgValidation1: "Responda todas as questões",
    msgValidation: "Existem questões não respondidas. Preencha as questões e tente novamente.",

    msgConfirmacao: "É possivel que suas alterações não tenham sido salvas.",

    /**
     * mensagem do modal de confirmação
     */
    msgConfirmacao2: "Você tem certeza que deseja enviar o questionário ? Após o envio não será permitido alteração/visualização das respostas.",
    msgNoPermission: "Você não tem permissão para acessar este instrumento",
    msgSendInviteEmailConfirmation: "Enviar e-mail de convite para selecionados",
    msgSendInviteEmailToAll: "Enviar e-mail de convite para todos",
    msgEnableReminderEmailConfirmation: 'Ativar o envio de e-mail de lembrete?',
    msgDisableReminderEmailConfirmation: 'Desativar o envio de e-mail de lembrete?',
    msgAnswerersEmailConfirmation: "Deseja enviar o e-mail para os respondentes?",
    msgAllAnswerersEmailConfirmation: "Deseja enviar o e-mail para TODOS os respondentes?",
    msgSelectedAnswerersEmailConfirmation: "Deseja enviar o e-mail para os respondentes selecionados?",
    msgNoSelectedAnswerers: 'Nenhum respondente selecionado',
    msgAnswererRoleNotSelected: 'Por favor, selecione um cargo',
    msgAnswererNameNotSelected: 'Por favor, insira o nome do respondente',
    msgAnswererEmailDuplicated: 'E-mail do respondente já cadastrado',
    msgSendReleaseResultsEmail: 'Enviar e-mail de liberação de resultados',
    msgSendInviteEmail: 'Deseja enviar o e-mail de convite para os participantes selecionados?',
    msgSendInviteEmailAll: 'Deseja enviar o e-mail de convite para TODOS os participantes?',
    msgInviteEmailToParticipant: 'Enviar e-mail de convite para participante',
    msgInviteEmailToParticipantConfirmation: 'Deseja enviar o e-mail de convite para o participante?',
    msgSendReleaseResultsEmailAll: 'Enviar e-mail de liberação de resultados',
    msgReleaseResultEmailConfirmation: 'Deseja enviar o e-mail de liberação de resultados para os participantes? Apenas os participantes que finalizaram algum instrumento com resultados habilitados receberão o e-mail.',
    msgSendInviteEmailAllAnswerers: 'Deseja enviar o e-mail de convite para TODOS os respondentes do processo?',
    msgReleaseResultEmail: 'Deseja enviar o e-mail de liberação de resultados para TODOS os participantes?'
  },

  Sincronos: {

    msgReleaseResultEmail: 'Deseja enviar o e-mail de liberação de resultados para TODOS os participantes?',

    Dinamicas: {

      msgCriteriosInsulficientes: "AVISO: A quantidade de critérios criados é insuficiente. Todas as atividades/etapas da rodada que dependem do resultado do case serão canceladas. Deseja continuar?",
    },
  },

  Geral: {

    msgOperationSuccess: 'Operação realizada com sucesso!',
    msgNotImplemented: "Ainda não implementado",
    msgOperationError: "Não foi possível executar a operação selecionada. Por favor, tente novamente",
    msgDataFetchError: "Não foi possível carregar informações. Por favor, recarregue a página",
    msgTryAgain: "Não foi possível processar operação. Por favor, Tente novamente",
    msgSocketProcessingError: "Não foi possível receber notificação do servidor. Por favor, recarregue a página",
    msgSocketDisconnected: "Conexão com servidor perdida! Aguarde a reconexão e tente novamente",
    msgRequestToEnableResults: 'Libere os resultados na tela de Configurar'
  },

  Error: {

    msgCsvError: "Não foi possível processar arquivo CSV informado. Por favor, verifique os dados do arquivo e tente novamente"
  },

  Assessment: {

    msgMissingSessions: 'Não há sessões do tipo inscrição pelo participante para enviar e-mail',
    msgIncompleteHierarchies: 'Valores de tempo de complexidade da hierarquia inseridos pelo sistema (10 sem), pois não foram configurados. Para colocar os valores corretos, insira os valores na tela de hierarquia',
    msgDrawGraphError: 'Não foi possível montar o gráfico. Por favor, recarregue a página',
    msgSelectDateFirst: 'Selecione uma data no calendário primeiro',
    msgConsultantNotSelected: 'Selecione o consultor',
    msgSessionDeleted: 'Sessão excluída com sucesso!',
    msgConsultantSubscriptionNotSelected: 'Opção "inscrições pelo consultor" não selecionada!',
    msgSessionsNotConfigured: 'Atenção: Existem sessões sem o tipo de inscrição configurado. Corrija essas sessões na tela Configurar',
    msgWaitForProcessing: 'Gerando sessões, aguarde por favor.',
    msgReleaseResultEmail: 'Deseja enviar o e-mail de liberação de resultados para TODOS os participantes?',
    msgReleaseResultEmailButton: 'Enviar e-mail de liberação de resultados',
    msgReleaseSessionsConfirmation: 'Deseja liberar as inscrições?',
    msgUnauthorizeSessionsConfirmation: 'Deseja desautorizar as inscrições?',
    msgUpdateRankingError: 'Não foi possível atualizar o ranking!',
    msgErrorUpdateSessions: 'Não foi possível atualizar a tabela. Por favor, recarregue a página',
    msgErrorFetchArchiveds: 'Não foi possível carregar os assessments arquivados! Por favor, recarregue a página',
    msgAssessmentRestored: 'Assessment restaurado com sucesso!',
    msgErrorFetch: 'Não foi possível carregar os assessments! Por favor, recarregue a página'
  },

  Prova: {

    msgFetchProvasError: 'Não foi possível carregar os dados das provas finalizadas',
    msgUpdateProgressError: 'Não foi possível atualizar a barra de progresso do participante. Por favor, recarregue a tela.',
    msgProgressFetchError: 'Não foi possível carregar o progresso dos participantes. Por favor, recarregue a página',
    msgPlayConfirmation: 'Tem certeza que deseja iniciar a prova?',
    msgPauseConfirmation: 'Tem certeza que deseja pausar a prova?',
    msgStopConfirmation: 'Tem certeza que deseja finalizar a prova? Esta ação não poderá ser desfeita. Nenhum participante poderá realizar a prova após esta confirmação!',
    msgAuthorizeAllConfirmation: 'Tem certeza que deseja autorizar TODOS participantes selecionados?',
    msgRevokeAllConfirmation: 'Tem certeza que deseja revogar TODOS participantes selecionados?',
    msgAddTimeToSelected: 'Tem certeza que deseja adicionar tempo a TODOS participantes selecionados?',
    msgRevokeConfirmation: 'Tem certeza que deseja revogar o participante?',
    msgToggleAuthorizeConfirmation: 'Tem certeza que deseja mudar a permissão do participante?',
  },

  Reports: {

    msgCannotRefreshNotifications: 'Não foi possível carregar as notificações. Por favor, recarregue a página',
    msgCannotLoadArchived: 'Não foi possível carregar relatórios arquivados. Por favor, recarregue a página',
    msgCannotGeneratePDF: 'Não foi possível gerar o pdf do relatório. Para gerar novamente, acesse o módulo de relatórios',
    msgCannotGenerateReport: 'Não foi possível gerar a pré visualização do relatório',
    msgCannotLoadResults: 'Não foi possível carregar cards do módulo Resultados',
    msgCannotLoadIndividualResults: 'Não foi possível carregar cards individuais do módulo Resultados. Por favor, recarregue a página'
  },

  Results: {

    msgFetchUnifiedProofsError: 'Não foi possível carregar resultado de provas'
  }
};

export default Messages;
