import { Colors } from 'utils';
import { DEVICE_WIDTH } from 'config/Responsive';
import DrawTicksOverPlugin from './DrawTicksOverPlugin';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const fullLabels = [
  'Aptidão Cognitiva',
  'Agente de\nMudança',
  'Nível de Agregação de\nValor',
  'Busca por Referências\nProfissionais',
  'Construção de\nVínculos',
  'Trânsito nos\nDiferentes Níveis'
];

const resumedLabels = [
  'AC',
  'AM',
  'NAV',
  'BRP',
  'CV',
  'TDN'
];

const graphConfig = () => ({
  type: 'polarArea',
  data: {
    datasets: []
  },
  plugins: [DrawTicksOverPlugin, ChartDataLabels],
  options: {
    plugins: {
      datalabels: {
        align: 'end',
        display: true,
        offset: function (context) {

          const width = context.chart.scale.width;
          if (width <= DEVICE_WIDTH.MOBILE_L) {
            return 5;
          } else if (width <= DEVICE_WIDTH.TABLET) {
            return 35;
          } else if (width <= DEVICE_WIDTH.LAPTOP) {
            return 70;
          } else if (width <= DEVICE_WIDTH.LAPTOP_L) {
            return 80;
          } else {
            return 90;
          }
        },
        font: function (context) {
          let width = context.chart.width;
          let size = '10';

          if (width <= DEVICE_WIDTH.MOBILE_L) {
            size = '10';
          } else {
            size = '12';
          }

          return {
            size
          };
        },
        formatter: function (value, context) {
          if (!context.dataset.showLabel) return null;

          const realValue = context.dataset.realValues[context.dataIndex];

          let name = '';
          const width = context.chart.scale.width;
          if (width <= DEVICE_WIDTH.MOBILE_L) {
            name = resumedLabels[context.dataIndex];
          } else {
            name = fullLabels[context.dataIndex];
          }
          let label = name + ': ' + realValue;

          return label;
        },
        color: function (ctx) {
          return 'black';
        },
      },
    },

    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Atributos de Potencial',
      fontColor: 'black'
    },
    scale: {
      display: true,
      ticks: {
        beginAtZero: true,
        max: 5,
        stepSize: 1,
        fontColor: 'black'
      }
    },
    tooltips: {
      enabled: false
    }
  }
});

function drawParticipant({ potential, participantName }) {
  let datasets = [];

  const realValues = [
    potential.aptidaoCognitiva,
    potential.agendaMudanca,
    potential.nivelAgregacaoValor,
    potential.buscasReferenciasProfissionais,
    potential.construcaoVinculos,
    potential.transitoDiferentesNiveis
  ];

  datasets.push({

    label: participantName,
    showLabel: false,

    data: realValues,

    backgroundColor: [
      Colors.setOpacity('#7f5fa9', 0.6),
      Colors.setOpacity('#ed5f60', 0.6),
      Colors.setOpacity('#face3a', 0.6),
      Colors.setOpacity('#56c5d5', 0.6),
      Colors.setOpacity('#f17542', 0.6),
      Colors.setOpacity('#B8860B', 0.6),
    ],
  });

  datasets.push({

    label: participantName,
    showLabel: true,

    realValues,
    data: [
      5,
      5,
      5,
      5,
      5,
      5
    ],
  });

  return datasets;
}

export default {

  graphConfig,
  drawParticipant
}