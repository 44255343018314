import React from 'react';

import DefaultTitle from 'components/default-title';
const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const WhatsBeingMeasured = ({ text, style }) => (
  <div style={style}>
    <DefaultTitle>O que está sendo analisado</DefaultTitle>
    <p style={{ marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text || '') }}></p>
  </div>
);

export default WhatsBeingMeasured;