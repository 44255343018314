export default function getResultsMaisPontuados(answer) {
  if (!answer) return;

  const newAnswer = { ...answer }

  Object.keys(newAnswer).map(key => {
    const obj = {
      E: newAnswer[key]['E'] === newAnswer[key]['I'] ? 0 : newAnswer[key]['E'],
      S: newAnswer[key]['S'] === newAnswer[key]['N'] ? 0 : newAnswer[key]['S'],
      T: newAnswer[key]['T'] === newAnswer[key]['F'] ? 0 : newAnswer[key]['T'],
      J: newAnswer[key]['J'] === newAnswer[key]['P'] ? 0 : newAnswer[key]['J'],
      I: newAnswer[key]['I'],
      N: newAnswer[key]['N'],
      F: newAnswer[key]['F'],
      P: newAnswer[key]['P'],
    }

    newAnswer[key]['E'] = obj['E'];
    newAnswer[key]['S'] = obj['S'];
    newAnswer[key]['T'] = obj['T'];
    newAnswer[key]['J'] = obj['J'];
  });

  return newAnswer;
}