function filtrarNome(nome) {
  if (nome) nome = nome.split(" ");
  else return nome;
  for (let i = 0; i < nome.length; i++) {
    if (nome[i] !== "") nome[i] = nome[i].charAt(0).toUpperCase() + nome[i].slice(1).toLowerCase()
  }
  return nome.join(" ")
}

function filtrarCPF(cpf) {
  return cpf.replace(/[^\d]/g, "")
}

function formatFloatToReal(value) {
  return new Intl.NumberFormat('pt', { currency: 'BRL', style: 'currency' }).format(Number.parseFloat(value));
}

function salarioMask(input) {

  if (input.length > 2) {
      let iVirgula = (input.length - 2) - 1

      let antVirgula = input.substring(0, iVirgula + 1)
      let dpsVirgula = input.substring(iVirgula + 1, input.length)

      input = antVirgula + "," + dpsVirgula

  } else if (input.length === 2) {
      input = "0," + input
  } else if (input.length === 1) {
      input = "0,0" + input
  }

  input = "R$ " + input
  return input
}

export {
  filtrarNome,
  filtrarCPF,
  salarioMask,
  formatFloatToReal,
}