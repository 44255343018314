
import React from 'react';
import decode from "jwt-decode"
import { Route,  Redirect } from 'react-router-dom';
const checkAuth= ()=>{
  const token =sessionStorage.getItem("token");

    if(!token ){
      return false;
    }
    try{

      const {exp} = decode(token)

      if(exp < new Date().getTime()/1000){
        sessionStorage.setItem('sessionExpired', true)

        return false;
      }
    }catch(e){
      sessionStorage.setItem('sessionExpired', true)
      return false;
    }
    return true;
  }


  const AuthRoute = ({component:Component,...rest})=>(
    <Route {...rest} render={props =>(
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{pathname: "/login"}}/>
      )
    )}/>
  )

  export default AuthRoute
