import React from 'react';
import Chart from "chart.js";
import 'chartjs-plugin-annotation';

import { notifyError } from 'Services/Notificacoes';
import utils from './LocusControlChartUtils';
import Messages from 'static/Messages';
import ErrorMessage from 'views/Components/error-message';

export default class LocusControlChart extends React.Component {

  state = {
    userResult: null,
    error: false
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    this.setState({ error: true });
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, utils.graphConfig());
  }

  drawParticipant(state) {
    const datasets = utils.drawParticipant({
      respondentes: this.props.respondentes,
      resultado: this.props.resultado,
      idConsultor: this.props.idConsultor,
      chartInstance: this.chart
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  drawGraph(state) {
    return new Promise((resolve, _reject) => {
      this.draw(state);
      this.drawParticipant(state);
      resolve();
    });
  }

  componentDidMount() {
    let state = {};
    this.setState({ state });
    const canvas = document.getElementById(this.props.id);
    if (!canvas) {
      console.info('canvas element removed.');
      return;
    };

    this
      .drawGraph(state)
      .catch(err => {
        this.handleError(err);
        notifyError(Messages.Assincronos.msgCannotMountGraph);
      });
  }

  getData = () => {
    const { 
      resultado,
      respondentes
    } = this.props;
    return [
      {
        name: 'Autoavaliação',
        nota: resultado.locusInternoNormalizado,
        graficLowLabel: 'Locus de controle Externo',
        graficHighLabel: 'Locus de controle Interno'
      },
    ].concat(
      respondentes.map(respondente => ({
        name: this.props.isConsultor
          ? `${respondente.nome} ${respondente.relacao}`
          : respondentes.length >= 3
          ? `${respondente.relacao}`
          : 'Geral',
        nota: respondente.resposta.locusInternoNormalizado,
      })),
    );
  };

  render() {

    if (this.state.error) return <ErrorMessage message={Messages.Assincronos.msgCannotMountGraph} />

    return <div style={{
      height: this.getData().length === 1 ? 150 : 
        150 + (this.getData().length * 100),
      marginTop: 25
    }}><canvas 
      id={this.props.id} /></div>;
  }
}