import React, { Component } from 'react';
import styled from 'styled-components';

import { HeaderLogged } from '../Assets/Assets';

import '../preview.css';

const DivSticked = styled.div`
  z-index: 1000;
  top: 0;
  left: 0;
  position: sticky;
`;

class Header extends Component {
  state = {
    isOpen: false,
    modalConfirmação: false
  };
  toggle = () => {
    this.setState((previousState) => ({
      modalConfirmação: !previousState.modalConfirmação
    }));
  };

  logout = () => {
    this.setState({
      modalConfirmação: true
    });
  };

  logoutConfirmado = () => {
    this.props.onLogout();
    this.setState({
      modalConfirmação: false
    });
  };

  cancelar = () => {
    this.setState({
      modalConfirmação: false
    });
  };

  render() {
    return (
      <DivSticked className={this.props.headerClass}>
        <HeaderLogged
          items={this.props.items}
          name={'Nome do Participante'}
          action={()=>{}}
        />
        {/* <Navbar color="light" light expand="md">
          <NavbarBrand href="/">Iapp</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink>
                  <i className="fa fa-trophy" /> Ranking: 20pts -{" "}
                </NavLink>
              </NavItem>
              <NavItem>
                <img
                  src={sessionStorage.getItem("foto")}
                  className="img-avatar"
                  alt="admin@bootstrapmaster.com"
                />
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {sessionStorage.getItem("username")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    Meus Dados <Badge color="danger">1</Badge>
                  </DropdownItem>

                  <DropdownItem onClick={this.logout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar> */}
      </DivSticked>
    );
  }
}
export default Header;
