import { Colors } from 'utils';
import { normalizeScore } from 'Services/utilsFn';

const PLOT_COLORS = [
  '#7f5fa9',
  '#ed5f60',
  '#face3a',
  '#8B8386',
  '#56c5d5',
  '#f17542',
  '#006400',
  '#B8860B',
  '#00FF7F',
  '#363636',
  '#cddd84',
  '#cdc5bb',
  '#a14443',
  '#FF1493',
  '#1c136c',
  '#CD6090',
  '#32CD32',
  '#FF6A6A',
  '#556B2F',
]

const graphConfig = () => ({
  type: 'radar',
  data: {
    labels: [],
    datasets: []
  },
  options: {
    title: {
			display: true,
			text: 'Escuta Ativa',
			color: '#23282c',
			font: {
				size: 12,
				weight: 'bold',
				family: 'Montserrat, sans-serif'
			},
		},
    legend: {
			position: 'bottom',
		},
    scale: {
      ticks: {
        display: false,
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 100,
        stepSize: 10
      },
      gridLines: {
        display: true
      },
    },
    plugins: {
			datalabels: {
				display: false,
			}
		},
    responsive: true,
  },
});

function drawParticipant({ result }) {
  let datasets = [];

  const BACKGROUND_COLORS = [
    Colors.setOpacity(PLOT_COLORS[0], 0.7),
    Colors.setOpacity(PLOT_COLORS[0], 0.2),
    Colors.setOpacity(PLOT_COLORS[1], 0.2),
    Colors.setOpacity(PLOT_COLORS[2], 0.2),
    Colors.setOpacity(PLOT_COLORS[3], 0.2),
    Colors.setOpacity(PLOT_COLORS[4], 0.2),
    Colors.setOpacity(PLOT_COLORS[5], 0.2),
    Colors.setOpacity(PLOT_COLORS[6], 0.2),
    Colors.setOpacity(PLOT_COLORS[7], 0.2),
  ];

  let { media1, media2 } = result;

  const media1Normalized = normalizeScore(media1, 5, 1, 100, 0);
  const media2Normalized = normalizeScore(media2, 5, 1, 100, 0);

  const media1SubNormalized = 100 - media1Normalized;
  const media2SubNormalized = 100 - media2Normalized;

  datasets.push({
    label: 'Autoavaliação',
    data: [media2Normalized, media1SubNormalized, media2SubNormalized, media1Normalized],
    borderColor: [BACKGROUND_COLORS[0]],
    backgroundColor: [BACKGROUND_COLORS[1]],
  });

  const resultNormalized = {
    media1Normalized,
    media1SubNormalized,
    media2Normalized,
    media2SubNormalized
  }

  return [datasets, resultNormalized];
}

export default {

  graphConfig,
  drawParticipant
}