import React, { Component } from 'react';
import { Row } from 'reactstrap';
import './scss/styles.scss';

export default class Legenda extends Component {
  render() {
    return (
      <Row className="legend-row row-centered justify-content-center">
        <div className="erro"></div>
        <h6 className="erro-legenda">Erro</h6>
        <div className="acerto"></div>
        <h6 className="acerto-legenda">Acerto</h6>
      </Row>
    );
  }
}
