import GraphConstants from './constants';
const { 
  SUPERIOR_LINE_COLOR,
  INFERIOR_LINE_COLOR,
} = GraphConstants;

export function getRectangle(pointX, pointY) {
  const minXDist = 0.31;
  const minYDist = 0.2;

  let rect = {
    a: {
      x: pointX - minXDist,
      y: pointY + minYDist
    },
    b: {
      x: pointX - minXDist,
      y: pointY - minYDist
    },
    c: {
      x: pointX + minXDist,
      y: pointY + minYDist
    },
    d: {
      x: pointX + minXDist,
      y: pointY - minYDist
    },
  }

  return rect;
}

export function superiorLine() {
  return {
    /* chartjs-plugin-datalabels specific */
    datalabels: {
      display: false
    },

    /* chartjs */
    label: 'Superior',
    fill: false,
    tension: 0,
    showLine: true,
    borderWidth: 4,
    borderColor: SUPERIOR_LINE_COLOR,
    backgroundColor: 'rgba(75,192,192,0.4)',
    pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: [
      { x: 3.25, y: 4.25 },
      { x: 3.25, y: 3.25 },
      { x: 5.25, y: 3.25 },
    ],
  }
}

export function inferiorLine() {
  return {
    /* chartjs-plugin-datalabels specific */
    datalabels: {
      display: false
    },

    showTooltips: {
      display: false
    },

    /* chartjs */
    label: 'Inferior',
    fill: false,
    tension: 0,
    showLine: true,
    borderWidth: 4,
    borderColor: INFERIOR_LINE_COLOR,
    backgroundColor: 'rgba(75,192,192,0.4)',
    pointBorderColor: 'rgba(75,192,192,1)',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: [
      { x: 0.75, y: 2.75 },
      { x: 2.75, y: 2.75 },
      { x: 2.75, y: 1.75 },
    ],
  }
}