import React, { Component } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import { FaCamera, FaUpload, FaTrashAlt, FaCheck } from 'react-icons/fa';
import { notifyError, notifySuccess } from 'Services/Notificacoes';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import ReactCrop from 'components/ReactCrop';
import { ButtonCancel, ButtonSave } from "views/Pages/Assets/Assets";

const StyledFooter = styled.div`
  width: 100%;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);

  .mouse-hover {
    cursor: pointer;
  }

  .mouse-hover:hover {
    opacity: .5 !important;
  }
`

const InputHidden = styled.input`
  display: none;
`;

export default class ModalTakePicture extends Component {
  state = {
    foto: '',
    videoStreamModal: false,
    width: 520,
    height: 0,
    streaming: false,
    stream: null,
    thereIsPhoto: false,
    vd: 'block',
    isPostingPhoto: false,
    iniciando: false,
    uploadedPhoto: false,
    uploadedImgW: 200,
    uploadedImgH: 200,
    cropModal: {
      isOpen: false,
      type: ''
    },
    cropFileName: '',
    cropImg: null,
    crop: {
      unit: '%',
      width: 50,
      x: 25,
      y: 25,
      aspect: 1/1
    },
    avatarChanged: false,
    avatar: '',
    avatarUrl: '',
    isTakingPic: false,
    deleteImgModal: false
  };

  componentDidMount = () => {
    if (this.props.foto) {
      this.setState({ foto: this.props.foto, thereIsPhoto: true });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.foto !== this.props.foto) {
      this.setState({ foto: this.props.foto, thereIsPhoto: !!this.props.foto });
    }
  }

  async salvarFoto(e,img) {
    try {
      if (e) e.preventDefault();
      const { userId, projectId, photo, uploadedPhoto, file } = this.state;

      const fileToUpload = img || file

      const foto = await this.props.handleUploadUserPhoto(fileToUpload);

      if (foto) this.setState({ avatarUrl: foto, foto });

      this.setState({ cropModal: { isOpen: false, type: '' } });

      this.props.toggle();

    } catch (err) {
      notifyError('Não foi possível salvar a foto. Por favor, tente novamente');
    } finally {
      this.setState({ isPostingPhoto: false });
    }

  }

  handleFileChange = async (img, e) => {
    if (e) e.preventDefault();
    const file = img;

    await this.salvarFoto(e, img);

    if (this.state.cropModal.type === 'avatar') {
      this.setState({ avatar: file, avatarChanged: true, cropModal: false });
    }
  }

  clearPhoto = (width, height) => {
    let canvas = document.getElementById('canvas');
    let photo = document.getElementById('photo');

    // clear photo
    var context = canvas && canvas.getContext('2d');
    if (context && !this.state.uploadedPhoto) {
      context.fillStyle = '#AAA';
      context.fillRect(0, 0, width, height);

      var data = canvas.toDataURL('image/png');
      photo.setAttribute('src', data);
    }
  };

  openVideoStream = () => {
    let { streaming, width, height, thereIsPhoto } = this.state;
    let video = document.getElementById('video');
    let canvas = document.getElementById('canvas');
    let photo = document.getElementById('photo');

    if (thereIsPhoto) return;

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          video.srcObject = stream;
          this.setState({ stream });
          video.play();
        })
        .catch((err) => {
          notifyError('Por favor, verifique se a câmera está conectada e se o navegador tem permissão para acessa-la.');
          console.error('An error occurred: ' + err);
          this.setState({ streaming: false });
          //this.toggleModal('videoStreamModal');
        });

      video.addEventListener(
        'canplay',
        (e) => {
          if (!streaming) {
            height = video.videoHeight / (video.videoWidth / width);

            video.setAttribute('width', width);
            video.setAttribute('height', height);
            // canvas.setAttribute("width", width)
            // canvas.setAttribute("height", height)
            canvas.setAttribute('width', width);
            canvas.setAttribute(
              'height',
              height
            );

            streaming = true;
            this.clearPhoto(width, height);
            this.setState({ streaming, width, height });
          }
        },
        false
      );

      this.setState({ canvas, video, photo, streaming, isTakingPic: true });
    } else {

      this.setState({
        streaming: false
      });
    }
  };

  closeVideoStream = () => {
    const { streaming, video, stream } = this.state;
    if (this.state.uploadedPhoto) this.setState({
      uploadedPhoto: false,
      photo: null,
      thereIsPhoto: !!this.state.foto,
      isTakingPic: false
    })
    if (!streaming) return;

    video.pause();
    video.srcObject = undefined;
    // video.src = ""
    stream.getTracks()[0].stop();

    this.setState({
      streaming: false,
      video,
      stream: null,
      photo: null,
      // height: 0,
      thereIsPhoto: !!this.state.foto,
      isTakingPic: false
    });
  };

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  handleImgCrop = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          this.setState({ cropImg: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  openCropModal = (e, type, fileBlob) => {
    if (!e && fileBlob) {
      const objFile = {
        target: {
          files: [fileBlob]
        }
      }
      this.handleImgCrop(objFile);
        this.setState({
          cropModal: {
            isOpen: true,
            type
          }
        });
    } else {
      if (!e.target.files || !e.target.files.length) return;

      const file = e.target.files[0];

      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        const fileName = e.target.files[0].name;

        this.handleImgCrop(e);
        this.setState({
          cropFileName: fileName,
          cropModal: {
            isOpen: true,
            type
          }
        });
      } else {
        notifyError("O formato da avatar deve ser .png, .jpg ou .jpeg. Tente novamente.");
      }
    }


  }

  captureFrame = async () => {
    let { width, height } = this.state;
    let video = document.getElementById('video');
    let canvas = document.getElementById('canvas');
    let photo = document.getElementById('photo');

    var context = canvas.getContext('2d');
    if (width && height) {

      // context.drawImage(video, 0, 0, width, height)
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      var dataUrl = canvas.toDataURL('image/png');
      const blob = this.dataURLtoBlob(dataUrl);

      photo.setAttribute('src', dataUrl);

      this.openCropModal(undefined, 'avatar', blob);

      this.setState({
        file: blob,
        thereIsPhoto: true,
        photo: {
          src: dataUrl
        }
      });

      // enviar socket de foto atualizada para o consultor
      // Constantes.io.emit('User:Selfie', { foto: data })
      // await api.post("/api/user/selfie", {
      //   foto: data,
      //   _id: sessionStorage.getItem("user")
      // })
    } else {
      this.clearphoto(width, height);
    }
  };

  takePicture(e) {
    e.preventDefault();
    this.captureFrame();
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async loadImgFile(e) {
    try {
      e.persist();
      const eventImg = e.target.files[0];
      const data = await this.toBase64(e.target.files[0]);
      this.openCropModal(e, 'avatar');
      if (data) {
        this.setState({
          file: eventImg,
          photo: {
            src: data
          },
          thereIsPhoto: true,
          uploadedPhoto: true,
        });
      }
    } catch (err) {
      notifyError('Não foi possível ler os dados da imagem. Por favor, selecione uma nova imagem e tente novamente.');
    }
  }

  excludeAvatar = async () => {
    try {
      if (this.state.isPostingPhoto || this.state.isTakingPic) return;

      await this.props.handleExcludeAvatar();

      this.setState({ foto: '', photo: null, thereIsPhoto: false, uploadedPhoto: false,  });

      this.toggleDeleteImgModal();
      this.props.toggle();
    } catch (err) {
      console.error(err);
      notifyError('Não foi possível excluir foto. Por favor, tente novamente');
    }
  }

  toggleDeleteImgModal = () => {
    this.setState({ deleteImgModal: !this.state.deleteImgModal });
  }

  render() {
    return (
      <>
      <Modal
          toggle={this.toggleDeleteImgModal}
          isOpen={this.state.deleteImgModal}
      >
        <ModalHeader toggle={this.toggleDeleteImgModal}>
          Remover imagem
        </ModalHeader>
        <ModalBody style={{ fontSize: 12 }}>
          Deseja remover essa imagem ?
        </ModalBody>
        <ModalFooter>
          <ButtonCancel onClick={this.toggleDeleteImgModal}>Cancelar</ButtonCancel>
          <ButtonSave  onClick={this.excludeAvatar}>Remover</ButtonSave>
        </ModalFooter>
      </Modal>
      <ReactCrop
          fileName={this.state.cropFileName}
          minWidth={60}
          circularCrop={true}
          showCropModal={this.state.cropModal.isOpen}
          src={this.state.cropImg}
          crop={this.state.crop}
          onCancelCrop={() =>
          this.setState({ cropModal: false, thereIsPhoto: false, uploadedPhoto: false })}
          onSubmitCrop={this.handleFileChange}
        />
        <Modal
              size="md"
              style={{ maxWidth: 600 }}
              isOpen={this.props.isOpen}
              onOpened={() => this.openVideoStream()}
              onClosed={() => this.closeVideoStream()}
              toggle={() => this.props.toggle()}
            >
              <ModalHeader toggle={() => this.props.toggle()}>Adicionar foto</ModalHeader>
              <ModalBody className="text-center">
                <canvas
                  id="canvas"
                  style={{
                    display: 'none',
                    width: this.state.width,
                    height: this.state.height,

                  }}
                />
                <div className="camera">
                  <video
                    id="video"
                    style={{
                      // width: this.state.width,
                      // height: this.state.height,
                      display: this.state.thereIsPhoto ? 'none' : 'block',
                      margin: '0 auto',
                      width: '100%'
                    }}
                  >
                    Video stream not available.
                  </video>
                </div>
                <div
                  className="output"
                  style={{ display: this.state.thereIsPhoto ? 'block' : 'none' }}
                >
                  <img
                    src={(this.state.photo && this.state.photo.src) || (this.state.foto && !this.state.foto.includes('blob') && `https://files-storage.s3.amazonaws.com/${this.state.foto}`) || this.state.foto && this.state.foto.includes('blob') && this.state.foto }
                    id="photo"
                    className="imagemFoto"
                    style={this.state.uploadedPhoto ? { height: this.state.uploadedImgH,  } : this.state.foto && (!this.state.photo || !this.state.photo.src) ? { width: 370, height: 370 } : null}
                  />
                </div>
                </ModalBody>
                <StyledFooter>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} className="text-center">
                    {this.state.thereIsPhoto ? (
                      <Tooltip
                        title={ 'Alterar foto' }
                        position="bottom"
                        arrow="true"
                        size="small"
                      >
                        <FaCamera
                          id="startbutton"
                          className={'mouse-hover'}
                          onClick={(e) => {
                            this.setState({ thereIsPhoto: false, uploadedPhoto: false, isTakingPic: true });
                            setTimeout(() => {
                              this.openVideoStream();
                            }, 500);
                          }}
                          style={{marginRight: 14}}
                          disabled={this.state.isPostingPhoto}
                          color={'#012a4a'}
                          size={26}
                        />
                      </Tooltip>
                    ) : (
                        <>
                          <Tooltip
                            title={ 'Tirar foto' }
                            position="bottom"
                            arrow="true"
                            size="small"
                          >
                            <FaCamera
                              id="startbutton"
                              className={'mouse-hover'}
                              onClick={(e) => this.takePicture(e)}
                              disabled={this.state.isPostingPhoto || !this.state.streaming}
                              style={{ marginLeft: 10, opacity: this.state.isPostingPhoto || !this.state.streaming ? '.3' : '100%' }}
                              color={'#012a4a'}
                              size={26}
                            />
                          </Tooltip>
                          <label style={{ marginLeft: 25 }}>
                            <Tooltip
                              title={ 'Adicionar foto' }
                              position="bottom"
                              arrow="true"
                              size="small"
                            >
                              <FaUpload
                                className={'mouse-hover'}
                                color={'#012a4a'}
                                style={{ marginRight: 14 }}
                                size={25}
                              />
                            </Tooltip>
                            <InputHidden type="file" accept='.png, .jpg, .jpeg' onChange={e => this.loadImgFile(e)} />
                          </label>
                        </>
                      )}{' '}
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }} className="text-center">
                      <Tooltip
                          title={ 'Excluir foto' }
                          position="bottom"
                          arrow="true"
                          size="small"
                      >
                        <FaTrashAlt
                          onClick={this.toggleDeleteImgModal}
                          className={'mouse-hover'}
                          disabled={ this.state.isPostingPhoto || this.state.isTakingPic }
                          style={{ opacity: this.state.isPostingPhoto || this.state.isTakingPic ? '.3' : '100%' }}
                          color={'#012a4a'}
                          size={23}
                        />
                      </Tooltip>
                    </div>
                    </div>
                </StyledFooter>
            </Modal>
          </>
    );
  }
}