import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Row, Col, Tooltip, Progress } from "reactstrap";
import { e } from "../../../Services/Constantes";
import InputMask from "react-input-mask";
import FileBase64 from "react-file-base64";
import HeaderTabelaVincular from "../../Pages/Modulos/Provas/Components/participantes/HeaderTabelaVincular";

const third = "#cdd8dc";

/////RANKING

var pessoas = [
  {
    nome: "Usuario 01",
    rodadas: [
      {
        atividades: [
          {
            nome: "storytelling",
            pontuacao: [
              {
                tipo: "Individual 01",
                pontuacao: 30
              },
              {
                tipo: "Grupo",
                pontuacao: 60
              },
              {
                tipo: "Individual 2",
                pontuacao: 90
              }
            ]
          },
          {
            nome: "sociometria",
            pontuacao: [
              {
                tipo: "total",
                pontuacao: 30
              }
            ]
          }
        ]
      },
      {
        atividades: [
          {
            nome: "storytelling",
            pontuacao: [
              {
                tipo: "Individual 01",
                pontuacao: 30
              },
              {
                tipo: "Grupo",
                pontuacao: 60
              },
              {
                tipo: "Individual 2",
                pontuacao: 90
              }
            ]
          },
          {
            nome: "sociometria",
            pontuacao: [
              {
                tipo: "total",
                pontuacao: 30
              }
            ]
          },
          {
            nome: "Estilo",
            pontuacao: [
              {
                tipo: "total",
                pontuacao: 70
              }
            ]
          }
        ]
      }
    ]
  },
  {
    nome: "Usuario 02",
    rodadas: [
      {
        atividades: [
          {
            nome: "storytelling",
            pontuacao: [
              {
                tipo: "Individual 01",
                pontuacao: 31
              },
              {
                tipo: "Grupo",
                pontuacao: 62
              },
              {
                tipo: "Individual 2",
                pontuacao: 92
              }
            ]
          },
          {
            nome: "sociometria",
            pontuacao: [
              {
                tipo: "total",
                pontuacao: 32
              }
            ]
          }
        ]
      },
      {
        atividades: [
          {
            nome: "storytelling",
            pontuacao: [
              {
                tipo: "Individual 01",
                pontuacao: 35
              },
              {
                tipo: "Grupo",
                pontuacao: 65
              },
              {
                tipo: "Individual 2",
                pontuacao: 97
              }
            ]
          },
          {
            nome: "sociometria",
            pontuacao: [
              {
                tipo: "total",
                pontuacao: 37
              }
            ]
          }
        ]
      }
    ]
  }
];

export const Rank = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;

  .roll {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 10px;
    text-align: center;
  }
  .line-break {
    width: 100%;
  }
  .roll2 {
    display: flex;
    flex-wrap: wrap;
    border-left: solid 1px lightgray;
    border-right: solid 1px lightgray;

    justify-content: space-around;
    text-align: center;
    height: 40px;
    text-align: center;

    div {
      flex-grow: 1;
    }
  }
  .margin {
    margin: 10px;
  }
  .cabecalho {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#18b2be+0,0f929a+100 */
    background: #18b2be; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #18b2be 0%,
      #0f929a 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #18b2be 0%,
      #0f929a 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #18b2be 0%,
      #0f929a 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b2be', endColorstr='#0f929a',GradientType=0 ); /* IE6-9 */
    color: white;
    padding: 5px;
    border-radius: 5px 5px 0px 0px;

    text-align: center;
  }
  .single {
    height: 40px;
    text-align: center;
    padding: 19px;
  }
  .border {
    border: solid 1px lightgray;
  }
  .duplo {
    height: 80px;
  }
  .grow {
    flex-grow: 2;
  }
`;

/*

Objeto

{
  "rodada":1,
  "instrumento":"Storytelling",
  "pontos":{
    "pessoa":"Thiago",
    "pontoIndividual":"10",
    "pontoGrupo":"20",
  }

}


*/

export class Ranking extends Component {
  render() {
    return (
      <Rank>
        <Row>
          {this.props.ranking.map((data, ix) => (
            <Col key="ix">Rodada {ix + 1}</Col>
          ))}
        </Row>
      </Rank>
    );
  }
}

export const BaseLogin = styled.div`
  background-color: #012a4a;
  color: white;
  width: 100%;
  padding: 40px;
  height: 100%;
  button {
    font-size: 16px;
  }
  .inputLogin {
    background: #001b2f;
    border: 0px;
    height: 40px;
    font-size: 16px !important;
    color: white;
    &::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 16px;
    }
  }
  .before span {
    background: #f0c219;
    border: 0px;
    border-radius: 5px 5px 5px 5px;
    padding: 5px 10px;
  }
`;
export const LogoHome = styled.img`
  width: 150px;
  margin: 50px;
`;
const BaseMenu = styled.div`
  background: ${third};
  padding: 10px;
  height: 100% !important;
  text-align: center;
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin-bottom: 20px;
  }
`;

const Ico = styled.button`
  border: 0px;
  height: 40px;
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  padding: 10px;
  border-radius: 5px;
  img {
    width: 18px;
    filter: invert(100%);
  }
  :focus {
    outline: none;
  }
`;

export const Base = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
`;
export class IconProjeto extends Component {
  state = {
    isToolTipOpen: false
  };

  toggleToolTip() {
    this.setState(prev => ({ isToolTipOpen: !prev.isToolTipOpen }));
  }

  render() {
    return (
      <span>
        <Ico id={this.props.id} onClick={this.props.onClick}>
          <img src={require("./images/" + this.props.icon + ".gif")} />
        </Ico>
        <Tooltip
          placement="top"
          isOpen={this.state.isToolTipOpen}
          target={this.props.id}
          delay={{ show: 50, hide: 50 }}
          toggle={() => this.toggleToolTip()}
        >
          {this.props.tip}
        </Tooltip>
      </span>
    );
  }
}

export class MenuProjeto extends Component {
  render() {
    return (
      <BaseMenu>
        <ul>
          <li>
            <IconProjeto
              id="icon-projeto-resumo"
              tip="Configurar"
              icon="eye"
              onClick={this.props.resumoProjeto}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-assincronos"
              tip="Assíncronos"
              icon="user"
              onClick={this.props.goAssincronos}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-prova"
              tip="Provas"
              icon="test"
              onClick={this.props.goProvas}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-sincronos"
              tip="Síncronos"
              icon="table"
              onClick={this.props.goSincronos}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-assessment"
              tip="Assessment"
              icon="meeting"
              onClick={this.props.goAssessment}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-resultado"
              tip="Resultados"
              icon="results"
              onClick={this.props.goResultados}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-relatorio"
              tip="Relatorio"
              icon="results"
              onClick={this.props.goRelatorios}
            />
          </li>
        </ul>
      </BaseMenu>
    );
  }
}

const Logo = styled.div`
  div {
    border: solid 1px lightgray;
    display: inline-block;
    padding: 20px;
    background: white;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      border: solid 1px lightgray;
      float: left;
    }
    span {
      font-weight: bold;
      padding: 60px 0px 20px 20px;
      float: left;
      font-size: 18px;
      padding-right: 50px;
    }
  }
`;

export class Cliente extends Component {
  render() {
    return (
      <Logo>
        <div>
          {this.props.logo !== "" ? (
            <img src={this.props.logo} alt={this.props.cliente} />
          ) : (
            <img src={require("./images/user.gif")} alt={this.props.cliente} />
          )}
        </div>
      </Logo>
    );
  }
}

export const BaseProjeto = styled.div`
  background: #f7f8f8;
  width: 100%;
  padding: 20px;
`;
export const Bread = styled.div`
  display: inline-block;
  font-size: 10px;
  ul {
    display: inline;
    padding: 0px;
  }
  li {
    list-style: none;
    display: inline-block;
    padding-left: 5px;
  }
  li:last-child {
    font-weight: bold;
    font-size: 12px;
  }
`;
export class Breadcrumb extends Component {
  render() {
    let itens = this.props.itens;
    var res = itens.split("/");

    return (
      <Bread>
        <ul>
          {res.map((item, index) => (
            <li key={index}>{item + " / "} </li>
          ))}
        </ul>
      </Bread>
    );
  }
}

export class Lista extends Component {
  render() {
    return <div />;
  }
}

const It = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "white" : "transparent")};
  border: ${props => (props.linha % 2 === 0 ? "solid 1px lightgray" : "")};
  width: 100%;
  padding: 10px;
  font-size: 12px;
  small {
    font-weight: bold;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin: 5px;
    display: inline;
  }

  p {
    padding: 10px;
    margin: 0px;
  }
`;
const ItCli = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "white" : "transparent")};
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
  small {
    font-weight: bold;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin: 5px;
    display: inline;
  }
  .status {
    color: ${props => (props.status === "APROVADO" ? "green" : "orange")};
  }
`;

export class Item extends Component {
  configurarDinamica = () => {
    this.props.configurarDinamica(this.props.linha);
  };
  configurarGrupo = () => {
    this.props.configurarGrupo(this.props.linha);
  };
  configurarRodadas = () => {
    this.props.configurarRodadas(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <It linha={this.props.linha}>
        <Row>
          <Col sm="8">
            <Row>
              <Col>
                <small>Dinâmica</small>
                <br />
                {this.props.nome}
              </Col>
              <Col>
                <small>Facilitadores</small>
                <br />
                {this.props.facilitadores}
              </Col>
              <Col>
                <small>Data</small>
                <br />
                {this.props.data}
              </Col>
              <Col>
                <small>Horário</small>
                <br />
                {this.props.hora}
              </Col>
              <Col>
                <small>Código de Acesso</small>
                <br />
                {this.props.codigoAcesso}
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                <ul>
                  <li>
                    <IconProjeto
                      id="icon-config"
                      tip="Configurar"
                      onClick={this.configurarDinamica}
                      icon="cog"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-participantes"
                      tip="Participantes"
                      onClick={this.configurarParticipantes}
                      icon="partner"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-rodadas"
                      tip="Rodadas"
                      onClick={this.configurarRodadas}
                      icon="arrows"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-grupo"
                      tip="Grupos"
                      onClick={this.configurarGrupo}
                      icon="grupo"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      tip="Acompanhar"
                      onClick={this.acompanhar}
                      icon="eye"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      tip="Arquivar"
                      onClick={this.arquivar}
                      icon="delete"
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </It>
    );
  };
  render() {
    return this.montaTela();
  }
}
export const HeaderTable = styled.div`
  small {
    font-weight: bold;
  }
`;

export class ItemAssincrono extends Component {
  configurarAssincrono = () => {
    this.props.configurarAssincrono(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <React.Fragment>
        <It linha={this.props.linha}>
          <Row>
            <Col sm="9">
              <Row>
                <Col>
                  <p>{this.props.nome}</p>
                </Col>
                <Col>
                  <p>{this.props.instrumentos}</p>
                </Col>
                <Col>
                  <p>{this.props.data.inicio}</p>
                </Col>
                <Col>
                  <p> {this.props.data.final}</p>
                </Col>
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Col>
                  <ul>
                    <li>
                      <IconProjeto
                        id="icon-config"
                        tip="Configurar"
                        onClick={this.configurarAssincrono}
                        icon="cog"
                      />
                    </li>
                    <li>
                      <IconProjeto
                        id="icon-participantes"
                        tip="Participantes"
                        onClick={this.configurarParticipantes}
                        icon="partner"
                      />
                    </li>
                    <li>
                      <IconProjeto
                        id="icon-acompanhar"
                        tip="Acompanhar"
                        onClick={this.acompanhar}
                        icon="eye"
                      />
                    </li>
                    <li>
                      <IconProjeto
                        id="icon-del"
                        tip="Arquivar"
                        onClick={this.arquivar}
                        icon="delete"
                      />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </It>
      </React.Fragment>
    );
  };
  render() {
    return this.montaTela();
  }
}
export class ItemAssincronoAcomp extends Component {
  configurarAssincrono = () => {
    this.props.configurarAssincrono(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    let cor = "success";
    return (
      <React.Fragment>
        <It linha={this.props.linha}>
          <Row>
            <Col sm="9">
              <Row>
                <Col sm="1" className="text-center">
                  <img
                    // src={require("./images/" + this.props.foto)}

                    src={
                      this.props.foto
                        ? this.props.foto
                        : require("./images/user.gif")
                    }
                    alt={this.props.cliente}
                    style={{ width: 30 }}
                  />
                </Col>
                <Col sm="2">
                  <p>{this.props.nome}</p>
                </Col>
                <Col sm="1">
                  <p>{this.props.cargo}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.coluna}</p>
                </Col>

                <Col sm="3">
                  <p>{this.props.realizacao}</p>
                </Col>
                <Col sm="3">
                  <p>{this.props.expira}</p>
                </Col>
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Col sm="6" style={{ paddingTop: 10 }}>
                  <Progress bar color="success" value="100">
                    {this.props.progresso}
                  </Progress>
                </Col>
                <Col sm="6" style={{ paddingTop: 10 }}>
                  <Icon icon="eye" />
                  <Icon icon="email" />
                  <Icon icon="delete" />
                </Col>
              </Row>
            </Col>
          </Row>
        </It>
      </React.Fragment>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemParticipante extends Component {
  configurarAssincrono = () => {
    this.props.configurarAssincrono(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    let cor = "success";
    return (
      <React.Fragment>
        <It linha={this.props.linha}>
          <Row>
            <Col sm="12">
              <Row>
                <Col sm="2" className="text-center">
                  <Row>
                    <Col sm="1">
                      <input type="checkbox" />
                    </Col>
                    <Col>
                      <img
                        // src={require("./images/" + this.props.foto)}

                        src={
                          this.props.foto
                            ? this.props.foto
                            : require("./images/user.gif")
                        }
                        alt={this.props.cliente}
                        style={{ width: 30 }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="2">
                  <p>{this.props.nome}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.cargo}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.cpf}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.email}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </It>
      </React.Fragment>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemAssessment extends Component {
  configurarAssessment = () => {
    this.props.configurarAssessment(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <It linha={this.props.linha}>
        <Row>
          <Col sm="8">
            <Row>
              <Col>
                <small>Assesment</small>
                <br />
                {this.props.nome}
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                <ul>
                  <li>
                    <IconProjeto
                      id="icon-config"
                      tip="Configurar"
                      onClick={this.configurarAssessment}
                      icon="cog"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-participantes"
                      tip="Participantes"
                      onClick={this.configurarParticipantes}
                      icon="partner"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      tip="Acompanhar"
                      onClick={this.acompanhar}
                      icon="eye"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      tip="Arquivar"
                      onClick={this.arquivar}
                      icon="delete"
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </It>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemProva extends Component {
  configurarProva = () => {
    this.props.configurarProva(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <It linha={this.props.linha}>
        <Row>
          <Col sm="8">
            <Row>
              <Col>
                <small>Prova</small>
                <br />
                {this.props.nome}
              </Col>
              <Col>
                <small>Facilitadores</small>
                <br />
                {this.props.facilitadores}
              </Col>
              <Col>
                <small>Data</small>
                <br />
                {this.props.data}
              </Col>
              <Col>
                <small>Horário</small>
                <br />
                {this.props.horario}
              </Col>
              <Col>
                <small>Código de acesso</small>
                <br />
                {this.props.codigoAcesso}
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                <ul>
                  <li>
                    <IconProjeto
                      id="icon-config"
                      tip="Configurar"
                      onClick={this.configurarProva}
                      icon="cog"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-participantes"
                      tip="Participantes"
                      onClick={this.configurarParticipantes}
                      icon="partner"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      tip="Acompanhar"
                      onClick={this.acompanhar}
                      icon="eye"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      tip="Arquivar"
                      onClick={this.arquivar}
                      icon="delete"
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </It>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemCliente extends Component {
  // configurarDinamica = () => {
  //   this.props.configurarDinamica(this.props.linha);
  // };
  // configurarGrupo = () => {
  //   this.props.configurarGrupo(this.props.linha);
  // };
  // configurarRodadas = () => {
  //   this.props.configurarRodadas(this.props.linha);
  // };
  configurarCliente = () => {
    this.props.configurarCliente(this.props.linha);
  };
  listarProjetos = () => {
    this.props.listarProjetos(this.props.linha);
  };
  excluir = () => {
    this.props.excluir(this.props.linha);
  };

  montaTela = () => {
    return (
      <ItCli linha={this.props.linha}>
        <Row>
          <Col sm="2">
            <Cliente
              logo={
                this.props.logo
                  ? this.props.logo
                  : require("../Assets/images/partner.gif")
              }
            />
          </Col>
          <Col sm="8">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Nome do Cliente</small>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col sm="3">
                    <small>UF</small>
                    <br />
                    {this.props.uf ? this.props.uf : "-"}
                  </Col>
                  <Col>
                    <small>Último Projeto</small>
                    <br />
                    {this.props.ultimoprojeto}
                  </Col>
                  <Col>
                    <small>Orçamento Total</small>
                    <br />
                    {"R$ " + this.props.orcamento}
                  </Col>
                </Row>
              </Col>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Segmento</small>
                    <br />
                    {this.props.segmento}
                  </Col>
                  <Col sm="3">
                    <small>Cidade</small>
                    <br />
                    {this.props.cidade ? this.props.cidade : "-"}
                  </Col>
                  <Col>
                    <small>Data da última alteração</small>
                    <br />
                    {this.props.dataAlt}
                  </Col>
                  <Col>
                    <small>Orçamento Consumido</small>
                    <br />
                    {"R$ " + this.props.orcamentoConsumido}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="2">
            <Row>
              <Col className="text-center">
                <ul style={{ marginTop: "30px" }}>
                  <li>
                    <IconProjeto
                      id={`icon-config-${this.props.linha}`}
                      icon="cog"
                      tip="Configurar"
                      onClick={this.configurarCliente}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id={`icon-list-${this.props.linha}`}
                      icon="list"
                      tip="Listar Projetos"
                      onClick={this.listarProjetos}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id={`icon-del-${this.props.linha}`}
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItCli>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemRelatorio extends Component {
  render() {
    return (
      <ItCli linha={this.props.linha} status={this.props.status}>
        <Row>
          <Col sm="9">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Nome do Relatório</small>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col sm="3">
                    <small>Data</small>
                    <br />
                    {this.props.data}
                  </Col>
                  <Col>
                    <small>Versão</small>
                    <br />
                    {this.props.versao}
                  </Col>
                  <Col>
                    <small>Status</small>
                    <br />
                    <p className="status">{this.props.status}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="3">
            <Row>
              <Col className="text-center">
                <ul style={{ marginTop: "30px" }}>
                  <li>
                    <IconProjeto
                      id="icon-projeto"
                      icon="edit"
                      tip="Editar"
                      onClick={this.configurarProjeto}
                    />
                  </li>

                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Revisar"
                      onClick={this.resumo}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItCli>
    );
  }
}
export class ItemProjeto extends Component {
  configurarProjeto = () => {
    this.props.configurarProjeto(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  resumo = () => {
    this.props.resumo(this.props.linha);
  };
  excluir = () => {
    this.props.excluir(this.props.linha);
  };

  montaTela = () => {
    return (
      <ItCli linha={this.props.linha}>
        <Row>
          <Col sm="9">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Nome do Projeto</small>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col sm="3">
                    <small>Área de atuação</small>
                    <br />
                    {this.props.atuacao ? this.props.atuacao : "-"}
                  </Col>
                  <Col>
                    <small>Data de Realização</small>
                    <br />
                    {this.props.realizacao}
                  </Col>
                  <Col>
                    <small>Orçamento Total</small>
                    <br />
                    {"R$ " + this.props.orcamento}
                  </Col>
                </Row>
              </Col>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Gestor</small>
                    <br />
                    {this.props.gestor ? this.props.gestor : "-"}
                  </Col>
                  <Col sm="3">
                    <small>Participantes</small>
                    <br />
                    {this.props.participantes}
                  </Col>
                  <Col>
                    <small>Status</small>
                    <br />
                    {this.props.status}
                  </Col>
                  <Col>
                    <small>Orçamento Consumido</small>
                    <br />
                    {"R$ " + this.props.orcamentoConsumido}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="3">
            <Row>
              <Col className="text-center">
                <ul style={{ marginTop: "30px" }}>
                  <li>
                    <IconProjeto
                      id="icon-projeto"
                      icon="cog"
                      tip="Configurar"
                      onClick={this.configurarProjeto}
                    />
                  </li>

                  <li>
                    <IconProjeto
                      id="icon-grupo"
                      icon="grupo"
                      tip="Configurar Participantes"
                      onClick={this.configurarParticipantes}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Resumo"
                      onClick={this.resumo}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItCli>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemParticipanteDashboard extends Component {
  resumo = () => {
    this.props.resumo(this.props.linha);
  };
  montaTela = () => {
    return (
      <ItCli linha={this.props.linha}>
        <Row>
          <Col sm="9">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Nome do Projeto</small>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col sm="3">
                    <small>Área de atuação</small>
                    <br />
                    {this.props.atuacao ? this.props.atuacao : "-"}
                  </Col>
                  <Col>
                    <small>Data de Realização</small>
                    <br />
                    {this.props.realizacao}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="3">
            <Row>
              <Col className="text-center">
                <ul style={{ marginTop: "30px" }}>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Resumo"
                      onClick={this.resumo}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItCli>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemParticipanteModulos extends Component {
  resumo = () => {
    this.props.resumo(this.props.linha);
  };
  montaTela = () => {
    return (
      <ItCli linha={this.props.linha}>
        <Row>
          <Col sm="9">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Nome do Projeto</small>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col>
                    <small>Data de Realização</small>
                    <br />
                    {this.props.realizacao}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="3">
            <Row>
              <Col className="text-center">
                <ul style={{ marginTop: "30px" }}>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Resumo"
                      onClick={this.resumo}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItCli>
    );
  };
  render() {
    return this.montaTela();
  }
}

const ItParticipanteProjeto = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "white" : "transparent")};
  width: 100%;
  padding: 20px;
  .foto {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    border: solid 1px #fff;
    -webkit-box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.75);
    float: left;
    background: url(${props => props.background});
    background-size: cover;
    background-position: center center;
  }
  .padd {
    padding-top: 30px;
  }
`;

const Del = styled.button`
  border: 0px;
  background: transparent;

  :focus {
    outline: none;
  }
  img {
    width: 30px;
  }
`;

export class ItemParticipanteDinamica extends Component {
  analisarExclusao = () => {
    this.props.analisarExclusao(this.props.participante);
  };
  callFotoParticipante = () => {
    this.props.callFotoParticipante(this.props.participante._id);
  };
  onClick = () => {
    /*
    this.props.atualizarParticipante(this.props.cpf);
    */
  };

  getFiles = files => {};
  render() {
    // status do participante
    let status = this.props.status;
    if (typeof status === "undefined" || typeof status === null)
      status = "NOVO";
    else if (status === false) status = "OFF";
    else if (status) status = "ON";

    // checkbox checked prop
    let checked = false;
    if (this.props.vincular) {
      if (this.props.vincular.find(v => v === this.props.participante._id))
        checked = true;
    } else if (this.props.desvincular) {
      if (this.props.desvincular.find(v => v === this.props.participante._id))
        checked = true;
    }

    return (
      <ItParticipanteProjeto linha={this.props.linha}>
        <Row>
          <Col sm="12">
            <Row>
              <Col
                sm="1"
                style={{ paddingTop: "20px" }}
                className="text-center"
              >
                <input
                  type="checkbox"
                  onChange={this.props.onChange}
                  checked={checked}
                />
              </Col>
              <Col sm="2">
                <img
                  // src={require("./images/" + this.props.foto)}
                  id={`foto-${this.props.participante._id}`}
                  src={
                    this.props.foto
                      ? this.props.foto
                      : require("./images/user.gif")
                  }
                  alt={this.props.cliente}
                  className="foto"
                />
              </Col>
              <Col sm="2">
                <span onClick={this.onClick}>{this.props.nome}</span>
              </Col>
              <Col sm="1">
                <span onClick={this.onClick}>{status}</span>
              </Col>
              <Col sm="3">
                <span>{this.props.cpf}</span>
              </Col>
              <Col sm="3">
                <span>{this.props.email}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItParticipanteProjeto>
    );
  }
}
export class ItemParticipanteProjeto extends Component {
  analisarExclusao = () => {
    this.props.analisarExclusao(this.props.participante);
  };
  callFotoParticipante = () => {
    this.props.callFotoParticipante(this.props.participante._id);
  };
  onClick = () => {
    this.props.atualizarParticipante(this.props.cpf);
  };

  getFiles = files => {};
  render() {
    let hierarquiaOptions;
    if (this.props.hierarquia) {
      hierarquiaOptions = this.props.hierarquia.map(cargo => (
        <option key={cargo.cargo}>{cargo.cargo}</option>
      ));
    }
    return (
      <ItParticipanteProjeto
        linha={this.props.linha}
        background={
          this.props.foto ? this.props.foto : require("./images/user.gif")
        }
      >
        <Row>
          <Col sm="12">
            <Row>
              {/* FOTO */}
              <Col>
                <img
                  //src={require("./images/" + this.props.foto)}
                  id={`foto-${this.props.participante._id}`}
                  alt={this.props.cliente}
                  className="foto"
                />
              </Col>
              {/* NOME */}
              <Col sm="2" className="padd">
                <span onClick={this.onClick}>{this.props.nome}</span>
              </Col>
              {/* CPF */}
              <Col sm="1" className="padd">
                <span>{this.props.cpf}</span>
              </Col>
              {/* EMAIL */}
              <Col sm="3" className="padd">
                <span>{this.props.email}</span>
              </Col>
              {/* CARGO */}
              <Col sm="2">
                <select
                  className="form-control"
                  name={`cargo-${this.props.cpf}`}
                  onChange={this.props.handleCargoSelectChange}
                  value={this.props.cargo}
                  required
                >
                  <option value="">Selecione</option>
                  {hierarquiaOptions}
                </select>
              </Col>
              <Col sm="1" className="padd">
                <Del onClick={this.analisarExclusao} type="button">
                  <IconLarge icon="deletered" />
                </Del>
              </Col>
              <Col sm="1" className="padd">
                <Del onClick={this.callFotoParticipante} type="button">
                  <div id="btn-foto-hold" style={{ display: "none" }}>
                    <FileBase64
                      multiple={false}
                      onDone={this.props.onDone}
                      accept="image/*"
                    />
                  </div>
                  <IconLarge icon="foto" />
                </Del>
              </Col>
              <Col sm="1" className="padd">
                <Del onClick={this.props.editar} type="button">
                  Editar
                </Del>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItParticipanteProjeto>
    );
  }
}

export default class Assets extends Component {
  render() {
    return <div />;
  }
}

export const Adicionar = styled.p`
  font-size:${props => (props.fontSize || "10px")};
  font-weight: bold;
  padding-top: ${props => (props.paddingTop || "35px")};
  text-decoration: underline;
  cursor: pointer;
`;

export const AdicionarCliente = styled.span`
  font-size: 10px;
  font-weight: bold;
  padding-top: 20px;
  text-decoration: underline;
  cursor: pointer;
`;

export const URL = styled.div`
  font-size: 12px;
  span {
    font-weight: bold;
  }
  img {
    width: 18px;
  }
`;

export class Link extends Component {
  render() {
    return (
      <URL>
        <span>Link de Acesso: </span> {this.props.url}
        <br />
        <span>Enviar Link Por Email</span>
        <br />
        <span>Copiar Link</span>
      </URL>
    );
  }
}

const Ico2 = styled.span`
  border: 0px;
  background: transparent;
  border-radius: 5px;
  img {
    width: 15px;
    filter: ${props => (props.active ? "invert(1)" : "invert(0)")};
    margin-left: 10px;
    margin-right: 5px;
  }
  :focus {
    outline: none;
  }
`;
const Ico3 = styled.span`
  border: 0px;
  background: transparent;
  border-radius: 5px;
  img {
    width: 30px;
    filter: ${props => (props.active ? "invert(1)" : "invert(0)")};
    margin-left: 10px;
    margin-right: 5px;
  }
  :focus {
    outline: none;
  }
`;
export const Stat = styled.div`
  button {
    border: 0px;
    background: ${props => (props.active ? "#232638" : "white")};
    color: ${props => (props.active ? "white" : "#232638")};
    border-radius: 30px;
    text-align: left;
    margin-right: 20px;
    margin-bottom: 10px;
    height: 30px;
    padding-right: 40px;
    font-size: 12px;
    :focus {
      outline: 0;
    }
    :hover {
      background: ${props => (props.active ? "white" : "#232638")};
      color: ${props => (props.active ? "#232638" : "white")};
      ${Ico2} {
        background: ${props => (props.active ? "white" : "#232638")};
        img {
          filter: ${props => (props.active ? "invert(0)" : "invert(1)")};
        }
      }
    }
  }
`;
export class Icon extends Component {
  render() {
    return (
      <Ico2 active={this.props.active}>
        <img
          src={require("./images/" + this.props.icon + ".gif")}
          alt="configurar"
        />
      </Ico2>
    );
  }
}
export class IconLarge extends Component {
  render() {
    return (
      <Ico3 active={this.props.active}>
        <img
          src={require("./images/" + this.props.icon + ".gif")}
          alt="configurar"
        />
      </Ico3>
    );
  }
}
export class StatusButton extends Component {
  navegacao = () => {
    switch (this.props.valor) {
      case "Configuração":
        this.props.history.push("configurar");
        break;
      case "Participantes":
        this.props.history.push("participantes");
        break;
      case "Rodadas":
        this.props.history.push("rodadas");
        break;
      case "Grupos":
        this.props.history.push("grupos");
        break;
      case "Acompanhar":
        this.props.history.push("acompanhar");
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <Stat active={this.props.active}>
        <button onClick={this.navegacao} active={this.props.active}>
          <Icon active={this.props.active} icon={this.props.icon} />{" "}
          {this.props.valor}
        </button>
      </Stat>
    );
  }
}

const CP2 = styled.div`
  .pad {
    padding-top: 5px;
  }
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 5px;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const CP = styled.div`
  border-bottom: solid 1px #cdd8dc;
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 5px;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const CPA = styled.div`
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 5px;
    width: 100%;
    :focus {
      outline: 0;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const TextArea = styled.textarea`
  border: 0px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  height: 90px;
  border-radius: 10px;
  :focus {
    outline: 0;
  }
`;
export class Campo extends Component {
  montaTela = () => {
    if (this.props.tipo === "text") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <InputMask
              type="text"
              name={this.props.name}
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              mask={this.props.mask}
              value={this.props.value}
              required={this.props.required}
              disabled={this.props.disabled}
            />
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "textInline") {
      return (
        <div>
          <CP>
            <Row>
              <Col sm={this.props.labelSize}>
                <small>{this.props.label}</small>
              </Col>
              <Col>
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  disabled={this.props.disabled}
                />
              </Col>
            </Row>
          </CP>
        </div>
      );
    } else if (this.props.tipo === "realizacao") {
      return (
        <div>
          <CP2>
            <Row>
              <Col className="pad" sm="3">
                <small>{this.props.label}:</small>
              </Col>
              <Col>
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  disabled={this.props.disabled}
                  style={{ borderBottom: "solid 1px black" }}
                />
              </Col>

              <Col className="pad" sm="1">
                <small>
                  <b>à</b>
                </small>
              </Col>
              <Col>
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  disabled={this.props.disabled}
                  style={{ borderBottom: "solid 1px black" }}
                />
              </Col>
            </Row>
          </CP2>
        </div>
      );
    } else if (this.props.tipo === "date") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="date" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "time") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="time" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="time"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "email") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="email" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="email"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "telefone") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="tel" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="tel"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "code") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="code" />
                </span>
              </Col>
              <Col sm="3">
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  readOnly
                />
              </Col>
              <Col>
                <span>
                  <small onClick={this.props.gerarCodigo}>Gerar Código</small>
                </span>
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "textArea") {
      return (
        <div>
          <CPA>
            <small>{this.props.label}</small>
            <br />
            <TextArea
              type="text"
              name={this.props.name}
              onChange={this.props.onChange}
              placeholder={this.props.placeholder}
              value={this.props.value}
              required={this.props.required}
            />
          </CPA>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "salario") {
      return (
        <div>
          <CP>
            <small>{this.props.label}</small>
            <br />
            <InputMask
              type="number"
              name={this.props.name}
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              mask={this.props.mask}
              value={this.props.value}
              required={this.props.required}
              disabled={this.props.disabled}
            />
          </CP>
          <br />
          <br />
        </div>
      );
    }
  };
  render() {
    return <div>{this.montaTela()}</div>;
  }
}

export const Button = styled.button`
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  margin: 10px;
  padding: 10px 50px 10px 50px;
`;
export const ButtonSave = styled.button`
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px 50px 10px 50px;
  margin: 10px;
  :focus {
    outline: 0;
  }
`;
export const ButtonCancel = styled.button`
  background: #db0308; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #db0308 0%,
    #c10a0f 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #db0308 0%,
    #c10a0f 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #db0308 0%,
    #c10a0f 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db0308', endColorstr='#c10a0f',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px 50px 10px 50px;
  :focus {
    outline: 0;
  }
  :disabled {
    background: linear-gradient(
      to bottom,
      #e38486 0%,
      #bf7c7e 100%
    );
  }
`;

export const Select = styled.select`
  background: #c4c5ca; /* Old browsers */
  color: white;
  border: 0;
  border-radius: 5px;
  height: 30px;
  width: 100%;
  padding: 20px;
`;

const SR = styled.div`
  background: white;
  display: inline-block;
  padding: 10px;
  border-radius: 20px;
  width: 320px;
  input {
    border: 0px;
    background: transparent;
    width: 250px;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: lightgray;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: lightgray;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: lightgray;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: lightgray;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

export class StatusAssinc extends Component {
  render() {
    return (
      <div style={{ padding: 5, border: "solid 1px black" }}>
        <Row>
          <Col className="text-center">
            <small>
              <b>Realizados: </b> {this.props.iniciados}/{this.props.total}
            </small>
          </Col>
          <Col className="text-center">
            <small>
              <b style={{ color: "green" }}>Finalizados: </b>{" "}
              {this.props.finalizador}/{this.props.total}
            </small>
          </Col>
          <Col className="text-center">
            <small>
              <b style={{ color: "red" }}>Expirados: </b> {this.props.expirados}
              /{this.props.total}
            </small>
          </Col>
        </Row>
      </div>
    );
  }
}
export const Search = props => (
  <SR>
    <span>
      <Icon icon="search" />
    </span>{" "}
    <input
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
    />
    {/* INPUT MASK para busca de projeto por data ao invés de nome }
    {props.buscaData ?
      <InputMask
        mask="99/99/9999"
        placeholder="xx/xx/xxxx"
        onChange={props.onChange} />
      :
      <input
        onChange={props.onChange}
        placeholder={props.placeholder} />}
    {*/}
  </SR>
);

const ADV = styled.div`
  width: 100%;
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: withe;
    border: 0px;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    :focus {
      outline: 0;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const BaseTime = styled.div`
  input {
    background: #f7f8f8;
    border: 0px;
    width: 100%;
    height: 30px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  small {
    font-weight: bold;
  }
`;

const ListaSugestion = styled.div`
  ul {
    background: lightgray;
    padding: 10px;
    margin-top: -20px;
  }
  li {
    list-style: none;
  }
`;

let escolhas = [];

export class AdvancedSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      es: [],
      name: "",
      check: false
    };
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;

    const userInput = e.currentTarget.value;

    this.setState({ name: e.currentTarget.value });

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  /*// Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      es: escolhas
    });
    if (escolhas.length != 0) {
      for (var i = 0; i < escolhas.length; i++) {
        console.log(this.state.filteredSuggestions[0], escolhas[i]);
        if (this.state.filteredSuggestions[0] !== escolhas[i]) {
          console.log(escolhas);
          let selecao = this.props.selecao(this.state.filteredSuggestions[0]);
          if (selecao) escolhas.push(selecao);
          return escolhas;
        }
      }
    } else {
      console.log(escolhas);
      let selecao = this.props.selecao(this.state.filteredSuggestions[0]);
      if (selecao) escolhas.push(selecao);
      return escolhas;
    }

    this.setState({ name: "" });
  };*/

  // Event fired when the user presses a key down
  /*onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions

    // if (e.keyCode === 13) {
    //   console.log(this.state.userInput)
    //  if(this.state.userInput!==""){
    //   this.setState({
    //     activeSuggestion: 0,
    //     showSuggestions: false,
    //     userInput: filteredSuggestions[activeSuggestion],
    //     es:escolhas
    //   });
    //   if(escolhas.length!=0){
    //     for(var i=0;i<escolhas.length;i++){
    //       if(this.state.filteredSuggestions[activeSuggestion]!==escolhas[i]){
    //         escolhas.push(this.state.filteredSuggestions[activeSuggestion])
    //         console.log(escolhas)
    //       }
    //     }
    //   }else{
    //     escolhas.push(this.state.filteredSuggestions[activeSuggestion])
    //     console.log(escolhas)
    //   }
    //  }
    //  this.setState({name:""})

    // }
    // // User pressed the up arrow, decrement the index
    // else if (e.keyCode === 38) {
    //   if (activeSuggestion === 0) {

    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion - 1 });

    // }
    // // User pressed the down arrow, increment the index
    // else if (e.keyCode === 40) {
    //   if (activeSuggestion - 1 === filteredSuggestions.length) {
    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion + 1 });

    // }
  };*/

  deleta = valor => {
    escolhas.splice(valor, 1);
    this.setState({ es: escolhas });
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ListaSugestion>
            <ul className="suggestions">
              {filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                return (
                  <li className={className} key={suggestion} onClick={onClick}>
                    {suggestion}
                  </li>
                );
              })}
            </ul>
          </ListaSugestion>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Não Existem dados cadastrados</em>
          </div>
        );
      }
    }

    return (
      <BaseTime>
        <Row>
          <Col sm="7">
            <small>{this.props.label}</small>
            <br />
            <input
              type="text"
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={userInput}
              value={this.state.name}
            />
            {suggestionsListComponent}
          </Col>
          <Col>
            {this.state.es.map((escolha, index) => (
              <User onClick={() => this.deleta(index)} key={index}>
                <Icon icon="delete" />
                {escolha}
              </User>
            ))}
          </Col>
        </Row>
      </BaseTime>
    );
  }
  // render(){
  //   return(

  // <Row>
  //   <Col>
  //     <ADV>
  //       <small>{this.props.label}</small><br/>
  //       <input></input>
  //     </ADV>
  //   </Col>
  //   <Col>
  //     <ADV>
  //       <small>{this.props.label}</small><br/>
  //       <input></input>
  //     </ADV>
  //   </Col>
  // </Row>
  //   )
  // }
}

export const Titulo = styled.h2`
  color: "#232638";
`;
export const Titulo2 = styled.h3`
  color: "#232638";
  font-weight: bold;
  font-size: 20px;
`;
export const Titulo3 = styled.h4`
  color: "#232638";
  font-weight: bold;
  font-size: 12px;
`;
export const SimpleButton = styled.button`
  border: 0px;
  background: ${props => (props.active ? "#232638" : "#f7f9f8")};
  color: ${props => (props.active ? "#f7f9f8" : "#232638")};
  border-radius: 30px;
  margin-right: 20px;
  margin-bottom: 10px;
  height: 30px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  text-align: center;
  :focus {
    outline: 0;
  }
`;

export const BaseModal = styled.div`
  padding: 20px;
`;

export const ButtonCollapse = styled.button`
  background: transparent;
  border: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #000;
  font-weight: bold;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;
  :focus {
    outline: 0;
  }
`;

export const User = styled.button`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 5px 40px 5px 0px;
  font-weight: bold;
  font-size: 12px;
  color: #002a4b;
  margin-right: 10px;
  margin-bottom: 10px;
  :focus {
    outline: 0;
  }
`;
export const UserDrag = styled.div`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #002a4b;
  margin-right: 10px;
  margin-bottom: 10px;
  :focus {
    outline: 0;
  }
  width: 100%;
  cursor: grab;
  button {
    background: transparent;
    border: 0px;
  }
`;

export const UserHierarquia = styled.div`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #002a4b;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  :focus {
    outline: 0;
  }
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  button {
    background: transparent;
    border: 0px;
  }
  /* Popover styling */

  a {
    text-decoration: none;
  }

  .popover__title {
  }

  .popover__wrapper {
    position: relative;

    display: inline-block;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin-left: 28px;
    transform: translate(0, 10px);
    background-color: #252638;
    padding: 1.5rem;
    color: white;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
  a {
    color: white;
  }
`;

export const UserStep = styled.div`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 11px;
  color: #002a4b;
  margin-bottom: 10px;
  text-align: center;
  :focus {
    outline: 0;
  }
  width: 200px;
  button {
    background: transparent;
    border: 0px;
  }
`;

export const Small = styled.small`
  font-weight: bold;
  width: 100%;
`;

export const FotosImport = styled.div``;

export const PopOver = styled.div`
  .popover__title {
  }

  .popover__wrapper {
    position: relative;
    width: 300px;
    display: inline-block;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin-left: 98px;
    transform: translate(0, 10px);
    background-color: #252638;
    padding: 1.5rem;
    color: white;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
`;

export const PopOverGrupos = styled.div`
  .popover__title {
  }

  .popover__wrapper {
    position: relative;
    width: 80px;
    display: inline-block;
  }
  .popover__wrapper {
    position: relative;
    width: 80px;
    display: inline-block;
    padding-top: 5px;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin-left: 38px;
    transform: translate(0, 10px);
    background-color: #252638;
    padding: 1.5rem;
    color: white;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
  p {
    cursor: pointer;
  }
`;

export const Play = styled.button`
  background: #008b30;
  padding: 10px 40px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  color: white;
`;

export const Pause = styled.button`
  background: #fec400;
  padding: 10px 40px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  color: white;
`;

export const Action = styled.button`
  background: #fec400;
  padding: 10px 40px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  color: white;
`;

const StatusCon = styled.div`
  .status {
    width: 10px;
    height: 10px;
    background: green;
    border-radius: 10px;
  }
`;

export class StatusOnline extends Component {
  render() {
    return (
      <StatusCon>
        <div className="status" />
        <span>{this.props.status}</span>
      </StatusCon>
    );
  }
}

export class NomeCliente extends Component {
  render() {
    return (
      <div>
        <span style={{ fontSize: 10, color: "lightgray" }}>Nome:</span>
        <h2>{this.props.nome}</h2>
      </div>
    );
  }
}

export const Certifique = styled.div`
  padding: 40px;
  border: 1px solid blue;
  text-align: left;

  i {
    color: #00a8b2;
  }
  small {
    font-weight: bold;
  }
`;

// ESTILOS DE LIDERANÇA ASSINCRONO
export const Qst = styled.div`
  background: ${(props) => (props.active ? '#fac100' : '#00babe')};
  color: white;
  padding: 10px 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  float: left;
  font-size: 14px;

  .next {
    background: lightgray;
  }
`;
