import React from 'react';
import styled from 'styled-components'

const StyledH5 = styled.h5`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
`
export default (props) => {

  return (
    <StyledH5
      {...props}>
      {props.children}
    </StyledH5>
  )
}
