import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel } from '../../Pages/Assets/Assets';

function InstructionsModal(props) {
  return(
    <Modal
      size={props.size || 'md'}
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
    >
      <ModalHeader toggle={() => props.toggle()}>
        { props.modalHeader }
      </ModalHeader>
      <ModalBody>
        { props.modalBody }
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={ () => {
        props.onCancel()
      } }>Fechar</ButtonCancel>
      </ModalFooter>
    </Modal>
  );
}

export default InstructionsModal;