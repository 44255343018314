import React, { Component } from "react";
import api from "Services/api";
import * as draftToHtml from "draftjs-to-html";
import {
  Col,
  Row,
  Container
} from "reactstrap";
import {
  Base,
  Textarea,
  Button,
  Footer
} from "./Assets/Assets";
import { Constantes } from "./Services/Constantes";
import Loading from "../../../Services/Loading";
import fetchInstrumentoCase from './Services/fetch-instrumento-case';

import styled from "styled-components";
import { withRouter } from 'react-router';

import './styles.css';

// Imagens
const spinner = Constantes.imagemSpinner;

const P = styled.p`
  font-size: 13px;
`;

String.prototype.cleanup = function() {
  return this.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
};

class Case extends Component {

  state = {
    loading: false,
    template: {
      nomeCase: ''
    },
    textoIndividual: '',
    criterios: [0, 0, 0]
  }


  configTemplate = async () => {
    const textIndex = new URLSearchParams(this.props.location.search).get("textIndex");
    const idAtividade = new URLSearchParams(this.props.location.search).get("idAtividade");
    const rodada = new URLSearchParams(this.props.location.search).get("roundIndex");
    const idInstrumento = new URLSearchParams(this.props.location.search).get("instrumentId");

    const instrumento = await fetchInstrumentoCase({ idAtividade, rodada, idInstrumento });
    const caseTemplateConfigId = instrumento.info;
    const caseConfigResponse = await api.get(`/v3/case-config/${caseTemplateConfigId}`);
    const infoCase = caseConfigResponse.data;
    const { textos } = infoCase;

    let textoIndividual = textos[textIndex].individual;

    if(typeof (textoIndividual) !== 'string') textoIndividual = draftToHtml(textoIndividual);

    this.setState({ textoIndividual, template: infoCase });
  }

  componentDidMount = async () => {
    await this.configTemplate();
  }

  render() {
    if (this.state.loading) return <Loading />;

    return (
      <div style={{ width: '100%' }} className="text-left back" tabIndex="0">
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Case</h1>
              </Col>
            </Row>
            <br />
            <br />
            <h1>{this.state.template.nomeCase}</h1>
            {/*<div
              dangerouslySetInnerHTML={{
                __html: this.state.template.template.texto
              }}
            />*/}

            <div
              className='responsive-image'
              dangerouslySetInnerHTML={{
                __html: (this.state.textoIndividual)
              }}
            />

            <Col sm="12" className="text-left">
              {" "}
              <br />
              <br />
              <h2>Defina os Critérios Abaixo:</h2>
              {this.state.criterios.map((criterio, index) => (
                <div key={index}>
                  {`0/${
                    Constantes.criteriosTamanho
                  }`}
                  <br />
                  <Textarea
                    placeholder="Insira um critério de decisão"
                    name={index + 1}
                    key={index}
                    onChange={()=>{}}
                    onBlur={()=>{}}
                    onFocus={()=>{}}
                    value={''}
                  />
                  <br />
                </div>
              ))}
            </Col>
            <Container className="text-center">
              <Button color="success" onClick={()=>{}}>
                {" "}
                Enviar{" "}
              </Button>
            </Container>
            <Footer />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(Case);
