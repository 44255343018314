import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router'
import { FcBusinessman, FcOrganization } from 'react-icons/fc';
import styled from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel } from "views/Pages/Assets/Assets";
import apiCalls from "config/apiCalls";
import { notifyError } from 'Services/Notificacoes';
import { Constantes } from 'Services/Constantes';

const DivIconsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const DivIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 150px;
  padding: 15px;
  cursor: pointer;
  background:#f1f1ee;

  &:hover {
    background: #aaae98;
    color: #fff;
  }
`;

const IconSpan = styled.span`
  font-size: 14px;
  margin-top: 7px;
`

const DivWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

function ModalEscolherAmbiente(props) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [rememberRole, setRememberRole] = useState(false);
  const [licenseAgreement, setLicenseAgreement] = useState(false);
  const [roleSelected, setRoleSelected] = useState('');

  useEffect(() => {
    setRememberRole(!!sessionStorage.getItem('rememberRole'));
  }, [sessionStorage.getItem('rememberRole')]);

  useEffect(() => {
    setRoleSelected(sessionStorage.getItem('roleSelected'));
  }, [sessionStorage.getItem('roleSelected')]);

  async function updateLoginAs(role, checkValue={}) {
    const value = checkValue.useThisValue ? checkValue.value : rememberRole;

    if (!value) {
      await apiCalls.users.updateUserLoginAs(sessionStorage.getItem('user'), '');
      sessionStorage.setItem("rememberRole", '');
      sessionStorage.setItem("roleSelected", role);
      setRoleSelected(role);
      return;
    }

    if (value && role && role.toLowerCase().includes(Constantes.funcaoConsultor)) {
      await apiCalls.users.updateUserLoginAs(sessionStorage.getItem('user'), Constantes.funcaoConsultor);
      sessionStorage.setItem("rememberRole", Constantes.funcaoConsultor);
    }
    else if (value && role && role.toLowerCase().includes(Constantes.funcaoParticipante)) {
      await apiCalls.users.updateUserLoginAs(sessionStorage.getItem('user'), Constantes.funcaoParticipante);
      sessionStorage.setItem("rememberRole", Constantes.funcaoParticipante);
    }

    setRoleSelected(role);
    sessionStorage.setItem("roleSelected", role);
  }

  async function accessConsultantRole() {
    try {
      if (loading) return;
      setLoading(true);

      await updateLoginAs(Constantes.funcaoConsultor);

      if (roleSelected && roleSelected.toLowerCase().includes(Constantes.funcaoConsultor)) return props.toggle();

      const userCpf = sessionStorage.getItem('userCpf');

      let acceptedLicense = false;

      if (props.verifyAddress) {
        const haveAddress = await props.verifyAddress(sessionStorage.getItem('user'), userCpf, props.senha);
        if (!haveAddress) {
          return props.toggleRegisterModal(userCpf);
        }
      } else {
        const res = await apiCalls.users.verifyContract(sessionStorage.getItem('user'));
        const { licenseAgreement: resLicenseAgreement } = res.data;

        setLicenseAgreement(resLicenseAgreement);
        acceptedLicense = resLicenseAgreement;
        sessionStorage.setItem('licenseAgreement', licenseAgreement);
      }

      if (props.onLogin && !props.licenseAgreement) {
        props.toggleContractModal();
      } else if (props.isParticipant && !acceptedLicense) {
        setRoleSelected(Constantes.funcaoParticipante);
        props.toggleContractModal();
      } else {
        sessionStorage.setItem('isPlayer', '');
        await apiCalls.logs.createUserLoginLog({ userCpf, userId: sessionStorage.getItem('user'), role: 'gestão consultor' });
        history.push("/dashboard");
        window.location.reload();
      }

    } catch (err) {
      console.error(err);
      notifyError('Não foi possível se logar no ambiente escolhido. Por favor, recarregue a página e tente novamente');
    } finally {
      setLoading(false);
    }
  }

  async function accessParticipantRole() {
    try {
      if (loading) return;

      setLoading(true);
      await updateLoginAs(Constantes.funcaoParticipante);

      if (roleSelected && roleSelected.toLowerCase().includes(Constantes.funcaoParticipante)) return props.toggle();

      sessionStorage.setItem('isPlayer', true);
      await apiCalls.logs.createUserLoginLog({ userCpf: sessionStorage.getItem('userCpf'), userId: sessionStorage.getItem('user'), role: 'gestão participante' });
      history.push("/participante/dashboard");
      window.location.reload();
    } catch (err) {
      console.error(err);
      notifyError('Não foi possível se logar no ambiente escolhido. Por favor, recarregue a página e tente novamente');
    } finally {
      setLoading(false);
    }
  }

  async function onCheckChange(e) {
    try {
      const value = e.target.checked;
      if (props.alreadyLogged) {
        const check = value ? roleSelected : '';

        const obj = {
          useThisValue: true,
          value: check
        }

        await updateLoginAs(roleSelected, obj)
      }

      setRememberRole(value);
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Modal
      size="lg"
      toggle={props.toggle}
      isOpen={props.isOpen}
      centered
    >
      <ModalHeader toggle={props.toggle}>
        Escolher ambiente
      </ModalHeader>
      <ModalBody style={{ fontSize: 12 }}>
      Você tem acesso a dois ambientes no sistema: <br />
      (i) CONSULTOR – Área onde você cria os processos, aplica os instrumentos e gera os relatórios <br />
      (ii) PARTICIPANTE – Área onde estão as atividades e resultados que você, como participante de um processo, está vinculado <br />
        <DivIconsWrapper>
          <DivIcon onClick={accessConsultantRole}>
            <FcOrganization className='icon-select-role' size={180} />
            <IconSpan>Ambiente Consultor</IconSpan>
          </DivIcon>
          <DivIcon onClick={accessParticipantRole}>
            <FcBusinessman className='icon-select-role' size={180} />
            <IconSpan>Ambiente Participante</IconSpan>
          </DivIcon>
        </DivIconsWrapper>
        <DivWrapper>
          <input type='checkbox' onChange={onCheckChange} checked={rememberRole} value={roleSelected}/>
          <span style={{ marginLeft: 5 }}>Lembrar ambiente no próximo login</span>
        </DivWrapper>
      </ModalBody>
      <ModalFooter>

      </ModalFooter>
    </Modal>
  );
}

export default ModalEscolherAmbiente;