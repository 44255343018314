import ReportSocketConnection from './ReportSocketConnection';

const reportsSocketURL = process.env.REACT_APP_REPORTS_SOCKET_URL;

const ReportProgressAPI = {

  socket: ReportSocketConnection.getConnection(reportsSocketURL),
  channels: {
    GET_PROCESSING: 'getProcessing',
    CHECK_VERSION: 'checkVersion',
    PRE_VISUALIZATION_DONE: 'preVisualizationDone',
    PDF_DONE: 'pdfDone'
  },
}

export default ReportProgressAPI;