import { normalizarPorMaximo } from 'Services/utilsFn';

const BACKGROUND_COLORS = [
  '#ff0000',
  '#dd5500',
  '#aaaa00',
  '#77dd00',
  '#55ee00',
  '#00ff00',
];

const getData = (
  {
    respondentes,
    resultado,
    isConsultor
  }
) => {
  const respondentesFiltrados = respondentes.filter(
    respondente => respondente.resultado,
  );
  return [
    {
      name: 'Autoavaliação',
      nota: normalizarPorMaximo(198 - 21, resultado.somaBruta - 21),
      graficLowLabel: 'Baixa articulação',
      graficHighLabel: 'Alta articulação'
    },
  ].concat(
    respondentesFiltrados.map(respondente => ({
      name: respondente.relacao,
      nota: normalizarPorMaximo(
        198 - 21,
        respondente.resultado.somaBruta - 21,
      ),
    })),
  );
};

const graphConfig = () => ({
  type: 'horizontalBar',
  data: {
    labels: new Array(100).fill(0).map((_n, i) => i + 1)
  },
  options: {
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'lines-y-axis',
          value: 0,
          borderColor: "#808386",
          backgroundColor: "#808386",
          borderWidth: 2,
        },
      ]
    },
    plugins: {
      datalabels: {
        display: false,
        color: 'black',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: true,
          stacked: false,
          gridLines: {
            display: false,
            drawBorder: true,
          },
          ticks: {
            display: false,
            fontColor: 'black',
            fontSize: 11,
            fontFamily: '"Montserrat", sans-serif',
            max: 100,
            min: 0,
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
            align: 'start',
            stepSize: 1,
          }
        },
        {
          id: "bar-x-axis",
          display: true,
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: true,
          },
          ticks: {
            fontColor: 'black',
            fontSize: 11,
            fontFamily: '"Montserrat", sans-serif',
            max: 100,
            min: 0,
            maxRotation: 0,
            minRotation: 0,
            autoSkip: false,
            align: 'start',
            stepSize: 1,
            callback: function(value, index, ticks) {
              if (value === 7) return 'Baixa Articulação';
              if (value === 93) return 'Alta Articulação';
            },
          }
        },
      ],
      yAxes: [
        {
          id: "bar-y-axis",
          barThickness: 25,
          align: 'start',
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: true,
          },
          ticks: {
            display: true,
            fontColor: 'black',
            fontFamily: '"Montserrat", sans-serif',
            fontSize: 11,
            min: 0,
            max: 2,
            stepSize: 1,
            callback: function(value, index, ticks) {
              if (value === 1) return 'Autoavaliação - ';
            },
          }
        },
        {
          id: "lines-y-axis",
          stacked: false,
          gridLines: {
            display: false,
            drawBorder: true,
          },
          ticks: {
            display: false,
            fontColor: 'black',
            fontFamily: '"Montserrat", sans-serif',
            fontSize: 11,
            min: 0,
            max: 2,
            stepSize: 1,
          }
        },
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Arte da articulação',
      fontColor: 'black'
    },
    tooltips: {
      enabled: false
    }
  }
});

function drawParticipant(props) {

  const data = getData({
    respondentes: props.respondentes,
    resultado: props.resultado,
    isConsultor: props.idConsultor,
  });
  props.chartInstance.options.scales.yAxes[0].ticks.max = data.length;
  props.chartInstance.options.scales.yAxes[0].ticks.callback = (value, index, ticks) => {
    if (value === 1) return 'AUTOAVALIAÇÃO - ';
    if (data[value - 1]?.name) return `${data[value - 1].name} - `;
  };

  let datasets = [];

  datasets.push({
    data: data.map((d, i) => {
      return {
        x: d.nota,
        y: i + 1
      }
    }),
    type: 'line',
    fill: false,
    showLine: false,
    backgroundColor: data.map(_d => '#000000'),
  });

  props.chartInstance.options.annotation.annotations.push({
    type: 'box',
    xScaleID: 'x-axis-0',
    yScaleID: 'bar-y-axis',
    xMin: data[0].nota - 5,
    xMax: data[0].nota + 5,
    borderColor: "transparent",
    backgroundColor: "rgba(190, 190, 190, 0.4)",
    borderWidth: 2,
  });
  props.chartInstance.update();

  datasets.push({
    data: new Array(data.length).fill(10),
    type: 'horizontalBar',
    backgroundColor: new Array(data.length).fill(BACKGROUND_COLORS[0]),
    barPercentage: 0.8,
    xAxisID: 'bar-x-axis'
  });

  datasets.push({
    data: new Array(data.length).fill(19),
    type: 'horizontalBar',
    backgroundColor: new Array(data.length).fill(BACKGROUND_COLORS[1]),
    barPercentage: 0.8,
    xAxisID: 'bar-x-axis'
  });

  datasets.push({
    data: new Array(data.length).fill(20),
    type: 'horizontalBar',
    backgroundColor: new Array(data.length).fill(BACKGROUND_COLORS[2]),
    barPercentage: 0.8,
    xAxisID: "bar-x-axis",
  });

  datasets.push({
    data: new Array(data.length).fill(20),
    type: 'horizontalBar',
    backgroundColor: new Array(data.length).fill(BACKGROUND_COLORS[3]),
    barPercentage: 0.8,
    xAxisID: "bar-x-axis",
  });

  datasets.push({
    data: new Array(data.length).fill(20),
    type: 'horizontalBar',
    backgroundColor: new Array(data.length).fill(BACKGROUND_COLORS[4]),
    barPercentage: 0.8,
    xAxisID: "bar-x-axis",
  });

  datasets.push({
    data: new Array(data.length).fill(11),
    type: 'horizontalBar',
    backgroundColor: new Array(data.length).fill(BACKGROUND_COLORS[5]),
    barPercentage: 0.8,
    xAxisID: "bar-x-axis",
  });
  return datasets;
}

export default {

  graphConfig,
  drawParticipant
}