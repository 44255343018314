import styled from "styled-components";

export const IappSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 25px;
  height: 10px;
  align-self: center;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;
