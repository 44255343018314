import React, { Component } from "react";
import { FaCog } from 'react-icons/fa';

// drag and drop component
import 'gridstack/dist/gridstack.css';
import { GridStack } from 'gridstack';
import 'gridstack/dist/h5/gridstack-dd-native';


// estilos
import styled from 'styled-components';
import './WidgetsAside.scss';

const WidgetsStack = styled.div`
color: #fff;

.grid-stack-item-content { background-color: #fff; color: #000; padding: 5px }
}
`

class WidgetAside extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
    }
  }

  toggleNav = () => {
    if (!this.state.navOpen) {
      document.getElementById("mySidenav").style.right = "0px";
      document.getElementById("dashboard").style.marginRight = "250px";
      document.getElementById("widgets-aside-open").style.marginRight = "250px";
    } else {
      document.getElementById("mySidenav").style.right = "-250px";
      document.getElementById("dashboard").style.marginRight = "0";
      document.getElementById("widgets-aside-open").style.marginRight = "0";
    }
    this.setState({
      navOpen: !this.state.navOpen
    });
  }

  componentDidMount() {
    const options = {
      dragIn: '.sidebar .grid-stack-item',
      dragInOptions: { revert: 'invalid', scroll: false, appendTo: 'body', helper: 'clone' },
      acceptWidgets: function (el) { return true; }
    };

    var grid = GridStack.init(options);

    grid.cellHeight(grid.cellWidth() * 0.5);

    let items = [
      {x: 0, y: 0, w: 1, h: 1, content: `
      <div class="grid-stack-item-header">
        <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Últimos Eventos</strong></div>
      </div>
      <div style={{ marginLeft: '5px', color: '#01294B' }}>Últimos eventos feitos no sistema.</div>`},
      {x: 0, y: 0, w: 1, h: 1, content: `
      <div class="grid-stack-item-header">
        <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Usuários Ativos</strong></div>
      </div>
      <div style={{ marginLeft: '5px', color: '#01294B' }}>Mostra os usuários que estão logados no sistema.</div>`},
      {x: 0, y: 0, w: 1, h: 1, content: `
      <div class="grid-stack-item-header">
        <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Meus Clientes</strong></div>
      </div>
      <div style={{ marginLeft: '5px', color: '#01294B' }}>Clientes vinculados ao meu usuário.</div>`},
    ];
    // grid.addWidget({content: })
    grid.load(items)
  }

  render() {
    return (
      <React.Fragment>
        <button id="widgets-aside-open" className="widgets-aside-open" onClick={() => this.toggleNav()}><FaCog /></button>
        <div id="mySidenav" class="sidenav">
          <WidgetsStack>
            <span style={{ marginLeft: '10px' }}>Widgets Disponíveis</span>
            {/* grid dos widgets */}
            <div class="grid-stack">
            </div>
          </WidgetsStack>
        </div>
      </React.Fragment>
    );
  }
}

export default WidgetAside;
