import React, { Component } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import AdaptiveSizeText from "../../Components/adaptive-text-size";
import PropTypes from "prop-types";
import "./css/Orderer.css";

/**
 * Um componente para ordenar tabelas.
 *
 * @export
 * @class Orderer
 * @extends {Component}
 */
export default class Orderer extends Component {
  static propTypes = {
    /** A função que implementa a ordenação */
    orderBy: PropTypes.func,
    /** A função que define se um valor é maior, menor ou igual */
    orderFn: PropTypes.func,
    /** `true` se essa instância está sendo usada para ordenar */
    active: PropTypes.bool,
    /** O componente que deve ser renderizado ao lado da flecha */
    children: PropTypes.oneOf(PropTypes.arrayOf(PropTypes.node), PropTypes.node)
  };

  static defaultProps = {
    active: false
  };

  state = {
    /** `true` se a próxima ordenação não é invertida */
    reversed: false,
    /** O número de vezes que o botão foi clicado enquanto ativo */
    count: 0
  };

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.active && !this.props.active) {
      this.setState({ reversed: false, count: 0 });
    }
  }

  componentDidMount() {
    if (this.props.active) {
      this.props.orderBy(this.props.orderFn, this.state.reversed);
    }
  }

  render() {
    return (
      <AdaptiveSizeText
        style={{ margin: 0, cursor: "pointer" }}
        onClick={() => {
          if (this.state.count === 0) {
            this.setState({
              count: 1
            });
            this.props.orderBy(this.props.orderFn, this.state.reversed);
          } else {
            this.props.orderBy(this.props.orderFn, !this.state.reversed);
            this.setState({ reversed: !this.state.reversed });
          }
        }}
      >
        {this.props.children}
        {this.props.active ? (
          this.state.reversed ? (
            <KeyboardArrowDown style={{ height: 17 }} />
          ) : (
            <KeyboardArrowUp style={{ height: 17 }} />
          )
        ) : (
          []
        )}
      </AdaptiveSizeText>
    );
  }
}
