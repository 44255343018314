import React, { Component } from 'react';
import { TourContext } from '@reactour/tour';
import { Tooltip } from 'react-tippy';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { AiOutlineDollar } from 'react-icons/ai';
import { FaRegFlag } from 'react-icons/fa'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppSidebarToggler } from '@coreui/react';
import styled from 'styled-components';
import Notification from 'components/Notification';
import ModalAlterarSenha from 'views/Components/modal-alterar-senha';
//import ModalEditarAcesso from 'views/Components/modal-editar-acesso';
import ModalEditarAcesso from 'views/Components/ModalEditarAcesso';
import ModalTrocaAmbiente from 'views/Components/modal-troca-ambiente';
import ModalEscolherAmbiente from 'views/Components/modal-escolher-ambiente';
import api from 'Services/api';
import apiCalls from "config/apiCalls";
import { notifyError } from 'Services/Notificacoes';
import ContractModal from "views/Components/ContractModal";
import { HeaderSelectBox } from "views/Pages/Assets/Assets";

import './styles.css';

export const DropdownWrapper = styled.div`
  .dropdown-menu {
    max-height: 300px;
    overflow: auto;
  }

  .dropdown-item {
    margin: 5px;
    background-color: #c9c9c9;

    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #000;
    font-size: 12px;
    width: auto;

    padding: 10px 5px;

    min-width: 200px;

    span {
      color: #000;
      cursor: default;
    }
  }

  /* .dropdown-item:hover {
    color: #000;
  } */

  .dropdown-header {
    color: #fff;
    font-weight: bold;
    background: transparent;
  }

  .dropdown-header {
    margin-bottom: 10px;
    /* border-bottom: none; */
  }

  .dropdown-item + .dropdown-header {
    margin-top: 10px;
    /* border-top: 1px solid #c8ced3; */
  }
`;

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  static contextType = TourContext;
  state = {
    oldPassword: '',
    passwordModal: false,
    modalEditarAcesso: false,
    loadingSpinner: false,
    nome:'',
    nomeConsultoria: '',
    email:'',
    celular:'',
    avatar: sessionStorage.getItem('userAvatar') || '',
    consultantLogo: '',
    cpf: sessionStorage.getItem('userCpf'),
    changeRoleModal: false,
    isConsultor: true,
    contractModal: false
  };

  mask = (o, f) => {
    setTimeout(function () {
      var v = f(o.value);
      if (v != o.value) {
        o.value = v;
      }
    }, 1);
  }

  mphone = (v) => {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }


  handleOldPasswordChange = e => {
    this.setState({ oldPassword: e.target.value });
  };

  togglePasswordModal = () => {
    this.setState(prev => ({ passwordModal: !prev.passwordModal }));
  };

  closePasswordModal = () => {
    this.setState({ passwordModal: false });
  };

  openPasswordModal = () => {
    this.setState({
      passwordModal: true,
      oldPassword: ''
    });
  }

  toggleModal = modalName => {
    this.setState(prev => {
      prev[modalName] = !prev[modalName];

      return prev;
    });
  };

  logout = async() => {
    await apiCalls.logs.createUserLogoutLog({ userCpf: sessionStorage.getItem("userCpf"), userId: sessionStorage.getItem("user"), role: 'gestão consultor' });

    sessionStorage.clear();

    this.props.history.push('/#/login');
  }

  updateUserState = (newState) => {
    this.setState({ ...newState });
  }

  editarConfig = async () => {
    const cpf = this.state.cpf;
    await api
      .get("api/user/0/user-ativo", {
        params: {
          cpf: cpf
        }
      })
      .then(response => {
        const user = response.data.dadosGestaoParticipante;
        const email = response.data.ultimoEmailAtivo;

        this.props.updateAsideState({
          consultantLogo: user.consultantLogo,
        });

        this.setState({
          nome: user.nome,
          nomeConsultoria: user.nomeConsultoria,
          email: email,
          celular: user.celular,
          cpf: user.cpf,
          avatar: user.avatar,
          consultantLogo: user.consultantLogo,
          modalEditarAcesso: true
        });

        // logout realizado
      })
      .catch(err => {
        console.error(err);
        if (err.response && err.response.data.error) {
          notifyError(err.response.data.error);
        } else {
          notifyError("Não foi possível salvar o perfil. Por favor, recarregue a página e tente novamente");
          this.setState({
            modalEsqueciSenha: false,
            esqueciCpf: "",
            esqueciEmail: "",
            displayEtapa1: "block",
            displayEtapa2: "none"
          });
        }
      });
    };

    handleChange = e => {
      if (e.target.name === 'celular') this.mask(e.target, this.mphone);
      this.setState({ [e.target.name]: e.target.value });
    };

    handleBlur = (e) => {
      if (e.target.name === 'celular') this.mask(e.target, this.mphone);
    }

    toggleChangeRoleModal = () => {
      this.setState({ changeRoleModal: !this.state.changeRoleModal });
    }

    closeChangeRoleModal = () => {
      this.setState({ changeRoleModal: false, isConsultor: true });
    }

    onConfirmSwitchRoles = () => {
      const user = sessionStorage.getItem("user");
      const username = sessionStorage.getItem("username");
      const userCpf = sessionStorage.getItem("userCpf");
      const userAvatar = sessionStorage.getItem("userAvatar");
      const consultantLogo = sessionStorage.getItem("consultantLogo");
      const userConsultantName = sessionStorage.getItem("userConsultantName");
      const token = sessionStorage.getItem("token");
      const isConsultantAndParticipant = sessionStorage.getItem("isConsultantAndParticipant");

      sessionStorage.clear();

      sessionStorage.setItem("user", user);
      sessionStorage.setItem("username", username);
      sessionStorage.setItem("userCpf", userCpf);
      sessionStorage.setItem("userAvatar", userAvatar);
      sessionStorage.setItem("consultantLogo", consultantLogo);
      sessionStorage.setItem("userConsultantName", userConsultantName);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("isConsultantAndParticipant", isConsultantAndParticipant);
      sessionStorage.setItem("isPlayer", true);

      setTimeout(() => {
        this.props.history.replace("/participante/dashboard");
        window.location.reload();
      }, 600);
    }

    onSelectChange = e => {
      if (!e.target.value) return;

      if (e.target.value !== 'consultor') {
        this.setState({ isConsultor: false });
        this.toggleChangeRoleModal();
      }
    }

    toggleContractModal = () => {
      this.setState({ contractModal: !this.state.contractModal });
    }

    updateLogoState = (state) => {
      this.setState({ ...state });

      this.props.updateAsideState(state);
    }

  render() {

    return (
      <div data-tour="dashboard-step-9" className='app-header navbar'>
        <ContractModal
          isOpen={this.state.contractModal}
          userId={sessionStorage.getItem('user')}
          toggle={this.toggleContractModal}
          onCancel={this.toggleContractModal}
          onlyView={true}
        />
        <ModalEscolherAmbiente
          alreadyLogged={true}
          toggle={this.toggleChangeRoleModal}
          isOpen={this.state.changeRoleModal}
          onCancel={this.closeChangeRoleModal}
        />
        <ModalAlterarSenha
          cpf={this.state.cpf}
          senhaAntiga={this.state.oldPassword}
          isOpen={this.state.passwordModal}
          toggle={this.togglePasswordModal}
          handleClose={this.closePasswordModal}
          handleChange={this.handleOldPasswordChange}
        />
        {/* <ModalEditarAcesso
          updateUserState={this.updateUserState}
          updateAsideState={this.props.updateAsideState}
          cpf={sessionStorage.getItem('userCpf')}
          consultantLogo={this.state.consultantLogo}
          avatar={this.state.avatar}
          history={this.props.history}
          email={this.state.email}
          nome={this.state.nome}
          nomeConsultoria={this.state.nomeConsultoria}
          celular={this.state.celular}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}

          isOpen={this.state.modalEditarAcesso}
          toggle={() => this.toggleModal("modalEditarAcesso")}
          handleClose={() => this.setState({ modalEditarAcesso: false, loadingSpinner: false })}
        /> */}

        <ModalEditarAcesso
          consultantLogo={this.state.consultantLogo}
          avatar={this.state.avatar}
          updateUserState={this.updateUserState}
          updateAsideState={this.updateLogoState}
          isOpen={this.state.modalEditarAcesso}
          toggle={() => this.toggleModal("modalEditarAcesso")}
          handleClose={() => this.setState({ modalEditarAcesso: false, loadingSpinner: false })}
        />
        <AppSidebarToggler className="d-lg-none" display="md" mobile />

        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <div style={{ marginLeft: 40 }} className='mobile-header'>
          <Tooltip
            html={<>
              { sessionStorage.getItem("clienteNome") ? <><span style={{ color: '#fff', fontSize: 14 }}><strong>Cliente:</strong> {sessionStorage.getItem("clienteNome")}</span><br /></> : null}
              { sessionStorage.getItem("projectName") ? <><span style={{ color: '#fff', fontSize: 14}}><strong>Projeto:</strong> {sessionStorage.getItem("projectName")}<br /></span></> : null}
              <span style={{ color: '#fff', fontSize: 14 }}><strong>Créditos Bônus:</strong> {this.props.tryonCredits && this.props.tryonCredits.toLocaleString('pt-BR') || 0}</span> <br />
              <span style={{ color: '#fff', fontSize: 14 }}><strong>Créditos Disponíveis:</strong> {this.props.purchasedCredits && this.props.purchasedCredits.toLocaleString('pt-BR') || 0}</span>
            </>}
            position="right"
            arrow="true"
            size="small"
          >
            <IoMdInformationCircleOutline color={'#fff'} size={27} />
          </Tooltip>
        </div>
        { sessionStorage.getItem("clienteNome") ? <Tooltip title={sessionStorage.getItem("clienteNome")} position="bottom" arrow="true" size="small"><span className='is-mobile' style={{ color: '#fff', fontSize: 14, marginLeft: 15 }}><strong>Cliente:</strong> {sessionStorage.getItem("clienteNome") && sessionStorage.getItem("clienteNome").length > 30 ? `${sessionStorage.getItem("clienteNome").substring(0, 30)}...` : sessionStorage.getItem("clienteNome")}</span></Tooltip> : null}
        { sessionStorage.getItem("projectName") ? <Tooltip title={sessionStorage.getItem("projectName")} position="bottom" arrow="true" size="small"><span className='is-mobile' style={{ color: '#fff', fontSize: 14, marginLeft: 15}}><strong>Projeto:</strong> {sessionStorage.getItem("projectName") && sessionStorage.getItem("projectName").length > 30 ? `${sessionStorage.getItem("projectName").substring(0, 30)}...` : sessionStorage.getItem("projectName")}</span> </Tooltip> : null}
        <Nav className="ml-auto" navbar>
          <span className='is-mobile' style={{ color: '#fff', fontSize: 11, marginLeft: 20 }}>Créditos Bônus: {this.props.tryonCredits && this.props.tryonCredits.toLocaleString('pt-BR') || 0}</span>
          <span className='is-mobile' style={{ color: '#fff', fontSize: 11, marginLeft: 15 }}>Créditos Disponíveis: {this.props.purchasedCredits && this.props.purchasedCredits.toLocaleString('pt-BR') || 0}</span>
          <span className='is-mobile' style={{textDecoration: 'underline', marginLeft: 30, color: '#fff', fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
            this.props.history.push('/financeiro/simulacao-compra');
          }}>Comprar Créditos</span>
          <span className='is-mobile' style={{display: this.context.steps.length ? 'unset' : 'none', textDecoration: 'underline', marginLeft: 30, color: '#fff', fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.context.setIsOpen(true)}>Tour</span>

          <span onClick={() => {
            this.props.history.push('/financeiro/simulacao-compra');
          }} style={{ marginRight: 20, cursor: 'pointer' }} className='mobile-header'>
            <Tooltip
              title='Comprar créditos'
              position="bottom"
              arrow="true"
              size="small"
            >
              <AiOutlineDollar color={'#fff'} size={25}/>
            </Tooltip>
          </span>
          { this.context.steps.length ? <span onClick={() => this.context.setIsOpen(true)} style={{ cursor: 'pointer' }} className='mobile-header'>
            <Tooltip
              title='Tour'
              position="bottom"
              arrow="true"
              size="small"
            >
              <FaRegFlag color={'#fff'} size={25}/>
            </Tooltip>
          </span> : null}

          {/* <button style={{ display: this.context.steps.length ? 'unset' : 'none' }} onClick={() => this.context.setIsOpen(true)}>TESTE TOUR</button> */}
          <AppHeaderDropdown direction="down" className="baseMenu">
            <Notification></Notification>
          </AppHeaderDropdown>
          <AppHeaderDropdown direction="down" className="baseMenu">
            <DropdownToggle nav>
              <img src={this.state.avatar ? `https://files-storage.s3.amazonaws.com/${this.state.avatar}` : 'https://files-storage.s3.amazonaws.com/avatar_default.png'} className="img-avatar" alt="admin@bootstrapmaster.com" />
            </DropdownToggle>
            <DropdownMenu left>
              <DropdownItem header tag="div" className="text-center"><strong>Minha Conta</strong></DropdownItem>
              <DropdownItem style={{ color: '#999' }}
                onClick={this.editarConfig}
                >Edição Perfil</DropdownItem>
              <DropdownItem style={{ color: '#999' }}
                onClick={this.openPasswordModal}
                >Alterar Senha</DropdownItem>
                {
                  sessionStorage.getItem('isConsultantAndParticipant') ? <DropdownItem style={{ color: '#999' }}
                  onClick={this.toggleChangeRoleModal}
                  >Alterar Ambiente</DropdownItem> : null
                }
              <DropdownItem style={{ color: '#999' }}
              onClick={this.toggleContractModal}
              >Visualizar Contrato</DropdownItem>
              <DropdownItem style={{ color: '#999' }}
              onClick={this.logout}
              >Logout</DropdownItem>
              {/* <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Mensagens<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Pagamentos<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem divider /> */}
            </DropdownMenu>
          </AppHeaderDropdown>
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="fa fa-envelope-o"></i><Badge pill color="success">5</Badge></NavLink>
          </NavItem>

           */}
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </div>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default withRouter(DefaultHeader);
