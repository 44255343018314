import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";

const ErrorRow = styled(Row)`
  padding: 15px;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
`;

export default function ErrorMessage(props) {
  return (
    <ErrorRow>
      <Col>{props.message}</Col>
    </ErrorRow>
  );
}
