import React, { Component } from 'react';
import styled from 'styled-components';

// import './css/MaxLinesComponent.css'
/**
 * Component de texto com número máximo de linhas.
 * Para esse componente funcionar é necessário que a fonte tenha um tamanho
 * fixo.
 *
 * @export
 * @class MaxLinesComponent
 * @extends {Component}
 */
export default class MaxLinesComponent extends Component {
  render() {
    const MaxLinesP = styled.p`
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: ${this.props.maxLines || 1};
      -webkit-box-orient: vertical;
      margin-right: ${this.props.maxLines || 0}em;
      width: min-content;
      max-width: 80%;
      text-overflow: ellipsis;
    `;
    return <MaxLinesP>{this.props.children}</MaxLinesP>;
  }
}
