import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "./scss/modalResultados.scss";
import BlueBanner from "../../Assets/ModalResultado/BlueBanner";
import Folder from "../../Assets/Folder";
import ModalResultadosHeader from "./ModalResultadosHeader";
import { ButtonCancel, ButtonSave } from "../../Assets/Assets";
import styled from "styled-components";

import apiCalls from "config/apiCalls";
import Loading from "Services/Loading";

// Componentes de Resultados
import ResultadoAssessments from './ResultadoAssessments';
import ResultadoAssincronos from './ResultadoAssincronos';
import ResultadosReports from './ResultadosReports';
import Perception from '../Perception';
import AtividadesDinamica from './AtividadesDinamica';
import ResultadoProvas from './ResultadoProvas';
import Comments from './comments';
import ErrorBoundary from '../../../Components/ErrorBoundary';
import ResultadosDinamica from './ResultadosDinamica';
import ProvaGeneralResults from './ProvaGeneralResults';
import UnifiedProof from 'views/Pages/Resultados/ModalResultados/ProvaGeneralResults/UnifiedProof';
import { filtrarNome } from "views/Pages/Participantes/utils/filtros";

const BlueContainer = styled.div`
  background-color: #00264b;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 30px;
  margin: 30px 0;
`;

export default class ModalResultados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idProjeto: '',
      idParticipante: '',
      user: {},
      loading: false,
      hasModulos: false,
      updateGraphs: false,
      commentChanged: false,
      commentLeavingGuard: false,
    };
  }

  async getInfoParticipante(idProjeto, idParticipante, participantViewResults) {
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.getInfoParticipante(idProjeto, idParticipante, participantViewResults);

      if (response.data) {
        let countModulos = 0;
        let user = response.data;

        // transforma o objeto user.modulo num array no formato [key, value]
        if (user.dinamicas && user.dinamicas.length > 0)
          countModulos++
        if (user.assessments && user.assessments.length > 0)
          countModulos++
        if (user.assincronos && user.assincronos.length > 0)
          countModulos++
        if (user.provas && user.provas.length > 0)
          countModulos++

        this.setState({ hasModules: countModulos > 0 ? true : false })
      }

      this.setState({
        user: response.data,
        loading: false,
      });

    } catch (err) {
      console.error(err);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { idProjeto, idParticipante, participantViewResults } = this.props;

    if (prevProps.idParticipante != idParticipante && idParticipante.length > 0) {
      this.getInfoParticipante(idProjeto, idParticipante, participantViewResults);
      this.setState({
        idProjeto,
        idParticipante
      })
    }
  }

  setUpdateGraphs = bool => {
    this.setState({ updateGraphs: bool });
  }

  setCommentsChanged = bool => {
    this.setState({ commentChanged: bool });
  }

  toggleCommentLeavingGuard = () => {
    this.setState({ commentLeavingGuard: !this.state.commentLeavingGuard })
  }

  render() {
    const { user } = this.state;
    const { idParticipante, idProjeto } = this.state;

    return (
      <>
        <Modal isOpen={this.state.commentLeavingGuard}>
          <ModalHeader toggle={this.toggleCommentLeavingGuard}>Atenção</ModalHeader>
          <ModalBody>
            <p>Há alterações não salvas no campo comentário. Fechar modal mesmo assim ?</p>
          </ModalBody>
          <ModalFooter>
            <ButtonSave onClick={() => {
              this.toggleCommentLeavingGuard();
              this.props.toggle();
            }}>Sim</ButtonSave>
            <ButtonCancel onClick={this.toggleCommentLeavingGuard}>Não</ButtonCancel>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.props.isOpen} size="xl">
          <ModalHeader toggle={() => {
            if (this.state.commentChanged) return this.toggleCommentLeavingGuard();
            this.props.toggle();
          }}>
            <ErrorBoundary errorComponent={() => <h1>Não foi possível carregar cabeçalho. Por favor, recarregue a página</h1>}>
              <ModalResultadosHeader user={user} loading={this.state.loading} />
            </ErrorBoundary>
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: '16px' }}>
              <Col>
                <BlueBanner>Resultados do Participante</BlueBanner>
                {
                  this.state.loading
                    ?
                    <div>
                      <Loading />
                    </div>
                    :
                    <>
                      {!this.state.hasModules &&
                        <div style={{ height: '100px', display: "flex", alignItems: 'center' }}>
                          <p>O participante não está vinculado a nenhum módulo.</p>
                        </div>
                      }

                      {
                        (user && user.assincronos && user.assincronos.length > 0) &&
                        <Folder
                          show={this.props.idAssincrono ? true : false}
                          nome={"Assíncronos"}
                        >
                          {
                            user.assincronos.map(assincrono => {
                              if (this.props.participantViewResults && !assincrono.instrumentos.length) return <></>
                              return (
                                <Folder
                                  show={this.props.idAssincrono && this.props.idAssincrono == assincrono._id}
                                  key={assincrono._id}
                                  nome={`Assíncrono - ${filtrarNome(assincrono.nome)}`}
                                >
                                  <ResultadoAssincronos
                                    isConsultant={true}
                                    instrumentos={assincrono.instrumentos}
                                    idAssincrono={assincrono._id}
                                    idProjeto={idProjeto}
                                    idParticipante={idParticipante}
                                    nomeParticipante={user.nome}
                                    idAssincronoInstrumento={this.props.idAssincronoInstrumento}
                                    participantViewResults={this.props.participantViewResults}
                                  />
                                </Folder>
                              )
                            })
                          }
                        </Folder>
                      }

                      {
                        (user && user.provas && user.provas.length > 0) &&
                        <Folder
                          show={this.props.resultadosIdProva ? true : false}
                          nome={"Provas"}
                          >
                            {
                              user.provas.map((prova) => (
                                <>
                                  {prova.isUnifiedProof ? (
                                    <Folder
                                      key={prova._id}
                                      show={this.props.resultadosIdProva == prova.idResult}
                                      nome={`Prova - ${filtrarNome(prova.nome)}`}
                                    >
                                      <Folder nome="Resultados da Prova"
                                        show={true}>
                                        <UnifiedProof
                                          idProva={prova._id}
                                          idProjeto={idProjeto}
                                          idParticipante={idParticipante}
                                          acertos={prova?.rank?.acertos}
                                          rp={prova?.rank?.pontuacao}
                                          idResult={prova.idResult} />
                                      </Folder>
                                    </Folder>
                                  ) : (
                                    <Folder
                                      key={prova._id}
                                      show={this.props.resultadosIdProva == prova._id}
                                      nome={`Prova - ${filtrarNome(prova.nome)}`}
                                    >
                                      <Folder nome="Atividades da Prova"
                                        show={this.props.showInstrumentActivitiesByDefault ? true : false}>

                                        <ResultadoProvas
                                          listenToSocket={this.props.resultadosIdProva ? true : false}
                                          rodadas={prova.rodadas}
                                          idProva={prova._id}
                                          idProjeto={idProjeto}
                                          idParticipante={idParticipante}
                                        />
                                      </Folder>
                                      <Folder nome="Resultados da Prova"
                                        show={this.props.showInstrumentResultsByDefault ? true : false}>
                                        <ProvaGeneralResults
                                          idProva={prova._id}
                                          idProjeto={idProjeto}
                                          idParticipante={idParticipante} />
                                      </Folder>
                                    </Folder>
                                  )}
                                </>
                              ))
                            }
                        </Folder>
                      }

                      {
                        (user && user.dinamicas && user.dinamicas.length > 0) &&

                        <Folder
                          show={this.props.resultadosIdDinamica ? true : false}
                          nome={"Dinâmicas"}
                        >
                          {
                            user.dinamicas.map((dinamica) => (
                              <Folder
                                show={this.props.resultadosIdDinamica && this.props.resultadosIdDinamica == dinamica._id}
                                key={dinamica._id}
                                nome={`Dinâmica - ${filtrarNome(dinamica.nome)}`}
                              >
                                <Folder
                                  nome={"Resultados da Dinâmica"}
                                  show={this.props.showInstrumentResultsByDefault ? true : false}>
                                  <ResultadosDinamica
                                    idDinamica={dinamica._id}
                                    idParticipante={idParticipante}
                                    rodadas={dinamica.rodadas}
                                    idProjeto={idProjeto}
                                  />
                                </Folder>
                                <Folder
                                  nome={"Atividades da Dinâmica"}
                                  show={this.props.showInstrumentActivitiesByDefault ? true : false}>
                                  <AtividadesDinamica
                                    rodadas={dinamica.rodadas}
                                    idDinamica={dinamica._id}
                                    idProjeto={idProjeto}
                                    idParticipante={idParticipante}
                                  />
                                </Folder>
                              </Folder>
                            ))
                          }
                        </Folder>

                      }

                      {
                        (user && user.assessments && user.assessments.length > 0) &&
                        <Folder
                          show={this.props.idAssessments ? true : false}
                          nome={"Assessments"}
                        >
                          { user.assessments.map(assessments => {
                            if (this.props.participantViewResults) return <></>
                            return (
                              <Folder
                                show={this.props.idAssessments && this.props.idAssessments == assessments._id}
                                key={assessments._id}
                                nome={`Assessments - ${filtrarNome(assessments.nome)}`}
                              >
                                <Folder
                                  show={this.props.showInstrumentResultsByDefault ? true : false}
                                  nome={'Percepção'}
                                >
                                  <Perception assessmentId={assessments._id}
                                    participantId={idParticipante}
                                    setUpdateGraphs={this.setUpdateGraphs}
                                    onSave={this.props.onPerceptionSave}
                                    consultantId={sessionStorage.getItem('user')} />
                                </Folder>
                                <Folder
                                  show={this.props.showInstrumentResultsByDefault ? true : false}
                                  nome={'Resultados do Assessment'}
                                >
                                  <ResultadoAssessments participantId={idParticipante}
                                  statusPreditivo={null}
                                  setUpdateGraphs={this.setUpdateGraphs}
                                  updateGraphs={this.state.updateGraphs}
                                  participantCanViewReadiness={true}
                                  participantCanViewPotential={true}
                                  isConsultant={true}
                                  incompleteHierarchies={null}
                                  idAssessments={assessments._id}
                                  idProjeto={this.props.idProjeto} />
                                </Folder>
                              </Folder>)

                          }) }

                        </Folder>
                      }

                      {user && user.reports && user.reports.length > 0 && (
                        <Folder
                          show={this.props.idConfig ? true : false}
                          nome={"Relatórios"}
                        >
                          {user.reports.map((report) => {
                            return (
                              <Folder
                              show={false}
                              key={report._id}
                              nome={`Relatório - ${filtrarNome(report.name)}`}
                              >
                                <ResultadosReports
                                  versions={report.versions}
                                  idReportConfig={report._id}
                                  idProjeto={idProjeto}
                                  idParticipante={idParticipante}
                                  participantViewResults={true}
                                />
                              </Folder>
                            );
                          })}
                        </Folder>
                      )}
                    </>
                }
              </Col>
            </Row>
            {
              this.props.participantViewResults
                ?
                <></>
                :
                <Row>
                  <Col>
                    <BlueContainer>Comentários sobre o Participante</BlueContainer>
                    <p style={{ fontSize: 12, fontStyle: 'italic', marginBottom: 30 }}>Utilize o campo abaixo para fazer comentários sobre o desempenho do participante durante as atividades em qualquer módulo. Esses comentários poderão ser resgatados durante a edição do relatório.</p>
                    <ErrorBoundary errorComponent={() => <h1>Não foi possível carregar comentários</h1>}>
                      <Comments setCommentsChanged={this.setCommentsChanged} participantId={idParticipante}
                        projectId={idProjeto} />
                    </ErrorBoundary>
                  </Col>
                </Row>
            }
          </ModalBody>
          <ModalFooter>
            <ButtonCancel onClick={() => {
              if (this.state.commentChanged) return this.toggleCommentLeavingGuard();
              this.props.toggle();
            }}>Fechar</ButtonCancel>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
