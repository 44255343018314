import { notifyError } from 'Services/Notificacoes';
import { getRectangle } from '../pp-graph';

function isOverlaping(rectA, rectB) {

  const xDist = Math.abs(rectA.a.x - rectB.a.x);
  const yDist = Math.abs(rectA.a.y - rectB.a.y);

  if (xDist + yDist === 0) {
    return true;
  }
  
  const minXDist = 0.31;
  const minYDist = 0.2;
  if (xDist < minXDist && yDist < minYDist) {
    return true;
  }

  return false;
}

function getCoordinates({
  name,
  pointX,
  pointY,
  datasets,
  xAxisSize = 5,
  yAxisSize = 5
}) {
  let x, y;
  let xOffset = 0.1; 
  let yOffset = 0.1;

  let attempts = 0;
  let maxAttempts = 1000;
  let overlaps = [];

  function checkOverlaps(x, y) {
    return datasets.filter((dataset) => {
      
      const label = dataset.data[1];
      let overlaping = false;

      if (label) {
        overlaping = (
          isOverlaping(getRectangle(x, y), getRectangle(label.x, label.y))
          ||
          isOverlaping(getRectangle(x, y), getRectangle(dataset.data[0].x, dataset.data[0].y))
        );
      } else {
        overlaping = isOverlaping(getRectangle(x, y), getRectangle(dataset.data[0].x, dataset.data[0].y))
      }

      return overlaping;
    })
  }

  do {

    if (pointY + yOffset < yAxisSize) {
      x = pointX + 0;      
      y = pointY + yOffset;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    if (pointY - yOffset > 0) {
      x = pointX + 0;
      y = pointY - yOffset;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    if (pointX + xOffset < xAxisSize) {
      x = pointX + xOffset;
      y = pointY + 0;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;

      x = pointX + xOffset;
      y = pointY - 0;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    if ((pointX + xOffset < xAxisSize) && (pointY + yOffset < yAxisSize)) {
      x = pointX + xOffset;
      y = pointY + yOffset;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    if ((pointX + xOffset < xAxisSize) && (pointY - yOffset > 0)) {
      x = pointX + xOffset;
      y = pointY - yOffset;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    if ((pointX - xOffset > 0) && (pointY + yOffset < yAxisSize)) {
      x = pointX - xOffset;
      y = pointY + yOffset;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    if ((pointX - xOffset > 0) && (pointY - yOffset > 0)) {
      x = pointX - xOffset;
      y = pointY - yOffset;
      overlaps = checkOverlaps(x, y);
      if (!overlaps.length) break;
    }

    xOffset += 0.1;
    yOffset += 0.1;

    attempts++;
  }
  while(overlaps.length && attempts < maxAttempts);
  if (x === undefined || y === undefined) {
    notifyError('Alguns participantes não couberam no gráfico');
  }

  return {
    x,
    y
  }
}

export default getCoordinates;