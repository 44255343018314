import React, { Component } from 'react';
import Loading from 'Services/Loading';
import { notifyError } from 'Services/Notificacoes';
import Folder from 'views/Pages/Assets/Folder';

import ReportRecommendation from './ReportRecommendation';


/**
* ### Componente de Resultados da Sociometria
*
* /**
* @author Gustavo Carvalho Silva
* @since 19/11/2020
* @props
* - instrumentos
* - idProjeto
* - idParticipante
*
* @desc Monta os folders dos instrumentos através dos ids recebidos na prop instrumentos
*/
export default class ResultadoReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      versions: [],
    };
  };

  getReport() {
    const { versions } = this.props;
    this.setState({ loading: true });
    try {
      this.setState({
        versions,
        loading: false,
      });
    } catch (err) {
      notifyError('Algo deu errado, por favor recarregue a página!');
    }
  }

  componentDidMount() {
    this.getReport();
  }

  render() {
    const {
      versions
    } = this.state;
    const {
      idParticipante,
      idProjeto,
      idReport,
    } = this.props;

    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
              versions.length ?
               versions.map(v =>
                {
                  return  <>
                  <Folder
                    key={v.report_id}
                    show={(this.props.idReportVersion && this.props.idReportVersion == v.report_id)}
                    nome={`Recomendação - Versão ${v.version}.0`}
                    >
                      <ReportRecommendation
                        idParticipante={idParticipante}
                        idProjeto={idProjeto}
                        idReport={v.report_id}
                        participantViewResults={this.props.participantViewResults}
                      />
                  </Folder>
                 </>}
               )
              :
              <div style={{ color: '#f00', fontSize: 12 }}>
                Não há versões para serem mostradas
              </div>

            }
            </>
        }
      </div>
    )
  }
}