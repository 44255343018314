import React, { useRef, useEffect, useState } from 'react';
import { ItemList } from '../styles';

function ConsultantRanking(props) {
  const [consultantRanking, setConsultantRanking] = useState(
[
  {
    position: 1,
    name: 'Marcos Ferreira',
    projects: [... new Array(3)]
  },
  {
    position: 2,
    name: 'Consultor 2',
    projects: [... new Array(4)]
  },
  {
    position: 3,
    name: 'Consultor 3',
    projects: [... new Array(3)]
  },
  {
    position: 4,
    name: 'Consultor 4',
    projects: [... new Array(4)]
  },
  {
    position: 5,
    name: 'Consultor 5',
    projects: [... new Array(3)]
  },
  {
    position: 7,
    name: 'Consultor 6',
    projects: [... new Array(4)]
  },
  {
    position: 8,
    name: 'Consultor 2',
    projects: [... new Array(4)]
  },
  {
    position: 9,
    name: 'Consultor 3',
    projects: [... new Array(3)]
  },
  {
    position: 10,
    name: 'Consultor 4',
    projects: [... new Array(4)]
  },
]
  )

  const intersectionRef = useRef(null);

  const observer = consultantRanking && new IntersectionObserver(enteries => {
    const ratio = enteries[0].intersectionRatio;

    if (ratio > 0) console.log('hit bottom');
  });

  useEffect(() => {
    observer && observer.observe(intersectionRef.current);
  }, []);

  return (
    <ItemList style={{
      fontSize: '10px'
    }}>
      {consultantRanking.map((consultant) => (
        <li>
          <div style={{
            display: 'flex',
            borderBottom: '3px solid #f8f8f8',
            padding: '10px 0px',
          }}>
            <span style={{
              paddingLeft: '20px'
            }}><b>{consultant.position}.</b></span>
            <span style={{ marginLeft: '15px' }}>{consultant.name}</span>
            <span style={{
              marginLeft: 'auto',
              marginRight: '20px',
            }}><b>{consultant.projects.length} prj</b></span>
          </div>
        </li>
      ))}
      <li ref={intersectionRef}></li>
    </ItemList>
  )
}

export default ConsultantRanking
