import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FaEye, FaFile, FaUser } from "react-icons/fa";
import styled from 'styled-components';
import FolderV2 from 'views/Pages/Assets/FolderV2';
import { Link } from 'react-router-dom';
import { ProjectActionWithImg } from 'assets/ProjectAction';
import { ImageProjeto } from 'views/Pages/Assets/Assets';
import ImgReports from 'views/Pages/Assets/images/Relatorios.png';
import { Tooltip } from 'react-tippy';
import {
  ActionIcon,
} from "iapp-design-system";
import { capitalizeFirstLetter } from 'utils/masks';
import AssessmentRegisterModal from 'views/Components/AssessmentRegisterModal';
import { Button } from '../../../../components/SideShortcuts/styles';
import dynamicIcon from "../../../../components/SideShortcuts/img/dynamic.svg";
import assessmentIcon from "../../../../components/SideShortcuts/img/assessment.svg";
import reportIcon from "../../../../components/SideShortcuts/img/report.svg";
import proofIcon from "../../../../components/SideShortcuts/img/proof.svg";

const Projects = styled.div`
  width: 100%;

  li {
    list-style: none;
    margin-left: 5px;
  }

  @media(max-width: 780px) {
    min-width: 100% !important;
  }
`;

const MenuIcon = styled(ActionIcon)`
  width: 35px;
`;

export const ProjectsFolder = ({
  nomeProjetos,
  client,
  canAccess
}) => {
  const [assessmentRegisterModal, setAssessmentRegisterModal] = useState(false);

  const setLocalStorageProject = (projectId, clientId) => {
    sessionStorage.setItem('projeto', projectId);
    sessionStorage.setItem('cliente', clientId);
    sessionStorage.setItem('clienteNome', client.nomeFantasia);

    if (client.logo)
      sessionStorage.setItem('clienteLogo', client.logo);
    else sessionStorage.removeItem('clienteLogo');
  }

  function toggleAssessmentRegisterModal() {
    setAssessmentRegisterModal(!assessmentRegisterModal);
  }

  return (
    <div style={{ display: 'flex' }}>
      <AssessmentRegisterModal
        isOpen={assessmentRegisterModal}
        toggle={toggleAssessmentRegisterModal}
      />
      <Projects>
        <FolderV2 nome='Todos os projetos' show={false}>
          {
            nomeProjetos.map(p => {

              return (
                <Row style={{ borderBottom: '1px solid #c9c9c9', fontSize: 12, marginRight: -10 }}>
                  <Col style={{ display: 'flex', alignItems: 'center' }} sm={3}>
                    <span>{capitalizeFirstLetter(p.nomeProjeto)}</span>
                  </Col>
                  <Col sm={9} className="text-center" style={{ marginRight: 0, paddingRight: 10 }}>
                    <ul style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginRight: '-2px' }}>
                      <li onClick={() => setLocalStorageProject(p.idProjeto, p.idCliente)}>
                        <Link to="/clientes/projetos/assincronos">
                          <Tooltip title="Assíncronos" position="top" arrow size="small">
                            <Button>
                              <FaUser size="20" />
                            </Button>
                          </Tooltip>
                        </Link>
                      </li>
                      <li onClick={() => setLocalStorageProject(p.idProjeto, p.idCliente)}>
                        <Link to="/clientes/projetos/provas">
                        <Tooltip title="Provas" position="top" arrow size="small">
                            <Button>
                              <img src={proofIcon} width="17" />
                            </Button>
                          </Tooltip>
                        </Link>
                      </li>
                      <li onClick={() => setLocalStorageProject(p.idProjeto, p.idCliente)}>
                        <Link to="/clientes/projetos/dinamicas">
                        <Tooltip title="Dinâmicas" position="top" arrow size="small">
                            <Button>
                              <img src={dynamicIcon} width="17" />
                            </Button>
                          </Tooltip>
                        </Link>
                      </li>

                      {canAccess && canAccess.find(a => a.includes('assessment')) ? <li onClick={() => setLocalStorageProject(p.idProjeto, p.idCliente)}>
                        <Link to="/clientes/projetos/assessments">
                        <Tooltip title="Assessments" position="top" arrow size="small">
                            <Button>
                              <img src={assessmentIcon} width="17" />
                            </Button>
                          </Tooltip>
                        </Link>
                      </li> :
                      <li>
                      <Tooltip interactive={true} html={<span style={{ cursor: 'pointer' }} onClick={toggleAssessmentRegisterModal}>Porque esse botão está desabilitado ?</span> } position="top" arrow size="small">
                        <Button disabled={true}>
                          <img src={assessmentIcon} width="17" />
                        </Button>
                      </Tooltip></li>}
                      <li onClick={() => setLocalStorageProject(p.idProjeto, p.idCliente)}>
                        <Link to="/clientes/projetos/resultados/configurar">
                          <Tooltip title="Resultados" position="top" arrow size="small">
                            <ProjectActionWithImg
                              id="icon-projeto-results"
                              title="Resultados"
                              icon="results"
                            />
                          </Tooltip>
                        </Link>
                      </li>
                      <li onClick={() => setLocalStorageProject(p.idProjeto, p.idCliente)}>
                        <Link to="/clientes/projetos/reports">
                        <Tooltip title="Relatórios" position="top" arrow size="small">
                            <Button>
                              <img src={reportIcon} width="17" />
                            </Button>
                          </Tooltip>
                        </Link>
                      </li>
                    </ul>
                  </Col>
                </Row>)
            })}
        </FolderV2>
      </Projects>
    </div>
  )
}