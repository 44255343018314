import React, { Component, useState } from 'react';
import CytoscapeComponent from "react-cytoscapejs";
import Cytoscape from "cytoscape";
import klay from "cytoscape-klay";
import Loading from 'Services/Loading';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import { Table } from "reactstrap";
import { Tooltip } from 'reactstrap';
import { p } from 'Services/Constantes';
import { IappSwitch } from "styles/iapp-switch";
import 'views/Pages/ConfiguracoesInstrumentos/Case/index.module.css';

import { capitalizeFirstLetterEachWord } from "utils/masks";

Cytoscape.use(klay);

/**
 * ### Componente de Resultados da Sociometria
 *
 * @props
 *
 * - idProjeto
 * - idParticipante
 * - idDinamica
 * - rodada
 *
 * @desc Baseado nas props, busca os resultados de um determinado participante na Sociometria.
 */
export default class InfoSociometria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respostasSociometria: [
        {
          index: 0,
          nome:
            "Escolha participante(s) que considera apto(s) a liderar a sua equipe",
          userZoomingEnabled: false
        },
        {
          index: 1,
          nome:
            " Indique participante(s) com quem gostaria de ter em sua equipe de trabalho",
          userZoomingEnabled: false
        },
        {
          index: 2,
          nome:
            "Indique participante(s) que não gostaria ou consideraria de ter em sua nova equipe de trabalho",
          userZoomingEnabled: false
        }
      ],
      nodes: [],
      edges: [],
      participantes: [],
      votos: [],

      loading: false,
      tooltipOpen: false,
    };
  }


  /**
   *
   * @param {Array} participantes = array contendo os participantes da rodada e grupo do participante sendo avaliado
   * @description Percorre o vetor de usuários e busca os participantes do instrumento junto ao participante avaliado
   *
   * @return
   * nodes [
   *  data: {
   *    id: "", id do participante
   *    label: "", nome do participante + contagem de votos que ele recebeu
   *    teste: "", index to teste que está sendo montado (ver o state respostasSociometria)
   *  }
   * ]
   *
   */
  mountNodes(participantes) {
    const { respostasSociometria, votos } = this.state;
    let nodes = respostasSociometria.map(resposta => {
      return participantes.map(participante => {
        return {
          'data': {
            "id": participante.id,
            "label": `${participante.nome} (${votos[resposta.index] && votos[resposta.index].find(voto => voto.id == participante.id).quantidade})`,
            "teste": resposta.index,
          }
        }
      })
    })
    return nodes;
  }

  /**
   *
   * @param {Array} participantes = array contendo os participantes da rodada e grupo do participante sendo avaliado
   * @description Percorre o vetor de usuários e busca as relações do instrumento (quem votou em quem) e retorna um
   * objeto que possa ser usado pelo componente Cytoscape
   *
   * @return
   * edges [
   *  data: {
   *    id: "", (id do objeto, gerado automaticamente)
   *    source: "", id do participante que votou
   *    target: "", id do participante que recebeu o voto
   *  }
   * ]
   *
   */
  mountEdges(participantes) {
    const { respostasSociometria } = this.state;

    let edges = [];

    respostasSociometria.map(resposta => {
      let tempResults = [];
      participantes.map(participante => {
        if(participante.respostas && participante.respostas[resposta.index] && participante.respostas[resposta.index].participantes)
        participante.respostas[resposta.index].participantes.map(p => {
          let connect = new Object();
          connect.data = {
            source: participante.id,
            target: p,
          }
          tempResults.push(connect);
        })
      });

      edges.push(tempResults)
    });
    return edges;
  }

  /**
   * @param {String} id id do usuário do qual se deve buscar os pontos
   * @param {Number} index index to teste que deve ser buscado (ver o state respostasSociometria)
   * @description Faz a contagem do número de votos que um usuário recebeu em uma etapa da sociometria
   */
  countPoints(id, index) {
    const { participantes } = this.state;
    let pontuacao = 0;
    participantes.map(participante => {
      if (participante.respostas && participante.respostas[index] && participante.respostas[index].participantes && participante.respostas[index].participantes.includes(id))
        pontuacao++;
    })
    return pontuacao;
  }

  /**
   * @param {String} id id do usuário do qual se deve buscar os pontos
   * @param {Number} index index to teste que deve ser buscado (ver o state respostasSociometria)
   * @description Cria um state com um array contendo o número de votos que cada participante recebeu em uma etapa do instrumento
   */
  mountVotos() {
    const { participantes } = this.state;

    let votos = [];
    this.state.respostasSociometria.map(resposta => {
      let tempVotos = [];
      participantes.map(({ id }) => {
        tempVotos.push({ id, quantidade: this.countPoints(id, resposta.index) });
      })
      votos.push(tempVotos);
    });

    this.setState({ votos: votos });
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idDinamica, rodada, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.dinamicas.getInfoSociometria(idParticipante, idProjeto, idDinamica, rodada, idInstrumento);
      const { participantes } = response.data;
      this.setState(({ participantes: participantes }));

      this.mountVotos();
      let nodes = this.mountNodes(participantes);
      let edges = this.mountEdges(participantes);

      this.setState({ nodes: nodes });
      this.setState({ edges: edges });

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  handleZoomClick = (answerIndex) => {
    this.setState(prev => {

      return {
        respostasSociometria: prev.respostasSociometria.map((r, curAnswerIndex) => {

          let userZoomingEnabled = r.userZoomingEnabled;
          if (curAnswerIndex === answerIndex) {
            userZoomingEnabled = !r.userZoomingEnabled;
          }
          console.log({ userZoomingEnabled, curAnswerIndex, answerIndex })

          return {
            ...r,
            userZoomingEnabled
          }
        })
      }
    })
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    const { respostasSociometria, nodes, edges, participantes, votos } = this.state;

    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            :
            <div>
              {
                participantes.length > 0
                ?
                <>
                {
                  respostasSociometria.map((resposta, answerIndex) => {
                    let testNodes = nodes[resposta.index];
                    let testEdges = edges[resposta.index];

                    testNodes.map(n => {
                      if (n && n.data && n.data.label)
                        n.data.label = capitalizeFirstLetterEachWord(n.data.label.toLowerCase())
                    });

                    return (
                      <div key={resposta.index}>
                        <h6>{resposta.nome}</h6>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IappSwitch htmlFor={`toggle-sociometry-zoom-${answerIndex}`}
                            style={{ marginRight: 10, marginBottom: 0 }}
                          >
                            <input
                              id={`toggle-sociometry-zoom-${answerIndex}`}
                              type="checkbox"
                              checked={resposta.userZoomingEnabled}
                              onClick={() => this.handleZoomClick(answerIndex)}
                            />
                            <span className="slider round" />
                          </IappSwitch>

                          <label style={{ fontSize: '12px', marginBottom: 0, marginRight: 5 }}
                            htmlFor={`toggle-sociometry-zoom-${answerIndex}`}>
                            {resposta.userZoomingEnabled ? 'Desativar zoom' : 'Ativar zoom'}
                          </label>

                        </div>

                        <CytoscapeComponent
                          minZoom={resposta.userZoomingEnabled ? 0: null}
                          maxZoom={resposta.userZoomingEnabled ? 2 : null}
                          // cy={(cy) => {
                          //   this.cy = cy;
                          //   cy.on('zoom', function (e) { console.log({ e }) }); // get zoom
                          // }}
                          elements={testNodes.concat(testEdges)}

                          userZoomingEnabled={resposta.userZoomingEnabled}
                          style={{
                            height: "600px",
                            width: "100%",
                            // padding: "30px"
                          }}
                          id="myCy"
                          stylesheet={[
                            {
                              selector: "edge",
                              style: {
                                // width: 2,
                                // height: 2,
                                "curve-style": "straight",
                                "target-arrow-shape": "triangle",
                                "line-color": "#a3a3a3",
                                "target-arrow-color": "#a3a3a3",
                                opacity: 1
                              }
                            },
                            {
                              selector: "node",
                              style: {
                                // width: 20,
                                // height: 20,
                                content: "data(label)",
                                // fontSize: 10,
                                "background-color": "#002a4c",
                                "font-family": `"Montserrat", sans-serif`,
                              }
                            },
                            {
                              selector: ':selected',
                              css: {
                                'background-color': '#07536d'
                              }
                            },
                          ]}

                          layout={{
                            name: "klay",
                            nodeDimensionsIncludeLabels: true,
                            fit: true,
                            klay: {
                              spacing: 50,
                              borderSpacing: 20,
                              mergeHierarchyCrossingEdges: true,

                              thoroughness: 7,
                            }
                          }}
                        />
                      </div>
                    )
                  }
                  )
                }
                  <h6>Votos recebidos por questão</h6>
                  <Table responsive>
                    <thead style={{ fontSize: '12px' }}>
                      <tr>
                        <th style={{ width: '35px' }}>#</th>
                        {participantes.map(({ nome }, idx) => (
                          <th style={{ minWidth: "100px", maxWidth: "150px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} key={idx}>{capitalizeFirstLetterEachWord(nome.toLowerCase())}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: '12px' }}>
                      {
                        respostasSociometria.map(resposta => (
                          <tr key={resposta.index}>
                            <ResultsIndex
                              index={resposta.index}
                              nome={capitalizeFirstLetterEachWord(resposta.nome.toLowerCase())}
                            />
                            {
                              participantes.map(({ id }, index) => (
                                <th key={index} style={{ fontWeight: 'normal' }}>
                                  {votos[resposta.index].find(voto => voto.id == id).quantidade}
                                </th>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </>
                :
                <div style={{ fontSize: '12px', color: 'red' }}>Não existem respostas</div>
            }
            </div>
        }
      </div>
    )
  }
}

const ResultsIndex = ({ index, nome }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <th scope="row" id={`tooltip-line-${index}`} style={{ cursor: 'help', fontWeight: 'bolder' }}>
        {index + 1}
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={`tooltip-line-${index}`}
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          {nome}
        </Tooltip>
      </th>
    </>
  )
}