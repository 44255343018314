import React, { Component } from 'react';
import IdentityAspectsChart from './IdentityAspectsChart/IdentityAspectsChart';
import { normalizarPorMaximo } from 'Services/utilsFn';
/**
 * Gera o gráfico de autoconceito no trabalho
 *
 * @export
 * @class AspectosDeIdentidadeGraph
 * @extends {Component}
 */
export default class AspectosDeIdentidadeGraph extends Component {
  getGraphData() {
    const dataArray = [
      {
        name: 'Autoavaliação',
        data: [
          Math.round(100 * normalizarPorMaximo(5, this.props.resposta['ip'])) /
            100,
          Math.round(100 * normalizarPorMaximo(5, this.props.resposta['ir'])) /
            100,
          Math.round(100 * normalizarPorMaximo(5, this.props.resposta['is'])) /
            100,
          Math.round(100 * normalizarPorMaximo(5, this.props.resposta['sp'])) /
            100,
          Math.round(100 * normalizarPorMaximo(5, this.props.resposta['ic'])) /
            100,
        ],
      }
    ]

    if (this.props.respondentes && this.props.respondentes.length) {
      this.props.respondentes
      .filter(respondente => respondente.resposta)
      .map((respondente, i) => {
        dataArray.unshift({
          name: this.props.isConsultor
          ? `${respondente.nome} ${respondente.relacao}`
          : `${respondente.relacao} ${i + 1}`,
          data: [
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['ip']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['ir']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['is']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['sp']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(5, respondente.resposta['ic']),
            ) / 100,
          ],
        });
      });
    }

    return dataArray;
  }

  render() {
    return (
      <div>
        <IdentityAspectsChart id={`identity-aspects-${this.props.idAssincrono}`}
          rodadasData={this.getGraphData()}
          title='Aspectos de Identidade' />
      </div>
    );
  }
}
