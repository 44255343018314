import React, { useState, useEffect } from "react";

import apiCalls from "config/apiCalls";
import { notifyError, notifySuccess } from "Services/Notificacoes";
import Loading from "Services/Loading";
import {
  GreyComment,
  CommentsContainer,
  Comment,
  FlexContainer,
  BorderlessButton
} from './styles';
import { ButtonSave } from "views/Pages/Assets/Assets";

function Comments(props) {

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [creatingComment, setCreatingComment] = useState(false);
  const [editingComment, setEditingComment] = useState(false);

  useEffect(() => {

    const load = async function loadComments() {
      try {
        setFetching(true);
        const res = await apiCalls.comments.getComments(
          sessionStorage.getItem('user'),
          props.participantId,
          props.projectId
        );
        const { comments: fetchedComments } = res.data;
        setComments(fetchedComments.map((c) => ({
          changed: false,
          hasFocus: false,
          _id: c._id,
          consultantId: c.consultantId,
          participantId: c.participantId,
          projectId: c.projectId,
          text: c.text,
          created: c.createdAt,
          consultantName: c.consultantName,
        })));
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setFetching(false);
      }
    }

    load();
  }, []);

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
    if (props.setCommentsChanged) props.setCommentsChanged(!!e.target.value);
  }

  const handleCommentChange = (e) => {
    try {
      const index = comments.findIndex((c) => c._id === e.target.id);
      if (index !== -1) {
        const clone = [...comments];
        clone[index].text = e.target.value;
        clone[index].changed = true;

        setComments(clone);
      }
    } catch (error) {
      console.error(error);
      notifyError('Não foi possível editar comentário! Por favor, tente novamente');
      setError(true);
    }
  }

  const createComment = async (_e) => {
    try {
      setCreatingComment(true);
      const consultantId = sessionStorage.getItem('user');
      const consultantName = sessionStorage.getItem('username');
      const res = await apiCalls.comments.saveComment(
        consultantId,
        props.participantId,
        props.projectId,
        newComment
      );
      const createdCommentId = res.data._id;

      const createdComment = {
        _id: createdCommentId,
        consultantId: consultantId,
        participantId: props.participantId,
        projectId: props.projectId,
        text: newComment,
        created: new Date().toISOString(),
        consultantName: consultantName,
      }

      const clone = [ ...comments ];
      clone.push(createdComment);
      setComments(clone);
      setNewComment('');
      if (props.setCommentsChanged) props.setCommentsChanged(false);
      notifySuccess('Comentário adicionado!');
    } catch (error) {
      console.error(error);
      notifyError('Não foi possível salvar comentário! Por favor, tente novamente');
      setError(true);
    } finally {
      setCreatingComment(false);
    }
  }

  const editComment = async (comment) => {
    try {
      setEditingComment(true);
      await apiCalls.comments.editComment(
        comment._id,
        comment
      );

      const index = comments.findIndex((c) => c._id === comment._id);
      const clone = [ ...comments ];

      if (index !== -1) {

        clone[index].changed = false;
        setComments(clone);
        notifySuccess('Comentário atualizado!');
      }
    } catch (error) {
      console.error(error);
      notifyError('Não foi possível editar comentário! Por favor, tente novamente');
      setError(true);
    } finally {
      setEditingComment(false);
    }
  }

  const setFocus = (id, value) => {
    const index = comments.findIndex((c) => c._id === id);
    const clone = [ ...comments ];

    clone[index].hasFocus = value;
    setComments(clone);
  }

  const textAreaAdjust = (element) => {
    if (element.target.offsetHeight < element.target.scrollHeight) {
      element.target.style.height = (element.target.scrollHeight)+"px";
    }
  }

  useEffect(() => {
    var textareasNL = document.getElementsByName('comment-area');
    var textareas = Array.from(textareasNL);

    textareas.map(textarea => {
      if (!textarea.style.height)
        textarea.style.height = (1 + textarea.scrollHeight)+"px";
    });
  });

  if (error) return <h1>Não foi possível carregar comentários</h1>

  if (fetching) return (
    <div>
      <Loading />
    </div>
  )

  return (
    <div>
      <CommentsContainer style={{ maxHeight: '30vw' }}>
        {comments.map((comment, index) => (
          <FlexContainer style={{ alignItems: 'center' }} key={index}>
            <div style={{width: '100%'}}>
            <span style={{fontSize: '10px'}}>{new Date(comment.created).toLocaleString()} - <strong>{comment.consultantName}</strong></span>
            <Comment
              id={comment._id}
              name="comment-area"
              key={index}
              disabled={comment.consultantId !== sessionStorage.getItem('user')}
              value={comment.text}
              onChange={handleCommentChange}
              onFocus={(_e) => setFocus(comment._id, true)}
              onBlur={(_e) => setFocus(comment._id, false)} />
            </div>


              {!comment.hasFocus && !comment.changed ? (
                <BorderlessButton onClick={(_e) => {
                  setFocus(comment._id, true);
                  const textArea = document.getElementById(comment._id);
                  textArea.focus();
                  textArea.setSelectionRange(textArea.value.length,textArea.value.length);
                }}
                disabled={!comment.text || editingComment}>
                  <i className="fa fa-pencil"
                    aria-hidden="true"
                    style={{
                      fontSize: '15px'
                    }}></i>
                </BorderlessButton>
              ) : ''}

            {comment.hasFocus || comment.changed ? (
              <BorderlessButton onClick={(_e) => editComment(comment)}
                disabled={!comment.text || editingComment}>
                <i className="fa fa-check-circle-o"
                  aria-hidden="true"
                  style={{
                    fontSize: '20px'
                  }}></i>
              </BorderlessButton>
            ) : ''}
          </FlexContainer>
        ))}
      </CommentsContainer>
      <FlexContainer style={{
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}>
        <GreyComment style={{ height: '50px', maxHeight: '20vw' }}
          onKeyUp={e => textAreaAdjust(e)}
          placeholder="Escreva aqui um comentário sobre o participante"
          value={newComment}
          onChange={handleNewCommentChange} />
        <ButtonSave style={{ marginRight: 0 }} onClick={createComment} disabled={!newComment || creatingComment}>Enviar Comentário</ButtonSave>
      </FlexContainer>
    </div>
  )
}

export default Comments;