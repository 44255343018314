
function verifyAdmin() {
  let items = [
    {
      title: true,
      name: "Menu de Navegação",
      wrapper: {
        element: "",
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "" // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "fa fa-tachometer",
      class: "dashboard-step-3"
    },
    {
      name: "Meus Processos",
      url: "/clientes",
      icon: "fa fa-tasks",
      class: "dashboard-step-4"
    },
    {
      name: "Ambiente Testes",
      url: "/ambiente-testes",
      icon: "fa fa-flask",
      class: "dashboard-step-5"
    },
    {
      name: "Financeiro",
      url: "/financeiro/simulacao-compra",
      icon: "fa fa-usd",
      class: "dashboard-step-6"
    },
    {
      name: "Logs",
      url: "/logs",
      icon: "fa fa-book"
    },
    {
      name: "Contate-nos",
      url: "/suporte",
      icon: "fa fa-question-circle",
      class: "dashboard-step-8"
    },
  ];

  const authorizedCpfs = process.env.REACT_APP_ADMIN_CPFS && process.env.REACT_APP_ADMIN_CPFS.split(',');

  if (authorizedCpfs && authorizedCpfs.find(c => c === sessionStorage.getItem('userCpf'))) {
    items = [
      {
        title: true,
        name: "Menu de Navegação",
        wrapper: {
          element: "",
          attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: "" // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: "Dashboard",
        url: "/dashboard",
        icon: "fa fa-tachometer",
        class: "dashboard-step-3"
      },
      {
        name: "Meus Processos",
        url: "/clientes",
        icon: "fa fa-tasks",
        class: "dashboard-step-4"
      },
      {
        name: "Ambiente Testes",
        url: "/ambiente-testes",
        icon: "fa fa-flask",
        class: "dashboard-step-5"
      },
      {
        name: "Financeiro",
        url: "/financeiro/simulacao-compra",
        icon: "fa fa-usd",
        class: "dashboard-step-6"
      },
      {
        name: "Logs",
        url: "/logs",
        icon: "fa fa-book"
      },
      {
        name: "Logs Admin",
        url: "/logs-admin",
        icon: "fa fa-book"
      },
      {
        name: "Contate-nos",
        url: "/suporte",
        icon: "fa fa-question-circle",
        class: "dashboard-step-8"
      },
    ];
  }

  return { items };
}


export default verifyAdmin;

