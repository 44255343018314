import React, { Component } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Tooltip } from "react-tippy";
import { assincronosApi } from '../../../../../Services/api';
import Loading from '../../../../../Services/Loading';
import AncoraDeCarreiraGraph from '../../../../Components/AncoraDeCarreiraGraph';
import DefaultTitle from 'components/default-title';
import DefaultText from 'components/default-text';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import { normalizarPorMaximo } from 'Services/utilsFn';

import apiCalls from 'config/apiCalls';
/**
 * Renderiza o resultado de uma ancora de carreira de um usuário
 *
 * @export
 * @class ResultadoAncoraDeCarreira
 * @extends {Component}
 */
export default class ResultadoAncoraDeCarreira extends Component {

  state = {
    loading: false,
    whatsBeingMeasuredText: '',
    resultado: {
      respostas: [],
      respondentes: []
    },
    dimensoesMaisPontuadasAutoAvaliada: [],
    dimensoesMaisPontuadasRespondente: [],
    textosAutoAvaliado: [],
    textosRespondentes: [],
  }

  componentDidMount() {
    this.setState({ loading: true });
    apiCalls.resultados.assincronos
      .getInfoInstrumento(
        this.props.idProjeto,
        this.props.idParticipante,
        this.props.idAssincrono,
        this.props.idInstrumento
      )
      .then((response) => {
        this.setState(prev => ({
          resultado: {
            ...prev.resultado,
            respostas: response.data
          }
        }));

        return response.data;
      })
      .then((answers) => {
        this.setState({
          loading: true,
          dimensoesMaisPontuadasAutoAvaliada: this.getDimensoesMaisPontuadas(
            answers[0],
          ),
          dimensoesMaisPontuadasRespondente: this.getDimensoesMaisPontuadas(
            this.getRespondenteResposta(this.props.resultado),
          ),
          textosAutoAvaliado: [],
          textosRespondentes: [],
        });
        this.getTextos();
      })
      .catch((err) => console.error(err))
      .finally(() => this.setState({ loading: false }));
  }

  async getTextoFromAncora(ancora) {
    const res = await assincronosApi.get(
      `/resultado/ancora/texto-consultor/${ancora.dimensaoName}`,
    );
    const obj = {
      dimensaoName: ancora.dimensaoName,
      ...res.data
    }
    return obj;
  }

  async getTextos() {
    const {
      dimensoesMaisPontuadasAutoAvaliada,
      dimensoesMaisPontuadasRespondente,
    } = this.state;
    const textosAutoAvaliado = await Promise.all(
      dimensoesMaisPontuadasAutoAvaliada.map(this.getTextoFromAncora),
    );
    const textosRespondentes = await Promise.all(
      dimensoesMaisPontuadasRespondente.map(this.getTextoFromAncora),
    );
    // const textosRespondentes = await Promise.all(
    //   dimensoesMaisPontuadasRespondente.map(this.getTextoFromAncora),
    // );

    this.setState({
      whatsBeingMeasuredText: textosAutoAvaliado.length ? textosAutoAvaliado[0].whatsBeingMeasuredText : '',
      textosAutoAvaliado,
      textosRespondentes,
      loading: false,
    });
  }

  /**
   * Retorna a média das respostas do respondente
   *
   * @param {InstrumentoInterno} instrumento
   * @returns {Map<String, number>}
   * @memberof ResultadoAncoraDeCarreira
   */
  getRespondenteResposta(instrumento) {
    let respostas = {
      somaAI: 0,
      somaCE: 0,
      somaDP: 0,
      somaEV: 0,
      somaGG: 0,
      somaSD: 0,
      somaSE: 0,
      somaTF: 0,
    };
    instrumento.respondentes.forEach(respondente => {
      if (respondente.resposta) {
        Object.keys(respondente.resposta).forEach(key => {
          respostas[key] += respondente.resposta[key];
        });
      }
    });
    return respostas;
  }

  getDimensoesMaisPontuadas(answer) {
    let dimensoesAncoras = [];
    for (let key in answer) {
      if (key.includes('soma')) {
        let dimensaoName = key.substr(key.length - 2);
        dimensoesAncoras.push({
          dimensaoName,
          pontos: answer[key],
        });
      }
    }
    const dimensaoMaisPontuada = dimensoesAncoras.reduce(
      (acc, at) => (at.pontos > acc.pontos ? at : acc),
      { pontos: 0 },
    );
    const dimensoesMaisPontuadas = dimensoesAncoras.filter(d => (d.pontos >= (dimensaoMaisPontuada.pontos - 3)));
    return dimensoesMaisPontuadas;
  }


  getAncoraResult(ancora) {
    const result = Math.round(
      100 *
        normalizarPorMaximo(
          42,
          (this.state.resultado && this.state.resultado.respostas[0] && this.state.resultado.respostas[0][ancora]) || 0,
        ),
    ) / 100

    return result;
  }

  render() {
    const {
      loading,
      textosAutoAvaliado,
      textosRespondentes,
      dimensoesMaisPontuadasAutoAvaliada,
      dimensoesMaisPontuadasRespondente,
      resultado
    } = this.state;
    if (loading) {
      return <Loading />;
    }

    if (!resultado.respostas.length) {
      return (
        <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
      )
    }
    return (
      <div>
        <AncoraDeCarreiraGraph
          resposta={resultado.respostas[0]}
          respondentes={resultado.respondentes}
          isConsultor={true}
          idAssincrono={this.props.idAssincrono}
        />
        <p style={{ fontWeight: 'bold', fontSize: 18 }}>O que está sendo analisado</p>
        <p dangerouslySetInnerHTML={{ __html: this.state.whatsBeingMeasuredText }}></p>
        <br />
        <p style={{ fontWeight: 'bold', fontSize: 18 }}>Resultado <Tooltip
                      title="Serão apresentadas as âncoras com as maiores notas obtidas."
                      position="top"
                      size="small"
                      trigger="mouseenter"
                      arrow="true"
                    ><FaRegQuestionCircle size={12} /></Tooltip></p>
        <br />
        <p>
          {dimensoesMaisPontuadasAutoAvaliada[0] &&
          dimensoesMaisPontuadasAutoAvaliada[0].pontos > 0
            ? textosAutoAvaliado.map(texto => (
                <>
                  <DefaultText bold>{texto.tipo}</DefaultText>
                  <DefaultText>{texto.texto}</DefaultText>
                  <br />
                </>
              ))
            : null}
        </p>
        {dimensoesMaisPontuadasRespondente[0] &&
        dimensoesMaisPontuadasRespondente[0].pontos > 0 ? (
          <>
            <br />
            <DefaultTitle>Respondentes</DefaultTitle>
          </>
          ) : null}
        <p>
          {dimensoesMaisPontuadasRespondente[0] &&
            dimensoesMaisPontuadasRespondente[0].pontos > 0
            ? textosRespondentes.map(texto => (
              <>
                <DefaultText bold>{texto.tipo}</DefaultText>
                <DefaultText>{texto.texto}</DefaultText>
                <br />
              </>
            ))
            : null}
        </p>
      </div>
    );
  }
}
