import React, { Component } from "react";
import _ from 'lodash'
import apiCalls from "config/apiCalls";
import { TourContext } from '@reactour/tour'
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  BaseProjeto,
  AdicionarCliente,
  ButtonCancel,
  ButtonSave,
  TourP
} from "../Assets/Assets";

import Breadcrumb from "../../../components/breadcrumb";
import { toast } from "react-toastify";
import api from "../../../Services/api";
import ClienteCadastro from "./ClienteCadastro";
import { CustomModal } from "iapp-design-system/dist/components/custom-modal";
import { notifyError, notifySuccess } from "Services/Notificacoes";
import Loading from "../../../Services/Loading";
import Search from "../../../components/Search";
import removeSpecials from "../../Reports/reports-config/utils/removeSpecials";
import verifyModulesAccess from "Services/verifyModulesAccess";

import ClientsTable from "./Components/ClientsTable";
import ShowArchived from "components/ShowArquived";
import { sortBy } from "lodash";

const MODAL_ARQUIVAR_MODES = {
  DELETE: "delete",
  TO_FILE: "to file",
};

class Clientes extends Component {
  static contextType = TourContext;

  state = {
    canAccess: [],
    clientes: [],
    lastModifiedProjects: [],
    searchQuery: "",
    ultimoProjeto: [],
    nomeProjetos: [],
    modalArquivar: {
      active: false,
      mode: MODAL_ARQUIVAR_MODES.TO_FILE,
      cliente: null,
    },
    modalRestore: {
      active: false,
      cliente: null,
      message: "",
    },
    modalNovo: false,
    modal: false,
    nome: "",
    areaAtuacao: "",
    inicio: "",
    fim: "",
    respRH: "",
    telRH: "",
    emailRH: "",
    instrumento: {},
    equipeProjeto: [],
    edicao: {},
    update: false,
    archiveds: {
      clientes: [],
      ultimoProjeto: [],
      nomeProjetos: [],
    },
    loading: false,
    archivedsLoading: false,

    sortField: "createdAt",
    sortOrder: 1,
    archivedSortField: "createdAt",
    archivedSortOrder: 1,
    openTour: false
  };

  closeArquivarClienteModal = () => {
    this.setState((prev) => {
      prev.modalArquivar.active = false;
      prev.modalArquivar.cliente = null;
      prev.modalArquivar.message = null;

      return prev;
    });
  };

  closeRestoreClienteModal = () => {
    this.setState((prev) => {
      prev.modalRestore.active = false;
      prev.modalRestore.cliente = null;
      prev.modalRestore.message = null;

      return prev;
    });
  };

  loadLastModifiedProjects = async (enterprises) => {
    const userId = sessionStorage.getItem("user");
    const sortField = "lastModified";
    const sortOrder = -1;

    const res = await apiCalls.clients.getProjectByUserIdDashboard(
      userId,
      undefined,
      0,
      sortField,
      sortOrder,
      undefined,
      true,
      true
    );
    const { projects } = res.data;
    const clientsSet = [...new Set(projects.map((project) => project.cliente))];

    let featuredProjects = clientsSet.map((clientId) => {
      const clientProjects = projects.filter(
        (project) => project.cliente === clientId
      );
      const enterpriseData = enterprises.find(
        (enterprise) => enterprise._id === clientId
      );

      if (!enterpriseData) return null;

      let clientLastProject = clientProjects[0];
      // clientLastProject.updatedAt = new Date(); // mock

      const featuredProject = {
        project: {
          name: clientLastProject.nome,
          _id: clientLastProject._id,
          lastModifiedDate: clientLastProject.lastModified.date
        },
        enterpriseId: enterpriseData._id,
      };

      return featuredProject;
    });

    featuredProjects = featuredProjects.filter((featured) => featured);

    enterprises = enterprises.map((enterprise) => {
      const lastProjectFinded = featuredProjects.find(
        (fp) => fp.enterpriseId === enterprise._id
      )

      let lastDate = null
      if (lastProjectFinded && lastProjectFinded.project.lastModifiedDate && new Date(lastProjectFinded.project.lastModifiedDate) !== 'Invalid Date') {
        lastDate = new Date(lastProjectFinded.project.lastModifiedDate)
      }

      return {
        ...enterprise,
        lastModifiedDate: lastDate,
        ultimaAlteracao: featuredProjects.find(
          (fp) => fp.enterpriseId === enterprise._id
        ) || {project: {name: '-', lastModifiedDate: null}},
      }
    });

    this.setState({
      lastModifiedProjects: featuredProjects,
      clientes: enterprises.filter((e) => e.active),
      archiveds: {
        ...this.state.archiveds,
        clientes: enterprises.filter((e) => !e.active),
      },
    });
    return featuredProjects;
  };

  async componentDidMount() {
    this.setState({ loading: true });

    const response = await api.get("api/enterprise/");
    let { clientes, ultimoProjeto, nomeProjetos } = response.data;

    clientes = clientes.map((cliente) => ({
      ...cliente,
      ultimoProjeto:
        ultimoProjeto.find((up) => up.id === cliente._id) && ultimoProjeto.find((up) => up.id === cliente._id).projeto || "-",
    }));

    this.setState({ clientes, ultimoProjeto, nomeProjetos, loading: false });

    const archivedsClients = await this.loadArchivedsClients();

    await this.loadLastModifiedProjects([...clientes, ...archivedsClients]);

    this.orderBy("createdAt");
    this.orderArchivedBy("createdAt");

    const resCanAccess = await verifyModulesAccess(sessionStorage.getItem('user'));

    this.setState({ canAccess: resCanAccess })

    if (clientes && clientes.length) {
      const { setSteps, setCurrentStep, currentStep } = this.context;

      let steps= [
        {
          selector: '[data-tour="clients-step-1"]',
          content:
            <>
            <TourP>
              Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
              Clicando aqui, irá aparecer o modal de cadastro do cliente.
            </TourP>
            <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
            </>,
          },
          {
            selector: '[data-tour="clients-step-2"]',
            content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
          },

          {
            selector: '[data-tour="clients-step-3"]',
            content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
          },
          {
            selector: '[data-tour="clients-step-4"]',
            content: <TourP>Aqui você pode desarquivar algum cliente que foi arquivado</TourP>
          },
          {
            selector: '[data-tour="clients-step-5"]',
            content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
          },
        ];

        if (!this.state.archiveds.clientes.length) {
          steps = [                {
            selector: '[data-tour="clients-step-1"]',
            content:
              <>
              <TourP>
                Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
                Clicando aqui, irá aparecer o modal de cadastro do cliente.
              </TourP>
              <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
              </>,
            },
            {
              selector: '[data-tour="clients-step-2"]',
              content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
            },

            {
              selector: '[data-tour="clients-step-3"]',
              content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
            },
            {
              selector: '[data-tour="clients-step-5"]',
              content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
            },]
        }

        setCurrentStep(0);
        setSteps(steps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clientes.length !== prevState.clientes.length || this.state.archiveds.clientes.length !== prevState.archiveds.clientes.length) {
      let steps = [];
      const { setSteps, setCurrentStep, currentStep } = this.context;

      if (this.state.clientes && this.state.clientes.length) {
        steps= [
          {
            selector: '[data-tour="clients-step-1"]',
            content:
              <>
              <TourP>
                Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
                Clicando aqui, irá aparecer o modal de cadastro do cliente.
              </TourP>
              <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
              </>,
            },
            {
              selector: '[data-tour="clients-step-2"]',
              content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
            },

            {
              selector: '[data-tour="clients-step-3"]',
              content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
            },
            {
              selector: '[data-tour="clients-step-4"]',
              content: <TourP>Aqui você pode desarquivar algum cliente que foi arquivado</TourP>
            },
            {
              selector: '[data-tour="clients-step-5"]',
              content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
            },
          ];

          if (!this.state.archiveds.clientes.length) {
            steps = [                {
              selector: '[data-tour="clients-step-1"]',
              content:
                <>
                <TourP>
                  Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
                  Clicando aqui, irá aparecer o modal de cadastro do cliente.
                </TourP>
                <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
                </>,
              },
              {
                selector: '[data-tour="clients-step-2"]',
                content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
              },

              {
                selector: '[data-tour="clients-step-3"]',
                content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
              },
              {
                selector: '[data-tour="clients-step-5"]',
                content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
              },]
          }

        }
        setCurrentStep(0);
        setSteps(steps);
    }
  }

  componentWillUnmount() {
    const { setSteps, setCurrentStep, currentStep } = this.context;
    setCurrentStep(0);
    setSteps([]);
  }

  arrumaData(data) {
    const dateIn = data.split("-");
    const FixDay = dateIn[2].split("T");
    const finalDate = FixDay[0] + "-" + dateIn[1] + "-" + dateIn[0];
    return finalDate;
  }

  loadArchivedsClients = async () => {
    this.setState({ archivedsLoading: true });
    const response = await api.get("api/enterprise/list-archiveds");
    let { clientes, ultimoProjeto, nomeProjetos } = response.data;

    clientes = clientes.map((cliente) => ({
      ...cliente,
      ultimoProjeto: ultimoProjeto.find((up) => up.id === cliente._id).projeto,
    }));

    this.setState({
      archiveds: {
        clientes,
        ultimoProjeto,
        nomeProjetos,
      },
      archivedsLoading: false,
    });

    return clientes;
  };

  handleSelectClient = (e) => {
    sessionStorage.setItem("cliente", e.target.value);
    this.setState({ modalNovo: true });
    //this.props.history.push("/projetos/projeto");
  };
  cancela = (e) => {
    this.setState({ modalNovo: false });
    //this.props.history.push("/projetos/projeto");
  };
  configurarCliente = (client) => {
    sessionStorage.setItem("cliente", client._id);
    this.setState({
      edicao: client,
      update: Object.keys(client).length,
      modal: true,
    });
  };
  listarProjetos = (client) => {
    sessionStorage.setItem("cliente", client._id);
    sessionStorage.setItem("clienteNome", client.nomeFantasia);
    sessionStorage.setItem("clienteLogo", client.logo ? client.logo : "");
    this.props.history.push("/clientes/projetos");
  };

  handleArquivarClienteClick = (client) => {
    this.setState((prev) => {
      let mode;
      let message;

      if (client.projetos && client.projetos.length) {
        mode = MODAL_ARQUIVAR_MODES.TO_FILE;
        message = `Deseja arquivar o cliente ${client.nomeFantasia}?`;
      } else {
        mode = MODAL_ARQUIVAR_MODES.DELETE;
        message = `Deseja excluir o cliente ${client.nomeFantasia}?`;
      }

      prev.modalArquivar.active = true;
      prev.modalArquivar.cliente = client;
      prev.modalArquivar.mode = mode;
      prev.modalArquivar.message = message;

      return prev;
    });
  };

  handleRestoreClientClick = (client) => {
    this.setState((prev) => {
      const message = `Deseja restaurar o cliente ${client.nomeFantasia}?`;

      prev.modalRestore.active = true;
      prev.modalRestore.cliente = client;
      prev.modalRestore.message = message;

      return prev;
    });
  };

  restoreClient = async (client) => {
    try {
      await api.put(`api/enterprise/restore/${client._id}`);
      this.setState((prev) => {
        prev.clientes.push(client);
        prev.archiveds.clientes = prev.archiveds.clientes.filter(
          (c) => c._id !== client._id
        );

        return prev;
      });

      const { setSteps, setCurrentStep, currentStep } = this.context;

      let steps= [
        {
          selector: '[data-tour="clients-step-1"]',
          content:
            <>
            <TourP>
              Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
              Clicando aqui, irá aparecer o modal de cadastro do cliente.
            </TourP>
            <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
            </>,
          },
          {
            selector: '[data-tour="clients-step-2"]',
            content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
          },

          {
            selector: '[data-tour="clients-step-3"]',
            content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
          },
          {
            selector: '[data-tour="clients-step-4"]',
            content: <TourP>Aqui você pode desarquivar algum cliente que foi arquivado</TourP>
          },
          {
            selector: '[data-tour="clients-step-5"]',
            content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
          },
        ];

        if (!this.state.archiveds.clientes.length) {
          steps = [                {
            selector: '[data-tour="clients-step-1"]',
            content:
              <>
              <TourP>
                Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
                Clicando aqui, irá aparecer o modal de cadastro do cliente.
              </TourP>
              <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
              </>,
            },
            {
              selector: '[data-tour="clients-step-2"]',
              content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
            },

            {
              selector: '[data-tour="clients-step-3"]',
              content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
            },
            {
              selector: '[data-tour="clients-step-5"]',
              content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
            },]
        }

        setCurrentStep(0);
        setSteps(steps);

      notifySuccess("Cliente restaurado com sucesso!");
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível restaurar o cliente. Por favor, tente novamente");
    }
  };

  toggle = () => {
    this.setState((prev) => {
      prev.modalArquivar.active = !prev.modalArquivar.active;
      return prev;
    });
  };

  toggleRestore = () => {
    this.setState((prev) => {
      prev.modalRestore.active = !prev.modalRestore.active;
      return prev;
    });
  };

  toggleAdicionarCliente = () => {
    this.setState((previousState) => ({
      modal: !previousState.modal,
      update: false,
      edicao: {},
    }));
  };

  async deleteClient(cliente) {
    try {
      // Desativando cliente no banco
      await api.delete(`api/enterprise/${cliente._id}`);

      this.setState((prev) => {
        prev.clientes = prev.clientes.filter((c) => c._id !== cliente._id);

        return prev;
      });
      notifySuccess("Cliente excluído com sucesso!");
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível excluir o cliente. Por favor, tente novamente");
    }
  }

  async archieveClient(cliente) {
    try {
      // Desativando cliente no banco
      await api.put(`api/enterprise/arquivar/${cliente._id}`);
      this.setState((prev) => {
        prev.archiveds.clientes.push(cliente);
        prev.clientes = prev.clientes.filter((c) => c._id !== cliente._id);

        return prev;
      });

      let steps = [];

      const { setSteps, setCurrentStep, currentStep } = this.context;
      if (this.state.clientes.length) {

      steps= [
        {
          selector: '[data-tour="clients-step-1"]',
          content:
            <>
            <TourP>
              Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
              Clicando aqui, irá aparecer o modal de cadastro do cliente.
            </TourP>
            <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
            </>,
          },
          {
            selector: '[data-tour="clients-step-2"]',
            content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
          },

          {
            selector: '[data-tour="clients-step-3"]',
            content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
          },
          {
            selector: '[data-tour="clients-step-4"]',
            content: <TourP>Aqui você pode desarquivar algum cliente que foi arquivado</TourP>
          },
          {
            selector: '[data-tour="clients-step-5"]',
            content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
          },
        ];

        if (!this.state.archiveds.clientes.length) {
          steps = [                {
            selector: '[data-tour="clients-step-1"]',
            content:
              <>
              <TourP>
                Para começar, vamos criar um cliente. Será o cadastro da empresa em que você fará o projeto.
                Clicando aqui, irá aparecer o modal de cadastro do cliente.
              </TourP>
              <TourP>Preencha os dados da empresa. Lembre-se que há campos obrigatórios a serem preenchidos, representados pelo “*”.</TourP>
              </>,
            },
            {
              selector: '[data-tour="clients-step-2"]',
              content: <TourP>Caso precise ajustar algo, clique na engrenagem que o pop-up para fazer as alterações será aberto</TourP>,
            },

            {
              selector: '[data-tour="clients-step-3"]',
              content: <TourP>Você pode arquivar o cliente e dessa forma ele não constará mais na lista.</TourP>
            },
            {
              selector: '[data-tour="clients-step-5"]',
              content: <TourP>Neste botão será apresentado a lista de todos os projetos que você possui neste cliente. Para criar um novo projeto, clique aqui.</TourP>
            },]
        }

      }
      setCurrentStep(0);
      setSteps(steps);

      notifySuccess("cliente arquivado com sucesso!");
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível arquivar o cliente. Por favor, tente novamente");
    }
  }

  handleArquivarClienteModal = async () => {
    const { modalArquivar } = this.state;
    const { cliente, mode } = modalArquivar;

    if (mode === MODAL_ARQUIVAR_MODES.TO_FILE) {
      await this.archieveClient(cliente);
    }
    if (mode === MODAL_ARQUIVAR_MODES.DELETE) {
      await this.deleteClient(cliente);
    }

    this.toggle();
  };

  handleRestoreClienteModal = async () => {
    const { modalRestore } = this.state;
    const { cliente } = modalRestore;

    await this.restoreClient(cliente);

    this.toggleRestore();
  };

  notify = (mensagem) => {
    toast.success(mensagem, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  filterArray = (text) => {
    let filtered = this.state.clientes;

    if (text) {
      const textReplaced = text.replaceAll(" ", "");
      let letras = /([A-Z])+/gi;
      if (letras.test(textReplaced)) {
        filtered = this.state.clientes.filter((c) => {
          const searchFiltered = removeSpecials(text).trim();
          const nameFiltered = removeSpecials(c.nomeFantasia).trim();
          return nameFiltered
            .toLowerCase()
            .includes(searchFiltered.toLowerCase());
        });
      } else {
        filtered = this.state.clientes.filter((c) => {
          return c.cnpj
            .replace(/[/.-]/gi, "")
            .trim()
            .includes(text.replace(/[/.-]/gi, "").trim());
        });
      }
    }

    return filtered;
  };

  filterArchivedsArray = (text) => {
    let filtered = this.state.archiveds.clientes;

    if (text) {
      const textReplaced = text.replaceAll(" ", "");
      let letras = /([A-Z])+/gi;
      if (letras.test(textReplaced)) {
        filtered = this.state.archiveds.clientes.filter((c) => {
          const searchFiltered = removeSpecials(text).trim();
          const nameFiltered = removeSpecials(c.nomeFantasia).trim();
          return nameFiltered
            .toLowerCase()
            .includes(searchFiltered.toLowerCase());
        });
      } else {
        filtered = this.state.archiveds.clientes.filter((c) => {
          return c.cnpj
            .replace(/[/.-]/gi, "")
            .trim()
            .includes(text.replace(/[/.-]/gi, "").trim());
        });
      }
    }

    return filtered;
  };

  search = (text) => {
    this.setState({ searchQuery: text });
  };

  /**
   * @description Pega o id do cliente para atualizar a logo
   */
  handleEnterpriseLogoUpload = async (enterpriseId, logo) => {
    const url = `/api/enterprise/${enterpriseId}/update-logo`;

    const formData = new FormData();

    formData.append("logo", logo);

    try {
      const resp = await api.patch(url, formData);
      const { msg, enterprise } = resp.data;
      notifySuccess(msg);

      return enterprise;
    } catch (err) {
      console.error(err);
      notifyError('Não foi possível atualizar a logo do cliente. Por favor, recarregue a página e tente novamente');
    }
  };

  handleForm = async (form) => {
    try {
      delete form.edicao;

      if (!Object.keys(this.state.edicao).length) {
        delete form.projetos;
        delete form.createdAt;
        delete form.createdBy;
        delete form.creditos;
        delete form.tipo;
        delete form.__v;
        delete form._id;
        await api
          .post("api/enterprise/", {
            ...form,
          })
          .then(async (res) => {
            const updatedClients = res.data;
            const lastIndex = updatedClients.length - 1;

            if (form.logo) {
              const enterpriseId = updatedClients[lastIndex]._id;
              const res2 = await this.handleEnterpriseLogoUpload(
                enterpriseId,
                form.logo
              );
              updatedClients[lastIndex].logo = res2.logo;
            }

            updatedClients[lastIndex].ultimaAlteracao = {
              project: {
                lastModifiedDate: null,
                name: '-'
              }
            }

            updatedClients[lastIndex].ultimoProjeto = '-'

            const newClients = updatedClients.map(c => {
              const finded = this.state.clientes.find(u => u._id === c._id)

              let obj = {
                ...c
              }

              if (finded) {
                obj = {
                  ...c,
                  ...finded
                }
              }

              return obj
            })

            const sortedClients = this.orderBy('createdAt', 1, newClients)

            this.notify("Criado cliente!");
            this.setState({
              clientes: sortedClients,
              sortOrder: 1,
              sortField: 'createdAt',
              modal: false,
              edicao: {},
            });
          })
          .catch((err) => {
            console.error(err);

            if (err && err.response && err.response.status === 401) {
              notifyError(err.response.data.error);
            } else {
              notifyError("Não foi possível cadastrar. Por favor, tente novamente");
            }

          });
      } else {
        // Atualizando
        await api
          .put(`api/enterprise/${sessionStorage.getItem("cliente")}`, {
            ...form,
          })
          .then(async (res) => {
            const updatedClients = res.data;

            if (form.logoRemoved) {
              await apiCalls.clientes.deleteEnterpriseImg(form._id);
              const index = updatedClients.findIndex((c) => c._id === form._id);
              updatedClients[index].logo = null;
            } else if (form.logo) {
              const res2 = await this.handleEnterpriseLogoUpload(
                form._id,
                form.logo
              );
              const index = updatedClients.findIndex((c) => c._id === res2._id);
              updatedClients[index].logo = res2.logo;
            }

            const newClients = this.state.clientes.map(c => {
              const finded = updatedClients.find(u => u._id === c._id)

              let obj = {
                ...c
              }

              if (finded) {
                obj = {
                  ...c,
                  ...finded
                }
              }

              return obj
            })

            sessionStorage.setItem("clienteNome", res.data.nomeFantasia);
            this.notify("Salvo!");
            this.setState({
              clientes: newClients,
              modal: false,
              edicao: {},
            });
          })
          .catch((err) => this.notify(err.response.data.error)); //this.notify(err.response.data.error));
      }
    } catch (error) {
      console.error(error);

      if (error && error.response && error.response.status === 401) {
        notifyError(error.response.data.error)
      }

      notifyError("Não foi possível criar/editar Cliente. Por favor, tente novamente");
    }
  };

  orderBy = (field, orderSort, clients) => {
    const { clientes, sortField, sortOrder } = this.state;

    let order = orderSort ? orderSort : sortOrder;

    if (sortField !== field && !orderSort) {
      order = -1;
    }

    let clientsState = clients && clients.length ? [...clients] : [...clientes];

    if (field === "ultimaAlteracao") {
      clientsState.sort((a, b) => {
        if (!a.ultimaAlteracao) return 0;

        if (
          a.ultimaAlteracao.project.name.toLowerCase() ===
           b.ultimaAlteracao.project.name.toLowerCase()
        ) {
          return 0;
        }
        return (
          (a.ultimaAlteracao.project.name.toLowerCase() <
           b.ultimaAlteracao.project.name.toLowerCase()
            ? -1
            : 1) * -order
        );
      });
    } else if (field === "orcamentoTotal") {
      clientsState.sort((a, b) => {
        if (
          parseInt(a.creditos.livre, 10) +
            parseInt(a.creditos.bloqueado, 10) ===
          parseInt(b.creditos.livre, 10) + parseInt(b.creditos.bloqueado, 10)
        ) {
          return 0;
        }
        return (
          (parseInt(a.creditos.livre, 10) + parseInt(a.creditos.bloqueado, 10) <
          parseInt(b.creditos.livre, 10) + parseInt(b.creditos.bloqueado, 10)
            ? -1
            : 1) * -order
        );
      });
    } else if (field === "orcamentoConsumido") {
      clientsState.sort((a, b) => {
        if (
          a.creditos.bloqueado.toLowerCase() ===
          b.creditos.bloqueado.toLowerCase()
        ) {
          return 0;
        }
        return (
          (a.creditos.bloqueado.toLowerCase() <
          b.creditos.bloqueado.toLowerCase()
            ? -1
            : 1) * -order
        );
      });
    } else if (field === 'lastModifiedDate') {
      let stringOrder = 'asc'

      if (order === -1) stringOrder = 'desc'

      const nullDates = clientsState.filter(c => !c.lastModifiedDate)

      const truthyDates = clientsState.filter(c => c.lastModifiedDate)

      clientsState = _.orderBy(truthyDates, ['lastModifiedDate'], [stringOrder])

      if (order === -1) {
        clientsState = [...clientsState, ...nullDates]
      } else {
        clientsState = [...nullDates, ...clientsState]
      }
    } else {
      clientsState.sort((a, b) => {
        if (a[field].toLowerCase() === b[field].toLowerCase()) {
          return 0;
        }
        return (
          (a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1) * -order
        );
      });
    }

    if (clients && clients.length) {
      return clientsState
    } else {
      this.setState({
        clientes: clientsState,
        sortOrder: -order,
        sortField: field,
      });
    }

  };

  orderArchivedBy = (field) => {
    const { archiveds, archivedSortField, archivedSortOrder } = this.state;

    let order = archivedSortOrder;

    if (archivedSortField !== field) {
      order = -1;
    }

    let clientsState = [...archiveds.clientes];

    if (field === "ultimaAlteracao") {
      clientsState.sort((a, b) => {
        if (!a.ultimaAlteracao) return 0;

        if (
          a.ultimaAlteracao.project.name.toLowerCase() ===
          b.ultimaAlteracao.project.name.toLowerCase()
        ) {
          return 0;
        }
        return (
          (a.ultimaAlteracao.project.name.toLowerCase() <
          b.ultimaAlteracao.project.name.toLowerCase()
            ? -1
            : 1) * -order
        );
      });
    } else if (field === "orcamentoTotal") {
      clientsState.sort((a, b) => {
        if (
          parseInt(a.creditos.livre, 10) +
            parseInt(a.creditos.bloqueado, 10) ===
          parseInt(b.creditos.livre, 10) + parseInt(b.creditos.bloqueado, 10)
        ) {
          return 0;
        }
        return (
          (parseInt(a.creditos.livre, 10) + parseInt(a.creditos.bloqueado, 10) <
          parseInt(b.creditos.livre, 10) + parseInt(b.creditos.bloqueado, 10)
            ? -1
            : 1) * -order
        );
      });
    } else if (field === "orcamentoConsumido") {
      clientsState.sort((a, b) => {
        if (
          a.creditos.bloqueado.toLowerCase() ===
          b.creditos.bloqueado.toLowerCase()
        ) {
          return 0;
        }
        return (
          (a.creditos.bloqueado.toLowerCase() <
          b.creditos.bloqueado.toLowerCase()
            ? -1
            : 1) * -order
        );
      });
    } else if (field === 'lastModifiedDate') {
      let stringOrder = 'asc'

      if (order === -1) stringOrder = 'desc'

      const nullDates = clientsState.filter(c => !c.lastModifiedDate)

      const truthyDates = clientsState.filter(c => c.lastModifiedDate)

      clientsState = _.orderBy(truthyDates, ['lastModifiedDate'], [stringOrder])

      if (order === -1) {
        clientsState = [...clientsState, ...nullDates]
      } else {
        clientsState = [...nullDates, ...clientsState]
      }
    } else {
      clientsState.sort((a, b) => {
        if (a[field].toLowerCase() === b[field].toLowerCase()) {
          return 0;
        }
        return (
          (a[field].toLowerCase() < b[field].toLowerCase() ? -1 : 1) * -order
        );
      });
    }

    this.setState({
      archiveds: {
        ...archiveds,
        clientes: clientsState,
      },
      archivedSortOrder: -order,
      archivedSortField: field,
    });
  };

  render() {
    const { modalArquivar, nomeProjetos, modalRestore } = this.state;

    if (this.state.loading) return <Loading />;

    const filteredClients = this.filterArray(this.state.searchQuery);
    const filteredArchivedClients = this.filterArchivedsArray(
      this.state.searchQuery
    );

    return (
      <div className="appster">
        {/* <Tour
        isOpen={this.state.openTour}
        steps={[
          {
            selector: '[data-tour="step-1"]',
            content: "TESTE 1"
          },
          {
            selector: '[data-tour="step-2"]',
            content: "TESTE 2"
          },
          {
            selector: '[data-tour="step-3"]',
            content: "TESTE 3"
          },
          {
            selector: '[data-tour="step-4"]',
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus volutpat quam eu mauris euismod imperdiet. Nullam elementum fermentum neque a placerat. Vivamus sed dui nisi. Phasellus vel dolor interdum, accumsan eros ut, rutrum dolor. Etiam in leo urna. Vestibulum maximus "
          },
        ]}/> */}

        <Modal toggle={this.toggleModalSendEmail} isOpen={modalArquivar.active}>
          <ModalHeader toggle={this.toggleModalSendEmail}>Atenção!</ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            {modalArquivar.message}
          </ModalBody>
          <ModalFooter>
            <ButtonCancel onClick={this.closeArquivarClienteModal}>
              Não
            </ButtonCancel>
            <ButtonSave onClick={this.handleArquivarClienteModal}>
              Sim
            </ButtonSave>
          </ModalFooter>
        </Modal>
        <Modal toggle={this.toggleModalSendEmail} isOpen={modalRestore.active}>
          <ModalHeader toggle={this.toggleModalSendEmail}>Atenção!</ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>{modalRestore.message}</ModalBody>
          <ModalFooter>
            <ButtonCancel onClick={this.closeRestoreClienteModal}>
              Não
            </ButtonCancel>
            <ButtonSave onClick={this.handleRestoreClienteModal}>
              Sim
            </ButtonSave>
          </ModalFooter>
        </Modal>
        <BaseProjeto disableDynamicWidth>
          <Container fluid>
            <Breadcrumb />

            <hr />

            <Row>
              <Col>
                <div style={{ display: "flex", fontSize: 12 }}>
                  <div style={{ fontSize: 12, marginRight: 50 }}>
                    <Search
                      style={{ width: 360 }}
                      searchFunction={this.search}
                      placeholder="Buscar por CNPJ ou Nome fantasia"
                    />
                  </div>
                  <div data-tour="clients-step-1" style={{ display: "flex", alignItems: "center" }}>
                    <AdicionarCliente id={'add-client-btn'} onClick={this.toggleAdicionarCliente}>
                      + Adicionar Cliente
                    </AdicionarCliente>
                    <div>
                      <ClienteCadastro
                        modal={this.state.modal}
                        handleForm={this.handleForm}
                        toggle={this.toggleAdicionarCliente}
                        edicao={this.state.edicao}
                        modalTitle={
                          this.state.update
                            ? "Configurar cliente"
                            : "Cadastrar cliente"
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                {this.state.clientes && this.state.clientes.length ? (
                  <ClientsTable
                    filteredClients={filteredClients}
                    canAccess={this.state.canAccess}
                    nomeProjetos={nomeProjetos}
                    ultimoProjeto={this.state.ultimoProjeto}
                    configurarCliente={this.configurarCliente}
                    listarProjetos={this.listarProjetos}
                    excluir={this.handleArquivarClienteClick}
                    lastModifiedProjects={this.state.lastModifiedProjects}
                    orderBy={this.orderBy}
                    sortField={this.state.sortField}
                    sortOrder={this.state.sortOrder}
                  />
                ) : (
                  <span style={{ fontSize: 12 }}>
                    Ainda não há clientes cadastrados
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                {this.state.archiveds.clientes &&
                this.state.archiveds.clientes.length ? (
                  <ShowArchived dataTour='clients-step-4'>
                    <ClientsTable
                      filteredClients={filteredArchivedClients}
                      ultimoProjeto={this.state.archiveds.ultimoProjeto}
                      configurarCliente={this.configurarCliente}
                      listarProjetos={this.listarProjetos}
                      restaurar={this.handleRestoreClientClick}
                      archivedsTable={true}
                      lastModifiedProjects={this.state.lastModifiedProjects}
                      orderBy={this.orderArchivedBy}
                      sortField={this.state.archivedSortField}
                      sortOrder={this.state.archivedSortOrder}/>
                  </ShowArchived>
                ) : null}
              </Col>
            </Row>
          </Container>
        </BaseProjeto>
      </div>
    );
  }
}

export default Clientes;
