import React, { Component } from 'react';
import { getSVGComponent } from 'views/Components/positionInBard3';

export default class LocusDeControle extends Component {
  state = {
    series: [],
    options: {},
  };

  getData = () => {
    const { 
      resultado,
      respondentes
    } = this.props;
    return [
      {
        name: 'Autoavaliação',
        nota: resultado.locusInternoNormalizado,
        graficLowLabel: 'Locus de controle Externo',
        graficHighLabel: 'Locus de controle Interno'
      },
    ].concat(
      respondentes.map(respondente => ({
        name: this.props.isConsultor
          ? `${respondente.nome} ${respondente.relacao}`
          : respondentes.length >= 3
          ? `${respondente.relacao}`
          : 'Geral',
        nota: respondente.resposta.locusInternoNormalizado,
      })),
    );
  };

  getGraphHeight(vh, max) {
    const calculatedSize = max * 90;
    if (calculatedSize > 0.7 * vh) {
      return 0.7 * vh;
    }
    if (calculatedSize < 250) {
      return 250;
    }
    return calculatedSize;
  }

  componentDidMount() {
    const max = this.props.respondentes.length && this.props.respondentes[0].resposta ? this.props.respondentes.length + 1 : 1;
    const yMax = (max % 2 === 0 ? max + 1 : max) + 1;
    const vh = window.innerHeight;
    const data = this.getData();
    getSVGComponent(
      data,
      1500,
      (this.getGraphHeight(vh, yMax) / 1.5),
      {
        top: 30,
        right: 0,
        bottom: 20,
        left: 40,
      },
      data.map(value => value.name),
      data => ({
        name: data.name,
        '<21': 21,
        '<48': 27,
        '<74': 26,
        '<100': 26,
      }),
      ['<21', '<48', '<74', '<100'],
      {
        '<21': '#ff0000',
        '<48': '#dd5500',
        '<74': '#aaaa00',
        '<100': '#00ff00',
      },
      true,
      `graph-locus${this.props.id}`,
    );
  }

  render() {
    const max = this.props.respondentes.length + 1;
    const yMax = (max % 2 === 0 ? max + 1 : max) + 1;
    const vh = window.innerHeight;
    return (
      <div id={`distribution-graph${this.props.id}`}>
        <svg
          id={`graph-locus${this.props.id}`}
          width='100%'
          height={this.getGraphHeight(vh, yMax)}
        ></svg>
      </div>
    );
  }
}
