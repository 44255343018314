import React from 'react';
import Chart from "chart.js";

import * as EstilosDeComunicacaoGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import EstiloDeComunicacaoGraphUtils from './EstiloDeComunicacaoGraphUtils';

export default class EstiloDeComunicacaoGraph extends React.Component {
  
  state = {
    userResult: null
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, EstiloDeComunicacaoGraphUtils.graphConfig(this.props.graphType));
  }

  getSession(state) {
    return state.sessions
      .filter(session => session)
      .filter(session => session.participante)
      .find(session => session.participante._id === this.props.participantId);
  }

  getPerception(state) {
    const session = this.getSession(state);
    return state.perceptions
      .filter(perception => perception)
      .find(perception => perception.sessao === session._id);
  }

  drawParticipant(state) {
    const datasets = EstiloDeComunicacaoGraphUtils.drawParticipant({
      result: state.userResult,
      graphType: this.props.graphType
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  componentDidMount() {
    let state = {};

    EstilosDeComunicacaoGraphService.loadUserResult({ 
      projectId: this.props.projectId, 
      participantId: this.props.participantId, 
      assincronoId: this.props.assincronoId, 
      instrumentId: this.props.instrumentId
    })
    .then(res => {
      state.userResult = res;
    })
    .catch(err => {
      this.handleError(err);
      notifyError('Não foi possível montar o gráfico individual. Por favor, recarregue a página e tente novamente');
    }).finally(_ => {
      this.setState({ state });
      var canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };
      this.draw(state);
      this.drawParticipant(state);
    });
  }

  render() {
    return <canvas 
      id={this.props.id} 
      height={450}
      width={450}
      />;
  }
}