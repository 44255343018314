import ModalParticipantPhoto from "components/ModalParticipantPhoto";
import React, { useState } from "react";
/**
 * Componente para mostrar a foto de um usuário
 *
 * @export
 * @class Foto
 * @extends {Component}
 */

export default function Foto({ foto, size, cliente }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {foto && (
        <ModalParticipantPhoto toggle={toggle} isOpen={isOpen} photo={foto} />
      )}

      <div className="text-center" onClick={toggle}>
        <img
          // src={require("./images/" + this.props.foto)}
          src={
            foto
              ? `https://files-storage.s3.amazonaws.com/${foto}`
              : `https://files-storage.s3.amazonaws.com/avatar_default.png`
            // : require('../Assets/images/user.gif')
          }
          alt={cliente}
          style={{
            width: size,
            height: size,
            borderRadius: "50%",
            verticalAlign: "middle",
            objectFit: "cover",
            cursor: foto ? "pointer" : "default",
          }}
        />
      </div>
    </>
  );
}
