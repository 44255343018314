import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import { FaQuestionCircle, FaTrashAlt } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { ButtonCancel, ButtonSave } from '../../Pages/Assets/Assets';
import Folder from 'views/Pages/Assets/Folder';
import Input from './components/Input';
import RegisteredCpfModal from './components/RegisteredCpfModal';
import AlertModal from './components/AlertModal';
import { Constantes } from "Services/Constantes";
import api from "Services/api";
import { assincronosApi, reportsApi } from "Services/api";
import { notifyError, notifySuccess } from "Services/Notificacoes";
import { validarCPF } from "Services/Validador";
import ModalTakePicture from '../modal-take-picture';
import ReactCrop from 'components/ReactCrop';


import apiCalls from 'config/apiCalls';

function ModalEditarAcesso(props) {
  const [userFirstAccess, setUserFirstAccess] = useState(false);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmeSenha, setConfirmeSenha] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razao, setRazao] = useState('');
  const [fantasia, setFantasia] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [nr, setNr] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [user, setUser] = useState(null);
  const [registeredCpfModal, setRegisteredCpfModal] = useState(false);
  const [cpfSearching, setCpfSearching] = useState(false);
  const [emailExistente, setEmailExistente] = useState(false);

  const [disableNome, setDisableNome] = useState(true);
  const [disableEmail, setDisableEmail] = useState(true);
  const [disableTelefone, setDisableTelefone] = useState(true);
  const [disableSenha, setDisableSenha] = useState(true);
  const [disableConfirme, setDisableConfirme] = useState(true);
  const [disableCnpj, setDisableCnpj] = useState(true);
  const [disableRazao, setDisableRazao] = useState(true);
  const [disableFantasia, setDisableFantasia] = useState(true);
  const [disableCep, setDisableCep] = useState(true);
  const [disableLogradouro, setDisableLogradouro] = useState(false);
  const [disableNr, setDisableNr] = useState(false);
  const [disableComplemento, setDisableComplemento] = useState(false);
  const [disableBairro, setDisableBairro] = useState(false);
  const [disableCidade, setDisableCidade] = useState(false);
  const [disableUf, setDisableUf] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const [validCpf, setValidCpf] = useState(true);
  const [validNome, setValidNome] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validTelefone, setValidTelefone] = useState(true);
  const [validSenha, setValidSenha] = useState(true);
  const [validConfirm, setValidConfirm] = useState(true);
  const [validCnpj, setValidCnpj] = useState(true);
  const [validRazao, setValidRazao] = useState(true);
  const [validFantasia, setValidFantasia] = useState(true);
  const [validCep, setValidCep] = useState(true);
  const [validLogradouro, setValidLogradouro] = useState(true);
  const [validNr, setValidNr] = useState(true);
  const [validComplemento, setValidComplemento] = useState(true);
  const [validBairro, setValidBairro] = useState(true);
  const [validCidade, setValidCidade] = useState(true);
  const [validUf, setValidUf] = useState(true);

  const [alertModal, setAlertModal] = useState({ isOpen: false, msg: '', cb: null });

  const [deleteLogoModal, setDeleteLogoModal] = useState(false);
  const [modalTakePic ,setModalTakePic] = useState(false);
  const [cropFileName, setCropFileName] = useState('');
  const [cropImg, setCropImg] = useState(null);
  const [cropModal, setCropModal] = useState({ isOpen: false, type: '' });
  const [avatarUrl, setAvatarUrl] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarChanged, setAvatarChanged] = useState(false);
  const [avatarRemoved, setAvatarRemoved] = useState(false);
  const [logoConsultantUrl, setLogoConsultantUrl] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoChanged, setLogoChanged] = useState(false);
  const [logoConsultantRemoved, setLogoConsultantRemoved] = useState(false);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    x: 25,
    y: 25,
    aspect: 1/1
  });


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const cepMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{5})(\d)/, '$1-$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(-\d{3})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const cnpjMask = (value) => {
    return value
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
  }

  const mask = (o, f, erased) => {
    setTimeout(function() {
      var v = f(o.value, erased);
      if (v != o.value) {
        o.value = v;
      }
    }, 1);
  }

  const mask2 = (o, f, erased) => {

    var v = f(o, erased);
    return v;

  }

  const mphone = (v, erased) => {

    if (erased) return v;
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    }  else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  const validate = () => {

    setValidEmail(true);
    setValidNome(true);
    setValidTelefone(true);
    setValidSenha(true);
    setValidConfirm(true);
    setValidCnpj(true);
    setValidRazao(true);
    setValidFantasia(true);
    setValidCep(true);
    setValidLogradouro(true);
    setValidNr(true);
    setValidBairro(true);
    setValidCidade(true);
    setValidUf(true);


    let isValid = true;

    if (!nome) {
      setValidNome(false);
      isValid = false;
    }

    if (!email || !validateEmail(email)) {
      setValidEmail(false);
      isValid = false;
    }

    if (telefone && telefone.length < 14) {
      setValidTelefone(false);
      isValid = false;
    }

    if ((!user) || (user && !user.realizouPrimeiroAcesso)) {
      if (!senha) {
        setValidSenha(false);
        setValidConfirm(false);
        isValid = false;
      }

      if (senha.length < 6) {
        setValidSenha(false);
        setValidConfirm(false);
        isValid = false;
        notifyError('Senha precisa ter no mínimo 6 caracteres.');
      }

      if (senha !== confirmeSenha) {
        setValidSenha(false);
        setValidConfirm(false);
        isValid = false;
        notifyError('Senhas não correspondem.');
      }
    }

    if (cnpj && cnpj.length < 18) {
      setValidCnpj(false);
      isValid = false;
    }

    if (cnpj && !razao) {
      setValidRazao(false);
      isValid = false;
    }

    if (cnpj && !fantasia) {
      setValidFantasia(false);
      isValid = false;
    }

    if (!cep || cep.length < 8) {
      setValidCep(false);
      isValid = false;
    }

    if (!logradouro) {
      setValidLogradouro(false);
      isValid = false;
    }

    if (!nr) {
      setValidNr(false);
      isValid = false;
    }

    if (!bairro) {
      setValidBairro(false);
      isValid = false;
    }

    if (!cidade) {
      setValidCidade(false);
      isValid = false;
    }

    if (!uf) {
      setValidUf(false);
      isValid = false;
    }

    if (!isValid) notifyError('Há campos que precisam ser preenchidos corretamente.');

    return isValid;
  }

  const handleConfirm = async() => {
    try {
      const payload = {
        name: nome,
        cel: telefone,
        email,
        password: senha,
        cnpj,
        companyName: razao,
        tradingName: fantasia,
        postalCode: cep,
        street: logradouro,
        addressComplement: complemento,
        addressNumber: nr,
        neighborhood: bairro,
        city: cidade,
        state: uf,
        isParticipant: !!user
      }

      const isValid = validate();

      if (!isValid) return;

      if (avatarChanged) {
        try {
          if (avatarRemoved) {
            await api.delete(`/v3/delete-images/user?userId=${sessionStorage.getItem('user')}&propertyToDelete=avatar`);
            setAvatarRemoved(false);
            if (props.updateUserState) {
              props.updateUserState({
                avatar: '',
              });
            }
          } else {
            const formData = new FormData();

            formData.append("avatar", avatar);

            const resp = await api.put(`/api/user/${sessionStorage.getItem('user')}/avatar`, formData);
            if (props.updateUserState) {
              props.updateUserState({
                avatar: resp.data.user.avatar,
              });
            }
          }

        } catch (err) {
          const message = err.erro ? err.erro : err.message;
          console.error(message);
          notifyError('Não foi possível enviar o avatar. Por favor, selecione o avatar e tente novamente');
        }
      }

      if (logoChanged) {
        try {
          if (logoConsultantRemoved) {
            await api.delete(`/v3/delete-images/user?userId=${sessionStorage.getItem('user')}&propertyToDelete=consultantLogo`);
            setLogoConsultantRemoved(false);
            if (props.updateAsideState) {
              props.updateAsideState({
                consultantLogo: '',
              });
            }
          } else {
            const formData = new FormData();

            formData.append("consultantLogo", logo);

            const resp = await api.put(`/api/user/${sessionStorage.getItem('user')}/consultant-logo`, formData);
            if (props.updateAsideState) {
              props.updateAsideState({
                consultantLogo: resp.data.user.consultantLogo,
              });
            }
          }
        } catch (err) {
          const message = err.erro ? err.erro : err.message;
          console.error(message);
          notifyError('Não foi possível enviar a logo da consultoria. Por favor, selecione a logo e tente novamente');
        }
      }

      const response = await apiCalls.users.updateConsultant(sessionStorage.getItem('user'), payload);

      const nomeConsultor = razao || nome;

      sessionStorage.setItem("username", nome);
      sessionStorage.setItem("consultantLogo", response.data.user.consultantLogo);
      sessionStorage.setItem("userAvatar", response.data.user.avatar);
      sessionStorage.setItem("userConsultantName", nomeConsultor);
      if (props.updateAsideState) {
        props.updateAsideState({
          nomeUser: nomeConsultor
        });
      }

      notifySuccess("Salvo com sucesso");

      props.handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      const urlCreator = window.URL || window.webkitURL;
      urlCreator.revokeObjectURL(avatarUrl);
      urlCreator.revokeObjectURL(logoConsultantUrl);
      setAvatarUrl('');
      setLogoConsultantUrl('');
      setLogoChanged(false);
      setAvatarChanged(false);
    }
  }


  const toggleAlertModal = (msg, cb) => {
    setAlertModal({ isOpen: !alertModal.isOpen, msg, cb });
  }

  const onCnpjChange = async(e) => {
    setValidCnpj(true);
    setCnpj(cnpjMask(e.target.value));
    const cnpjReplaced = e.target.value && e.target.value.replaceAll('.', '').replace('-','').replace('/', '');

    if (!cnpjReplaced) {
      setRazao('');
      setFantasia('');
    }

    if (cnpjReplaced && cnpjReplaced.length === 14) {
      try {
        const res = await api.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpjReplaced}`);

        const { situacao_cadastral, razao_social, nome_fantasia, cep: cepRes } = res.data;

        if (situacao_cadastral !== 2) {
          setDisableFantasia(true);
          setDisableRazao(true);
          return toggleAlertModal('O CNPJ informado é inativo ou inapto. Informe um CNPJ válido.', () => {
            setCnpj('');
          });
        }

        setRazao(razao_social);
        setFantasia(nome_fantasia);

        setValidRazao(true);
        setValidFantasia(true);

        if (!nome_fantasia) setDisableFantasia(false);
        if (!razao_social) setDisableRazao(false);

        const cepReplace = cepRes.replaceAll('.', '').replace('-', '');
        onCepChange({ target: { value: cepReplace } });
      } catch (err) {
        console.error(err);
        if (err && err.response && (err.response.status === 400 || err.response.status === 404)) {
          setDisableFantasia(true);
          setDisableRazao(true);
          toggleAlertModal('O CNPJ informado não foi encontrado na base da Receita Federal.', () => {
            setCnpj('');
          });
        }
      }
    }
  }

  const onCepChange = async(e) => {
    setValidCep(true);

    const maskedCep = cepMask(e.target.value);

    setCep(maskedCep);

    if (!maskedCep) {
      setLogradouro('');
      setNr('');
      setComplemento('');
      setBairro('');
      setCidade('');
      setUf('');
    }

    if (maskedCep && maskedCep.length === 9) {
      try {
        const res = await api.get(`https://brasilapi.com.br/api/cep/v1/${e.target.value.replace('-','')}`);
        const { city, neighborhood, state, street } = res.data;

        setCidade(city);
        setBairro(neighborhood);
        setUf(state);
        setLogradouro(street);

        setDisableNr(false);
        setDisableComplemento(false);

        setValidLogradouro(true);
        setValidBairro(true);
        setValidCidade(true);
        setValidUf(true);
      } catch (err) {
        console.error(err);
        toggleAlertModal(<><p>O CEP informado não foi encontrado. Verifique no site dos Correios se o CEP está correto ou informe os dados manualmente.</p><a href='https://buscacepinter.correios.com.br/app/endereco/index.php' target='_blank'>https://buscacepinter.correios.com.br/app/endereco/index.php</a></>, () => {});
      }
    }

  }

  const toggleRegisteredCpfModal = () => {
    if (sessionStorage.getItem('registerModalCpf')) return;
    setRegisteredCpfModal(!registeredCpfModal);
  }

  const disableAllInputs = () => {
    setDisableNome(true);
    setDisableEmail(true);
    setDisableTelefone(true);
    setDisableSenha(true);
    setDisableConfirme(true);
    setDisableCnpj(true);
    setDisableRazao(true);
    setDisableFantasia(true);
    setDisableCep(true);
    setDisableLogradouro(true);
    setDisableNr(true);
    setDisableComplemento(true);
    setDisableBairro(true);
    setDisableCidade(true);
    setDisableUf(true);
    setDisableConfirm(true);
  }

  const resetState = () => {
    setUserFirstAccess(false);
    setUser(null);
    setNome('');
    setEmail('');
    setTelefone('');
    setSenha('');
    setConfirmeSenha('');
    setCnpj('');
    setRazao('');
    setFantasia('');
    setCep('');
    setLogradouro('');
    setNr('');
    setComplemento('');
    setBairro('');
    setCidade('');
    setUf('');
  }

  function addCpfPontuation(cpf) {
    if (!cpf) return;

    return cpf.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4");
  }

  const handleImgCrop = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          setCropImg(reader.result)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const openCropModal = (e, type) => {
    if (!e.target.files || !e.target.files.length) return;

    const file = e.target.files[0];

    if (
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg"
    ) {
      const fileName = e.target.files[0].name;

      handleImgCrop(e);
      setCropFileName(fileName);
      setCropModal({ isOpen: true, type });
    } else {
      notifyError("O formato da avatar deve ser .png, .jpg ou .jpeg. Tente novamente.");
    }
  }

  const toggleDeleteLogoModal = () => {
    setDeleteLogoModal(!deleteLogoModal)
  }

  const toggleTakePicModal = () => {
    setModalTakePic(!modalTakePic);
  }

  const handleFileChange = img => {
    const file = img;
    const urlCreator = window.URL || window.webkitURL;

    const url = urlCreator.createObjectURL(file);

    if (cropModal.type === 'user') {
      setAvatarUrl(url);
      setAvatar(file);
      setAvatarChanged(true);
      setCropModal(false);
      setAvatarRemoved(false);
    } else if (cropModal.type === 'consultant') {
      setLogoConsultantUrl(url);
      setLogo(file);
      setLogoChanged(true);
      setCropModal(false);
      setLogoConsultantRemoved(false);
    }

  }

  const onRemoveConsultantLogo = () => {
    setLogoConsultantUrl('');
    setLogo(null);
    setLogoChanged(true);
    setCropModal(false);
    setLogoConsultantRemoved(true);

    toggleDeleteLogoModal();
  }

  const onUploadUserPhoto = (fileToUpload) => {
    const urlCreator = window.URL || window.webkitURL;

    const url = urlCreator.createObjectURL(fileToUpload);
    setAvatarUrl(url);
    setAvatar(fileToUpload);
    setAvatarChanged(true);
    setCropModal(false);
    setAvatarRemoved(false);

    return url;
  }

  const onRemoveUserPhoto = () => {
    setAvatarUrl('');
    setAvatar(null);
    setAvatarChanged(true);
    setCropModal(false);
    setAvatarRemoved(true);
  }

  async function handleNovoEmail() {
    try {
      if (validateEmail(email)) {
        const res = await apiCalls.users.verifyUserEmail(email, sessionStorage.getItem('user'));

        const { haveUser } = res.data;

        setEmailExistente(!!haveUser);
        if (haveUser) {
          setValidEmail(false);
          setDisableConfirm(true);
        } else {
          setValidEmail(true);
          setDisableConfirm(false);
        }
      }
    } catch (err) {
      console.error(err);
      setValidEmail(false);
    }
  }

  return(
    <>
    <AlertModal
      toggle={toggleAlertModal}
      isOpen={alertModal.isOpen}
      cb={alertModal.cb}
      msg={alertModal.msg}
    />
    <Modal
      toggle={toggleDeleteLogoModal}
      isOpen={deleteLogoModal}
    >
      <ModalHeader toggle={toggleDeleteLogoModal}>
        Remover imagem
      </ModalHeader>
      <ModalBody style={{ fontSize: 12 }}>
        Deseja remover essa imagem ?
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={toggleDeleteLogoModal}>Cancelar</ButtonCancel>
        <ButtonSave  onClick={onRemoveConsultantLogo}>Remover</ButtonSave>
      </ModalFooter>
    </Modal>
    <ModalTakePicture
      foto={avatarRemoved ? null : avatarUrl ? avatarUrl : props.avatar ? props.avatar : null}
      toggle={toggleTakePicModal}
      isOpen={modalTakePic}
      handleUploadUserPhoto={onUploadUserPhoto}
      handleExcludeAvatar={onRemoveUserPhoto}
    />
    <ReactCrop
      fileName={cropFileName}
      minWidth={60}
      circularCrop={true}
      showCropModal={cropModal.isOpen}
      src={cropImg}
      crop={crop}
      onCancelCrop={() =>
      setCropModal(false)
      }
      onSubmitCrop={handleFileChange}
    />
    <Modal
      onOpened={async() => {
        resetState();
        if (sessionStorage.getItem('userCpf')) {
          const cpfW = sessionStorage.getItem('userCpf').replaceAll('.', '').replace('-','');
          setCpfSearching(true);
          const res = await apiCalls.users.getUserRegister(cpfW);
          const { user: userRes, company: companyRes, address: addressRes } = res.data;
          setUser(userRes);
          setCpfSearching(false);
          setDisableNome(false);
          setDisableEmail(false);
          setDisableTelefone(false);
          setDisableCnpj(false);
          setDisableSenha(false);
          setDisableConfirme(false);
          setDisableCep(false);
          setDisableConfirm(false);

          const { nome: userName, email: userEmail, celular } = userRes;
          const cnpjRes = companyRes && companyRes.cnpj || '';
          const razaoRes = companyRes && companyRes.company_name || '';
          const fantasiaRes = companyRes && companyRes.trading_name || '';

          const cepRes = addressRes && addressRes.postal_code || '';
          const logradouroRes = addressRes && addressRes.street || '';
          const nrRes = addressRes && addressRes.address_number || '';
          const complementoRes = addressRes && addressRes.address_complement || '';
          const bairroRes = addressRes && addressRes.neighborhood || '';
          const cidadeRes = addressRes && addressRes.city || '';
          const ufRes = addressRes && addressRes.state || '';

          const telReplaced = celular && celular.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
          const telMasked = telReplaced && mask2(telReplaced, mphone, false);

          if (cnpjRes) setDisableFantasia(false);
          if (cepRes) {
            setCep(cepMask(cepRes));
            setDisableNr(false);
            setDisableComplemento(false);
          }

          setNome(userName);
          setEmail(userEmail);
          setTelefone(telMasked);
          setCnpj(cnpjRes);
          setRazao(razaoRes);
          setFantasia(fantasiaRes);
          setLogradouro(logradouroRes);
          setNr(nrRes);
          setComplemento(complementoRes);
          setBairro(bairroRes);
          setCidade(cidadeRes);
          setUf(ufRes);
        }
      }}
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      size='xl'
    >
      <ModalHeader toggle={() => props.toggle()}>
        Edição de perfil
      </ModalHeader>
      <ModalBody>
        <div style={{ fontSize: 12 }}>


        <Row>
        { !sessionStorage.getItem('isPlayer') ? <>
            <Col className='imgs label' sm={2} style={{ padding: 0, textAlign: 'left' }}>
              <label
                title="Logo Consultoria"
                style={{ backgroundImage: `url(${logoConsultantRemoved ?  null : logoConsultantUrl || `https://files-storage.s3.amazonaws.com/${props.consultantLogo}`})`, marginRight: 20  }}
                className="avatar"
              >
                <input onChange={(e) => openCropModal(e, 'consultant')} type="file" />
                {logoConsultantRemoved ? "+" : logoConsultantUrl || props.consultantLogo ? null : "+"}
              </label>
              <span style={{ marginLeft: 40 }} className='label1'>Logo</span>
              {(logoConsultantUrl || props.consultantLogo) && !logoConsultantRemoved  ? <FaTrashAlt onClick={toggleDeleteLogoModal} style={{ marginBottom: 4, cursor: 'pointer' }} /> : null}
            </Col>
        </> : null }
            <Col className='imgs label' sm={2} style={{ padding: 0, textAlign: 'left' }}>
              <label
                onClick={toggleTakePicModal}
                title="Avatar Usuário"
                style={{ backgroundImage: `url(${avatarRemoved ? null : avatarUrl || `https://files-storage.s3.amazonaws.com/${props.avatar}`})`, marginRight: 20  }}
                className="avatar"
              >
                {avatarRemoved ? "+" : avatarUrl || props.avatar ? null : "+"}
              </label>
              <span style={{ marginLeft: 15 }} className='label2'>Foto pessoal</span>
            </Col>
            <Col style={{ height: 'fit-content', display: 'flex', flexDirection: 'row-reverse' }}>
              <Tooltip
                title={!sessionStorage.getItem('isPlayer') ?
                        "Clique nos circulos apropriados e inclua imagens como a logo da sua consultoria e/ou sua foto de perfil. As imagens devem ser no formato JPG ou PNG com no máximo 2Mb."
                        :
                        "Clique no circulo e inclua uma imagem para sua foto de perfil. A imagem deve ser no formato JPG ou PNG com no máximo 2Mb."}
                position="top"
                size="small"
                trigger="mouseenter"
                arrow="true"
                disabled={false}
              >
                <FaQuestionCircle size={12} />
              </Tooltip>
            </Col>
            </Row>




          <p style={{ background: '#002a4c', padding: 20, color: '#fff', textAlign: 'left', marginBottom: 20, marginTop: 40 }}><strong>Dados pessoais</strong></p>

          <Input
            disabled={disableNome}
            valid={validNome}
            whiteSpace={'nowrap'}
            style={{ marginTop: 5, width: '89%' }}
            name='nome'
            label={'Nome Completo*:'}
            onChange={(e)=>{
              setValidNome(true);
              setNome(e.target.value)
            }}
            value={nome}
          />

          <div style={{ marginTop: 40, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              <Input
                whiteSpace={'nowrap'}
                optionsStyle={{ width: 400 }}
                valid={validEmail}
                style={{ marginTop: 5, width: 400 }}
                disabled={disableEmail}
                name='email'
                label={'E-Mail*:'}
                onBlur={handleNovoEmail}
                onChange={(e)=>{
                  setValidEmail(true);
                  setEmail(e.target.value)
                }}
                value={email}
              />
            </div>
            <Input
              style={{ marginTop: 5, width: 200 }}
              name='telefone'
              valid={validTelefone}
              label={'Telefone:'}
              placeholder={'(xx) xxxx-xxxx'}
              onChange={(e)=>{
                let erased = false;
                if (e.target.value.length < telefone.length) erased = true
                mask(e.target, mphone, erased)
                setTelefone(e.target.value)
              }}
              value={telefone}
              disabled={disableTelefone}
            />
          </div>
          { emailExistente ? <><br /><span>Este e-mail já está cadastrado no Iapp.</span></> : null }

          <Folder
          show={false}
          nome={'Cadastrar Empresa'}
          question={true}
          title={'Os dados do cadastro da empresa serão utilizados no contrato de licença de uso'}
          >
            <div style={{ marginTop: 20, marginBottom: 40, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Input
                whiteSpace={'nowrap'}
                valid={validCnpj}
                disabled={disableCnpj}
                style={{ marginTop: 5, width: 200 }}
                name='cnpj'
                label={'CNPJ:'}
                placeholder={'xx.xxx.xxx/xxxx-xx'}
                onChange={onCnpjChange}
                value={cnpj}
              />
              <Input
                whiteSpace={'nowrap'}
                valid={validRazao}
                disabled={disableRazao}
                style={{ marginTop: 5, width: 600 }}
                name='razao'
                label={'Razão Social:'}
                onChange={(e)=>{
                  setValidRazao(true);
                  setRazao(e.target.value)
                }}
                value={razao}
              />
            </div>
            <Input
              whiteSpace={'nowrap'}
              valid={validFantasia}
              disabled={disableFantasia}
              style={{ marginTop: 5, width: '100%' }}
              name='nomefantasia'
              label={'Nome Fantasia:'}
              onChange={(e)=>{
                setValidFantasia(true);
                setFantasia(e.target.value)
              }}
              value={fantasia}
            />
          </Folder>
          <p style={{ background: '#002a4c', padding: 20, color: '#fff', textAlign: 'left', marginBottom: 20, marginTop: 40 }}><strong>Endereço</strong></p>
          <p>Informar endereço comercial. Caso não tenha cadastrado empresa, informar endereço pessoal. Esses dados serão utilizados no seu contrato de utilização do software.</p>

          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Input
              whiteSpace={'nowrap'}
              valid={validCep}
              disabled={disableCep}
              style={{ marginTop: 5, width: 150 }}
              name='cep'
              label={'CEP*:'}
              onChange={onCepChange}
              placeholder={'xxxxx-xxx'}
              maxLength={9}
              value={cep}
            />
            <Input
              style={{ marginTop: 5, width: 400 }}
              valid={validLogradouro}
              disabled={disableLogradouro}
              name='logradouro'
              label={'Logradouro*:'}
              maxLength={255}
              onChange={(e)=>{
                setValidLogradouro(true);
                setLogradouro(e.target.value)
              }}
              value={logradouro}
            />
            <Input
              style={{ marginTop: 5, width: 100 }}
              valid={validNr}
              name='numero'
              disabled={disableNr}
              label={'Número*:'}
              onChange={(e)=>{
                const re = /^[0-9\b]+$/;

                // if value is not blank, then test the regex

                if (e.target.value === '' || re.test(e.target.value)) {
                  setValidNr(true);
                  setNr(e.target.value)
                }
              }}
              value={nr}
              maxLength={15}
            />
          </div>
          <div style={{ marginTop: 40, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Input
              whiteSpace={'nowrap'}
              valid={validComplemento}
              style={{ marginTop: 5, width: 400 }}
              disabled={disableComplemento}
              maxLength={255}
              name='complemento'
              label={'Complemento:'}
              onChange={(e)=>{
                setValidComplemento(true);
                setComplemento(e.target.value)
              }}
              value={complemento}
            />
            <Input
              style={{ marginTop: 5, width: 400 }}
              valid={validBairro}
              name='bairro'
              maxLength={255}
              disabled={disableBairro}
              label={'Bairro*:'}
              onChange={(e)=>{setBairro(e.target.value)}}
              value={bairro}
            />
          </div>
          <div style={{ marginTop: 40, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Input
              whiteSpace={'nowrap'}
              valid={validCidade}
              disabled={disableCidade}
              style={{ marginTop: 5, width: 400 }}
              name='cidade'
              label={'Cidade*:'}
              maxLength={255}
              onChange={(e)=>{
                setValidCidade(true);
                setCidade(e.target.value)
              }}
              value={cidade}
            />
            <Input
              style={{ marginTop: 5, width: 200 }}
              valid={validUf}
              disabled={disableUf}
              name='uf'
              label={'UF*:'}
              maxLength={2}
              onChange={(e)=>{
                setValidUf(true);
                setUf(e.target.value)
              }}
              value={uf}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={ () => {
        props.handleClose()
      } }>Cancelar</ButtonCancel>
        <ButtonSave disabled={disableConfirm} onClick={handleConfirm}>Salvar</ButtonSave>
      </ModalFooter>
    </Modal>
    </>
  );
}

export default ModalEditarAcesso;