import fetchSessions from "./fetchSessions";
import fetchPerceptions from "./fetchPerceptions";
import fetchHierarchyImp from './fetchHierarchy';

const defaultCargos = [
  {
    _id: 1,
    cargo: 'CARGO ATUAL',
    tempoPreditivo: 4,
    superiorImediato: 2
  },
  {
    _id: 2,
    cargo: 'PRÓXIMO CARGO',
    tempoPreditivo: 4,
    superiorImediato: 3
  },
  {
    _id: 3,
    cargo: 'DOIS CARGOS ACIMA',
    tempoPreditivo: 4,
    superiorImediato: null
  },
]

export async function loadSessions(assessmentId) {
  return await fetchSessions(assessmentId);
}

export async function loadPerceptions(assessmentId, userId) {
  return await fetchPerceptions(assessmentId, userId);
}

export async function loadHierarchy({
  projectId,
  cargo
}) {
  let hierarchies = await fetchHierarchyImp({ projectId, cargo });

  return hierarchies;
}