import React, { Component } from "react";
import Chart from "react-apexcharts";
import _ from "lodash";
import { PLOT_COLORS } from 'Services/Constantes';
import Loading from 'Services/Loading';

import {
  Container,
  Input,
  Spinner
} from 'reactstrap';
export class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        colors: PLOT_COLORS,
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false
          },
          width: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            barHeight: "100%"
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "K";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          // position: "top",
          // horizontalAlign: "left",
          // offsetX: 40
          markers: {
            radius: 12,
          }
        }
      },
      series: [
        {
          name: "Series 1",
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
        }
      ],
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000);
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <div id="chart" style={{height: '100%'}}>
          {
            this.state.loading
              ?
              <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Loading />
              </div>
              :
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height={240}
              />
          }
        </div>
      </div>
    );
  }
}

export class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: PLOT_COLORS,
      },
      series: [
        {
          data: [
            {
              x: "Marcos Ferreira",
              y: 250,
            },
            {
              x: "Lucilla Panacioni",
              y: 149,
            },
            {
              x: "Daniel Vicentim",
              y: 184,
            },
            {
              x: "Juliana Belomo",
              y: 55,
            },
            {
              x: "Julio Azevedo",
              y: 84,
            },
            {
              x: "Gustavo Carvalho",
              y: 34,
            },
          ],
        },
      ],
      loading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000);
  }

  render() {
    return (
      <div style={{height: '100%'}}>
        <div id="chart" style={{height: '100%'}}>
          {
            this.state.loading
              ?
              <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Loading />
              </div>
              :
              <div style={{margin: '0 5px 0 25px'}}>
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="treemap"
                  height={240}
                />
              </div>
          }
        </div>
      </div>
    );
  }
}