import React, { Component } from 'react';
import Folder from "views/Pages/Assets/Folder";
import dateIsBefore from "utils/date-is-before";
import convertToDateTimeString from "views/Pages/Modulos/utils/convert-to-date-time-string";
import apiCalls from 'config/apiCalls';
import { Row, Col } from 'reactstrap';
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import { FaRegThumbsUp, FaThumbsUp, FaInfoCircle } from "react-icons/fa";

import FeedbackGraph from 'views/Pages/Assets/ModalResultado/Resultados/components/FeedbackGraph';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

/**
 * ### Componente de Resultados de Coleta De Feedback
 *
 * /**
 * @author Gustavo Carvalho Silva
 * @since 01/12/2020
 * @props
 * - idProjeto
 * - idParticipante
 * - idAssincrono
 * - idInstrumento
 *
 * @desc Monta os resultados de um instrumento do tipo Coleta De Feedback
 */
export default class Feedback extends Component {
  constructor (props) {
    super(props);
    this.state = {
      whatsBeingMeasuredText: '',
      notHaveResults: false,
      infoInstrument: null,
      escolhas: [],
      respondentes: [],
      loading: false,
      weakWordCloud: [],
      strongWordCloud: [],
      userFeedbackResult: {}
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getModalInstrumentInfo(idProjeto, idParticipante, idAssincrono, idInstrumento);

      let resposta = response.data;

      if (resposta && resposta.instrument) {
        this.setState({ infoInstrument: resposta.instrument, whatsBeingMeasuredText: resposta.whatsBeingMeasured })
      }

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false })
    }
  }

  async getInfoRespondente() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      const participantQuery = !this.props.isConsultant;

      const response = await apiCalls.resultados.assincronos.getInfoRespondente(idProjeto, idParticipante, idAssincrono, idInstrumento, participantQuery);

      const { respondentes } = response.data;

      if (respondentes) this.setState({ respondentes });

    } catch(err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async getInfoUser() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      const response = await apiCalls.assincronos.getUserAnswerersFeedback({ assincId: idAssincrono, projectId: idProjeto, type: 'weak', userId: idParticipante });

      const { data } = response

      if (data && data.resultsTexts) {
        this.setState({ userFeedbackResult: data })
      }

    } catch(err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    await this.getInfoInstrumento();
    await this.getInfoRespondente();
    await this.getInfoUser()
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  setNotHaveResults = (bool) => {
    this.setState({ notHaveResults: bool })
  }

  isExpirado(instrument) {
    try {
      let { dataFim } = instrument.datas
      let { horarioFim } = instrument.horarios

      const hoje = new Date();

      let tempoFimDateString = convertToDateTimeString(dataFim, horarioFim);

      if (dateIsBefore(new Date(tempoFimDateString), hoje) === true)
        return true;
      else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getAnswererStatus = (r) => {
    if (r && r.resposta && r.resposta.finished) return 'finished'
    else {
      if (this.state.infoInstrument && this.isExpirado(this.state.infoInstrument)) return 'expired'
      else return 'notStarted'
    }
  }

  render() {
    const {
      respondentes
    } = this.state;

    return (
      <>
        {
          this.state.loading
          ?
          <Loading/>
          :
          <>
            {
              <>
                <Folder
                    className='text-capitalized'
                    nome={`Resultados Feedback`}
                    key={'participant-feedback-results'}
                    show={true}
                >
                  {
                    !this.state.notHaveResults ? (
                      <>
                        <div style={{ marginTop: '25px' }}>
                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Comportamentos admirados em você</span>
                  </div>
                  <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <FeedbackGraph
                        setNotHaveResults={this.setNotHaveResults}
                        type={'strong'}
                        isConsultant={this.props.isConsultant}
                        participantId={this.props.idParticipante}
                        assincId={this.props.idAssincrono}
                        projectId={this.props.idProjeto}
                        id={`feedback-graph-strong-${this.props.idInstrumento}-${this.props.idAssincrono}`}
                      />
                    </Col>
                  </Row>

                  <div style={{ marginTop: '25px' }}>
                      <span style={{ fontSize: 12, fontWeight: 'bold' }}>Comportamentos a serem desenvolvidos por você</span>
                  </div>
                  <Row>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <FeedbackGraph
                        type={'weak'}
                        isConsultant={this.props.isConsultant}
                        setNotHaveResults={this.setNotHaveResults}
                        participantId={this.props.idParticipante}
                        assincId={this.props.idAssincrono}
                        projectId={this.props.idProjeto}
                        id={`feedback-graph-weak-${this.props.idInstrumento}-${this.props.idAssincrono}`}
                      />
                    </Col>
                  </Row>
                      </>
                    ) : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                  }


                </Folder>

                <Folder
                    className='text-capitalized'
                    nome={`O que disseram para você`}
                    key={'participant-answerer-texts'}
                    show={false}
                >
                  {
                      this.props.isConsultant ?
                      this.state.respondentes.some(r => r.resposta && r.resposta.finished)
                      ?
                      this.state.respondentes.map(r => (
                        r.resposta && r.resposta.finished &&
                        <div style={{ marginBottom: 50 }}>
                          <hr />
                          <div style={{ marginTop: '25px' }}>
                            <span style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}>{ `${ this.props.isConsultant ? `${r.nome} - ` : '' }${r.relacao}` }</span>
                          </div>
                          <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.final_considerations ? r.resposta.final_considerations : '')}}></TextHMTL>
                        </div>
                      ))
                      :
                      <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                      :
                      (
                        <>
                        {respondentes && respondentes.superior && Object.keys(respondentes).map(function(key, index) {
                        return key.includes('superior') && respondentes[key].length && (
                          <>
                            <div style={{ marginTop: '25px' }}>
                              <span style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}>{ `${key}` }</span>
                            </div>
                            {respondentes[key].map((r, i) => (
                              r.resposta && r.resposta.final_considerations &&
                              <div style={{ marginBottom: 50 }}>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.final_considerations ? r.resposta.final_considerations : '')}}></TextHMTL>
                              </div>
                            ))}
                            <hr />
                          </>
                        )

                      })}


                      {respondentes && respondentes.canSeeFeedbacks ?
                      Object.keys(respondentes).map(function(key, index) {
                        return !key.includes('superior') && respondentes[key].length && (
                          <>
                            <div style={{ marginTop: '25px' }}>
                              <span style={{ textTransform: 'capitalize', fontSize: 12, fontWeight: 'bold' }}>{ `${key}` }</span>
                            </div>
                            {respondentes[key].map((r, i) => (
                              r.resposta && r.resposta.final_considerations &&
                              <div style={{ marginBottom: 50 }}>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.final_considerations ? r.resposta.final_considerations : '')}}></TextHMTL>
                              </div>
                            ))}
                            <hr />
                          </>
                        )

                      }) :
                      <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>}
                      </>
                      )
                    }
                </Folder>

                <Folder
                    className='text-capitalized'
                    nome={`O que está sendo analisado`}
                    key={'feedback-whats-being-measured'}
                    show={false}
                >
                  <Row>
                    <Col>
                      <>
                        <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.whatsBeingMeasuredText)}}></TextHMTL>
                      </>
                    </Col>
                  </Row>
                </Folder>

                <Folder
                    className='text-capitalized'
                    nome={`Resultado`}
                    key={'participant-result'}
                    show={false}
                >
                  {
                    this.state.userFeedbackResult && this.state.userFeedbackResult.resultsTexts && this.state.userFeedbackResult.resultsTexts.results.length ?
                      (
                        <>
                          <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.userFeedbackResult.resultsTexts.beginText)}}></TextHMTL>
                          {
                            this.state.userFeedbackResult.resultsTexts.results.map(r => (
                              <>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r)}}></TextHMTL>
                              </>
                            ))
                          }
                        </>
                      )
                    : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                  }
                </Folder>

                {
                  this.props.isConsultant ? (
                    <Folder
                    className='text-capitalized'
                    nome={`Stakeholders Feedback`}
                    key={'feedback-stakeholders'}
                    show={false}
                    >
                      {
                        respondentes && respondentes.length ? (
                          <>
                            { respondentes.map(r => (
                              <Folder className='text-capitalized'
                              nome={`${r.nome} (${r.relacao})`}
                              key={r._id}
                              nameContainerStyle={{ width: '95%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              icon={this.getAnswererStatus(r)}
                              show={false}>
                                {
                                  r && r.resposta && r.resposta.finished ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <span style={{ fontWeight: 'bold', fontSize: 12, display: 'flex', alignItems: 'center' }}><FaThumbsUp style={{ marginRight: 10 }} size={12} color={'#0f0'}/> Comportamentos admirados</span>
                                          <span style={{ fontSize: 12 }}>{r.resposta.strengths.map(s => s.label).join(', ')}</span>
                                        </Col>
                                      </Row>
                                      <Row style={{ marginTop: 10 }}>
                                        <Col>
                                          <span style={{ fontWeight: 'bold', fontSize: 12, display: 'flex', alignItems: 'center' }}><FaRegThumbsUp style={{ marginRight: 10 }} size={12} /> Comportamentos à desenvolver</span>
                                          <span style={{ fontSize: 12 }}>{r.resposta.weaknesses.map(s => s.label).join(', ')}</span>
                                        </Col>
                                      </Row>
                                      <Row style={{ marginTop: 10 }}>
                                        <Col>
                                          <span style={{ fontWeight: 'bold', fontSize: 12, display: 'flex', alignItems: 'center' }}><FaInfoCircle style={{ marginRight: 10 }} size={12} color={'#00f'}/> Considerações finais</span>
                                          <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.final_considerations ? r.resposta.final_considerations : '')}}></TextHMTL>
                                        </Col>
                                      </Row>
                                    </>
                                  ) : null
                                }
                              </Folder>
                            )) }
                          </>
                        ) : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                      }
                    </Folder>
                  ) : null
                }
              </>
            }
          </>
        }
      </>
    )
  }
}