import React from 'react';
import Chart from "chart.js";

import { notifyError } from 'Services/Notificacoes';
import utils from './SelfConceptChartUtils';
import Messages from 'static/Messages';

export default class SelfConceptChart extends React.Component {

  state = {
    userResult: null,
    error: false
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    this.setState({ error: true });
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, utils.graphConfig({
      title: this.props.title
    }));
  }

  getSession(state) {
    return state.sessions
      .filter(session => session)
      .filter(session => session.participante)
      .find(session => session.participante._id === this.props.participantId);
  }

  getPerception(state) {
    const session = this.getSession(state);
    return state.perceptions
      .filter(perception => perception)
      .find(perception => perception.sessao === session._id);
  }

	drawParticipant(state) {
		let data = utils.getData({
			rodadasData: this.props.rodadasData,
			roundIndex: this.props.roundIndex
		});

		this.chart.data = {
      ...this.chart.data,
      ...data
    };
    this.chart.update();
  }

  drawGraph(state) {
    return new Promise((resolve, _reject) => {
      this.draw(state);
      this.drawParticipant(state);
      resolve();
    });
  }

  componentDidMount() {
    let state = {};

    this.setState({ state });
    const canvas = document.getElementById(this.props.id);
    if (!canvas) {
      console.info('canvas element removed.');
      return;
    };

    this
      .drawGraph(state)
      .catch(err => {
        this.handleError(err);
        notifyError(Messages.Assincronos.msgCannotMountGraph);
      });
  }

  render() {

    return <canvas 
      id={this.props.id} />;
  }
}