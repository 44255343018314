export default function removeSpecials(texto) {
  texto = texto.replace(/[ÀÁÂÃÄÅ]/g, "A");
  texto = texto.replace(/[àáâãäå]/g, "a");
  texto = texto.replace(/[ÈÉÊËẼ]/g, "E");
  texto = texto.replace(/[èéêẽ]/g, "e");
  texto = texto.replace(/[ÌÍÎĨ]/g, "I");
  texto = texto.replace(/[ìíîĩ]/g, "i");
  texto = texto.replace(/[ÒÓÔÕ]/g, "O");
  texto = texto.replace(/[òóôõ]/g, "o");
  texto = texto.replace(/[ÙÚÛŨ]/g, "U");
  texto = texto.replace(/[ùúûũ]/g, "u");
  texto = texto.replace(/[ç]/g, "c");
  texto = texto.replace(/[Ç]/g, "c");

  return texto;
}
