import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { ButtonSave } from '../../../Pages/Assets/Assets';

function AlertModal(props) {
  return(
    <Modal isOpen={props.isOpen}
    toggle={() => {
      props.toggle();
      if (props.cb) props.cb();
    }}
    >
      <ModalHeader toggle={() => {
        props.toggle();
        if (props.cb) props.cb();
        }}>Aviso</ModalHeader>
      <ModalBody>
        <div style={{ fontSize: 12 }}>
          <p>{props.msg}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonSave onClick={() => {
          props.toggle();
          if (props.cb) props.cb();
          }}>Ok</ButtonSave>
      </ModalFooter>
    </Modal>
  );
}

export default AlertModal