/// Converte um objeto Date no formato: DD/MM/YYYY e um horário no formato HH:mm para
/// uma string formatada no formato UTC (No fuso horário de Brasília)
export default function convertToDateTimeString(date, time) {
  let day;
  let month;
  let year;
  let hour;
  try {
    day = date.substring(0, 2);
    month = date.substring(3, 5);
    year = date.substring(6);
    hour = time + ':00-03:00';
    Date.parse(`${year}-${month}-${day}T${hour}`);
  } catch (error) {
    return 'ERRO';
  }
  return `${year}-${month}-${day}T${hour}`;
}
