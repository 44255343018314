import React, { Component } from "react";
import {
  Button,
  // Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

import {
  BaseLogin,
  LogoHome,
  // LoginSelectBox,
  // LoginSelectOption,
  ButtonSave,
  ButtonCancel,
  LogoApps,
  FormareRedirectButtonWrapper
} from "../Assets/Assets";
import { notifyError, notifySuccess } from "../../../Services/Notificacoes";
import ModalPrimeiroAcesso from "../../Components/modal-primeiro-acesso";
import ModalEscolherAmbiente from "../../Components/modal-escolher-ambiente";
import "react-toastify/dist/ReactToastify.css";
import "./css/Login.css";
import api from "../../../Services/api";
import { assincronosApi, reportsApi } from "../../../Services/api";
import { Constantes } from "../../../Services/Constantes";
import apiCalls from 'config/apiCalls';
import Politics from "views/Components/Politics";
import ContractModal from "views/Components/ContractModal";
import InstructionsModal from 'views/Components/InstructionsModal';
import RegisterModal from "views/Components/RegisterModal";
import ForgotEmailModal from "views/Components/ForgotEmailModal";

const LogoUser = require("../../../assets/img/icons/boneco_login.png");
const CadeadoSenha = require("../../../assets/img/icons/cadeado_senha.png");

// Imagens
const spinner = Constantes.imagemSpinner;

class Login extends Component {
  state = {
    contractModal: false,
    forgotEmailModal: false,
    registerModal: false,
    cpf: "",
    esqueciCpf: "",
    cpfGestaoParticipante: "",
    esqueciEmail: "",
    senha: "",
    modal: false,
    loadingSpinner: false,
    modalPrimeiroAcesso: false,
    modalEsqueciSenha: false,
    displayEtapa1: "block",
    displayEtapa2: "none",
    userFormacao: null,
    nomeConsultoria: '',
    nome: '',
    email: '',
    avatar: '',
    consultantLogo: '',
    celular: '',
    userId: '',
    showSelectRole: false,
    roleToLogin: '',
    roleModal: false,
    sessionExpiredModal: false,
    licenseAgreement: false,
    registerModalCpf: ''
  };

  componentDidMount = () => {
    const sessionExpired = sessionStorage.getItem('sessionExpired')
    sessionStorage.clear();
    sessionStorage.setItem("user", "");
    sessionStorage.setItem("username", "");
    sessionStorage.setItem("userCpf", "");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("isPlayer", "");

    this.setState({ sessionExpiredModal: !!sessionExpired })

    const register = new URLSearchParams(this.props.location.search).get("register");

    if (register === '1') this.toggleRegisterModal();

  };
  handleChange = e => {
    let value = e.target.value;
    if (e.target.name.toLowerCase().includes('cpf')) value = this.cpfMask(e.target.value);

    this.setState({ [e.target.name]: value });
  };
  isAuthenticated() {
    const token = sessionStorage.getItem("token");
    return token && token.length > 10;
  }

  verifyAddress = async(userId, cpf, senha) => {
    const res = await apiCalls.users.getAddress(userId);

    const { haveAddress } = res.data;

    if (!haveAddress) {
      this.logout();
      sessionStorage.setItem('registerModalCpf', cpf);
      sessionStorage.setItem('senha', senha);
    }

    return haveAddress;
  }

  handleForm = async (e, userCpf='', userPassword='', asConsultant=false) => {
    if (e) e.preventDefault();
    this.setState({ loadingSpinner: true });

    const cpf = (userCpf && userCpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")) || this.state.cpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");
    const senha = userPassword || this.state.senha;

    api
      .post("api/auth/authenticate", {
        connId: Constantes.io.id,
        cpf,
        senha
      })
      .then(async response => {
        if (response.data.token) {
          this.setState({ licenseAgreement: response.data.user && response.data.user.licenseAgreement })
          sessionStorage.setItem("token", response.data.token);
          api.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          assincronosApi.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          reportsApi.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          if (response.data.primeiroAcesso) {
            this.setState({
              modalPrimeiroAcesso: true,
              userFormacao: response.data.user && response.data.user.formacao,
              userId: response.data.user && response.data.user._id,
              nomeConsultoria: response.data.user && response.data.user.nomeConsultoria,
              nome: response.data.user && response.data.user.nome,
              email: response.data.user && response.data.user.email,
              avatar: response.data.user && response.data.user.avatar,
              consultantLogo: response.data.user && response.data.user.consultantLogo,
              celular: response.data.user && response.data.user.celular,
            });
          } else {
            sessionStorage.setItem("user", response.data.user._id);
            sessionStorage.setItem("username", response.data.user.nome);
            sessionStorage.setItem("userCpf", response.data.user.cpf);
            sessionStorage.setItem("userAvatar", response.data.user.avatar);
            sessionStorage.setItem("consultantLogo", response.data.user.consultantLogo);
            sessionStorage.setItem("userConsultantName", response.data.user.nomeConsultoria);
            sessionStorage.setItem("rememberRole", response.data.user.loginAs);
            sessionStorage.setItem("roleSelected", response.data.user.loginAs);
            sessionStorage.setItem("licenseAgreement", response.data.user.licenseAgreement);
            const { funcao } = response.data.user.formacao;
            if (response.data.isConsultantAndParticipant) {
              if (asConsultant || response.data.user.loginAs && response.data.user.loginAs.toLowerCase().includes(Constantes.funcaoConsultor)) {
                sessionStorage.setItem("isPlayer", "");
                const haveAddress = await this.verifyAddress(response.data.user._id, response.data.user.cpf, senha);
                if (!haveAddress) {
                  return this.toggleRegisterModal(cpf);
                }
                if (!response.data.user.licenseAgreement) this.toggleContractModal();
                else {
                  this.props.history.push("/dashboard");
                  await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão consultor' });
                }
              } else if (response.data.user.loginAs && response.data.user.loginAs.toLowerCase().includes(Constantes.funcaoParticipante)) {
                sessionStorage.setItem("isPlayer", true);
                this.props.history.push("/participante/dashboard");
                await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão participante' });
              } else if (!response.data.user.loginAs) {
                this.setState({ roleModal: true, loadingSpinner: false });
              }
              sessionStorage.setItem('isConsultantAndParticipant', true);
            }
            else if (funcao === Constantes.funcaoConsultor) {
              sessionStorage.setItem("isPlayer", "");
              const haveAddress = await this.verifyAddress(response.data.user._id, response.data.user.cpf, senha);
                if (!haveAddress) {
                  return this.toggleRegisterModal(cpf);
                }
              if (!response.data.user.licenseAgreement) this.toggleContractModal();
              else {
                await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão consultor' });
                this.props.history.push("/dashboard");
              }
            } else if (funcao === Constantes.funcaoParticipante) {
              sessionStorage.setItem("isPlayer", true);
              await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão participante' });
              this.props.history.push("/participante/dashboard");
            }
          }
        } else if (response.data.logado) {
          this.setState({
            modal: true
          });
        }
      })
      .catch(err => {
        console.error(err);
        if (err.response && err.response.status === 400) {

          notifyError("CPF ou senha incorretos");
        } else {
          notifyError("Não foi possível realizar login. Por favor, recarregue a página e tente novamente");
        }
        this.setState({ loadingSpinner: false });
      });
  };

  loginNovo = async () => {
    const cpf = this.state.cpf;
    const senha = this.state.senha;
    await api
      .post("api/auth/logout", { cpf, senha })
      .then(response => {
        // logout realizado
      })
      .catch(err => {
        if (err.response && err.response.data.error)
          notifyError(err.response.data.error);
        else notifyError("Problema com o servidor. Por favor, recarregue a página e tente novamente");
        this.setState({ loadingSpinner: false });
      });
    await api
      .post("api/auth/authenticate", { cpf, senha })
      .then(async response => {
        if (response.data.token) {
          sessionStorage.setItem("user", response.data.user._id);
          sessionStorage.setItem("username", response.data.user.nome);
          sessionStorage.setItem("userCpf", response.data.user.cpf);
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("rememberRole", response.data.user.loginAs);
          sessionStorage.setItem("roleSelected", response.data.user.loginAs);
          sessionStorage.setItem("licenseAgreement", response.data.user.licenseAgreement);
          api.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          assincronosApi.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          reportsApi.defaults.headers.common["Authorization"] =
            "Bearer " + sessionStorage.getItem("token");
          const { funcao } = response.data.user.formacao;
          if (response.data.isConsultantAndParticipant) {
            if (response.data.user.loginAs && response.data.user.loginAs.toLowerCase().includes(Constantes.funcaoConsultor)) {
              sessionStorage.setItem("isPlayer", "");

              const haveAddress = await this.verifyAddress(response.data.user._id, response.data.user.cpf, senha);
                if (!haveAddress) {
                  return this.toggleRegisterModal(cpf);
                }
              if (!response.data.user.licenseAgreement) this.toggleContractModal();
              else {
                this.props.history.push("/dashboard");
                await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão consultor' });
              }
            } else if (response.data.user.loginAs && response.data.user.loginAs.toLowerCase().includes(Constantes.funcaoParticipante)) {
              sessionStorage.setItem("isPlayer", true);
              await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão participante' });
              this.props.history.push("/participante/dashboard");
            } else if (!response.data.user.loginAs) {
              this.setState({ roleModal: true, loadingSpinner: false });
            }
            this.setState({ roleModal: true });
          } else if (funcao === Constantes.funcaoConsultor) {
            sessionStorage.setItem("isPlayer", "");
            const haveAddress = await this.verifyAddress(response.data.user._id, response.data.user.cpf, senha);
            if (!haveAddress) {
              return this.toggleRegisterModal(cpf);
            }
            if (!response.data.user.licenseAgreement) this.toggleContractModal();
            else {
              await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão consultor' });
              this.props.history.push("/dashboard");
            }
          } else if (funcao === Constantes.funcaoParticipante) {
            sessionStorage.setItem("isPlayer", true);
            await apiCalls.logs.createUserLoginLog({ userCpf: cpf, userId: response.data.user._id, role: 'gestão participante' });
            this.props.history.push("/participante/dashboard");
          }
        } else if (response.data.logado) {
          this.setState({
            modal: true
          });
        }
      })
      .catch(err => {
        if (err.response && err.response.data.error)
          notifyError(err.response.data.error);
        else notifyError("Problema com o servidor. Por favor, recarregue a página e tente novamente");
        this.setState({ loadingSpinner: false });
      });
  };

  verificacaoCpf = async () => {
    let esqueciCpf = this.state.esqueciCpf;
    if (!esqueciCpf) return notifyError('Preencha o campo CPF');
    esqueciCpf = esqueciCpf.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");

    try {
      const res = await apiCalls.users.verifyUserFirstAccess(esqueciCpf);
      // const { haveFirstAccess } = res.data;
      // if (!haveFirstAccess) return notifyError('CPF ainda não realizou primeiro acesso.');
    } catch (err) {
      if (err && err.response && err.response.status === 404) return notifyError('CPF não encontrado no sistema.');
      else console.error(err)
    }

    await api
      .get("api/user/0/email-ativo", {
        params: {
          cpf: esqueciCpf
        }
      })
      .then(response => {
        this.setState({
          esqueciEmail: response.data.ultimoEmailAtivo,
          displayEtapa1: "none",
          displayEtapa2: "block"
        });
        // logout realizado
      })
      .catch(err => {
        if (err.response && err.response.data.error) {
          notifyError(err.response.data.error);
        } else {
          notifyError("Não encontramos o seu cpf cadastrado no sistema.");
          this.setState({
            modalEsqueciSenha: false,
            esqueciCpf: "",
            esqueciEmail: "",
            displayEtapa1: "block",
            displayEtapa2: "none"
          });
        }
      });
  };

  enviarEmail = async () => {
    let esqueciCpf = this.state.esqueciCpf;
    if (!esqueciCpf) return notifyError('Preencha o campo CPF');
    esqueciCpf = esqueciCpf.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");

    await apiCalls.users.recoverPassword({ cpf: esqueciCpf, encryptedVersion: true })
      .then(_response => {
        notifySuccess("Por favor verifique a sua conta de e-mail");
        this.setState({
          modalEsqueciSenha: false,
          esqueciCpf: "",
          esqueciEmail: ""
        });
      })
      .catch(err => {
        console.error(err);
        if (err.response && err.response.data.error) {
          notifyError(err.response.data.error);
        } else {
          notifyError("Não foi possível executar a operação selecionada. Por favor, recarregue a página e tente novamente");
          this.setState({
            modalEsqueciSenha: false,
            esqueciCpf: "",
            esqueciEmail: "",
            displayEtapa1: "block",
            displayEtapa2: "none"
          });
        }
      });
  };

  toogle = () => {
    this.setState({
      modal: false,
      loadingSpinner: false
    });
  };

  toggleModal = modalName => {
    this.setState(prev => {
      prev[modalName] = !prev[modalName];

      return prev;
    });
  };

  esqueci = () => {
    if (this.state.registerModal) this.toggleRegisterModal();

    this.setState({
      modalEsqueciSenha: true,
      loadingSpinner: false,
      esqueciCpf: "",
      esqueciEmail: "",
      displayEtapa1: "block",
      displayEtapa2: "none"
    });

    // this.setState(prev => {
    //   prev[modalSenha] = !prev[modalSenha];
    // })
  };

  handleModalClose = modalName => {
    switch (modalName) {
      case "modalPrimeiroAcesso":
        this.setState({
          loadingSpinner: false
        });
        break;

      default:
        break;
    }
  };

  onSelectRoleChange = e => {
    e.preventDefault();
    if (e.target.value === 'consultor') {
      sessionStorage.setItem('isPlayer', '');
    } else if (e.target.value === 'participante') {
      sessionStorage.setItem('isPlayer', true);
    }
    this.setState({ roleToLogin: e.target.value });
  }

  handleLogin = async() => {
    const { roleToLogin } = this.state;

    if (!roleToLogin) return;

    if (roleToLogin === Constantes.funcaoConsultor) {

      if (!this.state.licenseAgreement) this.toggleContractModal();
      else {
        this.props.history.push("/dashboard");
        window.location.reload();
      }
    } else if (roleToLogin === Constantes.funcaoParticipante) {
      this.props.history.push("/participante/dashboard");
      window.location.reload();
    }
  }

  fecharModalSenha = () => {
    this.setState({
      modalEsqueciSenha: false,
      displayEtapa2: 'none',
    });
  }

  logout = () => {
    sessionStorage.clear();
    this.setState({
      cpf: "",
      esqueciCpf: "",
      cpfGestaoParticipante: "",
      esqueciEmail: "",
      senha: "",
      modal: false,
      loadingSpinner: false,
      modalPrimeiroAcesso: false,
      modalEsqueciSenha: false,
      displayEtapa1: "block",
      displayEtapa2: "none",
      userFormacao: null,
      nomeConsultoria: '',
      nome: '',
      email: '',
      avatar: '',
      consultantLogo: '',
      celular: '',
      userId: '',
      showSelectRole: false,
      roleToLogin: '',
      roleModal: false
    });
  }

  toggleRoleModal = () => {
    if (this.state.roleModal) {
      this.logout();
    }

    this.setState({ roleModal: !this.state.roleModal });
  }

  handleCancelRoleModal = () => {
    this.logout();

    this.toggleRoleModal();
  }

  toggleContractModal = () => {
    if (this.state.contractModal) {
      this.logout();
    }

    this.setState({ contractModal: !this.state.contractModal });
  }

  getCurrentDate = () => {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, '0');
    let mes = String(data.getMonth() + 1).padStart(2, '0');
    let ano = data.getFullYear();
    return dia + '/' + mes + '/' + ano;
  }

  onContractConfirm = async() => {
    await api.put(`/v3/users/${sessionStorage.getItem('user')}/accept-contract`, {
      agreed: 1,
      firstAccess: true,
      date: new Date()
    });

    await apiCalls.logs.createUserLoginLog({ userCpf: sessionStorage.getItem('userCpf'), userId: sessionStorage.getItem('user'), role: 'gestão consultor' });

    this.props.history.push("/dashboard");
  }

  onContractClose = async() => {
    this.logout();

    this.toggleContractModal();
  }

  toggleRegisterModal = (cpf) => {
    this.setState({ registerModal: !this.state.registerModal, registerModalCpf: cpf })
  }

  onRegisterCancel = () => {
    this.toggleRegisterModal();
  }

  toggleForgotEmailModal = () => {
    this.setState({ forgotEmailModal: !this.state.forgotEmailModal });
  }

  toggleSessionExpiredModal = () => {
    this.setState({ sessionExpiredModal: !this.state.sessionExpiredModal });
  }

  cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }


  render() {
    return (
      <div className="app flex-row align-items-center">
        <ForgotEmailModal
          isOpen={this.state.forgotEmailModal}
          toggle={this.toggleForgotEmailModal}
          onCancel={this.toggleForgotEmailModal}
        />
        <RegisterModal
          forgotPasswordModal={this.esqueci}
          login={this.handleForm}
          toggle={this.toggleRegisterModal}
          isOpen={this.state.registerModal}
          onCancel={this.onRegisterCancel}
          cpf={this.state.registerModalCpf}
        />
        <ContractModal
          isOpen={this.state.contractModal}
          toggle={this.toggleContractModal}
          date={this.getCurrentDate()}
          onConfirm={this.onContractConfirm}
          onCancel={this.onContractClose}
        />
        <ModalEscolherAmbiente
          verifyAddress={this.verifyAddress}
          onLogin={true}
          licenseAgreement={this.state.licenseAgreement}
          toggleContractModal={this.toggleContractModal}
          toggle={this.toggleRoleModal}
          isOpen={this.state.roleModal}
          senha={this.state.senha}
          onCancel={this.handleCancelRoleModal}
          toggleRegisterModal={this.toggleRegisterModal}
        />
        <ModalPrimeiroAcesso
          userFormacao={this.state.userFormacao}
          userId={this.state.userId}
          celular={this.state.celular}
          userAvatar={this.state.avatar}
          consultantLogo={this.state.consultantLogo}
          userNome={this.state.nome}
          nomeConsultoria={this.state.nomeConsultoria}
          userEmail={this.state.email}
          cpf={this.state.cpf}
          senhaAntiga={this.state.senha}
          isOpen={this.state.modalPrimeiroAcesso}
          history={this.props.history}
          toggle={() => this.toggleModal("modalPrimeiroAcesso")}
          handleClose={() => this.handleModalClose("modalPrimeiroAcesso")}
        />
        <Modal
          isOpen={this.state.modal}
          toggle={this.toogle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Atenção</ModalHeader>
          <ModalBody>Usuário já logado. Deseja logar aqui?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toogle}>
              Não
            </Button>{" "}
            <Button color="success" onClick={this.loginNovo}>
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <InstructionsModal
          isOpen={this.state.sessionExpiredModal}
          modalHeader={'Aviso'}
          modalBody={
            <div style={{ fontSize: 12 }}>
              <p>Sessão expirada por tempo de inatividade. Faça login novamente.</p>
            </div>
          }
          onCancel={this.toggleSessionExpiredModal}
          toggle={this.toggleSessionExpiredModal}
        />

        <Modal
          isOpen={this.state.modalEsqueciSenha}
          toggle={this.fecharModalSenha}
          handleClose={() => this.handleModalClose("modalEsqueciSenha")}
          className="modalEsqueciSenha"
        >
          <ModalHeader toggle={this.fecharModalSenha}>Esqueceu sua senha? </ModalHeader>
          <ModalBody>
            <div style={{ display: this.state.displayEtapa1 }}>
              <span> Insira seu cpf para resgatar sua senha.</span>
              <InputGroup>
                <InputGroupAddon addonType="prepend" className="before">
                  👤
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="informe o cpf"
                  autoComplete="cpf"
                  name="esqueciCpf"
                  onChange={this.handleChange}
                  required
                />
              </InputGroup>
            </div>

            <div style={{ display: this.state.displayEtapa2 }}>
              Clique em enviar para que seja encaminhada a sua senha para o
              e-mail indicado.
              <InputGroup>
                <InputGroupAddon addonType="prepend" className="before">
                  @
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder=""
                  autoComplete="email"
                  name="esqueciEmail"
                  value={this.state.esqueciEmail}
                  required
                />
              </InputGroup>
              <span onClick={this.toggleForgotEmailModal} style={{ fontSize: 12, textDecoration: 'underline', cursor: 'pointer' }}>Não tenho mais esse e-mail</span>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.state.displayEtapa1 === 'block' ? (
              <>
                <ButtonCancel onClick={this.fecharModalSenha}>
                  Cancelar
                </ButtonCancel>
                <ButtonSave onClick={this.verificacaoCpf} style={{
                  margin: 0,
                  marginLeft: '0.25rem'
                }}>
                  Verificar
                </ButtonSave>
              </>
            ) : null}
            {this.state.displayEtapa2 === 'block' ? (
              <>
                <ButtonCancel onClick={this.fecharModalSenha}>
                  Cancelar
                </ButtonCancel>
                <ButtonSave onClick={this.enviarEmail} style={{
                  margin: 0,
                  marginLeft: '0.25rem'
                }}>
                  Enviar
                </ButtonSave>
              </>
            ) : null}

          </ModalFooter>
        </Modal>

        <BaseLogin>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
            className="app" id="inner-div">
            <br /> <br />
            <Container>
              <Row className="justify">

                <div className="presentation" style={{ minWidth: 412, maxWidth: 520, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ color: '#FFFFFF', fontWeight: 700, marginTop:  60 }} className="text-center">
                      <h3>Tranforme o RH em uma área estratégica com soluções disruptivas.</h3>
                    </div>
                    <FormareRedirectButtonWrapper>
                      <a style={{ width: 200 }} href="https://formaretech.com.br/" target="_blank">
                        <Button
                          type='button'
                          onClick={() => {}}
                          className="botao"
                          disabled={this.state.loadingSpinner}
                          style={{
                            marginTop: 5,
                            backgroundColor: "#F0C435",
                            color: "#000",
                            borderBox: "solid #F0C435",
                            fontWeight: 600,
                            fontSize: 14,
                            maxWidth: 200
                          }}
                        >
                        CONHEÇA MAIS
                        </Button>
                      </a>
                    </FormareRedirectButtonWrapper>
                    <LogoApps>
                    <img
                      alt="Logo Iapp"
                      src={require("../Assets/images/logo_iapp.png")}
                    />
                    <img
                      alt="Logo Iapp"
                      src={require("../Assets/images/logo_aodp.png")}
                    />
                    </LogoApps>
                </div>
                <div style={{ backgroundColor: "#fff", minWidth: 412, maxWidth: 450, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} className="text-center">
                  <div style={{ marginTop: 40 }}>
                    <h2 style={{ color: "#8A9476", fontSize: 18, fontWeight: 500 }}>Acesso à plataforma</h2>
                  </div>
                  <LogoHome
                    alt="logo"
                    src={require("../Assets/images/formaretech_logo.png")}
                  />
                  {/* <br /> */}

                    <div>
                      <Form onSubmit={this.handleForm}>
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="icons"
                          >
                            <img src={LogoUser} className="iconsImg" />
                          </InputGroupAddon>
                          <Input
                            disabled={this.state.showSelectRole}
                            type="text"
                            placeholder="CPF"
                            autoComplete="cpf"
                            value={this.state.cpf}
                            name="cpf"
                            onChange={this.handleChange}
                            style={{
                              backgroundColor: "#fff",
                              color: "#000",
                              borderColor: "#ddd"
                            }}
                            required
                            className="input-login"
                          />
                        </InputGroup>
                        <br />
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="icons"
                          >
                            <img src={CadeadoSenha} className="iconsImg" />
                          </InputGroupAddon>
                          <Input
                            disabled={this.state.showSelectRole}
                            type="password"
                            placeholder="Senha"
                            value={this.state.senha}
                            style={{
                              backgroundColor: "#fff",
                              color: "#000",
                              borderColor: "#ddd"
                            }}
                            autoComplete="current-password"
                            name="senha"
                            onChange={this.handleChange}
                            className="input-login"
                            required
                          />
                        </InputGroup>
                        <br />

                        <Button
                        type='submit'
                        className="botao"
                        disabled={this.state.loadingSpinner}
                        style={{
                          backgroundColor: "#8a9476",
                          color: "white",
                          borderBox: "solid #8a9476",
                          marginBottom: 25,
                          fontWeight: 500
                        }}
                        >
                        ACESSAR
                        </Button> <br />

                        <small style={{ color: '#000', fontWeight: 'bold' }}>Ainda não é um consultor IAPP?</small> <br />

                        <Button
                        type='button'
                        onClick={this.toggleRegisterModal}
                        className="botao"
                        disabled={this.state.loadingSpinner}
                        style={{
                          marginTop: 5,
                          backgroundColor: "#F0C435",
                          color: "#000",
                          borderBox: "solid #F0C435",
                          fontWeight: 600,
                          fontSize: 14
                        }}
                        >
                        CRIE SUA CONTA GRÁTIS
                        </Button>
                        <br />
                        <small style={{ color: '#000' }}>Acesso imediato. Não é necessário cartão de crédito.</small>

                        <br />
                        {this.state.loadingSpinner && (
                          <img src={require(`../Assets/images/${spinner}`)} />
                        )}
                      </Form>
                      <div>
                        {/* <small>
                          <a className="esqueciSenha" onClick={this.toggleRegisterModal} style={{
                            cursor:"pointer"
                          }}>
                            Criar Conta
                          </a>
                        </small>
                        <br /><br /> */}
                        <small>
                          <a
                          style={{
                            textDecoration: 'underline',
                            cursor:'pointer',
                            color: '#000000'
                          }}
                          onClick={this.esqueci}>
                            Esqueci minha senha
                          </a>
                        </small>
                        <br />
                        <br />
                        <br />
                        <div className="login-footer-mobile">
                          <small style={{ color: '#000' }}>Quer conhecer mais sobre o método IAPP ?</small>
                          <a style={{ width: 200 }} href="https://formaretech.com.br/" target="_blank">
                            <Button
                              type='button'
                              onClick={() => {}}
                              className="botao"
                              disabled={this.state.loadingSpinner}
                              style={{
                                marginTop: 5,
                                backgroundColor: "#F0C435",
                                color: "#000",
                                borderBox: "solid #F0C435",
                                fontWeight: 600,
                                fontSize: 14,
                                maxWidth: 200
                              }}
                            >
                              ACESSE O SITE
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  <br />
                  <br />
                </div>
              </Row>
            </Container>
            <footer style={{ paddingBottom:15 }}>
                <small>
                  <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
                </small>
              </footer>
          </div>
        </BaseLogin>

      </div>
    );
  }
}

export default Login;
