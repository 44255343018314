import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

/**
 * ### Componente de Resultados de Projetos Atuais
 *
 * /**
 * @author Gustavo Carvalho Silva
 * @since 01/12/2020
 * @props
 * - idProjeto
 * - idParticipante
 * - idAssincrono
 * - idInstrumento
 *
 * @desc Monta os resultados de um instrumento do tipo Projetos Atuais
 */
export default class ProjetosAtuais extends Component {
  constructor (props) {
    super(props);
    this.state = {
      resposta: "",
      loading: false,
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);

      let resposta = response.data;

      this.setState({ resposta: resposta });

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    const {
      resposta
    } = this.state;

    return (
      <>
        {
          this.state.loading
          ?
          <Loading/>
          :
          <>
            {
              resposta.length > 0
              ?
              <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(resposta)}}></TextHMTL>
              :
              <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
            }
          </>
        }
      </>
    )
  }
}