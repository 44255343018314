import React, { useState } from 'react';
import { Modal, Button } from "reactstrap";

import * as Imgs from './constants/s3-images'
import { relatorioPdfUrl } from './constants/s3-pdfs'

import './styles.css'

function WelcomeModal(props) {
  const [displaySessions, setDisplaySessions] = useState({
    session1: 'welcome-show-session',
    session2: 'welcome-hide-session'
  })

  function toggleSessions(session) {
    const newSessions = { ...displaySessions }

    Object.keys(newSessions).map(key => {
      newSessions[key] = 'welcome-hide-session'
    })

    newSessions[session] = 'welcome-show-session'

    setDisplaySessions(newSessions)
  }

  return(
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      size='xl'
    >

        <div style={{ backgroundImage: `url(${Imgs.headerBackgroundImageUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: 0 }}>
          <span onClick={() => props.toggle()} style={{ cursor: 'pointer', fontWeight: 'bold', marginRight: 20, marginTop: 10 }}>x</span>
        </div>
          <div style={{ padding: 35 }}>
            <p style={{ fontSize: 31, fontWeight: 'bold', color: '#02264a' }}>Seleção, Sucessão e Mapeamento de Prontidão e Potencial</p>
            <p style={{ fontSize: 31, fontWeight: 'bold', color: '#fff' }}>com a maior eficácia do mercado e 100% automatizado.</p>
          </div>
        </div>
        <div className='welcome-modal-wrapper' style={{ padding: 25 }}>
          <div style={{ borderBottom: '1px solid #e6e6e6', marginBottom: 10 }}>
            <span onClick={() => toggleSessions('session1')} style={{ fontSize: 12, width: 100, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 3, background: displaySessions.session1.includes('show') ? '#F0C435' : '#e6e6e6', cursor: 'pointer' }}>Instrumentos IAPP</span>
            <span onClick={() => toggleSessions('session2')} style={{ fontSize: 12, width: 100, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 3, background: displaySessions.session2.includes('show') ? '#F0C435' : '#e6e6e6', cursor: 'pointer' }}>Modelo de Relatório</span>
          </div>
          <div className={displaySessions['session1']}>
            <p>Os instrumentos do IAPP fornecem resultados confiáveis e gráficos fáceis de serem interpretados. Veja abaixo alguns exemplos</p>
            <div className='welcome-modal-section'>
              <div className='text-section' style={{ width: '75%', marginRight: 10 }}>
                <p className='section-title'>Estilos de Comunicação (Instrumentos de Autoconhecimento)</p>
                <p>O instrumento de avaliação dos Estilos de Comunicação indica as escolhas individuais quanto as formas de comunicação interpessoal, entendendo nossas percepções quanto ao que acreditamos gerará melhores resultados, tanto quanto reconhecer as diferentes percepções e escolhas dos interlocutores.</p>
                <p>Desse modo, corroborar na compreensão de que as diferenças dos estilos decorrem das experiências e histórico de resultados e, desse modo, como adquirimos conhecimento, abordamos os problemas e atuamos nas situações e com nossos interlocutores.</p>
                <p>Nesse sentido, observa-se que as preferências pode ser alterado em situações favoráveis das situações sob estresse.</p>
              </div>
              <div className='img-wrapper' style={{ width: '100%', height: 308 }}>
                <img src={ Imgs.estiloComunicacaoFavoravelImageUrl }/>
                <img src={ Imgs.estiloComunicacaoStressImageUrl }/>
              </div>
            </div>
            <div className='welcome-modal-section inverted-welcome-modal-section'>
              <div className='img-wrapper' style={{ width: '100%', height: 300 }}>
                <img src={ Imgs.tiposPersonalidadeImageUrl }/>
              </div>
              <div className='text-section' style={{ width: '75%', marginLeft: 10 }}>
                <p className='section-title'>Tipos de Personalidade (Instrumentos de Autoconhecimento)</p>
                <p>O instrumento de Tipos de Personalidade, que também é denominado como Temperamento ou Caráter, atua na identificação e categorização de um conjunto de comportamentos decorrentes de preferências e experiências pessoais. Ou seja, enquanto as pessoas podem ser reconhecidas por características comuns de um grupo social, assim como há evidências características influenciadas pela cultura, há aspectos pertinentes às deliberações internas que explicam escolhas, formas de atuação e comportamento, tanto quanto o processo de análise, reflexão e juízo de valor.</p>
                <p>Deste modo, compreender os Tipos de Personalidade é conhecer e compreender os motivadores das respostas das pessoas à luz dos 16 Tipos de Personalidade categorizadas.</p>
              </div>
            </div>
            <div style={{ flexDirection: 'column' }} className='welcome-modal-section'>
              <div style={{ width: '100%', }}>
                <p className='section-title'>Estilos de Liderança (Soft & Social Skills)</p>
                <p>Analisa sua capacidade de recrutar seus recursos pessoais, contemplando seus atributos e experiência na função atual enquanto atua com o grupo de trabalho. Adicionalmente, o quanto é hábil em identificar a maturidade dos participantes com os quais está deliberando, discutindo, decidindo e utilizando-se de diferentes estilos de liderança.</p>
                <p>Assim sendo, os resultados decorrem da análise individual cruzada de cada participante, sobre si próprio e sobre os demais, haja vista o conjunto de situações, em crescente tensão, ocorridas no grupo. Essa leitura é verificada a partir da solicitação de indicação do comportamento mais provável de si e dos demais participantes do grupo, considerando-se sete possíveis alternativas. Desse modo, assegura-se a assertividade da análise cruzada em cada rodada, mitigando eventuais situações em que os participantes apresentem nível distinto de conhecimento ou trabalho prévio.</p>
                <p>Os comportamentos indicados estão associados aos estilos de liderança que devem se ajustar à situação e à maturidade dos participantes, tanto individualmente quanto em grupo, ao atuar com maior ou menor protagonismo.</p>
              </div>
              <div className='img-wrapper' style={{ width: '100%', height: 326 }}>
                <img src={ Imgs.estilosLiderancaRadarImageUrl }/>
                <img src={ Imgs.estilosLiderancaEficaciaImageUrl }/>
                <img src={ Imgs.estilosLiderancaVelocimetroImageUrl }/>
              </div>
            </div>
            <div style={{ flexDirection: 'column' }} className='welcome-modal-section'>
              <div style={{ width: '100%', }}>
                <p className='section-title'>Prontidão e Pontencial (Grau de Abstração e Visão Estratégica - Couping & Grit)</p>
                <p>O Assessment atua na análise de duas dimensões distintas, mas inter-relacionadas e entrelaçadas, a Prontidão e a Potencial.</p>
                <p>A dimensão da <strong>prontidão</strong> contempla seis (6) atributos específicos, mutuamente exclusivos, sem perder sua interdependência. Ou seja, a prontidão não determina o potencial, pois se detém a verificar o domínio do participante sobre suas atribuições na função atual em perspectiva dos desafios e complexidade dos próximos dois anos. Esta análise considera o impacto da velocidade de incorporação de complexidade do contexto, considerando o segmento e a organização, na posição, função e responsabilidades atuais do participante.</p>
                <p>A dimensão do <strong>potencial</strong> , a exemplo do de prontidão, contempla seis (6) atributos específicos, mutuamente exclusivos, sem perder a interdependência entre si e com os de prontidão. Ou seja, enquanto a prontidão não indica o potencial, a velocidade de obtenção e avanço no domínio de novas atribuições, de maior complexidade e desafio, estabelece o pilar no qual o potencial será avaliado. Nesse sentido, o assessment na dimensão do potencial, investiga a capacidade do profissional em mobilizar o melhor de seus recursos pessoais, internos (atributos) em situações de crescente tensão. Logo, verifica e indica os indicadores preditivos no que tange a capacidade de incorporação de complexidade nas duas funções seguintes em um período de 2 a 5 anos. Por conseguinte, o quanto será capaz de dar conta dos aspectos atinentes à sua função atual e futuras, dado o contexto a que estarão sujeitos, organização e participante, nos próximos anos, em razão dos movimentos do mercado, devido a disrupções econômicas, sociais, políticas e tecnológicas, influenciando o as circunstâncias e contexto de atuação da organização, de seu modelo de negócios e da respectiva estratégia.</p>
              </div>
              <div className='flex-wrap-welcome-modal' style={{ display: 'flex' }}>
                <div className='img-wrapper' style={{ width: '60%', height: 550, justifyContent: 'flex-start' }}>
                  <img src={ Imgs.assessmentPotencialImageUrl }/>
                  <img style={{ marginLeft: 10 }} src={ Imgs.assessmentProntidaoImageUrl }/>
                </div>
                <div className='img-wrapper'>
                  <img style={{ maxHeight: '100%' }} src={ Imgs.assessmentGeralImageUrl }/>
                </div>
              </div>
            </div>
            <div style={{ flexDirection: 'column' }} className='welcome-modal-section'>
              <div style={{ width: '100%', }}>
                <p className='section-title'>Ranking (Posicionamento individual e geral)</p>
                <p>Proporciona uma visão de posicionamento de cada pessoa baseada nas notas obtidas em relação aos seus colegas, individualmente em cada instrumento e também em um ranking geral que considera todos os instrumentos que foram aplicados em um processo.</p>
              </div>
              <div className='img-wrapper' style={{ width: '100%', height: 350 }}>
                <img style={{ maxHeight: 360, marginRight: 10 }} src={ Imgs.dinamicaRankingGeralGraficoImageUrl }/>
                <img style={{ maxHeight: 360 }} src={ Imgs.dinamicaRankingGeralTabelaImageUrl }/>
              </div>
            </div>
          </div>

          <div className={displaySessions['session2']}>
            <object data={relatorioPdfUrl} type="application/pdf" width="100%" height="700px">
              <p>Não foi possível carregar o arquivo PDF, faça o <a href={relatorioPdfUrl} target='_blank'>Download</a> do arquivo.</p>
            </object>
          </div>


          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20 }}>
            <Button
            type='button'
            name='sessao1'
            onClick={props.toggle}
            className="botao"
            style={{
              marginTop: 5,
              backgroundColor: "#F0C435",
              color: "#000",
              borderBox: "solid #F0C435",
              fontWeight: 600,
              fontSize: 12,
              maxWidth: 200,
            }}
          >
                Vamos começar ?
          </Button>
          </div>
        </div>
    </Modal>
  );
}

export default WelcomeModal;