import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  text-decoration: underline;
  color: #0070f3;
`

function AnswererPendingActivityReminderTemplate() {
  return (
    <>
      <p><span><strong>Título do e-mail:</strong> FormareTech - Acesso ao ambiente de testes FormareTech</span><br />
      <span><strong>Remetente:</strong> no-reply@formaretech.com.br</span><br /></p>
      <img src="https://files-storage.s3.amazonaws.com/mail_assets/formaretech-logo-mail.png" alt="FormareTech Logo" /><br />
      <p>Olá, José Silva</p> <br />
      <p>Parabéns, foi convidado a acessar o ambiente de testes do sistema IAPP. Nele você aprenderá através de um tutorial guiado a como
      responder as atividades, de forma simples e objetiva.</p>
      <p>Para acessar, utilize as credenciais abaixo:</p>
      <p>Site: <StyledSpan>https://participante.formaretech.com.br</StyledSpan></p>
      <p>Usuário: 123.456.789-00</p>
      <p>Código de acesso: er5y77i</p> <br />
      <p>Qualquer dúvida ou problema, entre em contato conosco através do E-mail: meu_email@consultor.com.br</p> <br />
      <p>Por favor, não responda este e-mail.</p>
      <p>Obrigado!</p>
      <strong>FormareTech</strong>
    </>
  )
}

export default AnswererPendingActivityReminderTemplate;