import styled from 'styled-components';

export const Select = styled.select`
    width: 100%;
    border-color: #23282c;
    color: #23282c;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
`;

export const BlueLinkButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  color: #02264a;
`;

export const SubTitle = styled.span`
  color: #23282c;
  font-weight: 600;
  font-size: 12px;
`;

export const ClientsTable = styled.table`
  th {
    font-size: 12px;
    font-weight: 600;
    padding: 15px;
  }

  td {
    font-size: 10px;
    font-weight: 600;
    padding: 15px;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }

  tr:first-child {
    border-top: none;
  }
  
  tr {
    border-top: 5px solid #f8f8f8;
  }
`;

export const ItemList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const Wrapper = styled.div`
  span {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    color: #02264a;
    cursor: pointer;
  }

  .dropdown-menu {
    background-color: #00264b;
  }

  .dropdown-item {
    color: #fff;
    font-size: 12px;
  }

  .dropdown-item:hover {
    background-color: transparent;
    text-decoration: underline;
    color: #fff;
  }

  .dropdown-header {
    color: #fff;
    font-weight: bold;
    background: transparent;
  }

  .dropdown-header {
    margin-bottom: 10px;
    /* border-bottom: none; */
  }

  .dropdown-item + .dropdown-header {
    margin-top: 10px;
    /* border-top: 1px solid #c8ced3; */
  }
`;

export const BorderlessButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
`;