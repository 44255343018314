import ModalParticipantPhoto from "components/ModalParticipantPhoto";
import React, { useState } from "react";
import styled from "styled-components";
// import DefaultAvatar from '../../views/Pages/Assets/images/user.gif';

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: top;
  object-fit: cover;
`;

/**
 * @typedef { Object } Prop
 * @property { string } foto
 * @property { function } onClick
 */

/**
 *
 * @param { Prop } prop
 *
 */

const UserAvatar = ({ foto }) => {
  const awsURL = "https://files-storage.s3.amazonaws.com/";
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      {foto && (
        <ModalParticipantPhoto toggle={toggle} isOpen={isOpen} photo={foto} />
      )}
      <Image
        onClick={toggle}
        src={
          foto
            ? `${awsURL}${foto}`
            : `https://files-storage.s3.amazonaws.com/avatar_default.png`
        }
        style={{
          cursor: foto ? "pointer" : "default",
        }}
      />
    </>
  );
};

export default UserAvatar;
