import { Constantes } from "../../../Services/Constantes";

function dataAtualFormatada(){
  let data = new Date();
  let dia  = data.getDate().toString();
  let diaF = (dia.length == 1) ? '0'+dia : dia;
  let mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
  let mesF = (mes.length == 1) ? '0'+mes : mes;
  let anoF = data.getFullYear();
  let hour = data.getHours()
  let hourF = String(hour).length == 1 ? "0" + hour : hour
  let min = data.getMinutes()
  let minutes = String(min).length == 1 ? `0${min}` : min;


  return `${diaF}/${mesF}/${anoF} às ${hourF}:${minutes}`
}

const ajustarData = data => {
  let string = "";
  if (data.split("-").length > 1) {
    string =
      data.split("-")[2] + "/" + data.split("-")[1] + "/" + data.split("-")[0];
  } else string = data;
  return string;
};

function convertDataParaJsDate(data) {

  data = data.split("/");
  let dia = data[0];
  let mes = data[1];
  data[0] = mes;
  data[1] = dia;
  return data.join("/");
}

const ordenarMenorParaMaior = lista => {
  lista.sort((a, b) => {
    if (a === b) return 0;

    return a < b ? -1 : 1; // menor vai pra traz, maior para frente
  });
};

const ordenarMaiorParaMenor = lista => {
  lista.sort((a, b) => {
    if (a === b) return 0;

    return a < b ? 1 : -1; // menor vai pra frente, maior para traz
  });
};

const ordenarParticipantes = lista => {
  // ordenando lista
  lista.sort((a, b) => {
    if (a.nome === b.nome) return 0;

    return a.nome < b.nome ? -1 : 1;
  });
};

const compararHorarios = (horarioInicio, horarioFim) => {
  let hInicio = horarioInicio.substring(0, 2);
  let mInicio = horarioInicio.substring(3, 5);
  let hFim = horarioFim.substring(0, 2);
  let mFim = horarioFim.substring(3, 5);

  let maior = 2;
  if (hInicio < hFim) maior = 2;
  else if (hInicio > hFim) maior = 1;
  else if (hInicio === hFim) {
    if (mInicio < mFim) maior = 2;
    else if (mInicio > mFim) maior = 1;
    else return -1; // iguais
  }

  return maior;
};

const compararDatasCompletas = (di, hi, df, hf) => {
  di = convertDataParaJsDate(di);
  df = convertDataParaJsDate(df);

  if (new Date(di) > new Date(df)) {
    // segunda menor que a primeira
    return false;
  } else if (new Date(di) < new Date(df)) {
    // segunda maior que a primeira
    return true;
  } else {
    // datas iguais. comparar horas
    if (compararHorarios(hi, hf) === 1) {
      // horario final maior que o inicial
      return false;
    } else {
      return true;
    }
  }
};

const onComentarioChanged = (
  value,
  participanteResultado,
  atividade,
  comentarioNovo
) => {
  let comentario = atividade.comentarios.find(
    p => p.participante === participanteResultado.id
  );

  // Verifica se já existe comentário
  if (comentario) {
    // Verifica se já foi editado
    if (comentarioNovo) {
      comentario.log[comentario.log.length - 1].textoFinal = value;
    } else {
      comentario.log.push({
        alteradoPor: sessionStorage.getItem("user"),
        textoInicial: comentario.texto,
        textoFinal: value
      });
    }
    comentario.texto = value;
  } else {
    // Inicializa novo comentario
    atividade.comentarios.push({
      participante: participanteResultado.id,
      texto: value,
      log: [
        {
          alteradoPor: sessionStorage.getItem("user"),
          textoInicial: "",
          textoFinal: value
        }
      ]
    });
  }
  return atividade;
};

export function maskCPF(val) {

  val = val.split('')
  val.splice(3, 0, '.')
  val.splice(7, 0, '.')
  val.splice(11, 0, '-')
  val = val.join('')

  return val
}

export {
  ajustarData,
  ordenarParticipantes,
  convertDataParaJsDate,
  compararHorarios,
  compararDatasCompletas,
  ordenarMenorParaMaior,
  ordenarMaiorParaMenor,
  onComentarioChanged,
  dataAtualFormatada
};
