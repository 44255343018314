import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { Tooltip } from "react-tippy";
import { FaQuestionCircle } from "react-icons/fa";
import Grafico from 'views/Pages/Resultados/ModalResultados/ResultadosDinamica/Case/Grafico';
import LeadershipChart from 'views/Pages/Resultados/ModalResultados/AtividadesDinamica/EstilosDeLideranca/Graficos/LeadershipChart';

export class Estilos extends Component {

  render() {

    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'row-reverse' }}>
          <Tooltip
            title="Clique na legenda dos gráficos para selecionar/ocultar a informação no gráfico."
            position="top"
            size="small"
            trigger="mouseenter"
            arrow="true"
          >
            <FaQuestionCircle size={16} style={{ marginRight: 5 }} />
          </Tooltip>
        </div>

        <Row>
          <Col sm="12" md="6" lg="6">
            <LeadershipChart id={`activity-leadership-chart-round-${this.props.rodada}`}
              rodadasData={this.props.rodadasData}
              roundIndex={this.props.rodada}
              title={`Rodada ${this.props.rodada + 1} - Estilos de Liderança`} />
          </Col>

          <Col sm="12" md="6" lg="6">
            {isNaN(this.props.avg) === false ? (
              <Grafico
                series={[this.props.avg]}
                id={`styles-avg-chart-instrument-${this.props.idInstrumento}-tag-${this.props.tag}`}
                title="Eficácia da rodada" />
            ) : ''}
          </Col>
        </Row>


      </div>
    );
  }
}
