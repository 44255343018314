import apiCalls from 'config/apiCalls';
import React, { Component } from 'react';
import { Col, Row, Container } from "reactstrap";
import Loading from 'Services/Loading';
import { notifyError } from 'Services/Notificacoes';

import './scss/ResultadoStoryTelling.scss';

/**
 * @author Gustavo Carvalho Silva
 * @since 19/11/2020
 *
 * @props idProjeto, idParticipante, idDinamica, rodada
 * @description recebe os dados do participante na rodada e retorna os resultados do participante assim que for montado
 */
export default class InfoStoryTelling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      respostas: {
        individual: [],
        grupo: [],
        individual2: [],
      },

      loading: false,
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idDinamica, rodada, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.dinamicas.getInfoStoryTelling(idParticipante, idProjeto, idDinamica, rodada, idInstrumento);

      const { respostaIndividual, respostaIndividual2, storytellingGroup } = response.data;

      let respostaIndividualT = [];
      let respostaIndividual2T = [];
      let respostaGrupoT = [];

      if (respostaIndividual.length > 0) respostaIndividualT = respostaIndividual.map(resposta => resposta.content)
      if (respostaIndividual2.length > 0) respostaIndividual2T = respostaIndividual2.map(resposta => resposta.content)
      if (storytellingGroup.resposta && storytellingGroup.resposta.length > 0) respostaGrupoT = storytellingGroup.resposta.map(resposta => resposta.content)

      let respostas = {
        individual: respostaIndividualT ? respostaIndividualT : [],
        individual2: respostaIndividual2T ? respostaIndividual2T : [],
        grupo: respostaGrupoT ? respostaGrupoT : [],
      }

      this.setState({
        respostas
      });

      this.setState({ loading: false });

    } catch (err) {
      console.error(err);
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    const { respostas } = this.state;

    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            :
            <div className="row row-cols-sm-12">

              <div className="col">
                <Row className="storytelling-title">
                  <Col>
                    <h6>Individual</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      {
                        respostas.individual.length > 0
                          ?
                          respostas.individual.map((resposta, index) => (
                            <div
                              key={index}
                              className="storytelling-line"
                            >
                              {resposta}
                            </div>
                          ))
                          :
                          <div className="storytelling-no-responses">
                            Não existem respostas
                    </div>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="col">
                <Row className="storytelling-title">
                  <Col>
                    <h6>Fase em grupo</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      {
                        respostas.grupo.length > 0
                          ?
                          respostas.grupo.map((resposta, index) => (
                            <div
                              key={index}
                              className="storytelling-line"
                            >
                              {resposta}
                            </div>
                          ))
                          :
                          <div className="storytelling-no-responses">
                            Não existem respostas
                          </div>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="col">
                <Row className="storytelling-title">
                  <Col>
                    <h6>Pós discussão</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      {
                        respostas.individual2.length > 0
                          ?
                          respostas.individual2.map((resposta, index) => (
                            <div
                              key={index}
                              className="storytelling-line"
                            >
                              {resposta}
                            </div>
                          ))
                          :
                          <div className="storytelling-no-responses">
                            Não existem respostas
                          </div>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
        }
      </div>
    )
  }
}