import React from 'react';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import Visibility from "@material-ui/icons/Visibility";
import { FaEye, FaRegTimesCircle } from 'react-icons/fa';


const Ico = styled.button`
  border: 0px;
  height: 35px;
  width: 35px;

  margin-left: 2.5px;
  margin-right: 2.5px;

  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  padding: 8px;
  border-radius: 5px;
  img {
    width: 15px;
    filter: invert(100%);
    vertical-align: sub;
  }
  :focus {
    outline: none;
  }

  &:disabled {
    color: #23282c;
    opacity: 0.65;
    background: -moz-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #c8ced3 0%,
      #8d9194 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    background: -moz-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* FF3.6-15 */
  }
`;

const Button = styled.button`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  height: 35px;
  width: 35px;
  height: 35px;

  margin-left: 2.5px;
  margin-right: 2.5px;

  border: 0px;

  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  padding: 9px;
  border-radius: 5px;
  span,
  svg {
    color: #fafafa;
    width: 18px;
    height: 18px;
    overflow: visible;
    vertical-align: sub;
  }
  span {
    font-size: 18px;
  }
  svg {
    font-size: 18px;
  }
  :focus {
    outline: none;
  }

  &:disabled {
    color: #23282c;
    opacity: 0.65;
    background: -moz-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #c8ced3 0%,
      #8d9194 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    background: -moz-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* FF3.6-15 */
  }
`;

const PendenciesBadge = styled.span`
  height: 12px !important;
  width: 12px !important;
  display: block;
  background: #f86c6b;
  border-radius: 50%;
  position: absolute;
  margin-left: 16px;
  margin-top: -14px;
`;

export const ProjectActionWithImg = ({
  title,
  id,
  onClick,
  hasPendencies,
  icon,
  disabled
}) => {
  return (
    <Tooltip
      title={ disabled ? null : title }
      position="top"
      arrow="true"
      size="small"
      disabled={disabled}
    >
      <span>
        <Ico disabled={disabled} id={id} onClick={onClick}>
          {hasPendencies ? <PendenciesBadge /> : ""}
          <img
            alt={icon}
            src={require("../views/Pages/Assets/images/" + icon + ".gif")}
          />
        </Ico>
      </span>
    </Tooltip>
  );
}

export const ProjectAction = ({
  title,
  id,
  onClick,
  hasPendencies,
  icon,
  disabled
}) => {
  return (
    <Tooltip
      title={disabled && icon !== 'times-circle' ? null : title}
      position="top"
      arrow="true"
      size="small"
      disabled={disabled && icon !== 'times-circle'}
    >
      <Button disabled={disabled} id={id} onClick={onClick}>
        {hasPendencies ? <PendenciesBadge /> : ""}
        {icon === "eye" ? <FaEye /> : icon === 'times-circle' ? <FaRegTimesCircle />  : <i style={{ fontSize: 17, color: "#fff" }} className={`fa fa-${icon}`} />}
      </Button>
    </Tooltip>
  );
}