import React from 'react';
import Chart from "chart.js";
import DrawDoughnutLabelsPlugin from './DrawDoughnutLabelsPlugin';

class Grafico extends React.Component {
  
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let graphic = {
      graphic: this.props.series[0]
    };
    if (!graphic || isNaN(graphic.graphic)) throw new Error('this.props.series[0] not defined');

    const datasets = [
      {
        data: [
          graphic.graphic,
          100 - graphic.graphic
        ],
        backgroundColor: ['#325A9C'],
        label: 'Resultado',
      }
    ];

    const config = () => ({
      type: 'doughnut',
      data: {
        datasets,
        labels: [`${graphic.graphic} Pontos`]
      },
      plugins: [DrawDoughnutLabelsPlugin()],

      options: {

        plugins: {
          datalabels: {
            display: false
          }
        },
        tooltips: {
          enabled: false
        },

        // responsive: true, // not work if chart container width is fixed
        legend: {
          position: 'bottom',
        },
        title: {
          display: this.props.title ? true : false,
          text: this.props.title
        },
        // animation: {
        //   animateScale: false,
        //   animateRotate: false
        // },
        // maintainAspectRatio: false,
        circumference: Math.PI + 1,
        rotation: -Math.PI - 0.5,
        cutoutPercentage: 64,
      }
    });

    const ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, config());
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ 
          width: '500px', 
          paddingTop: '15px',
          display: 'inline-block',
        }}>
          <canvas
            id={this.props.id}
            />
        </div>
      </div>
      )
  }
}


export default Grafico;
