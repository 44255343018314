import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { Container } from './styles';
/**
 *
 * @param { Object } props
 * @param { String } props.photo - nome da foto do usuário
 * @param { boolean } props.isOpen -
 * @param { function } props.toggle
 */
export default function ModalParticipantPhoto({ photo, toggle, isOpen }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{
        maxWidth: "500px",
      }}
    >
      <ModalHeader toggle={toggle}>Foto do participante</ModalHeader>

      <ModalBody>
        {photo && (
          <img
            style={{
              width: "100%",
            }}
            src={`https://files-storage.s3.amazonaws.com/${photo}`}
          />
        )}
      </ModalBody>
    </Modal>
  );
}
