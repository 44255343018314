import React, { Component } from 'react';
import Folder from "views/Pages/Assets/Folder";
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

/**
 * ### Componente de Resultados de Coleta De Feedback
 *
 * /**
 * @author Gustavo Carvalho Silva
 * @since 01/12/2020
 * @props
 * - idProjeto
 * - idParticipante
 * - idAssincrono
 * - idInstrumento
 *
 * @desc Monta os resultados de um instrumento do tipo Coleta De Feedback
 */
export default class ColetaDeFeedback extends Component {
  constructor (props) {
    super(props);
    this.state = {
      escolhas: [],
      respondentes: [],
      loading: false,
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);

      let resposta = response.data;

      if (resposta.length > 0) {
        let escolhas = resposta[0].escolhas

        this.setState({ escolhas: escolhas });
      }

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false })
    }
  }

  async getInfoRespondente() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      const participantQuery = !this.props.isConsultant;

      const response = await apiCalls.resultados.assincronos.getInfoRespondente(idProjeto, idParticipante, idAssincrono, idInstrumento, participantQuery);

      const { respondentes } = response.data;

      if (respondentes) this.setState({ respondentes });

    } catch(err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    await this.getInfoInstrumento();
    await this.getInfoRespondente();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const {
      escolhas,
      respondentes
    } = this.state;

    return (
      <>
        {
          this.state.loading
          ?
          <Loading/>
          :
          <>
            {
              <>

                  <Folder
                    className='text-capitalized'
                    nome={`Visão de ${this.capitalizeFirstLetter(this.props.nomeParticipante)}`}
                    key={'participant-vision'}
                    show={true}
                  >
                    {
                      escolhas.length > 0
                      ?
                    <>
                      <div style={{ marginTop: '25px' }}>
                        <span style={{  fontSize: 12, fontWeight: 'bold' }}>Pontos Fortes</span>
                      </div>
                      <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(escolhas[0] ? escolhas[0] : '')}}></TextHMTL>
                      <div style={{ marginTop: '25px' }}>
                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>Oportunidades de Desenvolvimento Pessoal</span>
                      </div>
                      <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(escolhas[1] ? escolhas[1] : '')}}></TextHMTL>
                    </>
                      :
                      <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                    }
                  </Folder>
                  {

                    <Folder
                    nome={`Visão dos stakeholders`}
                    key={'stakeholders-vision'}
                    show={false}
                    >
                    {
                      this.props.isConsultant ?
                      this.state.respondentes.some(r => r.resposta && r.resposta.textos && r.resposta.textos.length)
                      ?
                      this.state.respondentes.map(r => (
                        r.resposta && r.resposta.textos && r.resposta.textos.length &&
                        <div style={{ marginBottom: 50 }}>
                          <hr />
                          <div style={{ marginTop: '25px' }}>
                            <span style={{ textTransform: 'capitalize', fontSize: 14 }}>{ `${ this.props.isConsultant ? `${r.nome} - ` : '' }${r.relacao}` }</span>
                          </div>
                          <div style={{ marginTop: '10px' }}>
                            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Pontos Fortes</span>
                          </div>
                          <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.textos && r.resposta.textos[0] ? r.resposta.textos[0] : '')}}></TextHMTL>
                          <div style={{ marginTop: '25px' }}>
                            <span style={{ fontSize: 12, fontWeight: 'bold' }}>Oportunidades de Desenvolvimento Pessoal</span>
                          </div>
                          <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.textos && r.resposta.textos[1] ? r.resposta.textos[1] : '')}}></TextHMTL>
                        </div>
                      ))
                      :
                      <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                      :
                      (
                        <>
                        {respondentes && respondentes.superior && Object.keys(respondentes).map(function(key, index) {
                        return key.includes('superior') && respondentes[key].length && (
                          <>
                            <div style={{ marginTop: '25px' }}>
                              <span style={{ textTransform: 'capitalize', fontSize: 14 }}>{ `${key}` }</span>
                            </div>
                            {respondentes[key].map((r, i) => (
                              r.resposta && r.resposta.textos && r.resposta.textos.length &&
                              <div style={{ marginBottom: 50 }}>
                                <hr />
                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Respondente {i + 1}</span>
                                <div style={{ marginTop: '10px' }}>
                                  <span style={{ fontSize: 12, fontWeight: 'bold' }}>Pontos Fortes</span>
                                </div>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.textos && r.resposta.textos[0] ? r.resposta.textos[0] : '')}}></TextHMTL>
                                <div style={{ marginTop: '25px' }}>
                                  <span style={{ fontSize: 12, fontWeight: 'bold' }}>Oportunidades de Desenvolvimento Pessoal</span>
                                </div>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.textos && r.resposta.textos[1] ? r.resposta.textos[1] : '')}}></TextHMTL>
                              </div>
                            ))}
                          </>
                        )

                      })}


                      {respondentes && respondentes.canSeeFeedbacks ?
                      Object.keys(respondentes).map(function(key, index) {
                        return !key.includes('superior') && respondentes[key].length && (
                          <>
                            <div style={{ marginTop: '25px' }}>
                              <span style={{ textTransform: 'capitalize', fontSize: 14 }}>{ `${key}` }</span>
                            </div>
                            {respondentes[key].map((r, i) => (
                              r.resposta && r.resposta.textos && r.resposta.textos.length &&
                              <div style={{ marginBottom: 50 }}>
                                <hr />
                                <span style={{ fontSize: 12, fontWeight: 'bold' }}>Respondente {i + 1}</span>
                                <div style={{ marginTop: '10px' }}>
                                  <span style={{ fontSize: 12, fontWeight: 'bold' }}>Pontos Fortes</span>
                                </div>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.textos && r.resposta.textos[0] ? r.resposta.textos[0] : '')}}></TextHMTL>
                                <div style={{ marginTop: '25px' }}>
                                  <span style={{ fontSize: 12, fontWeight: 'bold' }}>Oportunidades de Desenvolvimento Pessoal</span>
                                </div>
                                <TextHMTL dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(r.resposta && r.resposta.textos && r.resposta.textos[1] ? r.resposta.textos[1] : '')}}></TextHMTL>
                              </div>
                            ))}
                          </>
                        )

                      }) :
                      <NoResultsFound>Não há respostas suficiente para liberar a visualização dos resultados. Falta(m) {respondentes.totalFeedbackLeft} respondentes preencherem a coleta de feedback para você poder visualizar</NoResultsFound>}
                      </>
                      )
                    }
                    </Folder>

                  }

              </>
            }
          </>
        }
      </>
    )
  }
}