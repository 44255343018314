import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { notifyError, notifySuccess } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import './scss/styles.scss';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import TableResultados from './TableResultados';
import Questoes from './Graficos/Questoes/';
import Legenda from './Legenda';
import { ButtonCancel, ButtonSave } from 'views/Pages/Assets/Assets';
import { Constantes } from "Services/Constantes";
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';

/**
 * @author Gustavo Carvalho Silva
 * @since 27/11/2020
 *
 * @props idProjeto, idParticipante, idProva
 * @description componente que exibe o resultado de um participante em uma prova
 */
export default class ResultadoProvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      respostas: [],
      loading: false,

      modalExclusao: false,
      indexParaExcluir: 0,
    };
  }

  /**
   * @param { boolean } ultima parametro que verifica se a questão é a que está sendo respondida pelo participante
   *
   * Adiciona mais tempo à prova
   */
  adicionarTempo = (ultima) => {
    const {
      idProva,
      idProjeto,
      idParticipante
    } = this.props;
    if (ultima) {
      apiCalls.provas.addTempoProva(idProva, idProjeto, [idParticipante])
        .then(res => notifySuccess(res.data))
        .catch(err => console.error(err));
    } else {
      notifyError('Só é possível adicionar tempo a uma questão não respondida')
    }
  };

  /**
   * @param { number } index da questão que deve ser removida
   * @param { boolean } ultima serve pra inspecionar se a questão está sendo respondidad pelo participante
   *
   * Remove uma questão respondida pelo participante e atualiza os resultados
   */
  excluirQuestao = (index, ultima) => {
    if (ultima) notifyError("Não é permitido excluir questão em andamento")
    else {
      this.setState({
        modalExclusao: !this.state.modalExclusao,
        indexParaExcluir: index
      })
    }
  };

  excluir = () => {
    const {
      idProva,
      idProjeto,
      idParticipante,
    } = this.props;
    const {
      indexParaExcluir
    } = this.state;

    try {
      apiCalls.provas.excluirQuestao(idParticipante, idProjeto, idProva, indexParaExcluir);

      let tempRespostas = this.state.respostas;
      tempRespostas.splice(indexParaExcluir, 1);

      this.setState({
        respostas: tempRespostas
      })
      this.mountSeries();

      notifySuccess("Questão excluida!");
    } catch (err) {
      console.error(err);
    } finally {
      this.toggleModalExclusao();
    }
  }

  mountSeries() {
    let data = {
      labels: [],
      datasets: [
        {
          label: "Acertos",
          fillColor: "rgba(0,191,255,0)",
          pointBorderColor: "#5fba77",
          showLine: false,
          backgroundColor: "rgba(100,149,237,0)",
          highlightStroke: "rgba(100,149,237,0)",
          lineTension: 0,
          data: [],
          borderColor: "grey",
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: "#5fba77"
        },
        {
          label: "Nivel",
          fillColor: "rgba(0,191,255,0)",
          pointBorderColor: "#d44444",
          lineTension: 0,
          backgroundColor: "rgba(100,149,237,0)",
          highlightStroke: "rgba(100,149,237,0)",
          data: [],
          borderColor: "blue",
          borderWidth: 1,
          pointRadius: 3,
          pointBackgroundColor: "#d44444"
        }
      ]
    };
    this.gerarEvolucao(data);

    this.setState({
      series: data
    });
  }

  /**
   * Calcula os dados para gerar o grafico
   *
   * @param {User} participanteResultado O resultado do participante
   * @param {Object} data A configuração de data salva.
   */
  gerarEvolucao = (data) => {
    const {
      respostas
    } = this.state;

    let participanteResultado = [];
    data.labels = [];
    data.datasets[0].data = [];
    data.datasets[1].data = [];

    if (
      respostas && respostas.length > 0
    ) {
      respostas.map((resposta, index) => {
        if (resposta.resposta && resposta.resposta.texto) {
          participanteResultado.push({
            questao: index + 1,
            nivel: resposta.nivel + 1,
            acerto: resposta.correta
          });
          data.labels.push(index + 1);

          if (resposta.resposta.correta) {
            data.datasets[0].data.push({
              y: resposta.nivel + 1,
              x: index + 1
            });
          }

          data.datasets[1].data.push(resposta.nivel + 1);
        } else if (resposta.resposta && resposta.resposta.texto === '') {
          participanteResultado.push({
            questao: index + 1,
            nivel: resposta.nivel + 1,
            acerto: false
          });
          data.labels.push(index + 1);

          data.datasets[1].data.push(resposta.nivel + 1);
        }
      });
    }
  };

  toggleModalExclusao = () => {
    this.setState({
      modalExclusao : !this.state.modalExclusao,
    })
  }

  /**
   * busca as informações do participante na prova selecionada e monta os dados dos gráficos e da tabela
   */
  async getInfoProva() {
    const {
      idProjeto,
      idParticipante,
      idProva
    } = this.props;
    try {
      this.setState({ loading: true });
      let response = await apiCalls.resultados.provas.getInfoProva(idProjeto, idParticipante, idProva);
      this.setState({ respostas: response.data });

      if (this.state.respostas.length > 0)
        this.mountSeries();
    } catch (err) {
      console.error('error', err);
      this.setState({ respostas: [] });
      // notifyError('Não foi possível encontrar os resultados!');
    } finally {
      this.setState({ loading: false });
    }
  }

  subscribe() {
    Constantes.io.on('Users:Prova:Resposta', data => {
      if (data.prova !== this.provaId) return;
      if (data.id !== this.props.idParticipante) return;
      
      this.getInfoProva();
    });
    Constantes.io.on('Users:Prova:Finalizado', (data) => {
      if (data.prova !== this.provaId) return;
      if (data.id !== this.props.idParticipante) return;
      this.getInfoProva();
    })
  }

  componentDidMount() {
    this.provaId = localStorage.getItem('prova');
    if (this.props.listenToSocket) {
      this.subscribe();
    }

    this.getInfoProva();
  }

  componentWillUnmount() {
    Constantes.io.off('Users:Prova:Resposta');
  }

  render() {
    const {
      respostas,
      series
    } = this.state;
    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                respostas.length > 0
                ?
                <>
                  <Modal
                    isOpen={this.state.modalExclusao}
                    toggle={this.toggleModalExclusao}
                    >
                    <ModalHeader>
                      Excluir Questão
                    </ModalHeader>
                    <ModalBody>
                      Deseja excluir essa questão?
                    </ModalBody>
                    <ModalFooter>
                      <ButtonCancel onClick={this.excluir}>Sim</ButtonCancel>
                      <ButtonSave onClick={this.toggleModalExclusao}>Não</ButtonSave>
                    </ModalFooter>
                  </Modal>
                  <Legenda />
                  <Questoes
                    series={series}
                  />

                  <TableResultados
                    respostas={respostas}
                    adicionarTempo={this.adicionarTempo}
                    excluirQuestao={this.excluirQuestao}
                  />
                </>
                :
                <NoResultsFound />
              }
            </>
        }
      </div>
    )
  }
}
