import React, { Component } from 'react';

import Folder from "views/Pages/Assets/Folder";
import HQGraph from 'views/Pages/Modulos/Assessment/Resultados/Components/HQGraph';
import PotentialIndGraph from 'views/Pages/Modulos/Assessment/Resultados/Components/PotentialIndGraph';
import ProntidaoIndGraph from 'views/Pages/Modulos/Assessment/Resultados/Components/ProntidaoIndGraph';
import PotentialResult from 'views/Pages/Resultados/ModalResultados/ResultadoAssessments/components/assessment-feedback/PotentialResult';
import ReadinessResult from 'views/Pages/Resultados/ModalResultados/ResultadoAssessments/components/assessment-feedback/ReadinessResult';
import { notifyError } from "Services/Notificacoes";
import { RedText } from './styles.jsx';
import Messages from 'static/Messages';
import {
  getParticipantesAssessmentAcompanhamento,
} from "../../../Modulos/requisicoes";
import * as ModalAcompanhamentoService from '../../../../Pages/Assets/ModalAcompanhamento/services';
import apiCalls from 'config/apiCalls';
import allFieldsNumeric from 'utils/allFieldsNumeric';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';

function PreditivoResult({ incompleteHierarchies, participantId, assessmentId }) {

  return (
    <>
      {incompleteHierarchies ?
        <RedText>
          <p>{Messages.Assessment.msgIncompleteHierarchies}</p>
        </RedText> : ''}
      <HQGraph assessmentId={assessmentId} participantId={participantId} />
    </>
  )
}

export default class ResultadoAssessments extends Component {

  state = {
    incompleteHierarchies: [],
    cargo: null,
    loading: false,
    hierarchies: [],
    perception: null,
    error: false,
    graphsUpdated: false,
    preditivoFolder: false,
    potentialFolder: false,
    prontidaoFolder: false
  }

  async getHierarchies(projectId, cargo) {
    const hierarchies = await ModalAcompanhamentoService.fetchHierarchy({
      projectId,
      cargo
    });
    return hierarchies;
  }

  async getSessions() {
    return await getParticipantesAssessmentAcompanhamento(
      this.props.idAssessments
    );
  }

  async fetchPerception(assessmentId) {
    return apiCalls.assessments.getPerceptions(assessmentId, this.props.participantId);
  }

  async getPerception() {
    const res = await apiCalls.assessments.getPerceptions(this.props.idAssessments, this.props.participantId);
    return res.data;
  }

  async getParticipantSession(sessions) {
    return sessions
      .filter(session => session)
      .filter(session => session.participante)
      .find(session => session.participante._id === this.props.participantId);
  }

  async getPerceptionParticipante() {
    const perception = await this.getPerception();
    return perception;
  }

  async getUserProject(projectId) {
    const resP = await apiCalls.resultados.getInfoParticipante(
      projectId,
      this.props.participantId
    );

    const userProject = resP && resP.data ? {
      cargo: resP.data.cargo,
      _idProjeto: projectId,
      nome: resP.data.nome,
      participantId: resP.data._id
    } : null

    return userProject;
  }

  getGraphsResults = async() => {
    try {
      this.setState({ loading: true });
      this.projectId = sessionStorage.getItem('projeto') || this.props.idProjeto;

      const perception = await this.getPerceptionParticipante();

      let userProject = await this.getUserProject(this.projectId);

      let incompleteHierarchies;
      let hierarchies;

      if (!userProject) console.warn("userProject not founded in sessions");
      else {
        hierarchies = await this.getHierarchies(
          this.projectId,
          userProject.cargo
        );
        if (!hierarchies) throw new Error('hierarchies not defined');

        incompleteHierarchies = hierarchies.some(hierarchy => hierarchy.type === 'fill');
      }


      this.setState({ hierarchies });
      this.setState({ cargo: (userProject && userProject.cargo || '') });
      this.setState({ incompleteHierarchies });
      this.setState({ perception });
    } catch (err) {
      console.error(err);
      notifyError(Messages.Geral.msgDataFetchError);
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.updateGraphs !== this.props.updateGraphs && this.props.updateGraphs) {
      this.setState({ graphsUpdated: true });
      await this.getGraphsResults();
      this.props.setUpdateGraphs(false);
    }
  }

  async componentDidMount() {
    await this.getGraphsResults();
    this.setState({ graphsUpdated: false });
  }

  havePotential() {
    const { perception } = this.state;
    if (!perception) return false;

    try {
      const potential = perception.atributosPotencial;

      if (!potential || (allFieldsNumeric(potential) === false)) {
        return false;
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  haveReadness() {
    const { perception } = this.state;
    if (!perception) return false;

    try {
      const readness = perception.atributosProntidao;

      if (!readness || (allFieldsNumeric(readness) === false)) {
        return false;
      }

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  havePrediction() {
    const { perception } = this.state;

    try {
      if (!perception ||
        !perception.preditivo ||
        !perception.preditivo.umCargoAcima) {
        return false;
      }

      if (!perception.preditivo.cargoAtual) {
        return false;
      }

      if (perception.preditivo.umCargoAcima.mesesPermanencia === null) {

        return false;
      }

      if (perception.preditivo.cargoAtual.prontoCargo === false &&
        perception.preditivo.cargoAtual.mesesPermanencia === null) {

        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  render() {

    if (this.state.loading) return null;

    if (this.state.error) return (
      <div>
        <RedText>
          {Messages.Geral.msgDataFetchError}
        </RedText>
      </div>
    )

    return (
      <div>
        {this.props.participantCanViewReadiness &&
        <Folder
          nome={"Prontidão"}
          key={'prontidao'}
          show={this.state.graphsUpdated && this.state.prontidaoFolder}
          onClick={() => {
            this.setState({ prontidaoFolder: true })
          }}
          onClose={() => {
            this.setState({ prontidaoFolder: false })
          }}
        >
          {this.haveReadness() ? (
            <>
            <ProntidaoIndGraph assessmentId={this.props.idAssessments}
              participantId={this.props.participantId} />
            <ReadinessResult assessmentId={this.props.idAssessments}
              participantId={this.props.participantId}
              idProjeto={this.props.idProjeto} />
            </>
          ) : (
              <NoResultsFound />
            )}
        </Folder>
        }

        {this.props.participantCanViewPotential &&
        <Folder
          nome={"Potencial"}
          key={'potencial'}
          show={this.state.graphsUpdated && this.state.potentialFolder}
          onClick={() => {
            this.setState({ potentialFolder: true })
          }}
          onClose={() => {
            this.setState({ potentialFolder: false })
          }}
        >
          {this.havePotential() ? (
            <>
            <PotentialIndGraph assessmentId={this.props.idAssessments} participantId={this.props.participantId} />
            <PotentialResult assessmentId={this.props.idAssessments}
              participantId={this.props.participantId}
              idProjeto={this.props.idProjeto} />
            </>
          ) : (
              <NoResultsFound />
            )}
        </Folder>
        }

        {this.props.isConsultant &&
        <Folder
          nome={"Predição"}
          key={'preditivo'}
          show={this.state.graphsUpdated && this.state.preditivoFolder}
          onClick={() => {
            this.setState({ preditivoFolder: true })
          }}
          onClose={() => {
            this.setState({ preditivoFolder: false })
          }}
        >
          {this.havePrediction() ? (
            <PreditivoResult assessmentId={this.props.idAssessments} participantId={this.props.participantId}
              incompleteHierarchies={this.state.incompleteHierarchies} />
          ) : (
              <NoResultsFound />
            )}
        </Folder>
        }
      </div>
    )
  }
}
