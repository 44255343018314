import apiCalls from 'config/apiCalls';
import React, { useEffect, useLayoutEffect, useState, useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { FaBell, FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tippy';
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { notifyError } from 'Services/Notificacoes';
import styled from 'styled-components';
import ReportProgressAPI from "views/Reports/ReportsList/ReportProgressAPI";
import Messages from 'static/Messages';
import { Constantes, notificationsType } from "Services/Constantes";
import { ButtonCancel, ButtonSave } from "views/Pages/Assets/Assets";

const { PDF_DONE, PRE_VISUALIZATION_DONE } = ReportProgressAPI.channels;

export const DropdownWrapper = styled.div`
  .dropdown-menu {
    max-height: 300px;
    overflow: auto;
    left: -55px !important;
  }

  .dropdown-header {
    margin: 5px;
    width: auto;
    min-width: 200px;
    padding: 10px 5px;
    border-radius: 3px;
    background-color: #f7f7f7;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #000;
    font-size: 12px;

    span {
      color: #000;
      cursor: default;
    }
  }

`;

let fetchTimeOut = null;

const NOTIFICATION_ACTIONS = {
  UPDATE: 'UPDATE'
}
const notificationReducer = (state, action) => {
  switch(action.type) {
    case NOTIFICATION_ACTIONS.UPDATE:
      return action.value;
    default:
      return state;
  }
}

export default function Notification() {
  const history = useHistory();

  const [notifications, dispatch] = useReducer(notificationReducer, []);
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFirstInteration, setIsFirstInteration] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [noCreditsModal, setNoCreditsModal] = useState(false);

  useEffect(() => {
    try {
      async function loadNotifications() {
        const userId = sessionStorage.getItem('user');
        if (!userId) return;
        let notifications = await apiCalls.notifications.getNotifications(userId);

        notifications.data.notifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        dispatch({
          type: NOTIFICATION_ACTIONS.UPDATE,
          value: notifications.data.notifications
        });
      }
      loadNotifications();
    } catch (err) {
      console.error('Não foi possível carregar as notificações');
    }
  }, []);

  const fetch = async () => {
    try {
      const userId = sessionStorage.getItem('user');
      if (!userId) return;
      setIsFetching(true);
      let responseNotifications = await apiCalls.notifications.getNotifications(userId);

      let fetchedNotifications = responseNotifications.data.notifications;

      let idsNotifications = notifications.map(n => n._id)
      let newNotifications = fetchedNotifications.filter(fN => !idsNotifications.find(id => fN._id === id));

      setIsFetching(false);
      dispatch({
        type: NOTIFICATION_ACTIONS.UPDATE,
        value: fetchedNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      });
    } catch (err) {
      console.error(err);
      notifyError(Messages.Reports.msgCannotRefreshNotifications);
    }
  }

  useEffect(() => {
    Constantes.io.on('Credits:NoCredits', async ({
      userId,
      userName,
      msg,
      moduleId,
      projectId,
      module
    }) => {
      if (userId === sessionStorage.getItem("user")) {
        if (!isFetching){
          await fetch();
        }
      }
    });

  }, []);

  useLayoutEffect(() => {
    if (!isFetching && !isFirstInteration){
      clearTimeout(fetchTimeOut);
      fetchTimeOut = setTimeout(async () => {

        await fetch();
      }, 1000 * 60 * 5); // 5 minutos
    }

    setIsFirstInteration(false);

  }, [notifications]);

  useEffect(() => {
    async function readAll() {
      if (!isOpen) {
        try {
          if (notifications.find(n => !n.viewed)) {
            dispatch({
              type: NOTIFICATION_ACTIONS.UPDATE,
              value: notifications.map(n => ({ ...n, viewed: true }))
            })
            await apiCalls.notifications.updateManyNotifications(notifications.map(n => n._id), { viewed: true });
            clearTimeout(fetchTimeOut);
          }
        } catch (err) {
          console.error('Não foi possível atualizar as notificações');
        }
      }
    }
    readAll();
  }, [isOpen]);

  useEffect(() => {

    async function handlePdfDone() {
      await fetch();
    }

    async function handlePreVisualization() {
      await fetch();
    }

    function listenToSocket() {
      ReportProgressAPI.socket.on(PDF_DONE, handlePdfDone);
      ReportProgressAPI.socket.on(PRE_VISUALIZATION_DONE, handlePreVisualization);
    }

    listenToSocket();

    return function unmount() {
      ReportProgressAPI.socket.off(PDF_DONE, handlePdfDone);
      ReportProgressAPI.socket.off(PRE_VISUALIZATION_DONE, handlePreVisualization);
    }
  }, []);

  async function checkAsDone(id) {
    try {
      await apiCalls.notifications.updateNotification(id, { done: true });

      dispatch({
        type: NOTIFICATION_ACTIONS.UPDATE,
        value: notifications.map(n => n._id == id ? { ...n, done: true } : n)
      })
    } catch (err) {
      console.error('Não foi possível marcar como lida');
    }
    clearTimeout(fetchTimeOut);
  }

  function toggleNoCreditsModal() {
    setNoCreditsModal(!noCreditsModal)
  }

  return (
    <>
      <Modal
      isOpen={noCreditsModal}
      toggle={toggleNoCreditsModal}
      >
        <ModalHeader toggle={toggleNoCreditsModal}>
          Aviso
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: 12 }}>Algumas atividades não podem ser realizadas, pois o saldo de créditos é
              insuficiente. Deseja adquirir créditos agora?</p>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel onClick={toggleNoCreditsModal}>Não</ButtonCancel>
        <ButtonSave onClick={() => {
          history.push('/financeiro/simulacao-compra');
          toggleNoCreditsModal();
        }}>
          Sim
        </ButtonSave>
        </ModalFooter>
      </Modal>
      <DropdownWrapper>
        <Dropdown isOpen={isOpen} toggle={e => setIsOpen(!isOpen)}>
          <DropdownToggle nav>
            <NavLink
              onClick={() => {
                // readAll();
              }}
              href="#"
              style={{
                color: "#fff"
              }}
            >
              <FaBell></FaBell>
              {
                notifications.filter(n => !n.viewed).length > 0 &&
                <Badge pill color="danger">{notifications.filter(n => !n.viewed).length}</Badge>
              }
            </NavLink>
          </DropdownToggle>
          <DropdownMenu left>
            {
              notifications.filter(n => n.done).length === notifications.length &&
              <DropdownItem header tag="div" style={{ backgroundColor: '#fff', borderBottom: 0 }} onClick={() => { }}>
                Não há novas notificações
              </DropdownItem>
            }
            {
              notifications && notifications.length > 0 && notifications.map(notification => (
                <>
                  {
                    !notification.done &&
                    <DropdownItem header tag="div" style={!notification.viewed ? { backgroundColor: '#dedede' } : {}} onClick={() => { }} >
                      <span style={{ cursor: 'pointer' }} onClick={() => {
                        if (notification.notification_type_id === notificationsType.CREDITS) {
                          toggleNoCreditsModal();
                        }
                      }}>{notification.content}</span>
                      <span style={{ marginLeft: 10, cursor: 'pointer' }}>
                        {
                          notification.done
                            ?
                            <FaCheckCircle size={14} />
                            :
                            <Tooltip
                              title="Excluir"
                              size="small"
                              position="left"
                              arrow="true"
                            >
                              <FaRegTimesCircle
                                size={14}
                                onClick={() => checkAsDone(notification._id)}
                              />
                            </Tooltip>
                        }
                      </span>
                    </DropdownItem>
                  }
                </>
              ))
            }
          </DropdownMenu>
        </Dropdown>
      </DropdownWrapper>
    </>
  )
}