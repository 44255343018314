import * as d3 from 'd3';
import cloud from 'd3-cloud';

function drawParticipant({ relacoes, words, id, wordsVotes }) {
  const width = 600;
  const height = 500;

  cloud()
  .size([width, height])
  .words(words.map(function (d) {
    // O tamanho agora é a soma dos votos
    const totalVotes = d.votes.reduce((sum, v) => sum + v, 0);
    return { text: d.text, size: totalVotes * 12 + 8, votes: d.votes }; // Multiplica por 10 para escalar o tamanho
  }))
  .padding(5)
  .rotate(() => (Math.random() > 0.5 ? 0 : 90))
  .fontSize(d => d.size)
  .on("end", draw)
  .start();

  function getVotesText(text='') {
    if (text) {
      return Object.keys(wordsVotes).map(key => {
        if (key.toLowerCase() === text.toLowerCase()) {
          return Object.keys(wordsVotes[key]).map(r => {
            return `${r}: ${wordsVotes[key][r]}\n`
          }).filter(v => v).join(' ')
        } else return ''
      }).filter(v => v).join(' ')
    } else return ''
  }

  function draw(words) {
    const color = d3.scaleOrdinal(d3.schemeCategory10)

    const svg = d3.select(`#${id}`)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    // Desenha as palavras
    svg.selectAll("text.word")
      .data(words)
      .enter().append("text")
      .attr("class", "word")
      .style("font-size", d => d.size + "px")
      //.style("fill", "grey")
      .style("fill", d => color(d.text))
      .style("font-family", "Roboto Condensed")
      .style("cursor", "pointer")
      .attr("text-anchor", "middle")
      .attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
      .text(d => d.text)
      .append('svg:title')
      .text(function(d) {
        return getVotesText(d.text);
      })
  }
}

export { drawParticipant }