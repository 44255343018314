import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import { Tooltip } from "react-tippy";
import { FaQuestionCircle } from "react-icons/fa";
import LeadershipChart from 'views/Pages/Resultados/ModalResultados/AtividadesDinamica/EstilosDeLideranca/Graficos/LeadershipChart';

export class Estilos extends Component {

  render() {
    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'row-reverse' }}>
          <Tooltip
            title="Clique na legenda dos gráficos para selecionar/ocultar a informação no gráfico."
            position="top"
            size="small"
            trigger="mouseenter"
            arrow="true"
          >
            <FaQuestionCircle size={16} style={{ marginRight: 5 }} />
          </Tooltip>
        </div>

        <Row>
          {this.props.rodadasData.map((rodada, i) => {

            if (!rodada.autoAvaliacao.radar && !rodada.equipe.radar) return;

            return <Col sm="12" md="6" lg="6">
              <LeadershipChart id={`result-leadership-chart-round-${this.props.idInstrumento}-${i}`}
                rodadasData={this.props.rodadasData}
                roundIndex={i}
                title={`Rodada ${i + 1} - Estilos de Liderança`} />
            </Col>

          })}
        </Row>

      </div>
    );
  }
}
