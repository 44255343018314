function getFloatFormated(value) {
  return Number(value.toFixed(2));
}

/**
 * Faz a primeira letra de uma string
 * ficar maíuscula e as outras minúsculas
 *
 * @param {string} txt
 */
export function upperCaseFirstLetter(txt) {
  if(txt){
    const firstLetter = txt[0].toUpperCase();
    return firstLetter + txt.slice(1, txt.length).toLowerCase();
  }
}

/**
 * Espera uma quantidade de tempo
 *
 * @param {number} ms O tempo para ser esperado em ms
 * @returns
 */
export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Formata o nome de um instrumento para ser
 * usado como url
 *
 * @export
 * @param {string} instrumentName
 * @returns {string} o nome do instrumento formatado
 */
export function formatInstrumentURL(instrumentName) {
  /**
   * As palavras que compoem o nome do instrumento
   * @type {string[]}
   */
  const words = instrumentName.split(' ');
  /**
   * As palavras que compoem o nome do instrumento,
   * sem acentos
   * @type {string[]}
   */
  const formattedWords = words.map(removeAccent);
  return formattedWords.join('-').toLowerCase();
}

/**
 * Remove acentos de uma palavra ou frase
 *
 * @export
 * @param {string} word A palavra ou frase para remover acentos
 * @returns {string} A palavra fornecida sem acentos e em letras maiúsculas
 */
export function removeAccent(word) {
  /**
   * A relação de letras e letras com acentos
   * que deve ser usada para mapear os acentos
   * @type {Object<string, Array<string>>}
   */
  const accents = {
    A: ['Á', 'À', 'Ã', 'Â'],
    E: ['É', 'Ê', 'È', 'Ẽ'],
    O: ['Ó', 'Ô', 'Õ', 'Ò'],
    I: ['Í', 'Ì', 'Ĩ', 'Î'],
    U: ['Ú', 'Ù', 'Û', 'Ũ'],
    C: ['Ç'],
  };
  word = word.toUpperCase();
  Object.keys(accents).forEach(letra => {
    const variantes = accents[letra];
    variantes.forEach(variante => {
      word = word.split(variante).join(letra);
    });
  });
  return word;
}
/**
 * Normaliza um valor por meio da normalização por máximo
 * @author LucasSzavara
 *
 * @param {double} max O maior resultado possível
 * @param {double} playerResult O valor para ser normalizado
 * @param {number} topValue O maior valor possível, por padrão 100
 * @returns {double} O valor normalizado
 */
export function normalizarPorMaximo(max, playerResult, topValue = 100) {
  if (max === 0) {
    return 0;
  }
  const result = (topValue * playerResult) / max;
  return result;
}

export function normalizeScore(
  pontuacao,
  melhorPontuacao,
  piorPontuacao,
  valorMax,
  valorMin = 0
) {
  const formatedScore = getFloatFormated(Number(pontuacao));
  const formatedBestScore = getFloatFormated(Number(melhorPontuacao));
  const formatedWorstScore = getFloatFormated(Number(piorPontuacao));
  const formatedMaxValue = getFloatFormated(Number(valorMax));
  const formatedMinValue = getFloatFormated(Number(valorMin));

  if (formatedBestScore === formatedWorstScore) return 0;

  const scoreCalc =
    ((formatedScore - formatedWorstScore) *
      (formatedMaxValue - formatedMinValue)) /
      (formatedBestScore - formatedWorstScore) +
    formatedMinValue;

  return getFloatFormated(Number(scoreCalc));
}
