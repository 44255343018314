import styled from 'styled-components';

export const SearchWrapper = styled.div`
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 12px;
  padding: 5px 6px;
  border: 1px solid #cacaca;
  border-radius: 20px;
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  width: ${(props) => props.width || '100%'};
  min-width: min-content;
  input {
    border: 0px;
    background: transparent;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: lightgray;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: lightgray;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: lightgray;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: lightgray;
    }
    .ico {
      width: 12px !important;
    }
  }
`;
