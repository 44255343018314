import Validator from 'validator';

const ValidateCargo = (n, cargos) => {
  let encontrado = cargos.find(c => c.cargo.toLowerCase() === n.toLowerCase());

  return encontrado;
};

const ValidateNumber = n => {
  var rgx = /^[0-9]+/;
  return n.match(rgx);
};

const ValidateSalario = n => {
  var rgx = /^[0-9]*\.?[0-9]*$/;
  return n.toString().match(rgx);
};

const ValidateEmail = email => {
  return Validator.isEmail(email + '');
};

const ValidateTelefoneComDDD = n => {
  var rgx = /^\(?[1-9]{2}\)?\s?\d{4,5}(\-|\s)?\d{4}$/;
  return n.toString().match(rgx);
};

const ValidateNome = n => {
  var rgx = /([A-Z][a-zçõã]+)([ a-z]+)/;
  return n.toString().match(rgx);
};

const CheckDate = data => {
  var expReg = /^((0[1-9]|[12]\d)\/(0[1-9]|1[0-2])|30\/(0[13-9]|1[0-2])|31\/(0[13578]|1[02]))\/(19|20)?\d{2}$/;
  var aRet = true;
  if (data && data.match(expReg) && data != "") {
    var dia = data.substring(0, 2);
    var mes = data.substring(3, 5);
    var ano = data.substring(6, 10);

    if ((mes == 4 || mes == 6 || mes == 9 || mes == 11) && dia > 30)
      aRet = false;
    else if (ano % 4 != 0 && mes == 2 && dia > 28) aRet = false;
    else if (ano % 4 == 0 && mes == 2 && dia > 29) aRet = false;
  } else aRet = false;
  return aRet;
};

export {
  ValidateEmail,
  ValidateNome,
  ValidateNumber,
  ValidateSalario,
  ValidateTelefoneComDDD,
  CheckDate,
  ValidateCargo
};
