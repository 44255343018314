import { normalizarPorMaximo } from 'Services/utilsFn';

export default function normalizeResultValues(result) {
  Object.keys(result).map(key => {
    result[key]['E'] = Math.round(100 * normalizarPorMaximo(10, result[key]['E'])) / 100;
    result[key]['S'] = Math.round(100 * normalizarPorMaximo(20, result[key]['S'])) / 100;
    result[key]['T'] = Math.round(100 * normalizarPorMaximo(20, result[key]['T'])) / 100;
    result[key]['J'] = Math.round(100 * normalizarPorMaximo(20, result[key]['J'])) / 100;
    result[key]['I'] = Math.round(100 * normalizarPorMaximo(10, result[key]['I'])) / 100;
    result[key]['N'] = Math.round(100 * normalizarPorMaximo(20, result[key]['N'])) / 100;
    result[key]['F'] = Math.round(100 * normalizarPorMaximo(20, result[key]['F'])) / 100;
    result[key]['P'] = Math.round(100 * normalizarPorMaximo(20, result[key]['P'])) / 100;
  });

  return result;
}