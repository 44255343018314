import apiCalls from "config/apiCalls";
import React, { Component } from "react";
import { Estilos } from "./Graficos/Estilos";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import Loading from "Services/Loading";
import { Constantes } from "Services/Constantes";
import api from "Services/api";

/**
 * @props idProjeto, idParticipante, idDinamica, rodada
 */
export default class InfoEstilosDeLideranca extends Component {
  constructor(props) {
    super(props);

    // gráfico de eficácia
    this.escala = _.range(-24, 24, 1);

    this.state = {
      resultadoIndividual: [],
      series: [],
      graficosEstilos: [],

      loading: false,
    };
  };

  findEficacia(e) {
    const eficacia = this.escala.findIndex(item => item == e);
    return eficacia;
  }

  countEfficiency(indices, emptyData) {
    for (var i = 0; i < emptyData.length; i++) {
      for (var j = 0; j < indices.length; j++) {
        if (i === indices[j]) {
          emptyData[i]++;
        }
      }
    }
    return emptyData;
  }

  loadSeries(rodadas) {
    let graficoEficacia = rodadas.map((rodada) => {
      return rodada.grafico.series
    })
    graficoEficacia.map((series, index) => {
      series.map((serie, _serieIndex) => {
        if (serie.name && serie.name == "Autoavaliação") {
          let indices = serie.data.map(e => this.findEficacia(e));
          let emptyData = new Array(49).fill(0);
          this.countEfficiency(indices, emptyData);

          this.setState(prevState => ({
            series: [
              ...prevState.series,
              {
                name: `Rodada ${index + 1} - Autoavaliação `,
                data: emptyData
              }
            ]
          }));
        }
        if (serie.name && serie.name == "Grupo") {
          let indices = serie.data.map(e => this.findEficacia(e));
          let emptyData = new Array(49).fill(0);
          this.countEfficiency(indices, emptyData);

          this.setState(prevState => ({
            series: [
              ...prevState.series,
              {
                name: `Rodada ${index + 1} - Grupo `,
                data: emptyData
              }
            ]
          }));
        }
      })
    });
  }

  async getAvg() {
    let avg = 0;

    const res = await api.post(
      `/v3/results/ranking-dinamica`,
      {
        idProjeto: this.props.idProjeto,
        dynamicId: this.props.idDinamica
      }
    );
    const result = res.data.results.find(r => (
      r.instrumentName.toLowerCase().trim() === Constantes.instrumentoEstilos.toLowerCase().trim() &&
      r.roundIndex === this.props.rodada
    ));
    if (!result) return NaN;

    let participantResult = result.rankings.find(r => (
      r.userId === this.props.idParticipante
    ));
    if (!participantResult) return NaN;

    avg = participantResult.ranking.totalConsultor;

    this.setState({ roundEfficiency: avg });
    return avg;
  }

  async loadEstilos(rodadas) {
    let graficos = [];
    let graficosEstilos2 = [];
    let estilosPlayed = true;

    if (!rodadas[this.props.rodada] ||
      !rodadas[this.props.rodada].autoAvaliacao.radar ||
      !rodadas[this.props.rodada].equipe.radar) {
        estilosPlayed = false;
    }

    rodadas.map((rodada, idx) => {
      if (rodada.autoAvaliacao.radar)
        graficos.push({ name: `Rodada ${idx + 1} - Autoavaliação`, estilos: rodada.autoAvaliacao.radar })
      if (rodada.equipe.radar)
        graficos.push({ name: `Rodada ${idx + 1} - Grupo`, estilos: rodada.equipe.radar })
    });

    for (let idx = 0; idx < rodadas.length; idx++) {
      let rodada = rodadas[idx];

      if (rodada.rodada !== this.props.rodada) continue;

      if (!rodada.autoAvaliacao.radar && !rodada.equipe.radar) continue;

      let avg = await this.getAvg({ roundIndex: idx });
      graficosEstilos2.push({
        name: `Rodada ${idx + 1}`,
        avg,
        series: []
      });

      if (rodada.autoAvaliacao.radar) {
        graficosEstilos2[graficosEstilos2.length - 1].series.push(
          {
            name: `Rodada ${idx + 1} - Autoavaliação`,
            data: [
              rodada.autoAvaliacao.radar.laissezFaire,
              rodada.autoAvaliacao.radar.autoritario,
              rodada.autoAvaliacao.radar.diretivo,
              rodada.autoAvaliacao.radar.modelador,
              rodada.autoAvaliacao.radar.participativo,
              rodada.autoAvaliacao.radar.desenvolvedor,
              rodada.autoAvaliacao.radar.afetivo,
            ]
          },
        )
      }

      if (rodada.equipe.radar) {
        graficosEstilos2[graficosEstilos2.length - 1].series.push({
          name: `Rodada ${idx + 1} - Grupo`,
          data: [
            rodada.equipe.radar.laissezFaire,
            rodada.equipe.radar.autoritario,
            rodada.equipe.radar.diretivo,
            rodada.equipe.radar.modelador,
            rodada.equipe.radar.participativo,
            rodada.equipe.radar.desenvolvedor,
            rodada.equipe.radar.afetivo,
          ]
        })
      }
    }

    this.setState({
      estilosPlayed,
      graficosEstilos: graficos,
      graficosEstilos2,
      rodadas,
    });
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idDinamica, rodada } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.dinamicas.getInfoEstilosDeLideranca(idProjeto, idParticipante, idDinamica, rodada);

      const { rodadasData } = response.data;

      if (rodadasData) {
        await this.getAvg();
        await this.loadEstilos(rodadasData);
        this.loadSeries(rodadasData);
      }

      this.setState({
        normalized_total_average: response.data.normalized_total_average
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    // let linkedInEstilos = resultadoIndividual.isVinculado !== false;
    let linkedInEstilos = true;
    const { estilosPlayed, graficosEstilos, series, graficosEstilos2, normalized_total_average, rodadas } = this.state;

    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            :
            <div>
              {estilosPlayed && !this.state.loading ? (
                <Estilos
                  areas={[
                    "Laissez-Faire",
                    "Autoritário",
                    "Diretivo",
                    "Modelador",
                    "Participativo",
                    "Desenvolvedor",
                    "Afetivo",
                  ]}
                  width={"100%"}
                  height={"100%"}
                  valores={graficosEstilos.map((r) => {
                    return {
                      name: r.name,
                      data: [
                        r.estilos.laissezFaire,
                        r.estilos.autoritario,
                        r.estilos.diretivo,
                        r.estilos.modelador,
                        r.estilos.participativo,
                        r.estilos.desenvolvedor,
                        r.estilos.afetivo,
                      ],
                    };
                  })}
                  valores2={graficosEstilos2}
                  rodada={this.props.rodada}
                  rodadasData={this.state.rodadas}
                  tag="activity-chart"
                  idInstrumento={this.props.idInstrumento}
                  avg={this.state.roundEfficiency}
                />
              ) : ''}

              {
                !estilosPlayed &&
                <Row>
                  <Col>
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Ainda não há dados para construção do gráfico de estilos de
                      liderança
                    </p>
                  </Col>
                </Row>
              }

              {
                !linkedInEstilos &&
                <Row>
                  <Col>
                    <p style={{ color: "red", fontSize: "12px" }}>
                      O participante foi desvinculado
                    </p>
                  </Col>
                </Row>
              }
            </div>
        }
      </div>
    )
  }
}
