import { converterParaSegundos } from '../Dinamicas/Acompanhamento/utilsTempo';
import apiCalls from 'config/apiCalls';
import moment from "moment";

function timeToDecimal(t) {
  var arr = t.split(':');
  var dec = parseInt((arr[1]/6)*10, 10);

  return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
}

function decimalToTime(t) {
  var decimalTimeString = String(t);
  var decimalTime = parseFloat(decimalTimeString);
  decimalTime = decimalTime * 60 * 60;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));
  decimalTime = decimalTime - (minutes * 60);
  var seconds = Math.round(decimalTime);
  if(hours < 10)
  {
    hours = "0" + hours;
  }
  if(minutes < 10)
  {
    minutes = "0" + minutes;
  }
  if(seconds < 10)
  {
    seconds = "0" + seconds;
  }
  return("" + hours + ":" + minutes);
}

async function overlapsExistingSession(consultantSessions = [], dataInicioCalculo, sessao, assessmentRes, assessment) {

  const assessmentsObjArray = assessmentRes.filter(a => a);

  const sessionStartDate = new Date(dataInicioCalculo.format("YYYY-MM-DD") + " " + sessao.inicioSessao.format("HH:mm"));
  const sessionEndDate = new Date(dataInicioCalculo.format("YYYY-MM-DD") + " " + sessao.fimSessao.format("HH:mm"));

  const existingSessions = consultantSessions
    .filter(s => new Date(s.horaInicio).getDate() === dataInicioCalculo._d.getDate());

  if (!existingSessions.length) return false;

  let overlaps = false;
  const conflictedSessions = [];
  existingSessions.forEach(async consultantSession => {
    if (
      (sessionStartDate.toISOString() >= consultantSession.horaInicio &&
        sessionStartDate.toISOString() < consultantSession.horaFim) ||
      (sessionEndDate.toISOString() > consultantSession.horaInicio &&
        sessionEndDate.toISOString() <= consultantSession.horaFim) ||
      (sessionStartDate.toISOString() < consultantSession.horaInicio &&
        sessionEndDate.toISOString() > consultantSession.horaFim)
    ) {
      const conflictedAssessment = assessmentsObjArray.find(a => a._id === consultantSession.assessment)

      const currentSession = { sessionId: sessao._id, startTime: sessionStartDate.toISOString(), endTime: sessionEndDate.toISOString(), assessmentName: assessment.nome, date: sessao };
      const conflictedSession = { sessionId: consultantSession._id, startTime: consultantSession.horaInicio, endTime: consultantSession.horaFim, assessmentName: conflictedAssessment ? conflictedAssessment.nome : '' };

      conflictedSessions.push({ currentSession, conflictedSession });
      overlaps = true; // "sessao" intersects with an existing session.
    }
  });

  return overlaps;
}

function verifySessionTime({ assessment }) {

  const horarioAlmocoInicio = new moment(assessment.horarioAlmocoInicio)
  const horarioAlmocoFim = new moment(assessment.horarioAlmocoFim)

  const almocoInicioDecimal = timeToDecimal(horarioAlmocoInicio.format("HH:mm"))
  const almocoFimDecimal = timeToDecimal(horarioAlmocoFim.format("HH:mm"))

  const subAlmoco = (almocoFimDecimal - almocoInicioDecimal) < 0 ? (almocoFimDecimal - almocoInicioDecimal) * -1 : (almocoFimDecimal - almocoInicioDecimal);

  const horarioInicio = new moment(assessment.horarios.horarioInicio)
  const horarioFim = new moment(assessment.horarios.horarioFim)

  const inicioDecimal = timeToDecimal(horarioInicio.format("HH:mm"))
  const fimDecimal = timeToDecimal(horarioFim.format("HH:mm"))

  const subHorario = (fimDecimal - inicioDecimal) < 0 ? (fimDecimal - inicioDecimal) * -1 : (fimDecimal - inicioDecimal);

  const sub = (subHorario - subAlmoco) < 0 ? (subHorario - subAlmoco) * -1 : (subHorario - subAlmoco);

  return sub;
}

async function calcularSessoesDia(horarioInicio, horarioFim, horarioAlmocoInicio, horarioAlmocoFim, tempoSessaoInformado, intervaloSessoesInformado, assessment, selectedDays) {

  let horariosCalculados = [];

  let tempoSessao = converterParaSegundos(tempoSessaoInformado);
  let tempoIntervalo;

  try {
    tempoIntervalo = converterParaSegundos(intervaloSessoesInformado);
  } catch (err) {
    tempoIntervalo = null;
  }

  if (tempoSessao < 60) {
    return;
  }

  //=====================================CALCULO PARA QUANDO NÃO TEM HORÁRIO DE ALMOÇO==================================
  if ((assessment.haveInterval && ((horarioAlmocoFim && horarioAlmocoFim.format("HH:mm")) === (horarioAlmocoInicio && horarioAlmocoInicio.format("HH:mm")))) || !assessment.haveInterval) {

    var horarioInicioCalculo = new moment(horarioInicio);
    var horarioFimCalculo = new moment(horarioFim);

    while (horarioInicioCalculo < horarioFimCalculo) {
      let inicioSessao = new moment(horarioInicioCalculo.format("YYYY-MM-DD HH:mm"));
      let fimSessao = new moment(horarioInicioCalculo.format("YYYY-MM-DD HH:mm")).add(tempoSessao, "seconds");

      if (fimSessao <= horarioFimCalculo) {
        horariosCalculados.push({
          inicioSessao,
          fimSessao
        });

        horarioInicioCalculo = horarioInicioCalculo.add(tempoSessao, "seconds");
        horarioInicioCalculo = horarioInicioCalculo.add(tempoIntervalo, "seconds");
      } else {
        break;
      }
    }
  }
  //=====================================CALCULO PARA QUANDO TEM HORÁRIO DE ALMOÇO==================================
  else {
    var horarioInicioCalculo = new moment(horarioInicio);
    var horarioFimCalculo = new moment(horarioAlmocoInicio);

    while (horarioInicioCalculo < horarioFimCalculo) {
      let inicioSessao = new moment(horarioInicioCalculo.format("YYYY-MM-DD HH:mm"));
      let fimSessao = new moment(horarioInicioCalculo.format("YYYY-MM-DD HH:mm")).add(tempoSessao, "seconds");

      if (fimSessao <= horarioFimCalculo) {
        horariosCalculados.push({
          inicioSessao,
          fimSessao
        });

        horarioInicioCalculo = horarioInicioCalculo.add(tempoSessao, "seconds");
        horarioInicioCalculo = horarioInicioCalculo.add(tempoIntervalo, "seconds");
      } else {
        break;
      }
    }

    horarioInicioCalculo = new moment(horarioAlmocoFim);
    horarioFimCalculo = new moment(horarioFim);

    while (horarioInicioCalculo < horarioFimCalculo) {
      let inicioSessao = new moment(horarioInicioCalculo.format("YYYY-MM-DD HH:mm"));
      let fimSessao = new moment(horarioInicioCalculo.format("YYYY-MM-DD HH:mm")).add(tempoSessao, "seconds");

      if (fimSessao <= horarioFimCalculo) {
        horariosCalculados.push({
          inicioSessao,
          fimSessao
        });

        horarioInicioCalculo = horarioInicioCalculo.add(tempoSessao, "seconds");
        horarioInicioCalculo = horarioInicioCalculo.add(tempoIntervalo, "seconds");
      } else {
        break;
      }
    }
  }

  return horariosCalculados;
}

async function calcularSomenteDataFim({ consultor, assessmentId }) {
  let selectedDays = [1,0,2,3,6,5,4];
  let assessment = {
    horarioAlmocoFim: null,
    horarioAlmocoInicio: null,
    horarios: {
      horarioFim: moment().endOf('day'), // moment
      horarioInicio: moment(), // moment
    },
    datas: {
      dataInicio: moment(),
      dataPrazoInicio: moment(), // moment
      dataPrazoFim: moment('2099-12-31')
    },
    tempoSessao: '01:00',
    quantidadeSessoes: '1',
    haveInterval: false,
    local: 'sem local - sessão gerada automaticamente',
    consultor,
    tipoPrazo: 1,
    diasAntecedencia: undefined,
    inscricoesConsultor: true,
    sessoes: []
  }

  if (
    assessment.horarioAlmocoFim &&
    assessment.horarioAlmocoInicio &&
    new Date(assessment.horarioAlmocoFim) != "Invalid Date" &&
    new Date(assessment.horarioAlmocoInicio) != "Invalid Date" &&
    assessment.horarios.horarioFim &&
    assessment.horarios.horarioInicio &&
    new Date(assessment.horarios.horarioFim) != "Invalid Date" &&
    new Date(assessment.horarios.horarioInicio) != "Invalid Date" &&
    assessment.tempoSessao
  ) {
    const sessionTimeDecimal = timeToDecimal(assessment.tempoSessao);
    const maxSessionTime = verifySessionTime({ assessment });
    if (sessionTimeDecimal > maxSessionTime) {
      throw new Error(
        `Tempo de sessão não pode ser superior a ${decimalToTime(maxSessionTime)}hrs`
      );
    }
  }

  if (new Date(assessment.datas.dataInicio) &&
    new Date(assessment.horarios.horarioInicio) &&
    assessment.tempoSessao &&
    selectedDays.length &&
    (assessment.quantidadeSessoes > 0) &&
    assessment.horarios.horarioFim
  ) {

    if (assessment.haveInterval && (!assessment.horarioAlmocoInicio || !assessment.horarioAlmocoFim)) return;

    let sessoes = [];

    var horariosCalculados = await calcularSessoesDia(assessment.horarios.horarioInicio,
      assessment.horarios.horarioFim,
      assessment.horarioAlmocoInicio,
      assessment.horarioAlmocoFim,
      assessment.tempoSessao,
      assessment.intervaloSessoes,
      assessment,
      selectedDays
    );

    var dataInicioCalculo = moment(moment(assessment.datas.dataInicio).format("YYYY-MM-DD"));

    if (!horariosCalculados.length) {
      throw new Error('Não foi possível gerar sessões com os parâmetros informados');
    }

    var quantidadeDias = assessment.quantidadeSessoes / horariosCalculados.length;

    var quantidadeDiasLocalizados = 0;

    const res = await apiCalls.assessments.getSessionsByConsultant({
      consultantId: consultor
    });
    const consultantSessions = res.data;

    const existingSessions = consultantSessions
      .filter(s => new Date(s.horaInicio).getDate() === dataInicioCalculo._d.getDate());

    let assessmentRes = [];
    if (existingSessions && existingSessions.length) {
      const assessmentsIds = [];
      existingSessions.map(s => {
        if (!assessmentsIds.find(a => a === s.assessment)) assessmentsIds.push(s.assessment);
      });
      const { data } = await apiCalls.assessments.getAssessmentsByIds(JSON.stringify(assessmentsIds));

      assessmentRes = data.assessments;
    }

    while ((quantidadeDiasLocalizados < quantidadeDias)) {

      if (selectedDays.includes(dataInicioCalculo.weekday()) == true) {

        let sessionsAdded = 0;
        for (const sessao of horariosCalculados) {

          if (sessoes.length < assessment.quantidadeSessoes) {

            if (await overlapsExistingSession(consultantSessions, dataInicioCalculo, sessao, assessmentRes, assessment)) continue;

            sessoes.push({
              assessment: assessmentId,
              idUsuario: consultor,
              local: assessment.local,
              consultor: assessment.consultor,
              data: new Date(dataInicioCalculo.format("YYYY-MM-DD") + " " + sessao.inicioSessao.format("HH:mm")),
              horaInicio: new Date(dataInicioCalculo.format("YYYY-MM-DD") + " " + sessao.inicioSessao.format("HH:mm")),
              horaFim: new Date(dataInicioCalculo.format("YYYY-MM-DD") + " " + sessao.fimSessao.format("HH:mm")),
              prazoInscricaoTipo: assessment.tipoPrazo,
              prazoInscricaoAntecedencia: assessment.diasAntecedencia,
              prazoInscricaoDataInicio: assessment.datas.dataPrazoInicio ? new Date(assessment.datas.dataPrazoInicio).toISOString() : null,
              prazoInscricaoDataFim: assessment.datas.dataPrazoInicio ? new Date(assessment.datas.dataPrazoFim).toISOString() : null,
              inscricoesConsultor: assessment.inscricoesConsultor
            });
            sessionsAdded += 1;
          }
        }

        if (sessionsAdded > 0) quantidadeDiasLocalizados++;
      }

      dataInicioCalculo = dataInicioCalculo.add(1, 'days');
    }

    assessment["datas"]["dataFim"] = moment(sessoes[sessoes.length - 1].horaFim);
    assessment.sessoes = sessoes;

    return sessoes;
    }
}

export default calcularSomenteDataFim;