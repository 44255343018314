import React, { useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Moment from "moment";
/**
 * As props que devem ser passadas para o DateTimePicker
 * @typedef {Object} DateTimePickerProps
 * @property {Function} onChange A função de atualização dos valores de data e horário
 * @property {Date | Moment.Moment} value O horário inicial
 * @property {string} [label] O texto do campo
 * @property {string} [invalidDateMessage] O texto de data inválida
 */
/**
 * O componente padrão de leitura de date time picker
 * A única forma de fazer o `KeyboardDatePicker` funcionar é
 * usando conceitos do react hooks
 *
 * @see {@link https://pt-br.reactjs.org/docs/hooks-intro.html } Para uma introdução ao react hooks
 * @see {@link https://material-ui-pickers.dev/api/KeyboardDatePicker } Para a documentação do `KeyboardDateTimePicker`
 * @see {@link https://material-ui-pickers.dev/demo/date-picker#inline-mode } Para um exemplo do `KeyboardDateTimePicker`
 * @export
 * @param {DatePickerProps} props
 * @returns {ThemeProvider} O componente para ler data e horário
 */
export default function(props) {
  const [selectedDate, handleDateChange] = useState(props.value);
  return (
    <KeyboardDatePicker
      {...props}
      variant="inline"
      ampm={false}
      label={props.label}
      value={selectedDate}
      onChange={(date, value) => {
        handleDateChange(date, value);
        props.onChange(date, value);
      }}
      format="DD/MM/YYYY"
      invalidDateMessage={
        props.invalidDateMessage || "Formato de data inválido"
      }
    />
  );
}
