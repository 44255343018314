import { Colors } from 'utils';
import { PLOT_COLORS } from 'Services/Constantes';
import { normalizarPorMaximo } from 'Services/utilsFn';

const BACKGROUND_COLORS = [
  Colors.setOpacity(PLOT_COLORS[0], 0.6),
  Colors.setOpacity(PLOT_COLORS[1], 0.6),
  Colors.setOpacity(PLOT_COLORS[2], 0.6),
  Colors.setOpacity(PLOT_COLORS[3], 0.6),
  Colors.setOpacity(PLOT_COLORS[4], 0.6),
  Colors.setOpacity(PLOT_COLORS[5], 0.6),
  Colors.setOpacity(PLOT_COLORS[6], 0.6),
  Colors.setOpacity(PLOT_COLORS[7], 0.6),
];

const graphConfig = () => ({
  type: 'horizontalBar',
  data: {},
  options: {
    layout: {
      padding: {
        left: 180
      }
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          display: true,
          drawBorder: true,
        },
        ticks: {
          fontColor: 'black',
          max: 100,
          min: 0,
          // precision: 0,
          // stepSize: 1,
        }
      }],
      yAxes: [
        {
          align: 'start',
          labels: [
            'Iniciação e Persistência',
            'Eficácia perante a adversidade',
            'Eficácia social'
          ],
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
          },
          ticks: {
            display: true,
            fontColor: 'black',
            mirror: true,
            padding: 180
          }
        },
        // {
        //   labels: ['I', 'N', 'F', 'P'],
        //   position: 'right',
        //   type: 'category',
        //   offset: true,
        //   stacked: true,
        //   gridLines: {
        //     display: true,
        //     drawBorder: true,
        //   },
        //   ticks: {
        //     fontColor: 'black'
        //   }
        // }
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Auto Eficácia',
      fontColor: 'black'
    },
    tooltips: {
      enabled: false
    }
  }
});

function drawParticipant({ result }) {
  const iniNormalizado = Math.round(100 * normalizarPorMaximo(7, result.ini)) / 100;
  const epNormalizado = Math.round(100 * normalizarPorMaximo(7, result.ep)) / 100;
  const esNormalizado = Math.round(100 * normalizarPorMaximo(7, result.es)) / 100;

  let datasets = [];

  datasets.push({
    data: [iniNormalizado, epNormalizado, esNormalizado],
    type: 'horizontalBar',
    backgroundColor: [BACKGROUND_COLORS[0], BACKGROUND_COLORS[1], BACKGROUND_COLORS[2]],
    categoryPercentage: 1.0,
    barPercentage: 0.9,
  });

  return datasets;
}

export default {

  graphConfig,
  drawParticipant
}