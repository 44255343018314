export function validarNovoTempo(tempo) {
  return /^([0-1]?\d|2[0-3])(?::([0-5]?\d))?(?::([0-5]?\d))?$/.test(tempo)

}

export function converterParaMs(tempo) {
  let hora = parseInt(tempo.slice(0, 2), 10);
  let minuto = parseInt(tempo.slice(3, 5), 10);
  let segundo = parseInt(tempo.slice(6, 8), 10);
  return (hora * 3600 + minuto * 60 + segundo) * 1000;
}

export function converterParaSegundos(tempo) {
  const hora = parseInt(tempo.slice(0, 2), 10);
  const minuto = parseInt(tempo.slice(3, 5), 10);
  return (hora * 60 + minuto) * 60;
}
