import React from 'react';
import styled from 'styled-components';

import EmailAutocomplete from "views/Components/EmailAutocomplete/index";

const TextInputField = styled.input`
  background: transparent;
  border: ${props => props.valid ? 'none' : '1px solid #f00' };
  border-bottom: ${props => props.valid ? '1px solid #c8ced3' : '1px solid #f00'};
  width: 200px;
  margin-left: 10px;
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cdd8dc;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #cdd8dc;
  }
  ::-ms-input-placeholder {
    /* IE 10+ */
    color: #cdd8dc;
  }
  ::-moz-placeholder {
    /* Firefox 18- */
    color: #cdd8dc;
  }

  @media(max-width: 991px) {
    width: 100% !important;
  }
`;

function Input(props) {
  return (
     <div style={{ display: 'flex', alignItems: 'flex-end', opacity: props.disabled ? '50%' : '100%' }}>
      <span style={{ fontWeight: 'bold', whiteSpace: props.whiteSpace || 'normal' }}>{props.label}</span>
      {
        props.name && props.name === 'email' ?
        <EmailAutocomplete optionsStyle={props.optionsStyle} inputStyle={{ padding: 0 }} style={{ marginTop: 20, marginLeft: 10, width: 400 }} hidePlaceholder={true} name={props.name} onChange={props.onChange} disabled={props.disabled} required={props.required} value={props.value} />
        : <TextInputField
        valid={props.valid}
        disabled={props.disabled}
        style={props.style}
        type={props.type || 'text'}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        required={props.required}
        maxLength={props.maxLength}
      />
      }

    </div>
  );
}

export default Input;