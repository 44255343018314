import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import LocusDeControleGraph from './Graficos/LocusDeControleGraph';
import { assincronosApi } from 'Services/api';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import LocusControlChart from './Graficos/LocusControlChart';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

/**
* ### Componente de Resultados de Locus de Controle
*
* /**
* @author Gustavo Carvalho Silva
* @since 01/12/2020
* @props
* - idProjeto
* - idParticipante
* - idAssincrono
* - idInstrumento
*
* @desc Monta os resultados de um instrumento do tipo Locus de Controle
*/
export default class LocusDeControle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultado: {},
      respondentes: [],
      textosAutoAvaliado: '',
      whatsBeingMeasuredTextFound: '',
      loading: false,
    }
  }

  /**
   * @param { number } nota nota do participante no locusExternoBruto
   * Retorna um texto baseado na nota do participante
   */
  async getTextoFromAncora(nota) {
    const stops = [5, 11, 17, 23];
    let result = 5;
    stops.reverse().forEach(stop => {
      if (stop >= nota) {
        result = stop;
      }
    });
    let res = {};
    if (this.props.participantViewResults) {
      res = await assincronosApi.get(
        `/resultado/locus/texto-participante/${result}`,
      );
    } else {
      res = await assincronosApi.get(
        `/resultado/locus/texto-consultor/${result}`,
      );
    }
    return res.data;
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);
      let resultado = response.data;
      if (resultado.length > 0) {
        const textosAutoAvaliado = await this.getTextoFromAncora(
          resultado[0].locusExternoBruto,
        );

        this.setState({
          resultado: resultado[0],
          textosAutoAvaliado,
          whatsBeingMeasuredTextFound: textosAutoAvaliado && textosAutoAvaliado.whatsBeingMeasuredText || ''
        });
      }

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    const {
      idInstrumento,
      idAssincrono
    } = this.props;

    const {
      resultado,
      respondentes,
      textosAutoAvaliado
    } = this.state;

    return (
      <>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                resultado.locusExternoBruto
                  ?
                  <>
                  <Row>
                    <Col>
                      <LocusControlChart
                        id={`${idAssincrono}-${idInstrumento}`}
                        resultado={resultado}
                        respondentes={respondentes}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <p style={{ fontSize: 18, fontWeight: 'bold' }}>O que está sendo analisado</p><br />
                    <p dangerouslySetInnerHTML={{ __html: this.state.whatsBeingMeasuredTextFound }}></p>
                  </Row>
                  <br />
                  <Row style={{ flexDirection: 'column' }}>
                    <p style={{ fontSize: 18, fontWeight: 'bold' }}>Resultado</p>
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textosAutoAvaliado.texto) }}></p>
                  </Row>
                </>
                :
                <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
              }
            </>
        }
      </>
    )
  }
}