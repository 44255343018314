import axios from "axios";

export default async function getResult(resultId) {
  try {
    return await axios.get(
      `${process.env.REACT_APP_RESULTS_API_URL}/results-api/${resultId}`
    );
  } catch (err) {
    console.error(err);
  }
}
