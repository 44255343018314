import styled from 'styled-components';

export const BreadCrumbContainer = styled.nav`
  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }

  li + li {
    padding-left: 0.5rem;
  }

  li + li::before {
    content: "/";
    display: inline-block;
    padding-right: 0.5rem;
  }
`;

export const BreadCrumbItem = styled.li`
  margin: 0;
  display: inline-block;
  text-transform: capitalize;
`;