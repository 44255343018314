import { Calculations } from 'utils';
import GraphConstants from 'views/Pages/Modulos/Assessment/Resultados/Components/PPGraph/constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const {
  PARTICIPANT_POINT_COLOR
} = GraphConstants;

const {
  defineLinearFunc,
  func,
  twoDecimalPoints
} = Calculations;
const LAST_POINT = 22;

const DASHED_LINE_COLOR = '#bab9b7';

function hierarquiasDataset(state) {
  let datasets = [];
  const preditivoFirstCargo = state.hierarquia[0].tempoPreditivo;

  let y = 0;
  let x = preditivoFirstCargo;
  state.hierarquia.map((hierarquia, index, _array) => {
    let data = [];

    const point1 = {
      x: 0,
      y
    };

    const point2 = {
      x,
      y: index === 0 ? preditivoFirstCargo : y + preditivoFirstCargo
    }

    const [a, b] = defineLinearFunc([point1, point2]);
    // ax + b = y
    // ax = y - b
    // let x = (y - b) / a
    let point3X = (22 - b) / a
    const point3 = {
      x: point3X,
      y: 22
    };

    data.push(point1);
    data.push(point3);

    y += hierarquia.tempoPreditivo;

    datasets.push({
      linearFunc: {
        a,
        b,
        linearFunc: func(a, b)
      },
      label: hierarquia.cargo,
      fill: false,
      borderDash: [10, 10],
      data,
      tension: 0,

      borderColor: DASHED_LINE_COLOR,
      backgroundColor: DASHED_LINE_COLOR,
      pointBorderColor: DASHED_LINE_COLOR,
      pointBackgroundColor: DASHED_LINE_COLOR,
      pointHoverBackgroundColor: DASHED_LINE_COLOR,
      pointHoverBorderColor: DASHED_LINE_COLOR,
      pointBackgroundColor: DASHED_LINE_COLOR,
    });
  });

  return datasets;
}

function drawParticipant(state, cadastroParticipante, perception) {
  let datasets = [];
  let data = [];

  const { preditivo } = perception;

  const cargoParticipante = cadastroParticipante.cargo;
  const cargoIndex = state.hierarquia
    .findIndex(hierarquia => (
      hierarquia.cargo.toLowerCase().trim() ===
      cargoParticipante.toLowerCase().trim())
    );
  const preditivoFirstCargo = state.hierarquia[cargoIndex].tempoPreditivo;
  let initialY = 0;
  for (const hierarquia of state.hierarquia) {

    if (cargoParticipante.toLowerCase() === hierarquia.cargo.toLowerCase()) break;
    initialY += hierarquia.tempoPreditivo;
  }

  let zeroPoint = {
    x: 0,
    y: initialY
  }
  let coefs = [];

  const { cargoAtual, umCargoAcima } = preditivo;
  let cargoAtualPoint, nextCargoPoint;

  if (cargoAtual.prontoCargo === false) {
    const mesesPermanencia = Number((cargoAtual.mesesPermanencia / 6).toFixed(2));

    cargoAtualPoint = {
      x: zeroPoint.x + mesesPermanencia,
      y: initialY
    }

    const mesesPermanenciaProximo = Number((umCargoAcima.mesesPermanencia / 6).toFixed(2));

    nextCargoPoint = {
      x: cargoAtualPoint.x + mesesPermanenciaProximo,
      y: cargoAtualPoint.y + preditivoFirstCargo
    }

    coefs = defineLinearFunc([cargoAtualPoint, nextCargoPoint])
  } else {

    const mesesPermanenciaProximo = Number((umCargoAcima.mesesPermanencia / 6).toFixed(2));

    nextCargoPoint = {
      x: zeroPoint.x + mesesPermanenciaProximo,
      y: zeroPoint.y + preditivoFirstCargo
    }

    coefs = defineLinearFunc([zeroPoint, nextCargoPoint])
  }

  const linearFunc = func(coefs[0], coefs[1]);
  const lastPointX = LAST_POINT;
  const lastPointY = linearFunc(lastPointX);
  const lastPoint = { x: lastPointX, y: lastPointY }

  data.push(zeroPoint);
  if (cargoAtualPoint) data.push(cargoAtualPoint);
  if (nextCargoPoint) data.push(nextCargoPoint);
  if (lastPoint) data.push(lastPoint);

  datasets.push({
    linearFunc: {
      a: coefs[0],
      b: coefs[1],
      func: linearFunc
    },
    fill: false,
    data,
    tension: 0,
    borderColor: PARTICIPANT_POINT_COLOR,
    borderWidth: 10,
    pointRadius: 0,
  });

  return datasets;
}

function drawIntersections({ state, perception, participantDatasets, hierarquiaDatasets }) {
  let datasets = [];
  // a1x + b1 = a2x + b2
  // a1x = a2x + b2 - b1
  // a1x - a2x = b2 - b1
  // x * (a1 - a2) = b2 - b1
  // x = b2 - b1 / (a1 - a2)
  let intersectionData = [];

  if (perception.preditivo.cargoAtual.prontoCargo) {
    const firstPoint = participantDatasets[0].data[0];

    intersectionData.push({
      x: firstPoint.x,
      y: firstPoint.y,
    });
  } else {

    intersectionData.push({
      x: participantDatasets[0].data[1].x,
      y: participantDatasets[0].data[1].y,
    });
  }

  hierarquiaDatasets.map(hierarquia => {
    let a1 = participantDatasets[0].linearFunc.a;
    let b1 = participantDatasets[0].linearFunc.b;
    let a2 = hierarquia.linearFunc.a;
    let b2 = hierarquia.linearFunc.b;
    let x = twoDecimalPoints(
      twoDecimalPoints((b2 - b1)) /
      twoDecimalPoints((a1 - a2))
    );
    let y = participantDatasets[0].linearFunc.func(x)

    if (
      !x ||
      !y ||
      x < 0 ||
      y < 0 ||
      x < participantDatasets[0].data[0].x ||
      y < participantDatasets[0].data[0].y) {

      return;
    }

    intersectionData.push(
      {
        x,
        y
      }
    );
  });

  datasets.push({
    fill: false,
    data: intersectionData,
    tension: 0,

    borderColor: PARTICIPANT_POINT_COLOR,
    backgroundColor: PARTICIPANT_POINT_COLOR,
    pointBorderColor: PARTICIPANT_POINT_COLOR,
    pointBackgroundColor: PARTICIPANT_POINT_COLOR,
    pointHoverBackgroundColor: PARTICIPANT_POINT_COLOR,
    pointHoverBorderColor: PARTICIPANT_POINT_COLOR,
    pointBackgroundColor: PARTICIPANT_POINT_COLOR,
    pointRadius: 6,
    pointBorderWidth: 6,
    pointHoverRadius: 8,
    borderWidth: 10,
    pointHoverBorderWidth: 3,
    pointHitRadius: 5,
  });

  return datasets;
}

const chartConfig = () => ({
  type: 'line',
  data: {
    datasets: []
  },
  plugins: [ChartDataLabels],
  options: {
    legend: {
      display: false,
      position: 'bottom'
    },

    plugins: {
      datalabels: {
        clamp: true,
        align: function (context) {
          const x = context.dataset.data[context.dataIndex].x;
          if (x >= 20) {
            return 'left'
          };

          return 'bottom';
        },
        display: true,
        padding: 0,
        font: {
          size: '12'
        },
        formatter: function (value, context) {
          // if (context.dataIndex === 0) return ''
          let label = context.dataset.label || '';

          // const twins = context.chart.config.data.datasets
          //   .filter(dataset => (
          //     dataset.data[0].x === value.x && dataset.data[0].y === value.y)
          //   );

          // if (twins.length > 1) {
          //   return context.dataset.label;
          // }
          return label;
        },
        color: function (ctx) {
          // use the same color as the border
          return 'black';
        },
        // borderColor: function (ctx) {
        //   if (ctx.dataIndex === 1) {
        //     // use the same color as the border
        //     return ctx.dataset.borderColor;
        //   }
        // },
        // backgroundColor: function (ctx) {
        //   if (ctx.dataIndex === 1) {
        //     if (ctx.dataset.data[1].x === 2 && ctx.dataset.data[1].y === 2) {
        //     }
        //     // use the same color as the border
        //     return ctx.dataset.borderColor;
        //   }
        // }
      },
    },

    // responsive: true,
    title: {
      display: true,
      text: 'Gráfico Preditivo'
    },
    // tooltips: {
    //   mode: 'label',
    // },
    // hover: {
    //   mode: 'nearest',
    //   intersect: true
    // },
    scales: {
      xAxes: [{
        display: true,
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: 'Semestres'
        },
        ticks: {
          min: 0,
          stepSize: 1,
          max: LAST_POINT
        }
      }],
      yAxes: [{
        display: true,
        type: 'linear',
        scaleLabel: {
          display: true,
          labelString: 'Complexidade'
        },
        ticks: {
          display: true,
          min: 0,
          max: LAST_POINT,
          stepSize: 1,
          padding: 10
        }
      }]
    },
  }
})

export default {
  hierarquiasDataset,
  drawParticipant,
  drawIntersections,
  chartConfig
}