import styled from 'styled-components';

export const GreyComment = styled.textarea`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ccc;

  :focus {
    background-color: white;
    border: 1px solid #000;
  }

  ::placeholder {
    color: #c9c9c9;
  }
`;

export const CommentsContainer = styled.div`
  width: 100%;
  max-height: 500px;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px;
  resize: vertical;
  overflow: auto;
  margin-bottom: 15px;
`;

export const Comment = styled.textarea`
  width: 100%;
  font-size: 12px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const BorderlessButton = styled.button`
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  border: none;
  background-color: transparent;
`;