import Chart from "chart.js";

const DrawTicksOverPlugin = {
  afterDraw: function (chart) {
    try {
    var ctx = chart.ctx;
    const scale = chart.scales.scale;

    if (!scale) return; // chart does not have scale

    scale.options.ticks.fontColor = 'transparent'; // hide original tick
    scale.options.ticks.showLabelBackdrop = false; // hide original tick
      const tickGap = scale.getPixelForTick(1) - scale.getPixelForTick(0);
      let printed = [];

      // loop through ticks array
      Chart.helpers.each(scale.ticks, function (tick, index) {
        const xPos = scale.xCenter;
        var yPos = scale.getPixelForTick(index);
        printed.push(tick);

        ctx.save();
        ctx.textBaseline = 'middle';
        ctx.font = "10px Montserrat";
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.fillText(tick, xPos, scale.yCenter + ((tickGap / 2) * index));
        ctx.restore();
      });
    } catch (err) {
      console.error(err)
    }
  }
}

export default DrawTicksOverPlugin;