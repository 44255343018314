import { GlobalDebug } from "utils/remove-console"
import React, { Component } from "react";
import { ToastContainer } from 'react-toastify';
import { TourContext } from '@reactour/tour';
import styled from 'styled-components';
import CookieConsent from "react-cookie-consent";
import Bowser from "bowser";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import moment from "moment";
import "moment/locale/pt-br";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { TourProvider } from "@reactour/tour";
import { HashRouter, Route, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "./App.scss";
import AuthRoute from "./Services/authRoute";
import apiCalls from "config/apiCalls";
import { Constantes } from "./Services/Constantes";
import MomentUtils from "@date-io/moment";
// Containers
import { DefaultLayout } from "./containers";
import { Login, Page404, Page500, Register } from "./views/Pages";
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import PreviewCases from "./views/Pages/SyncInstruments/PreviewCases";
import PreviewTemplates from "./views/Pages/EmailTemplates/PreviewTemplates";
import ViewResults from "./views/Results/components/ViewResults";
import PrimeiroAcesso from "./views/Participante/PrimeiroAcesso/PrimeiroAcesso";
import InvalidBrowser from "components/InvalidBrowser";
import { Provider } from "unstated";
import CookieConsentModal from "views/Components/CookieConsentModal";

import 'react-tippy/dist/tippy.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-alice-carousel/lib/alice-carousel.css';

if (process.env.NODE_ENV === 'production') {
  GlobalDebug(false)
}

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      "100": "#00264b",
      "200": "#00264b",
      "300": "#00264b",
      "400": "#00264b",
      "500": "#00264b",
      "600": "#00264b",
      "700": "#00264b",
      "800": "#00264b"
    },
    secondary: {
      A100: "#ffc107",
      A200: "#ffc107",
      A300: "#ffc107",
      A400: "#ffc107",
      A500: "#ffc107",
      A600: "#ffc107",
      A700: "#ffc107",
      A800: "#ffc107"
    }
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: "12px"
  }
});

const steps = [];

moment.locale("pt-br");
// import { renderRoutes } from 'react-router-config';

class App extends Component {
  state = {
    blockTourNextBtn: false,
    blockTourPrevBtn: false,
    isValidBrowser: true,
    verifyiedConsultantUser: false,
    verifyiedPolicy: false,
    consentVisible: false,
    cookieModal: false,
  }

  verifyPolicy = async() => {
    let splited = window.location.href.split('#');
    splited = splited[1].split('/');
    if (splited[1].includes('login')) {
      this.setState({ verifyiedPolicy: false });
    } else {
      if (!this.state.verifyiedPolicy) {
        const res = await apiCalls.users.getPolicyData(sessionStorage.getItem('user'));

        const { policy_agreement } = res.data;

        this.setState({ consentVisible: !policy_agreement, verifyiedPolicy: true });
      }
    }

  }

  verifyLocation = async() => {
    let splited = window.location.href.split('#');
      splited = splited[1].split('/');

      if (splited[1].includes('login')) {
        if (this.state.verifyiedConsultantUser) this.setState({ verifyiedConsultantUser: false });
        return;
      }
      else if (splited[1].includes('participante')) {
        if (this.state.verifyiedConsultantUser) this.setState({ verifyiedConsultantUser: false });

        if (!sessionStorage.getItem('isPlayer')) {
          sessionStorage.setItem('isPlayer', true);
          window.location.reload();
        }
      }
      else if (!splited[1].includes('participante')) {
        if (!this.state.verifyiedConsultantUser) {
          const req = await apiCalls.users.verifyIfUserIsConsultant(sessionStorage.getItem('user'));
          const { userIsConsultant } = req.data;
          if (userIsConsultant) this.setState({ verifyiedConsultantUser: true });
          else {
            sessionStorage.clear();
            return window.location.reload();
          }
        }

        if (sessionStorage.getItem('isPlayer')) {
          sessionStorage.setItem('isPlayer', '');
          window.location.reload();
        }
      }
  }

  verifyToken = async () => {
    try {
      const token = sessionStorage.getItem("token");

      if (token) await apiCalls.users.verifyToken();
    } catch (err) {
      console.error(err);
      if (err && err.response && err.response.status === 401) {
        sessionStorage.clear();
        sessionStorage.setItem('sessionExpired', true)
        return window.location.reload();
      }
    }
  }

  componentDidMount = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
      chrome: ">=81.0.4000",
      firefox: ">=69",
      opera: ">=65",
      edge: ">=81.0",
      safari: ">=12"
    });

    const validBrowser = !browser.getBrowser().name.toLowerCase().includes('internet explorer') && isValidBrowser !== false;
    this.setState({ isValidBrowser: validBrowser });

    this.subscribePush();

    this.verifyPolicy();

    const tokenCheckInterval = 60000 * 60; // 1hora
    setInterval(this.verifyToken, tokenCheckInterval);

    window.addEventListener('hashchange', async() => {await this.verifyLocation()});

    window.addEventListener('hashchange', async() => {await this.verifyPolicy()});
  };

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', async() => {await this.verifyLocation()});
    window.removeEventListener('hashchange', async() => {await this.verifyPolicy()});
  }

  subscribePush = () => {
    Constantes.io.on(`Auth:${Constantes.mensagemDeslogarTodos}`, userId => {
      if (sessionStorage.user === userId) {
        if (!sessionStorage.getItem("userLogando")) {
          sessionStorage.clear();
          window.location.reload();
        } else sessionStorage.setItem("userLogando", "");
      }
    });
  };

  toggleCookieConsentModal = () => {
    this.setState({ cookieModal: !this.state.cookieModal });
  }

  onAcceptPolicy = async() => {
    const res = await apiCalls.users.acceptPolicy(sessionStorage.getItem('user'));

    const { success } = res.data;

    if (success) this.setState({ consentVisible: false });
  }

  render() {
    return (
      <>
        <CookieConsentModal isOpen={this.state.cookieModal} toggle={this.toggleCookieConsentModal} />
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName={'CookieConsentIappConsultant'}
          overlay={true}
          onAccept={this.onAcceptPolicy}
          visible={this.state.consentVisible ? 'show' : 'hidden'}
          style={{ background: "#2B373B", justifyContent: 'center', zIndex: 1050 }}
          contentStyle={{ flex: 'none' }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          overlayStyle={{ zIndex: 100001 }}
        >
          Para prosseguir, precisamos que leia e aceite a nossa <a onClick={this.toggleCookieConsentModal} href="javascript:void(0)">Política de Privacidade</a>. Ao clicar em "Ok", você consente que está de acordo.
        </CookieConsent>
        <ToastContainer />
        {!this.state.isValidBrowser ? (
          <InvalidBrowser />
        ) : null}
        <TourProvider
        disableInteraction={true}
        className='remove-btn-focus-outline'
        nextButton={({ Button, ...props }) => {
          return <span onClick={() => {
            if ((props.currentStep >= props.stepsLength - 1) || this.state.blockTourNextBtn) return;
            props.setCurrentStep(props.currentStep + 1);
          }}><FaArrowRight style={{ cursor: 'pointer', opacity: props.currentStep >= props.stepsLength - 1 ? '40%' : '100%' }} size={14} /></span>

        }}
        prevButton={({ Button, ...props }) => {
          return <span onClick={() => {
            if ((props.currentStep <= 0) || this.state.blockTourPrevBtn) return;
            props.setCurrentStep(props.currentStep - 1);
          }}><FaArrowLeft style={{ cursor: 'pointer', opacity: props.currentStep <= 0 ? '40%' : '100%' }} size={14} /></span>
        }}
        position='right'
        setBlockTourNextBtn={(bool) => {this.setState({ blockTourNextBtn: bool })}}
        blockTourNextBtn={this.state.blockTourNextBtn}
        setBlockTourPrevBtn={(bool) => {this.setState({ blockTourPrevBtn: bool })}}
        blockTourPrevBtn={this.state.blockTourPrevBtn}
        beforeClose={() => {
          this.setState({ blockTourNextBtn: false, blockTourPrevBtn: false});

          document.body.style.overflow = 'visible'
        }}
        afterOpen={() => document.body.style.overflow = 'hidden'}
        consentVisible={this.state.consentVisible}
        steps={steps}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
            <ThemeProvider theme={defaultMaterialTheme}>
              <Provider>
                <HashRouter>
                  <Switch>
                    <Route
                      exact
                      path="/preview/cases"
                      name="Preview Cases"
                      component={PreviewCases}
                    />
                    <Route
                      exact
                      path="/preview/templates"
                      name="Preview Templates"
                      component={PreviewTemplates}
                    />
                    <Route
                      exact
                      path="/view-results"
                      name="Visualizar resultados"
                      component={ViewResults}
                    />
                    <Route
                      exact
                      path="/login"
                      name="Login Page"
                      component={Login}
                    />
                    <Route
                      exact
                      path="/resetpassword"
                      name="Forgot Password Page"
                      component={ForgotPassword}
                    />
                    <Route
                      exact
                      path="/primeiro-acesso"
                      name="Primeiro Acesso Page"
                      component={PrimeiroAcesso}
                    />
                    <Route
                      exact
                      path="/register"
                      name="Register Page"
                      component={Register}
                    />
                    <Route exact path="/404" name="Page 404" component={Page404} />
                    <Route exact path="/500" name="Page 500" component={Page500} />
                    <AuthRoute path="/" name="Home" component={DefaultLayout} />
                  </Switch>
                </HashRouter>
              </Provider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </TourProvider>
      </>
      // <Provider>
      //   <HashRouter>
      //     <Switch>
      //       <Route exact path="/login" name="Login Page" component={Login} />
      //       <Route
      //         exact
      //         path="/primeiro-acesso"
      //         name="Primeiro Acesso Page"
      //         component={PrimeiroAcesso}
      //       />
      //       <Route
      //         exact
      //         path="/register"
      //         name="Register Page"
      //         component={Register}
      //       />
      //       <Route exact path="/404" name="Page 404" component={Page404} />
      //       <Route exact path="/500" name="Page 500" component={Page500} />
      //       <AuthRoute path="/" name="Home" component={DefaultLayout} />
      //     </Switch>
      //   </HashRouter>
      // </Provider>
    );
  }
}

export default App;
