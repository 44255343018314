import * as d3 from 'd3';
import './css/Barra.css';

let node = document.createElement('div');

export function getSVGComponent(
  data,
  width,
  height,
  margin,
  xaxisLabels,
  yaxisLabels,
  id = null
) {
  // Configurar domínio e tamanho do eixo X
  let x = d3
    .scaleLinear()
    .domain([0, 101])
    .range([margin.left, width - margin.right - margin.left]);
  // Configurar domínio e tamanho do eixo Y
  let y = d3
    .scaleLinear()
    .domain([0, yaxisLabels[yaxisLabels.length - 1]])
    .range([height - margin.bottom, margin.top]);

  // Criar o elemento do eixo X
  let xAxis = (g) =>
    g.attr('transform', `translate(0,${height - margin.bottom})`).call(
      d3
        .axisBottom(x)
        .ticks(20)
        .tickSizeOuter(0)
    );
  // Criar o elemento do eixo Y
  let yAxis = (g) =>
    g.attr('transform', `translate(${margin.left},0)`).call(
      d3
        .axisLeft(y)
        .tickValues(yaxisLabels)
        .tickFormat(d3.format('0'))
    );

  let svg = d3.select('#graph').attr('viewBox', [0, 0, width, height]);

  if (id) svg = d3.select(`#graph-${id}`).attr('viewBox', [0, 0, width, height])


  svg.selectAll('svg > *').remove();
  svg
    .append('g')
    .attr(
      'fill',
      data.length
        ? data[2].data.length
          ? 'rgba(0, 150, 255, 0.2)'
          : 'rgba(0, 150, 255, 1)'
        : 'rgba(0, 150, 255, 1)'
    )
    .selectAll('rect')
    .data(data.length ? data[0].data : [])
    .join('rect')
    .attr('x', (d) => x(d.name))
    .attr('y', (d) => y(d.value))
    .attr('height', (d) => y(0) - y(d.value))
    .attr('width', (width - margin.right - margin.left) / 120)
    .append('svg:title')
    .text(function(d) {
      return `
          Pessoas: ${d.value}\nNota: ${d.name}
        `;
    });
  svg
    .append('g')
    .attr('fill', '#ff0000')
    .selectAll('rect')
    .data(data.length ? data[2].data : [])
    .join('rect')
    .attr('x', (d) => x(d.name))
    .attr('y', (d) => y(d.value))
    .attr('height', (d) => y(0) - y(d.value))
    .attr('width', (width - margin.right - margin.left) / 800)
    .append('svg:title')
    .text(function(d) {
      return `
            Pessoas: ${d.value}\nNota: ${d.name}
          `;
    });
  svg
    .append('path')
    .datum(data.length ? data[1].data : [])
    .attr('fill', 'none')
    .attr('stroke', '#4e4e4e')
    .attr('stroke-width', 1.5)
    .attr(
      'd',
      d3
        .line()
        .x(function(d) {
          return x(d.name);
        })
        .y(function(d) {
          return y(d.value);
        })
    );

  svg.append('g').call(xAxis);
  svg
    .append('text')
    .attr('transform', 'translate(' + width / 2 + ' ,' + (height + 30) + ')')
    .attr('x', 0)
    .attr('y', 0)
    .style('text-anchor', 'middle')

  svg.append('g').call(yAxis);
  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0)
    .attr('x', 0 - height / 2)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text('Pessoas');
}

export default node;
