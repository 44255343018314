import React from 'react';
import {
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import './scss/styles.scss';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

export default function TableRow(props) {
  const {
    index,
    questao,
    correta,
    nivel,
    resposta,
    tempoIniciado,
    tempoQuestaoDuracao,
  } = props;

  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  function formatarTempo(tempo) {
    var ms = tempo % 1000;
    tempo = (tempo - ms) / 1000;
    var secs = tempo % 60;
    tempo = (tempo - secs) / 60;
    var mins = tempo % 60;
    var hrs = (tempo - mins) / 60;

    return pad(mins) + ":" + pad(secs);
  }

  return (
    <>
      {/* Popover para Questão */}
      <UncontrolledPopover
        placement="right"
        target={"questao" + index}
        trigger="hover"
      >
        <PopoverHeader>Questão</PopoverHeader>
        <PopoverBody
          style={{
            minWidth: "5vw"
          }}
        >
          {questao.split("</p>").map((text, index) => {
            return (
              <p
                key={index}
                style={{marginBottom: '1em'}}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text + "</p>")
                }}
              ></p>
            );
          })}
        </PopoverBody>
      </UncontrolledPopover>

      {/* Popover para Resposta */}
      <UncontrolledPopover
        placement="right"
        target={"resposta" + index}
        trigger="hover"
      >
        <PopoverHeader>Resposta</PopoverHeader>
        <PopoverBody
          style={{
            minWidth: "5vw"
          }}
        >
          {resposta && resposta.split("</p>").map((text, index) => {
            return (
              <p
                key={index}
                style={{marginBottom: '1em'}}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text + "</p>")
                }}
              ></p>
            );
          })}
        </PopoverBody>
      </UncontrolledPopover>

      <tr>
        <td>
          <div className="td-index-questao">
            {
              correta
              ?
              <div className="acerto"></div>
              :
              <div className="erro"></div>
            }
            {index + 1}
          </div>
        </td>
        <td style={{ minWidth: "150px", maxWidth: "200px" }}>
          <span className="td-limited" id={`questao${index}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(questao + "</p>")}}></span>
        </td>
        <td>
          {nivel}
        </td>
        <td style={{ minWidth: "150px", maxWidth: "200px" }}>
          <span className="td-limited" id={`resposta${index}`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(resposta ? resposta : '' + "</p>")}}></span>
        </td>
        <td>
          {tempoIniciado ? new Date(Number(tempoIniciado)).toTimeString().substring(0, 8) :  '-'}
        </td>
        <td>
          {tempoQuestaoDuracao ? new Date(Number(tempoIniciado) + Number(tempoQuestaoDuracao)).toTimeString().substring(0, 8) : '-'}
        </td>
        <td>
          {tempoQuestaoDuracao ? formatarTempo(Number(tempoQuestaoDuracao)) : '-'}
        </td>
        <td>
          <strong style={{cursor : 'pointer'}} onClick={() => props.adicionarTempo(tempoQuestaoDuracao ? false : true)}>+30"</strong>
        </td>
        <td>
          <div style={{cursor : 'pointer', color : '#d44444' }} onClick={() => props.excluirQuestao(index, tempoQuestaoDuracao ? false : true)}>Excluir</div>
        </td>
      </tr>
    </>
  )
}
