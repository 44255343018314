
// intervalo de ping
export let pingServerInterval
export const pingOnline = 'ONLINE'

// Instrumentos
export const instrumentoCaseIndividual = "case";
export const instrumentoCaseIndividual2 = "case2";
export const instrumentoCaseGrupo = "caseGrupo";
export const instrumentoBinario = "binario";
export const caseIndividualQuantidade = 3;
export const caseGrupoQuantidade = 5;
export const instrumentoStoryTellingIndividual = "storytelling";
export const instrumentoStoryTellingIndividual2 = "storytelling2";
export const instrumentoStoryTellingGrupo = "storytellingGrupo";
export const instrumentoSociometria = "sociometria";
export const instrumentoEstilos = "Estilos de liderança";
export const estilosTotalQuestoes = 6;
export const instrumentoProvas = "provas";

export const atividadesIndividual = [
  instrumentoCaseIndividual,
  instrumentoStoryTellingIndividual
];

export const atividadesIndividual2 = [
  instrumentoCaseIndividual2,
  instrumentoStoryTellingIndividual2
];

export const atividadesGrupo = [
  instrumentoCaseGrupo,
  instrumentoStoryTellingGrupo
];

export const criteriosTamanho = 100;

// Mensagens
export const mensagemAguardandoSecretario = "Aguardando Secretário...";
export const mensagemSelecionar = "Selecionar";
export const mensagemDeslogarTodos = "deslogarTodos";
export const mensagemCPFinvalido = "Insira um CPF válido";

// Imagens
export const imagemSpinner = "spinner.gif";

// Provas
export const provaPortugues = "Raciocinio analitico";
export const provaIngles = "Ingles";
export const provaDemo = "Demonstracao";
export const provaInglesQuestoes = 20;
export const provaNivelSuperior = 5;
export const provaNivelInferior = 1;
export const provaMudancaNivel = 3;
export const provaPortuguesQuestoes = 20;
export const finishedProofStatusCode = 0;
export const startedProofStatusCode = 1;
export const notStartedProofStatusCode = -2;

// Tempos
export const tempoProvaQuestao = 3; // minutos
export const tempoProvaProva = 50; // minutos

// Estatistica
export const confiabilidade = 1.96; //Confiabilidade
export const p = 0.5; // proporção
export const e = 0.05; // erro

// Grupos
export const quantidadeCriteriosGrupo = 5;

// Modulos
export const moduloDinamicas = "dinamicas";
export const moduloAssincronos = "assincronos";
export const moduloAssessments = "assessments";
export const moduloProvas = "provas";

// Textos
export const textosProvaDefault =
  "<p>Car@ participante, a seguir algumas instru&ccedil;&otilde;es que devem ser consideradas imediatamente.</p><p>&nbsp;</p><p><strong>INSTRU&Ccedil;&Otilde;ES</strong></p><ul><li>O supervisor de prova indicar&aacute; o momento apropriado para d&uacute;vidas</li><li>Est&aacute; proibido comentar, perguntar ou qualquer consulta aos demais participantes, supervisores ou material de qualquer natureza;</li><li>Desligue seu celular ou coloque-o em modo avi&atilde;o, desativando avisos sonoros e/ou alarmes;</li><li>Nenhuma pergunta ser&aacute; respondida. O entendimento das quest&otilde;es faz parte da prova.</li><li>Qualquer problema t&eacute;cnico dever&aacute; ser imediatamente notificado erguendo a m&atilde;o e solicitando a presen&ccedil;a do supervisor ou suporte t&eacute;cnico;</li><li>Voc&ecirc; ter&aacute; um tempo <strong>TOTAL de 50 minutos para completar a Prova</strong>, composta por <strong>20 quest&otilde;es</strong> de M&uacute;ltipla alternativas(A a E)</li><li>Cada quest&atilde;o lhe oferece at&eacute; <strong>3 minutos</strong> para responder. Observe que se tomar todo o tempo por quest&atilde;o <strong>N&Atilde;O TER&Aacute; TEMPO</strong> para finalizar as 20 quest&otilde;es previstas para a prova.</li><li>Voc&ecirc; <strong>DEVE</strong> escolher uma alternativa para avan&ccedil;ar para a pr&oacute;xima quest&atilde;o. N&atilde;o ser&atilde;o aceitas respostas em branco, salvo quando o tempo acaba.</li><li>Voc&ecirc; <strong>DEVE</strong> clicar no bot&atilde;o Enviar para registrar sua resposta.</li></ul>";
export const textosDinamicaDefault = `<p><span style="font-weight: 400;">Ol&aacute;! Voc&ecirc; est&aacute; iniciando a etapa de Din&acirc;mica. Seja bem-vindo!</span></p>
<p><span style="font-weight: 400;">Antes de iniciar garanta que seu equipamento atende aos requisitos abaixo:</span></p>
<p><span style="font-weight: 400;">REQUISITOS:&nbsp;</span></p>
<p><span style="font-weight: 400;">&nbsp;- Conex&atilde;o de internet de banda larga</span></p>
<p><span style="font-weight: 400;">&nbsp;- Utilizar o navegador Google Chrome - vers&atilde;o 83.0.4103.61 (Vers&atilde;o oficial) 64 bits&nbsp;</span></p>
<p><span style="font-weight: 400;">-&nbsp; Garantir que n&atilde;o tenha nenhum Software VPN instalado no sistema operacional</span></p>
<p><span style="font-weight: 400;">- Desabilite qualquer tradutor autom&aacute;tico do navegador ou sistema operacional</span></p>
<p><span style="font-weight: 400;">DIN&Acirc;MICA:</span></p>
<p><span style="font-weight: 400;">Observe que algumas das an&aacute;lises refletem desafios que vivenciamos no dia a dia da organiza&ccedil;&atilde;o: falta de informa&ccedil;&otilde;es e/ou informa&ccedil;&otilde;es insuficientes, compreens&atilde;o limitada da proposta, diferentes perspectivas em busca de solu&ccedil;&atilde;o, conflitos de posi&ccedil;&otilde;es e de interesses. E assim como em seu cotidiano, tais aspectos devem ser tratados e esclarecidos pelo pr&oacute;prio grupo. Ou seja, os Consultores e Facilitadores do Time de Apoio n&atilde;o interferir&aacute; nas discuss&otilde;es, d&uacute;vidas e delibera&ccedil;&otilde;es do grupo, </span><strong>exceto em quest&otilde;es t&eacute;cnicas. </strong><span style="font-weight: 400;">Nessa situa&ccedil;&atilde;o, estaremos prontos para atend&ecirc;-los na sala comum.</span></p>
<p><span style="font-weight: 400;">Todas as atividades e respectivas entregas ser&atilde;o realizadas atrav&eacute;s do seu equipamento.</span></p>
<p><span style="font-weight: 400;">ATIVIDADES</span></p>
<p><span style="font-weight: 400;">Instru&ccedil;&atilde;o - Ao iniciar cada atividade, ser&aacute; apresentado a instru&ccedil;&atilde;o em um pop-up, o qual ap&oacute;s ler e fechar, voc&ecirc; poder&aacute; visualizar novamente clicando no bot&atilde;o &ldquo;instru&ccedil;&atilde;o&rdquo; no canto superior direito</span></p>
<p><span style="font-weight: 400;">Tempo - Cada atividade apresentar&aacute; uma barra de tempo que est&aacute; localizada na parte superior da tela</span></p>
<p><span style="font-weight: 400;">RECOMENDA&Ccedil;&Otilde;ES E DICAS:</span></p>
<p><span style="font-weight: 400;">Caso n&atilde;o tenha foto no seu perfil, clique no &iacute;cone do seu avatar (imagem circular no in&iacute;cio dessa tela) e coloque a sua foto. Pode ser carregando uma foto guardada no seu computador ou tirando uma foto pela c&acirc;mera do seu equipamento.&nbsp;</span></p>
<p><span style="font-weight: 400;">IMPORTANTE: USE IMAGENS QUE CONTENHA APENAS VOC&Ecirc; NA FOTO</span></p>
<p><span style="font-weight: 400;">Em atividade em grupo, quest&otilde;es ou coment&aacute;rios feitos com colegas de grupos de outras mesas n&atilde;o ser&atilde;o permitidos;</span></p>
<p><span style="font-weight: 400;">No decorrer da din&acirc;mica desabilite chamadas de celular e outras atividades em paralelo que tire a sua aten&ccedil;&atilde;o ou de outros membros</span></p>
<p>&nbsp;</p>`;

export class Constantes {
  static get estilosTotalQuestoes() {
    return estilosTotalQuestoes;
  }
  static get instrumentoCaseIndividual() {
    return instrumentoCaseIndividual;
  }
  static get instrumentoCaseIndividual2() {
    return instrumentoCaseIndividual2;
  }
  static get instrumentoCaseGrupo() {
    return instrumentoCaseGrupo;
  }
  static get instrumentoBinario() {
    return instrumentoBinario;
  }
  static get caseIndividualQuantidade() {
    return caseIndividualQuantidade;
  }
  static get caseGrupoQuantidade() {
    return caseGrupoQuantidade;
  }
  static get instrumentoStoryTellingIndividual() {
    return instrumentoStoryTellingIndividual;
  }
  static get instrumentoStoryTellingIndividual2() {
    return instrumentoStoryTellingIndividual2;
  }
  static get instrumentoStoryTellingGrupo() {
    return instrumentoStoryTellingGrupo;
  }
  static get instrumentoSociometria() {
    return instrumentoSociometria;
  }
  static get instrumentoEstilos() {
    return instrumentoEstilos;
  }
  static get instrumentoProvas() {
    return instrumentoProvas;
  }
  static get atividadesIndividual() {
    return atividadesIndividual;
  }
  static get atividadesIndividual2() {
    return atividadesIndividual2;
  }
  static get atividadesGrupo() {
    return atividadesGrupo;
  }
  static get criteriosTamanho() {
    return criteriosTamanho;
  }
  static get mensagemAguardandoSecretario() {
    return mensagemAguardandoSecretario;
  }
  static get mensagemSelecionar() {
    return mensagemSelecionar;
  }
  static get mensagemDeslogarTodos() {
    return mensagemDeslogarTodos;
  }
  static get mensagemCPFinvalido() {
    return mensagemCPFinvalido;
  }
  static get imagemSpinner() {
    return imagemSpinner;
  }
  static get provaPortugues() {
    return provaPortugues;
  }
  static get provaIngles() {
    return provaIngles;
  }
  static get provaDemo() {
    return provaDemo;
  }
  static get provaPortuguesQuestoes() {
    return provaPortuguesQuestoes;
  }
  static get provaInglesQuestoes() {
    return provaInglesQuestoes;
  }
  static get provaNivelSuperior() {
    return provaNivelSuperior;
  }
  static get provaNivelInferior() {
    return provaNivelInferior;
  }
  static get provaMudancaNivel() {
    return provaMudancaNivel;
  }
  static get tempoProvaQuestao() {
    return tempoProvaQuestao;
  }
  static get tempoProvaProva() {
    return tempoProvaProva;
  }
  static get confiabilidade() {
    return confiabilidade;
  }
  static get p() {
    return p;
  }
  static get e() {
    return e;
  }
  static get quantidadeCriteriosGrupo() {
    return quantidadeCriteriosGrupo;
  }
  static get moduloDinamicas() {
    return moduloDinamicas;
  }
  static get moduloAssincronos() {
    return moduloAssincronos;
  }
  static get moduloProvas() {
    return moduloProvas;
  }
  static get moduloAssessments() {
    return moduloAssessments;
  }
  static get textosProvaDefault() {
    return textosProvaDefault;
  }
  static get textosDinamicaDefault() {
    return textosDinamicaDefault;
  }
}
