import React from "react"
import ReactCrop from 'components/ReactCrop';
import { Modal, ModalBody, Container, Col, Row, ModalHeader, ModalFooter } from "reactstrap"
import { Tooltip } from 'react-tippy';
import { FaQuestionCircle, FaTrashAlt } from 'react-icons/fa';
import { Campo, ButtonSave, ButtonCancel } from "../Assets/Assets"
import FileBase64 from "react-file-base64"
import { validarCNPJ } from "../../../Services/Validador"
import { notifyError } from "../../../Services/Notificacoes";

import './styles.css';

function getInitialState() {
  return {
    cnpj: "",
    razaoSocial: "",
    setorAtuacao: "",
    nomeFantasia: "",
    telefone: "",
    endereco: "",
    cidade: "",
    uf: "",
    logo: "",
    email: "",
    edicao: false,
    loading: false,
    logoChanged: false,
    cropModal: false,
    cropFileName: '',
    cropImg: null,
    crop: {
      unit: '%',
      width: 50,
      x: 25,
      y: 25,
      aspect: 1/1
    },
    logoRemoved: false,
    deleteLogoModal: false,
    logoUrl: ''
  };
}

class ClienteCadastro extends React.Component {

  state = getInitialState();

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  salvar = async e => {
    e.preventDefault()
    let form = {...this.state};
    if (!this.state.logoChanged) form.logo = '';

    if (form.telefone) {
      form.telefone = form.telefone
        .replace("+", "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
      while (form.telefone.includes("_")) form.telefone = form.telefone.replace("_", "")
    }

    if (form.cnpj) {
      while (form.cnpj.includes(".")) form.cnpj = form.cnpj.replace(".", "");
      while (form.cnpj.includes("/")) form.cnpj = form.cnpj.replace("/", "");
      while (form.cnpj.includes("-")) form.cnpj = form.cnpj.replace("-", "");
    }

    e.preventDefault()
    // Check
    if (!this.state.razaoSocial) {
      notifyError("Insira a razão social")
    } else if (!this.state.setorAtuacao) {
      notifyError("Insira o setor de atuação")
    } else if (!this.state.cnpj) {
      notifyError("Insira o CNPJ")
    } else if (!validarCNPJ(this.state.cnpj)) {
      notifyError("Insira um CNPJ válido")
    } else if (!this.state.nomeFantasia) {
      notifyError("Insira o nome fantasia")
    } else if (form.telefone && form.telefone.length < 12) {
      notifyError("Telefone no formato incorreto!")
    } else {
      if (form.cnpj && form.telefone) {
        form.cnpj = form.cnpj.replace(/[^\d]+/g, "")
        form.telefone = form.telefone.replace(/[^\d]+/g, "")
      }

      this.setState({ loading: true });

      await this.props.handleForm(form);
      const initialState = getInitialState();
      this.setState({
        ...initialState
      }) // limpa os dados do formulário para o estado inicial
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if ((prevProps.edicao && prevProps.edicao.logo) !== (this.props.edicao && this.props.edicao.logo)) {
      console.log('logo', this.props.edicao.logo)
      this.setState({ logo: this.props.edicao.logo });
    }
  }

  handleFileChange = img => {
    const file = img;

    const urlCreator = window.URL || window.webkitURL;

    const url = urlCreator.createObjectURL(file);

    this.setState({ logoUrl: url, logo: file, logoChanged: true, cropModal: false, logoRemoved: false });
  }

  handleModalOpen = () => {
    let { edicao } = this.props

    if (edicao && Object.keys(edicao).length) {

      this.setState({
        ...edicao,
        editando: true
      })
    } else {
      this.setState({
        editando: false
      })
    }
  }

  handleModalClose = () => {
    let { editando } = this.state

    // if (editando) {
    const initialState = getInitialState();
    this.setState({
      ...initialState
    })
    // }
  }

  handleImgCrop = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          this.setState({ cropImg: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  openCropModal = (e) => {
    if (!e.target.files || !e.target.files.length) return;

    const file = e.target.files[0];

    if (
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg"
    ) {
      const fileName = e.target.files[0].name;

      this.handleImgCrop(e);
      this.setState({
        cropFileName: fileName,
        cropModal: true
      });
    } else {
      notifyError("O formato da logo deve ser .png, .jpg ou .jpeg. Tente novamente.");
    }
  }

  toggleDeleteLogoModal = () => {
    this.setState({ deleteLogoModal: !this.state.deleteLogoModal });
  }

  onRemoveConsultantLogo = () => {
    this.setState({ logo: null, logoUrl: '', cropModal: false, logoRemoved: true });
    this.toggleDeleteLogoModal();
  }

  render() {
    return (
      <div>
        <Modal
          toggle={this.toggleDeleteLogoModal}
          isOpen={this.state.deleteLogoModal}
        >
          <ModalHeader toggle={this.toggleDeleteLogoModal}>
            Remover imagem
          </ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            Deseja remover essa imagem ?
          </ModalBody>
          <ModalFooter>
            <ButtonCancel onClick={this.toggleDeleteLogoModal}>Cancelar</ButtonCancel>
            <ButtonSave  onClick={this.onRemoveConsultantLogo}>Remover</ButtonSave>
          </ModalFooter>
        </Modal>
        <ReactCrop
          fileName={this.state.cropFileName}
          minWidth={60}
          circularCrop={true}
          showCropModal={this.state.cropModal}
          src={this.state.cropImg}
          crop={this.state.crop}
          onCancelCrop={() =>
          this.setState({ cropModal: false })}
          onSubmitCrop={this.handleFileChange}
        />
        <Modal

          size="lg"
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          className={this.props.className}
          onOpened={this.handleModalOpen}
          onClosed={this.handleModalClose}>
          <ModalHeader
            toggle={this.props.toggle}
          >
            {
              this.props.modalTitle
            }
          </ModalHeader>
          <form onSubmit={this.salvar}>
            <ModalBody>
              {/* <p>
                <b>Logo</b>
              </p>
              <input
                type="file"
                style={{ marginBottom: "24px" }}
                onChange={this.openCropModal}
                accept={".png, .jpeg, .jpg"}
              /> */}
              <Row>
              <Col className='imgs label' sm={2} style={{ padding: 0 }}>
                <label
                  title="Logo Cliente"
                  style={{ backgroundImage: `url(${this.state.logoRemoved ?  null : this.state.logoUrl || `https://files-storage.s3.amazonaws.com/${this.state.logo}`})`, marginRight: 20  }}
                  className="avatar"
                >
                  <input accept={".png, .jpeg, .jpg"} onChange={(e) => this.openCropModal(e)} type="file" />
                  {this.state.logoRemoved ? "+" : this.state.logoUrl || this.state.logo ? null : "+"}
                </label>
                <span className='label1'>Logo</span>
                {(this.state.logoUrl || this.state.logo) && !this.state.logoRemoved ? <FaTrashAlt size={12} onClick={this.toggleDeleteLogoModal} style={{ marginBottom: 4, cursor: 'pointer' }} /> : null}
              </Col>
              <Col style={{ height: 'fit-content', display: 'flex', flexDirection: 'row-reverse' }}>
                <Tooltip
                  title={"Clique no circulo e inclua uma imagem para o cliente. A imagem deve ser no formato JPG ou PNG com no máximo 2Mb."}
                  position="top"
                  size="small"
                  trigger="mouseenter"
                  arrow="true"
                  disabled={false}
                >
                  <FaQuestionCircle size={12} />
                </Tooltip>
              </Col>
              </Row>
              <br />
              <br />
              <div className="row">
                <div className="col-md-6">
                  <Campo
                    tipo="text"
                    className="form-control"
                    name="razaoSocial"
                    onChange={this.handleInputChange}
                    value={this.state.razaoSocial}
                    label="Razão Social*"
                    placeholder="Razão Social"
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Campo
                    tipo="text"
                    className="form-control"
                    name="cnpj"
                    mask={"99.999.999/9999-99"}
                    onChange={this.handleInputChange}
                    value={this.state.cnpj}
                    label="Cnpj*"
                    placeholder="Cnpj"
                    required={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Campo
                    tipo="text"
                    className="form-control"
                    name="setorAtuacao"
                    onChange={this.handleInputChange}
                    value={this.state.setorAtuacao}
                    label="Setor de Atuação*"
                    placeholder="Setor de Atuação"
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Campo
                    tipo="text"
                    className="form-control"
                    name="nomeFantasia"
                    onChange={this.handleInputChange}
                    value={this.state.nomeFantasia}
                    label="Nome Fantasia*"
                    placeholder="Nome Fantasia"
                    required={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Campo
                    tipo="text"
                    className="form-control"
                    name="endereco"
                    onChange={this.handleInputChange}
                    value={this.state.endereco}
                    label="Endereço"
                    placeholder="Endereço"
                    required={false}
                    mask={""}
                  />
                </div>


                <div className="col-md-3">
                  <Campo
                    tipo="text"
                    name="cidade"
                    className="inputText"
                    onChange={this.handleInputChange}
                    value={this.state.cidade}
                    label="Cidade"
                    placeholder="Cidade"
                    required={false}
                  />
                </div>
                <div className="col-md-3">
                  <Campo
                    tipo="text"
                    name="uf"
                    className="form-control"
                    onChange={this.handleInputChange}
                    value={this.state.uf}
                    label="UF"
                    placeholder="UF"
                    required={false}
                  />
                </div>


              </div>
              <div className="row">
                <div className="col-md-6">
                  <Campo
                    tipo="telefone"
                    className="form-control"
                    mask={"+99(99)99999-9999"}
                    name="telefone"
                    onChange={this.handleInputChange}
                    value={this.state.telefone}
                    label="Telefone"
                    placeholder="Telefone"
                    required={false}
                  />
                </div>

                <div className="col-md-6">
                  <Campo
                    tipo="email"
                    className="form-control"
                    name="email"
                    mask={""}
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    label="Email"
                    placeholder="Email"
                    required={false}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonSave disabled={this.state.loading} type="submit">Enviar</ButtonSave>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    )
  }
}

export default ClienteCadastro
