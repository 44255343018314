import React from "react";
import { Modal, Col, Row, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel, ButtonSave } from 'views/Pages/Assets/Assets';

function ModalTrocaAmbiente(props) {
  return(
    <Modal
        toggle={props.toggle}
        isOpen={props.isOpen}
        onClosed={props.handleClose}
      >
        <ModalHeader
          toggle={props.toggle}
        >
          Trocar ambiente
        </ModalHeader>
        <ModalBody style={{ fontSize: 12 }}>
          Deseja trocar para o ambiente de {props.roleToSwitch} ?
        </ModalBody>
        <ModalFooter>
          <ButtonCancel onClick={props.handleClose}>Cancelar</ButtonCancel>
          <ButtonSave onClick={props.handleConfirm}>Trocar</ButtonSave>
        </ModalFooter>
      </Modal>
  );
}

export default ModalTrocaAmbiente;