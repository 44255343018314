import React from 'react';
import {
  Row,
  Col,
} from "reactstrap";
import apiCalls from "config/apiCalls";
import _ from 'lodash';

import * as ResultadoProvasService from './services';
import Grafico from 'views/Pages/Resultados/ModalResultados/ResultadosDinamica/Case/Grafico'
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import DefaultTitle from 'components/default-title';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';

const FeedbackText = ({ text, rp, acertos }) => (
  <>
    <Row>
      <Col>
        <p>
          <DefaultTitle>Resultado</DefaultTitle>
        </p>
      </Col>
    </Row>
    <Row>
      <Col style={{
        marginTop: '20px',
        fontSize: '12px',
        marginBottom: 15
      }}>
        { _.isNumber(rp) && _.isNumber(acertos) ?
        <>
          <span>Acertos: {acertos}</span> <br />
          <span>Resultado ponderado: {rp}</span><br/><br/>
        </> : null }
        <p dangerouslySetInnerHTML={{
          __html: text
        }}>
        </p>
      </Col>
    </Row>
  </>
)


class UnifiedProof extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackText: '',
      normalizedTotal: null,
      loading: false,
      whatsBeingMeasuredText: '',
    }
  }

  handleError(err, msg) {
    if (!msg) return console.error({err});
    console.error(err);
    notifyError(msg);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    ResultadoProvasService.getUnifiedProofPhrases({
      idResult: this.props.idResult,
      idUser: this.props.idParticipante,
      idProvaUnificada: this.props.idProva
    })
      .then((res) => {
        this.setState({
          feedbackText: res.participant_modal_text,
          normalizedTotal: res.normalized_total,
          whatsBeingMeasuredText: res.whats_being_measured_text
        });
      })
      .catch(err => this.handleError(err))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { feedbackText, normalizedTotal, loading } = this.state;

    if (loading) return  <Loading />;

    return (
      <div>
        {
          feedbackText && (normalizedTotal !== undefined) && feedbackText.length ? (
            <>
              <Grafico series={[normalizedTotal]}
                id={`proof-general-chart-${this.props.idProva}`} />
              <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} />
              <br />
              <FeedbackText acertos={this.props.acertos} rp={this.props.rp} text={this.state.feedbackText} />
            </>
          )
            :
            <NoResultsFound />
        }
      </div>
    )
  }
}

export default UnifiedProof;