import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { ButtonCancel, ButtonSave } from '../../../Pages/Assets/Assets';
import Input from './Input';

function Msg1(props) {
  return(
    <p style={{ fontSize: 12 }}>O CPF {props.cpf} está cadastrado como <strong>consultor</strong> no Iapp. Informe sua senha e clique no botão <strong>Acessar</strong> para fazer login.</p>
  )
}

function Msg2(props) {
  return(
    <p style={{ fontSize: 12 }}>O CPF {props.cpf} está cadastrado como <strong>participante</strong> no Iapp. Informe sua senha, clique no botão <strong>Acessar</strong> para fazer login ou no botão <strong>Cadastrar como consultor</strong> para se cadastrar como um consultor.</p>
  )
}

function RegisteredCpfModal(props) {
  const [senha, setSenha] = useState('');

  return(
    <Modal isOpen={props.isOpen}
    onOpened={()=>{ setSenha('') }}
    size={props.formacao && props.formacao.includes('consultor') ? 'md' : 'lg'}
    toggle={() => {
      if (props.formacao && props.formacao.includes('consultor')) {
        props.setCpf('');
      }
      props.toggle()
    }}
    >
      <ModalHeader toggle={() => {
        if (props.formacao && props.formacao.includes('consultor')) {
          props.setCpf('');
        }
        props.toggle()
        }}>Aviso</ModalHeader>
      <ModalBody>
        <div style={{ fontSize: 12 }}>
          { props.formacao && props.formacao.includes('consultor') ? <Msg1 {...props}/> : <Msg2 {...props} /> }
          <Input
            whiteSpace={'nowrap'}
            type='password'
            style={{ marginTop: 5, width: 400 }}
            name='senha'
            valid={true}
            label={'Senha:'}
            onChange={(e)=>{setSenha(e.target.value)}}
            value={senha}
          /> <br />
          <span style={{ marginTop: 5, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
            props.forgotPasswordModal();
            props.toggle();
          }}>Esqueceu a senha? Clique aqui para recuperar.</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={() => {
          if (props.formacao && props.formacao.includes('consultor')) {
            props.setCpf('');
          }
          props.toggle()
          }}>Cancelar</ButtonCancel>
          { props.formacao && !props.formacao.includes('consultor') ? <ButtonSave onClick={() => {props.handleRegisterAsConsultant(senha)}}>Cadastrar como consultor</ButtonSave> : null }
        <ButtonSave onClick={() => { props.onConfirm(props.cpf, senha) }}>Acessar</ButtonSave>
      </ModalFooter>
    </Modal>
  );
}

export default RegisteredCpfModal