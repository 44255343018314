import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AnswererPendingActivityReminderTemplate from './components/AnswererPendingActivityReminderTemplate';
import AssessmentInviteTemplate from './components/AssessmentInviteTemplate';
import AssessmentReminderTemplate from './components/AssessmentReminderTemplate';
import AsyncAnswererInviteTemplate from './components/AsyncAnswererInviteTemplate';
import AsyncParticipantInviteTemplate from './components/AsyncParticipantInviteTemplate';
import GeralResultsTemplate from './components/GeralResultsTemplate';
import ParticipantPendingActivityReminderTemplate from './components/ParticipantPendingActivityReminderTemplate';
import ParticipantInviteTemplate from './components/ParticipantInviteTemplate';
import ParticipantInviteTemplateMarked from './components/ParticipantInviteTemplateMarked';
import TestsParticipantInviteTemplate from './components/TestsParticipantInviteTemplate';

import './styles.css';

const StyledDiv = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  max-width: 600px;
  height: 100%;
  font-size: 14px !important;
  line-height: 21px;
  padding: 10px
`

function PreviewTemplates(props) {
  const [template, setTemplate] = useState(null);

  const templatesArray = [
    {
      id: '1',
      template: <AnswererPendingActivityReminderTemplate />
    },
    {
      id: '2',
      template: <AssessmentInviteTemplate />
    },
    {
      id: '3',
      template: <AssessmentReminderTemplate />
    },
    {
      id: '4',
      template: <AsyncAnswererInviteTemplate />
    },
    {
      id: '5',
      template: <AsyncParticipantInviteTemplate />
    },
    {
      id: '6',
      template: <GeralResultsTemplate />
    },
    {
      id: '7',
      template: <ParticipantPendingActivityReminderTemplate />
    },
    {
      id: '8',
      template: <ParticipantInviteTemplate />
    },
    {
      id: '9',
      template: <ParticipantInviteTemplateMarked />
    },
    {
      id: '10',
      template: <TestsParticipantInviteTemplate />
    }
  ]

  useEffect(() => {
    const selectedTemplate = new URLSearchParams(props.location.search).get("template");
    if (selectedTemplate) {
      const templateFounded = templatesArray.find(t => t.id === selectedTemplate);
      console.log(templateFounded)
      if (templateFounded) setTemplate(templateFounded.template);
    }
  }, [])

  return(
    <div className='preview-templates' style={{ width: '100%', minHeight: '100vh', height: '100%', background: '#fff' }}>
      <StyledDiv>
        { template }
      </StyledDiv>
    </div>
  );
}

export default PreviewTemplates;