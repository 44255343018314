import api, { assincronosApi } from "../../../Services/api.js";
import { notifyError, notifySuccess } from "../../../Services/Notificacoes.js";
import { Constantes } from "../../../Services/Constantes.js";

function updateParticipantesProva(ids, provaID) {
  return api
    .put(`api/prova/participantes/${provaID}`, {
      ids: ids
    })
    .then(res => res.status);
}

export async function vincularParticipantesProva(ids, provaID) {
  return api
    .put(`api/prova/vincularparticipantes/${provaID}`, {
      ids: ids
    })
    .then(res => res.status);
}

export async function linkAllProof(provaID) {
  return api
    .put(`api/prova/link-all/${provaID}`)
    .then(res => res.status);
}

export async function unlinkAllProof(provaID, verificarAntes) {
  return api
    .put(`api/prova/unlink-all/${provaID}`, {
      verificarAntes
    });
}

export async function desvincularParticipantesProva(
  ids,
  provaID,
  verificarAntes = false
) {
  return api
    .put(`api/prova/desvincularparticipantes/${provaID}`, {
      ids: ids,
      verificarAntes
    });
}

export async function vincularParticipantesAssessment(ids, assessId) {
  return api
    .put(`api/assessments/${assessId}/vincular-participantes`, {
      participantes: ids
    })
    .then(res => res.status);
}

export async function desvincularParticipantesAssessment(
  ids,
  assessId,
  verificarAntes = false
) {
  return api
    .put(`api/assessments/${assessId}/desvincular-participantes`, {
      participantes: ids,
      verificarAntes
    });
}

export async function linkAllAssessmentParticipants(projectId, assessId) {
  return api
    .put(`api/assessments/${assessId}/link-all`, {}, { params: {
      projectId
    } })
    .then(res => res.status);
}

export async function unlinkAllAssessmentParticipants(
  projectId,
  assessId,
  verificarAntes
) {
  return api
    .put(`api/assessments/${assessId}/unlink-all`,
      {
        verificarAntes
      },
      {
        params: {
          projectId
        }
      }
    );
}

export async function getParticipantesAssessmentAcompanhamento(
  acompanhamentoId,
  pagina = null
) {
  return api
    .get(`api/sessoes?idAssessment=${acompanhamentoId}&pagina=${pagina}`)
    .then(res => res.data);
}

export function marcarAusenteSessoesAcompanhamento(sessao) {
  api
    .put(`api/sessoes/marcar-como-ausente`, {
      sessoes: sessao
    })
    .then(putRes => {
      notifySuccess(putRes.data.msg);
      setTimeout(function() {
        window.location.reload(false);
      }, 1000);
    })
    .catch(err => {
      console.error(err);
      sessao != ""
        ? notifyError(err.response.data.msg)
        : notifyError("Selecione ao menos uma sessão para o envio.");
    });
}

export function liberarResultadoSessao(sessao) {
  api
    .put(`api/sessoes/liberar-resultado`, {
      sessoes: sessao
    })
    .then(putRes => {
      notifySuccess(putRes.data.msg);
    })
    .catch(err => {
      console.error(err);
      sessao != ""
        ? notifyError(err.response.data.msg)
        : notifyError("Selecione ao menos uma sessão para o envio.");
    });
}

export function liberarInscricaoSessao(sessao, assessmentId) {
  api
    .put(`api/sessoes/liberar-inscricoes`, {
      sessoes: sessao,
      assessmentId
    })
    .then(putRes => {
      notifySuccess("Inscrições liberada com sucesso.");
    })
    .catch(err => {
      console.error(err);
      notifyError(err.response.data.msg);
    });
}

export function unauthorizeSessionInscription(session, assessmentId) {
  api
    .put(`api/sessoes/unauthorize-inscriptions`, {
      sessoes: session,
      assessmentId
    })
    .then(putRes => {
      notifySuccess("Inscrições desautorizadas com sucesso.");
    })
    .catch(err => {
      console.error(err);
      notifyError(err.response.data.msg);
    });
}

export function enviarEmailSessao(sessao, assess, partic) {
  api
    .put(`api/assessments/enviar-email-convite`, {
      sessoes: sessao,
      assessment: assess,
      participantes: partic
    })
    .then(putRes => {
      notifySuccess(putRes.data.msg);
    })
    .catch(err => {
      console.error(err.response.data.error);
      notifyError(err.response.data.error);
    });
}

export function excluirSessaoArquivar(sessao) {
  api
    .delete(`/api/sessoes/${sessao}`)
    .then(putRes => {
      notifySuccess(putRes.data.msg);
    })
    .catch(err => {
      console.error(err);
      notifyError("Não foi possível excluir a sessão! Por favor, recarregue a página e tente novamente");
    });
}

async function getAtividade(projectId, atividade, atividadeId) {
  const resGet = await api
    .get(`api/projects/${projectId}/info`)

  const projeto = resGet.data;
  if (!projeto.modulos) {
    return null;
  }
  let ativ = projeto.modulos[atividade].find(
    ati => ati._id === atividadeId
  );

  if (atividade === Constantes.moduloProvas) {
    projeto.modulos[atividade].forEach(p =>
      p._id !== atividadeId
        ? ativ
          ? ativ.provas
            ? ativ.provas.push({
                id: p._id,
                nome: p.nome
              })
            : (ativ.provas = [
                {
                  id: p._id,
                  nome: p.nome
                }
              ])
          : (ativ = { provas: [{ id: p._id, nome: p.nome }] })
        : false
    );
  }

  return ativ;
}

function getProjeto(projectId, cb) {
  api
    .get(`api/projects/${projectId}`)
    .then(resGet => {

      const projeto = resGet.data;
      if (!projeto) {
        return null;
      }


      cb(projeto);
    })
    .catch(err => console.error(err));
}

export async function getEquipeConsultor(projectId) {
  try {
    let resGet = await api.get(`api/projects/${projectId}/equipeConsultor`);


    const projeto = resGet.data;
    if (!projeto) {
      return null;
    }

    return projeto;
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function criarAtividade(projectId, atividade, info, cb) {
  try {
    const res = await api
    .post(`api/projects/${projectId}/modulos/${atividade}`, {
      info
    });

    const updated = res.data;
    cb(updated);
  } catch (err) {
    console.error(err);
  }
}

async function atualizarAtividade(projectId, atividade, info, cb) {
  try {
    const res = await api
    .put(`api/projects/${projectId}/modulos/${atividade}/${info._id}`, {
      info
    });

    const updated = res.data
    cb(updated);
  } catch (err) {
    console.error(err);
    notifyError("Não foi possível atualizar a atividade! Por favor, recarregue a página e tente novamente");
  }
}

function getNewCodigoDeAcesso(projectId, callback) {
  return api
    .get(`/api/projeto/access-code`)
    .then(res => callback(null, res.data));
}

function getCodigoAcesso(projectId, cb) {
  api
    .get(`/api/projects/${projectId}/codigosacesso`)
    .then(resGet => {
      if (resGet.data) cb(null, resGet.data.cod);
      else alert("código não recebido");
    })
    .catch(err => cb(err));
}

async function getAssessment(idAssessment) {
  let resGet = await api.get(`/api/assessments/${idAssessment}`);
  return resGet.data;
}

async function getParticipantes(atividade, atividadeId) {
  let resGet = await api.get(
    `/api/projects/modulos/${atividade}/${atividadeId}/participantes`
  );
  return resGet.data;
}

async function getParticipantesProjeto(projectId) {
  // let resGet = await api.get(`/api/projects/${projectId}/participantes`)
  let resGet = await api.get(`/api/projects/${projectId}`);
  let projeto = resGet.data;
  let participantes = projeto.participantes.map((p, i) => {
    const indexProjeto = p.projetos.findIndex(p => p._idProjeto === projectId);
    const info = p.projetos[indexProjeto];

    return { ...p, ...info };
  });

  return participantes;
}

async function updateParticipantesAtividade(ids, atividadeId, atividade) {
  try {
    let resPut = await api.put(
      `/api/projects/modulos/${atividade}/${atividadeId}/participantes`,
      { participantes: ids }
    );
    let msg = resPut.data.msg;
  } catch (error) {
    console.error(error);
    alert("Erro: não foi possível salvar alterações no banco de dados");
  }
}

async function getInstrumentos(tipo) {
  const instrumentosLista = await assincronosApi.get(
    `/consultor/available-instruments/${sessionStorage.getItem("user")}`
  );

  return instrumentosLista.data;
}

async function getRecommendedInstruments() {
  const instrumentosLista = await assincronosApi.get(
    `/consultor/recommended-instruments/${sessionStorage.getItem("user")}`
  );

  return instrumentosLista.data;
}

// WO = WITHOUT
async function getInstrumentsWOData() {
  const instrumentosLista = await assincronosApi.get(
    `/instruments/available-instruments/${sessionStorage.getItem("user")}`
  );

  return instrumentosLista.data;
}

async function getRecommendedInstrumentsWOData() {
  const instrumentosLista = await assincronosApi.get(
    `/instruments/recommended-instruments/${sessionStorage.getItem("user")}`
  );

  return instrumentosLista.data;
}

export {
  getAtividade,
  getProjeto,
  criarAtividade,
  atualizarAtividade,
  getAssessment,
  getParticipantes,
  getParticipantesProjeto,
  getNewCodigoDeAcesso,
  getCodigoAcesso,
  updateParticipantesAtividade,
  updateParticipantesProva,
  getInstrumentos,
  getRecommendedInstruments,
  getInstrumentsWOData,
  getRecommendedInstrumentsWOData
};
