export function validatePassword(password) {
  const minimumLenght = 8;
  const regexUpper = /[A-Z]/;
  const regexNumber = /[0-9]/;
  const regexSpecials = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

  const errors = [];

  // Verifica o tamanho mínimo da senha
  if (password.length < minimumLenght) {
    errors.push("a senha deve ter no mínimo 8 caracteres*");
  }

  // Verifica se há pelo menos uma letra maiúscula na senha
  if (!regexUpper.test(password)) {
    errors.push("a senha deve ter pelo menos uma letra maiúscula*");
  }

  // Verifica se há pelo menos um número na senha
  if (!regexNumber.test(password)) {
    errors.push("a senha deve ter pelo menos um número*");
  }

  // Verifica se há pelo menos um caractere especial na senha
  if (!regexSpecials.test(password)) {
    errors.push("a senha deve ter pelo menos um caractere especial*");
  }

  return errors;
}