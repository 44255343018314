import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ButtonCancel, ButtonSave } from "views/Pages/Assets/Assets";
import ReportProgressAPI from "views/Reports/ReportsList/ReportProgressAPI";
import { notifyError } from 'Services/Notificacoes';
import Messages from 'static/Messages';

const { PDF_DONE } = ReportProgressAPI.channels;

class PdfNotificationModal extends React.Component {

  state = {
    showModal: false,
    pdfUrl: null
  }

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.configSocket = this.configSocket.bind(this);
    this.handlePdfDone = this.handlePdfDone.bind(this);
  }

  toggleModal() {
    this.setState(prev => ({ showModal: !prev.showModal }));
  }

  async openModal(pdfUrl) {
    if (!pdfUrl) throw new Error('pdfUrl not defined');

    this.setState({
      showModal: true,
      pdfUrl
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
      pdfUrl: null
    });
  }

  async downloadPdf() {
    try {
      const url = this.state.pdfUrl;
      if (!url) throw new Error('pdf url not defined');

      function saveFile(url) {
        if (!url) return;

        let a = document.createElement('a');
        a.href = url;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }

      saveFile(url);
      this.closeModal();
    } catch (err) {
      console.error(err);
    }
  }

  handlePdfDone(data) {
    try {
      if (data.consultant_id !== this.consultant_id) {
        return;
      }
      if (data.error) {
        this.closeModal();
        return notifyError(Messages.Reports.msgCannotGeneratePDF);
      }

      this.openModal(data.pdf_url);
    } catch (err) {
      console.error(err);
      notifyError(Messages.Geral.msgSocketProcessingError);
    }
  }

  async configSocket() {
    ReportProgressAPI.socket.on(PDF_DONE, this.handlePdfDone);
  }

  componentDidMount() {
    this.consultant_id = sessionStorage.getItem('user');
    this.configSocket();
  }

  componentWillUnmount() {
    ReportProgressAPI.socket.off(PDF_DONE, this.handlePdfDone);
  }

  render() {

    const { showModal } = this.state;

    return <Modal
      isOpen={showModal}
      toggle={this.toggleModal}
    >
      <ModalHeader
        toggle={this.toggleModal}
      >
        Atenção!
    </ModalHeader>
      <ModalBody style={{ fontSize: 12 }}>
        <p>PDF gerado. Deseja baixar o arquivo?</p>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel
          onClick={() => {
            this.closeModal()
          }}
        >
          Não
    </ButtonCancel>

        <ButtonSave onClick={() => this.downloadPdf()}>Sim</ButtonSave>
      </ModalFooter>
    </Modal>
  }
}

export default PdfNotificationModal;