import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { FaRegQuestionCircle } from "react-icons/fa";
import { Tooltip } from 'react-tippy';
import { Container, Row, Col } from "reactstrap";
import Loadable from "react-loadable";
import Dashboard from "../../views/Pages/Dashboard/Dashboard";
import verifyModulesAccess from "Services/verifyModulesAccess";
import apiCalls from 'config/apiCalls';
import InstructionsModal from 'views/Components/InstructionsModal';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import Footer from "../../views/Participante/components/Footer";
import Loading from 'Services/Loading';
import PdfNotificationModal from 'components/pdf-notification-modal';
import ReportNotificationModal from 'components/report-notification-modal';
import { toast } from "react-toastify";
import { Constantes } from "Services/Constantes";

const Reports = Loadable({
  loader: () => import("views/Reports"),
  loading: Loading
});

const Results = Loadable({
  loader: () => import("views/Results"),
  loading: Loading
});

const LogScreen = Loadable({
  loader: () => import("views/LogScreen"),
  loading: Loading
});

const LogScreenAdmin = Loadable({
  loader: () => import("views/LogScreenAdmin"),
  loading: Loading
});

const TestEnvironment = Loadable({
  loader: () => import("views/TestEnvironment"),
  loading: Loading
});

const BuySimulation = Loadable({
  loader: () => import("views/BuySimulation"),
  loading: Loading
});

class DefaultLayout extends Component {
  notificacoes = []; // mensagens da prova
  state = {
    helpModal: false,
    availablePurchasedCredits: 0,
    availableTryonCredits: 0,
    nomeUser: "",
    consultantLogo: '',
    modulesAccess: [],
    haveClient: false,
    haveProject: false,
    projectName: '',
    checkedNav: false,
    navs:{ items: [
      {
        title: true,
        name: "Menu de Navegação",
        wrapper: {
          element: "",
          attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: "" // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: "Dashboard",
        url: "/dashboard",
        icon: "fa fa-tachometer",
        class: "dashboard-step-3"
      },
      {
        name: "Meus Processos",
        url: "/clientes",
        icon: "fa fa-tasks",
        class: "dashboard-step-4"
      },
      {
        name: "Ambiente Testes",
        url: "/ambiente-testes",
        icon: "fa fa-flask",
        class: "dashboard-step-5"
      },
      {
        name: "Financeiro",
        url: "/financeiro/simulacao-compra",
        icon: "fa fa-usd",
        class: "dashboard-step-6"
      },
      // {
      //   name: "Logs",
      //   url: "/logs",
      //   icon: "fa fa-book"
      // },
      {
        name: "Contate-nos",
        url: "/suporte",
        icon: "fa fa-question-circle",
        class: "dashboard-step-8"
      },
    ]}
  };

  getCredits = async() => {
    const creditsRes = await apiCalls.credits.getAllBalance({
      userId: sessionStorage.getItem('user'),
    });

    let availablePurchasedCredits = 0;
    let availableTryonCredits = 0;

    if (creditsRes && creditsRes.data) {
      const { purchaseCreditsBalance, tryonCreditsBalance } = creditsRes.data;

      availablePurchasedCredits = purchaseCreditsBalance;
      availableTryonCredits = tryonCreditsBalance;
    }

    return [availablePurchasedCredits, availableTryonCredits];
  }

  verifyClientProject = async() => {
    let splited = window.location.href.split('#');
    splited = splited[1].split('/');

    let haveClient = false;
    let haveProject = false;
    let projectName = '';

    if (splited.find(s => s.includes('cliente')) && splited.length > 2) haveClient = true;
    else {
      sessionStorage.removeItem("clienteNome");
      haveClient = false;
    }

    if (sessionStorage.getItem('projeto') && splited.find(s => s.includes('projeto')) && splited.length > 3 && !(splited.find(s => s.includes('configurar')) && splited.length === 4)) {
      const res = await apiCalls.projetos.getProjectNameById(sessionStorage.getItem('projeto'));
      const { project } = res.data;
      projectName = project.nome;
      haveProject = true;
      sessionStorage.setItem("projectName", project.nome);
    } else {
      haveProject = false;
      projectName = '';
      sessionStorage.removeItem("projectName");
    }

    this.setState({ haveClient, haveProject, projectName });
  }

  verifyLocation = () => {
    let splited = window.location.href.split('#');
    splited = splited[1].split('/');

    if (splited[1] !== 'login' && !this.state.checkedNav) {
      const navs = navigation();

      this.setState({ navs, checkedNav: true });
    } else if (splited[1] === 'login') {
      this.setState({ checkedNav: false, navs: { items: [
        {
          title: true,
          name: "Menu de Navegação",
          wrapper: {
            element: "",
            attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: "" // optional class names space delimited list for title item ex: "text-center"
        },
        {
          name: "Dashboard",
          url: "/dashboard",
          icon: "fa fa-tachometer",
          class: "dashboard-step-3"
        },
        {
          name: "Meus Processos",
          url: "/clientes",
          icon: "fa fa-tasks",
          class: "dashboard-step-4"
        },
        {
          name: "Ambiente Testes",
          url: "/ambiente-testes",
          icon: "fa fa-flask",
          class: "dashboard-step-5"
        },
        {
          name: "Financeiro",
          url: "/financeiro/simulacao-compra",
          icon: "fa fa-usd",
          class: "dashboard-step-6"
        },
        // {
        //   name: "Logs",
        //   url: "/logs",
        //   icon: "fa fa-book"
        // },
        {
          name: "Contate-nos",
          url: "/suporte",
          icon: "fa fa-question-circle",
          class: "dashboard-step-8"
        },
      ]} })
    }
  }

  componentDidMount = async() => {
    this.setState({ nomeUser: sessionStorage.getItem('userConsultantName'), consultantLogo: sessionStorage.getItem('consultantLogo') });

    window.addEventListener('hashchange', async() => {await this.verifyClientProject()});

    window.addEventListener('hashchange', () => {this.verifyLocation()});

    // escuta mensagem da prova
    Constantes.io.on('Users:Prova:NaoRespondeu', ({
      idParticipante,
      nomeParticipante,
      prova,
      indexQuestao,
      idConsultor
    }) => {
      const mostrada = this.notificacoes.find(n => (
        n.idParticipante === idParticipante &&
        n.nomeParticipante && nomeParticipante
      )
      );
      if (mostrada) return;
      // if (prova !== this.provaId) return;
      if (idConsultor !== sessionStorage.getItem('user')) return;
      let msg = `O participante ${nomeParticipante} pode estar com problemas de acesso`;
      // notifyInfo(msg);
      toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
      });
      this.notificacoes.push({
        tipo: 'participante-nao-respondeu',
        idParticipante,
        nomeParticipante,
        indexQuestao
      });
    });

    Constantes.io.on('Credits:NoCredits', ({
      userId,
      userName,
      msg,
      moduleId,
      projectId,
      module
    }) => {
      if (userId === sessionStorage.getItem("user")) {
        toast.info(msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false
        });
      }
    });

    Constantes.io.on('Credits:UpdateCredits', async ({
      userId
    }) => {
      if (userId === sessionStorage.getItem("user")) {
        const [purchasedCredits, tryonCredits] = await this.getCredits();

        this.setState({ availablePurchasedCredits: purchasedCredits.toLocaleString('pt-BR'), availableTryonCredits: tryonCredits.toLocaleString('pt-BR') })
      }
    });

    Constantes.io.on('Pix:Completed', async ({
      userId
    }) => {
      if (userId === sessionStorage.getItem("user")) {
        toast.success('Pagamento Pix recebido.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true
        });

        this.props.history.push("/financeiro/meus-pedidos");
      }
    });


    // Constantes.assincIo.on('Credits:NoCredits', ({
    //   userId,
    //   userName,
    //   moduleId,
    //   projectId,
    //   module
    // }) => {
    //   if (userId === sessionStorage.getItem("user")) {
    //     const msg = `Usuário ${userName} não conseguiu fazer login no instrumento assíncrono, créditos insuficientes.`;
    //     toast.info(msg, {
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: false
    //     });
    //   }
    // });

    // Constantes.assincIo.on('Credits:UpdateCredits', async ({
    //   userId
    // }) => {
    //   if (userId === sessionStorage.getItem("user")) {
    //     const availableCredits = await this.getCredits();

    //     this.setState({ availableCredits: availableCredits.toLocaleString('pt-BR') })
    //   }
    // });

    const canAccess = await verifyModulesAccess(sessionStorage.getItem('user'));

    const [purchasedCredits, tryonCredits] = await this.getCredits();

    const navs = navigation();

    this.setState({ navs, modulesAccess: canAccess, availablePurchasedCredits: purchasedCredits.toLocaleString('pt-BR'), availableTryonCredits: tryonCredits.toLocaleString('pt-BR') });
  };

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', async() => {await this.verifyClientProject()});
    window.removeEventListener('hashchange', () => {this.verifyLocation()});
  }

  updateState = (newState) => {
    this.setState({ ...newState })
  }

  toggleHelpModal = () => {
    this.setState({ helpModal: !this.state.helpModal })
  }

  render() {
    const isPlayer = sessionStorage.getItem("isPlayer");
    return (
      <div className="app">
          <InstructionsModal
            isOpen={this.state.helpModal}
            modalHeader={'Ajuda'}
            modalBody={
              <div style={{ fontSize: 12 }}>
                <strong>Créditos Bônus</strong><br />
                <p>São créditos oferecidos gratuitamente ao fazer o primeiro cadastro no <strong>IAPP</strong>. Esses créditos serão utilizados nos instrumentos que você configurar.</p>
                <p>O Saldo visto em <strong>Bônus</strong> se refere ao somatório dos créditos recebidos gratuitamente em todos os instrumentos. Os créditos serão debitados quando os participantes fizerem a atividade na qual estão vinculados.</p>
                <p>Para cada instrumento do <strong>IAPP</strong> são incluídos créditos bônus suficientes para 10 participantes (10 licenças).</p>
                <p><strong>Importante:</strong> Os créditos de um instrumento não podem ser utilizados em outro instrumento.</p>
                <br />
                <strong>Créditos Disponíveis</strong><br />
                <p>São os créditos adquiridos através do módulo <strong>Financeiro</strong>.</p>
                <p>Os créditos disponíveis, diferente dos créditos bônus, podem ser utilizados livremente em qualquer módulo.</p>
                <p>Você pode simular a quantidade de créditos necessários ao seu processo, inserindo o número de licenças necessárias em cada instrumento. O sistema irá calcular o valor total dos créditos.</p>
                <p>Para saber mais sobre a simulação de créditos e o custo das licenças, acesse o módulo <strong>Financeiro</strong> no menu lateral do <strong>IAPP</strong>.</p>
              </div>
            }
            onCancel={this.toggleHelpModal}
            toggle={this.toggleHelpModal}
          />
          {isPlayer ? null : (
            <AppHeader fixed>
              <DefaultHeader haveClient={this.state.haveClient} haveProject={this.state.haveProject} projectName={this.state.projectName} purchasedCredits={this.state.availablePurchasedCredits} tryonCredits={this.state.availableTryonCredits} updateAsideState={this.updateState} />
            </AppHeader>
          )}
          <div className="app-body">
            {isPlayer ? null : (
              <AppSidebar fixed display="lg" className="menuLateral dashboard-step-2">
                <div className="sidebar-header">
                  <Row>
                    <Col>
                      <div className=" container">
                        <img
                          src={this.state.consultantLogo ? `https://files-storage.s3.amazonaws.com/${this.state.consultantLogo}` : "https://files-storage.s3.amazonaws.com/enterprise_default.png"}
                          className="avatrAjuste"
                          alt="Avatar"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <div>
                        <br />
                        {this.state.nomeUser}
                      </div>
                    </Col>
                    {/* <Col className="text-left">
                    <div>
                      <br />
                      <small>R$ 10.000,00</small>
                      <br />
                      <small>R$ 800,00</small>
                      <br />
                    </div>
                  </Col> */}
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <span style={{ fontSize: 11 }}><strong>Créditos</strong><Tooltip
                position="right"
                size="small"
                trigger="mouseenter"
                arrow="true"
                title={'Ajuda'}
              ><FaRegQuestionCircle onClick={this.toggleHelpModal} style={{ marginLeft: 5, cursor: 'pointer' }} size={12}  /></Tooltip></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <span style={{ fontSize: 11 }}>Bônus: <strong>{this.state.availableTryonCredits}</strong></span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                    <span style={{ fontSize: 11 }}>Disponíveis: <strong>{this.state.availablePurchasedCredits}</strong></span>
                    </Col>
                  </Row>
                </div>

                <AppSidebarForm />
                <AppSidebarNav navConfig={this.state.navs} {...this.props} />
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
            )}
            <main className="main">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        (route.moduleAuth && this.state.modulesAccess.find(m => m.includes(route.moduleAuth))) || (!route.moduleAuth) ?
                        <>
                          <PdfNotificationModal />
                          <ReportNotificationModal />
                          <route.component {...props} />
                        </> : <Redirect to="/dashboard" />
                      )}
                    />
                  ) : null;
                })}
                <Route
                  path='/clientes/projetos/reports'
                  exact={false}
                  name='Reports'
                  render={props => <Reports {...props} />}
                />

                <Route exact={true} path="/clientes/projetos/resultados" render={() => <Redirect to="/clientes/projetos/resultados/configurar" />} />

                <Route
                  path='/clientes/projetos/resultados/configurar'
                  exact={false}
                  name='Results'
                  render={props => <Results {...props} />}
                />

                <Route
                  path='/clientes/projetos/resultados/liberar-resultados'
                  exact={false}
                  name='Liberar Resultados'
                  render={props => <Results {...props} />}
                />

                <Route
                exact={true}
                path="/logs"
                name='Auditoria'
                render={props => <LogScreen { ...props } />}  />

                <Route
                exact={true}
                path="/logs-admin"
                name='Auditoria'
                render={props => <LogScreenAdmin { ...props } />}  />

                <Route
                exact={false}
                path="/ambiente-testes"
                name='Ambiente Testes'
                render={props => <TestEnvironment { ...props } />}  />

                <Route
                exact={false}
                path="/financeiro"
                name='Simulação de compra'
                render={props => <BuySimulation { ...props } />}  />

              </Switch>
            </main>
            <AppAside fixed>
              <DefaultAside />
            </AppAside>
          </div>
          <AppFooter>
            {/* <DefaultFooter /> */}
            <Footer />
          </AppFooter>
      </div>
    );
  }
}

export default withRouter(DefaultLayout);
