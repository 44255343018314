import React from 'react';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { Table } from '../GenericTable';

export default function PageTable({ striped, children, minWidth, setOverflow, style={}, dataTour, loadingComponent }) {
  return (
    <div data-tour={dataTour} style={{ ...style, width: "100%", overflow: setOverflow ? setOverflow : 'auto' }}>
      <Table striped={striped} style={{ minWidth: minWidth ? minWidth : 800, width: "100%" }}>
        {children}
      </Table>
      { loadingComponent || null }
    </div>
  )
}

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th`
  white-space: nowrap;
`;

export const Td = styled.td`
  white-space: nowrap;
`;

export const ThWithMax = styled(Th)`
  max-width: 300px;
`;

export const TdWithMax = styled(Td)`
  max-width: ${props => props.tdMaxWidth || '300px'};
  min-width: ${props => props.tdMinWidth || '300px'};
  width: ${props => `${props.tdWidth ? `${props.tdWidth} !important` : '50%' }`};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ThWithActions = styled.th`
  background-color: #f7f8f8;
  white-space: nowrap;
  position: sticky;
  right: 0;
`;

export const TdActions = styled.td`
  text-align: right;
  white-space: nowrap;
  position: sticky;
  z-index: 1;
  right: 0;
`;

export const ActionsList = styled.ul`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
`;

export const TdWithActions = ({children, style, rowSpan}) => {
  return (
    <TdActions style={style} rowSpan={rowSpan ? rowSpan : 1}>
      <ActionsList>
        {children}
      </ActionsList>
    </TdActions>
  )
}

export const TdWithTooltip = ({ tdMaxWidth, tdMinWidth, content, title, style, rowSpan, tdWidth, className, onClick }) => {
  return (
    <TdWithMax onClick={(e) => {
      if (onClick) onClick(e);
    }} className={className} tdMaxWidth={tdMaxWidth} tdMinWidth={tdMinWidth} tdWidth={tdWidth} style={style} rowSpan={rowSpan ? rowSpan : 1}>
      <Tooltip
        title={title || content}
        arrow="true"
        size="small"
        position="right"
      >
        {content}
      </Tooltip>
    </TdWithMax>
  )
};
