import React from 'react';
import { useHistory } from 'react-router-dom';
import PageTable, { Tr, Th, Td, THead, TBody, TdWithTooltip, ThWithActions, TdWithActions } from "components/PageTable";

import { ProjectActionWithImg, ProjectAction } from "assets/ProjectAction";
import { ProjectsFolder } from "./ProjectsFolder";
import { capitalizeFirstLetter, convertToBRCurrency } from "utils/masks";
import { ClienteAvatar } from 'views/Pages/Assets/Assets';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import formatDate from 'views/Results/ResultsConfig/utils/formatDate';

export default function ClientsTable({
  filteredClients,
  canAccess,
  nomeProjetos,
  ultimoProjeto,
  configurarCliente,
  listarProjetos,
  excluir,
  restaurar,
  archivedsTable,
  lastModifiedProjects,
  orderBy,
  sortField,
  sortOrder
}) {
  const history = useHistory();

  function handleArrow(field) {
    return (
      <>
        {sortField === field ? (
          <span style={{ marginRight: 5 }}>
              {sortOrder === 1 ? <FaChevronDown /> : <FaChevronUp />}
          </span>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <PageTable>
      <THead>
        <Tr>
          <Th style={{ width: 60 }}></Th>
          <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('nomeFantasia')}>
            {handleArrow("nomeFantasia")}
            Cliente
          </Th>
          <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('ultimoProjeto')}>
            {handleArrow("ultimoProjeto")}
            Projeto recente
          </Th>
          <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('ultimaAlteracao')}>
            {handleArrow("ultimaAlteracao")}
            Última alteração
          </Th>
          <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('lastModifiedDate')}>
            {handleArrow("lastModifiedDate")}
            Data Ult. Alteração
          </Th>
          <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('createdAt')}>
            {handleArrow("createdAt")}
            Data Criação
          </Th>
          {/* <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('orcamentoTotal')}>
            {handleArrow("orcamentoTotal")}
            Orçamento total
          </Th>
          <Th style={{ cursor: 'pointer' }} onClick={() => orderBy('orcamentoConsumido')}>
            {handleArrow("orcamentoConsumido")}
            Orçamento consumido
            </Th> */}
          <ThWithActions></ThWithActions>
        </Tr>
      </THead>
      <TBody>
        {
          filteredClients.map((cliente, index) => (
            <>
              <Tr>
                <Td style={archivedsTable ? {} : { borderBottom: 0, paddingBottom: 0 }}>
                  <ClienteAvatar
                    logo={cliente.logo}
                  />
                </Td>

                <TdWithTooltip
                  tdMinWidth={'200px'}
                  title={capitalizeFirstLetter(cliente.nomeFantasia)}
                  content={<>
                    <span><strong>{capitalizeFirstLetter(cliente.nomeFantasia)}</strong></span> <br/><br/>
                    <span>Créditos Disponíveis: {cliente.creditsAvailable ? cliente.creditsAvailable.toLocaleString("pt-BR") : 0}</span> <br/>
                    <span>Créditos Consumido: {cliente.creditsConsumed ? cliente.creditsConsumed.toLocaleString("pt-BR") : 0}</span>
                  </>}
                  style={archivedsTable ? {} : { borderBottom: 0, paddingBottom: 0 }}
                />

                <TdWithTooltip
                  style={archivedsTable ? { fontWeight: 'bold' } : { borderBottom: 0, paddingBottom: 0, fontWeight: 'bold' }}
                  content={capitalizeFirstLetter(cliente.ultimoProjeto).toUpperCase()}
                />
                <Td
                onClick={() => {
                  if (archivedsTable) return;
                  const client = lastModifiedProjects.find(p => String(p.enterpriseId) === String(cliente._id));

                  if (!client) return;

                  sessionStorage.setItem('projeto', client.project._id);
                  sessionStorage.setItem('cliente', cliente._id);
                  sessionStorage.setItem('clienteNome', cliente.nomeFantasia);

                  if (cliente.logo)
                    sessionStorage.setItem('clienteLogo', cliente.logo);
                  else sessionStorage.removeItem('clienteLogo');

                  history.push('/clientes/projetos/resumo');
                }}
                style={archivedsTable ?
                  {} :
                  {
                    borderBottom: 0,
                    paddingBottom: 0,
                    textDecoration: lastModifiedProjects.find(p => String(p.enterpriseId) === String(cliente._id)) ? 'underline' : 'none' ,
                    cursor: lastModifiedProjects.find(p => String(p.enterpriseId) === String(cliente._id)) ? 'pointer' : 'default'
                  }}>
                  { lastModifiedProjects && lastModifiedProjects.find(p => String(p.enterpriseId) === String(cliente._id)) ?
                  lastModifiedProjects.find(p => String(p.enterpriseId) === String(cliente._id)).project.name.toUpperCase() :
                  '-' }
                </Td>
                <Td style={archivedsTable ? {} : { borderBottom: 0, paddingBottom: 0 }}>
                  { cliente.lastModifiedDate ? formatDate(cliente.lastModifiedDate) : '-' }
                </Td>
                <Td style={archivedsTable ? {} : { borderBottom: 0, paddingBottom: 0 }}>
                  { cliente.createdAt ? formatDate(cliente.createdAt) : '-' }
                </Td>
                {/* <Td style={archivedsTable ? {} : { borderBottom: 0, paddingBottom: 0 }}>
                  {
                    convertToBRCurrency(parseInt(cliente.creditos.livre, 10) +
                      parseInt(cliente.creditos.bloqueado, 10))
                  }
                </Td>
                <Td style={archivedsTable ? {} : { borderBottom: 0, paddingBottom: 0 }}>
                  {
                    convertToBRCurrency(cliente.creditos.bloqueado)
                  }
                </Td> */}

                <TdWithActions style={archivedsTable ? { position: 'unset' } : { borderBottom: 0, paddingBottom: 0, position: 'unset' }}>
                  <li data-tour="clients-step-2">
                    <ProjectAction
                      id={`icon-config-${index}`}
                      icon="cog"
                      title="Configurar cliente"
                      disabled={archivedsTable}
                      onClick={() => {

                        configurarCliente(cliente)
                      }}
                    />
                  </li>
                  <li data-tour="clients-step-5">
                    <ProjectAction
                      id={`icon-list-${index}`}
                      icon="list"
                      title="Listar projetos"
                      disabled={archivedsTable}
                      onClick={() => {

                        listarProjetos(cliente)
                      }}
                    />
                  </li>
                  {
                    excluir
                    ?
                    <li data-tour="clients-step-3">
                      <ProjectAction
                        id={`icon-del-${index}`}
                        icon="folder"
                        title="Arquivar cliente"
                        onClick={() => excluir(cliente)}
                      />
                    </li>
                    :
                    <li>
                      <ProjectAction
                        id={`icon-del-${index}`}
                        icon="folder-open"
                        title="Restaurar cliente"
                        onClick={() => restaurar(cliente)}
                      />
                    </li>
                  }
                </TdWithActions>
              </Tr>
              {
                nomeProjetos && nomeProjetos.length > 0 &&
                <Tr>
                  <Td colSpan={2}></Td>
                  <Td style={{ paddingTop: 0 }} colSpan="7">
                    <ProjectsFolder
                      canAccess={canAccess}
                      client={cliente}
                      nomeProjetos={
                        nomeProjetos.filter(p => p.idCliente === cliente._id)
                      }
                    />
                  </Td>
                </Tr>
              }
            </>
          ))
        }
      </TBody>
    </PageTable>
  )
}