import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  text-decoration: underline;
  color: #0070f3;
`

function AssessmentReminderTemplate() {
  return (
    <>
      <p><span><strong>Título do e-mail:</strong> FormareTech - Lembrete de inscrição</span><br />
      <span><strong>Remetente:</strong> no-reply@formaretech.com.br</span><br /></p>
      <img src="https://files-storage.s3.amazonaws.com/mail_assets/formaretech-logo-mail.png" alt="FormareTech Logo" /><br />
      <p>Olá, João Goulard</p> <br />
      <p>Você ainda tem inscrições para sessões de assessment pendentes. Não esqueça de se inscrever
      antes do prazo final para inscrição.</p> <br />
      <p>- Assessment 1 - Prazo de 01/01/2030 até 01/02/2030</p>
      <p>- Assessment 2 - Prazo de 01/02/2030 até 01/03/2030</p><br />
      <p>Para poder se inscrever, entre na <StyledSpan>área do participante</StyledSpan>, insira suas credenciais e se inscreva nas
      sessões que estiverem na coluna “a serem realizadas”.</p>
      <p>Para acessar, utilize como login o seu CPF e a senha já configurada anteriormente.</p>
      <p>Se este é o seu primeiro acesso, utilize as credenciais abaixo:</p>
      <p>Site: <StyledSpan>https://iapp.formaretech.com.br/</StyledSpan></p>
      <p>Usuário: 000.000.000-00</p>
      <p>Senha provisória: 123456</p>
      <br />
      <p>Após o primeiro acesso será solicitado que você altere sua senha.</p>
      <br />
      <p>Qualquer dúvida ou problema, entre em contato conosco através do E-mail: meu_email@consultor.com.br</p> <br />
      <p>Por favor, não responda este e-mail.</p>
      <p>Obrigado!</p>
      <strong>FormareTech</strong>
    </>
  )
}

export default AssessmentReminderTemplate;