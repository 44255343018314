import React from 'react';
import Chart from "chart.js";

import * as ProntidaoIndGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import ProntidaoIndGraphUtils from './ProntidaoIndGraphUtils';

export default class ProntidaoIndGraph extends React.Component {

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  async loadPerceptions() {
    let perceptions = [];

    let perception = await ProntidaoIndGraphService.loadPerceptions(this.props.assessmentId, this.props.participantId);
    perceptions.push(perception);

    return perceptions;
  }

  draw() {
    var ctx = document.getElementById(this.graphName).getContext("2d");
    this.chart = new Chart(ctx, ProntidaoIndGraphUtils.graphConfig());
  }

  getSession(state) {
    return state.sessions
      .filter(session => session)
      .filter(session => session.participante)
      .find(session => session.participante._id === this.props.participantId);
  }

  getPerception(state) {
    return state.perceptions
      .filter(perception => perception)
      .find(perception => perception.userId === this.props.participantId);
  }

  drawParticipant(state) {
    const prontidao = this.getPerception(state).atributosProntidao;
    const datasets = ProntidaoIndGraphUtils.drawParticipant({
      prontidao
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  componentDidMount() {
    this.graphName = `prontidao-ind-graph-${this.props.assessmentId}`;
    let state = {};

    ProntidaoIndGraphService.loadSessions(
      this.props.assessmentId
    ).then(res => {

      const sessions = res.data;
      state.sessions = sessions;
      return this.loadPerceptions();
    }).then(resPerceptions => {
      state.perceptions = resPerceptions.map(perception => perception.data);
    }).catch(err => {
      this.handleError(err);
      notifyError('Não foi possível montar o gráfico individual. Por favor, recarregue a página');
    }).finally(_ => {
      this.setState({ state });
      var canvas = document.getElementById(this.graphName);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };
      this.draw(state);
      this.drawParticipant(state);
    });
  }

  render() {
    return <canvas
      id={this.graphName}
      height={this.props.height} />;
  }
}