export const validarCPF = value => {
  if (value && value.replaceAll('.', '').replace('-', '').length > 11) return false

  let num1,
    num2,
    num3,
    num4,
    num5,
    num6,
    num7,
    num8,
    num9,
    num10,
    num11,
    soma1,
    soma2,
    resto1,
    resto2;

  num1 = Number(value.slice(0, 1));
  num2 = Number(value.slice(1, 2));
  num3 = Number(value.slice(2, 3));
  num4 = Number(value.slice(3, 4));
  num5 = Number(value.slice(4, 5));
  num6 = Number(value.slice(5, 6));
  num7 = Number(value.slice(6, 7));
  num8 = Number(value.slice(7, 8));
  num9 = Number(value.slice(8, 9));
  num10 = Number(value.slice(9, 10));
  num11 = Number(value.slice(10, 11));

  if (
    num1 === num2 &&
    num2 === num3 &&
    num3 === num4 &&
    num4 === num5 &&
    num5 === num6 &&
    num6 === num7 &&
    num7 === num8 &&
    num8 === num9 &&
    num9 === num10 &&
    num10 === num11
  ) {
    return false;
  } else {
    soma1 =
      num1 * 10 +
      num2 * 9 +
      num3 * 8 +
      num4 * 7 +
      num5 * 6 +
      num6 * 5 +
      num7 * 4 +
      num8 * 3 +
      num9 * 2;
    resto1 = (soma1 * 10) % 11;

    if (resto1 === 10) {
      resto1 = 0;
    }

    soma2 =
      num1 * 11 +
      num2 * 10 +
      num3 * 9 +
      num4 * 8 +
      num5 * 7 +
      num6 * 6 +
      num7 * 5 +
      num8 * 4 +
      num9 * 3 +
      num10 * 2;
    resto2 = (soma2 * 10) % 11;

    if (resto2 === 10) {
      resto2 = 0;
    }

    if (resto1 === num10 && resto2 === num11) {
      return true;
    } else {
      return false;
    }
  }
};

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado.toString() !== digitos.charAt(1)) return false;

  return true;
}
