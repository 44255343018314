import apiCalls from 'config/apiCalls';
import React from 'react';
import Loading from 'Services/Loading';
import Grafico from './Grafico';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import DefaultTitle from 'components/default-title';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

class Case extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      normalized_total_average: 0,
      participant_modal_text: '',
      difference_text: '',
      analise: '',
      loading: false,
      resultExplanationPhrase: ''
    }
  }

  async componentDidMount() {
    const { idParticipante, idDinamica } = this.props;
    this.setState({ loading: true });
    try {
      const response = await apiCalls.resultados.dinamicas.generalResultByInstrument(idParticipante, idDinamica, 'case');

      console.log(response.difference_text);

      this.setState({
        normalized_total_average: response.data.normalized_total_average,
        participant_modal_text: response.data.participant_modal_text,
        difference_text: response.data.difference_text,
        resultExplanationPhrase: response.data.result_explanation_phrase,
        analise: response.data.whats_beeing_measured_text
      });
    } catch (err) {
      // if(err.response.status != 404)
        // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { normalized_total_average, difference_text, participant_modal_text, loading, analise } = this.state;

    return (
      <div style={{ fontSize: 12 }}>
        {
          loading
            ?
            <Loading />
            :
            <>
              <p>{this.state.resultExplanationPhrase}</p>
              {
                (!isNaN(normalized_total_average) && participant_modal_text)
                  ?
                  <>
                    <Grafico series={[normalized_total_average]}
                      id={`systemic-vision-chart-${this.props.idDinamica}`} />
                    <div>
                      <WhatsBeingMeasured text={analise} />
                    </div>

                    <div style={{marginTop: 10}}>
                      <DefaultTitle>Resultado</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(participant_modal_text) }}></p>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(difference_text) }}></p>
                    </div>
                  </>
                  :
                  <NoResultsFound />
              }
            </>
        }
      </div>
    );
  }
}

export default Case;
