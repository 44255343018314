import fetchResults from "./fetchResults";
import gravarGrafico from "./gravarGrafico";
import encontrarGrafico from "./encontrarGrafico";
import resetarPosicoesGrafico from "./resetarPosicoesGrafico";
import atualizarTamanhoFonte from "./atualizarTamanhoFonte";

export async function loadResults(assessmentId, abbr) {
  return await fetchResults(assessmentId, abbr);
}

export async function salvarGrafico({ datasets, assessmentId }) {
  return await gravarGrafico({ datasets, assessmentId });
}

export async function buscarGrafico({ assessmentId }) {
  return await encontrarGrafico({ assessmentId });
}

export async function resetarPosicoes({ assessmentId }) {
  return await resetarPosicoesGrafico({ assessmentId });
}

export async function alterarTamanhoFonte({ tamanho, assessmentId }) {
  return await atualizarTamanhoFonte({ tamanho, assessmentId });
}
