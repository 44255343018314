import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import AutoEficaciaGraph from 'views/Pages/Modulos/Assincronos/Components/AutoEficaciaGraph';
import { Col, Row } from 'reactstrap';

import './scss/styles.scss';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import * as AutoEficaciaService from './services';
import Messages from 'static/Messages';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import { getDimensoes } from 'models/auto-eficacia/getDimensoes';

const AutoEficaciaDiv = styled.div``;

const FeedbackText = styled.p`
p {
  font-size: 12px;
  margin-bottom: 5px;

  strong {
    font-size: 14px;
  }

  & ~ p {
    margin-left: 0px;
  }
}
`

export default class AutoEficacia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respostasFormatadas: {},
      loading: false,
      personalidade: {},
      feedbackTexts: [],
      whatsBeingMeasuredText: ''
    }
  }

  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);
      let resultado = response.data[0];
      if (!resultado) return null;
      if (!resultado.dimensoes) resultado.dimensoes = getDimensoes(resultado);

      if (resultado) {
        this.setState({ resultado });

        return resultado;
      }

    } catch (err) {
      console.error(err);
      notifyError(Messages.Geral.msgDataFetchError);
    }
  }

  async getTextos() {
    const { feedbackTexts, whatsBeingMeasuredText } = await AutoEficaciaService
      .getFeedbackText({
        userId: this.props.idParticipante,
        assincronoId: this.assincId
       });
    return { feedbackTexts, whatsBeingMeasuredText };
  }

  componentDidMount() {
    this.assincId = this.props.idAssincrono;

    const loadTexts = async () => {
      await this.getTextos()
        .then(({ feedbackTexts, whatsBeingMeasuredText }) => {
          this.setState({
            feedbackTexts: feedbackTexts || [],
            whatsBeingMeasuredText
          });
        })
        .catch(err => {
          console.error(err);
        })
    }

    this.getInfoInstrumento()
      .then(async (resultado) => {
        if (!resultado) {
          return; // Results not found message.
        } else {
          await loadTexts();
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const {
      resultado,
      feedbackTexts
    } = this.state;

    return (
      <AutoEficaciaDiv>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                resultado && feedbackTexts && feedbackTexts.length
                  ?
                  <>
                    <AutoEficaciaGraph id={`auto-eficacia-graph-${this.props.idAssincrono}`}
                      participantId={this.props.idParticipante}
                      assincronoId={this.props.idAssincrono}
                      instrumentId={this.props.idInstrumento}
                      projectId={this.props.idProjeto} />

                    <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} style={{ marginTop: 30 }} />
                    <br />
                    <Row>
                      <Col>
                        <p>
                          <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>Resultado</h3>
                        </p>
                      </Col>
                    </Row>

                    {feedbackTexts && feedbackTexts.length && feedbackTexts.map(text => (
                      <Row>
                        <Col style={{
                          fontSize: '12px',
                          marginBottom: 15
                        }}>
                          {text.title}
                          <FeedbackText dangerouslySetInnerHTML={{
                            __html: text.htmlText
                          }}
                          ></FeedbackText>
                        </Col>
                      </Row>
                    ))}
                  </>
                  :
                  <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
              }
            </>
        }
      </AutoEficaciaDiv>
    )
  }
}