import React from 'react';

import apiCalls from 'config/apiCalls';
import Loading from 'Services/Loading';
import Grafico from 'views/Pages/Resultados/ModalResultados/ResultadosDinamica/Case/Grafico';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import DefaultTitle from 'components/default-title';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import Messages from 'static/Messages';
import { notifyError } from "Services/Notificacoes";
const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

class AssessmentFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      normalized_total_average: 0,
      participant_modal_text: '',
      difference_text: '',
      analise: '',
      loading: false,
      resultExplanationPhrase: '',

      estrategiasEnfrentamento: '',
      agendaMudanca: '',
      nivelAgregacaoValor: '',
      buscasReferenciasProfissionais: '',
      construcaoVinculos: '',
      transitoDiferentesNiveis: ''
    }
  }

  handleError(error, msg = Messages.Geral.msgOperationError) {
    try {
      console.error(error);
      notifyError(msg);
    } catch (error2) {
      console.error(error2);
    }
  }

  async componentDidMount() {
    // const { idParticipante, idDinamica } = this.props;
    this.setState({ loading: true });
    try {
      const response = await apiCalls.assessments.feedbackTexts({
        assessmentId: this.props.assessmentId, 
        participantId: this.props.participantId,
        projectId: this.props.idProjeto
      });
      if (!response.data) return notifyError('Não foi possível carregar o Resultado');
      this.setState({
        estrategiasEnfrentamento: response.data.potential_texts.participant_modal_texts.find(t => t.attribute === 'estrategiasEnfrentamento').text,
        agendaMudanca: response.data.potential_texts.participant_modal_texts.find(t => t.attribute === 'agendaMudanca').text,
        nivelAgregacaoValor: response.data.potential_texts.participant_modal_texts.find(t => t.attribute === 'nivelAgregacaoValor').text,
        buscasReferenciasProfissionais: response.data.potential_texts.participant_modal_texts.find(t => t.attribute === 'buscasReferenciasProfissionais').text,
        construcaoVinculos: response.data.potential_texts.participant_modal_texts.find(t => t.attribute === 'construcaoVinculos').text,
        transitoDiferentesNiveis: response.data.potential_texts.participant_modal_texts.find(t => t.attribute === 'transitoDiferentesNiveis').text,
        analise: response.data.potential_texts.whats_being_measured_text
      });

      // console.log(response.difference_text);

      // this.setState({
      //   normalized_total_average: response.data.normalized_total_average,
      //   participant_modal_text: response.data.participant_modal_text,
      //   difference_text: response.data.difference_text,
      //   resultExplanationPhrase: response.data.result_explanation_phrase,
      //   analise: response.data.whats_beeing_measured_text
      // });
    } catch (err) {
      this.handleError(err);
      // if(err.response.status != 404)
        // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { 
      normalized_total_average, 
      difference_text, 
      participant_modal_text, 
      loading, 
      analise ,
      estrategiasEnfrentamento,
      agendaMudanca,
      nivelAgregacaoValor,
      buscasReferenciasProfissionais,
      construcaoVinculos,
      transitoDiferentesNiveis
    } = this.state;

    return (
      <div style={{ fontSize: 12 }}>
        {
          loading
            ?
            <Loading />
            :
            <>
              <p>{this.state.resultExplanationPhrase}</p>
              {
                // (!isNaN(normalized_total_average) && participant_modal_text)
                  // ?
                  <>
                    {/* <Grafico series={[normalized_total_average]}
                      id={`assessment-feedback-chart-${this.props.assessmentId}`} /> */}
                    <div>
                      <WhatsBeingMeasured text={analise} />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <DefaultTitle>Resultado</DefaultTitle>

                      <DefaultTitle style={{ marginTop: 20, fontSize: '12px'}}>Aptidão Cognitiva</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(estrategiasEnfrentamento) }}></p>

                      <DefaultTitle style={{ fontSize: '12px'}}>Agente de Mudança</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(agendaMudanca) }}></p>

                      <DefaultTitle style={{ fontSize: '12px'}}>Nível de Agregação de Valor</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(nivelAgregacaoValor) }}></p>

                      <DefaultTitle style={{ fontSize: '12px'}}>Busca por Referências Profissionais</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(buscasReferenciasProfissionais) }}></p>

                      <DefaultTitle style={{ fontSize: '12px'}}>Construção de Vínculos</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(construcaoVinculos) }}></p>

                      <DefaultTitle style={{ fontSize: '12px'}}>Trânsito nos Diferentes Níveis</DefaultTitle>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transitoDiferentesNiveis) }}></p>
                      
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(participant_modal_text) }}></p>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(difference_text) }}></p>
                    </div>
                  </>
                  // :
                  // ''
              }
            </>
        }
      </div>
    );
  }
}

export default AssessmentFeedback;
