import React from 'react';

import * as FeedbackGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import * as FeedbackGraphUtils from './FeedbackGraphUtils';

import './styles/styles.css'

export default class EstiloDeComunicacaoGraph extends React.Component {

  state = {
    userResult: {
      relacoes: [],
      words: [],
      wordsVotes: []
    },
    alreadyInScreen: false,
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  drawParticipant({ userResult }) {
    FeedbackGraphUtils.drawParticipant({
      relacoes: userResult.relacoes || [],
      words: userResult.words || [],
      wordsVotes: userResult.wordsVotes,
      id: this.props.id
    });
  }

  componentDidMount() {
    let state = {};
    if (this.state.alreadyInScreen) return

    FeedbackGraphService.loadUserResult({
      userId: this.props.participantId,
      assincId: this.props.assincId,
      type: this.props.type,
      projectId: this.props.projectId,
      isConsultant: this.props.isConsultant
    })
    .then(res => {
      if (!res.result.words || !res.result.words.length) this.props.setNotHaveResults(true)
      state.userResult = res && res.result || {};
    })
    .catch(err => {
      this.handleError(err);
      notifyError('Não foi possível montar o gráfico individual. Por favor, recarregue a página e tente novamente');
    }).finally(_ => {
      this.setState({ state });
      var svg = document.getElementById(this.props.id);
      if (svg && svg.firstChild) {
        return
      }
      if (!svg) {
        console.info('svg element removed.');
        return;
      };

      this.drawParticipant(state);
      this.setState({ alreadyInScreen: true })
    });
  }

  render() {
    return <svg
      id={this.props.id}
      height={500}
      width={600}
      />;
  }
}