export default function formatAnswer(answerers) {
  if (!answerers) return;

  let newObj = {};

  Object.keys(answerers).map(key => {
    newObj[key] = {
      ...answerers[key],
      E: answerers[key]['sumE'],
      S: answerers[key]['sumES'] + answerers[key]['sumS'],
      T: answerers[key]['sumST'] + answerers[key]['sumT'],
      J: answerers[key]['sumTJ'] + answerers[key]['sumJ'],
      I: answerers[key]['sumI'],
      N: answerers[key]['sumIN'] + answerers[key]['sumN'],
      F: answerers[key]['sumNF'] + answerers[key]['sumF'],
      P: answerers[key]['sumFP'] + answerers[key]['sumP'],
    }
  });

  return newObj;
}