import React, { Component } from "react";
// import './css/BlueBanner.css';
import styled from "styled-components";

const Container = styled.div`
  background-color: #00264b;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 30px;
  /* margin: 30px 0 0 0; */
`;
export default class BlueBanner extends Component {
  render() {
    return <Container>{this.props.children}</Container>;
  }
}
