import React, { useRef, useState, useEffect } from 'react';
import InputMask from "react-input-mask";

import './styles.css';

function Input(props) {
  const inputRef = useRef(null);

  const [inputFocused, setInputFocused] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    setText(props.value);
  }, [props.value]);

  return (
    <div style={props.style} className="table-input">
      {
        props.label ?
        <span style={{ marginRight: 5, fontWeight: 'bold', fontSize: 12 }}>{props.label}</span>
        : null
      }
      <input
        ref={inputRef}
        type="text"
        name={props.name}
        className={props.inputClassName}
        placeholder={props.placeholder}
        onChange={(e) => {
          setText(e.target.value);
        }}
        value={text}
        required={props.required}
        disabled={props.disabled}
        tabIndex={props.tabIndex || 0}
        onFocus={() => setInputFocused(true)}
        onBlur={async() => {
          if (props.changeInput) await props.changeInput(props.coluna.moduloNome, props.coluna.propriedades, props.coluna.moduloId, text);

          setTimeout(() => {
            setInputFocused(false);
          }, 100);
        }}
      />
      {!props.disabled ? inputFocused ? (
              <i
                style={{ color: "#808080" }}
                className="fa fa-check check-icon"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                onClick={() => {
                  setInputFocused(true);
                  inputRef.current.focus();
                }}
                style={{ color: "#808080" }}
                className="fa fa-pencil pencil-icon"
                aria-hidden="true"
              ></i>
            ) : null}
    </div>
  );
}

export default Input;