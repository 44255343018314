/// Retorna a diferença, em ms, entre dois objetos Date
export default function dateDiffInMilli(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(
    a.getFullYear(),
    a.getMonth(),
    a.getDate(),
    a.getHours(),
    a.getMinutes(),
    a.getSeconds(),
    a.getMilliseconds()
  );
  const utc2 = Date.UTC(
    b.getFullYear(),
    b.getMonth(),
    b.getDate(),
    b.getHours(),
    b.getMinutes(),
    b.getSeconds(),
    b.getMilliseconds()
  );

  return utc2 - utc1;
}
