import styled from "styled-components";

const TextButton = styled.button`
  font-size: 12px;
  font-weight: 600;
  border: 0;
  background-color: transparent;
  text-decoration: underline;
  color: #02264a;

  &:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
`;

export default TextButton;