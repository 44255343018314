import apiCalls from 'config/apiCalls';
import React from 'react';
import Loading from 'Services/Loading';
import Grafico from '../Case/Grafico';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import DefaultTitle from 'components/default-title';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

class Storytelling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      normalized_total_average: null,
      general_result_text: '',
      difference_text: '',
      analise: '',
      loading: false,
      resultExplanationPhrase: ''
    }
  }

  async componentDidMount() {
    const { idParticipante, idDinamica } = this.props;
    this.setState({ loading: true });
    try {
      const response = await apiCalls.resultados.dinamicas.getResultStorytelling(idParticipante, idDinamica);

      this.setState({
        normalized_total_average: response.data.normalized_total_average,
        general_result_text: response.data.general_result_text,
        difference_text: response.data.difference_text,
        resultExplanationPhrase: response.data.result_explanation_phrase,
        analise: response.data.whats_beeing_measured_text
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { normalized_total_average, general_result_text, difference_text, loading, analise } = this.state;
    return (
      <div style={{ fontSize: 12 }}>
        {
          loading
            ?
            <Loading />
            :
            <>
              <p>{this.state.resultExplanationPhrase}</p>

              {(normalized_total_average !== null && isNaN(normalized_total_average) === false) ? (
                <Grafico series={[normalized_total_average]}
                  id={`analytical-thinking-chart-${this.props.idDinamica}`} />
              ) : ''}

              {general_result_text ? (
                <div>
                <WhatsBeingMeasured text={analise} />
              </div>
              ) : ''}

              <div style={{ marginTop: 10 }}>
                {general_result_text ? (
                  <>
                    <DefaultTitle>Resultado</DefaultTitle>
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(general_result_text) }}></p>
                  </>
                ) : ''}

                {difference_text ? (
                  <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(difference_text) }}></p>
                ) : ''}
              </div>

              {(normalized_total_average === null || isNaN(normalized_total_average) || !general_result_text) ? (
                <NoResultsFound />
              ) : ''}
            </>
        }
      </div>
    );
  }
}

export default Storytelling;
