import React, { Component } from 'react';
import { Tooltip } from 'react-tippy';
import { FaRegQuestionCircle } from "react-icons/fa";
import { Row, Col } from 'reactstrap';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import InstructionsModal from 'views/Components/InstructionsModal';
import HvGraph from 'views/Pages/Assets/ModalResultado/Resultados/components/HvGraph';
import Folder from 'views/Pages/Assets/Folder';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const NoResponse = styled.div`
  color: #F00;
`;

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

 /**
 * ### Componente de Resultados de História de Vida
 *
 * /**
 * @author Gustavo Carvalho Silva
 * @since 01/12/2020
 * @props
 * - idProjeto
 * - idParticipante
 * - idAssincrono
 * - idInstrumento
 *
 * @desc Monta os resultados de um instrumento do tipo História de Vida
 */
export default class HistoriaDeVida extends Component {
  constructor (props) {
    super(props);
    this.state = {
      termsModal: false,
      resposta: "",
      userResults: null,
      loading: false,
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);

      let resposta = response.data;

      const userResults = await apiCalls.assincronos.getParticipantHvResults({ participantId: idParticipante, assincId: idAssincrono });
      const { results } = userResults.data
      this.setState({ resposta: resposta, userResults: results && results[0] ? results[0] : null });

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  toggleTermsModal = () => {
    this.setState({ termsModal: !this.state.termsModal })
  }

  render() {
    const {
      resposta
    } = this.state;

    return (
      <>
        <InstructionsModal
          size='lg'
          isOpen={this.state.termsModal}
          modalHeader={'Termos importantes para compreensão dos resultados'}
          modalBody={
            <div style={{ fontSize: 12 }}>
              {this.state.userResults ? <p dangerouslySetInnerHTML={{ __html: this.state.userResults.resultExplanationTextFound }}></p> : <p></p>}
            </div>
          }
          onCancel={this.toggleTermsModal}
          toggle={this.toggleTermsModal}
        />

        {
          this.state.loading
          ?
          <Loading />
          :
          <>
            {
              this.state.userResults
              ?
              <>
              <Folder
                key={'hv-1'}
                show={true}
                nome={"Resultados"}
              >
                <Row>
                  <Col>
                    <HvGraph
                      participantId={this.props.idParticipante}
                      assincId={this.props.idAssincrono}
                      id={`hv-graph-${this.props.idInstrumento}-${this.props.idAssincrono}`}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <p style={{ fontSize: 18, fontWeight: 'bold' }}>O que está sendo analisado</p><br />
                  <p dangerouslySetInnerHTML={{ __html: this.state.userResults.whatsBeingMeasuredTextFound }}></p>
                </Row>
                <br />
                <Row style={{ flexDirection: 'column' }}>
                  <p style={{ fontSize: 18, fontWeight: 'bold' }}>Resultado <Tooltip
                    position="top"
                    size="small"
                    trigger="mouseenter"
                    arrow="true"
                    title={'Termos importantes para compreensão dos resultados'}
                  ><FaRegQuestionCircle style={{ cursor: 'pointer' }} onClick={this.toggleTermsModal} size={14}  /></Tooltip></p><br />
                  <span style={{ fontWeight: 'bold', fontSize: 12 }}>Diversidade Lexical</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Grupo: {Number(this.state.userResults.graph_cl_score).toFixed(2).replace('.', ',')}</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Mercado: {Number(this.state.userResults.raw_cl_score).toFixed(2).replace('.', ',')}</span><br />
                  {this.state.userResults.diversidadeGeralTextFound ? <>
                  <p dangerouslySetInnerHTML={{ __html: this.state.userResults.diversidadeGeralTextFound && this.state.userResults.diversidadeGeralTextFound.text ? this.state.userResults.diversidadeGeralTextFound.text.replaceAll('${participant_name}', this.props.nomeParticipante) : '<p></p>' }}></p><br /></> : null}

                  <span style={{ fontWeight: 'bold', fontSize: 12 }}>Estética Textual</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Grupo: {Number(this.state.userResults.graph_et_score).toFixed(2).replace('.', ',')}</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Mercado: {Number(this.state.userResults.raw_et_score).toFixed(2).replace('.', ',')}</span><br />
                  {this.state.userResults.esteticaGeralTextFound ? <>
                  <p dangerouslySetInnerHTML={{ __html: this.state.userResults.esteticaGeralTextFound && this.state.userResults.esteticaGeralTextFound.text ? this.state.userResults.esteticaGeralTextFound.text.replaceAll('${participant_name}', this.props.nomeParticipante) : '<p></p>' }}></p><br /></> : null}

                  <span style={{ fontWeight: 'bold', fontSize: 12 }}>Clareza de Argumentação</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Grupo: {Number(this.state.userResults.graph_ca_score).toFixed(2).replace('.', ',')}</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Mercado: {Number(this.state.userResults.raw_ca_score).toFixed(2).replace('.', ',')}</span><br />
                  {this.state.userResults.clarezaGeralTextFound ? <>
                  <p dangerouslySetInnerHTML={{ __html: this.state.userResults.clarezaGeralTextFound && this.state.userResults.clarezaGeralTextFound.text ? this.state.userResults.clarezaGeralTextFound.text.replaceAll('${participant_name}', this.props.nomeParticipante) : '<p></p>' }}></p><br /></> : null}

                  <span style={{ fontWeight: 'bold', fontSize: 12 }}>Assertividade de Comunicação</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Grupo: {Number(this.state.userResults.graph_ac_score).toFixed(2).replace('.', ',')}</span>
                  <span style={{ fontSize: 12 }}>Nota referente ao Mercado: {Number(this.state.userResults.raw_ac_score).toFixed(2).replace('.', ',')}</span><br />
                  {this.state.userResults.assertividadeGeralTextFound ? <>
                  <p dangerouslySetInnerHTML={{ __html: this.state.userResults.assertividadeGeralTextFound && this.state.userResults.assertividadeGeralTextFound.text ? this.state.userResults.assertividadeGeralTextFound.text.replaceAll('${participant_name}', this.props.nomeParticipante) : '<p></p>' }}></p><br /></> : null}
                </Row>
                </Folder>
                <Folder
                  key={'hv-2'}
                  show={false}
                  nome={"Visualizar Texto"}
                >
                  <Row>
                    <div style={{ fontSize: 12, paddingRight: 15, width: '100%', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(resposta)}}></div>
                  </Row>
                </Folder>
              </>
              :
              <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
            }
          </>
        }
      </>
    )
  }
}