import React, { Component } from 'react';
// import getRadarGraph from './RadarGraph';
import { normalizarPorMaximo } from '../../Services/utilsFn';
import CareerAnchorsChart from './CareerAnchorsChart/CareerAnchorsChart';
/**
 * Gera o gráfico de ancora de carreira
 *
 * @export
 * @class AncoraDeCarreiraGraph
 * @extends {Component}
 */
export default class AncoraDeCarreiraGraph extends Component {
  getGraphData() {
    const dataArray = [
      {
        name: 'Autoavaliação',
        data: [
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaAI']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaSE']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaCE']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaGG']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaTF']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaSD']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaDP']) || 0,
              ),
          ) / 100,
          Math.round(
            100 *
              normalizarPorMaximo(
                42,
                (this.props.resposta && this.props.resposta['somaEV']) || 0,
              ),
          ) / 100,
        ],
      }
    ];

    if (this.props.respondentes && this.props.respondentes.length) {
      this.props.respondentes
        .filter(respondente => respondente.resposta)
        .map(respondente => {
          dataArray.unshift({
            name: `Respondente ${respondente.nome}`,
          data: [
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaAI']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaSE']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaCE']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaGG']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaTF']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaSD']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaDP']),
            ) / 100,
            Math.round(
              100 * normalizarPorMaximo(42, respondente.resposta['somaEV']),
            ) / 100,
          ],
          });
        });
    }

    return dataArray;
  }

  render() {
    return (
      <div>
        <CareerAnchorsChart id={`career-anchors-${this.props.idAssincrono}`}
          rodadasData={this.getGraphData()}
          title='Âncoras da carreira' />
      </div>
    );
  }
}
