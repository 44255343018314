import React from 'react';
import Chart from "chart.js";
import 'chartjs-plugin-annotation';

import * as ArticulationChartService from './services';
import { notifyError } from 'Services/Notificacoes';
import utils from './ArticulationArtChartUtils';
import Messages from 'static/Messages';
import { normalizarPorMaximo } from 'Services/utilsFn';
import ErrorMessage from 'views/Components/error-message';

export default class ArticulationArtChart extends React.Component {

  state = {
    userResult: null,
    error: false
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    this.setState({ error: true });
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, utils.graphConfig());
  }

  drawParticipant(state) {
    const datasets = utils.drawParticipant({
      respondentes: this.props.respondentes,
      resultado: this.props.resultado,
      idConsultor: this.props.idConsultor,
      chartInstance: this.chart
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  drawGraph(state) {
    return new Promise((resolve, _reject) => {
      this.draw(state);
      this.drawParticipant(state);
      resolve();
    });
  }

  componentDidMount() {
    let state = {};

    // ArticulationChartService.loadUserResult({
    //   idProjeto: this.props.projectId,
    //   idParticipante: this.props.participantId,
    //   idAssincrono: this.props.assincronoId,
    //   idInstrumento: this.props.instrumentId,
    // })
    // .then(res => {
    //   state.userResult = res;
    // })
    // .catch(err => {
    //   this.handleError(err);
    //   notifyError(Messages.Assincronos.msgCannotMountGraph);
    // }).finally(_ => {
      this.setState({ state });
      const canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };

      this
        .drawGraph(state)
        .catch(err => {
          this.handleError(err);
          notifyError(Messages.Assincronos.msgCannotMountGraph);
        })
    // });
  }

  getData = () => {
    const respondentesFiltrados = this.props.respondentes.filter(
      respondente => respondente.resultado,
    );
    return [
      {
        name: 'Autoavaliação',
        nota: normalizarPorMaximo(198 - 21, this.props.resultado.somaBruta - 21),
        graficLowLabel: 'Baixa articulação',
        graficHighLabel: 'Alta articulação'
      },
    ].concat(
      respondentesFiltrados.map(respondente => ({
        name: this.props.isConsultor
          ? `${respondente.nome} ${respondente.relacao}`
          : respondentesFiltrados.length >= 3
          ? `${respondente.relacao}`
          : 'Geral',
        nota: normalizarPorMaximo(
          198 - 21,
          respondente.resultado.somaBruta - 21,
        ),
      })),
    );
  };

  render() {

    if (this.state.error) return <ErrorMessage message={Messages.Assincronos.msgCannotMountGraph} />

    return <div style={{
      height: this.getData().length === 1 ? 150 :
        1 + (this.getData().length * 80),
      marginTop: 25
    }}><canvas
      id={this.props.id} /></div>;
  }
}