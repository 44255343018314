import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { BreadCrumbContainer, BreadCrumbItem } from "./styles";

const Breadcrumb = ({ ignorePaths }) => {
  const location = useLocation();
  const [paths, setPaths] = useState([]);
  const [customLocation, setCustomLocation] = useState(location);

  useEffect(() => {
    const { pathname } = location;

    let pathArray = pathname.split("/");

    for (const p of pathArray) {
      if (p.match(/^[0-9a-fA-F]{24}$/)) {
        pathArray = pathArray.filter((pOpt) => pOpt !== p);
      }
    }

    if (ignorePaths && ignorePaths.length) {
      ignorePaths.forEach(ignorePath => {
        pathArray = pathArray.filter((pOpt) => pOpt !== ignorePath);
      });
    }

    const pathArrayWithoutFirst = pathArray.slice(1);

    const links = [];

    links.push(pathArrayWithoutFirst[0]);

    pathArrayWithoutFirst.reduce((prev, curr) => {
      const result = `${prev}/${curr}`;
      links.push(result);
      return result;
    });

    let locationArray = location.pathname.split("/");

    for (const l of locationArray) {
      if (l.match(/^[0-9a-fA-F]{24}$/)) {
        locationArray = locationArray.filter((pOpt) => pOpt !== l);
      }
    }
    const newLocation = [];

    locationArray.reduce((prev, curr) => {
      const result = `${prev}/${curr}`;
      newLocation.push(result);
      return result;
    });

    setCustomLocation(newLocation[newLocation.length - 1]);
    setPaths(links);
  }, [location.pathname]);

  return (
    <BreadCrumbContainer>
      <ul>
        {paths.length &&
          paths.map((pathname, index) => {
            let splitedPath = pathname.split("/");
            let value = splitedPath[splitedPath.length - 1];
            value = value.replace('-', ' ');

            return (
              <BreadCrumbItem key={index}>
                {`/${pathname}` === customLocation ? (
                  value
                ) : (
                  <Link to={`/${pathname}`}>
                    {value}
                  </Link>
                )}
              </BreadCrumbItem>
            );
          })}
      </ul>
    </BreadCrumbContainer>
  );
};

export default Breadcrumb;
