const DrawDoughnutLabelsPlugin = () => {

    return {
    afterDraw: function(chart) {
        var ctx = chart.chart.ctx;

        var fontStyle = 'Open sans';
        var color =  '#666';

        ctx.font = "12px " + fontStyle;

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = color;

        ctx.fillText('0', 160, 225);
        ctx.fillText('100', 340, 225);
    }
  }
  }

  module.exports = DrawDoughnutLabelsPlugin;
