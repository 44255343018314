import React from "react";
import styled from "styled-components";
import logo from "../Assets/images/formare.png";
import Politics from "views/Components/Politics";


const Ft = styled.footer`
  .logo {
    filter: invert(1);
  }
  width: 100%;
  background: #f0f3f5;
  // border-top: 1px solid #c8ced3;
`;

export const Logo = styled.img`
  width: 150px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Ft className="text-center">
      <footer>
        <small>
          <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
        </small>
      </footer>
    </Ft>
  );
}
