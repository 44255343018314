import React, { Component } from 'react';
import _ from 'lodash';
import { Tooltip } from 'react-tippy';
import { FaRegQuestionCircle } from "react-icons/fa";
import Folder from "views/Pages/Assets/Folder";
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import { assincronosApi } from 'Services/api';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import ArteDaArticulacao from './Graficos/ArteDaArticulacaoGraph';
import ArticulationArtChart from './Graficos/ArticulationArtChart';
import NoResultsFound from '../Components/NoResultsFound';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

/**
* ### Componente de Resultados de Arte da Articulação
*
*
* @author Gustavo Carvalho Silva
* @since 01/12/2020
* @props
* - idProjeto
* - idParticipante
* - idAssincrono
* - idInstrumento
*
* @desc Monta os resultados de um instrumento do tipo Arte da Articulação
*/
export default class LocusDeControle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultado: {},
      respondentes: [],
      textosAutoAvaliado: '',
      loading: false,
    }
  }

  async getTextoFromNota(nota) {
    const stops = [39, 74, 109, 144, 179, 198];
    let result = 39;
    stops.reverse().forEach(stop => {
      if (stop > nota) {
        result = stop;
      }
    });

    let res = {};
    if (this.props.participantViewResults) {
       res = await assincronosApi.get(
        `/resultado/arte-da-articulacao/texto-participante/${result}`,
      );
    } else {
      res = await assincronosApi.get(
        `/resultado/arte-da-articulacao/texto-consultor/${result}`,
      );
    }
    return res.data;
  }

  async getInfoRespondente() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      const response = await apiCalls.resultados.assincronos.getInfoInstrumentoAnswerer(idProjeto, idParticipante, idAssincrono, idInstrumento);

      const { respondentes } = response.data;

      let respArray = [];
      respondentes.map(r => {
        if (r.resposta && r.resposta.finished) {
          const obj = {
            nome: r.nome,
            relacao: r.relacao,
            resultado: {
              somaBruta: r.resposta && r.resposta.somaBruta,
              somaNormalizada: r.resposta && r.resposta.somaNormalizada,
              finished: r.resposta && r.resposta.finished,
              finishedAt: r.resposta && r.resposta.finishedAt
            }
          }

          respArray.push(obj);
        }
      });

      if (respArray) {
        respArray = _.orderBy(respArray, ['relacao'], ['desc'])
        this.setState({ respondentes: respArray });
      }

    } catch(err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);
      let resultado = response.data;

      if (resultado.length > 0) {
        const textosAutoAvaliado = await this.getTextoFromNota(
          resultado[0].somaBruta,
        );
        this.setState({
          resultado: resultado[0],
          textosAutoAvaliado
        });
      }

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false })
    }
  }

  async componentDidMount() {
    await this.getInfoInstrumento();
    await this.getInfoRespondente();
  }

  render() {
    const {
      idInstrumento,
      idAssincrono
    } = this.props;

    const {
      resultado,
      respondentes,
      textosAutoAvaliado
    } = this.state;

    return (
      <>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                resultado.somaBruta
                  ?
                  <>
                    <ArticulationArtChart
                      id={`${idAssincrono}-${idInstrumento}`}
                      resultado={resultado}
                      respondentes={respondentes}
                    />
                  </>
                  :
                  <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
              }

                <Folder
                question={true}
                title={'O resultado da sua autoavaliação não considera a avaliação dos respondentes.'}
                key={'arte-articulacao-results-texts'}
                show={true}
                nome={'Autoavaliação'}>
                      {
                        resultado.somaBruta ?
                        <>
                          <p>
                            <h3 style={{ fontSize: 18, fontWeight: 'bold', marginTop: 20 }}>Resultado</h3>
                          </p>
                          <TextHMTL dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textosAutoAvaliado.texto) }}></TextHMTL>
                        </> : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                      }
                </Folder>
            </>
        }
      </>
    )
  }
}