import { io } from 'socket.io-client';

const ReportSocketConnection = {

  getConnection(url) {
    return io(url, {
      transports: ["websocket"],
      upgrade: false
    }, {
      "force new connection": true
    });
  }
}

export default ReportSocketConnection;