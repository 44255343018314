import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import {
  Card,
  Col,
  Row,
  Container,
  Badge,
  ListGroupItem,
  ListGroup,
  ListGroupItemHeading,
} from "reactstrap";
import { notifyError } from 'Services/Notificacoes';
import styled from "styled-components";
import Loading from 'Services/Loading';

import { capitalizeFirstLetterEachWord } from "utils/masks";

const Wrapper = styled.div`
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }

  h5 {
    font-size: 12px;
    font-weight: 600;
  }

  .list--group {
    display: flex;
    justify-content: space-between;
  }

  .badge {
    align-self: self-end;
    margin-left: 5px;
  }

  .padding--respostas {
    padding-bottom: 6px;
    padding-left: 12px;
  }

  .card {
    height: 100%;
    /* padding: 10px; */
  }

  .container {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .row--margin-top {
    margin-top: 15px;
  }
`;

const CaseChoicesContainer = styled(Col)`
margin-top: 10px;
margin-bottom: 10px;
`;

const ParagrafoComHifen = styled.p`
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;
/**
 * ### Componente de Resultados do Case
 *
 * @props
 *
 * - idProjeto
 * - idParticipante
 * - idDinamica
 * - rodada
 *
 * @desc Baseado nas props, busca os resultados de um determinado participante no Case.
 */
export default class InfoCase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      criteriosIndividual: [],
      criteriosSecretario: [],
      criteriosIndividual2: [],
      escolhasGrupo: [],
      escolhasIndividuais: [],

      loading: false,
    };
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idParticipante, idProjeto, idDinamica, rodada, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.dinamicas.getInfoCase(idParticipante, idProjeto, idDinamica, rodada, idInstrumento);

      const { criteriosIndividual, criteriosSecretario, criteriosIndividual2, escolhasGrupo, escolhasIndividuais } = response.data;

      this.setState({
        criteriosIndividual: criteriosIndividual ? criteriosIndividual : [],
        criteriosSecretario: criteriosSecretario ? criteriosSecretario : [],
        criteriosIndividual2: criteriosIndividual2 ? criteriosIndividual2 : [],
        escolhasGrupo: escolhasGrupo ? escolhasGrupo : [],
        escolhasIndividuais: escolhasIndividuais ? escolhasIndividuais : [],
      });

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {

    const {
      criteriosIndividual,
      criteriosSecretario,
      criteriosIndividual2,
      escolhasGrupo,
      escolhasIndividuais
    } = this.state;

    return (
      <Wrapper>
        <Container className="themed-container" fluid="xl">
          {
            this.state.loading
              ?
              <Loading />
              :
              <Row className="row--margin-top">
                <>
                  <Col sm="4">
                    <Card>
                      <CaseChoicesContainer>
                        <Row>
                          <Col>Individuais</Col>
                        </Row>
                        <ListGroup>
                          <ListGroupItemHeading>
                            Critérios da rodada
                          </ListGroupItemHeading>
                          <Container>
                            {criteriosIndividual.length > 0 ? (
                              criteriosIndividual.map((c, cIndex) => (
                                <ListGroupItem
                                  key={cIndex}
                                  className="list--group"
                                  style={{ flexDirection: "row" }}
                                >
                                  <ParagrafoComHifen style={{
                                    width: '100%',
                                  }}>{c.value}</ParagrafoComHifen>
                                  <Badge className="badge" pill>
                                    {c.ScoreNormalizado}
                                  </Badge>
                                </ListGroupItem>
                              ))
                            ) : (
                                <Row>
                                  <Col>
                                    <p style={{ color: "red" }}>
                                      Não existem criterios
                                    </p>
                                  </Col>
                                </Row>
                              )}
                          </Container>
                        </ListGroup>
                      </CaseChoicesContainer>
                    </Card>
                  </Col>

                  <Col sm="8">
                    <Row>
                      <Col sm="6">
                        <Card>
                          <CaseChoicesContainer>
                            <Row>
                              <Col>Secretário</Col>
                            </Row>
                            <ListGroup>
                              <ListGroupItemHeading>
                                Critérios da rodada
                        </ListGroupItemHeading>
                              <Container>
                                {criteriosSecretario.length > 0 ? (
                                  criteriosSecretario.map((c, cIndex) => (
                                    <ListGroupItem
                                      key={cIndex}
                                      className="list--group"
                                      style={{ flexDirection: "row" }}
                                    >
                                      <ParagrafoComHifen style={{
                                        width: '100%',
                                      }}>{c.value}</ParagrafoComHifen>
                                      <Badge className="badge" pill>
                                        {c.ScoreNormalizado}
                                      </Badge>
                                    </ListGroupItem>
                                  ))
                                ) : (
                                    <Row>
                                      <Col>
                                        <p style={{ color: "red" }}>
                                          Não existem criterios
                                </p>
                                      </Col>
                                    </Row>
                                  )}
                              </Container>
                            </ListGroup>
                          </CaseChoicesContainer>
                        </Card>
                      </Col>
                      <Col sm="6">
                        <Card>
                          <CaseChoicesContainer>
                            <Row>
                              <Col>Pós discussão</Col>
                            </Row>
                            <ListGroup>
                              <ListGroupItemHeading>
                                Critérios da rodada
                              </ListGroupItemHeading>
                              <Container>
                                {criteriosIndividual2.length > 0 ? (
                                  criteriosIndividual2.map((c, cIndex) =>
                                    c ? (
                                      <ListGroupItem
                                        key={cIndex}
                                        className="list--group"
                                        style={{ flexDirection: "row" }}
                                      >
                                        <ParagrafoComHifen style={{
                                          width: '100%',
                                        }}>{c.value}</ParagrafoComHifen>
                                        <Badge className="badge" pill>
                                          {c.ScoreNormalizado}
                                        </Badge>
                                      </ListGroupItem>
                                    ) : null
                                  )
                                ) : (
                                    <Row>
                                      <Col>
                                        <p style={{ color: "red" }}>
                                          Não existem criterios
                                </p>
                                      </Col>
                                    </Row>
                                  )}
                              </Container>
                            </ListGroup>
                          </CaseChoicesContainer>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="row--margin-top">
                      <Col style={{ display: "inline-grid" }}>
                        <Card>
                          <CaseChoicesContainer>
                            <Row className="padding--respostas">
                              <Col>Decisão do grupo</Col>
                            </Row>
                            <Container>
                              {escolhasGrupo.length > 0 ? (
                                escolhasGrupo.map(
                                  (escolhas, eIndex) =>
                                    escolhas &&
                                    escolhas.value && (
                                      <ListGroupItem key={eIndex}>
                                        <p style={{ margin: "auto" }}>
                                          {`${capitalizeFirstLetterEachWord(escolhas.value.toLowerCase())} `}
                                        </p>
                                      </ListGroupItem>
                                    )
                                )
                              ) : (
                                  <Row>
                                    <Col>
                                      <p style={{ color: "red" }}>
                                        Não existem escolhas
                              </p>
                                    </Col>
                                  </Row>
                                )}
                            </Container>
                          </CaseChoicesContainer>
                        </Card>
                      </Col>

                      <Col style={{ display: "inline-grid" }}>
                        <Card>
                          <CaseChoicesContainer>
                            <Row className="padding--respostas">
                              <Col>Decisão individual</Col>
                            </Row>
                            <Container>
                              {escolhasIndividuais.length > 0 ? (
                                escolhasIndividuais.map(
                                  (escolhas, eIndex) =>
                                    escolhas &&
                                    escolhas.value && (
                                      <ListGroupItem key={eIndex}>
                                        <p style={{ margin: "auto" }}>
                                          {`${capitalizeFirstLetterEachWord(escolhas.value.toLowerCase())} `}
                                        </p>
                                      </ListGroupItem>
                                    )
                                )
                              ) : (
                                  <Row>
                                    <Col>
                                      <p style={{ color: "red" }}>
                                        Não existem escolhas
                                      </p>
                                    </Col>
                                  </Row>
                                )}
                            </Container>
                          </CaseChoicesContainer>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </>
              </Row>
          }
        </Container>
      </Wrapper>
    )
  }
}
