import React, { Component } from "react";
import Header from './Assets/header';
import { Main } from './Assets/Assets';
import Case from './Case';
import CaseGrupo from './CaseGrupo';

class PreviewCases extends Component {

  state = {
    instrumentoAtual: null,
    template: {
      nomeCase: ''
    },
    textos: {
      grupo: '',
      individual: ''
    },
    itemsNav: [],
    headerItemsClass: 'individual'
  }

  handleNavigateCase = (e) => {
    this.setState({ instrumentoAtual: <Case template={this.state.template} textos={this.state.textos} />, headerItemsClass: 'individual' });
  }
  handleNavigateCaseGrupo = (e) => {
    this.setState({ instrumentoAtual: <CaseGrupo template={this.state.template} textos={this.state.textos} />, headerItemsClass: 'grupo' });
  }

  componentDidMount = async () => {
    const textIndex = new URLSearchParams(this.props.location.search).get("textIndex");

    this.setState({
      instrumentoAtual: <Case />,
      itemsNav: [
        { label: `Textos Individuais - ${Number(textIndex) + 1}`, onClick: this.handleNavigateCase, className: 'individualLabel' },
        { label: `Textos Grupo - ${Number(textIndex) + 1}`, onClick: this.handleNavigateCaseGrupo, className: 'grupoLabel' },
      ],
    });
  }

  render() {
    return (
      <div>
        <Header headerClass={this.state.headerItemsClass} items={this.state.itemsNav} />
        <Main>
          { this.state.instrumentoAtual }
        </Main>
      </div>
    );
  }
}

export default PreviewCases;
