import { Colors } from 'utils';

const PLOT_COLORS = [
  '#7f5fa9',
  '#ed5f60',
  '#face3a',
  '#8B8386',
  '#56c5d5',
  '#f17542',
  '#006400',
  '#B8860B',
  '#00FF7F',
  '#363636',
  '#cddd84',
  '#cdc5bb',
  '#a14443',
  '#FF1493',
  '#1c136c',
  '#CD6090',
  '#32CD32',
  '#FF6A6A',
  '#556B2F',
]

const graphConfig = () => ({
  type: 'horizontalBar',
  data: {
    datasets: []
  },
  options: {
    events: [],
    plugins: {
      datalabels: {
        font: {
          weight: 'bold'
        },
        display: true,
        color: 'black',
        formatter: function(value, context) {
          return value.replace('.', ',');
        }
      },
      legend: {
        position: 'bottom'
      },
      title: {
        fontColor: 'black',
        display: true,
        text: 'Raciocínio Lógico e Analítico'
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          display: true,
          drawBorder: true,
        },
        ticks: {
          min: 0,
          max: 100,
          fontColor: 'black',
        }
      }],
      yAxes: [
        {
          align: 'start',
          labels: [
            '',
            '',
          ],
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
          },
          ticks: {
            display: true,
            fontColor: 'black',
            mirror: true,
            padding: 0
          }
        },
      ],
    },
    legend: {
      display: true,
      position: 'bottom'
    },
    title: {
      fontColor: 'black',
      display: true,
      text: 'Raciocínio Lógico e Analítico'
    },
    tooltips: {
      enabled: false
    }
  }
});

function drawParticipant({ result }) {
  let datasets = [];

  const BACKGROUND_COLORS = [
    Colors.setOpacity(PLOT_COLORS[0], 0.6),
    Colors.setOpacity(PLOT_COLORS[1], 0.6),
    Colors.setOpacity(PLOT_COLORS[2], 0.6),
    Colors.setOpacity(PLOT_COLORS[3], 0.6),
    Colors.setOpacity(PLOT_COLORS[4], 0.6),
    Colors.setOpacity(PLOT_COLORS[5], 0.6),
    Colors.setOpacity(PLOT_COLORS[6], 0.6),
    Colors.setOpacity(PLOT_COLORS[7], 0.6),
  ];

  const mercado = result.notaMercado !== 'S/V' ? Number(result.notaMercado).toFixed(2) : 0.00;
  const normalizado = result.normalizado !== 'S/V' ? Number(result.normalizado).toFixed(2) : 0.00;

  datasets.push({
    label: 'Nota Referente ao Grupo',
    data: [normalizado, mercado],
    type: 'horizontalBar',
    backgroundColor: [BACKGROUND_COLORS[1], BACKGROUND_COLORS[0]],
    categoryPercentage: 1,
    barPercentage: 0.8,
    hidden: false,
    hideInLegendAndTooltip: true
  });

  datasets.push({
    label: 'Nota Referente ao Mercado',
    data: [],
    type: 'horizontalBar',
    backgroundColor: [BACKGROUND_COLORS[0]],
    categoryPercentage: 1.0,
    barPercentage: 0.4,
    hidden: false,
    hideInLegendAndTooltip: true
  });

  return datasets;
}

export default {

  graphConfig,
  drawParticipant
}