import React from 'react';
import Chart from "chart.js";

import * as AutoEficaciaService from './services';
import { notifyError } from 'Services/Notificacoes';
import AutoEficaciaGraphUtils from './AutoEficaciaGraphUtils';
import Messages from 'static/Messages';
import ErrorMessage from 'views/Components/error-message';

export default class AutoEficacia extends React.Component {

  state = {
    userResult: null,
    error: false
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    this.setState({ error: true });
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, AutoEficaciaGraphUtils.graphConfig());
  }

  drawParticipant(state) {
    const datasets = AutoEficaciaGraphUtils.drawParticipant({
      result: state.userResult,
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  drawGraph(state) {
    return new Promise((resolve, _reject) => {
      this.draw(state);
      this.drawParticipant(state);
      resolve();
    });
  }

  componentDidMount() {
    let state = {};

    AutoEficaciaService.loadUserResult({
      idProjeto: this.props.projectId, 
      idParticipante: this.props.participantId, 
      idAssincrono: this.props.assincronoId, 
      idInstrumento: this.props.instrumentId,
    })
    .then(res => {
      state.userResult = res;
    })
    .catch(err => {
      this.handleError(err);
      notifyError(Messages.Assincronos.msgCannotMountGraph);
    }).finally(_ => {
      this.setState({ state });
      const canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };

      this
        .drawGraph(state)
        .catch(err => {
          this.handleError(err);
          notifyError(Messages.Assincronos.msgCannotMountGraph);
        })
    });
  }

  render() {

    if (this.state.error) return <ErrorMessage message={Messages.Assincronos.msgCannotMountGraph} />

    return <div style={{ height: 300, marginTop: 25 }}><canvas 
      id={this.props.id} /></div>;
  }
}