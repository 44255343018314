import { Colors } from 'utils';
import { PLOT_COLORS } from 'Services/Constantes';

const BACKGROUND_COLORS = [
  Colors.setOpacity(PLOT_COLORS[0], 0.6),
  Colors.setOpacity(PLOT_COLORS[1], 0.6),
  Colors.setOpacity(PLOT_COLORS[2], 0.6),
  Colors.setOpacity(PLOT_COLORS[3], 0.6),
  Colors.setOpacity(PLOT_COLORS[4], 0.6),
  Colors.setOpacity(PLOT_COLORS[5], 0.6),
  Colors.setOpacity(PLOT_COLORS[6], 0.6),
  Colors.setOpacity(PLOT_COLORS[7], 0.6),
];

const graphConfig = () => ({
  type: 'bar',
  data: {},
  options: {
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
      },
    },
    title: {
      display: true,
      text: 'Eficácia por rodada',
      color: '#23282c',
      font: {
        size: 12,
        weight: 'bold',
        family: 'Montserrat, sans-serif'
      },
    },
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          min: -24,
          max: 24,
        }
      }]
    },
    responsive: true,
    tooltips: {
      enabled: false
    }
  }
});

function getData({ labels, datasetData, datasetData2 }) {
  let data = [];

  data.labels = labels;
  data.datasets = [
    {
      label: 'Média das notas recebidas',
      data: datasetData,
      backgroundColor: BACKGROUND_COLORS[0]
    },
    {
      label: 'Autoavaliação',
      data: datasetData2,
      backgroundColor: BACKGROUND_COLORS[1]
    },
  ];

  return data;
}

export default {

  graphConfig,
  getData
}