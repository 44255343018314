import apiCalls from 'config/apiCalls';

export default async function verifyModulesAccess(userId, module='') {
  const res = await apiCalls.users.verifyModulesAccess(userId);

  const { canAccess } = res.data;

  if (module) {
    return canAccess.find(m => m.includes(module));
  }

  return canAccess;
}