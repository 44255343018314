import apiCalls from 'config/apiCalls';

async function loadFeedbackText({ userId, assincronoId }) {
  const res = await apiCalls.tiposPersonalidade.getResultTexts({ userId, assincronoId });
  return { 
    feedbackText: res.data.participant_modal_text,
    whatsBeingMeasuredText: res.data.whats_being_measured_text
  };
}

export default loadFeedbackText;