import React from 'react';
import Chart from "chart.js";

import * as TiposPersonalidadeGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import TiposPersonalidadeGraphUtils from './tiposPersonalidadeGraphUtils';
import Messages from 'static/Messages';
import formatAnswer from 'models/tipos-personalidade/formatAnswer';
import normalizeResultValues from 'models/tipos-personalidade/normalizeResultValues';
import getDimensoesMaisPontuadas from 'models/tipos-personalidade/getDimensoesMaisPontuadas';
import getResultsMaisPontuados from 'models/tipos-personalidade/getResultsMaisPontuados';
import ErrorMessage from 'views/Components/error-message';

export default class TiposPersonalidadeGraph extends React.Component {

  state = {
    userResult: null,
    error: false
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    this.setState({ error: true });
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, TiposPersonalidadeGraphUtils.graphConfig());
  }

  getSession(state) {
    return state.sessions
      .filter(session => session)
      .filter(session => session.participante)
      .find(session => session.participante._id === this.props.participantId);
  }

  getPerception(state) {
    const session = this.getSession(state);
    return state.perceptions
      .filter(perception => perception)
      .find(perception => perception.sessao === session._id);
  }

  drawParticipant(state) {
    const datasets = TiposPersonalidadeGraphUtils.drawParticipant({
      result: getResultsMaisPontuados(state.userResult),
      bestResults: getDimensoesMaisPontuadas(state.userResult)[0]
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  drawGraph(state) {
    return new Promise((resolve, _reject) => {
      this.draw(state);
      this.drawParticipant(state);
      resolve();
    });
  }

  componentDidMount() {
    let state = {};

    TiposPersonalidadeGraphService.loadUserResult({
      idProjeto: this.props.projectId,
      idParticipante: this.props.participantId,
      idAssincrono: this.props.assincronoId,
      idInstrumento: this.props.instrumentId,
    })
    .then(res => {
      state.userResult = formatAnswer(res);
      state.userResult = normalizeResultValues(state.userResult);
    })
    .catch(err => {
      this.handleError(err);
      notifyError(Messages.Assincronos.msgCannotMountGraph);
    }).finally(_ => {
      this.setState({ state });
      const canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };

      this
        .drawGraph(state)
        .catch(err => {
          this.handleError(err);
          notifyError(Messages.Assincronos.msgCannotMountGraph);
        })
    });
  }

  render() {

    if (this.state.error) return <ErrorMessage message={Messages.Assincronos.msgCannotMountGraph} />

    return (
      <div style={{ height: 250 }} className='tipos-graph-wrapper-individual'>
         <canvas
         id={this.props.id} />
      </div>);
  }
}