import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel } from '../../Pages/Assets/Assets';
import TextoEstilosDeComunicacao from 'views/Components/TextoEstilosDeComunicacao';

function EstiloComunicacaoTextResultModal(props) {
  const typesObj = {
    racional: 'pn',
    reflexivo: 'i',
    afetivo: 's',
    'pragmático': 'pr',
  }

  function getTextsByType(type='', estilo='', texts=null) {
    if (!texts) return []

    const typeLowered = type.toLowerCase()

    const resumedType = typesObj[typeLowered]

    if (estilo.toLowerCase().includes('stress')) {
      return texts.stressTexts.filter(t => t.type === resumedType)
    } else if (estilo.toLowerCase().includes('fav')) {
      return texts.confortableTexts.filter(t => t.type === resumedType)
    }

    return []
  }

  return(
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      size={'lg'}
    >
      <ModalHeader toggle={() => props.toggle()}>
        { props.answerersGroup } - Resultados
      </ModalHeader>
      <ModalBody>
        { props.results && props.results.texts ? getTextsByType(props.textType, props.estiloType, props.results.texts).map(textObj => (
          <TextoEstilosDeComunicacao
          textObj={textObj} />
        )) : null }
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={ () => {
        props.toggle()
      } }>Fechar</ButtonCancel>
      </ModalFooter>
    </Modal>
  );
}

export default EstiloComunicacaoTextResultModal;