import apiCalls from "config/apiCalls";
import React, { Component } from "react";
import { Estilos } from "views/Pages/Resultados/ModalResultados/ResultadosDinamica/EstilosDeLideranca/Graficos/Estilos";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import Loading from "Services/Loading";
import { estilosPdfUrl } from "Services/Constantes";

import createDOMPurify from 'dompurify';
import styled from "styled-components";
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import DefaultTitle from 'components/default-title';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import { Constantes } from "Services/Constantes";
import api from "Services/api";
import EffectivenessChart from 'views/Pages/Resultados/ModalResultados/AtividadesDinamica/EstilosDeLideranca/Graficos/EffectivenessChart/EffectivenessChart';
import Grafico from 'views/Pages/Resultados/ModalResultados/ResultadosDinamica/Case/Grafico';
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.p`
  text-align: justify;
  font-size: 12px;

  p {
    text-align: justify;
    margin-bottom: 5px;
  }
`;

/**
 * @props idProjeto, idParticipante, idDinamica, rodada
 */
export default class EstilosDeLideranca extends Component {
  constructor(props) {
    super(props);

    // gráfico de eficácia
    this.escala = _.range(-24, 24, 1);

    this.state = {
      series: [],
      areas: [
        "Laissez-Faire",
        "Autoritário",
        "Diretivo",
        "Modelador",
        "Participativo",
        "Desenvolvedor",
        "Afetivo",
      ],
      resultadoIndividual: [],
      graficosEstilos: [],

      normalized_total_average: 0,
      text: '',
      analise: '',

      loading: false,
      resultExplanationPhrase: '',
      effectivenessChart: null
    };
  };

  findEficacia(e) {
    const eficacia = this.escala.findIndex(item => item == e);
    return eficacia;
  }

  countEfficiency(indices, emptyData) {
    for (var i = 0; i < emptyData.length; i++) {
      for (var j = 0; j < indices.length; j++) {
        if (i === indices[j]) {
          emptyData[i]++;
        }
      }
    }
    return emptyData;
  }

  loadSeries(rodadas) {
    let graficoEficacia = rodadas.map((rodada) => {
      return rodada.grafico.series
    })
    graficoEficacia.map((series, index) => {
      series.map((serie, _serieIndex) => {
        if (serie.name && serie.name == "Autoavaliação") {
          let indices = serie.data.map(e => this.findEficacia(e));
          let emptyData = new Array(49).fill(0);
          this.countEfficiency(indices, emptyData);

          this.setState(prevState => ({
            series: [
              ...prevState.series,
              {
                name: `Rodada ${index + 1} - Autoavaliação `,
                data: emptyData
              }
            ]
          }));
        }
        if (serie.name && serie.name == "Grupo") {
          let indices = serie.data.map(e => this.findEficacia(e));
          let emptyData = new Array(49).fill(0);
          this.countEfficiency(indices, emptyData);

          this.setState(prevState => ({
            series: [
              ...prevState.series,
              {
                name: `Rodada ${index + 1} - Grupo `,
                data: emptyData
              }
            ]
          }));
        }
      })
    });
  }

  async getAvg() {
    let avg = 0;

    const res = await api.post(
      `/v3/results/ranking-dinamica`,
      {
        idProjeto: this.props.idProjeto,
        dynamicId: this.props.idDinamica
      }
    );
    const result = res.data.results.find(r => (
      r.instrumentName.toLowerCase().trim() ===
      Constantes.instrumentoEstilos.toLowerCase().trim()
    ));
    if (!result) return NaN;

    let participantResult = result.rankings.find(r => (
      r.userId === this.props.idParticipante
    ));
    if (!participantResult) return NaN;

    avg = participantResult.totalFinalConsultor;

    return avg;
  }

  async loadEstilos(rodadas) {
    let graficos = [];
    let graficosEstilos2 = [];

    rodadas.map((rodada, idx) => {
      if (rodada.autoAvaliacao.radar)
        graficos.push({ name: `Rodada ${idx + 1} - Autoavaliação`, estilos: rodada.autoAvaliacao.radar })
      if (rodada.equipe.radar)
        graficos.push({ name: `Rodada ${idx + 1} - Grupo`, estilos: rodada.equipe.radar })
    });

    for (let idx = 0; idx < rodadas.length; idx++) {
      let rodada = rodadas[idx];
      let avg = await this.getAvg({ roundIndex: idx });

      if (!rodada.autoAvaliacao.radar && !rodada.equipe.radar) continue;

      graficosEstilos2.push({
        name: `Rodada ${idx + 1}`,
        // avg: (rodada.equipe.eficaciaTotalRecebida /
        //   rodada.equipe.votos.length),
        avg,
        series: []
      });

      if (rodada.autoAvaliacao.radar) {
        graficosEstilos2[graficosEstilos2.length - 1].series.push(
          {
            name: `Rodada ${idx + 1} - Autoavaliação`,
            data: [
              rodada.autoAvaliacao.radar.laissezFaire,
              rodada.autoAvaliacao.radar.autoritario,
              rodada.autoAvaliacao.radar.diretivo,
              rodada.autoAvaliacao.radar.modelador,
              rodada.autoAvaliacao.radar.participativo,
              rodada.autoAvaliacao.radar.desenvolvedor,
              rodada.autoAvaliacao.radar.afetivo,
            ]
          },
        )
      }

      if (rodada.equipe.radar) {
        graficosEstilos2[graficosEstilos2.length - 1].series.push({
          name: `Rodada ${idx + 1} - Grupo`,
          data: [
            rodada.equipe.radar.laissezFaire,
            rodada.equipe.radar.autoritario,
            rodada.equipe.radar.diretivo,
            rodada.equipe.radar.modelador,
            rodada.equipe.radar.participativo,
            rodada.equipe.radar.desenvolvedor,
            rodada.equipe.radar.afetivo,
          ]
        })
      }
    }

    this.setState({
      graficosEstilos: graficos,
      graficosEstilos2,
      rodadas,
    });
  }

  initEffectivenessChart(roundsData) {
    let labels = [];
    let datasetData = [];
    let datasetData2 = [];

    for (let idx = 0; idx < roundsData.length; idx++) {

      let round = roundsData[idx];

      if (!round.equipe.radar && !round.autoAvaliacao.radar) continue;

      labels.push(`Rodada ${idx + 1}`);

      if (round.equipe.radar) {
        let avg = parseFloat(round.equipe.eficaciaTotalRecebida /
          round.equipe.votos.length).toFixed(2);

        datasetData.push(avg);
      } else {
        datasetData.push(0);
      }

      if (round.autoAvaliacao.radar) {
        datasetData2.push(round.autoAvaliacao.eficaciaAtribuida.toFixed(2));
      } else {
        datasetData2.push(0);
      }
    }

    this.setState(prev => ({
      effectivenessChart: {
        ...prev.effectivenessChart,
        labels,
        datasetData,
        datasetData2
      }
    }))
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idDinamica } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.dinamicas.getInfoEstilosDeLideranca(idProjeto, idParticipante, idDinamica);

      const { rodadasData } = response.data;
      const { general_result_text } = response.data;
      const { normalized_total_average } = response.data;
      const { result_explanation_phrase } = response.data;

      if (rodadasData) {
        await this.loadEstilos(rodadasData);
        this.loadSeries(rodadasData);
        this.initEffectivenessChart(rodadasData);
      }

      this.setState({
        text: general_result_text.text,
        normalized_total_average,
        resultExplanationPhrase: result_explanation_phrase,
        analise: response.data.whats_beeing_measured_text
      });

    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    // let linkedInEstilos = resultadoIndividual.isVinculado !== false;
    let linkedInEstilos = true;
    const {
      graficosEstilos,
      series,
      areas,
      normalized_total_average,
      text,
      analise,
      graficosEstilos2,
      rodadas,
      effectivenessChart,
      loading
    } = this.state;

    const estilosPlayed = graficosEstilos.length > 0;

    return (
      <div>
        {
          loading
            ?
            <Loading />
            :
            <>
              {
                (!isNaN(normalized_total_average) && text && series && series.length > 0)
                  ?
                  <div>
                    <p>{this.state.resultExplanationPhrase}</p>

                    {
                      !estilosPlayed &&
                      <Row>
                        <Col>
                          <p style={{ color: "red", fontSize: "12px" }}>
                            Ainda não há dados para construção do gráfico de estilos de
                            liderança
                          </p>
                        </Col>
                      </Row>
                    }

                    {
                      !linkedInEstilos &&
                      <Row>
                        <Col>
                          <p style={{ color: "red", fontSize: "12px" }}>
                            O participante foi desvinculado
                          </p>
                        </Col>
                      </Row>
                    }

                    {estilosPlayed && (
                      <Estilos
                        areas={[
                          "Laissez-Faire",
                          "Autoritário",
                          "Diretivo",
                          "Modelador",
                          "Participativo",
                          "Desenvolvedor",
                          "Afetivo",
                        ]}
                        width={"100%"}
                        height={"100%"}
                        valores={graficosEstilos.map((r) => {
                          return {
                            name: r.name,
                            data: [
                              r.estilos.laissezFaire,
                              r.estilos.autoritario,
                              r.estilos.diretivo,
                              r.estilos.modelador,
                              r.estilos.participativo,
                              r.estilos.desenvolvedor,
                              r.estilos.afetivo,
                            ],
                          };
                        })}
                        valores2={graficosEstilos2}
                        tag="results-chart"
                        idInstrumento={this.props.idInstrumento}
                        rodadasData={rodadas}
                      />
                    )}

                    {effectivenessChart ? (
                      <Row style={{ marginTop: '100px' }}>
                        <Col sm="12" md="6">
                          <EffectivenessChart id={`effectiveness-graph-${this.props.idInstrumento}`}
                            participantId={this.props.idParticipante}
                            dynamicId={this.props.idDinamica}
                            instrumentId={this.props.idInstrumento}
                            projectId={this.props.idProjeto}
                            labels={effectivenessChart.labels}
                            datasetData={effectivenessChart.datasetData}
                            datasetData2={effectivenessChart.datasetData2} />
                        </Col>

                        <Col sm="12" md="6">
                          {isNaN(normalized_total_average) === false ? (
                            <Grafico
                              series={[normalized_total_average]}
                              id={`general-styles-avg-chart-${this.props.idInstrumento}`}
                              title="Eficácia total" />
                          ) : ''}
                        </Col>
                      </Row>
                    ) : ''}

                    <>
                      <div>
                        <WhatsBeingMeasured text={analise} />
                      </div>

                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <DefaultTitle>Resultado</DefaultTitle>
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></p>
                        <p><a href={estilosPdfUrl} target="_blank" >{ estilosPdfUrl }</a></p>
                      </div>
                    </>
                  </div>
                  :
                  <NoResultsFound />
              }
            </>
        }
      </div>
    )
  }
}
