import React, { Component, createRef } from "react";
import { FaRegQuestionCircle, FaCheckCircle } from "react-icons/fa";
import { MdBlock } from "react-icons/md"
import { Tooltip } from 'react-tippy';
import { Col, Row } from "reactstrap";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import Icon from '@material-ui/core/Icon';
import "./css/Folder.css";
import styled from "styled-components";

export default class Folder extends Component {
  state = {
    show: false
  };

  static defaultProps = {
    nameContainerStyle: {}
  }


  folderRef = createRef()


  componentDidMount() {
    if (this.props.show) {
      this.setState({ show: true });


      if (this.folderRef.current) {
        this.folderRef.current.scrollIntoView({ block: "center" })
      }
    }
  }

  handleHeaderClick = () => {
    if (this.state.show && this.props.onClose) {
      this.props.onClose();
    }
    if (!this.state.show && this.props.onClick) {
      this.props.onClick();
    }
    this.setState({ show: !this.state.show });
  }

  getIconComponent = (icon) => {
    const icons = {
      'expired': <><MdBlock size={12} color={'#f00'} /></>,
      'notStarted': <><FaCheckCircle size={12} color={'#cecece'} /></>,
      'finished': <><FaCheckCircle size={12} color={'#0f0'} /></>
    }

    return icons[icon] || <></>
  }

  getIconTooltip = (icon) => {
    const icons = {
      'expired': 'Atividade não entregue (expirada)',
      'notStarted': 'Atividade não entregue mas ainda no prazo',
      'finished': 'Atividade entregue no prazo'
    }

    return icons[icon] || <></>
  }

  render() {
    const component = () => (
      <Row className="row-centered folder without-margin">
        <span ref={this.folderRef}></span>
        <Row
          onClick={this.handleHeaderClick}
          className="without-margin folder-collapse"
          style={{
            minWidth: "min-content",
            width: "100%",
            cursor: "pointer",
            alignItems: "center"
          }}
        >
          {this.state.show ?
            <Icon className="fa fa-chevron-down" /> :
            <Icon className="fa fa-chevron-right" />
          }
          <span
            style={{
              marginLeft: "15px",
              ...this.props.nameContainerStyle
            }}
          >
            {this.props.nome}{this.props.question ? <Tooltip
                position="right"
                size="small"
                trigger="mouseenter"
                arrow="true"
                title={this.props.title}
              ><FaRegQuestionCircle style={{ marginLeft: 5, marginBottom: 2 }} size={11}  /></Tooltip> : null}
              { this.props.icon ? <Tooltip
                position="top"
                size="small"
                trigger="mouseenter"
                arrow="true"
                title={this.getIconTooltip(this.props.icon)}
              >{ this.getIconComponent(this.props.icon) }</Tooltip> : null }
          </span>
        </Row>
        {/* <Row> */}
        <div
          style={{ width: "min-content" }}
        >
          {this.props.component}
        </div>
        {/* </Row> */}
      </Row>
    );
    if (this.state.show) {
      return (
        <Col sm="12" className="open-folder without-padding">
          {component()}
          <div>{this.props.children}</div>
        </Col>
      );
    }
    return component();
  }
}
