import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";

import { BaseLogin, LogoHome } from "../Assets/Assets";
import { notifyError, notifySuccess } from "../../../Services/Notificacoes";
import "react-toastify/dist/ReactToastify.css";
import "./css/Login.css";
import api from "../../../Services/api";
import { Constantes } from "../../../Services/Constantes";
import apiCalls from 'config/apiCalls';
import Politics from "views/Components/Politics";
import { validatePassword } from "Services/validatePassword";

const LogoUser = require("../../../assets/img/icons/boneco_login.png");
const CadeadoSenha = require("../../../assets/img/icons/cadeado_senha.png");

// Imagens
const spinner = Constantes.imagemSpinner;

class ForgotPassword extends Component {
  state = {
    passwordErrors: [],
    loadingSpinner: false,
    newPassword: '',
    repeatedPassword: '',
    token: '',
    formLoading: false,
    invalidToken: false
  };

  handleInvalidToken = () => {
    this.setState({ invalidToken: true });
    notifyError('Solicite a recuperação de senha novamente.');

    setTimeout(() => {
      return this.props.history.replace('/login');
    }, 6000);
  }

  verifyToken = async token => {
    try {
      const res = await apiCalls.userTokens.validateToken(token);

      return res.data;
    } catch (err) {
      if (err && err.response && err.response.status === 404) return false;
      else {
        // notifyError('Ocorreu um erro ao verificar o token');
        notifyError('Não foi possível verificar o acesso. Por favor, recarregue a página e tente novamente');
        console.error(err);
        return false
      }
    }
  }

  componentDidMount = async () => {
      const token = new URLSearchParams(this.props.location.search).get("token");

      const { isTokenValid } = await this.verifyToken(token);

      if (!isTokenValid) return this.handleInvalidToken();

      this.setState({ token });

  };

  handleChange = e => {
    if (e.target.name.toLowerCase() === 'newpassword') {
      const errors = validatePassword(e.target.value);

      this.setState({ passwordErrors: errors });
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  validatePasswords = () => {
    return this.state.newPassword === this.state.repeatedPassword;
  }

  changeUserPassword = async () => {
    this.setState({ formLoading: true });
    try {
      await apiCalls.users.modifyUserPassword(this.state.token, this.state.newPassword);

      notifySuccess('Senha atualizada com sucesso. Você será redirecionado para a tela de login.');

      setTimeout(() => {
        return this.props.history.replace('/login');
      }, 6000);
    } catch(err) {
      console.error(err);
      notifyError('Não foi possível atualizar a senha. Por favor, tente novamente');
      this.setState({ formLoading: false });
    }
  }

  handleForm = async e => {
    e.preventDefault();
    if (this.state.formLoading || this.state.invalidToken) return;

    if (!this.state.newPassword) return notifyError('Informe a nova senha');

    if (this.state.passwordErrors.length) return notifyError('Senha não atende os requisitos.')

    if (!this.validatePasswords()) return notifyError('Senha repetida incorreta');

    this.setState({ formLoading: true });

    const { isTokenValid } = await this.verifyToken(this.state.token);

    if (!isTokenValid) return this.handleInvalidToken();

    await this.changeUserPassword();
  };

  render() {
    return (
      <div className="app flex-row align-items-center">

        <BaseLogin>
          <div className="app flex-row align-items-center" id="inner-div">
            <br /> <br />
            <Container>
              <Row className="justify-content-center">
                <Col sm="8" className="text-center" offset="6">
                  <LogoHome
                    alt="logo"
                    src={require("../Assets/images/logo_vertical.png")}
                  />
                  <br />
                  <Row>
                    <Col sm={{ size: 6, order: 2, offset: 3 }}>
                      <Form onSubmit={this.handleForm}>
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="icons"
                          >
                            <img src={CadeadoSenha} className="iconsImg" />
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Nova senha"
                            autoComplete="new-password"
                            name="newPassword"
                            onChange={this.handleChange}
                            style={{
                              backgroundColor: "#5b654e",
                              color: "white",
                              borderColor: "#5b654e"
                            }}
                            required
                            className="input-login"
                          />
                        </InputGroup>
                        <br />
                        {
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {this.state.passwordErrors.map(error => {
                              return (
                                <>
                                  <span style={{ fontSize: 11, fontWeight: 'bold', color: '#FF5959', fontStyle: 'italic', whiteSpace: 'nowrap' }}>{error}</span>
                                </>
                              )
                            })}
                          </div>
                        }
                        <br />
                        <InputGroup>
                          <InputGroupAddon
                            addonType="prepend"
                            className="icons"
                          >
                            <img src={CadeadoSenha} className="iconsImg" />
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Repita a senha"
                            style={{
                              backgroundColor: "#5b654e",
                              color: "white",
                              borderColor: "#5b654e"
                            }}
                            autoComplete="repeated-password"
                            name="repeatedPassword"
                            onChange={this.handleChange}
                            className="input-login"
                            required
                          />
                        </InputGroup>
                        <br />
                        <Button
                          className="botao"
                          disabled={this.state.loadingSpinner || this.state.formLoading || this.state.invalidToken}
                          style={{
                            backgroundColor: "#694e4a",
                            color: "white",
                            borderBox: "solid #694e4a"
                          }}
                        >
                          ATUALIZAR
                        </Button>

                        <br />
                        {this.state.loadingSpinner && (
                          <img src={require(`../Assets/images/${spinner}`)} />
                        )}
                        <br />
                      </Form>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <footer>
                    <small>
                      <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
                    </small>
                  </footer>
                </Col>
              </Row>
            </Container>
          </div>
        </BaseLogin>
      </div>
    );
  }
}

export default ForgotPassword;
