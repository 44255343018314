import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Foto from '../../Assets/Foto';
import './scss/modalResultadosHeader.scss';
import { maskCPF } from "../../Modulos/utils";
import Loading from "Services/Loading";

import { capitalizeFirstLetterEachWord } from "utils/masks";

const Container = styled(Row)`
  padding-top: 20px;

  h6 {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }

  p {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

/**
 * @props user: {}, loading: boolean
 * @class ModalResultadosHeader
 * @extends {Component}
 *
 * @description header do modal de resultados. Contém a foto, o nome, cargo, telefone e email do usuário
 */
export default class ModalResultadosHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingHeader: true,
    };
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  maskTelefone(string) {
    return string.replace(/(\d{2})(\d{5})(\d{4})/,
      function( regex, arg1, arg2, arg3) {
      return `(${arg1}) ${arg2}-${arg3}`;
      })
  }

  render() {
    return (
      <Container>

        <Col sm="auto">
          {
            this.props.loading
            ?
            <Loading/>
            :
            <Foto
              cliente={this.props.user.nome}
              foto={this.props.user.foto}
              size={100}
            />
          }
        </Col>
        <Col>
          <h5>{this.props.loading ? 'Carregando...' : capitalizeFirstLetterEachWord(this.props.user.nome.toLowerCase())}</h5>
          <div>
            <p>
              <b>Cargo:</b> {this.props.loading ? 'Carregando...' : this.props.user.cargo}
            </p>
            <p>
              <b>Cpf.:</b> {this.props.loading ? 'Carregando...' : maskCPF(`${this.props.user.cpf}`)}
            </p>
            {
              this.props.user.telefone &&
              <p>
                <b>Tel.:</b> {this.props.loading ? 'Carregando...' : this.maskTelefone(this.props.user.telefone)}
              </p>
            }
            <p>
              <b>E-mail:</b> {this.props.loading ? 'Carregando...' : this.props.user.email}
            </p>
          </div>
        </Col>
      </Container>
    );
  }
}
