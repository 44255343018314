import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  text-decoration: underline;
  color: #0070f3;
`

function ParticipantInviteTemplate() {
  return (
    <>
      <p><span><strong>Título do e-mail:</strong> Cadastro na plataforma FormareTech</span><br />
      <span><strong>Remetente:</strong> no-reply@formaretech.com.br</span><br /></p>
      <img src="https://files-storage.s3.amazonaws.com/mail_assets/formaretech-logo-mail.png" alt="FormareTech Logo" /><br />
      <p>Olá, João Goulard</p> <br />
      <p>Parabéns, você foi cadastrado na plataforma da FormareTech pela 'Consultoria Modelo'.</p>
      <p>Para poder acessar as ferramentas e resultados disponibilizados pelo consultor responsável, seus dados de acesso são:</p> <br />
      <p>Site: <StyledSpan>https://iapp.formaretech.com.br</StyledSpan></p>
      <p>Usuário: cpf do participante</p>
      <p>Senha provisória: senha gerada pelo Iapp</p> <br />
      <p>Após o primeiro acesso será solicitado que você altere sua senha.</p>
      <p>Aguarde orientação do consultor sobre os próximos passos.</p><br />
      <p>Qualquer dúvida ou problema, entre em contato conosco através do E-mail: meu_email@consultor.com.br</p> <br />
      <p>Por favor, não responda este e-mail.</p>
      <p>Obrigado!</p>
      <strong>FormareTech</strong>
    </>
  )
}

export default ParticipantInviteTemplate;