import { Colors } from 'utils';
import { PLOT_COLORS } from 'Services/Constantes';

const BACKGROUND_COLORS = [
  Colors.setOpacity(PLOT_COLORS[0], 0.6),
  Colors.setOpacity(PLOT_COLORS[1], 0.6),
  Colors.setOpacity(PLOT_COLORS[2], 0.6),
  Colors.setOpacity(PLOT_COLORS[3], 0.6),
  Colors.setOpacity(PLOT_COLORS[4], 0.6),
  Colors.setOpacity(PLOT_COLORS[5], 0.6),
  Colors.setOpacity(PLOT_COLORS[6], 0.6),
  Colors.setOpacity(PLOT_COLORS[7], 0.6),
];

const graphConfig = () => ({
  type: 'horizontalBar',
  data: {},
  options: {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        formatter: function (value, _context) {
          return '';
        }
      }
    },
    responsive: true,
    scales: {
      xAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false
        },
        ticks: {
          fontColor: 'black',
          max: 100,
          min: -100,
          callback: function (value) {
            return Math.abs(value);
          }
        }
      }],
      yAxes: [
        {
          labels: ['E', 'S', 'T', 'J'],
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false
          },
          ticks: {
            display: true,
            fontColor: 'black',
          }
        },
        {
          labels: ['I', 'N', 'F', 'P'],
          position: 'right',
          type: 'category',
          offset: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false
          },
          ticks: {
            fontColor: 'black'
          }
        }
      ],
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Tipos de Personalidade - Autoavaliação',
      fontColor: 'black'
    },
    tooltips: {
      enabled: false
    }
  }
});

function drawParticipant({ result, bestResults }) {
  let datasets = [];
  let leftData = [];
  let rightData = [];

  leftData.push(result['E'] * -1);
  leftData.push(result['S'] * -1);
  leftData.push(result['T'] * -1);
  leftData.push(result['J'] * -1);

  rightData.push(result['I']);
  rightData.push(result['N']);
  rightData.push(result['F']);
  rightData.push(result['P']);

  let greyColor = 'rgba(0, 0, 0, 0.1)';
  let colors = {
    E: bestResults.indexOf('E') === -1 ? greyColor : BACKGROUND_COLORS[0],
    S: bestResults.indexOf('S') === -1 ? greyColor : BACKGROUND_COLORS[0],
    T: bestResults.indexOf('T') === -1 ? greyColor : BACKGROUND_COLORS[0],
    J: bestResults.indexOf('J') === -1 ? greyColor : BACKGROUND_COLORS[0],
    I: bestResults.indexOf('I') === -1 ? greyColor : BACKGROUND_COLORS[0],
    N: bestResults.indexOf('N') === -1 ? greyColor : BACKGROUND_COLORS[0],
    F: bestResults.indexOf('F') === -1 ? greyColor : BACKGROUND_COLORS[0],
    P: bestResults.indexOf('P') === -1 ? greyColor : BACKGROUND_COLORS[0],
  }

  datasets.push({
    data: leftData,
    type: 'horizontalBar',
    backgroundColor: [colors.E, colors.S, colors.T, colors.J,],
    categoryPercentage: 0.8,
    barPercentage: 0.8,
  });
  datasets.push({
    data: rightData,
    type: 'horizontalBar',
    backgroundColor: [colors.I, colors.N, colors.F, colors.P],
    categoryPercentage: 0.8,
    barPercentage: 0.8,
  });

  return datasets;
}

export default {

  graphConfig,
  drawParticipant
}