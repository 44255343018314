import React from 'react';
import styled from 'styled-components';

const NoResultsBox = styled.div`
  padding-top: 1em;
  font-size: 12px;
  color: red;
`;

export default function NoResultsFound ({ children }) {
  return (
    <NoResultsBox>{children || 'Não há resultados para serem exibidos.'}</NoResultsBox>
  );
}