import axios from "axios";

export default async function listParticipants(projectId, participants) {
  try {
    return await axios.get(
      `${process.env.REACT_APP_RESULTS_API_URL}/results-api/users/project/${projectId}`,
      {
        params: {
          participantsIds: participants
        }
      }
    );
  } catch (err) {
    console.error(err);
  }
}
