import { Colors } from 'utils';
import Chart from "chart.js";
import { PLOT_COLORS } from 'Services/Constantes';

const BACKGROUND_COLORS = [
  Colors.setOpacity(PLOT_COLORS[0], 0.6),
  Colors.setOpacity(PLOT_COLORS[1], 0.6),
  Colors.setOpacity(PLOT_COLORS[2], 0.6),
  Colors.setOpacity(PLOT_COLORS[3], 0.6),
  Colors.setOpacity(PLOT_COLORS[4], 0.6),
  Colors.setOpacity(PLOT_COLORS[5], 0.6),
  Colors.setOpacity(PLOT_COLORS[6], 0.6),
  Colors.setOpacity(PLOT_COLORS[7], 0.6),
];

const graphConfig = ({
	title
}) => ({
	type: 'radar',
	data: {},
	options: {

		tooltips: {
			enabled: true,
			callbacks: {
				title: (item, data) => {
					return '';
				},
				label: (item, data) => {
					const nota = data.datasets[item.datasetIndex].data[item.index];
					const avaliando = data.datasets[item.datasetIndex].label;
					const estilo = data.labels[item.index];
					return `${avaliando}: ${estilo}. Nota: ${nota}`;
				}
			}
		},

		plugins: {
			datalabels: {
				display: false,
			}
		},
		legend: {
			position: 'bottom',
		},
		title: {
			display: true,
			text: 'Estilos de Liderança',
			color: '#23282c',
			font: {
				size: 12,
				weight: 'bold',
				family: 'Montserrat, sans-serif'
			},
		},
		scale: {
			ticks: {
				beginAtZero: true
			}
		}
	},
});

const loadLeadershipStyles = (data) => {
	let graphs = [];

  if (data && data.auto) {
    graphs.push(
      {
        name: 'Autoavaliação',
        estilos: data.auto,
      }
    )
  }

  for (const key in data) {
    if (key !== 'auto') {
      graphs.unshift({ name: key, estilos: data[key] })
    }
  }

	return graphs.map((r) => {
		return {
			name: r.name,
			data: [
				r.estilos.laissezFaire,
				r.estilos.autoritario,
				r.estilos.diretivo,
				r.estilos.modelador,
				r.estilos.participativo,
				r.estilos.desenvolvedor,
				r.estilos.afetivo,
			],
		};
	});
}

function getData({ rodadasData }) {
  let data = [];

	data.labels = ["Laissez-Faire", "Autoritario", "Diretivo", "Modelador", "Participativo", "Desenvolvedor", "Afetivo"];

	const leadershipStylesGraph = loadLeadershipStyles(rodadasData);

	if (!leadershipStylesGraph || !leadershipStylesGraph.length) return {};

	let chartColors = [
		'#7f5fa9',
		'#ed5f60',
		'#face3a',
		'#8B8386',
		'#56c5d5',
		'#f17542',
		'#006400',
		'#B8860B',
		'#00FF7F',
		'#363636',

		'#cddd84',
		'#cdc5bb',
		'#a14443',
		'#FF1493',
		'#1c136c',
		'#CD6090',
		'#32CD32',
		'#FF6A6A',
		'#556B2F',
		'#FFFF00',
	];

	chartColors = chartColors.reverse();

	const color = Chart.helpers.color;
  let colorIndex = 20;

	const datasets = leadershipStylesGraph.map((result, index) => {
		if (colorIndex === 0){
			colorIndex = 20;
		}

		colorIndex += -1;

		return {
			label: result.name,
			backgroundColor: color(chartColors[colorIndex]).alpha(0.2).rgbString(),
			borderColor: chartColors[colorIndex],
			pointBackgroundColor: chartColors[colorIndex] ,
			data: result.data
		}
	})

  data.datasets = datasets;

  return data;
}

export default {

  graphConfig,
  getData
}