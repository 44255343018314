// Base url
let { REACT_APP_ASSINCRONOS_API_URL } = process.env;
export const baseAssincURL = REACT_APP_ASSINCRONOS_API_URL;
// const { REACT_APP_BASE_URL } = process.env

// if (!REACT_APP_BASE_URL) {
//   throw new Error('Necessário informar URL base.')
// }

// export const baseURL = REACT_APP_BASE_URL
