import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Orderer from '../../../../Assets/Orderer';
import Checkbox from '../../../../Assets/CustomCheckbox';

/**
 * O cabeçalho da tabela de vincular participantes. Inclui
 * os campos dinâmicos
 *
 * @export
 * @class HeaderTabelaVincular
 * @extends {Component}
 */
export default class HeaderTabelaVincular extends Component {
  state = {
    /**
     * O método de ordenação atual.
     *
     * Método → Valor
     * * Nome → 'nome'
     * * CPF → 'cpf'
     * * Email → 'email'
     * * Cargo → 'cargo'
     * @type {string}
     */
    active: 'nome',
  };

  /**
   * Define a ordem dos participantes por nome
   *
   * @param {Participante} a
   * @param {Participante} b
   * @returns {0|1|-1} Retorna 0 se os nomes forem iguais, 1 se `a` vier após `b` e -1
   * se `a` vier depois de `b`
   */
  sortByName = (a, b) => {
    this.setState({
      active: 'nome',
    });
    /** O id do projeto atual
     * @type {string}
     */
    const projectID = sessionStorage.getItem('projeto');
    /** O nome do usuário `a` dentro do projeto
     * @type {string}
     */
    const aName = a.projetos.find((p) => p._idProjeto === projectID).nome;
    /** O nome do usuário `b` dentro do projeto
     * @type {string}
     */
    const bName = b.projetos.find((p) => p._idProjeto === projectID).nome;
    if (aName > bName) {
      return 1;
    }
    if (aName < bName) {
      return -1;
    }
    return 0;
  };

  /**
   * Define a ordem dos participantes por email
   *
   * @param {Participante} a
   * @param {Participante} b
   * @returns {0|1|-1} Retorna 0 se os email forem iguais, 1 se `a` vier após `b` e -1
   * se `a` vier depois de `b`
   */
  sortByEmail = (a, b) => {
    this.setState({
      active: 'email',
    });
    /** O id do projeto atual
     * @type {string}
     */
    const projectID = sessionStorage.getItem('projeto');
    /** O email do usuário `a` dentro do projeto
     * @type {string}
     */
    const aEmail = a.projetos.find((p) => p._idProjeto === projectID).email;
    /** O email do usuário `b` dentro do projeto
     * @type {string}
     */
    const bEmail = b.projetos.find((p) => p._idProjeto === projectID).email;
    if (aEmail > bEmail) {
      return 1;
    }
    if (aEmail < bEmail) {
      return -1;
    }
    return 0;
  };

  /**
   * Define a ordem dos participantes por cpf
   *
   * @param {Participante} a
   * @param {Participante} b
   * @returns {0 | 1 | -1} 0 se o cpf for igual,
   * -1 se *b* for anterior a *a* e
   * 1 se *a* for anterior a *b*
   */
  sortByCpf = (a, b) => {
    this.setState({
      active: 'cpf',
    });
    if (Number(a.cpf) > Number(b.cpf)) {
      return 1;
    }
    if (Number(b.cpf) > Number(a.cpf)) {
      return -1;
    }
    return 0;
  };

  componentDidMount() {
    this.props.orderBy(this.sortByName, false);
  }

  sortByCampoAdicional = {
    /**
     * Define a ordem dos participantes por cargo
     *
     * @param {Participante} a
     * @param {Participante} b
     * @returns {0 | 1 | -1} 0 se o cargo for igual,
     * -1 se *b* for anterior a *a* e
     * 1 se *a* for anterior a *b*
     */
    cargo: (a, b) => {
      this.setState({
        active: 'cargo',
      });
      /** O id do projeto atual
       * @type {string}
       */
      const projectID = sessionStorage.getItem('projeto');
      /** O cargo do usuário `a` dentro do projeto
       * @type {string}
       */
      const aCargo = a.projetos.find((p) => p._idProjeto === projectID).cargo;
      /** O cargo do usuário `b` dentro do projeto
       * @type {string}
       */
      const bCargo = b.projetos.find((p) => p._idProjeto === projectID).cargo;
      if (aCargo > bCargo) {
        return 1;
      }
      if (aCargo < bCargo) {
        return -1;
      }
      return 0;
    },
    /**
     * Define a ordem dos participantes por salário
     *
     * @param {Participante} a
     * @param {Participante} b
     * @returns {0 | 1 | -1} 0 se o salário for igual,
     * -1 se *b* for menor que a *a* e
     * 1 se *a* for maior que a *b*
     */
    salario: (a, b) => {
      this.setState({
        active: 'salario',
      });
      /** O id do projeto atual
       * @type {string}
       */
      const projectID = sessionStorage.getItem('projeto');
      /** O salario do usuário `a` dentro do projeto
       * @type {string}
       */
      const aSalario = a.projetos.find((p) => p._idProjeto === projectID)
        .salario;
      /** O salario do usuário `b` dentro do projeto
       * @type {string}
       */
      const bSalario = b.projetos.find((p) => p._idProjeto === projectID)
        .salario;
      if (aSalario > bSalario) {
        return -1;
      }
      if (aSalario < bSalario) {
        return 1;
      }
      return 0;
    },
  };

  render() {
    return (
      <div
        style={{
          display: 'flexbox',
          alignItems: 'center',
          marginBottom: '1vh',
        }}
      >
        <Row className='no-break without-margin'>
          <Col sm='12' className='without-padding'>
            <Row className='without-margin row-centered no-break'>
              <Col sm='1'>
                <Row className='no-break without-margin'>
                  <Col sm='6' className='full-centered'>
                    <Checkbox
                      onClick={this.props.handleSelectAll}
                      checked={this.props.checked}
                      invisible={this.props.invisibleCheckbox}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm='2'>
                <Orderer
                  orderBy={this.props.orderBy}
                  orderFn={this.sortByName}
                  active={this.state.active === 'nome'}
                >
                  Nome
                </Orderer>
              </Col>
              <Col sm='1' className='without-padding cpf'>
                <Orderer
                  orderBy={this.props.orderBy}
                  orderFn={this.sortByCpf}
                  active={this.state.active === 'cpf'}
                >
                  CPF
                </Orderer>
              </Col>
              <Col sm='4' className='email'>
                <Orderer
                  orderBy={this.props.orderBy}
                  orderFn={this.sortByEmail}
                  active={this.state.active === 'email'}
                >
                  Email
                </Orderer>
              </Col>
              {this.props.camposAdicionais
                ? this.props.camposAdicionais.map((campoAdicional) => (
                    <Col sm='2'>
                      <Orderer
                        orderBy={this.props.orderBy}
                        orderFn={this.sortByCampoAdicional[campoAdicional.nome]}
                        active={this.state.active === campoAdicional.nome}
                      >
                        {campoAdicional.prettifiedNome}
                      </Orderer>
                    </Col>
                  ))
                : null}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
