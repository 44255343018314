import apiCalls from 'config/apiCalls';
import {
  getParticipantNextHierarchies
} from 'models/hierarchies';

async function fetchHierarchy({ projectId, cargo }) {
  const project = await apiCalls.projetos.getProjectById(projectId);
  let hierarchies = project.data.hierarquia;
  const nextHierarchies = getParticipantNextHierarchies({
    hierarchies,
    currentCargo: cargo
  });

  return nextHierarchies;
}

export default fetchHierarchy;