import * as PPGraphService from 'views/Pages/Modulos/Assessment/Resultados/Components/PPGraph/services';
import * as GraphService from 'views/Pages/Modulos/Assessment/Resultados/Components/PPGraph/graph-services';
import * as PPGraphUtils from 'views/Pages/Modulos/Assessment/Resultados/Components/PPGraph/pp-graph';
import GraphConstants from 'views/Pages/Modulos/Assessment/Resultados/Components/PPGraph/constants';
import { PLOT_COLORS } from 'Services/Constantes';

const {
  ATRIBUTOS_PRONTIDAO,
  PARTICIPANT_POINT_COLOR
} = GraphConstants;

function ajustarCor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function orderResult(results) {
  results.rankings.sort((a,b) => {
    if (a.mediaPotencial < b.mediaPotencial) return 1
    else if (a.mediaPotencial > b.mediaPotencial) return -1
    else return 0;
  });
  results.rankings.sort((a,b) => {
    if (a.mediaProntidao < b.mediaProntidao) return 1
    else if (a.mediaProntidao > b.mediaProntidao) return -1
    else return 0;
  });

  return results;
}

async function salvarGrafico({ datasets }, assessmentId='') {
  const id = assessmentId || localStorage.getItem('assessment');

  await PPGraphService.salvarGrafico({

    assessmentId: id,
    datasets: datasets.map(d => {
      return {
        datalabels: d.datalabels,
        backgroundColor: d.backgroundColor,
        borderColor: d.borderColor,
        borderWidth: d.borderWidth,
        data: d.data,
        fill: d.fill,
        hidden: d.hidden,
        label: d.label,
        participantId: d.participantId,
        pointBackgroundColor: d.pointBackgroundColor,
        pointBorderColor: d.pointBorderColor,
        pointBorderWidth: d.pointBorderWidth,
        pointHoverBackgroundColor: d.pointHoverBackgroundColor,
        pointHoverBorderColor: d.pointHoverBorderColor,
        pointHoverBorderWidth: d.pointHoverBorderWidth,
        pointRadius: d.pointRadius,
        pointHoverRadius: d.pointHoverRadius,
        showLine: d.showLine,
        tension: d.tension,
      }
    }),

  });
}

async function fillGraph(results, assessmentId='') {
  const id = assessmentId || localStorage.getItem('assessment');

  const grafico = await PPGraphService.buscarGrafico({
    assessmentId: id
  });
  if (grafico && grafico.datasets?.length > 0) {

    let prontidaoResults = results
      .find(result => result.resultName.toLowerCase() === ATRIBUTOS_PRONTIDAO.toLowerCase());
    prontidaoResults.rankings.map(p => p.name.substr(0, 17));

    prontidaoResults = orderResult(prontidaoResults);

    let colors = [];

    function getColor(x, y, indexRanking) {
      let index = colors.findIndex(c => c.x === x && c.y === y);

      if (index !== -1) return colors[index].color;

      let colorIndex = 0;

      if (indexRanking >= PLOT_COLORS.length) {

        colorIndex = indexRanking;
        while (colorIndex >= PLOT_COLORS.length) {
          colorIndex = colorIndex - PLOT_COLORS.length;
        }

      } else
        colorIndex = indexRanking;

      const color = {
        x,
        y,
        color: PLOT_COLORS[colorIndex]
      };
      colors.push(color);

      return color.color;
    }

    let datasets = [];

    prontidaoResults.rankings.forEach((ranking, index, original) => {
      const s = original.filter(r2 => (
        r2.mediaPotencial === ranking.mediaPotencial &&
        r2.mediaProntidao === ranking.mediaProntidao &&
        r2.userId !== ranking.userId
      ));

      ranking.s = s;
    });

    prontidaoResults.rankings.sort((a, b) => {
      if (a.s.length < b.s.length) return 1;
      if (a.s.length > b.s.length) return -1;
      if (a.s.length === b.s.length) return 0;
    });

    const naoPosicionados = prontidaoResults.rankings.filter(ranking => {
      const posicionado = grafico.datasets.find(datasetSalvo => datasetSalvo.participantId === ranking.userId);

      return !posicionado;
    });

    grafico.datasets.map(d => {
      const rankingFounded = prontidaoResults.rankings.find(p => p.userId === d.participantId);

      if (rankingFounded) {
        const datasetData = [...d.data];

        datasetData.map((data, index) => {
          if (index !== datasetData.length - 1) {
            if (data.x !== rankingFounded.mediaProntidao || data.y !== rankingFounded.mediaPotencial) {
              const pointX = rankingFounded.mediaProntidao;
              const pointY = rankingFounded.mediaPotencial;
              const labelCoordinates = GraphService.findCoordinates({
                name: rankingFounded.name,
                pointX,
                pointY,
                datasets
              });

              datasetData[datasetData.length - 1] = labelCoordinates;
            }

            data.x = rankingFounded.mediaProntidao;
            data.y = rankingFounded.mediaPotencial;
          }
        });

        d.label = rankingFounded.name;
        d.data = datasetData;
      }
    });

    grafico.datasets.forEach((dataset, index) => {
      getColor(dataset.data[0].x, dataset.data[0].y, index);
    });

    datasets = grafico.datasets;

    let ultimaIndexCor = grafico.datasets.length;
    for (let index = 0; index < naoPosicionados.length; index++) {
      let ranking = naoPosicionados[index];
      if (!ranking) throw new Error('ranking não definido');

      const color = getColor(ranking.mediaProntidao, ranking.mediaPotencial, ultimaIndexCor);
      ultimaIndexCor++;

      const dataset = {
        participantId: ranking.userId,
        label: ranking.name,
        hidden: false,
        fill: false,
        tension: 1,
        showLine: true,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: [
          color,
          ajustarCor(color, 20),
        ],
        pointBackgroundColor: [
          color,
          // ajustarCor(color, 20)
          'rgba(0, 0, 0, 0)'
        ],
        pointHoverBackgroundColor: [
          color,
          // ajustarCor(color, 20),
          'rgba(0, 0, 0, 0)'
        ],
        pointHoverBorderColor: [
          color,
          ajustarCor(color, 20),
          // 'rgba(0, 0, 0, 0)'
        ],

        pointBorderWidth: 1,
        pointHoverRadius: [3, 6],
        borderWidth: 1,
        pointHoverBorderWidth: 2,
        pointRadius: [3, 5],
        data: [
          { x: ranking.mediaProntidao, y: ranking.mediaPotencial }
        ],
      }

      datasets.push(dataset);
    }

    let novosCount = 0;
    datasets = datasets.map(dataset => {

      if (dataset.data[1] !== undefined && dataset.data[1] !== undefined) {
        return dataset;
      }

      const pointX = dataset.data[0].x;
      const pointY = dataset.data[0].y;
      const labelCoordinates = GraphService.findCoordinates({
        name: dataset.label,
        pointX,
        pointY,
        datasets
      });

      dataset.data = [
        ...dataset.data,
        {
          x: labelCoordinates.x,
          y: labelCoordinates.y,
          rect: PPGraphUtils.getRectangle(labelCoordinates.x, labelCoordinates.y)
        },
      ]

      novosCount ++;
      return dataset;
    });

    await salvarGrafico({ datasets: [ ...datasets ] }, id);

  } else { // grafico = undefined || grafico.datasets.length = 0

    let prontidaoResults = results
      .find(result => result.resultName.toLowerCase() === ATRIBUTOS_PRONTIDAO.toLowerCase());

    if (!prontidaoResults) return; // não existem resultados.

    prontidaoResults.rankings.map(p => p.name.substr(0, 17));

    /** TESTE */
    // let prontidaoResults = {
    //   rankings: [
    //   ]
    // }
    // new Array(50).fill(0).map((_, i) => {
    //   const mT = getRandomInt(1, 5);
    //   const mP = getRandomInt(1, 5);
    //   prontidaoResults.rankings.push({
    //     name: 'Pessoa G. M.'.substr(0, 17),
    //     mediaProntidao: mT,
    //     mediaPotencial: mP
    //   })
    // })
    prontidaoResults = orderResult(prontidaoResults);

    let colors = [];

    function getColor(x, y, indexRanking) {
      let index = colors.findIndex(c => c.x === x && c.y === y);

      if (index !== -1) return colors[index].color;

      let colorIndex = 0;

      if (indexRanking >= PLOT_COLORS.length) {

        colorIndex = indexRanking;
        while (colorIndex >= PLOT_COLORS.length) {
          colorIndex = colorIndex - PLOT_COLORS.length;
        }

      } else
        colorIndex = indexRanking;

      const color = {
        x,
        y,
        color: PLOT_COLORS[colorIndex]
      };
      colors.push(color);

      return color.color;
    }

    let datasets = [];

    prontidaoResults.rankings.forEach((ranking, index, original) => {
      const s = original.filter(r2 => (
        r2.mediaPotencial === ranking.mediaPotencial &&
        r2.mediaProntidao === ranking.mediaProntidao &&
        r2.userId !== ranking.userId
      ));

      ranking.s = s;
    });

    prontidaoResults.rankings.sort((a, b) => {
      if (a.s.length < b.s.length) return 1;
      if (a.s.length > b.s.length) return -1;
      if (a.s.length === b.s.length) return 0;
    });

    for (let index = 0; index < prontidaoResults.rankings.length; index++) {
      let ranking = prontidaoResults.rankings[index];

      const color = getColor(ranking.mediaProntidao, ranking.mediaPotencial, index);
      const dataset = {
        participantId: ranking.userId,
        label: ranking.name,
        hidden: false,
        fill: false,
        tension: 1,
        showLine: true,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: [
          color,
          ajustarCor(color, 20)
          // 'rgba(0, 0, 0, 0)'
        ],
        pointBackgroundColor: [
          color,
          // ajustarCor(color, 20)
          'rgba(0, 0, 0, 0)'
        ],
        pointHoverBackgroundColor: [
          color,
          // ajustarCor(color, 20)
          'rgba(0, 0, 0, 0)'
        ],
        pointHoverBorderColor: [
          color,
          ajustarCor(color, 20),
          // 'rgba(0, 0, 0, 0)'
        ],
        pointBorderWidth: 1,
        pointHoverRadius: [3, 6],
        borderWidth: 1,
        pointHoverBorderWidth: 2,
        pointRadius: [3, 5],
        data: [
          { x: ranking.mediaProntidao, y: ranking.mediaPotencial }
        ],
      }

      datasets.push(dataset);
    }

    datasets = datasets.map(dataset => {

      const pointX = dataset.data[0].x;
      const pointY = dataset.data[0].y;
      const labelCoordinates = GraphService.findCoordinates({
        name: dataset.label,
        pointX,
        pointY,
        datasets
      });

      dataset.data = [
        ...dataset.data,
        {
          x: labelCoordinates.x,
          y: labelCoordinates.y,
          rect: PPGraphUtils.getRectangle(labelCoordinates.x, labelCoordinates.y)
        },
      ]

      return dataset;
    });

    // datasets.forEach(dataset => {
    //   dataset.pointHoverBorderColor =
    //     [dataset.backgroundColor, 'rgba(0, 0, 0, 0)'];
    //   dataset.pointHoverBackgroundColor =
    //     [dataset.backgroundColor, 'rgba(0, 0, 0, 0)'];
    //   dataset.pointBackgroundColor =
    //     [dataset.backgroundColor, 'rgba(0, 0, 0, 0)'];
    //   dataset.pointBorderColor =
    //     [dataset.backgroundColor, 'rgba(0, 0, 0, 0)'];
    // });

    const lineDatasets = [
      PPGraphUtils.superiorLine(),
      PPGraphUtils.inferiorLine()
    ]

    const newDatasets = [
      ...lineDatasets,
      ...datasets
    ];

    await salvarGrafico({ datasets: [ ...newDatasets ] }, id);
  }
}

export default async function saveResults(assessmentId='') {
  const id = assessmentId || localStorage.getItem('assessment');

  const res = await PPGraphService.loadResults(id, true);
  await fillGraph(res.data, id);
}