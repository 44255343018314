import styled from 'styled-components';

export const BaseMenu = styled.div`
  background: #cdd8dc;
  padding: 10px;
  height: 100% !important;
  text-align: center;
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin-bottom: 15px;
  }
  .resultados {
    list-style: none;
    margin-bottom: 0px;
    border-bottom: solid 1px black;
  }
  .resultados:last-child {
    list-style: none;
    margin-bottom: 0px;
  }
`;

export const Button = styled.button`
  height: 35px;
  width: 35px;
  height: 35px;
  border: 0px;

  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  );
  padding: 7px;
  border-radius: 5px;
  span,
  svg {
    color: #fafafa;
    width: 18px;
    height: 18px;
    overflow: visible;
    vertical-align: sub;
  }
  span {
    font-size: 18px;
  }
  svg {
    font-size: 18px;
  }
  :focus {
    outline: none;
  }

  &:disabled {
    color: #23282c;
    opacity: 0.65;
    background: -moz-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #c8ced3 0%,
      #8d9194 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    background: -moz-linear-gradient(
      top,
      #c8ced3 0%,
      #8d9194 100%
    ); /* FF3.6-15 */
  }
`;

export const ImgFiltered = styled.img`
  filter: invert(100%) !important;
`