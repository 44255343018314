import React, { Component } from 'react';
import { normalizarRankingPorMaximo } from '../utils.js';
import { getSVGComponent } from './d3_barra';

export class Barra extends Component {
  state = {
    series: [],
    options: {}
  };

  getGaussianFunction(mean, variance) {
    return (x) =>
      (1 / (variance * Math.sqrt(2 * Math.PI))) *
      Math.pow(Math.E, -0.5 * Math.pow((x - mean) / variance, 2));
  }

  mean(array, length) {
    if (array.length === 0) {
      return 0;
    }
    const sum = array.reduce((a, b) => Number(a) + Number(b));
    return sum / (length || array.length);
  }

  variance(array, length) {
    if (array.length === 0) {
      return 0;
    }
    const mean = this.mean(array);
    let sum = 0;
    array.forEach((a) => {
      sum += Math.pow(Number(a) - Number(mean), 2);
    });

    return sum / (length || array.length);
  }

  componentDidMount() {
    this.setState({
      series: this.updateSeries(),
      options: this.updateOptions()
    });
  }
  range(from, to, step) {
    let list = [];
    for (let i = from; i < to; i += step | 1) {
      list.push(i);
    }
    return list;
  }

  range2(from, to, step) {
    let list = [];
    for (let i = from; i <= to; i += step) {
      list.push(i);
    }
    return list;
  }

  getCorrectArray(array, w) {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < w[i]; j++) {
        newArray.push(array[i]);
      }
    }
    return newArray;
  }

  updateSeries() {
    let fx = () => '';
    if (this.props.valores.length) {
      fx = this.getGaussianFunction(
        this.mean(
          this.props.categorias.map(
            (cat, i) => Number(cat) * this.props.valores[i]
          ),
          this.props.valores.reduce((a, acc) => a + acc)
        ),
        Math.sqrt(
          this.variance(
            this.getCorrectArray(this.props.categorias, this.props.valores)
          )
        )
      );
    }
    const rawDistribution = this.range(0, 101, 1).map((categoria) =>
      fx(categoria)
    );
    const max = this.props.valores.length
      ? this.props.valores.reduce((acc, at) => (at > acc ? at : acc))
      : 0;
    const maxValue = rawDistribution.reduce((acc, a) => (a > acc ? a : acc));
    const normalizedDist = rawDistribution.map((dist, i) => {
      return {
        name: i,
        value: normalizarRankingPorMaximo(maxValue, dist, max)
      };
    });

    let series = [
      {
        name: '# Pessoas',
        type: 'column',
        backgroundColor: this.props.users.length
          ? 'rgba(255,99,132,0.1)'
          : 'rgba(255,99,132,0.2)',
        borderColor: this.props.users.length
          ? 'rgba(255,99,132,0.5)'
          : 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: this.props.users.length
          ? 'rgba(255,99,132,0.2)'
          : 'rgba(255,99,132,0.4)',
        hoverBorderColor: this.props.users.length
          ? 'rgba(255,99,132,0.5)'
          : 'rgba(255,99,132,1)',
        data: this.range(0, 101, 1)
          .map((categoria) => {
            let pos = this.props.categorias.findIndex(
              (cat) => Math.floor(Number(cat)) === categoria
            );
            if (pos !== -1) {
              return { name: categoria, value: this.props.valores[pos] };
              // return this.props.valores[pos];
            }
            // return {
            //   name: categoria,
            //   value: 1
            // };
          })
          .filter((val) => val)
        // .map(
        //   (_, i) =>
        //     !this.props.users.some(
        //       (user) => Math.floor(user.normalizado) === i
        //     )
        // )
      },
      {
        name: '# Curva normal',
        type: 'line',
        data: normalizedDist
      }
    ];
    if (this.props.users) {
      series = series.concat([
        {
          name: `# Selecionados`,
          type: 'column',
          backgroundColor: 'rgba(0, 100, 255,0.2)',
          borderColor: 'rgba(0, 100, 255,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(0, 100, 255,0.4)',
          hoverBorderColor: 'rgba(0, 100, 255,1)',
          data: this.range(0, 101, 1)
            .map((categoria) => {
              let pos = this.props.categorias.findIndex((cat) =>
                this.props.users.some(
                  (user) =>
                    String(cat) === String(user.normalizado) &&
                    Math.floor(user.normalizado) === categoria
                )
              );
              if (pos !== -1) {
                return {
                  name: this.props.categorias[pos],
                  value: this.props.valores[pos]
                };
                // return this.props.valores[pos];
              }
              // return 0;
              return undefined;
            })
            .filter((val) => val)
        }
      ]);
    }
    return series;
  }
  updateOptions() {
    const max = this.props.valores.length
      ? this.props.valores.reduce((acc, at) => (at > acc ? at : acc))
      : 0;
    const yMax = (max % 2 === 0 ? max + 1 : max) + 1;
    return {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%',
          // minWidth: 10
          fixedWidth: 10,
          itemsNumber: this.props.categorias.length,
          concatenateSeries: true
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true
          },
          autoSelected: 'zoom'
        },
        fontFamily: 'Montserrat'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        type: 'numeric',
        // tickAmount:
        tickAmount: 20,
        tickPlacement: 'on',
        categories: this.range(0, 101, 1),
        min: 0,
        max: 100,
        axisTicks: {
          show: false
        },
        title: {
          text: 'Nota normalizada'
        }
      },
      yaxis: {
        // seriesNames: '# Pessoas',
        title: {
          text: 'Pessoas'
        },
        tickAmount: yMax < 5 ? yMax : Math.floor(yMax / 2),
        min: 0,
        max: yMax,
        forceNiceScale: false,
        labels: {
          formatter: (val) => val.toFixed(0)
        }
      },
      // scales: {
      //   yAxes: [
      //     {
      //       ticks: {
      //         min: 0,
      //         max: yMax,
      //         stepSize: 1
      //       }
      //     }
      //   ],
      //   xAxes: [
      //     {
      //       barPercentage: 0.5,
      //       barThickness: 6,
      //       maxBarThickness: 8,
      //       minBarLength: 2,
      //       gridLines: {
      //         offsetGridLines: true
      //       },
      //       ticks: {
      //         min: 0,
      //         max: 100,
      //         stepSize: 1
      //       }
      //     }
      //   ]
      // }
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return `
          <div>
            <div>
            ${this.props.usersByRanking[dataPointIndex]
              .map(
                (user) =>
                  `<p className="tooltip-user-name">${user.nome} - ${user.normalizado}</p>`
              )
              .join('\n')}
            </div>
          </div>
          `;
        }
      },
      colors: [
        ({ value, seriesIndex, w }) => {
          if (seriesIndex === 0 && this.props.users.length) {
            return 'rgba(0, 150, 255, 0.2)';
          }
          if (!this.props.users.length) {
            return 'rgba(0, 150, 255, 1)';
          }
          return 'rgba(255, 0, 0, 1)';
        }
      ]
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.valores !== prevProps.valores ||
      this.props.categorias !== prevProps.categorias
    ) {
      this.setState({
        series: this.updateSeries(),
        options: this.updateOptions()
      });
    }
  };

  getGraphHeight(vh, max) {
    const calculatedSize = max * 15;
    if (calculatedSize > 0.7 * vh) {
      return 0.7 * vh;
    }
    if (calculatedSize < 250) {
      return 250;
    }
    return calculatedSize;
  }

  render() {
    const max = this.props.valores.length
      ? this.props.valores.reduce((acc, at) => (at > acc ? at : acc))
      : 0;
    const yMax = (max % 2 === 0 ? max + 1 : max) + 1;
    const vh = window.innerHeight;
    return (
      <div id="distribution-graph">
        {/* <Chart
          series={this.state.series}
          options={this.state.options}
          width={this.props.width}
          height={this.getGraphHeight(vh, yMax)}
          type="bar"
        /> */}
        <svg
          id= {this.props.id ? `graph-${this.props.id}` : "graph"}
          width="100%"
          height={this.getGraphHeight(vh, yMax)}
        ></svg>
        <div id="graph-tooltip"></div>
        {getSVGComponent(
          this.state.series,
          1500,
          this.getGraphHeight(vh, yMax),
          { top: 30, right: 0, bottom: 20, left: 40 },
          this.range(0, 101, 5),
          this.range2(0, yMax + 1, yMax < 5 ? 1 : 3),
          this.props.id
        )}
      </div>
    );
  }
}
