import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import EscutaAtivaGraph from 'views/Pages/Assets/ModalResultado/Resultados/components/EscutaAtivaGraph';
import { Col, Row } from 'reactstrap';

import './scss/styles.scss';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import * as EscutaAtivaService from './services';
import Messages from 'static/Messages';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import { getDimensao } from 'models/escuta-ativa/getDimensao';

const EscutaAtivaDiv = styled.div``;

const FeedbackText = styled.p`
p {
  font-size: 12px;
  margin-bottom: 5px;

  strong {
    font-size: 14px;
  }

  & ~ p {
    margin-left: 0px;
  }
}
`

export default class EscutaAtiva extends Component {
  constructor(props) {
    super(props);
    this.state = {
      respostasFormatadas: {},
      loading: false,
      personalidade: {},
      feedbackText: '',
      whatsBeingMeasuredText: ''
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);
      const resultado = response.data[0];
      if (!resultado.dimensao) resultado.dimensao = getDimensao(resultado);

      if (resultado) {
        this.setState({ resultado });

        return resultado;
      }

    } catch (err) {
      console.error(err);
      // notifyError(Messages.Geral.msgDataFetchError);
    } finally {
      this.setState({ loading: false });
    }
  }

  async getTextos() {
    const { feedbackText, whatsBeingMeasuredText } = await EscutaAtivaService
      .getFeedbackText({
        userId: this.props.idParticipante,
        assincronoId: this.assincId
       });
    return { feedbackText, whatsBeingMeasuredText };
  }

  componentDidMount() {
    this.assincId = this.props.idAssincrono;

    const loadTexts = async () => {
      await this.getTextos()
        .then(({ feedbackText, whatsBeingMeasuredText }) => {
          this.setState({
            feedbackText,
            whatsBeingMeasuredText
          });
        })
        .catch(err => {
          console.error(err);
          notifyError(Messages.Assincronos.msgFeedbackTextFetchFailed);
        })
    }

    this.getInfoInstrumento()
      .then(async (resultado) => {
        if (!resultado) {
          return; // Results not found message.
        } else {
          await loadTexts();
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const {
      resultado,
    } = this.state;

    return (
      <EscutaAtivaDiv>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                resultado
                  ?
                  <>
                    <EscutaAtivaGraph id={`Escuta-ativa-graph-${this.props.idAssincrono}`}
                      participantId={this.props.idParticipante}
                      assincronoId={this.props.idAssincrono}
                      instrumentId={this.props.idInstrumento}
                      projectId={this.props.idProjeto} />

                    <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} />
                    <br />
                    <Row>
                      <Col>
                        <p>
                          <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>Resultado</h3>
                        </p>
                      </Col>
                    </Row>

                    <br />

                    <Row>
                      <Col style={{
                        fontSize: '12px',
                        marginBottom: 15
                      }}>
                        <FeedbackText dangerouslySetInnerHTML={{
                          __html: this.state.feedbackText
                        }}
                        ></FeedbackText>
                      </Col>
                    </Row>
                  </>
                  :
                  <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
              }
            </>
        }
      </EscutaAtivaDiv>
    )
  }
}