import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import Icon from '@material-ui/core/Icon';
import "./css/FolderV2.css";
import styled from "styled-components";

export default class FolderV2 extends Component {
  state = {
    show: false
  };
  componentDidMount() {
    if (this.props.show) {
      this.setState({ show: true });
    }
  }

  handleHeaderClick = () => {
    if (!this.state.show && this.props.onClick) {
      this.props.onClick();
    }
    this.setState({ show: !this.state.show });
  }
  render() {
    const component = () => (
      <Row className="row-centered folder without-margin">
        <Row
          onClick={this.handleHeaderClick}
          className="without-margin folder-collapse"
          style={{
            minWidth: "min-content",
            width: "100%",
            cursor: "pointer",
            alignItems: "center"
          }}
        >
          {this.state.show ?
            <Icon className="fa fa-chevron-down" /> :
            <Icon className="fa fa-chevron-right" />
          }
          <span
            style={{
              marginLeft: "15px"
            }}
          >
            {this.props.nome}
          </span>
        </Row>
        {/* <Row> */}
        <div
          style={{ width: "min-content" }}
        >
          {this.props.component}
        </div>
        {/* </Row> */}
      </Row>
    );
    if (this.state.show) {
      return (
        <Col sm="12" className="open-folder without-padding">
          {component()}
          <div>{this.props.children}</div>
        </Col>
      );
    }
    return component();
  }
}
