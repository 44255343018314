import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { TourContext } from '@reactour/tour';
import { withRouter } from "react-router-dom";
import _ from 'lodash';

import { FaCog, FaTimes } from 'react-icons/fa';

// drag and drop component
import 'gridstack/dist/gridstack.css';
import { GridStack } from 'gridstack';
import 'gridstack/dist/h5/gridstack-dd-native';

// componentes internos
import { BarChart, PieChart } from './components/Graphs';
import WidgetsAside from './components/WidgetAside';

import NextEvents from "./components/NextEvents";
import ConsultantRanking from './components/ConsultantRanking';
import FeaturedProjectsTable from './components/FeaturedProjectsTable';
import {
  TourP
} from "views/Pages/Assets/Assets";

import WelcomeModal from 'views/Components/welcome-modal/index';

import Loading from 'Services/Loading';

// estilos
import {
  Container,
  Input,
  Spinner
} from 'reactstrap';
import DatePicker from "views/Pages/Assets/DatePicker";
import styled from 'styled-components';
import {
  ItemList
} from './styles';

const DashboardStack = styled.div`
.grid-stack-item-content {
  background-color: #fff;
 }

.grid-stack-item-header {
  border-bottom: 1px solid #c9c9c9;

  padding: 10px 0;

  display: flex;
  justify-content: space-between;
 }
`;

const GridHeaderUtils = styled.div`
color: #A6A6A6;
margin-right: 5px;
display: none;
`;

const DashboardContainer = styled.div`

`

// mocks

const consultantRankingMock = [
  {
    position: 1,
    name: 'Marcos Ferreira',
    projects: [... new Array(3)]
  },
  {
    position: 2,
    name: 'Consultor 2',
    projects: [... new Array(4)]
  },
  {
    position: 3,
    name: 'Consultor 3',
    projects: [... new Array(3)]
  },
  {
    position: 4,
    name: 'Consultor 4',
    projects: [... new Array(4)]
  },
  {
    position: 5,
    name: 'Consultor 5',
    projects: [... new Array(3)]
  },
  {
    position: 7,
    name: 'Consultor 6',
    projects: [... new Array(4)]
  },
  {
    position: 8,
    name: 'Consultor 2',
    projects: [... new Array(4)]
  },
  {
    position: 9,
    name: 'Consultor 3',
    projects: [... new Array(3)]
  },
  {
    position: 10,
    name: 'Consultor 4',
    projects: [... new Array(4)]
  },
];

const teamMembersMock = [
  {
    _id: 1,
    name: 'Member 1',
  },
  {
    _id: 1,
    name: 'Member 2',
  },
]


class FeaturedProject {
  clientName = 'Quentin Tarantino';
  RhResponsible = 'Consultor RH';
  project = { nome: 'Projeto de Teste' };
  creditsConsumed = 980;
  lastAccessDate = new Date();
  consumptionStartDate = 'JUN 1, 2019';
  consumptionFinishDate = 'JUN 10, 2019';
  clientId = null;
  logo = null;
}

class Event {
  tipo = '';
  dateString = '';
  name = '';
}

class Dashboard extends Component {
  static contextType = TourContext;

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      sortField: "tipo",
      sortOrder: -1,
      sortProjectField: 'lastModified.date',
      sortProjectOrder: -1,
      featuredProjects: [],
      enterprises: [],
      consultantRanking: [],
      teamMembers: [],
      page: 0,
      loading: false,
      widgetBaseHeight: 120,
      welcomeModal: false,
    }

    this.loadMoreProjects = this.loadMoreProjects.bind(this);
  }

  componentWillMount() {
    this.nextEventsWidgetRef = React.createRef();
    this.featuredProjectsWidgetRef = React.createRef();
    this.gridRef = React.createRef();
  }

  async loadMoreProjects() {
    if (this.isLoadingProjects) {
      return;
    };
    this.isLoadingProjects = true;

    // getting clients data
    const res2 = await apiCalls.clients.getEnterprises();
    const enterprises = res2.data.clientes;

    const userId = sessionStorage.getItem('user');

    const res = await apiCalls.clients.getProjectDashboard(userId, true, true);
    const { projects } = res.data;
    const clientsSet = [...new Set(projects.map((project) => project.cliente))];

    let featuredProjects = clientsSet.map((clientId) => {
      const clientProjects = projects.filter((project) => project.cliente === clientId);

      const enterpriseData = enterprises.find((enterprise) => enterprise._id === clientId);

      if (!enterpriseData) return null;

      let clientLastProject = clientProjects[0];

      clientProjects.map(p => {
        const date1 = new Date(p.lastModified.date);
        const date2 = new Date(clientLastProject.lastModified.date);

        if (date1 > date2) clientLastProject = p;
      });
      // clientLastProject.updatedAt = new Date(); // mock
      const featuredProject = new FeaturedProject();
      featuredProject.project = clientLastProject;
      featuredProject.logo = enterpriseData.logo;
      featuredProject.clientName = enterpriseData.razaoSocial;
      featuredProject.RhResponsible = enterpriseData.setorAtuacao;
      featuredProject.clientId = clientId;

      return featuredProject;
    });

    featuredProjects = featuredProjects.filter((featured) => featured);
    featuredProjects = _.orderBy(featuredProjects, ['project.lastModified.date'], ['desc']);

    this.setState(prev => ({
      enterprises,
      page: prev.page + 1,
      featuredProjects: [
        ...prev.featuredProjects,
        ...featuredProjects
      ]
    }));

    this.isLoadingProjects = false;

    return enterprises;
  }

  orderBy = (field) => {
    const { events, sortField, sortOrder } = this.state;

    let order = sortOrder;
    let stringOrder = 'asc';

    if (sortField !== field) {
      order = -1;
    }

    if (order === -1) stringOrder = 'desc';

    let eventsState = [...events];

    eventsState = _.orderBy(eventsState, [field], [stringOrder]);

    this.setState({
      events: eventsState,
      sortOrder: -order,
      sortField: field,
    });
  };

  orderProjectsBy = (field) => {
    const { sortProjectField, sortProjectOrder, featuredProjects } = this.state;
    let order = sortProjectOrder;
    let stringOrder = 'asc';

    if (sortProjectField !== field) {
      order = -1;
    }

    if (order === -1) stringOrder = 'desc';

    let featuredProjectsState = [...featuredProjects];

    featuredProjectsState = _.orderBy(featuredProjectsState, [field], [stringOrder]);

    this.setState({
      featuredProjects: featuredProjectsState,
      sortProjectOrder: -order,
      sortProjectField: field,
    });
  }

  saveWidgets = async (id, x, y, w, h, widgetsModule, widgetsScreen) => {
    try {
      const res = await apiCalls.widgets.updatePreferences(
        id,
        sessionStorage.getItem('user'),
        widgetsModule,
        widgetsScreen,
        x,
        y,
        w,
        h
      );
    } catch (err) {
      console.error('Não foi possível salvar!');
    }
  }

  async componentDidMount() {
    // load events
    this.setState({
      loading: true,
    })
    const criadoPor = sessionStorage.getItem('user');
    const res = await apiCalls.dinamica.getDinamicas(criadoPor, {
      sortField: 'realizationDate',
      sortOrder: '-1'
    });

    const enterprises = await this.loadMoreProjects();

    const resAssessments = await apiCalls.assessments.getAssessments(criadoPor, {
      sortField: 'dataInicio',
      sortOrder: '-1'
    });

    const resAssincronos = await apiCalls.assincronos.getAssincronos(criadoPor, {
      sortField: 'dataInicio',
      sortOrder: '-1'
    });

    const { projects } = res.data;
    const { assessments } = resAssessments.data;
    const { assincronos } = resAssincronos.data;

    let dynamics = [];
    projects.map((project) => {
      let { dinamicas } = project.modulos;

      dinamicas = dinamicas.map((dynamic) => ({
        ...dynamic,
        projectId: project._id,
        clientId: project.criadoPor
      }));

      dynamics = dynamics.concat(dinamicas);
    });

    const events = [];

    dynamics.map((dynamic) => {
      let event = new Event();

      const client = enterprises.find(e => e.projetos.find(p => p === dynamic.projectId));

      event.tipo = 'Dinâmica';
      event.data = this.formatDate(dynamic.data, dynamic.horario);
      event.name = dynamic.nome;
      event.eventId = dynamic._id;
      event.projectId = dynamic.projectId;
      event.clientParams = {
        clientId: client && client._id,
        clientLogo: client && client.logo,
        RhResponsible: client && client.setorAtuacao,
        clientName: client && client.razaoSocial
      }
      event.module = {
        name: 'dinamica',
        id: dynamic._id
      }
      event.endpoint = '/clientes/projetos/dinamicas/configurar';

      events.push(event);
    });

    assessments.map((a) => {
      let event = new Event();

      const client = enterprises.find(e => e.projetos.find(p => p === a.projeto));

      event.tipo = 'Assessment';
      event.data = new Date(a.dataInicio);
      event.name = a.nome;
      event.eventId = a._id;
      event.projectId = a.projeto;
      event.clientParams = {
        clientId: client && client._id,
        clientLogo: client && client.logo,
        RhResponsible: client && client.setorAtuacao,
        clientName: client && client.razaoSocial
      }
      event.module = {
        name: 'assessment',
        id: a._id
      }
      event.endpoint = '/clientes/projetos/assessments/configurar';

      events.push(event);
    });

    assincronos.map((a) => {
      let event = new Event();

      const client = enterprises.find(e => e.projetos.find(p => p === a.projectId));

      event.tipo = 'Assíncrono';
      event.data = new Date(a.dataFim);
      event.name = a.nome;
      event.eventId = a._id;
      event.projectId = a.projectId;
      event.clientParams = {
        clientId: client && client._id,
        clientLogo: client && client.logo,
        RhResponsible: client && client.setorAtuacao,
        clientName: client && client.razaoSocial
      }
      event.module = {
        name: 'assincrono',
        id: a._id
      }
      event.endpoint = '/clientes/projetos/assincronos/configurar';

      events.push(event);
    });

    // events.sort(function compare(a, b) {

    //   if (!a.dateString) return 1;
    //   if (!b.dateString) return -1;

    //   var dateA = new Date(a.dateString);
    //   var dateB = new Date(b.dateString);
    //   return dateA - dateB;
    // });

    events.sort((a, b) => a.data - b.data);
    const filteredEvents = events.filter(e => e.data > new Date());

    this.setState(prev => ({
      consultantRanking: consultantRankingMock,
      teamMembers: teamMembersMock,
      events: [
        ...prev.events,
        ...filteredEvents
      ]
    }));

    this.setState({
      loading: false,
    })
    // grid widgets


    const options = {
      handleClass: "grid-stack-item-header",
      acceptWidgets: function (el) {
        return true;
      },
    };

    this.gridRef.current = this.gridRef.current || GridStack.init(options);
    const grid = this.gridRef.current;

    grid.batchUpdate();
    grid.removeAll(false);
    grid.makeWidget(this.nextEventsWidgetRef.current);
    grid.makeWidget(this.featuredProjectsWidgetRef.current);
    grid.commit();

    grid.on("dragstop", (event, element) => {
      if (
        this.nextEventsWidgetRef.current.gridstackNode.y != this.featuredProjectsWidgetRef.current.gridstackNode.y
      ) {
        grid.update(this.nextEventsWidgetRef.current, { x: 0, w: 12 });
        grid.update(this.featuredProjectsWidgetRef.current, { x: 0, w: 12 });
      }
    });

    grid.on('change', (event, element) => {
      element && element.map(e => {
        const moduleName = e.el.id === '1' ? 'next-events' : 'featured-projects'

        this.saveWidgets(
          e.el.id,
          `${e.x}`,
          `${e.y}`,
          `${e.w}`,
          `${e.h}`,
          moduleName,
          'consultant-dashboard'
        );
      })
    });

    let nextEventWidget = {}
    let featuredProjectWidget = {}

    try {
      const nextEventsWidgets = await apiCalls.widgets.getPreferences({
        screen_ref: 'consultant-dashboard',
        module_ref: 'next-events',
        id_widget: '1',
        id_user: sessionStorage.getItem('user'),
      });

      nextEventWidget = nextEventsWidgets.data;
    } catch (err) {
      const res = await apiCalls.widgets.createPreferences(
        '1',
        sessionStorage.getItem('user'),
        'next-events',
        'consultant-dashboard',
        "0",
        "0",
        "12",
        "3"
      )
    }

    try {
      const featuredProjectsWidgets = await apiCalls.widgets.getPreferences({
        screen_ref: 'consultant-dashboard',
        module_ref: 'featured-projects',
        id_widget: '2',
        id_user: sessionStorage.getItem('user'),
      });

      featuredProjectWidget = featuredProjectsWidgets.data;
    } catch (err) {
      const res = await apiCalls.widgets.createPreferences(
        '2',
        sessionStorage.getItem('user'),
        'featured-projects',
        'consultant-dashboard',
        "0",
        "0",
        "12",
        "3"
      )
    }

    grid.batchUpdate();
    grid.update(this.nextEventsWidgetRef.current, {
      x: nextEventWidget.x,
      y: nextEventWidget.y,
      w: nextEventWidget.w,
      h: nextEventWidget.h,
    });

    grid.update(this.featuredProjectsWidgetRef.current, {
      x: featuredProjectWidget.x,
      y: featuredProjectWidget.y,
      w: featuredProjectWidget.w,
      h: featuredProjectWidget.h,
    });

    grid.commit();

    const { setSteps, setCurrentStep, currentStep, setIsOpen, consentVisible } = this.context;

    const steps= [
      {
        selector: '[data-tour="dashboard-step-1"]',
        content: <>
          <TourP style={{ fontSize: 18, color: '#02264a', fontWeight: 'bold' }}>Bem-vindo ao IAPP!</TourP>
          <TourP>Este tutorial vai mostrar os primeiros passos para que você inicie o uso da plataforma.
          Clique em ‘Próximo’ para continuar.</TourP>
        </>,
        position: "center"
      },
      {
        selector: '.dashboard-step-2',
        content: <TourP>Aqui é o menu de opções principal.</TourP>,
      },
      {
        selector: '.dashboard-step-3',
        content: <TourP>A opção ‘Dashboard’ abre esta tela.</TourP>,
      },
      {
        selector: '.dashboard-step-4',
        content: <TourP>Clicando em ‘Meus Processos’ você tem acesso as telas de configuração de clientes, projetos e instrumentos.</TourP>,
      },
      {
        selector: '.dashboard-step-5',
        content: <TourP>O ‘Ambiente de Testes’ é o lugar ideal para criação de processos de teste, ou seja, sem validação de respostas ou pontuação e serve como um ambiente de aprendizado para os participantes.</TourP>,
      },
      {
        selector: '.dashboard-step-6',
        content: <TourP>No menu ‘Financeiro’ você acompanha o seu saldo de créditos, simula compras e adquire créditos para uso em seus projetos.</TourP>,
      },
      // {
      //   selector: '.dashboard-step-7',
      //   content: <TourP>Para visualizar os ‘Logs’ (registro das atividades), clique nessa opção.</TourP>,
      // },
      {
        selector: '.dashboard-step-8',
        content: <TourP>Na opção ‘Contato’ você pode enviar uma mensagem para o nosso setor de suporte, sempre que precisar.</TourP>,
      },
      {
        selector: '[data-tour="dashboard-step-9"]',
        content: <TourP>No cabeçalho do sistema você encontra algumas informações importantes como nome do cliente e projeto atuais, saldo de créditos e opções para o seu perfil de acesso.</TourP>,
      },
      {
        selector: '[data-tour="dashboard-step-10"]',
        content: <TourP> Nesta área será apresentado um resumo dos eventos com data futura. Você pode acessar a tela de configuração do módulo clicando no ícone de engrenagem na coluna ‘Ações’.</TourP>,
        styles: {
          highlightedArea: (base, { x, y }) => ({
            ...base,
            x: x + 10,
            y: y + 10,
          }),
        }
      },
      {
        selector: '[data-tour="dashboard-step-11"]',
        content: <TourP> Aqui você visualiza informações sobre os projetos mais recentes. Para acessar um módulo rapidamente, basta clicar no nome do módulo que aparecerá na coluna ‘Último acesso’.</TourP>,
      },
      {
        selector: '[data-tour="dashboard-step-12"]',
        content: <>
          <TourP style={{ fontSize: 18, color: '#02264a', fontWeight: 'bold' }}>Vamos começar!</TourP>
        </>,
        position: "center"
      },
      {
        selector: '[data-tour="dashboard-step-12"]',
        content: <>
          <TourP style={{ fontSize: 18, color: '#02264a', fontWeight: 'bold' }}>Vamos começar!</TourP>
        </>,
        position: "center",
        action: () => {
          this.props.history.push("/clientes");
          setIsOpen(false);
        }
      },
    ];

    setCurrentStep(0);
    setSteps(steps);
    if (consentVisible) {
      this.toggleWelcomeModal();
    }
  }

  componentWillUnmount() {
    const { setSteps, setCurrentStep, currentStep } = this.context;
    setCurrentStep(0);
    setSteps([]);
  }

  formatDate(date, hour) {
    let vDate = date.split('/');
    return new Date(`${vDate[2]}-${vDate[1]}-${vDate[0]}T${hour}:00`);
  }

  toggleWelcomeModal = () => {
    if (this.state.welcomeModal) {
      const { setIsOpen } = this.context;
      setIsOpen(true);
    }

    this.setState({ welcomeModal: !this.state.welcomeModal });
  }

  render() {
    const {
      events,
      enterprises,
      featuredProjects,
      consultantRanking,
      teamMembers
    } = this.state;
    return (
      <>
        <WelcomeModal
          toggle={this.toggleWelcomeModal}
          isOpen={this.state.welcomeModal}
        />

        <DashboardContainer style={{
          // padding: '20px',
          backgroundColor: '#f8f8f8',
          height: '100%',
          transition: '0.5s',
        }}
          id="dashboard"
        >
          {
            this.state.loading
              ?
              <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Loading />
              </div>
              :
              <Container fluid>
                <>
                  {/* <WidgetsAside /> */}

                  <div style={{ display: 'flex', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', justifyContent: 'space-between' }}>
                    {/* <Input type={'select'} style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '30%',
                      marginTop: '20px',
                    }}>
                      <option>Minhas Empresas</option>
                    </Input>
                    <Input type={'select'} style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '30%',
                      marginTop: '20px',
                    }}>
                      <option>Membros da Equipe</option>
                    </Input> */}

                    {/* <div style={{
                      display: 'flex',
                      width: '30%',
                      alignItems: 'center'
                    }}>
                      <DatePicker
                        clearable
                        tabIndex={1}
                        id="prazoInicio"
                        name="data"
                        label="Início:"
                        value={new Date()}
                        onChange={() => { }}
                        disabled={false}
                        className="small-input"
                      />

                      <DatePicker
                        clearable
                        tabIndex={1}
                        id="prazoFim"
                        name="data"
                        label="Término:"
                        value={new Date()}
                        onChange={() => { }}
                        disabled={false}
                        className="small-input"
                      />
                    </div> */}
                  </div>

                  <DashboardStack>
                    <div class="grid-stack">

                      {/* <div class="grid-stack-item border-dark" gs-w="4" gs-h="3">
                        <div class="grid-stack-item-content">
                          <div style={{ position: 'relative' }}>
                            <div class="grid-stack-item-header">
                              <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Gráfico</strong></div>
                              <GridHeaderUtils><FaCog style={{ marginRight: '5px' }} /><FaTimes style={{ marginRight: '5px' }} /></GridHeaderUtils>
                            </div>
                          </div>
                          <div style={{ height: 'calc(100% - 42px)', overflow: 'auto' }}>
                            <BarChart />
                          </div>
                        </div>
                      </div> */}

                      {/* <div class="grid-stack-item border-dark" gs-w="4" gs-h="3">
                        <div class="grid-stack-item-content">
                          <div style={{ position: 'relative' }}>
                            <div class="grid-stack-item-header">
                              <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Gráfico</strong></div>
                              <GridHeaderUtils><FaCog style={{ marginRight: '5px' }} /><FaTimes style={{ marginRight: '5px' }} /></GridHeaderUtils>
                            </div>
                          </div>
                          <div style={{ height: 'calc(100% - 42px)', overflow: 'auto' }}>
                            <PieChart />
                          </div>
                        </div>
                      </div> */}

                      {/* <div class="grid-stack-item border-dark" gs-w="4" gs-h="3">
                        <div class="grid-stack-item-content">
                          <div style={{ position: 'relative' }}>
                            <div class="grid-stack-item-header">
                              <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Ranking</strong></div>
                              <GridHeaderUtils><FaCog style={{ marginRight: '5px' }} /><FaTimes style={{ marginRight: '5px' }} /></GridHeaderUtils>
                            </div>
                          </div>
                          <div style={{ height: 'calc(100% - 42px)', overflow: 'auto' }}>
                            <ConsultantRanking />
                          </div>
                        </div>
                      </div> */}

                      <div id='1' ref={this.nextEventsWidgetRef} data-tour="dashboard-step-10" class="grid-stack-item border-dark" gs-w="12" gs-h="3">
                        <div class="grid-stack-item-content">
                          <div style={{ position: 'relative' }}>
                            <div class="grid-stack-item-header">
                              <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Próximos Eventos</strong></div>
                              <GridHeaderUtils><FaCog style={{ marginRight: '5px' }} /><FaTimes style={{ marginRight: '5px' }} /></GridHeaderUtils>
                            </div>
                          </div>
                          <div style={{ height: 'calc(100% - 42px)', overflow: 'auto' }}>
                            <div>
                              <NextEvents sortField={this.state.sortField} sortOrder={this.state.sortOrder} orderBy={this.orderBy} events={events} />
                            </div>
                          </div>
                        </div>
                      </div>


                      <div id='2' ref={this.featuredProjectsWidgetRef} data-tour="dashboard-step-11" class="grid-stack-item border-dark" gs-w="12" gs-h="4">
                        <div class="grid-stack-item-content">
                          <div style={{ position: 'relative' }}>
                            <div class="grid-stack-item-header">
                              <div style={{ marginLeft: '5px', color: '#01294B' }}><strong>Projetos Recentes</strong></div>
                              <GridHeaderUtils><FaCog style={{ marginRight: '5px' }} /><FaTimes style={{ marginRight: '5px' }} /></GridHeaderUtils>
                            </div>
                          </div>
                          <div style={{ height: 'calc(100% - 42px)', overflow: 'auto' }}>
                            <FeaturedProjectsTable
                              sortField={this.state.sortProjectField}
                              sortOrder={this.state.sortProjectOrder}
                              orderProjectsBy={this.orderProjectsBy}
                              featuredProjects={featuredProjects}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </DashboardStack>
                </>
              </Container>
          }
        </DashboardContainer >
      </>
    )
  }
}

export default withRouter(Dashboard);