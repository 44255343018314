/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import api from "../../../Services/api";
import { assincronosApi, reportsApi } from "../../../Services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Constantes } from "../../../Services/Constantes";
import { BaseLogin, LogoHome } from "../Assets/Assets";
import { notifyError, notifySuccess } from "../../../Services/Notificacoes";
import Politics from "views/Components/Politics";


// Imagens
const spinner = Constantes.imagemSpinner;

class PrimeiroAcesso extends Component {
  state = {
    cpf: "",
    senhaAntiga: "",
    senhaNova: "",
    senhaNova2: "",
    nome: "",
    email: "",
    loadingSpinner: false
  };

  componentDidMount = () => {
    if (!this.isAuthenticated()) this.props.history.push("login");
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  isAuthenticated() {
    const token = sessionStorage.getItem("token");
    return token && token.length > 10;
  }

  validar = () => {
    const { nome, senhaNova, senhaNova2, email } = this.state;
    if (senhaNova !== senhaNova2) {
      notifyError("Senhas diferentes!");
      this.setState({ loadingSpinner: false });
    } else if (nome === "") {
      notifyError("Favor insira nome");
      this.setState({ loadingSpinner: false });
    } else if (email === "") {
      notifyError("Favor insira o email");
      this.setState({ loadingSpinner: false });
    } else return true;
  };

  handleForm = async e => {
    e.preventDefault();
    this.setState({ loadingSpinner: true });

    const cpf = this.state.cpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");
    const { email, nome, senhaAntiga, senhaNova } = this.state;

    if (this.validar()) {
      api
        .post("api/auth/primeiro-acesso", {
          cpf,
          nome,
          senhaAntiga,
          senhaNova,
          email
        })
        .then(response => {
          console.log(api.defaults.headers.common);
          if (response.data.token) {
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("username", response.data.user.nome);
            sessionStorage.setItem("user", response.data.user._id);
            api.defaults.headers.common["Authorization"] =
              "Bearer " + sessionStorage.getItem("token");
            assincronosApi.defaults.headers.common["Authorization"] =
              "Bearer " + sessionStorage.getItem("token");
            reportsApi.defaults.headers.common["Authorization"] =
              "Bearer " + sessionStorage.getItem("token");
            const { funcao } = response.data.user.formacao;
            if (funcao === Constantes.funcaoConsultor) {
              // this.props.history.push("/projeto/participacao");

              notifySuccess("Sucesso! Aguarde alguns segundos...");
              this.sleep(3000).then(() => this.props.history.push("dashboard"));
            }
            if (funcao === Constantes.funcaoParticipante) {
              // this.props.history.push("/projeto/participacao");
              notifySuccess("Sucesso! Aguarde alguns segundos...");
              this.sleep(3000).then(() =>
                this.props.history.push("participante/dashboard")
              );
            }
          }
        })
        .catch(err => {
          if (err.response && err.response.data.error)
            notifyError(err.response.data.error);
          else
            notifyError(
              "Problema técnico - Erro Servidor. Tentando se reconectar"
            );
          this.setState({ loadingSpinner: false });
        });
    }
  };

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
  toogle = () => {
    this.setState({
      loadingSpinner: false
    });
  };
  render() {
    return (
      <div className="app flex-row align-items-center">

        <Container fluid style={{ padding: "0px" }}>
          <BaseLogin>
            <div className="app flex-row align-items-center">
              <br /> <br />
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" className="text-center" offset="6">
                    <LogoHome
                      alt="logo"
                      src={require("../Assets/images/logo.png")}
                    />
                    <br />
                    <br />
                    <Row>
                      <Col sm={{ size: 6, order: 2, offset: 3 }}>
                        <Form onSubmit={this.handleForm}>
                          <InputGroup>
                            <InputGroupAddon
                              addonType="prepend"
                              className="before"
                            >
                              👤
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="Insira seu nome completo "
                              autoComplete="name"
                              name="nome"
                              onChange={this.handleChange}
                              required
                            />
                          </InputGroup>
                          <br />
                          <InputGroup>
                            <InputGroupAddon
                              addonType="prepend"
                              className="before"
                            >
                              👤
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="cpf"
                              autoComplete="cpf"
                              name="cpf"
                              onChange={this.handleChange}
                              required
                            />
                          </InputGroup>
                          <InputGroup>
                            <InputGroupAddon
                              addonType="prepend"
                              className="before"
                            >
                              👤
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder="email"
                              autoComplete="email"
                              name="email"
                              onChange={this.handleChange}
                              required
                            />
                          </InputGroup>
                          <br />
                          <InputGroup>
                            <InputGroupAddon
                              addonType="prepend"
                              className="before"
                            >
                              🔒
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Senha Antiga"
                              //autoComplete="current-password"
                              name="senhaAntiga"
                              onChange={this.handleChange}
                              required
                            />
                            <Input
                              type="password"
                              placeholder="Nova Senha"
                              //autoComplete="current-password"
                              name="senhaNova"
                              onChange={this.handleChange}
                              required
                            />
                            <Input
                              type="password"
                              placeholder="Repita Nova Senha"
                              //autoComplete="current-password"
                              name="senhaNova2"
                              onChange={this.handleChange}
                              required
                            />
                          </InputGroup>
                          <br />
                          <Button
                            className="botao"
                            disabled={this.state.loadingSpinner}
                          >
                            ACESSAR
                          </Button>
                          <br />
                          <br />
                          {this.state.loadingSpinner && (
                            <img src={require(`../Assets/images/${spinner}`)} />
                          )}
                          <br />
                        </Form>
                      </Col>
                    </Row>

                    <LogoHome
                      alt="logo"
                      src={require("../Assets/images/formare.png")}
                    />
                    <div>
                      <small>
                        <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
                      </small>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </BaseLogin>
        </Container>
      </div>
    );
  }
}

export default PrimeiroAcesso;
