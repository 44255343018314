import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { format, parseISO } from 'date-fns';
import {
  pt
} from 'date-fns/locale';
import { useHistory } from "react-router-dom";

import {
  BorderlessButton
} from '../styles';

import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  min-width: 480px;
  font-size: 12px;
  overflow: auto;

  ::-webkit-scrollbar {
    height: 8px !important;
  }

  th {
    font-size: 12px;
    padding:10px;
    border-bottom: 2px solid #e4e5e6;
  }

  td {
    font-size: 12px;
    padding:10px;
    border-bottom: 1px solid #e4e5e6;
  }
`;

function formatDate(isoString) {
  if (!isoString) return '';

  const parsed = parseISO(isoString);
  return format(parsed, 'dd/MM/yyyy - HH:mm', {
    locale: pt
  });
}

const DynamicEvent = (props) => {
  const [nextEvents, setNextEvents] = useState([]);

  const { events, orderBy, sortField, sortOrder } = props;

  const history = useHistory();

  const navToConfiguration = (clientParams, projectId, module, endpoint) => {
    const clientLogo = clientParams.clientLogo || '';

    sessionStorage.setItem('clienteLogo', clientLogo);
    sessionStorage.setItem('clienteNome', clientParams.clientName);
    sessionStorage.setItem('cliente', clientParams.clientId);
    localStorage.setItem(module.name, module.id);
    sessionStorage.setItem('projeto', projectId);

    history.push(endpoint);
  }

  useEffect(() => {

    setNextEvents(events);
  }, [events]);

  function handleArrow(field) {
    return (
      <>
        {sortField === field ? (
          <span style={{ marginRight: 5 }}>
              {sortOrder === 1 ? <FaChevronDown /> : <FaChevronUp />}
          </span>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div >
      <Table>
        <thead>
          <tr>
            <th style={{ cursor: 'pointer' }} onClick={() => orderBy('tipo')} className="sticky-col">{handleArrow("tipo")} Tipo</th>
            <th style={{ cursor: 'pointer' }} onClick={() => orderBy('data')} className="sticky-col">{handleArrow("data")} Data</th>
            <th style={{ cursor: 'pointer' }} onClick={() => orderBy('name')} className="sticky-col">{handleArrow("name")} Nome</th>
            <th style={{ cursor: 'pointer', textAlign: 'center' }} className="sticky-col"> Ações </th>
          </tr>
        </thead>
        <tbody>
          {
            nextEvents.map((event, index) => (
              <React.Fragment key={index}>
                {
                  event.tipo
                  ?
                  <tr>
                    <td>{event.tipo}</td>
                    <td>{event.data.toLocaleDateString() || 'data não definida'}</td>
                    <td>{event.name}</td>
                    <td>
                      <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', marginRight: 23 }}>
                      <Tooltip
                        title={'Acesse a tela de editar'}
                        position="top"
                        arrow="true"
                        size="small"
                      >
                        <BorderlessButton onClick={() => navToConfiguration(
                          event.clientParams,
                          event.projectId,
                          event.module,
                          event.endpoint
                          )}>
                          <i class="fa fa-cog"
                            aria-hidden="true"
                            style={{
                              fontSize: '15px'
                            }} />
                        </BorderlessButton>
                      </Tooltip>
                        {/* <BorderlessButton style={{
                          marginLeft: '15px'
                        }}
                        onClick={() => { }}>
                          <i class="fa fa-calendar"
                            aria-hidden="true"
                            style={{
                              fontSize: '15px',
                            }}></i>
                        </BorderlessButton> */}
                      </div>
                    </td>
                  </tr>
                  :
                  <></>
                }
              </React.Fragment>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default DynamicEvent;