import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Icon } from 'views/Pages/Assets/Assets';

import './styles.css';

const Wrapper = styled.div`
  @media(max-width: 991px) {
    width: 100% !important;
  }
`

const Ul = styled.ul`
  @media(max-width: 991px) {
    width: 100% !important;
  }
`

const EmailInput = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([
    'gmail.com',
    'yahoo.com',
    'yahoo.com.br',
    'hotmail.com',
    'aol.com',
    'msn.com',
    'live.com',
    'outlook.com',
    'outlook.com.br',
    'uol.com.br',
    'bol.com.br',
    'googlemail.com',
    'ig.com.br',
    'terra.com.br',
    'icloud.com',
  ]);

  useEffect(() => {
    if (props.value || props.value === '') setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.includes('@')) {
      setShowAutocomplete(true);
    } else {
      setShowAutocomplete(false);
    }

    props.onChange(e);
  };

  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };


  const handleAutocompleteClick = (option) => {
    const splitedString = inputValue.split('@');

    setInputValue(`${splitedString[0]}@${option}`);
    setShowAutocomplete(false);

    const obj = {
      target: {
        name: props.name,
        value: `${splitedString[0]}@${option}`
      }
    }

    props.onChange(obj);
  };

  const filteredOptions = autocompleteOptions.filter((option) =>
    option.includes(inputValue.split('@').pop())
  );

  return (
    <Wrapper className='autocomplete-div' style={props.style}>
      {
        props.label ? <span className='autocomplete-label'>{props.label}</span> : null
      }
      <div style={{ display: 'flex' }}>
        { props.inputIcon ? <Icon icon='email'/> : null }
        <input
          className='autocomplete-input'
          style={props.inputStyle}
          type="text"
          value={inputValue}
          onBlur={async() => {
            if (props.onBlur) props.onBlur();

            await sleep(500);
            setShowAutocomplete(false);
          }}
          onChange={handleInputChange}
          name={props.name}
          disabled={props.disabled}
          required={props.required}
          maxLength={255}
          placeholder={props.hidePlaceholder ? "" : "email@exemplo.com"}
        />
      </div>
      {showAutocomplete && (
        <Ul className='autocomplete-list' style={props.optionsStyle}>
          {filteredOptions.map((option, index) => (
            <li className='autocomplete-option' key={index} onClick={() => handleAutocompleteClick(option)}>
              {option}
            </li>
          ))}
        </Ul>
      )}
    </Wrapper>
  );
};

export default EmailInput;