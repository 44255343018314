import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  text-decoration: underline;
  color: #0070f3;
`

function AsyncAnswererInviteTemplate() {
  return (
    <>
      <p><span><strong>Título do e-mail:</strong> FormareTech - Convite para participar</span><br />
      <span><strong>Remetente:</strong> no-reply@formaretech.com.br</span><br /></p>
      <img src="https://files-storage.s3.amazonaws.com/mail_assets/formaretech-logo-mail.png" alt="FormareTech Logo" /><br />
      <p>Olá, João da Silva</p> <br />
      <p>Você foi indicado para responder um(ns) instrumento(s) sobre o desempenho do(s)
      participantes(s) abaixo, que estão em um projeto inserido no sistema IAPP.</p> <br />
      <p>Veja quem solicitou o feedback:</p>
      <p>- João Goulard</p>
      <p>- Pedro Alvares Cabral</p><br />
      <p>Quais instrumentos você deve responder:</p>
      <p>- Instrumento 1 - Até 01/01/2030</p>
      <p>- Instrumento 2 - Até 01/02/2030</p>
      <p>- Instrumento 3 - Até 01/03/2030</p> <br />
      <p>Sua identificação está mantida apenas para controle de resposta e não será apresentada para o
      participante.</p>
      <p>Sua informação se constitui em um material riquíssimo para contribuir no desenvolvimento
      profissional do requisitante. Por isso, pedimos que responda o quanto antes para o progresso do
      participante junto ao programa.</p><br />
      <p>Acesse o link abaixo para responder o(s) formulário(s) de feedback.</p>
      <StyledSpan>https://respondente.formaretech.com.br/</StyledSpan> <br /><br />
      <p>ATENÇÃO! ESSE LINK É PESSOAL E INSTRANSFERÍVEL! NÃO ENCAMINHE ESSE E-MAIL</p><br />
      <p>Qualquer dúvida ou problema, entre em contato conosco através do E-mail: suporte@formaretech.com.br</p> <br />
      <p>Por favor, não responda este e-mail.</p>
      <p>Obrigado!</p>
      <strong>FormareTech</strong>
    </>
  )
}

export default AsyncAnswererInviteTemplate