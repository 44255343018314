import * as d3 from 'd3';
import './css/Barra.css';

let node = document.createElement('div');

/**
 * A partir de um elemento svg com id `graph` cria um gráfico indicando
 * a posição de um grupo de usuários
 *
 * @export
 * @param {Data} data Os dados para criar o gráfico
 * @param {number} width A largura do gráfico
 * @param {number} height A altura do gráfico
 * @param {
 * {
 *   top: number,
 *   left: number,
 *   bottom: number,
 *   right: number
 * }} margin A margem para ser aplicada no gráfico
 * @param {any[]} yaxisLabels As categorias possíveis
 * @param {Function} formatData A função para definir os tamanhos de cada
 * bloco de cor.
 * @example
 * ```
 * function formatData(data) {
 *   return {
 *     name: data.name,
 *     '<10': 10,
 *     '<29': 19,
 *     '<49': 20,
 *     '<69': 20,
 *     '<89': 20,
 *     '<100': 11,
 *   };
 * }
 * ```
 * @param {any[]} limits Os limites para cada área colorida
 * @example ```['<10', '<29', '<49', '<69', '<89', '<100']```
 * @param {Object} colors As cores relacionando os limites com a cor correspondente
 * @param {boolean} hasAreaAround Define se uma área de 5% ao redor da autoavaliação será destacada
 * @param {string} [id] O id do componente
 */
export function getSVGComponent(
  data,
  width,
  height,
  margin,
  yaxisLabels,
  formatData,
  limits,
  colors,
  hasAreaAround,
  id = 'graph',
) {
  // Configurar domínio e tamanho do eixo X
  let x = d3
    .scaleLinear()
    .range([margin.left, width - margin.right - margin.left]);
  // Configurar domínio e tamanho do eixo Y
  let y = d3
    .scaleBand()
    .domain(yaxisLabels)
    .range([margin.top, height - margin.bottom])
    .padding(0.1);

  // Criar o elemento do eixo X
  let xAxis = g =>
    g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(0));
  // Criar o elemento do eixo Y
  let yAxis = g =>
    g
      .attr('transform', `translate(${margin.left},0)`)
      .style('font-size', '17px')
      .call(d3.axisLeft(y).tickSizeOuter(0))
      .call(g => g.selectAll('.domain').remove());

  // Conectar com o elemento svg
  const svg = d3
    .select(`#${id}`)
    .attr('viewBox', [0, 0, width, height])
    .style('overflow', 'visible');


  // Remover o conteúdo antigo do svg
  svg.selectAll('svg > *').remove();

  // Criar a separação de cores do gráfico
  svg
    .append('g')
    .selectAll('rect')
    .data(
      d3
        .stack()
        .keys(limits)
        .offset(d3.stackOffsetExpand)(data.map(formatData)),
    )
    .enter()
    .append('g')
    .attr('fill', d => colors[d.key])
    .selectAll('rect')
    .data(d => d)
    .join('rect')
    .attr('x', d => x(d[0]))
    .attr('y', d => y(d.data.name) + 15)
    .attr('height', y.bandwidth() - 30)
    .attr('width', d => x(d[1]) - x(d[0]));

  // Posicionar marcas do usuário
  svg
    .append('g')
    .attr('fill', '#4e4e4e')
    .selectAll('rect')
    .data(data)
    .join('rect')
    .attr('x', d => x(d.nota / 100))
    .attr('y', d => y(d.name) + 10)
    .attr('height', y.bandwidth() - 20)
    .attr('width', d => 2);

  if (hasAreaAround) {
    // Posicionar marca de compatibilidade
    svg
      .append('g')
      .attr('fill', '#000000')
      .attr('opacity', 0.1)
      .selectAll('rect')
      .data([data[0]])
      .join('rect')
      .attr('x', d => x((d.nota - 5) / 100))
      .attr('y', d => y(margin.top))
      .attr('height', Math.abs(height - margin.bottom))
      .attr('width', d => x(0.1) - margin.left);
  }

  // Posicionar eixo X
  svg.append('g').call(xAxis);
  if (data[0].graficLowLabel && data[0].graficHighLabel) {
    svg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height + 30) + ')')
      .attr('x', -555)
      .style('text-anchor', 'end')
      .style('font-size', '17px')
      .text(data[0].graficLowLabel);

    svg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height + 30) + ')')
      .attr('x', 710)
      .style('text-anchor', 'end')
      .style('font-size', '17px')
      .text(data[0].graficHighLabel);
  } else {
    svg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height + 30) + ')')
      .attr('x', 0)
      .style('text-anchor', 'middle')
      .text('Nota normalizada');
  }

  // Posicionar eixo Y
  svg.append('g').call(yAxis);
  svg
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 0)
    .attr('x', 0 - height / 2)
    .attr('dy', '1em')
    .style('text-anchor', 'middle');
}

export default node;
