import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import { assincronosApi } from 'Services/api';
import styled from 'styled-components';
import AspectosDeIdentidadeGraph from './Graficos/AspectosDeIdentidadeGraph';
import NoResultsFound from '../Components/NoResultsFound';

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

/**
 * ### Componente de Resultados de Aspectos De Identidade
 *
 * /**
 * @author Gustavo Carvalho Silva
 * @since 01/12/2020
 * @props
 * - idProjeto
 * - idParticipante
 * - idAssincrono
 * - idInstrumento
 *
 * @desc Monta os resultados de um instrumento do tipo Aspectos De Identidade
 */
export default class AspectosDeIdentidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resposta: {},
      respondentes: [],
      textosAutoAvaliado: {},
      textosRespondentes: {},

      dimensoesMaisPontuadasAutoAvaliada: {},
      dimensoesMaisPontuadasRespondente: {},

      loading: false,
    }
  }

  getDimensoesMaisPontuadas(answer) {
    let dimensoesAncoras = Object.entries(answer);
    let dimensaoMaisPontuada = '';

    let aux = 0;
    dimensoesAncoras.map(ancora => {
      if (typeof(ancora[1]) === "number" && ancora[1] >= aux) {
        aux = ancora[1];
        dimensaoMaisPontuada = ancora[0];
      }
    })
    this.setState({
      dimensoesMaisPontuadasAutoAvaliada: { ancora: dimensaoMaisPontuada, pontos: aux }
    })
    return dimensaoMaisPontuada
  }

  async getTextoFromAncora(ancora) {
    let res = {};
    if (this.props.participantViewResults) {
      res = await assincronosApi.get(
      `/resultado/aspectos-de-identidade/texto-participante/${ancora}`,
    );
    } else {
      res = await assincronosApi.get(
        `/resultado/aspectos-de-identidade/texto-consultor/${ancora}`,
      );
    }
    return res.data;
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);
      let resposta = response.data;

      if (resposta.length > 0) {
        let dimensoesMaisPontuadasAutoAvaliada = this.getDimensoesMaisPontuadas(resposta[0]);
        let textosAutoAvaliado = {}

        if (dimensoesMaisPontuadasAutoAvaliada)
           textosAutoAvaliado = await this.getTextoFromAncora(dimensoesMaisPontuadasAutoAvaliada);

        this.setState({ resposta: resposta[0], textosAutoAvaliado });
      }

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    const {
      resposta,
      respondentes,
      textosAutoAvaliado,
      dimensoesMaisPontuadasAutoAvaliada,
      dimensoesMaisPontuadasRespondente,
    } = this.state;

    return (
      <>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                dimensoesMaisPontuadasAutoAvaliada.pontos
                  ?
                  <>
                    <AspectosDeIdentidadeGraph
                      resposta={resposta}
                      respondentes={respondentes}
                      idAssincrono={this.props.idAssincrono}
                    />

                    {/* <h5 style={{ fontSize: '16px' }}>
                      {(dimensoesMaisPontuadasAutoAvaliada &&
                        dimensoesMaisPontuadasAutoAvaliada.pontos > 0) ||
                        (dimensoesMaisPontuadasRespondente &&
                          dimensoesMaisPontuadasRespondente.pontos > 0)
                        ? 'Grupo legal'
                        : null}
                    </h5> */}

                    <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textosAutoAvaliado.dimensao) }}></h4>
                    <TextHMTL dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textosAutoAvaliado.texto) }}></TextHMTL>
                  </>
                  :
                  <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
              }

            </>
        }
      </>
    )
  }
}