export default function getDimensoesMaisPontuadas(answer) {
  try {
    const textoEI =
      answer["E"] > answer["I"]
        ? ["E"]
        : answer["E"] === answer["I"]
          ? ["I", "E"]
          : ["I"];
    const textoNS =
      answer["N"] > answer["S"]
        ? ["N"]
        : answer["N"] === answer["S"]
          ? ["N", "S"]
          : ["S"];
    const textoTF =
      answer["T"] > answer["F"]
        ? ["T"]
        : answer["T"] === answer["F"]
          ? ["F", "T"]
          : ["F"];
    const textoJP =
      answer["J"] > answer["P"]
        ? ["J"]
        : answer["J"] === answer["P"]
          ? ["P", "J"]
          : ["P"];
    let textos = [];
    textoEI.forEach(letraEI =>
      textoNS.forEach(letraNS =>
        textoTF.forEach(letraTF =>
          textoJP.forEach(letraJP =>
            textos.push(`${letraEI}${letraNS}${letraTF}${letraJP}`)
          )
        )
      )
    );
    return textos;
  } catch (err) {
    console.error(err);
    return [];
  }

}