exports.getDimensoes = (answer) => {
  let dimensoesAncoras = [
    {
      dimensaoNome: 'ini',
      pontos: answer.ini
    },
    {
      dimensaoNome: 'ep',
      pontos: answer.ep
    },
    {
      dimensaoNome: 'es',
      pontos: answer.es
    },
  ];
  const dimensaoMaisPontuada = dimensoesAncoras.reduce((acc, at) =>
    at.pontos > acc.pontos ? at : acc,
  );
  const dimensoesMaisPontuadas = dimensoesAncoras.filter(
    d => d.pontos === dimensaoMaisPontuada.pontos,
  );
  return dimensoesMaisPontuadas.map(d => d.dimensaoNome);
}