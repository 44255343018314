import React from 'react';
import Chart from "chart.js";

import * as HQGraphServices from './services';
import { notifyError } from 'Services/Notificacoes';
import HQGraphUtils from './HQGraphUtils';
import Messages from 'static/Messages';
import apiCalls from "config/apiCalls";

/**
 * Hierarchy Graph
 */
export default class HQGraph extends React.Component {

  state = {};

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  async loadPerceptions() {
    let perceptions = [];

    let perception = await HQGraphServices.loadPerceptions(this.props.assessmentId, this.props.participantId);
    perceptions.push(perception);


    return perceptions;
  }

  async getCadastroParticipante() {
    const resP = await apiCalls.resultados.getInfoParticipante(
      sessionStorage.getItem("projeto"),
      this.props.participantId
    );

    const participanteProjeto = resP && resP.data ? {
      cargo: resP.data.cargo,
      _idProjeto: sessionStorage.getItem("projeto"),
      nome: resP.data.nome,
      participantId: resP.data._id
    } : null

    return participanteProjeto;
  }

  getPerceptionParticipante(state) {
    return state.perceptions
      .filter(perception => perception)
      .find(perception => perception.userId === this.props.participantId);
  }

  async drawParticipant(state) {
    const cadastroParticipante = await this.getCadastroParticipante();
    const perception = this.getPerceptionParticipante(state);
    let datasets = HQGraphUtils.drawParticipant(state, cadastroParticipante, perception);

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();

    this.participantDatasets = datasets;
  }

  drawHierarquias(state) {
    let datasets = HQGraphUtils.hierarquiasDataset(state);

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();

    this.hierarquiaDatasets = datasets;
  }

  draw(state) {
    var ctx = document.getElementById(this.graphName).getContext("2d");
    this.chart = new Chart(ctx, HQGraphUtils.chartConfig());
  }

  parseHierarquia(hierarquia) {
    hierarquia = hierarquia.map(hierarquia => {
      hierarquia.tempoPreditivo = Number(hierarquia.tempoPreditivo);

      return hierarquia;
    })
  }

  drawIntersections(state) {
    const perception = this.getPerceptionParticipante(state);
    let datasets = HQGraphUtils.drawIntersections({
      state,
      perception,
      participantDatasets: this.participantDatasets,
      hierarquiaDatasets: this.hierarquiaDatasets
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  async drawGraph(state) {
    this.draw(state);
    await this.drawParticipant(state);
    this.drawHierarquias(state);
    this.drawIntersections(state);
  }

  async componentDidMount() {
    let state = {};
    this.graphName = `hq-graph-${this.props.assessmentId}`;

    HQGraphServices.loadSessions(
      this.props.assessmentId
    ).then(res => {

      const sessions = res.data;
      state.sessions = sessions;
      return this.loadPerceptions();
    }).then(async resPerceptions => {
      state.perceptions = resPerceptions.map(perception => perception.data);

      this.cadastroParticipante = await this.getCadastroParticipante(state);

      return HQGraphServices.loadHierarchy({
        projectId: sessionStorage.getItem('projeto'),
        cargo: this.cadastroParticipante.cargo
      });
    }).then(resHierarquia => {

      this.parseHierarquia(resHierarquia);
      state.hierarquia = resHierarquia;

      this.setState({ state });
      var canvas = document.getElementById(this.graphName);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };

      return this.drawGraph(state);
    }).catch(err => {
      this.handleError(err);
      notifyError(Messages.Assessment.msgDrawGraphError);
    })
  }


  render() {
    return <canvas
      id={this.graphName}
      height={this.props.height} />;
  }
}