import _ from 'lodash';

import { Constantes } from "Services/Constantes";
import apiCalls from "config/apiCalls";
import api from "Services/api";
import { withTimeSync, withTime } from "./withTIme";

export { default as Colors } from './colors';
export { default as Calculations} from './calculations';

export function dealInstrumentProperty(instrument, setValue = 0) {
  const i = { ...instrument };

  try {
    if (!i || !i.content) return i;

    if (i.content.includes('storytelling')) i.start.binario = setValue;
    if (
      i.content.includes('case') &&
      i.binario &&
      !i.binario.ativo) {

      i.start.binario = setValue;
    }

    if (i.content.includes('sociometria') || i.content.includes('Estilos')) {
      i.start.grupo = setValue;
      i.start.binario = setValue;
    }

    return i;
  } catch (error) {
    console.error(error);
    return i;
  }
}

export function getUnfinishedInstruments(round = null) {
  if (!round) return;
  const unfinishedInstruments = [];

  const { instrumentos } = round;

  for (const inst of instrumentos) {
    const treatedInstrument = dealInstrumentProperty(inst);

    if (treatedInstrument.start.individual !== -1) {
      for(const key in treatedInstrument.start) {
        if (treatedInstrument.start[key] !== 0 && !unfinishedInstruments.find(i => String(i._id) === String(inst._id))) {
          unfinishedInstruments.push(inst);
        }
      }
    }
  }
  return unfinishedInstruments;
}

export function getActualRound(rodadas = null) {
  let actualRound = null;
  const rounds = rodadas;
  for (let rIndex = 0 ; rIndex < rounds.length ; rIndex++) {
    if (_.isNumber(actualRound)) return actualRound;

    rounds[rIndex].instrumentos.map(i => {
      const treatedInstrument = dealInstrumentProperty(i);

      for (let key in treatedInstrument.start) {
        if (treatedInstrument.start[key] !== 0) actualRound = rIndex;
      }
    });
  }

  return _.isNumber(actualRound) ? actualRound : rounds.length - 1;
}

export function verifyIfRoundsStarted(rounds = []) {
  let started = false;

  for(const round of rounds) {
    const { instrumentos } = round;

    for (const inst of instrumentos) {
      const treatedInstrument = dealInstrumentProperty(inst, -1);

      for(const key in treatedInstrument.start) {
        if (treatedInstrument.start[key] !== -1) started = true;
      }
    }
  }

  return started;
}

/**
 * retorna um índice aleatório da array informada
 *
 * @param {[]} a array informada
 */
export function sortIndex(array) {
  return Math.floor(Math.random() * array.length);
}

export { withTime, withTimeSync };

export async function updateRankingInstrumento(
  instrumentName,
  instrumentId,
  roundIndex,
  projectId,
  dinamicaId
) {
  try {
    console.info("atualizando ranking do instrumento ", instrumentName);
    switch (instrumentName) {
      case Constantes.instrumentoSociometria:
        await apiCalls.dinamica.updateRankingSociometria(
          projectId,
          dinamicaId,
          instrumentId,
          roundIndex
        );
        break;

      case Constantes.instrumentoStoryTellingIndividual2:
        await apiCalls.dinamica.updateRankingStorytelling(
          projectId,
          dinamicaId,
          instrumentId,
          roundIndex
        );
        break;

      case Constantes.instrumentoEstilos:
        await apiCalls.dinamica.updateRankingEstilos(
          projectId,
          dinamicaId,
          instrumentId,
          roundIndex
        );
        break;

      case Constantes.instrumentoBinarioNormalizado:
        // geração da normalização de binário
        await api.get(
          `binary/get-standardized-note-generation/?instrumentId=${instrumentId}`
        );
        await apiCalls.dinamica.updateRankingCase(
          projectId,
          dinamicaId,
          instrumentId,
          roundIndex
        );
        break;

      case Constantes.instrumentoBinario:
        await apiCalls.dinamica.updateRankingCase(
          projectId,
          dinamicaId,
          instrumentId,
          roundIndex
        );
        break;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
* Returns a random integer between min and max
* Using Math.round() will give you a non-uniform distribution!
*/
export function getRandomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatDate(date) {
  let data = new Date(date),
    dia = data.getDate().toString(),
    diaF = dia.length == 1 ? "0" + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = mes.length == 1 ? "0" + mes : mes,
    anoF = data.getFullYear();

  if (isNaN(data.getDate())) return date;

  return diaF + "/" + mesF + "/" + anoF;
}

export function formatDateWithHours(date) {
  let data = new Date(date)
  let dia = data.getDate().toString()
  let diaF = dia.length == 1 ? "0" + dia : dia
  let mes = (data.getMonth() + 1).toString() //+1 pois no getMonth Janeiro começa com zero.
  let mesF = mes.length == 1 ? "0" + mes : mes
  let anoF = data.getFullYear()
  let hour = data.getHours()
  let hourF = String(hour).length == 1 ? "0" + hour : hour
  let min = data.getMinutes()
  let minF = String(min).length == 1 ? `0${min}` : min;

  if (isNaN(data.getDate())) return date;

  return `${diaF}/${mesF}/${anoF} às ${hourF}:${minF}`;
}