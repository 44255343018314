import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  Col,
  Row,
  Container,
  InputGroup,
  InputGroupAddon,
  Input,
  Progress
} from 'reactstrap';
import Politics from "views/Components/Politics";

import './css/Assets.css';

// CORES ////
export const primary = '#012a4a';
export const secondary = '#f1c21a';
export const color = '#fff';

/// ASSETS ///

export const Main = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 150px;
  margin: 50px;
`;
export const BaseLogin = styled.div`
  background-color: #012a4a;
  color: white;

  padding: 40px;
  height: 100%;
  button {
    font-size: 16px;
  }
  .inputLogin {
    background: #001b2f;
    border: 0px;
    height: 40px;
    font-size: 16px !important;
    color: white;

    &::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 16px;
    }
  }
  .before span {
    background: #f0c219;
    border: 0px;
    border-radius: 5px 5px 5px 5px;
    padding: 5px 10px;
  }
`;

const rotate = keyframes`
        0% {
			opacity: 1;
		}
        33% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
`;
const stopped = keyframes`
  from {
    opacity:1;
  }

  to {
    opacity:1;

  }
`;
const Blink = styled.div`
  animation: ${(props) => (props.color === 'danger' ? rotate : stopped)} 0.5s
    linear infinite;
  -webkit-animation-timing-function: steps(1, end);
`;
export const Button = styled.button`
  background: ${(props) => (props.yellow ? '#f0c219' : '#012a4a')};
  color: ${(props) => (props.yellow ? '#012a4a' : '#fff')};
  border: 0px;
  font-size: 12px;
  width: 200px;
  margin: 20px 0px 20px 0px;
  padding: 10px;
  border-radius: 5px;
`;

export class Login extends Component {
  render() {
    return (
      <BaseLogin>
        <div className="app flex-row align-items-center">
          <br /> <br />
          <Container>
            <Row className="justify-content-center">
              <Col sm="12" className="text-center">
                <Logo alt="logo" src={require('./_assets/logo.png')} />
                <InputGroup>
                  <InputGroupAddon addonType="prepend" className="before">
                    🔒
                  </InputGroupAddon>
                  <Input
                    placeholder="Código de Acesso"
                    className="inputLogin"
                  />
                </InputGroup>
                <br />
                <InputGroup>
                  <InputGroupAddon addonType="prepend" className="before">
                    👤
                  </InputGroupAddon>
                  <Input placeholder="CPF" className="inputLogin" />
                </InputGroup>
                <Button yellow className="botao">
                  ACESSAR
                </Button>
                <br />
                <Logo alt="logo" src={require('./_assets/formare_tech_horizontal_branco.png')} />
                <div>
                  <small>
                    <b>Copyright 2024© - Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </BaseLogin>
    );
  }
}

export const Header = styled.div`
  text-align: right;
  padding: 30px;
  background: #002848;
  img {
    width: 50px;
  }
`;

export const User = styled.div`
  text-align: center;

  div {
    width: 100px;
    height: 100px;
    border: solid 2px white;
    -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    border-radius: 50px;
    margin: 0 auto;
    background: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
`;
export const User2 = styled.div`
  text-align: center;

  div {
    width: 50px;
    height: 50px;
    border: solid 1px white;
    -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    border-radius: 50px;
    background: url(${(props) => props.background});
    background-size: cover;
    background-position: center;
  }
`;

export const Socio = styled.button`
  text-align: center;
  background: transparent;
  border: 0px;
  opacity: ${(props) => (props.modal === true ? '1' : '0.5')};
  .active {
    opacity: 0.5;
  }
  :active {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
  img {
    width: 100px;
    border: solid 2px white;
    -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    border-radius: 50px;
  }
  p {
    margin: 20px;
    font-weight: 700;
    color: #012a4a;
  }
`;
export class SociometriaComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  seleciona = () => {
    this.toggle();
    return this.props.name;
    //this.props.action();
  };
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal
    }));
  }
  render() {
    return (
      <Socio onClick={this.seleciona} modal={this.state.modal}>
        <img
          alt="logo"
          src={`https://files-storage.s3.amazonaws.com/${this.props.foto}`}
          style={{
            verticalAlign: "top",
            objectFit: "cover",
          }}
        />
        <p>{this.props.name}</p>
      </Socio>
    );
  }
}

export class UserFoto extends Component {
  render() {
    return (
      <User
        background={
          this.props.foto == ''
            ? `"https://files-storage.s3.amazonaws.com/avatar_default.png"`
            : `"https://files-storage.s3.amazonaws.com/${this.props.foto}"`
        }
      >
        <div alt="logo" onClick={() => this.props.onClick()} />
      </User>
    );
  }
}

export class UserFotoSmall extends Component {
  render() {
    return (
      <User2
        background={
          this.props.foto == ""
            ? `"https://files-storage.s3.amazonaws.com/avatar_default.png"`
            : `"https://files-storage.s3.amazonaws.com/${this.props.foto}"`
        }
      >
        <div alt="logo" />
      </User2>
    );
  }
}

export const UserGreeting = styled.div`
  font-weight: bold;
  text-align: center;
  text-align: center;
  font-size: 12px;
  small {
    font-size: 12px;
  }
  button {
    background: #002a4b;
    color: #fff;
    border: 0px;
    font-size: 12px;
    width: 200px;
    margin: 20px 0px 20px 0px;
  }
  .logo {
    filter: invert(1);
  }
`;

export const Base = styled.div`
  background: white;
  padding: 20px 0px;
  width: 100%;
  p {
    font-size: 14px;
  }
`;
export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin: 10px;
`;

export const Nome = styled.div`
  background: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
`;
export const BaseConclusao = styled.div`
  background: #f2c800;
  padding: 20px;
  text-align: center;

  div {
    font-size: 16px !important;
  }
  .logo {
    filter: invert(1);
    width: 100px;
  }
  button {
    background: #002a4b;
    color: #fff;
    border: 0px;
    font-size: 12px;
    width: 200px;
    margin: 20px 0px 20px 0px;
  }
`;

export const HD = styled.div`
  background: #002a4b;
  padding: 0 20px;
  height: 100px;
  .row,
  .col {
    height: 100px;
  }
  .usr {
    width: 100px;
    height: 100px;
    border: solid 2px white;
    -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.32);
    border-radius: 50px;
    margin-top: 40px;
    background: url(${(props) => props.background});
    background-size: cover;
    background-position: center center;
  }
  .usrName p {
    color: white;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 75px;
  }

  .bloco {
    .lgt {
      margin-left: -80px;
      margin-top: 50px;
      color: white;
      text-decoration: underline;
      position: absolute;
      cursor: pointer;
    }
    img {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 566px) {
    #logo {
      margin-top: 25px;
      width: 100px;
    }
  }

  @media screen and (max-width: 413px) {
    .usrName > p {
      color: #000000;
      padding-top: 0;
    }
    .usrName {
      position: relative;
      bottom: 40px;
      left: 100px;
    }
  }
`;

export class HeaderLogged extends Component {
  render() {
    return (
      <Row id="header-logged">
        <div className='user-area' id="userdata">
          <div className="usr float-left" alt="user">
            <img
              style={{
                verticalAlign: "top",
                objectFit: "cover",
              }}
              src={`https://files-storage.s3.amazonaws.com/avatar_default.png`}
            />
          </div>
          <div className="usrName float-left">
            <p>{this.props.name}</p>
          </div>
        </div>

        <div style={{ color: '#fff', textAlign: 'center' }}>
          <span className='preview-title' style={{ color: '#C4C4C4', fontWeight: 'bolder' }}>PRÉ-VISUALIZAÇÃO </span>
          <span className='preview-label'>Recarregue esta tela ao salvar alterações feitas no case</span>
        </div>

        <div id="logo-wrapper">
          {
            this.props.items && this.props.items.length ? (
              <div>
                {this.props.items.map(i => (
                  <span className={i.className} style={{ marginRight: 50, color: '#fff', cursor: 'pointer' }} onClick={() => {i.onClick()}}>{i.label}</span>
                ))}
              </div>
            ) : null
          }
          <img
            alt="logo"
            id="logo"
            src={require('./_assets/logoheader.png')}
            style={{ paddingTop: 5, fontSize: 12 }}
          />
        </div>
      </Row>
      // <HD
      //   background={

      // >
      //   <Row>
      //     <Col style={{ width: '100px' }}>
      //       <Row>
      //         <Col>
      //           <div className="usr float-left" alt="user" />
      //           <div className="usrName float-left">
      //             <p>{this.props.name}</p>
      //           </div>
      //         </Col>
      //       </Row>
      //     </Col>

      //     <Col className="text-right bloco">
      //       <span
      //         className="lgt"
      //         onClick={this.props.action}
      //         style={{ paddingTop: 25, fontSize: 12 }}
      //       >
      //         Logout
      //       </span>{' '}
      //       <img
      //         alt="logo"
      //         id="logo"
      //         src={require('./_assets/logoheader.png')}
      //         style={{ paddingTop: 5, fontSize: 12 }}
      //       />
      //     </Col>
      //   </Row>
      // </HD>
    );
  }
}

const St = styled.div`
  padding: 10px;
  border: solid 1px gray;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: ${(props) => (props.null === 'null' ? '6px' : '1px')};
  padding-bottom: ${(props) => (props.null === 'null' ? '8px' : '8px')};
  opacity: ${(props) => (props.null === 'null' ? '0.5' : '1')};
  background: ${(props) => (props.null === 'null' ? '#fbfbfb' : '#fff')};

  ${Icon} {
    width: 18px;
    height: 18px;
  }
`;
export class Story extends Component {
  render() {
    if (this.props.tipo === 'null') {
      return (
        <St null={this.props.tipo}>
          <Icon src={require('./_assets/move.gif')} alt="Storytelling" />
          <div>{this.props.story}</div>
        </St>
      );
    } else {
      return (
        <St null={this.props.tipo}>
          <Icon src={require('./_assets/move.gif')} alt="Storytelling" />
          <div>{this.props.story}</div>
        </St>
      );
    }
  }
}
export class Contador extends Component {
  render() {
    return (
      <Row>
        <Col>
          <div>
            <small>
              Tempo Restante: <b>{this.props.counter}</b>
            </small>
            <Progress color={'success'} value={this.props.tempo} />
          </div>
        </Col>
      </Row>
    );
  }
}

export const Cont = styled.div`
  border: solid 1px lightgray;
  padding: 20px;
`;
export class ContadorQst extends Component {
  state = {
    cor: 'success'
  };

  componentWillReceiveProps = () => {
    // console.log(this.props.tempo)
    if (this.props.tempo < 1000 && this.props.tempo > 50) {
      this.state.cor = 'success';
    }
    if (this.props.tempo < 50 && this.props.tempo > 25) {
      this.state.cor = 'warning';
    }
    if (this.props.tempo < 25) {
      this.state.cor = 'danger';
    }
  };

  render() {
    return (
      <Row>
        <Col sm="12">
          <Cont>
            <small>
              A questão termina em: <b>{this.props.counter}</b>
            </small>
            <Blink color={this.state.cor}>
              <Progress color={this.state.cor} value={this.props.tempo} />
            </Blink>
          </Cont>
        </Col>
      </Row>
    );
  }
}

export class ContadorProva extends Component {
  state = {
    cor: 'success'
  };
  componentWillReceiveProps = () => {
    // console.log(this.props.tempo)
    if (this.props.tempo < 100 && this.props.tempo > 50) {
      this.state.cor = 'success';
    }
    if (this.props.tempo < 50 && this.props.tempo > 25) {
      this.state.cor = 'warning';
    }
    if (this.props.tempo < 25) {
      this.state.cor = 'danger';
    }
  };
  render() {
    return (
      <Row>
        <Col sm="12" className="text-left">
          <div>
            <small>
              Tempo Restante: <b>{this.props.counter}</b>
            </small>
            <Blink color={this.state.cor}>
              <Progress color={this.state.cor} value={this.props.tempo} />
            </Blink>
          </div>
        </Col>
      </Row>
    );
  }
}

export const Enunciado = styled.div`
  color: #012a4a;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  p {
    color: #000;
    font-weight: 100;
  }
`;

export const Ft = styled.div`
  margin-top: ${props => props.marginTop || '50px'};
  .logo {
    filter: invert(1);
  }
`;
export class Footer extends Component {
  render() {
    return (
      <Ft marginTop={this.props.marginTop} style={this.props.style} className="text-center">
        <Logo
          className="logo"
          alt="logo"
          src={require('./_assets/formare_tech_horizontal_branco.png')}
        />
        <div>
          <small>
            <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
          </small>
        </div>
      </Ft>
    );
  }
}

export const Textarea = styled.textarea`
  border: solid 1px #d7d7d7;
  font-size: 16px;
  height: 60px;
  width: 90%;
  border-radius: 5px;
  :focus {
    outline: none;
  }
  padding: 10px;
  &::-webkit-input-placeholder {
    color: #d7d7d7;
    font-size: 10px;
  }
`;

export const Clear = styled.div`
  clear: both;
`;

export const Qst = styled.div`
  background: ${(props) => (props.active ? '#fac100' : '#00babe')};
  color: white;
  padding: 10px 12px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  float: left;
  font-size: 14px;

  .next {
    background: lightgray;
  }
`;

export const Fonts = styled.span`
  border: solid 1px lightgray;
  padding: 10px;
  margin-right: 10px;
  button {
    border: 0px;
    background: transparent;
  }
  .a {
    font-size: 12px;
  }
  .aPlus {
    font-size: 18px;
  }
`;

export const Opt = styled.button`
    font-size:12px;
    font-weight:700;
    padding:20px;
    border:solid 1px lightgray;
    margin: 10px 0px;
    width:100%;
    text-align:left;
    background:transparent;


    span{

        /*font-size:14px;*/
    }
    div{
        width:100%;
        height:100%
    }


    :focus{
        background:${(props) => (props.tempo === 0 ? 'white' : '#012a4a')};
        color:#fff;
        outline: none;

    }

    .geral{
        width:100%;

    }

    p{
        font-size:${(props) => props.estilo};

        ::before{
            content: "${(props) => props.contador}";
            font-weight:bold;
        }
    }


`;

const Rad = styled.div`
    width: calc(100% - 36px);
  input{
    opacity:0;
    :checked + label{
      color:white;
      background:#012a4a;

    }
  }
  label{
    color:#012a4a;;
    padding:20px;
    border:solid 1px gray;
    margin:20px;
    background:white;
    width:100%;
    p{
      font-size:${(props) => props.estilo};
      ::before{
            content: "${(props) => props.contador}";
            font-weight:bold;
        }
    }
  }


`;

export class Options extends Component {
  state = {
    cor: 'success'
  };

  componentWillReceiveProps = () => {
    // console.log(this.props.tempo)
    //// LIMPA BOTÃO

    if (this.props.tempo === 100) {
      document.getElementsByName('radio').forEach((bt) => {
        bt.checked = false;
        bt.blur();
        //console.log(bt)
        this.props.sobe();
      });
    }
    if (this.props.tempo < 100 && this.props.tempo > 50) {
      this.state.cor = 'success';
    }
    if (this.props.tempo < 50 && this.props.tempo > 25) {
      this.state.cor = 'warning';
    }
    if (this.props.tempo < 25) {
      this.state.cor = 'danger';
    }
    if (this.props.tempo === 0) {
    }
  };
  render() {
    return (
      <Rad estilo={this.props.font + 'px'} contador={this.props.contador}>
        <input type="radio" name="radio" id={this.props.contador} />
        <label for={this.props.contador}>
          <div
            className="text-left ajuste"
            dangerouslySetInnerHTML={{
              __html: this.props.texto
            }}
          />
        </label>
      </Rad>
      // <Opt
      //   id="bt"
      //   onClick={this.props.onClick}
      //   contador={this.props.contador}
      //   estilo={this.props.font + "px"}
      // >
      //   <div className="geral">
      //     <div
      //       dangerouslySetInnerHTML={{
      //         __html: this.props.texto
      //       }}
      //     />
      //   </div>
      // </Opt>
    );
  }
}

export class Mesa extends Component {
  render() {
    return (
      <div className="text-center">
        <h2>
          Dirija-se para a <br />
          Mesa {this.props.mesa}
        </h2>
        <img src={require('./_assets/mesa.gif')} style={{ width: '50%' }} />
      </div>
    );
  }
}
export const Titulo = styled.p`
  font-size: 20px;
  font-weight: 700;
`;
export default class Assets extends Component {
  render() {
    return (
      <div>
        <Login />
        <Base />
        <Header>
          <img alt="logo" src={require('./_assets/logoheader.png')} />
        </Header>
        <Base>
          <br />

          <UserGreeting>
            <div>
              Bem Vindo(a),
              <Nome>CEZAR ALMEIDA.</Nome>
              <br />
              <small>Não é Você?</small>
              <br />
              <small>
                <b>Fazer Logout</b>
              </small>
              <br />
              <Button>Ir para a dinâmica</Button>
              <br />
              <Footer />
            </div>
          </UserGreeting>
        </Base>
        <BaseConclusao>
          <Logo
            className="logo"
            alt="logo"
            src={require('./_assets/logo.png')}
          />
          <div>
            <small>Fim da dinâmica</small>
            <br />
            Você está liberado!
            <br />
            Entregue seu tablet para o instrutor.
            <br />
            <br />
            Obrigado pela participação!
            <br />
            <br />
            <Button>Voltar para página Inicial</Button>
            <br />
            <br />
            <Footer />
          </div>
        </BaseConclusao>

        <Base>
          <Titulo>Storyteling</Titulo>
          <br />
          <Contador tempo={30}> </Contador>
          <Story story="A. 	Will e o líder da guange se encontram face a face na rua principall da cidade. Os outros bandidos se espalham pelos telhados das cadas. Passa um pouco do meio-dia." />
          <Story
            tipo="null"
            story="A. 	Will e o líder da guange se encontram face a face na rua principall da cidade. Os outros bandidos se espalham pelos telhados das cadas. Passa um pouco do meio-dia."
          />
          <div className="text-center">
            <Button>Enviar</Button>
            <br />
          </div>
          <Footer />
        </Base>
        <Base>
          <Titulo>Sociometria</Titulo>
          <br />
          <Contador tempo={30}> </Contador>
          <Enunciado>
            Questão 01
            <p>
              Escolha três participantes que gostaria de manter em sua equipe de
              trabalho:
            </p>
          </Enunciado>

          <SociometriaComp
            foto={`https://files-storage.s3.amazonaws.com/avatar_default.png`}
            name="Daniel Vicentim"
          />
          <div className="text-center">
            <Button>Enviar</Button>
            <br />
          </div>
          <Footer />
        </Base>
        <Base>
          <Titulo>Critérios</Titulo>
          <br />
          <Contador tempo={30}> </Contador>
          <Enunciado>
            Questão 01
            <p>
              Escolha três participantes que gostaria de manter em sua equipe de
              trabalho:
            </p>
          </Enunciado>

          <div className="text-center">
            <Textarea placeholder="Insira um critério de decisão" />
            <br />
            <br />
            <Button>Enviar</Button>
            <br />
          </div>
          <Footer />
        </Base>

        <Base>
          <HeaderLogged
            user={`https://files-storage.s3.amazonaws.com/avatar_default.png`}
            name="Teste TEsste"
          />
          <br />
          <br />
          <Container>
            <Row>
              <Col>
                <Titulo>Prova</Titulo>
              </Col>
              <Col className="text-right">
                <Icon src={require('./_assets/doubt.png')} alt="Isntrucoes" />
                Instruções
              </Col>
            </Row>
            <br />
            <ContadorProva tempo={30}> </ContadorProva>
            <br />
            <br />
            <Qst>01</Qst>
            <Qst>02</Qst>
            <Qst active>03</Qst>
          </Container>
          <br />
          <br />
          <Container>
            <Row>
              <Col sm="6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eu
                lorem volutpat augue auctor egestas vitae vitae felis. Sed
                auctor tellus et convallis tempor. Integer et nunc sagittis,
                bibendum turpis ut, pulvinar metus. Sed id odio bibendum,
                convallis nulla at, eleifend mi. Integer porta porttitor diam,
                eu cursus eros aliquam ut. Donec ac vehicula quam, vel finibus
                leo. Quisque at lorem ac augue suscipit cursus. Curabitur eget
                tortor nec justo tempor finibus at at velit. Phasellus nulla
                augue, tincidunt nec mi sed, accumsan fermentum lacus. Phasellus
                sed vehicula magna. Curabitur malesuada consequat ligula, eget
                egestas neque luctus id.
                <br />
                <Options name="choice" texto="Alternativa 01" />
                <Options name="choice" texto="Alternativa 02" />
                <Options name="choice" texto="Alternativa 03" />
              </Col>
              <Col sm="6">
                <ContadorQst />
                <br />
                <Fonts>
                  <button className="a">A-</button>
                </Fonts>
                <Fonts>
                  <button className="aPlus">A+</button>
                </Fonts>
              </Col>
            </Row>
          </Container>
          <Container className="text-center">
            <Button>CONFIRMAR</Button>
            <Footer />
          </Container>
        </Base>
      </div>
    );
  }
}

export const BoxArrastavel = styled.div`
  border: solid 1px lightgray;
  display: inline-block;
  width: 200px;
  padding: 20px;
  margin: 20px;
`;

/// FORMATACAO INSTRUCOES
export const Instrucoes = styled.div`
  padding: 20px;
  div {
    text-align: justify;
    font-size: 14px;
  }
  b {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const Instrucoes2 = styled.div`
  padding: 1rem;
  div {
    text-align: left;
    font-size: 14px;
  }
  b {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;
export const Inst = styled.button`
  background: transparent;
  border: 0px;

  :focus {
    outline: 0;
  }
`;

/////////////
