import axios from "axios";
import { Constantes } from "./Constantes";

axios.interceptors.request.use(function (config) {
  config.headers.Authorization =  "Bearer " + sessionStorage.getItem("token");

  return config;
});

const api = axios.create({
  baseURL: Constantes.baseURL,
  headers: {
    common: {
      Authorization: "Bearer " + sessionStorage.getItem("token")
    }
  }
});

export default api;

const REACT_APP_ASSINCRONOS_API_URL = process.env.REACT_APP_ASSINCRONOS_API_URL;
const REACT_APP_REPORTS_API_URL = process.env.REACT_APP_REPORTS_API_URL;

export const assincronosApi = axios.create({
  baseURL: REACT_APP_ASSINCRONOS_API_URL,
  headers: {
    common: {
      Authorization: "Bearer " + sessionStorage.getItem("token")
    }
  }
});

export const reportsApi = axios.create({
  baseURL: REACT_APP_REPORTS_API_URL || 'http://localhost:3333',
  headers: {
    common: {
      Authorization: "Bearer " + sessionStorage.getItem("token")
    }
  }
});
