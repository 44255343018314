import React, { Component } from "react";
import styled from "styled-components";
import { Link as LinkRouter } from 'react-router-dom';

import {
  Row,
  Col,
  Tooltip,
  Progress,
  Collapse,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Badge
} from "reactstrap";
import InputMask from "react-input-mask";
import FileBase64 from "react-file-base64";
import CustomCheckbox from "./CustomCheckbox";
import { maskCPF } from "../Modulos/utils";
import "./css/nomeCliente.css";
import "./css/Assets.css";
import MaxLinesComponent from "./MaxLinesComponent";
import Loading from 'Services/Loading';
import FolderV2 from './FolderV2';

import {
  ActionIcon,
} from "iapp-design-system";

import ImgReports from './images/Relatorios.png';

const MenuIcon = styled(ActionIcon)`
  width: 40px;
`;

const ColStyled = styled(Col)`
  max-width: 100% !important;
  width: 100% !important;
  min-width: 56.6% !important;

  @media(max-width: 780px) {
    min-width: 100% !important;
  }
`

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);
const third = "#cdd8dc";

export const LoginSelectBox = styled.select`
  width: 100%;
  background-color: #5b654e;
  color: #fff;
  border: 1px solid #694e4a;
  padding: 4px;
  font-size: 0.875rem;
  margin-bottom: 20px;
`

export const HeaderSelectBox = styled.select`
  width: 160px;
  background-color: #002a4c;
  color: #fff;
  border: none;
  border-bottom: 1px solid #fff;
  padding: 4px;
  font-size: 0.875rem;
  outline: none;
`

export const LoginSelectOption = styled.option`

`

export const TourP = styled.p`
  font-size: 12px;
  padding-right: 8px;
`

/////RANKING

export const Rank = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;

  .roll {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 10px;
    text-align: center;
  }
  .line-break {
    width: 100%;
  }
  .roll2 {
    display: flex;
    flex-wrap: wrap;
    border-left: solid 1px lightgray;
    border-right: solid 1px lightgray;

    justify-content: space-around;
    text-align: center;
    height: 40px;
    text-align: center;

    div {
      flex-grow: 1;
    }
  }
  .margin {
    margin: 10px;
  }
  .cabecalho {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#18b2be+0,0f929a+100 */
    background: #18b2be; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #18b2be 0%,
      #0f929a 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #18b2be 0%,
      #0f929a 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #18b2be 0%,
      #0f929a 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b2be', endColorstr='#0f929a',GradientType=0 ); /* IE6-9 */
    color: white;
    padding: 5px;
    border-radius: 5px 5px 0px 0px;

    text-align: center;
  }
  .single {
    height: 40px;
    text-align: center;
    padding: 19px;
  }
  .border {
    border: solid 1px lightgray;
  }
  .duplo {
    height: 80px;
  }
  .grow {
    flex-grow: 2;
  }
`;

/*

Objeto

{
  "rodada":1,
  "instrumento":"Storytelling",
  "pontos":{
    "pessoa":"Thiago",
    "pontoIndividual":"10",
    "pontoGrupo":"20",
  }

}


*/

export class Ranking extends Component {
  render() {
    return (
      <Rank>
        <Row>
          {this.props.ranking.map((data, ix) => (
            <Col key="ix">Rodada {ix + 1}</Col>
          ))}
        </Row>
      </Rank>
    );
  }
};

export const BaseLogin = styled.div`
  background-image: url('https://files-storage.s3.amazonaws.com/iapp-assets/iapp_login_bg.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  color: white;
  width: 100vw;
  button {
    font-size: 16px;
  }
  .inputLogin {
    background: #001b2f;
    border: 0px;
    height: 40px;
    font-size: 16px !important;
    color: white;
    &::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 16px;
    }
  }
  .before span {
    background: #f0c219;
    border: 0px;
    border-radius: 5px 5px 5px 5px;
    padding: 5px 10px;
  }
`;

export const LogoHome = styled.img`
  width: 150px;
  margin: 30px;
`;

export const LogoApps = styled.div`
  display: 'flex';
  alignItems: 'center';
  justifyContent: 'center';
  flexDirection: 'row';
  margin-top: 50px;
  img {
    width: 70px;
    margin-right: 12px;
  }
`;

export const FormareRedirectButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const BaseMenu = styled.div`
  background: ${third};
  padding: 10px;
  height: 100% !important;
  text-align: center;
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin-bottom: 20px;
  }
  .resultados {
    list-style: none;
    margin-bottom: 0px;
    border-bottom: solid 1px black;
  }
  .resultados:last-child {
    list-style: none;
    margin-bottom: 0px;
  }
`;

const Ico = styled.button`
  border: 0px;
  height: 40px;
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  padding: 10px;
  border-radius: 5px;
  img {
    width: 18px;
    filter: invert(100%);
  }
  :focus {
    outline: none;
  }
`;

const IcoResult = styled.button`
  border: 0px;
  height: 40px;
  background: #fff;
  padding: 10px;
  img {
    width: 18px;
  }
  :focus {
    outline: none;
  }
`;

export const Base = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
`;
export class IconProjeto extends Component {
  state = {
    isToolTipOpen: false
  };

  toggleToolTip() {
    this.setState(prev => ({ isToolTipOpen: !prev.isToolTipOpen }));
  }

  render() {
    return (
      <span>
        <Ico id={this.props.id} onClick={this.props.onClick}>
          {this.props.hasPendencies ? <span className="pendencies-badge2"></span> : ""}
          <img
            alt={this.props.icon}
            src={require("./images/" + this.props.icon + ".gif")}
          />
        </Ico>
        <Tooltip
          placement="top"
          isOpen={this.state.isToolTipOpen}
          target={this.props.id}
          delay={{ show: 25, hide: 25 }}
          toggle={() => this.toggleToolTip()}
        >
          {this.props.tip}
        </Tooltip>
      </span>
    );
  }
}

export class ImageProjeto extends Component {
  state = {
    isToolTipOpen: false
  };

  toggleToolTip() {
    this.setState(prev => ({ isToolTipOpen: !prev.isToolTipOpen }));
  }

  render() {
    return (
      <span>
        <MenuIcon src={this.props.icon}
          alt={this.props.alt}
          id={this.props.id}
          onClick={this.props.onClick}
        />
        <Tooltip
          placement="top"
          isOpen={this.state.isToolTipOpen}
          target={this.props.id}
          delay={{ show: 25, hide: 25 }}
          toggle={() => this.toggleToolTip()}
        >
          {this.props.tip}
        </Tooltip>
      </span>
    );
  }
}

export class IconResultado extends Component {
  state = {
    isToolTipOpen: false
  };

  toggleToolTip() {
    this.setState(prev => ({ isToolTipOpen: !prev.isToolTipOpen }));
  }

  render() {
    return (
      <span>
        <IcoResult id={this.props.id} onClick={this.props.onClick}>
          <img
            alt={this.props.icon}
            src={require("./images/" + this.props.icon + ".gif")}
          />
        </IcoResult>
        <Tooltip
          placement="top"
          isOpen={this.state.isToolTipOpen}
          target={this.props.id}
          delay={{ show: 0, hide: 0 }}
          toggle={() => this.toggleToolTip()}
        >
          {this.props.tip}
        </Tooltip>
      </span>
    );
  }
}

export class MenuProjeto extends Component {
  state = {
    collapse: false
  };

  /// funcoes de caminho MENU LATERAL
  goRanking = () => {
    window.location.href = "/#/clientes/projetos/resultados/sincronos";
  };
  goRankingProva = () => {
    window.location.href = "/#/clientes/projetos/resultados/provas";
  };

  toggle = () => {
    this.setState(state => ({ collapse: !this.state.collapse }));
  };

  render() {
    return (
      <BaseMenu>
        <ul>
          <li>
            <IconProjeto
              id="icon-projeto-resumo"
              tip="Configurar"
              icon="eye"
              onClick={this.props.resumoProjeto}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-assincronos"
              tip="Assíncronos"
              icon="user"
              onClick={this.props.goAssincronos}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-prova"
              tip="Provas"
              icon="test"
              onClick={this.props.goProvas}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-sincronos"
              tip="Síncronos"
              icon="table"
              onClick={this.props.goSincronos}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-assessment"
              tip="Assessments"
              icon="meeting"
              onClick={this.props.goAssessment}
            />
          </li>
          <li>
            <IconProjeto
              id="icon-projeto-resultado"
              tip="Resultados"
              icon="results"
              onClick={this.toggle}
            />
          </li>
          <Collapse isOpen={this.state.collapse}>
            <ul>
              <li className="resultados">
                <IconResultado
                  id="icon-resultados-participantes"
                  // id="icon-projeto-sincronos2"
                  tip="Resultados Participantes"
                  icon="user"
                  onClick={this.props.goResultadosSincronos}
                />
              </li>
              <li className="resultados">
                <IconResultado
                  id="icon-resultados-assesment"
                  // id="icon-projeto-assessment2"
                  tip="Resultados Assesment"
                  icon="test"
                  onClick={this.goRankingProva}
                />
              </li>
              <li className="resultados">
                <IconResultado
                  id="icon-resultados-assincronos"
                  // id="icon-projeto-resultado2"
                  tip="Resultados Assíncronos"
                  icon="table"
                  onClick={this.props.goResultadosAssincronos}
                />
              </li>
              <li className="resultados">
                <IconResultado
                  id="icon-resultados-sincronos"
                  // id="icon-projeto-resultado2"
                  tip="Resultados Síncronos"
                  icon="meeting"
                  onClick={this.props.goResultadosAssincronos}
                />
              </li>
              <li className="resultados">
                <IconResultado
                  id="icon-resultados-ranking"
                  // id="icon-projeto-resultado2"
                  tip="Ranking"
                  icon="grupo"
                  onClick={this.goRanking}
                />
              </li>
            </ul>
          </Collapse>
        </ul>
      </BaseMenu>
    );
  }
}

const Logo = styled.div`
  div {
    display: inline-block;
    background: transparent;
    margin-top: 5px;
    img {
      width: 70px;
      height: 70px;
      float: left;
      border: solid 1px lightgray;
    }
    span {
      font-weight: bold;
      padding: 60px 0px 20px 20px;
      float: left;
      font-size: 18px;
      padding-right: 50px;
    }
  }
`;

export class Cliente extends Component {
  render() {
    return (
      <Logo>
        <div>
          <img style={{ borderRadius: '100%' }}
            src={this.props.logo ?
              `https://files-storage.s3.amazonaws.com/${this.props.logo}` :
              'https://files-storage.s3.amazonaws.com/enterprise_default.png'}
            alt={this.props.cliente} />
        </div>
      </Logo>
    );
  }
}


export class ClienteAvatar extends Component {
  render() {
    const Image = styled.img`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      vertical-align: top;
      object-fit: cover;
    `;
    return (
      <Image
        src={this.props.logo ?
          `https://files-storage.s3.amazonaws.com/${this.props.logo}` :
          'https://files-storage.s3.amazonaws.com/enterprise_default.png'}
        alt={this.props.cliente} />
    );
  }
}


export const BaseProjeto = styled.div`
  background: #f7f8f8;
  width: ${props => props.disableDynamicWidth ? '100%' : 'calc(100% - 54px)'};
  padding: ${props =>
    props.className && props.className.includes("without-padding")
      ? 0
      : "20px"};
`;
export const Bread = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  ul {
    display: inline;
    padding: 0px;
    margin: 0;
  }
  li {
    list-style: none;
    display: inline-block;
    padding-left: 5px;
    font-weight: 300;
  }
  li:last-child {
    font-weight: bold;
    font-size: 12px;
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    font-size: calc(10px + (12 - 10) * ((100vw - 1000px) / (1500 - 1000)));
    li:last-child {
      font-size: calc(12px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
    }
  }
  @media (min-width: 1500px) {
    font-size: 12px;
    li:last-child {
      font-size: 14px;
    }
  }
`;
export class Breadcrumb extends Component {
  render() {
    let itens = this.props.itens;
    var res = itens.split("/");

    return (
      <Bread>
        <img src={require("../../../assets/img/seta-breadcumb.png")} alt="" />
        <ul>
          {res.map((item, index) => (
            <li key={index}>{item + "  / "} </li>
          ))}
        </ul>
      </Bread>
    );
  }
}

export class Lista extends Component {
  render() {
    return <div />;
  }
}

export const It = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "white" : "transparent")};
  border: ${props =>
    props.linha % 2 === 0 ? "solid 1px lightgray" : "solid 1px transparent"};
  width: 100%;
  padding: 10px 0;
  font-size: 12px;
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  small {
    font-weight: bold;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin: 5px;
    display: inline;
  }

  p {
    margin: 0px;
  }
`;
const ItCli = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "white" : "transparent")};
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
  small {
    font-weight: bold;
  }
  ul {
    margin: 0px;
    padding: 0px;
  }
  li {
    list-style: none;
    margin: 5px;
    display: inline;
  }
  .status {
    color: ${props => (props.status === "APROVADO" ? "green" : "orange")};
  }
`;

const Status = styled.div`
  color: white;
  background: ${props => (props.status === "Aprovado" ? "#009a3d" : "")};
  background: ${props =>
    props.status === "Liberado ao Participante" ? "#8dc300" : ""};
  background: ${props => (props.status === "Em Andamento" ? "#00a0bc" : "")};
  font-size: 10px;
  padding: 10px;
  text-align: center;
`;

export class Item extends Component {
  configurarDinamica = () => {
    this.props.configurarDinamica(this.props.linha);
  };
  configurarGrupo = () => {
    this.props.configurarGrupo(this.props.linha);
  };
  configurarRodadas = () => {
    this.props.configurarRodadas(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  results = () => {
    this.props.results(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <It linha={this.props.linha}>
        <Row>
          <Col sm="8">
            <Row>
              <Col>
                <small>Dinâmica</small>
                <br />
                {this.props.nome}
              </Col>
              <Col>
                <small>Facilitadores</small>
                <br />
                {this.props.facilitadores}
              </Col>
              <Col>
                <small>Data</small>
                <br />
                {this.props.data}
              </Col>
              <Col>
                <small>Horário</small>
                <br />
                {this.props.hora}
              </Col>
              <Col>
                <small>Código de Acesso</small>
                <br />
                {this.props.codigoAcesso}
              </Col>
            </Row>
          </Col>
          <Col sm="4">
            <Row>
              <Col>
                <ul>
                  <li>
                    <IconProjeto
                      id="icon-config"
                      tip="Configurar"
                      onClick={this.configurarDinamica}
                      icon="cog"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-participantes"
                      hasPendencies={this.props.participantes && this.props.participantes.length <= 0}
                      tip="Participantes"
                      onClick={this.configurarParticipantes}
                      icon="grupo"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-rodadas"
                      hasPendencies={this.props.invalidRounds}
                      tip="Rodadas"
                      onClick={this.configurarRodadas}
                      icon="arrows"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-grupo"
                      hasPendencies={this.props.invalidGroups}
                      tip="Grupos"
                      onClick={this.configurarGrupo}
                      icon="partner"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      hasPendencies={this.props.invalidInstruments}
                      tip="Acompanhar"
                      onClick={this.acompanhar}
                      icon="eye"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-results"
                      tip="Resultados"
                      onClick={this.results}
                      icon="Resultados"
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      tip="Arquivar"
                      onClick={this.arquivar}
                      icon="delete"
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </It>
    );
  };
  render() {
    return this.montaTela();
  }
}
export const HeaderTable = styled.div`
  height: 5vh;
`;

export class ItemAssincronoAcomp extends Component {
  configurarAssincrono = () => {
    this.props.configurarAssincrono(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <React.Fragment>
        <It linha={this.props.linha}>
          <Row>
            <Col sm="9">
              <Row>
                <Col sm="1" className="text-center">
                  <img
                    // src={require("./images/" + this.props.foto)}

                    src={
                      this.props.foto
                        ? this.props.foto
                        : require("./images/user.gif")
                    }
                    alt={this.props.cliente}
                    style={{ width: 30 }}
                  />
                </Col>
                <Col sm="2">
                  <p>{this.props.nome}</p>
                </Col>
                <Col sm="1">
                  <p>{this.props.cargo}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.coluna}</p>
                </Col>

                <Col sm="3">
                  <p>{this.props.realizacao}</p>
                </Col>
                <Col sm="3">
                  <p>{this.props.expira}</p>
                </Col>
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Col sm="6" style={{ paddingTop: 10 }}>
                  <Progress bar color="success" value="100">
                    {this.props.progresso}
                  </Progress>
                </Col>
                <Col sm="6" style={{ paddingTop: 10 }}>
                  <Icon icon="eye" />
                  <Button
                    onClick={() => this.props.enviarEmail(this.props.userId)}
                  >
                    <Icon icon="email" />
                  </Button>
                  <Icon icon="delete" />
                </Col>
              </Row>
            </Col>
          </Row>
        </It>
      </React.Fragment>
    );
  };
  render() {
    return this.montaTela();
  }
}
export class ProvaRanking extends Component {
  configurarAssincrono = () => {
    this.props.configurarAssincrono(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };

  montaTela = () => {
    return (
      <React.Fragment>
        <It linha={this.props.linha}>
          <Row>
            <Col sm="12">
              <Row>
                <Col sm="1">
                  <input
                    type="checkbox"
                    onClick={() => this.props.toggleSelected(this.props.nome)}
                  />
                </Col>
                <Col sm="1" className="text-center">
                  <img
                    // src={require("./images/" + this.props.foto)}

                    src={
                      this.props.foto
                        ? this.props.foto
                        : require("./images/user.gif")
                    }
                    alt={this.props.cliente}
                    style={{ width: 30 }}
                  />
                </Col>
                <Col sm="3">
                  <p>{this.props.nome}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.rk}</p>
                </Col>
                <Col sm="1">
                  <p>{this.props.acertos}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.notaRn}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.notaRn2}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </It>
      </React.Fragment>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemProvaAssincronoAcomp extends Component {
  getChecked() {
    let { selecionados, id } = this.props;
    return (
      selecionados.find(s => s.id === id) &&
      selecionados.find(s => s.id === id).checked
    );
  }
  render() {
    const { id } = this.props;
    const checked = this.getChecked();
    // configurarAssincrono = () => {
    //   this.props.configurarAssincrono(this.props.linha);
    // };
    // configurarParticipantes = () => {
    //   this.props.configurarParticipantes(this.props.linha);
    // };
    // acompanhar = () => {
    //   this.props.acompanhar(this.props.linha);
    // };
    // arquivar = () => {
    //   this.props.arquivar(this.props.linha);
    // };

    // montaTela = () => {
    //   let { selecionados, cpf, id } = this.props;
    //   let checked = false;
    //   if (
    //     selecionados.find(s => s.id === id) &&
    //     selecionados.find(s => s.id === id).checked
    //   )
    //     checked = true;

    return (
      <React.Fragment>
        <It linha={this.props.linha}>
          <Row>
            <Col
              sm="1"
              style={{ paddingTop: 10, paddingLeft: 26 }}
              className="text-center"
            >
              <input
                onChange={e => this.props.onCheckBoxChanged(e, id)}
                type="checkbox"
                checked={checked}
              ></input>
            </Col>
            <Col sm="1" className="text-center">
              <img
                // src={require("./images/" + this.props.foto)}

                src={
                  this.props.foto
                    ? this.props.foto
                    : require("./images/user.gif")
                }
                alt={this.props.cliente}
                style={{ width: 30 }}
              />
            </Col>
            <Col sm="2">
              <p onClick={() => this.props.modal(this.props.userId)}>
                {this.props.nome.substring(0, 10) + " ..."}
              </p>
            </Col>
            <Col sm="2">
              <p>{maskCPF(this.props.cpf)}</p>
            </Col>
            <Col sm="1">
              <StatusOnline
                status={
                  this.props.status
                    ? "Online"
                    : this.props.status === undefined
                      ? "Novo"
                      : "Offline"

                  // this.props.status
                }
              />
            </Col>

            <Col sm="2" style={{ paddingTop: 20 }}>
              <Progress
                className="progress-xs"
                color="warning"
                value={this.props.tempoDeProva}
              />
            </Col>
            <Col sm="1">
              <p>{this.props.progresso}</p>
            </Col>
            <Col sm="1">
              <p>{this.props.ranking}</p>
            </Col>
            <Col sm="1">
              <p
                onClick={() =>
                  this.props.autoriza(
                    [
                      {
                        id: this.props.userId,
                        autorizado: !this.props.revogado
                      }
                    ],
                    true
                  )
                }
              >
                {this.props.revogado}
              </p>
            </Col>
          </Row>
        </It>
      </React.Fragment>
    );
  }
}

export class ItemParticipante extends Component {
  configurarAssincrono = () => {
    this.props.configurarAssincrono(this.props.linha);
  };
  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };
  acompanhar = () => {
    this.props.acompanhar(this.props.linha);
  };
  arquivar = () => {
    this.props.arquivar(this.props.linha);
  };
  toggle = e => {
    this.props.onChange(
      e,
      this.props.participante._id || this.props.participante.id
    );
  };

  montaTela = () => {
    /* STATUS DO PARTICIPANTE /*
    /*
    let status = this.props.status;
    if (typeof status === "undefined" || typeof status === null)
      status = "NOVO";
    else if (status === false) status = "OFF";
    else if (status) status = "ON";
    */

    // checkbox checked prop
    let checked = false;

    if (this.props.vincular) {
      if (
        this.props.vincular.find(v => {
          console.log(v);
          return (
            v === (this.props.participante._id || this.props.participante.id)
          );
        })
      )
        checked = true;
    } else if (this.props.desvincular) {
      if (
        this.props.desvincular.find(
          v => v === (this.props.participante._id || this.props.participante.id)
        )
      )
        checked = true;
    }
    if (this.props.shouldChangeStatus) {
      if (
        this.props.shouldChangeStatus.find(v => {
          return (
            v === (this.props.participante._id || this.props.participante.id)
          );
        })
      )
        checked = true;
    }

    return (
      <>
        <It linha={this.props.linha} id="it-padding">
          <Row className="full-width">
            <Col sm="12">
              <Row className="full-width">
                <Col sm="2" className="text-center">
                  <Row className="full-width">
                    <Col sm="6">
                      <CustomCheckbox onClick={this.toggle} checked={checked} />
                    </Col>
                    <Col sm="6">
                      <img
                        // src={require("./images/" + this.props.foto)}

                        src={
                          this.props.foto
                            ? this.props.foto
                            : require("./images/user.gif")
                        }
                        alt={this.props.cliente}
                        style={{ width: 30 }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="3">
                  <MaxLinesComponent maxLines="2">
                    {this.props.nome}
                  </MaxLinesComponent>
                </Col>
                {this.props.facilitador ? null : (
                  <Col sm="2">
                    <p>{this.props.cargo}</p>
                  </Col>
                )}
                <Col sm={this.props.facilitador ? "3" : "2"}>
                  <p>{this.props.cpf}</p>
                </Col>
                <Col sm="2">
                  <p>{this.props.email}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </It>
      </>
    );
  };
  render() {
    return this.montaTela();
  }
}
export class ItemCliente extends Component {
  // configurarDinamica = () => {
  //   this.props.configurarDinamica(this.props.linha);
  // };
  // configurarGrupo = () => {
  //   this.props.configurarGrupo(this.props.linha);
  // };
  // configurarRodadas = () => {
  //   this.props.configurarRodadas(this.props.linha);
  // };
  configurarCliente = () => {
    this.props.configurarCliente(this.props.linha);
  };
  listarProjetos = () => {
    this.props.listarProjetos(this.props.linha);
  };
  excluir = () => {
    this.props.excluir(this.props.linha);
  };

  setLocalStorageProject = (projectId, clientId) => {
    sessionStorage.setItem('projeto', projectId);
    sessionStorage.setItem('cliente', clientId);
  }

  montaTela = () => {
    return (
      <ItCli linha={this.props.linha}>
        <Row>
          <Col sm="2">
            <Cliente
              logo={this.props.logo}
            />
          </Col>
          <Col style={{ fontSize: 12 }} sm="7">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Nome do Cliente</span>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col sm="3">
                    <span style={{ fontWeight: 'bold' }}>UF</span>
                    <br />
                    {this.props.uf ? this.props.uf : "-"}
                  </Col>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Último Projeto</span>
                    <br />
                    {this.props.ultimoprojeto}
                  </Col>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Orçamento Total</span>
                    <br />
                    {"R$ " + this.props.orcamento}
                  </Col>
                </Row>
              </Col>
              <Col sm="12">
                <Row>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Segmento</span>
                    <br />
                    {this.props.segmento}
                  </Col>
                  <Col sm="3">
                    <span style={{ fontWeight: 'bold' }}>Cidade</span>
                    <br />
                    {this.props.cidade ? this.props.cidade : "-"}
                  </Col>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Data da última alteração</span>
                    <br />
                    {this.props.dataAlt}
                  </Col>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>Orçamento Consumido</span>
                    <br />
                    {"R$ " + this.props.orcamentoConsumido}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="3">
            <div>
              <Col className="text-right">
                <ul style={{ marginTop: "30px", display: 'flex', justifyContent: 'flex-end' }}>
                  <li>
                    <IconProjeto
                      id={`icon-config-${this.props.linha}`}
                      icon="cog"
                      tip="Configurar"
                      onClick={this.configurarCliente}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id={`icon-list-${this.props.linha}`}
                      icon="list"
                      tip="Listar Projetos"
                      onClick={this.listarProjetos}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id={`icon-del-${this.props.linha}`}
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                </ul>
              </Col>
            </div>
          </Col>
          <Row>
            <Col sm={5}>
            </Col>
            <ColStyled sm={6} style={{ padding: 0 }}>
              <FolderV2 nome='Projetos' show={false}>
                {
                  this.props.nomeProjetos.map(p => {

                    return (
                      <Row style={{ paddingBottom: 5, borderBottom: '1px solid #c9c9c9', fontSize: 12 }}>
                        <Col style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 10 }} sm={3}>
                          <span>{p.nomeProjeto}</span>
                        </Col>
                        <Col sm={9} className="text-center" style={{ marginRight: 0 }}>
                          <ul style={{ marginTop: "30px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginRight: '-2px' }}>
                            <li onClick={() => this.setLocalStorageProject(p.idProjeto, p.idCliente)}>
                              <LinkRouter to="/clientes/projetos/assincronos">
                                <IconProjeto
                                  id="icon-projeto-assincronos"
                                  tip="Assíncronos"
                                  icon="user"
                                />
                              </LinkRouter>
                            </li>
                            <li onClick={() => this.setLocalStorageProject(p.idProjeto, p.idCliente)}>
                              <LinkRouter to="/clientes/projetos/provas">
                                <IconProjeto
                                  id="icon-projeto-prova"
                                  tip="Provas"
                                  icon="test"
                                />
                              </LinkRouter>
                            </li>
                            <li onClick={() => this.setLocalStorageProject(p.idProjeto, p.idCliente)}>
                              <LinkRouter to="/clientes/projetos/dinamicas">
                                <IconProjeto
                                  id="icon-projeto-sincronos"
                                  tip="Síncronos"
                                  icon="table"
                                />
                              </LinkRouter>
                            </li>

                            <li onClick={() => this.setLocalStorageProject(p.idProjeto, p.idCliente)}>
                              <LinkRouter to="/clientes/projetos/assessments">
                                <IconProjeto
                                  id="icon-projeto-assessment"
                                  tip="Assessments"
                                  icon="meeting"
                                />
                              </LinkRouter>
                            </li>
                            <li onClick={() => console.log('results')}>
                              <IconProjeto
                                id="icon-projeto-results"
                                tip="Resultados"
                                icon="results"
                              />
                            </li>
                            <li onClick={() => this.setLocalStorageProject(p.idProjeto, p.idCliente)}>
                              <LinkRouter to="/clientes/projetos/reports">
                                <ImageProjeto
                                  icon={ImgReports}
                                  alt="Relatórios"
                                  id="icon-projeto-reports"
                                  tip="Relatórios"
                                />
                              </LinkRouter>
                            </li>
                          </ul>
                        </Col>
                      </Row>)
                  })}
              </FolderV2>
            </ColStyled>
          </Row>
        </Row>
      </ItCli>
    );
  };
  render() {
    return this.montaTela();
  }
}

export class ItemRelatorio extends Component {
  render() {
    return (
      <It linha={this.props.linha}>
        <Row>
          <Col sm="8">
            <Row>
              <Col sm="12">
                <small>Nome do Relatório</small>
              </Col>
            </Row>

            {this.props.dados !== undefined
              ? this.props.dados.map((item, index) => (
                <Row key={index}>
                  <Col sm="3">
                    <p>
                      <input type="checkbox" /> {item.nome} - <b>ver.</b>{" "}
                      {item.versao}
                    </p>
                  </Col>

                  <Col sm="2">
                    <p>{item.data}</p>
                  </Col>
                  <Col sm="3">
                    <Status className="status" status={item.status}>
                      {item.status}
                    </Status>
                  </Col>
                  <Col sm="4">
                    <p>Revisado por Teste {item.pessoaStatus}</p>
                  </Col>
                </Row>
              ))
              : ""}
          </Col>
          <Col sm="4">
            <Row className=" h-100 d-table">
              <Col className="text-center d-table-cell align-middle">
                <ul>
                  <li>
                    <IconProjeto
                      id="icon-projeto"
                      icon="edit"
                      tip="Editar"
                      onClick={this.configurarProjeto}
                    />
                  </li>

                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Revisar"
                      onClick={this.resumo}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-projeto"
                      icon="edit"
                      tip="Editar"
                      onClick={this.configurarProjeto}
                    />
                  </li>

                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Revisar"
                      onClick={this.resumo}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </It>
    );
  }
}
export class ItemProjeto extends Component {
  configurarProjeto = () => {
    this.props.configurarProjeto(this.props.linha);
  };

  configurarHierarquias = () => {
    this.props.configurarHierarquias(this.props.linha);
  }

  configurarParticipantes = () => {
    this.props.configurarParticipantes(this.props.linha);
  };

  resumo = () => {
    this.props.resumo(this.props.linha);
  };
  excluir = () => {
    this.props.excluir(this.props.linha);
  };

  montaTela = () => {
    return (
      <ItCli linha={this.props.linha}>
        <Row>
          <Col sm="9">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Nome do Projeto</small>
                    <br />
                    {this.props.nome}
                  </Col>
                  <Col sm="3">
                    <small>Área de atuação</small>
                    <br />
                    {this.props.atuacao ? this.props.atuacao : "-"}
                  </Col>
                  <Col>
                    <small>Data de Realização</small>
                    <br />
                    {this.props.realizacao}
                  </Col>
                  <Col>
                    <small>Orçamento Total</small>
                    <br />
                    {"R$ " + this.props.orcamento}
                  </Col>
                </Row>
              </Col>
              <Col sm="12">
                <Row>
                  <Col>
                    <small>Gestor</small>
                    <br />
                    {this.props.gestor ? this.props.gestor : "-"}
                  </Col>
                  <Col sm="3">
                    <small>Participantes</small>
                    <br />
                    {this.props.participantes}
                  </Col>
                  <Col>
                    <small>Status</small>
                    <br />
                    {this.props.status}
                  </Col>
                  <Col>
                    <small>Orçamento Consumido</small>
                    <br />
                    {"R$ " + this.props.orcamentoConsumido}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm="3">
            <Row>
              <Col className="text-center">
                <ul style={{ marginTop: "30px" }}>
                  <li>
                    <IconProjeto
                      id="icon-projeto"
                      icon="cog"
                      tip="Configurar"
                      onClick={this.configurarProjeto}
                    />
                  </li>

                  <li>
                    <IconProjeto
                      id="icon-grupo"
                      icon="grupo"
                      tip="Configurar Participantes"
                      onClick={this.configurarParticipantes}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-grupo"
                      icon="hierarquia"
                      tip="Configurar Hierarquias"
                      onClick={this.configurarHierarquias}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-acompanhar"
                      icon="eye"
                      tip="Resumo"
                      onClick={this.resumo}
                    />
                  </li>
                  <li>
                    <IconProjeto
                      id="icon-del"
                      icon="delete"
                      tip="Excluir"
                      onClick={this.excluir}
                    />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItCli>
    );
  };
  render() {
    return this.montaTela();
  }
}

const ItParticipanteProjeto = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "white" : "transparent")};
  width: 100%;
  padding: 20px;
  .foto {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: solid 1px #fff;
    -webkit-box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 4px 5px -1px rgba(0, 0, 0, 0.75);
    float: left;
    background: url(${props => props.background});
    background-size: cover;
    background-position: center center;
  }
  .padd {
    padding-top: 30px;
  }
`;

export const Del = styled.button`
  border: 0px;
  background: transparent;

  :focus {
    outline: none;
  }
  img {
    width: 30px;
  }
`;

export class ItemParticipanteDinamica extends Component {
  analisarExclusao = () => {
    this.props.analisarExclusao(this.props.participante);
  };
  callFotoParticipante = () => {
    this.props.callFotoParticipante(
      this.props.participante._id || this.props.participante.id
    );
  };
  onClick = () => {
    /*
    this.props.atualizarParticipante(this.props.cpf);
    */
  };

  getFiles = files => { };
  render() {
    // status do participante
    let status = this.props.status;
    if (typeof status === "undefined" || typeof status === undefined)
      status = "NOVO";
    else if (status === false) status = "OFF";
    else if (status) status = "ON";

    // checkbox checked prop
    let checked = false;
    if (this.props.vincular) {
      if (
        this.props.vincular.find(
          v => v === this.props.participante._id || this.props.participante.id
        )
      )
        checked = true;
    } else if (this.props.desvincular) {
      if (this.props.desvincular.find(v => v === this.props.participante._id))
        checked = true;
    }

    return (
      <ItParticipanteProjeto linha={this.props.linha}>
        <Row>
          <Col sm="12">
            <Row>
              <Col
                sm="1"
                style={{ paddingTop: "20px" }}
                className="text-center"
              >
                <input
                  type="checkbox"
                  onChange={this.props.onChange}
                  checked={checked}
                />
              </Col>
              <Col sm="2">
                <img
                  // src={require("./images/" + this.props.foto)}
                  id={`foto-${this.props.participante._id}`}
                  src={
                    this.props.foto
                      ? this.props.foto
                      : require("./images/user.gif")
                  }
                  alt={this.props.cliente}
                  className="foto"
                />
              </Col>
              <Col sm="2">
                <span onClick={this.onClick}>{this.props.nome}</span>
              </Col>
              <Col sm="1">
                <span onClick={this.onClick}>{status}</span>
              </Col>
              <Col sm="3">
                <span>{this.props.cpf}</span>
              </Col>
              <Col sm="3">
                <span>{this.props.email}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItParticipanteProjeto>
    );
  }
}
export class ItemParticipanteProjeto extends Component {

  callFotoParticipante = () => {
    this.props.callFotoParticipante(this.props.participante._id);
  };
  onClick = () => {
    this.props.editar();
  };

  getFiles = files => { };
  render() {
    let { cpf, participante } = this.props;

    let hierarquiaOptions;
    if (this.props.hierarquia) {
      hierarquiaOptions = this.props.hierarquia.map(cargo => (
        <option key={cargo.cargo}>{cargo.cargo}</option>
      ));
    }
    return (
      <ItParticipanteProjeto
        linha={this.props.linha}
        background={
          this.props.foto ? this.props.foto : require("./images/user.gif")
        }
      >
        <Row>
          <Col sm="12">
            <Row>
              <Col sm="0-1">
                <input
                  type="checkbox"
                  checked={this.props.selecionado}
                  onChange={e => this.props.onSelect(e, cpf)}
                />
              </Col>
              {/* FOTO */}
              <Col sm="1" style={{ cursor: "pointer" }}>
                <img
                  //src={require("./images/" + this.props.foto)}
                  id={`foto-${this.props.participante._id}`}
                  alt={this.props.cliente}
                  className="foto"
                  onClick={this.onClick}
                  style={{ width: "64px", height: "64px" }}
                />
              </Col>
              {/* NOME */}
              <Col sm="2" className="padd" style={{ cursor: "pointer" }}>
                <span onClick={this.onClick}>{this.props.nome}</span>
              </Col>
              {/* CPF */}
              <Col sm="2" className="padd" style={{ cursor: "pointer" }}>
                <span>{this.props.cpf}</span>
              </Col>
              {/* EMAIL */}
              <Col sm="2" className="padd" style={{ cursor: "pointer" }}>
                <span onClick={this.onClick}>{this.props.email}</span>
              </Col>
              {/* CARGO */}
              <Col sm="2">
                <select
                  className="form-control"
                  name={`cargo-${this.props.cpf}`}
                  onChange={this.props.handleCargoSelectChange}
                  value={this.props.cargo}
                  required
                >
                  <option value="">Selecione</option>
                  {hierarquiaOptions}
                </select>
              </Col>
              <Col sm="0-5" className="padd">
                {
                  participante.arquivado &&
                  <Button onClick={this.props.desarquivar}>Desarquivar</Button>
                }
                {
                  participante.arquivado &&
                  <Del onClick={this.props.remover} type="button">
                    <IconLarge icon="deletered" />
                  </Del>
                }
                {
                  !participante.arquivado &&
                  <Button onClick={this.props.arquivar}>Arquivar</Button>
                }
              </Col>
              <Col sm="0-5" className="padd">
                <Del onClick={this.callFotoParticipante} type="button">
                  <div id="btn-foto-hold" style={{ display: "none" }}>
                    <FileBase64
                      multiple={false}
                      onDone={this.props.onDone}
                      accept="image/*"
                    />
                  </div>
                  <IconLarge icon="foto" />
                </Del>
              </Col>
              <Col sm="0-5" className="padd">
                <Del onClick={this.props.editar} type="button">
                  Editar
                </Del>
              </Col>
            </Row>
          </Col>
        </Row>
      </ItParticipanteProjeto>
    );
  }
}

export default class Assets extends Component {
  render() {
    return <div />;
  }
}

export const Adicionar = styled.span`
  font-size: 12px;
  color: #02264a;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

export const AdicionarCliente = styled.span`
  font-size: 12px;
  font-weight: bold;
  /* padding-top: 20px; */
  text-decoration: underline;
  cursor: pointer;
  color: #002A4B;
`;

export const URL = styled.div`
  font-size: 12px;
  span {
    font-weight: bold;
  }
  img {
    width: 18px;
  }
`;

export class Link extends Component {
  render() {
    return (
      <URL>
        <span>Link de Acesso: </span> {this.props.url}
        <br />
        <span>Enviar Link Por Email</span>
        <br />
        <span>Copiar Link</span>
      </URL>
    );
  }
}

export const Ico2 = styled.span`
  border: 0px;
  background: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  img {
    width: ${props => props.size || "15px"};
    filter: ${props => (props.active ? "invert(1)" : "invert(0)")};
    margin-left: 10px;
    margin-right: 10px;
  }
  :focus {
    outline: none;
  }
`;
const Ico3 = styled.span`
  border: 0px;
  background: transparent;
  border-radius: 5px;
  img {
    width: 30px;
    filter: ${props => (props.active ? "invert(1)" : "invert(0)")};
    margin-left: 10px;
    margin-right: 5px;
  }
  :focus {
    outline: none;
  }
`;
export const Stat = styled.div`
  button {
    border: 0px;
    background: ${props => (props.active ? "#232638" : "white")};
    color: ${props => (props.active ? "white" : "#232638")};
    border-radius: 30px;
    text-align: left;
    margin-right: 20px;
    padding: 0.5em 0;
    padding-right: 40px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    :focus {
      outline: 0;
    }
    :hover {
      background: #232638;
      color: white;
      ${Ico2} {
        background: #232638;
        img {
          filter: invert(1);
        }
      }
    }
  }
  @media (min-width: 1000px) and (max-width: 1500px) {
    button {
      font-size: calc(12px + (14 - 12) * ((100vw - 1000px) / (1500 - 1000)));
    }
  }

  @media (min-width: 1500px) {
    button {
      font-size: 14px;
    }
  }
`;
export class Icon extends Component {
  render() {
    return (
      <Ico2
        {...this.props}
        active={this.props.active}
        className={this.props.className}
      >
        <img
          src={require("./images/" + (this.props.icon || "user") + ".gif")}
          alt="configurar"
        />
      </Ico2>
    );
  }
}
/**
 * se passar uma prop src, renderiza uma tag img com essa src
 * se passar um prop icon, renderiza o arquivo .gif cujo nome é o valor dessa prop em assets/images
 */
export class IconLarge extends Component {
  render() {
    if (!this.props.src && !this.props.icon) {
      throw new Error('deve ser passado uma prop `src` ou `icon` para IconLarge')
    }
    return (
      <Ico3 active={this.props.active}>
        <img
          src={this.props.src || require("./images/" + this.props.icon + ".gif")}
          alt="configurar"
        />
      </Ico3>
    );
  }
}
export class StatusButton extends Component {
  navegacao = () => {
    switch (this.props.valor) {
      case "Configurar":
        this.props.history.push("configurar");
        break;
      case "Vincular/Desvincular":
        this.props.history.push("participantes");
        break;
      case "Rodadas":
        this.props.history.push("rodadas");
        break;
      case "Grupos":
        this.props.history.push("grupos");
        break;
      case "Acompanhar":
        this.props.history.push("acompanhar");
        break;
      default:
        this.props.history.push(this.props.valor.toLowerCase());
        break;
    }
  };
  render() {
    return (
      <Stat active={this.props.active}>
        <button data-tour={this.props.dataTour} onClick={this.navegacao}>
          <Icon
            active={this.props.active}
            icon={this.props.icon}
            size="1.3em"
          />{" "}
          {this.props.valor}
        </button>
      </Stat>
    );
  }
}

const CP3 = styled.div`
  .pad {
    padding-top: 5px;
  }
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 7px;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const CP2 = styled.div`
  .pad {
    padding-top: 5px;
  }
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 5px;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

export const CP = styled.div`
  border-bottom: solid 1px #cdd8dc;
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 5px;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const CPA = styled.div`
  small {
    font-weight: bold;
    margin-bottom: 20px;
  }
  span small {
    text-decoration: underline;
    cursor: pointer;
  }
  input {
    background: transparent;
    border: 0px;
    padding: 5px;
    width: 100%;
    :focus {
      outline: 0;
    }
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cdd8dc;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: #cdd8dc;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: #cdd8dc;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

const TextArea = styled.textarea`
  border: 0px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  height: 90px;
  border-radius: 10px;
  :focus {
    outline: 0;
  }
`;

export class GerarCodigo extends Component {
  state = {
    tooltipOpen: false
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    return (
      <div>
        <CP>
          <small>{this.props.label}</small>
          <br />
          <Row>
            <Col sm="1">
              <span>
                <Icon icon="code" />
              </span>
            </Col>
            <Col sm="3">
              <InputMask
                id={this.props.id}
                type="text"
                style={{ cursor: "pointer" }}
                name={this.props.name}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                mask={this.props.mask}
                value={this.props.value}
                required={this.props.required}
                tabIndex={this.props.tabIndex || 0}
                readOnly
              />
              <Tooltip
                target={this.props.id}
                isOpen={this.state.tooltipOpen}
                toggle={this.toggle}
                trigger="hover"
                placement="bottom"
                delay={0}
              >
                Todas as letras são minúsculas!
              </Tooltip>
            </Col>
            <Col>
              <span>
                <small onClick={this.props.gerarCodigo}>Gerar Código</small>
              </span>
            </Col>
          </Row>
        </CP>
        <br />
        <br />
      </div>
    );
  }
}

export class Campo extends Component {
  montaTela = () => {
    if (this.props.tipo === "text") {
      return (
        <div style={this.props.style}>
          <CP style={this.props.cpStyle}>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <InputMask
              type="text"
              name={this.props.name}
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              mask={this.props.mask}
              value={this.props.value}
              required={this.props.required}
              disabled={this.props.disabled}
              tabIndex={this.props.tabIndex || 0}
            />
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "textInline") {
      return (
        <div style={this.props.style}>
          <CP>
            <Row>
              <Col sm={this.props.labelSize}>
                <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
              </Col>
              <Col>
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  disabled={this.props.disabled}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
        </div>
      );
    } else if (this.props.tipo === "realizacao") {
      return (
        <div>
          <CP2>
            <Row style={{ alignItems: 'center' }}>
              {/* <Col sm="2">
                <strong>{this.props.label}:</strong>
              </Col> */}
              <Col sm="12" lg="6">
                <Row style={{ marginLeft: -5 }}>
                  <strong>Início:</strong>
                </Row>
                <Row>
                  <Col xl="5" sm="5" md="5" lg="7" style={{ paddingRight: 0 }}>
                    <InputMask
                      type="text"
                      name={`${this.props.name}.datas.dataInicio`}
                      placeholder={this.props.placeholder}
                      onChange={this.props.onChange}
                      mask={this.props.mask}
                      value={this.props.value.datas.dataInicio}
                      style={{ borderBottom: "solid 1px black" }}
                      required={this.props.required}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>

                  <Col md="5" style={{ maxWidth: "fit-content", paddingRight: 0 }}>
                    <InputMask
                      type="text"
                      name={`${this.props.name}.horarios.horarioInicio`}
                      placeholder="00:00"
                      onChange={this.props.onChange}
                      mask="99:99"
                      value={this.props.value.horarios.horarioInicio}
                      style={{ borderBottom: "solid 1px black" }}
                      required={this.props.required}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm="12" lg="6">
                <Row style={{ marginLeft: -5 }}>
                  <strong>Fim:</strong>
                </Row>
                <Row>
                  <Col xl="5" sm="5" md="5" lg="7" style={{ paddingRight: 0 }}>
                    <InputMask
                      type="text"
                      name={`${this.props.name}.datas.dataFim`}
                      placeholder={this.props.placeholder}
                      onChange={this.props.onChange}
                      mask={this.props.mask}
                      value={this.props.value.datas.dataFim}
                      style={{ borderBottom: "solid 1px black" }}
                      required={this.props.required}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>

                  <Col md="5" style={{ maxWidth: "fit-content", paddingRight: 0 }}>
                    <InputMask
                      type="text"
                      name={`${this.props.name}.horarios.horarioFim`}
                      placeholder="00:00"
                      // disabled={true}
                      onChange={this.props.onChange}
                      mask="99:99"
                      value={this.props.value.horarios.horarioFim}
                      style={{ borderBottom: "solid 1px black" }}
                      required={this.props.required}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CP2>
        </div>
      );
    } else if (this.props.tipo === 'realizacao2') {
      return (
        <div style={{ ...this.props.style, fontSize: 12 }}>
          <CP3>
            <Row style={{ alignItems: 'center' }}>
              {/* <Col sm="2">
                <strong>{this.props.label}:</strong>
              </Col> */}
              <Col sm="12">
                <Row>
                  <Col style={{ paddingRight: 0 }}>
                    <InputMask
                      type="text"
                      name={`${this.props.name}.datas.dataInicio`}
                      placeholder={this.props.placeholder}
                      onChange={this.props.onChange}
                      mask={this.props.mask}
                      value={this.props.value.dataInicio}
                      style={{ borderBottom: "solid 1px #c9c9c9" }}
                      required={this.props.required}
                      disabled={this.props.disabled}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>

                  <Col md="5" style={{ maxWidth: "fit-content", paddingRight: 0 }}>
                    <InputMask
                      disabled={this.props.disabled}
                      type="text"
                      name={`${this.props.name}.horarios.horarioInicio`}
                      placeholder={this.props.hourPlaceholder || "00:00"}
                      onChange={this.props.onChange}
                      mask="99:99"
                      value={this.props.value.horarioInicio}
                      style={{ borderBottom: "solid 1px #c9c9c9" }}
                      required={this.props.required}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CP3>
        </div>
      );
    } else if (this.props.tipo === 'realizacao3') {
      return (
        <div style={{ ...this.props.style, fontSize: 12 }}>
          <CP3>
            <Row style={{ alignItems: 'center' }}>
              {/* <Col sm="2">
                <strong>{this.props.label}:</strong>
              </Col> */}
              <Col sm="12">
                <Row>
                  <Col style={{ paddingRight: 0 }}>
                    <InputMask
                      type="text"
                      name={`${this.props.name}.datas.dataInicio`}
                      placeholder={this.props.placeholder}
                      onChange={this.props.onChange}
                      mask={this.props.mask}
                      value={this.props.value.dataInicio}
                      style={{ borderBottom: "solid 1px #c9c9c9" }}
                      required={this.props.required}
                      disabled={this.props.disabled}
                      tabIndex={this.props.tabIndex || 0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CP3>
        </div>
      );
    } else if (this.props.tipo === "date") {
      return (
        <div style={this.props.style}>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="date" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "time") {
      return (
        <div>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="time" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="time"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "email") {
      return (
        <div style={this.props.style}>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="email" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="email"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "email2") {
      return (
        <div style={this.props.style}>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col>
                <InputMask
                  type="email"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "telefone") {
      return (
        <div style={this.props.style}>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="tel" />
                </span>
              </Col>
              <Col>
                <InputMask
                  type="tel"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "telefone2") {
      return (
        <div style={this.props.style}>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col>
                <InputMask
                  type="tel"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                />
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "code") {
      return (
        <div>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <Row>
              <Col sm="1">
                <span>
                  <Icon icon="code" />
                </span>
              </Col>
              <Col sm="3">
                <InputMask
                  type="text"
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  onChange={this.props.onChange}
                  mask={this.props.mask}
                  value={this.props.value}
                  required={this.props.required}
                  tabIndex={this.props.tabIndex || 0}
                  readOnly
                />
              </Col>
              <Col>
                <span>
                  <small onClick={this.props.gerarCodigo}>Gerar Código</small>
                </span>
              </Col>
            </Row>
          </CP>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "textArea") {
      return (
        <div>
          <CPA>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <TextArea
              type="text"
              name={this.props.name}
              onChange={this.props.onChange}
              placeholder={this.props.placeholder}
              value={this.props.value}
              required={this.props.required}
              style={{ backgroundColor: this.props.fundo }}
              disabled={this.props.disabled}
              tabIndex={this.props.tabIndex || 0}
            />
          </CPA>
          <br />
          <br />
        </div>
      );
    } else if (this.props.tipo === "salario") {
      return (
        <div>
          <CP>
            <span style={{fontSize: 12, fontWeight: 600}}>{this.props.label}</span>
            <br />
            <InputMask
              type="number"
              name={this.props.name}
              placeholder={this.props.placeholder}
              onChange={this.props.onChange}
              mask={this.props.mask}
              value={this.props.value}
              required={this.props.required}
              disabled={this.props.disabled}
              tabIndex={this.props.tabIndex || 0}
            />
          </CP>
          <br />
          <br />
        </div>
      );
    }
  };
  render() {
    return <div className="input">{this.montaTela()}</div>;
  }
}

export const Button = styled.button`
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  margin: ${props => props.margin || "10px"};
  padding: ${props => props.padding || "10px 0"};
  display: flex;
  justify-content: center;
`;

export const ButtonSave = styled.button`
  font-size: 12px;
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px 50px 10px 50px;
  margin: 10px;

  :focus {
    outline: 0;
  }
`;

export const PaymentButton = styled.button`
  font-size: 12px;
  background: #18b3c0; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #18b3c0 0%,
    #10949d 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #18b3c0 0%,
    #10949d 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  width: 130px;
  max-width: 130px;
  min-width: 130px;
  cursor: pointer;

  &.disabled {
    background: #FFF; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #FFF 0%,
      #EEE 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #FFF 0%,
      #EEE 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #FFF 0%,
      #EEE 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFF', endColorstr='#EEE',GradientType=0 ); /* IE6-9 */
    border: 1px solid #BBB;
    color: black;
  }
`;

export const ButtonCancel = styled.button`
  font-size: 12px;
  background: #db0308; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #db0308 0%,
    #c10a0f 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #db0308 0%,
    #c10a0f 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #db0308 0%,
    #c10a0f 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db0308', endColorstr='#c10a0f',GradientType=0 ); /* IE6-9 */
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 10px 50px 10px 50px;
  :focus {
    outline: 0;
  }
`;

export const Select = styled.select`
  border: 0;
  border-radius: 5px;
  height: 30px;
  width: 100%;
  background-color: rgb(221, 221, 221) !important;
  // padding: 20px;
`;

export const SR = styled.div`
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 5px 10px;
  border: 1px solid #cacaca;
  border-radius: 20px;
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  width: ${(props) => props.width || '100%'};
  min-width: min-content;
  input {
    border: 0px;
    background: transparent;
    width: 100%;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: lightgray;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: lightgray;
    }
    ::-ms-input-placeholder {
      /* IE 10+ */
      color: lightgray;
    }
    ::-moz-placeholder {
      /* Firefox 18- */
      color: lightgray;
    }
    .ico {
      width: 12px !important;
    }
  }
`;

export class StatusAssinc extends Component {
  render() {
    return (
      <div style={{ padding: 0, border: "solid 0px black" }}>
        <Row>
          <div style={{ margin: '0 5px' }}>
            <Badge color="secondary">
              Não iniciado: {this.props.naoIniciados}/{this.props.total}
            </Badge>
          </div>
          <div style={{ margin: '0 5px' }}>
            <Badge style={{ backgroundColor: '#002a4b', color: '#fff' }}>
              Iniciado: {this.props.iniciados}/{this.props.total}
            </Badge>
          </div>
          <div style={{ margin: '0 5px' }}>
            <Badge style={{ backgroundColor: '#3A6543', color: '#fff' }}>
              Finalizados: {this.props.finalizados}/{this.props.total}
            </Badge>
          </div>
          <div style={{ margin: '0 5px' }}>
            <Badge style={{ backgroundColor: '#b63b3b', color: '#fff' }}>
              Expirados: {this.props.expirados}/{this.props.total}
            </Badge>
          </div>
        </Row>
      </div>
    );
  }
}
export const Search = props => (
  <SR style={props.style} width={props.width} marginBottom={props.marginBottom}>
    <span>
      <Icon icon="search" />
    </span>{" "}
    <input
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value || undefined}
    />
    {props.searchLoading ?
      <div>
        <Loading width={'25px'} height={'25px'} innerBorderPx={'2px'} outterBorderPx={'2px'} />
      </div> : null}
  </SR>
);

const BaseTime = styled.div`
  input {
    background: #f7f8f8;
    border: 0px;
    width: 100%;
    height: 30px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  small {
    font-weight: bold;
  }
`;

const ListaSugestion = styled.div`
  ul {
    background: lightgray;
    padding: 10px;
    margin-top: -20px;
  }
  li {
    list-style: none;
  }
`;

let escolhas = [];

export class AdvancedSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      es: [],
      name: "",
      check: false
    };
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;

    const userInput = e.currentTarget.value;

    this.setState({ name: e.currentTarget.value });

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  /*// Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      es: escolhas
    });
    if (escolhas.length != 0) {
      for (var i = 0; i < escolhas.length; i++) {
        console.log(this.state.filteredSuggestions[0], escolhas[i]);
        if (this.state.filteredSuggestions[0] !== escolhas[i]) {
          console.log(escolhas);
          let selecao = this.props.selecao(this.state.filteredSuggestions[0]);
          if (selecao) escolhas.push(selecao);
          return escolhas;
        }
      }
    } else {
      console.log(escolhas);
      let selecao = this.props.selecao(this.state.filteredSuggestions[0]);
      if (selecao) escolhas.push(selecao);
      return escolhas;
    }

    this.setState({ name: "" });
  };*/

  // Event fired when the user presses a key down
  /*onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions

    // if (e.keyCode === 13) {
    //   console.log(this.state.userInput)
    //  if(this.state.userInput!==""){
    //   this.setState({
    //     activeSuggestion: 0,
    //     showSuggestions: false,
    //     userInput: filteredSuggestions[activeSuggestion],
    //     es:escolhas
    //   });
    //   if(escolhas.length!=0){
    //     for(var i=0;i<escolhas.length;i++){
    //       if(this.state.filteredSuggestions[activeSuggestion]!==escolhas[i]){
    //         escolhas.push(this.state.filteredSuggestions[activeSuggestion])
    //         console.log(escolhas)
    //       }
    //     }
    //   }else{
    //     escolhas.push(this.state.filteredSuggestions[activeSuggestion])
    //     console.log(escolhas)
    //   }
    //  }
    //  this.setState({name:""})

    // }
    // // User pressed the up arrow, decrement the index
    // else if (e.keyCode === 38) {
    //   if (activeSuggestion === 0) {

    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion - 1 });

    // }
    // // User pressed the down arrow, increment the index
    // else if (e.keyCode === 40) {
    //   if (activeSuggestion - 1 === filteredSuggestions.length) {
    //     return;
    //   }

    //   this.setState({ activeSuggestion: activeSuggestion + 1 });

    // }
  };*/

  deleta = valor => {
    escolhas.splice(valor, 1);
    this.setState({ es: escolhas });
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ListaSugestion>
            <ul className="suggestions">
              {filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                return (
                  <li className={className} key={suggestion} onClick={onClick}>
                    {suggestion}
                  </li>
                );
              })}
            </ul>
          </ListaSugestion>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>Não Existem dados cadastrados</em>
          </div>
        );
      }
    }

    return (
      <BaseTime>
        <Row>
          <Col sm="7">
            <small>{this.props.label}</small>
            <br />
            <input
              type="text"
              onChange={onChange}
              onKeyDown={onKeyDown}
              value={userInput}
            />
            {suggestionsListComponent}
          </Col>
          <Col>
            {this.state.es.map((escolha, index) => (
              <User onClick={() => this.deleta(index)} key={index}>
                <Icon icon="delete" />
                {escolha}
              </User>
            ))}
          </Col>
        </Row>
      </BaseTime>
    );
  }
  // render(){
  //   return(

  // <Row>
  //   <Col>
  //     <ADV>
  //       <small>{this.props.label}</small><br/>
  //       <input></input>
  //     </ADV>
  //   </Col>
  //   <Col>
  //     <ADV>
  //       <small>{this.props.label}</small><br/>
  //       <input></input>
  //     </ADV>
  //   </Col>
  // </Row>
  //   )
  // }
}

export const Titulo = styled.p`
  color: #232638;
  font-weight: bold;
`;
export const Titulo2 = styled.h3`
  color: "#232638";
  font-weight: bold;
  font-size: 18px;
`;
export const Titulo3 = styled.h4`
  color: "#232638";
  font-weight: bold;
  font-size: 12px;
`;
export const SimpleButton = styled.button`
  border: 0px;
  background: ${props => (props.active ? "#232638" : "#f7f9f8")};
  color: ${props => (props.active ? "#f7f9f8" : "#232638")};
  border-radius: 30px;
  margin-right: 20px;
  margin-bottom: 10px;
  height: 30px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  text-align: center;
  :focus {
    outline: 0;
  }
`;

export const BaseModal = styled.div`
  padding: 20px;
`;

export const ButtonCollapse = styled.button`
  background: transparent;
  border: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #000;
  font-weight: bold;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;
  :focus {
    outline: 0;
  }
`;

export const User = styled.button`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 5px 40px 5px 0px;
  font-weight: bold;
  font-size: 12px;
  color: #002a4b;
  margin-right: 10px;
  margin-bottom: 10px;
  :focus {
    outline: 0;
  }
`;
export const UserDrag = styled.div`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background: #ECECEC;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #002a4b;
  margin-right: 10px;
  margin-bottom: 10px;
  :focus {
    outline: 0;
  }
  width: 100%;
  cursor: grab;
  button {
    background: transparent;
    border: 0px;
  }
`;

export const UserHierarquia = styled.div`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #002a4b;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
  :focus {
    outline: 0;
  }
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  button {
    background: transparent;
    border: 0px;
  }
  /* Popover styling */

  a {
    text-decoration: none;
  }

  .popover__title {
  }

  .popover__wrapper {
    position: relative;

    display: inline-block;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin-left: 28px;
    transform: translate(0, 10px);
    background-color: #252638;
    padding: 1.5rem;
    color: white;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
  a {
    color: white;
  }
`;

export const UserStep = styled.div`
  background: #cbd6da; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #cbd6da 0%,
    #bfc8cc 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd6da', endColorstr='#bfc8cc',GradientType=0 ); /* IE6-9 */
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 11px;
  color: #002a4b;
  margin-bottom: 10px;
  text-align: center;
  :focus {
    outline: 0;
  }
  width: 200px;
  button {
    background: transparent;
    border: 0px;
  }
`;

export const Small = styled.small`
  font-weight: bold;
  width: 100%;
`;

export const FotosImport = styled.div``;

export const PopOver = styled.div`
  .popover__title {
  }

  .popover__wrapper {
    position: relative;
    width: 300px;
    display: inline-block;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin-left: 98px;
    transform: translate(0, 10px);
    background-color: #252638;
    padding: 1.5rem;
    color: white;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
`;

export const PopOverGrupos = styled.div`
  .popover__title {
  }

  .popover__wrapper {
    position: relative;
    /* width: 80px; */
    display: inline-block;
  }
  .popover__wrapper {
    position: relative;
    /* width: 80px; */
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    /* margin-top: 20px; */
    margin-left: 38px;
    transform: translate(0, 10px);
    background-color: #252638;
    padding: 1.5rem;
    color: white;
    right: 10px;
    top: 35px;
  }

  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(-10px, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
  p {
    cursor: pointer;
  }

  p:hover {
    text-decoration: underline;
  }
`;

export const Play = styled.button`
  background: #008b30;
  padding: 10px 40px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  color: white;
`;

export const Pause = styled.button`
  background: #fec400;
  padding: 10px 40px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  color: white;
`;

export const Action = styled.button`
  background: #fec400;
  padding: 10px 40px;
  text-align: center;
  border: 0px;
  border-radius: 25px;
  color: white;
`;

// const StatusCon = styled.div`
//   .status {
//     width: 10px;
//     height: 10px;
//     background: green;
//     border-radius: 10px;
//   }
// `;

const Online = styled.div`
  .status {
    width: 10px;
    height: 10px;
    background: green;
    border-radius: 10px;
  }
`;

const Offline = styled.div`
  .status {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 10px;
  }
`;

export class StatusOnline extends Component {
  render() {
    let { status } = this.props;

    if (status) {
      return (
        <Online>
          <div className="status" />
        </Online>
      );
    }
    return (
      <Offline>
        <div className="status" />
      </Offline>
    );
  }
}

export class NomeCliente extends Component {
  render() {
    return (
      <div>
        <h2 className="nome-cliente">{this.props.nome}</h2>
      </div>
    );
  }
}

export const Certifique = styled.div`
  padding: 40px;
  border: 1px solid blue;
  text-align: left;

  i {
    color: #00a8b2;
  }
  small {
    font-weight: bold;
  }
`;

const Pv = styled.div`
  background: ${props => (props.linha % 2 === 0 ? "#F8F9F8" : "white")};
  border: ${props => (props.linha % 2 === 0 ? "solid 1px lightgray" : "")};
  width: 100%;
  padding: 0 10px;
  font-size: 12px;

  p {
    padding-top: 20px;
    padding-left: 10px;
  }
  input {
    background: transparent;
  }
`;
export class ProvaModal extends Component {
  state = {
    respostasPopoverIsOpen: false,
    questaoPopoverIsOpen: false,
    deleteQuestion: false
  };

  toggleRespostasPopover = () => {
    this.setState({
      respostasPopoverIsOpen: !this.state.respostasPopoverIsOpen
    });
  };

  toggleQuestaoPopover = () => {
    console.log(this.state.questaoPopoverIsOpen);
    this.setState({
      questaoPopoverIsOpen: !this.state.questaoPopoverIsOpen
    });
    console.log(this.state.questaoPopoverIsOpen);
  };

  toggleDelete = () => {
    this.setState({
      deleteQuestion: !this.state.deleteQuestion
    });
  };

  render() {
    return (
      <Pv key={this.props.linha} linha={this.props.linha}>
        <Row>
          <Col sm="1">
            <p>{this.props.posicao + 1}</p>
          </Col>
          <Col sm={this.props.finalizada ? "3" : "2"}>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.props.questao)
              }}
              id={"questao" + this.props.posicao}
              key={"questao" + this.props.posicao}
            ></p>
            <UncontrolledPopover
              placement="right"
              target={"questao" + this.props.posicao}
              trigger="hover"
            >
              <PopoverHeader>Questão</PopoverHeader>
              <PopoverBody
                style={{
                  minWidth: "5vw"
                }}
              >
                {this.props.questao.split("</p>").map(text => {
                  return (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(text + "</p>")
                      }}
                    ></p>
                  );
                })}
              </PopoverBody>
            </UncontrolledPopover>
          </Col>

          <Col sm="1">
            <p>{this.props.nivel}</p>
          </Col>
          <Col sm={this.props.finalizada ? "3" : "2"}>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.props.alternativa)
              }}
              id={"res" + this.props.posicao}
            ></p>
            <UncontrolledPopover
              placement="right"
              target={"res" + this.props.posicao}
              trigger="hover"
            >
              <PopoverHeader>Resposta</PopoverHeader>
              <PopoverBody>
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.alternativa }}
                ></div>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>

          <Col sm="1">
            <p>{this.props.mostrada}</p>
          </Col>
          <Col sm="2">
            <p>{this.props.respondida}</p>
          </Col>
          {this.props.finalizada ? (
            []
          ) : (
            <Col sm="1">
              {this.props.allowAdicionarTempo ? (
                <p onClick={() => this.props.adicionarTempo(this.props.linha)}>
                  <strong>+30"</strong>
                </p>
              ) : (
                <p
                  onClick={() =>
                    this.props.notificar(
                      "Não é possível adicionar tempo a questões anteriores."
                    )
                  }
                >
                  <strong>+30"</strong>
                </p>
              )}
            </Col>
          )}
          <Col sm="1">
            <p>{this.props.tempo}</p>
          </Col>
          {this.props.finalizada ? (
            []
          ) : (
            <Col sm="1">
              <p onClick={this.toggleDelete}>
                <Icon icon="deletered" />
              </p>
            </Col>
          )}
        </Row>
        <ConfirmDelete
          onConfirm={() => this.props.excluirQuestao(this.props.linha)}
          onCancel={this.toggleDelete}
          className={this.state.deleteQuestion ? "" : "none"}
        />
      </Pv>
    );
  }
}

class ConfirmDelete extends Component {
  render() {
    return (
      <Row className={this.props.className}>
        <button onClick={this.props.onConfirm} className="confirm">
          Confirmar
        </button>
        <button onClick={this.props.onCancel} className="cancel">
          Cancelar
        </button>
      </Row>
    );
  }
}

export const ButtonWithSpinner = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.cancel ? "#db0308" : "#18b3c0"}; /* Old browsers */
  background: ${props => props.cancel ? (
    "-moz-linear-gradient(top,#db0308 0%,#c10a0f 100%)"
  ) : (
    "-moz-linear-gradient(top, #18b3c0 0%, #10949d 100%)"
  )};

  background: ${props => props.cancel ? (
    "-moz-linear-gradient(top,#db0308 0%,#c10a0f 100%)"
  ) : (
    "-webkit-linear-gradient(top,#18b3c0 0%,#10949d 100%)")}; /* Chrome10-25,Safari5.1-6 */

  background: ${props => props.cancel ? (
    "-webkit-linear-gradient(top,#db0308 0%,#c10a0f 100%)"
  ) : (
    "linear-gradient(to bottom,#18b3c0 0%,#10949d 100%)"
  )};

  filter: ${props => props.cancel ? (
    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#db0308', endColorstr='#c10a0f',GradientType=0 )"
  ) : (
    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#18b3c0', endColorstr='#10949d',GradientType=0 )"
  )}; /* IE6-9 */
  color: white;
  font-weight: 600;
  border: 0;
  border-radius: 5px;
  padding: 10px 50px 10px 50px;
  :focus {
    outline: 0;
  }
  span {
    display: flex;
    flex-direction: row;
  }
  margin: 10px;
`;


export const ButtonText = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: #02264a;
  cursor: pointer;
`;

export const CircularButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  padding: 5px 5px;

  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: #f7f8f8;
  }
`;

export const CustomSelect = (props) => (
  <select
    className={`form-selector ${props.className}`}
    name={props.name}
    onChange={e => props.onChange(props.options[e.target.value])}
    required={props.required}
    disabled={props.disabled}
    style={{ width: '100%', maxWidth: '100%', minHeight: 30, paddingLeft: 4 }}
  >
    <option disabled selected hidden>
      {props.default || "Selecione"}
    </option>
    {props.options.map((option, key) => (
      <option key={key} value={key} selected={option.selected}>
        {option.nome}
      </option>
    ))}
  </select>
);