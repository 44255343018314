import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import { FaQuestionCircle, FaTrashAlt } from 'react-icons/fa';
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal, Col, Row, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from 'views/Participante/components/Buttons';
import InputMaskBase from "react-input-mask";
import ReactCrop from 'components/ReactCrop';
import { ValidateEmail, ValidateTelefoneComDDD } from 'views/Pages/Participantes/utils/validations.js';

import { notifyError } from "../../Services/Notificacoes";
import api from "../../Services/api";
import { assincronosApi, reportsApi } from "../../Services/api";
import { Constantes } from "../../Services/Constantes";
import ModalTakePicture from './modal-take-picture';
import { ButtonCancel, ButtonSave } from "views/Pages/Assets/Assets";

import './css/modalEditAccess.css';

const InputLabel = styled(Col)`
  padding-top: 15px;
  padding-bottom: 10px;
  color: #000;
`;

const InputText = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
`;

const InputMask = styled(InputMaskBase)`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
`;

const InputMaskV2 = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
`;

class ModalPrimeiroAcesso extends Component {
  static propTypes = {
    cpf: PropTypes.string.isRequired,
    senhaAntiga: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,

    toggle: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    isOpen: false,
    cpf: null
  };

  state = {
    nome:  '',
    email: '',
    celular: '',
    senhaNova: "",
    senhaNova2: "",
    senhaAntiga: "",

    loadingSpinner: false,
    cropModal: {
      isOpen: false,
      type: ''
    },
    cropFileName: '',
    cropImg: null,
    crop: {
      unit: '%',
      width: 50,
      x: 25,
      y: 25,
      aspect: 1/1
    },
    avatarChanged: false,
    avatar: '',
    avatarUrl: '',
    logo:  '',
    logoConsultantUrl: '',
    logoChanged: false,
    nomeConsultoria: '',
    deleteLogoModal: false,
    logoConsultantRemoved: false,
    avatarRemoved: false,
    modalTakePic: false
  };

  componentDidMount = () => {
    if (!this.isAuthenticated()) return this.props.history.push("login");
  };

  isAuthenticated() {
    const token = sessionStorage.getItem("token");
    return token && token.length > 10;
  }

  validar = () => {
    const { nome, senhaNova, senhaNova2, email, celular, nomeConsultoria } = this.state;
    if (nomeConsultoria === '' && this.props.userFormacao && this.props.userFormacao.funcao === 'consultor') {
      notifyError("O campo nome consultoria é obrigatório!");
      this.setState({ loadingSpinner: false });
    } else if (senhaNova !== senhaNova2) {
      notifyError("As senhas precisam ser iguais!");
      this.setState({ loadingSpinner: false });
    } else if (nome === "") {
      notifyError("O campo nome é obrigatório!");
      this.setState({ loadingSpinner: false });
    } else if (email === "" || !ValidateEmail(email)) {
      if (email === "") {
        notifyError("O campo e-mail é obrigatório!");
      } else {
        notifyError("Esse e-mail não é válido!");
      }
      this.setState({ loadingSpinner: false });
    } else if(celular.replace('(', '') != '' && celular.length < 14) {
      notifyError("Esse telefone não é válido!");
    } else return true;
  };

  handleForm = async e => {
    if (this.state.loadingSpinner) return;
    try {
      e.preventDefault();
      this.setState({ loadingSpinner: true });

      const cpf = this.props.cpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");
      const { email, nome, senhaNova, celular, nomeConsultoria } = this.state;
      const { senhaAntiga } = this.props;
      if (!senhaAntiga) throw new Error("senha antiga nao informada");

      if (this.validar()) {
        if (this.state.avatarChanged && !this.state.avatarRemoved) {
          try {
            const formData = new FormData();

            formData.append("avatar", this.state.avatar);

            const resp = await api.put(`/api/user/${this.props.userId}/avatar`, formData);

            sessionStorage.setItem("userAvatar", resp.data.user.avatar);

          } catch (err) {
            const message = err.erro ? err.erro : err.message;
            console.error(message);
            notifyError('Não foi possível enviar o avatar. Selecione o avatar e tente novamente.');
          }

        }

        if (this.state.logoChanged && !this.state.logoConsultantRemoved) {
          try {
            const formData = new FormData();

            formData.append("consultantLogo", this.state.logo);

            const resp = await api.put(`/api/user/${this.props.userId}/consultant-logo`, formData);

            sessionStorage.setItem("consultantLogo", resp.data.user.consultantLogo);

          } catch (err) {
            const message = err.erro ? err.erro : err.message;
            console.error(message);
            notifyError('Não foi possível enviar a logo da consultoria. Selecione a logo e tente novamente.');
          }
        }

        api
          .post("api/auth/primeiro-acesso", {
            cpf,
            nome,
            senhaAntiga,
            senhaNova,
            email,
            celular,
            nomeConsultoria
          })
          .then(response => {
            if (response.data.token) {
              sessionStorage.setItem("token", response.data.token);
              sessionStorage.setItem("username", response.data.user.nome);
              sessionStorage.setItem("user", response.data.user._id);
              sessionStorage.setItem("userCpf", cpf);
              sessionStorage.setItem("userAvatar", response.data.user.avatar);
              sessionStorage.setItem("consultantLogo", response.data.user.consultantLogo);
              sessionStorage.setItem("userConsultantName", response.data.user.nomeConsultoria);
              api.defaults.headers.common["Authorization"] =
                "Bearer " + sessionStorage.getItem("token");
              assincronosApi.defaults.headers.common["Authorization"] =
                "Bearer " + sessionStorage.getItem("token");
              reportsApi.defaults.headers.common["Authorization"] =
                "Bearer " + sessionStorage.getItem("token");
              const { funcao } = response.data.user.formacao;
              if (funcao === Constantes.funcaoConsultor) {
                sessionStorage.setItem("isPlayer", "");
                this.props.history.push("/dashboard");
              } else if (funcao === Constantes.funcaoParticipante) {
                sessionStorage.setItem("isPlayer", true);
                this.props.history.push("/participante/dashboard");
              }
            }
          })
          .catch(err => {
            console.error(err);
            if (err.response && err.response.data && err.response.data.error)
              notifyError(err.response.data.error);
            else
              notifyError('Não foi possível salvar alterações. Por favor, recarregue a página e tente novamente');
            this.setState({ loadingSpinner: false });
          });

          const urlCreator = window.URL || window.webkitURL;
          urlCreator.revokeObjectURL(this.state.avatarUrl);
          urlCreator.revokeObjectURL(this.state.logoConsultantUrl);
          this.setState({ avatarUrl: '', logoConsultantUrl: '', logoChanged: false, avatarChanged: false });
      }
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível salvar alterações. Por favor, recarregue a página e tente novamente");
    } finally {
      this.setState({ loadingSpinner: false });
    }
  };

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  toogle = () => {
    this.setState({
      loadingSpinner: false
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  mask = (o, f, erased) => {
    setTimeout(function() {
      var v = f(o.value, erased);
      if (v != o.value) {
        o.value = v;
      }
    }, 1);
  }

  mphone = (v, erased) => {

    if (erased) return v;

    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    }  else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  handleFileChange = img => {
    const file = img;
    const urlCreator = window.URL || window.webkitURL;

    const url = urlCreator.createObjectURL(file);

    if (this.state.cropModal.type === 'user') {
      this.setState({ avatarUrl: url, avatar: file, avatarChanged: true, cropModal: false, avatarRemoved: false });
    } else if (this.state.cropModal.type === 'consultant') {
      this.setState({ logoConsultantUrl: url, logo: file, logoChanged: true, cropModal: false, logoConsultantRemoved: false });
    }

  }

  handleImgCrop = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          this.setState({ cropImg: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  openCropModal = (e, type) => {
    if (!e.target.files || !e.target.files.length) return;

    const file = e.target.files[0];

    if (
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "image/jpeg"
    ) {
      const fileName = e.target.files[0].name;

      this.handleImgCrop(e);
      this.setState({
        cropFileName: fileName,
        cropModal: {
          isOpen: true,
          type
        }
      });
    } else {
      notifyError("O formato da avatar deve ser .png, .jpg ou .jpeg. Tente novamente.");
    }
  }

  toggleDeleteLogoModal = () => {
    this.setState({ deleteLogoModal: !this.state.deleteLogoModal });
  }

  onRemoveConsultantLogo = () => {
    this.setState({ logoConsultantUrl: '', logo: null, logoChanged: true, cropModal: false, logoConsultantRemoved: true });
    this.toggleDeleteLogoModal();
  }

  toggleTakePicModal = () => {
    this.setState({ modalTakePic: !this.state.modalTakePic })
  }

  onUploadUserPhoto = (fileToUpload) => {
    const urlCreator = window.URL || window.webkitURL;

    const url = urlCreator.createObjectURL(fileToUpload);
    this.setState({ avatarUrl: url, avatar: fileToUpload, avatarChanged: true, cropModal: false, avatarRemoved: false });

    return url;
  }

  onRemoveUserPhoto = () => {
    this.setState({ avatarUrl: '', avatar: null, avatarChanged: true, cropModal: false, avatarRemoved: true });
  }

  render() {
    return (
      <>
      <Modal
          toggle={this.toggleDeleteLogoModal}
          isOpen={this.state.deleteLogoModal}
        >
          <ModalHeader toggle={this.toggleDeleteLogoModal}>
            Remover imagem
          </ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            Deseja remover essa imagem ?
          </ModalBody>
          <ModalFooter>
            <ButtonCancel onClick={this.toggleDeleteLogoModal}>Cancelar</ButtonCancel>
            <ButtonSave  onClick={this.onRemoveConsultantLogo}>Remover</ButtonSave>
          </ModalFooter>
        </Modal>
        <ModalTakePicture
          foto={this.state.avatarRemoved ? null : this.state.avatarUrl ? this.state.avatarUrl : this.props.avatar ? this.props.avatar : null}
          toggle={this.toggleTakePicModal}
          isOpen={this.state.modalTakePic}
          handleUploadUserPhoto={this.onUploadUserPhoto}
          handleExcludeAvatar={this.onRemoveUserPhoto}
        />
        <ReactCrop
          fileName={this.state.cropFileName}
          minWidth={60}
          circularCrop={true}
          showCropModal={this.state.cropModal.isOpen}
          src={this.state.cropImg}
          crop={this.state.crop}
          onCancelCrop={() =>
          this.setState({ cropModal: false })}
          onSubmitCrop={this.handleFileChange}
        />
        <Modal
          size="lg"
          toggle={this.props.toggle}
          isOpen={this.props.isOpen}
          onClosed={() => {
            this.props.handleClose()
            const urlCreator = window.URL || window.webkitURL;
            urlCreator.revokeObjectURL(this.state.avatarUrl);
            urlCreator.revokeObjectURL(this.state.logoConsultantUrl);
            this.setState({ avatarUrl: '', logoConsultantUrl: '', logoChanged: false, avatarChanged: false });
          }}
        >
          <ModalHeader toggle={this.props.toggle}>
            Primeiro Acesso
          </ModalHeader>
          <ModalBody style={{ fontSize: 12 }}>
            <p>Complete seus dados preenchendo o formulário:</p>
            <form onSubmit={this.handleForm}>
            <Row>
              { this.props.userFormacao && this.props.userFormacao.funcao === 'consultor' ? <>
                  <Col className='imgs label' sm={2} style={{ padding: 0 }}>
                    <label
                      title="Logo Consultoria"
                      style={{ backgroundImage: `url(${this.state.logoConsultantRemoved ?  null : this.state.logoConsultantUrl || `https://files-storage.s3.amazonaws.com/${this.props.consultantLogo}`})`, marginRight: 20  }}
                      className="avatar"
                    >
                      <input onChange={(e) => this.openCropModal(e, 'consultant')} type="file" />
                      {this.state.logoConsultantRemoved ? "+" : this.state.logoConsultantUrl || this.props.consultantLogo ? null : "+"}
                    </label>
                    <span className='label1'>Logo</span>
                    {(this.state.logoConsultantUrl || this.props.consultantLogo) && !this.state.logoConsultantRemoved  ? <FaTrashAlt onClick={this.toggleDeleteLogoModal} style={{ marginBottom: 4, cursor: 'pointer' }} /> : null}
                  </Col>
              </> : null }
              <Col className='imgs label' sm={2} style={{ padding: 0 }}>
                    <label
                      onClick={this.toggleTakePicModal}
                      title="Avatar Usuário"
                      style={{ backgroundImage: `url(${this.state.avatarRemoved ? null : this.state.avatarUrl || `https://files-storage.s3.amazonaws.com/${this.props.avatar}`})`, marginRight: 20  }}
                      className="avatar"
                    >
                      {this.state.avatarRemoved ? "+" : this.state.avatarUrl || this.props.avatar ? null : "+"}
                    </label>
                    <span className='label2'>Foto pessoal</span>
                  </Col>
                  <Col style={{ height: 'fit-content', display: 'flex', flexDirection: 'row-reverse' }}>
                    <Tooltip
                      title={this.props.userFormacao && this.props.userFormacao.funcao === 'consultor' ?
                              "Clique nos circulos apropriados e inclua imagens como a logo da sua consultoria e/ou sua foto de perfil. As imagens devem ser no formato JPG ou PNG com no máximo 2Mb."
                              :
                              "Clique no circulo e inclua uma imagem para sua foto de perfil. A imagem deve ser no formato JPG ou PNG com no máximo 2Mb."}
                      position="top"
                      size="small"
                      trigger="mouseenter"
                      arrow="true"
                      disabled={false}
                    >
                      <FaQuestionCircle size={12} />
                    </Tooltip>
                  </Col>
                  </Row>
            { this.props.userFormacao && this.props.userFormacao.funcao === 'consultor' ?
              <>
                <Row>
                  <Col sm={12} style={{ padding: 0 }}>
                    <InputLabel sm="12">
                      <strong>Nome Consultoria*</strong>
                    </InputLabel>
                    <Col sm="12">
                      <InputText
                        value={this.state.nomeConsultoria}
                        type="text"
                        name="nomeConsultoria"
                        placeholder='Nome da consultoria'
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                  </Col>
                </Row>
              </>
              : null }
              <Row>
                <Col sm={12} style={{ padding: 0 }}>
                  <InputLabel sm={12}>
                    <b>Nome Completo*</b>
                  </InputLabel>
                  <Col sm={12}>
                    <InputText
                      type="text"
                      name="nome"
                      onChange={this.handleChange}
                      placeholder="Nome Completo"
                      required
                    />
                  </Col>
                </Col>
              </Row>

              <Row>
                <Col sm={12} style={{ padding: 0 }}>
                  <InputLabel sm={12}>
                    <b>E-mail Pessoal*</b>
                  </InputLabel>
                  <Col sm={12}>
                    <InputText
                      value={this.state.email}
                      name="email"
                      type="email"
                      onChange={this.handleChange}
                      placeholder="email@email.com"
                      required
                    />
                  </Col>
                </Col>
              </Row>

              <Row>
                <Col sm={12} style={{ padding: 0 }}>
                  <InputLabel sm={6}>
                    <b>Telefone</b>
                  </InputLabel>
                  <Col sm={6}>
                    <InputMaskV2
                      type="text"
                      id="phone"
                      onChange={(e) => {
                        let erased = false;
                        if (e.target.value.length < this.state.celular.length) erased = true
                        this.mask(e.target, this.mphone, erased)
                        this.handleChange(e)
                      }}
                      placeholder="(xx) xxxxx-xxxx"
                      required
                      value={this.state.celular}
                      name="celular"
                      onBlur={(e) => {
                        let erased = false;
                        if (e.target.value.length < this.state.celular.length) erased = true
                        this.mask(e.target, this.mphone)
                        }} />
                  </Col>
                </Col>
              </Row>

              <Row>
                <Col sm={6} style={{ padding: 0 }}>
                  <InputLabel sm={12}>
                    <b>Nova Senha*</b>
                  </InputLabel>
                  <Col sm={12}>
                    <InputText
                      type="password"
                      placeholder="******"
                      name="senhaNova"
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Col>

                <Col sm={6} style={{ padding: 0 }}>
                  <InputLabel sm={12}>
                    <b>Confirmar Nova Senha*</b>
                  </InputLabel>
                  <Col sm={12}>
                    <InputText
                      type="password"
                      placeholder="******"
                      name="senhaNova2"
                      onChange={this.handleChange}
                      required
                    />
                  </Col>
                </Col>
              </Row>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.handleForm}
            >
              Acessar
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ModalPrimeiroAcesso;
