import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Col,
    Row
} from 'reactstrap';

export default class TextoEstilosDeComunicacao extends Component {
    static propTypes = {
        texto: PropTypes.object.isRequired,
    }

    state = {
      title: {
        i: 'Reflexivo',
        pn: 'Racional',
        s: 'Afetivo',
        pr: 'Pragmático'
      }
    }

    render() {
        const { text, type } = this.props.textObj;

        return (
            <div>
                <Row>
                    <Col>
                        <h3 style={{
                        fontSize: '18px',
                    }}>{this.state.title[type]}</h3>
                    </Col>
                </Row>
                <Row>
                <Col style={{
                      fontSize: '12px',
                      marginBottom: 15
                    }}><p
                    dangerouslySetInnerHTML={{
                      __html: text
                    }}
                  ></p></Col>
                </Row>

            </div>
        )
    }
}
