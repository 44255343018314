import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import PageTable, { Tr, Td, THead, TBody, TdWithTooltip, ThWithMax } from "components/PageTable";
import UserAvatar from "components/UserAvatar";
import ModalResultados from 'views/Pages/Resultados/ModalResultados';

import { capitalizeFirstLetterEachWord } from "utils/masks";

import Input from '../../components/Input/Input.jsx';
import './styles.css';

const Th = styled.th`
  white-space: nowrap;
  top: 0px;
  position: sticky;
  z-index: 1000;
`;
const Th2 = styled.th`
  top: 37.4px;
  position: sticky;
  z-index: 1000;
`;
const Th2WithMax = styled.th`
  top: 37.4px;
  position: sticky;
  z-index: 1000;
  white-space: nowrap;
  min-width: 150px;
  max-width: 150px;
`;

const DivWithMax = styled.div`
  min-width: 120px;
  width: 85% !important;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  float: left;
  font-weight: bold;
`;

// .assessment-table tr td {
//   font-size: 10px;
// }

// .assessment-table tr th {
//   font-size: 10px;
//   padding: 0.75rem 0;
// }
// .assessment-table-performance tr td {
//   padding: 0.75rem 0;
// }

// .results-module-table {
//   text-align: center;
// }

// .results-module-table tr td {
//   padding: 0.75rem 0;
//   vertical-align: middle;
// }

// .results-module-table tr td img {
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   vertical-align: top;
//   object-fit: cover;
// }

function buscaNomeExibicao(nome) {
  if (nome === 'assessment') return 'Resultado Assessment';
  if (nome === 'dinamica') return 'Resultado Dinâmica';
  if (nome === 'prova') return 'Resultado Prova';
  if (nome === 'assinc') return 'Resultado Assíncrono';
  if (nome === 'result') return 'Resultado';
}

function buscaIdModulo(nome, resultadoModulo) {
  if (nome === 'assessment') return resultadoModulo.assessment_id;
  if (nome === 'dinamica') return resultadoModulo.dynamic_id;
  if (nome === 'prova') return resultadoModulo.proof_id;
  if (nome === 'assinc') return resultadoModulo.async_id;
  if (nome === 'result') return resultadoModulo.results_id;
}

function buscaNomePropriedadesModulo(nome) {
  if (nome === 'assessment') return { id: 'assessment_id', name: 'assessment_name' };
  if (nome === 'dinamica') return { id: 'dynamic_id', name: 'dynamic_name' };
  if (nome === 'prova') return { id: 'proof_id', name: 'proof_name' };
  if (nome === 'assinc') return { id: 'async_id', name: 'async_name' };
  if (nome === 'result') return { id: 'results_id', name: 'results_name' };
}

function buscaNomeModulo(nome, resultadoModulo) {
  if (nome === 'assessment')  return resultadoModulo.assessment_name;
  if (nome === 'dinamica') return resultadoModulo.dynamic_name;
  if (nome === 'prova') return resultadoModulo.proof_name;
  if (nome === 'assinc') return resultadoModulo.async_name;
  if (nome === 'result') return resultadoModulo.results_name;
}

function buscaResultado(nome, resultadoModulo) {
  if (nome === 'assessment') return resultadoModulo.pontuacao;
  if (nome === 'dinamica') return resultadoModulo.pontuacao;
  if (nome === 'prova') return resultadoModulo.pontuacao;
  if (nome === 'assinc') return resultadoModulo.pontuacao;
  if (nome === 'result') return resultadoModulo.pontuacao;
}

function getAvatar(avatar) {
  return avatar && avatar.length
    ? `https://files-storage.s3.amazonaws.com/${avatar}`
    : `https://files-storage.s3.amazonaws.com/avatar_default.png`
}

function ResultsTable(props) {
  const [columns1, setColumns1] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [lines, setLines] = useState([]);
  const [modalResultados, setModalResultados] = useState(null);
  const [resultadosIdProjeto, setResultadosIdProjeto] = useState(null);
  const [resultadosIdParticipante, setResultadosIdParticipante] = useState(null);

  useEffect(()=>{
    if (!props.participantes || !props.participantes.length) {
      setColumns1([]);
      setColumns2([]);
      setLines([]);
      return;
    }
    const resultsRanking = {
      rankings: {
        participants: props.participantes
      }
    }

    let colunas1 = [];
      resultsRanking.rankings.participants[0].modulos.forEach((modulo) => {
        const nome = Object.keys(modulo)[0];

        colunas1.push({
          nome,
          nomeExibicao: buscaNomeExibicao(nome) || '',
          moduloNome: nome,
          colSpan: modulo[nome].length,
        });

      });

      const colunas2 = [];
      resultsRanking.rankings.participants[0].modulos.forEach(categoriaModulo => {

        const nome = Object.keys(categoriaModulo)[0];
        categoriaModulo[nome].map(resultadoModulo => {
          colunas2.push({
            nomeExibicao: buscaNomeModulo(nome, resultadoModulo),
            moduloId: buscaIdModulo(nome, resultadoModulo),
            moduloNome: nome,
            propriedades: buscaNomePropriedadesModulo(nome)
          });
        });

      });

      const linhas = [];
      resultsRanking.rankings.participants.forEach(participante => {
        let colunas = {};
        colunas.avatar = participante.avatar;
        colunas.nome = participante.participant_name;
        colunas.participantId = participante.participant_id;
        colunas.rk = participante.rk;
        colunas.resultados = [];

        participante.modulos.forEach(categoriaModulo => {

          const nome = Object.keys(categoriaModulo)[0];
          categoriaModulo[nome].map(resultadoModulo => {
            colunas.resultados.push(buscaResultado(nome, resultadoModulo));
          });

        });

        colunas.resultados.push(participante.finalTotal);

        linhas.push(colunas);
      });

      colunas1.map((c, i) => {
        let columnColor = '#f7f8f8';
        if (i % 2 === 0) columnColor = '#fff';

        c.columnColor = columnColor;
      });
      colunas2.map(c => {
        let columnColor = '#f7f8f8';
        const column1 = colunas1.find(cF => cF.moduloNome === c.moduloNome);
        if (column1) columnColor = column1.columnColor;

        c.columnColor = columnColor;
      });

      setColumns1(colunas1)
      setColumns2(colunas2)
      setLines(linhas)
  }, [props.participantes]);

  function toggleModalResultados () {
    setModalResultados(!modalResultados);
  }

  function exibeModalResultados(participantId) {
    const idProjeto = sessionStorage.getItem('projeto');

    setResultadosIdProjeto(idProjeto);
    setResultadosIdParticipante(participantId);
    toggleModalResultados();
  }

  return (
  <>
    <ModalResultados
      isOpen={modalResultados}
      toggle={toggleModalResultados}
      idProjeto={resultadosIdProjeto}
      idParticipante={resultadosIdParticipante}
      showInstrumentActivitiesByDefault={false}
      showInstrumentResultsByDefault={false}
    />
    <PageTable minWidth={600} style={{ marginTop: 20, height: 385 }} setOverflow='auto'>
      <THead>
        <Tr>

          <Th style={{ width: 80, maxWidth: 80, minWidth: 80, position: 'sticky', left: 0, zIndex: 1019 }} />
          <Th style={{ width: 230, maxWidth: 230, minWidth: 230, textAlign: 'left', position: 'sticky', left: 80, zIndex: 1019 }}>Nome</Th>
          <Th style={{ width: 80, maxWidth: 80, minWidth: 80, textAlign: 'left', position: 'sticky', left: 310, zIndex: 1019 }}>RK</Th>
          {columns1.map(coluna => <Th colSpan={coluna.colSpan}
            style={{ textAlign: 'left', background: coluna.columnColor }}>{coluna.nomeExibicao}</Th>)}
          <Th style={{ textAlign: 'left', width: '10%' }}>Total</Th>


        </Tr>
        <Tr>

          <Th2 style={{width: 80, maxWidth: 80, minWidth: 80, position: 'sticky', left: 0, zIndex: 1019 }} />
          <Th2 style={{width: 230, maxWidth: 230, minWidth: 230, position: 'sticky', left: 80, zIndex: 1019 }} />
          <Th2 style={{ width: 80, maxWidth: 80, minWidth: 80, position: 'sticky', left: 310, zIndex: 1019 }} />
          {columns2.map(coluna => <Th2WithMax className='text-left'
            style={{ background: coluna.columnColor }}>

                <DivWithMax>
                  <Tooltip
                    title={coluna.nomeExibicao}
                    arrow="true"
                    size="small"
                    position="top"
                  >
                    <span>{coluna.nomeExibicao}</span>
                  </Tooltip>
                </DivWithMax>

              {!props.disableInputs ? <div><i
                  onClick={() => props.toggleChangeTableNameModal(coluna.moduloNome, coluna.propriedades, coluna.moduloId, coluna.nomeExibicao)}
                  style={{ color: "#000", cursor: 'pointer', marginLeft: 2 }}
                  className="fa fa-pencil pencil-icon"
                  aria-hidden="true"
                ></i></div> : null}
              {/* <Input changeInput={props.onBlurTableInput} value={coluna.nomeExibicao} disabled={props.disableInputs} coluna={coluna} /> */}

            </Th2WithMax>)}
          <Th2 />

        </Tr>
      </THead>
      <TBody>

      {lines.map((linha) => {
        return (
          <Tr>
            <Td style={{ width: 80, maxWidth: 80, minWidth: 80, position: 'sticky', left: 0 }}>
              <UserAvatar foto={linha.avatar} />
            </Td>
            <TdWithTooltip
            style={{position: 'sticky', left: 80 }}
            tdMinWidth={'230px'}
            tdMaxWidth={'230px'}
            title={capitalizeFirstLetterEachWord(linha.nome.toLowerCase())}
            tdWidth={'230px'} content={<span className={"hover-name"} onClick={() => exibeModalResultados(linha.participantId)}>{capitalizeFirstLetterEachWord(linha.nome.toLowerCase())}</span>} />
            <Td style={{position: 'sticky', left: 310, width: 80, maxWidth: 80, minWidth: 80 }}>{linha.rk}</Td>
            {linha.resultados.map(resultado => <Td><span style={{ whiteSpace: 'nowrap' }}>{resultado}</span></Td>)}
          </Tr>
        )
      })}
      </TBody>
    </PageTable>
  </>
);
}

export default ResultsTable;