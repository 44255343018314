import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container } from "reactstrap";
import { ButtonCancel, ButtonSave } from '../../Pages/Assets/Assets';
import Folder from 'views/Pages/Assets/Folder';
import Input from './components/Input';
import RegisteredCpfModal from './components/RegisteredCpfModal';
import AlertModal from './components/AlertModal';
import { Constantes } from "Services/Constantes";
import api from "Services/api";
import { assincronosApi, reportsApi } from "Services/api";
import { notifyError, notifySuccess } from "Services/Notificacoes";
import { validarCPF } from "Services/Validador";
import { validatePassword } from "Services/validatePassword";

import apiCalls from 'config/apiCalls';

function RegisterModal(props) {
  const [userFirstAccess, setUserFirstAccess] = useState(false);
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmeSenha, setConfirmeSenha] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razao, setRazao] = useState('');
  const [fantasia, setFantasia] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [nr, setNr] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [cpfSearching, setCpfSearching] = useState(false);
  const [user, setUser] = useState(null);
  const [registeredCpfModal, setRegisteredCpfModal] = useState(false);
  const [emailExistente, setEmailExistente] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState([]);

  const [disableNome, setDisableNome] = useState(true);
  const [disableEmail, setDisableEmail] = useState(true);
  const [disableTelefone, setDisableTelefone] = useState(true);
  const [disableSenha, setDisableSenha] = useState(true);
  const [disableConfirme, setDisableConfirme] = useState(true);
  const [disableCnpj, setDisableCnpj] = useState(true);
  const [disableRazao, setDisableRazao] = useState(true);
  const [disableFantasia, setDisableFantasia] = useState(true);
  const [disableCep, setDisableCep] = useState(true);
  const [disableLogradouro, setDisableLogradouro] = useState(true);
  const [disableNr, setDisableNr] = useState(true);
  const [disableComplemento, setDisableComplemento] = useState(true);
  const [disableBairro, setDisableBairro] = useState(true);
  const [disableCidade, setDisableCidade] = useState(true);
  const [disableUf, setDisableUf] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const [validCpf, setValidCpf] = useState(true);
  const [validNome, setValidNome] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validTelefone, setValidTelefone] = useState(true);
  const [validSenha, setValidSenha] = useState(true);
  const [validConfirm, setValidConfirm] = useState(true);
  const [validCnpj, setValidCnpj] = useState(true);
  const [validRazao, setValidRazao] = useState(true);
  const [validFantasia, setValidFantasia] = useState(true);
  const [validCep, setValidCep] = useState(true);
  const [validLogradouro, setValidLogradouro] = useState(true);
  const [validNr, setValidNr] = useState(true);
  const [validComplemento, setValidComplemento] = useState(true);
  const [validBairro, setValidBairro] = useState(true);
  const [validCidade, setValidCidade] = useState(true);
  const [validUf, setValidUf] = useState(true);
  const [createLoading, setCreateLoading] = useState(false)

  const [alertModal, setAlertModal] = useState({ isOpen: false, msg: '', cb: null });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const cepMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{5})(\d)/, '$1-$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(-\d{3})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const cnpjMask = (value) => {
    return value
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
  }

  const mask = (o, f, erased) => {
    setTimeout(function () {
      var v = f(o.value, erased);
      if (v != o.value) {
        o.value = v;
      }
    }, 1);
  }

  const mask2 = (o, f, erased) => {

    var v = f(o, erased);
    return v;

  }

  const mphone = (v, erased) => {

    if (erased) return v;
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  const validate = () => {

    setValidCpf(true);
    setValidEmail(true);
    setValidNome(true);
    setValidTelefone(true);
    setValidSenha(true);
    setValidConfirm(true);
    setValidCnpj(true);
    setValidRazao(true);
    setValidFantasia(true);
    setValidCep(true);
    setValidLogradouro(true);
    setValidNr(true);
    setValidBairro(true);
    setValidCidade(true);
    setValidUf(true);


    let isValid = true;
    if (!cpf || cpf.length < 14) {
      setValidCpf(false);
      isValid = false;
    }


    const removedCpfPontuations = cpf && cpf.replaceAll('.', '').replace('-', '');
    if (cpf && !validarCPF(removedCpfPontuations)) {
      setValidCpf(false);
      isValid = false;
    }

    if (!nome) {
      setValidNome(false);
      isValid = false;
    }

    if (!email || !validateEmail(email)) {
      setValidEmail(false);
      isValid = false;
    }

    if (telefone && telefone.length < 14) {
      setValidTelefone(false);
      isValid = false;
    }

    /*if ((!user) || (user && !user.realizouPrimeiroAcesso)) {
      if (!senha) {
        setValidSenha(false);
        setValidConfirm(false);
        isValid = false;
      }

      if (senha.length < 8) {
        setValidSenha(false);
        setValidConfirm(false);
        isValid = false;
        notifyError('Senha precisa ter no mínimo 8 caracteres.');
      }

      if (senha !== confirmeSenha) {
        setValidSenha(false);
        setValidConfirm(false);
        isValid = false;
        notifyError('Senhas não correspondem.');
      }
    } */

    if (passwordErrors.length) {
      notifyError("Senha não atende os requisitos.");
      isValid = false;
    }

    if (!passwordErrors.length && senha !== confirmeSenha) {
      setValidSenha(false);
      setValidConfirm(false);
      isValid = false;
      notifyError('Senhas não correspondem.');
    }

    if (cnpj && cnpj.length < 18) {
      setValidCnpj(false);
      isValid = false;
    }

    if (cnpj && !razao) {
      setValidRazao(false);
      isValid = false;
    }

    if (cnpj && !fantasia) {
      setValidFantasia(false);
      isValid = false;
    }

    if (!cep || cep.length < 8) {
      setValidCep(false);
      isValid = false;
    }

    if (!logradouro) {
      setValidLogradouro(false);
      isValid = false;
    }

    if (!nr) {
      setValidNr(false);
      isValid = false;
    }

    if (!bairro) {
      setValidBairro(false);
      isValid = false;
    }

    if (!cidade) {
      setValidCidade(false);
      isValid = false;
    }

    if (!uf) {
      setValidUf(false);
      isValid = false;
    }

    if (!isValid) notifyError('Há campos que precisam ser preenchidos corretamente.');

    return isValid;
  }

  const handleConfirm = async () => {
    try {
      if (createLoading) return;
      setCreateLoading(true)

      const payload = {
        cpf: cpf && cpf.replaceAll('.', '').replace('-', ''),
        name: nome,
        cel: telefone,
        email,
        password: senha,
        cnpj,
        companyName: razao,
        tradingName: fantasia,
        postalCode: cep,
        street: logradouro,
        addressComplement: complemento,
        addressNumber: nr,
        neighborhood: bairro,
        city: cidade,
        state: uf,
        isParticipant: !!user
      }

      const isValid = validate();

      if (!isValid) {
        setCreateLoading(false)
        return;
      }

      const res = await apiCalls.users.createConsultant(payload);

      if (res && res.data && res.data.user) {
        setTimeout(async () => {
          const cpfReplaced = cpf && cpf.replaceAll('.', '').replace('-', '');

          if (!user) await props.login(null, cpfReplaced, senha, true);
          else if (user && sessionStorage.getItem('registerModalCpf')) {
            const senhaSess = sessionStorage.getItem('senha');
            sessionStorage.clear();
            await props.login(null, cpfReplaced, senhaSess, true);
          }
          else if (user && !sessionStorage.getItem('registerModalCpf')) notifySuccess('Usuário cadastrado como consultor. Você pode logar agora.');
          props.toggle();
        }, 1500)
      } else {
        setCreateLoading(false)
      }
    } catch (err) {
      console.error(err)
      notifyError('Ocorreu um erro ao tentar cadastrar, tente novamente.')
      setCreateLoading(false)
    }
  }

  const onCpfChange = async (e) => {
    sessionStorage.clear();
    setValidCpf(true);
    setCpf(cpfMask(e.target.value));
    setUserFirstAccess(false);

    const cpfReplaced = e.target.value && e.target.value.replaceAll('.', '').replace('-', '');
    const validCpf = validarCPF(cpfReplaced);
    if (cpfReplaced && cpfReplaced.length === 11 && !validCpf) notifyError('Cpf inválido.');

    if (cpfReplaced && cpfReplaced.length === 11 && validCpf) {
      setCpfSearching(true);
      const res = await apiCalls.users.getUserByCpf(cpfReplaced);
      const { user } = res.data;
      setUser(user);
      setCpfSearching(false);
      setDisableNome(false);
      if ((!user) || (user && !user.realizouPrimeiroAcesso)) setDisableEmail(false);
      setDisableTelefone(false);
      setDisableCnpj(false);
      setDisableSenha(false);
      setDisableConfirme(false);
      setDisableCep(false);
      setDisableLogradouro(false);
      setDisableNr(false);
      setDisableComplemento(false);
      setDisableBairro(false);
      setDisableCidade(false);
      setDisableUf(false);
      setDisableConfirm(false);
    } else {
      setUser(null);
      setDisableNome(true);
      setDisableConfirm(false);
      setDisableCnpj(true);
      setDisableRazao(true);
      setDisableFantasia(true);
      setDisableCep(true);
      setDisableLogradouro(true);
      setDisableNr(true);
      setDisableComplemento(true);
      setDisableBairro(true);
      setDisableCidade(true);
      setDisableUf(true);
      setDisableConfirm(true);
    }
  }

  const toggleAlertModal = (msg, cb) => {
    setAlertModal({ isOpen: !alertModal.isOpen, msg, cb });
  }

  const onCnpjChange = async (e) => {
    setValidCnpj(true);
    setCnpj(cnpjMask(e.target.value));
    const cnpjReplaced = e.target.value && e.target.value.replaceAll('.', '').replace('-', '').replace('/', '');

    if (!cnpjReplaced) {
      setRazao('');
      setFantasia('');
    }

    if (cnpjReplaced && cnpjReplaced.length === 14) {
      try {
        const res = await api.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpjReplaced}`);

        const { situacao_cadastral, razao_social, nome_fantasia, cep: cepRes } = res.data;

        if (situacao_cadastral !== 2) {
          setDisableFantasia(true);
          setDisableRazao(true);
          return toggleAlertModal('O CNPJ informado é inativo ou inapto. Informe um CNPJ válido.', () => {
            setCnpj('');
          });
        }

        setRazao(razao_social);
        setFantasia(nome_fantasia);

        setValidRazao(true);
        setValidFantasia(true);

        if (!nome_fantasia) setDisableFantasia(false);
        if (!razao_social) setDisableRazao(false);

        const cepReplace = cepRes.replaceAll('.', '').replace('-', '');
        onCepChange({ target: { value: cepReplace } });
      } catch (err) {
        console.error(err);
        if (err && err.response && (err.response.status === 400 || err.response.status === 404)) {
          setDisableFantasia(true);
          setDisableRazao(true);
          toggleAlertModal('O CNPJ informado não foi encontrado na base da Receita Federal. Informe os dados manualmente.', () => {
            setCnpj('')
          });
        }
      }
    }
  }

  const onCepChange = async (e) => {
    setValidCep(true);

    const maskedCep = cepMask(e.target.value);

    setCep(maskedCep);

    if (!maskedCep) {
      setLogradouro('');
      setNr('');
      setComplemento('');
      setBairro('');
      setCidade('');
      setUf('');
    }

    if (maskedCep && maskedCep.length === 9) {
      try {
        const res = await api.get(`https://brasilapi.com.br/api/cep/v1/${e.target.value.replace('-','')}`);
        const { city, neighborhood, state, street } = res.data;

        setCidade(city);
        setBairro(neighborhood);
        setUf(state);
        setLogradouro(street);

        setDisableNr(false);
        setDisableComplemento(false);

        setValidLogradouro(true);
        setValidBairro(true);
        setValidCidade(true);
        setValidUf(true);
      } catch (err) {
        console.error(err);
        toggleAlertModal(<><p>O CEP informado não foi encontrado. Verifique no site dos Correios se o CEP está correto ou informe os dados manualmente.</p><a href='https://buscacepinter.correios.com.br/app/endereco/index.php' target='_blank'>https://buscacepinter.correios.com.br/app/endereco/index.php</a></>, () => {});
      }
    }

  }

  const toggleRegisteredCpfModal = () => {
    if (sessionStorage.getItem('registerModalCpf')) return;
    setRegisteredCpfModal(!registeredCpfModal);
  }

  const handleRegisterAsConsultant = async (modalSenha) => {
    try {
      if (!modalSenha) return notifyError('Campo senha é requerido.');
      const { nome: userName, email: userEmail, celular } = user;

      const cpfReplaced = cpf && cpf.replaceAll('.', '').replace('-', '');

      const res = await apiCalls.users.verifyPassword(cpfReplaced, modalSenha);

      const { firstAccess, success } = res.data;

      if (!success) return notifyError('Senha incorreta.');

      setUserFirstAccess(firstAccess);
      setNome(userName);
      setEmail(userEmail);
      setTelefone(celular);
      setDisableSenha(false);
      setDisableConfirme(false);

      toggleRegisteredCpfModal();
    } catch (err) {
      console.error(err);
      if (err && err.response && err.response.data.error) notifyError(err.response.data.error);
      else notifyError('Ocorreu um erro ao verificar a senha.');
    }
  }

  useEffect(() => {
    if (user) {
      toggleRegisteredCpfModal();
    }
  }, [user]);

  useEffect(() => {
    if (!cpf || cpf.length < 14) {
      disableAllInputs();
    }
  }, [cpf]);

  const disableAllInputs = () => {
    setDisableNome(true);
    setDisableEmail(true);
    setDisableTelefone(true);
    setDisableSenha(true);
    setDisableConfirme(true);
    setDisableCnpj(true);
    setDisableRazao(true);
    setDisableFantasia(true);
    setDisableCep(true);
    setDisableLogradouro(true);
    setDisableNr(true);
    setDisableComplemento(true);
    setDisableBairro(true);
    setDisableCidade(true);
    setDisableUf(true);
    setDisableConfirm(true);
  }

  const resetState = () => {
    setCreateLoading(false);
    setUserFirstAccess(false);
    setUser(null);
    setCpf('');
    setNome('');
    setEmail('');
    setTelefone('');
    setSenha('');
    setConfirmeSenha('');
    setCnpj('');
    setRazao('');
    setFantasia('');
    setCep('');
    setLogradouro('');
    setNr('');
    setComplemento('');
    setBairro('');
    setCidade('');
    setUf('');
  }

  function addCpfPontuation(cpf) {
    if (!cpf) return;

    return cpf.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4");
  }

  async function handleNovoEmail() {
    try {
      if (validateEmail(email)) {
        const res = await apiCalls.users.verifyUserEmail(email);

        const { haveUser } = res.data;

        setEmailExistente(!!haveUser);
        if (haveUser) {
          setValidEmail(false);
          setDisableConfirm(true);
        } else {
          setValidEmail(true);
          setDisableConfirm(false);
        }
      }
    } catch (err) {
      console.error(err);
      setValidEmail(false);
    }
  }

  return (
    <>
      <AlertModal
        toggle={toggleAlertModal}
        isOpen={alertModal.isOpen}
        cb={alertModal.cb}
        msg={alertModal.msg}
      />
      <RegisteredCpfModal
        forgotPasswordModal={props.forgotPasswordModal}
        handleRegisterAsConsultant={handleRegisterAsConsultant}
        onConfirm={(userCpf, userPassword) => { props.login(null, userCpf, userPassword, true) }}
        setCpf={setCpf}
        cpf={cpf}
        toggle={toggleRegisteredCpfModal}
        isOpen={registeredCpfModal}
        formacao={user && user.formacao && user.formacao.funcao}
      />
      <Modal
        onClosed={() => {
          if (sessionStorage.getItem('senha')) sessionStorage.removeItem('senha');
          if (sessionStorage.getItem('registerModalCpf')) sessionStorage.removeItem('registerModalCpf');
        }}
        onOpened={async () => {
          resetState();
          if (sessionStorage.getItem('registerModalCpf')) {
            const cpfW = sessionStorage.getItem('registerModalCpf').replaceAll('.', '').replace('-', '');
            setCpf(addCpfPontuation(cpfW));
            setCpfSearching(true);
            const res = await apiCalls.users.getUserByCpf(cpfW);
            const { user: userRes } = res.data;
            setUser(userRes);
            setCpfSearching(false);
            setDisableNome(false);
            setDisableEmail(false);
            setDisableTelefone(false);
            setDisableCnpj(false);
            setDisableSenha(false);
            setDisableConfirme(false);
            setDisableCep(false);
            setDisableConfirm(false);

            const { nome: userName, email: userEmail, celular } = userRes;

            const telReplaced = celular && celular.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
            const telMasked = telReplaced && mask2(telReplaced, mphone, false);

            setNome(userName);
            setEmail(userEmail);
            setTelefone(telMasked);
            setDisableSenha(true);
            setDisableConfirme(true);
          }
        }}
        isOpen={props.isOpen}
        toggle={() => props.toggle()}
        size='lg'
      >
        <ModalHeader toggle={() => props.toggle()}>
          Seja um consultor IAPP. Crie sua conta e teste gratuitamente.
        </ModalHeader>
        <ModalBody>
          <div style={{ fontSize: 12 }}>
            <p>Ao completar o formulário, você será cadastrado como <strong>Consultor</strong> e receberá créditos bônus para testar o IAPP. Os créditos são válidos <strong>apenas para o primeiro cadastro por CNPJ</strong> e serão utilizados nos instrumentos que você configurar. <strong>Não é necessário cartão de crédito ou qualquer outro meio de fidelização.</strong></p>
            <p>Quando os créditos bônus acabarem o acesso ao IAPP permanecerá disponível. Para continuar a utilizar os instrumentos do IAPP após o consumo dos créditos bônus, você poderá adquirir <strong>créditos individuais</strong> para os instrumentos que desejar utilizar.</p>

            <p style={{ background: '#002a4c', padding: 20, color: '#fff', textAlign: 'left', marginBottom: 20, marginTop: 40 }}>
              <strong>Dados pessoais</strong>
            </p>
            <div style={{ marginBottom: 20 }}>
              <p>Informe seu CPF para iniciar o cadastro.</p>
              <p>Precisamos do seu CPF para validar o cadastro no IAPP. Fique tranquilo, seus dados estão seguros.</p>

              <Input
                disabled={cpfSearching}
                valid={validCpf}
                style={{ marginTop: 20 }}
                name='cpf'
                label={'CPF*:'}
                placeholder={'xxx.xxx.xxx-xx'}
                onChange={onCpfChange}
                value={cpf}
              />
            </div>
            <Container style={{ margin:0, padding:0 }}>
              <Row>
                <Col lg="12" md="12">
                  <Input
                    disabled={disableNome}
                    valid={validNome}
                    whiteSpace={'nowrap'}
                    style={{ marginTop: 20, width: '100%' }}
                    name='nome'
                    label={'Nome Completo*:'}
                    onChange={(e) => {
                      setValidNome(true);
                      setNome(e.target.value)
                    }}
                    value={nome}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="8" md="12">
                <Input
                  whiteSpace={'nowrap'}
                  valid={validEmail}
                  style={{ marginTop: 20, width: '100%' }}
                  disabled={disableEmail}
                  name='email'
                  label={'E-Mail*:'}
                  onBlur={handleNovoEmail}
                  onChange={(e) => {
                    setValidEmail(true);
                    setEmail(e.target.value)
                  }}
                  value={email}
                />
                </Col>

                <Col lg="4" md="12">
                <Input
                  style={{ marginTop: 20, width: '100%' }}
                  name='telefone'
                  valid={validTelefone}
                  label={'Telefone:'}
                  placeholder={'(xx) xxxx-xxxx'}
                  onChange={(e) => {
                    let erased = false;
                    if (e.target.value.length < telefone.length) erased = true
                    mask(e.target, mphone, erased)
                    setTelefone(e.target.value)
                  }}
                  value={telefone}
                  disabled={disableTelefone}
                />
                </Col>
              </Row>

                {emailExistente ? <><br /><span>Este e-mail já está cadastrado no Iapp.</span></> : null}

                {(!user) || (user && !user.realizouPrimeiroAcesso) ? <Row>
                  {user && !user.realizouPrimeiroAcesso ? <div style={{ width: '100%' }}><span style={{ fontSize: 12 }}>Informe uma nova senha para seu acesso no Iapp</span></div> : null}

                  <Col lg="4" md="12">
                  <Input
                    whiteSpace={'nowrap'}
                    valid={validSenha}
                    style={{ marginTop: 20, width: '100%' }}
                    name='senha'
                    disabled={disableSenha}
                    type='password'
                    label={'Senha para acesso*:'}
                    onChange={(e) => {
                      const errors = validatePassword(e.target.value);

                      setPasswordErrors(errors);
                      setValidSenha(!errors.length);
                      setValidConfirm(!errors.length);
                      setSenha(e.target.value)
                    }}
                    value={senha}
                  />
                  {
                    passwordErrors.map(error => {
                      return (
                        <>
                          <span style={{ fontSize: 11, color: '#f00', fontStyle: 'italic', whiteSpace: 'nowrap' }}>{error}</span> <br />
                        </>
                      )
                    })
                  }
                  </Col>

                  <Col lg="4" md="12">
                  <Input
                  whiteSpace={'nowrap'}
                    style={{ marginTop: 20, width: '100%' }}
                    valid={validConfirm}
                    name='confirme'
                    disabled={disableConfirme}
                    type='password'
                    label={'Confirme a Senha*:'}
                    onChange={(e) => {
                      setValidSenha(true);
                      setValidConfirm(true);
                      setConfirmeSenha(e.target.value)
                    }}
                    value={confirmeSenha}
                  />
                  </Col>
                </Row> : null}
            </Container>

            <Folder
              show={false}
              nome={'Cadastrar Empresa'}
              question={true}
              title={'Os dados do cadastro da empresa serão utilizados no contrato de licença de uso'}
            >
              <Container style={{ margin: 0, padding: 0 }}>

                <Row>
                  <Col lg="4" sm="12">
                    <Input
                      whiteSpace={'nowrap'}
                      valid={validCnpj}
                      disabled={disableCnpj}
                      style={{ marginTop: 5, width: '100%' }}
                      name='cnpj'
                      label={'CNPJ:'}
                      placeholder={'xx.xxx.xxx/xxxx-xx'}
                      onChange={onCnpjChange}
                      value={cnpj}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12">
                    <Input
                      whiteSpace={'nowrap'}
                      valid={validRazao}
                      disabled={disableRazao}
                      style={{ marginTop: 20, width: '100%' }}
                      name='razao'
                      label={'Razão Social:'}
                      onChange={(e) => {
                        setValidRazao(true);
                        setRazao(e.target.value)
                      }}
                      value={razao}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12">
                    <Input
                      whiteSpace={'nowrap'}
                      valid={validFantasia}
                      disabled={disableFantasia}
                      style={{ marginTop: 20, width: '100%' }}
                      name='nomefantasia'
                      label={'Nome Fantasia:'}
                      onChange={(e) => {
                        setValidFantasia(true);
                        setFantasia(e.target.value)
                      }}
                      value={fantasia}
                    />
                  </Col>
                </Row>

              </Container>
            </Folder>
            <p style={{ background: '#002a4c', padding: 20, color: '#fff', textAlign: 'left', marginBottom: 20, marginTop: 40 }}><strong>Endereço</strong></p>
            <p>Informar endereço comercial. Caso não tenha cadastrado empresa, informar endereço pessoal. Esses dados serão utilizados no seu contrato de utilização do software.</p>



            <Input
              whiteSpace={'nowrap'}
              valid={validCep}
              disabled={disableCep}
              style={{ marginTop: 20, width: 150 }}
              name='cep'
              label={'CEP*:'}
              onChange={onCepChange}
              placeholder={'xxxxx-xxx'}
              maxLength={9}
              value={cep}
            />

            <Container style={{ margin: 0, padding: 0 }}>
              <Row>
                <Col lg="9" sm="12">
                  <Input
                    style={{ marginTop: 20, width: '100%' }}
                    valid={validLogradouro}
                    disabled={disableLogradouro}
                    name='logradouro'
                    label={'Logradouro*:'}
                    maxLength={255}
                    onChange={(e) => {
                      setValidLogradouro(true);
                      setLogradouro(e.target.value)
                    }}
                    value={logradouro}
                  />
                </Col>

                <Col lg="3" sm="12">
                  <Input
                    style={{ marginTop: 20, width: '100%' }}
                    valid={validNr}
                    name='numero'
                    disabled={disableNr}
                    label={'Número*:'}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;

                      // if value is not blank, then test the regex

                      if (e.target.value === '' || re.test(e.target.value)) {
                        setValidNr(true);
                        setNr(e.target.value)
                      }
                    }}
                    value={nr}
                    maxLength={15}
                  />
                </Col>

              </Row>

              <Row>

                <Col lg="6" sm="12">
                  <Input
                    whiteSpace={'nowrap'}
                    valid={validComplemento}
                    style={{ marginTop: 20, width: '100%' }}
                    disabled={disableComplemento}
                    name='complemento'
                    label={'Complemento:'}
                    maxLength={255}
                    onChange={(e) => {
                      setValidComplemento(true);
                      setComplemento(e.target.value)
                    }}
                    value={complemento}
                  />
                </Col>

                <Col lg="6" sm="12">
                  <Input
                    style={{ marginTop: 20, width: '100%' }}
                    valid={validBairro}
                    name='bairro'
                    disabled={disableBairro}
                    label={'Bairro*:'}
                    maxLength={255}
                    onChange={(e) => { setBairro(e.target.value) }}
                    value={bairro}
                  />
                </Col>

              </Row>

              <Row>
                <Col lg="9" sm="12">
                  <Input
                    whiteSpace={'nowrap'}
                    valid={validCidade}
                    disabled={disableCidade}
                    style={{ marginTop: 20, width: '100%' }}
                    name='cidade'
                    label={'Cidade*:'}
                    maxLength={255}
                    onChange={(e) => {
                      setValidCidade(true);
                      setCidade(e.target.value)
                    }}
                    value={cidade}
                  />
                </Col>

                <Col lg="3" sm="12">
                  <Input
                    style={{ marginTop: 20, width: '100%' }}
                    valid={validUf}
                    disabled={disableUf}
                    name='uf'
                    label={'UF*:'}
                    maxLength={2}
                    onChange={(e) => {
                      setValidUf(true);
                      setUf(e.target.value)
                    }}
                    value={uf}
                  />
                </Col>

              </Row>
            </Container>

          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonCancel onClick={() => {
            props.onCancel()
          }}>Cancelar</ButtonCancel>
          <ButtonSave disabled={disableConfirm || createLoading} onClick={async () => await handleConfirm()}>Finalizar</ButtonSave>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default RegisterModal;