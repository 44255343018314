import React, { Component } from 'react';
import { normalizarPorMaximo } from 'Services/utilsFn';
import { getSVGComponent } from 'views/Components/positionInBard3';

export default class ArteDaArticulacaoGraph extends Component {
  state = {
    series: [],
    options: {},
  };

  getData = () => {
    const respondentes = this.props.respondentes.filter(
      respondente => respondente.resultado,
    );
    return [
      {
        name: 'Autoavaliação',
        nota: normalizarPorMaximo(198 - 21, this.props.resultado.somaBruta - 21),
        graficLowLabel: 'Baixa articulação',
        graficHighLabel: 'Alta articulação'
      },
    ].concat(
      respondentes.map(respondente => ({
        name: this.props.isConsultor
          ? `${respondente.nome} ${respondente.relacao}`
          : respondentes.length >= 3
          ? `${respondente.relacao}`
          : 'Geral',
        nota: normalizarPorMaximo(
          198 - 21,
          respondente.resultado.somaBruta - 21,
        ),
      })),
    );
  };

  getGraphHeight(vh, max) {
    const calculatedSize = max * 90;
    if (calculatedSize > 0.7 * vh) {
      return 0.7 * vh;
    }
    if (calculatedSize < 250) {
      return 250;
    }
    return calculatedSize;
  }

  componentDidMount() {
    const max = this.props.respondentes.length && this.props.respondentes[0].resultado ? this.props.respondentes.length + 1 : 1;
    const yMax = (max % 2 === 0 ? max + 1 : max) + 1;
    const vh = window.innerHeight;
    const data = this.getData();
    getSVGComponent(
      data,
      1500,
      (this.getGraphHeight(vh, yMax) / 1.5),
      {
        top: 30,
        right: 0,
        bottom: 20,
        left: 40,
      },
      data.map(value => value.name),
      data => ({
        name: data.name,
        '<10': 10,
        '<29': 19,
        '<49': 20,
        '<69': 20,
        '<89': 20,
        '<100': 11,
      }),
      ['<10', '<29', '<49', '<69', '<89', '<100'],
      {
        '<10': '#ff0000',
        '<29': '#dd5500',
        '<49': '#aaaa00',
        '<69': '#77dd00',
        '<89': '#55ee00',
        '<100': '#00ff00',
      },
      true,
      `graph-arte-da-articulacao${this.props.id}`,
    );
  }

  render() {
    const max = this.props.respondentes.length + 1;
    const yMax = (max % 2 === 0 ? max + 1 : max) + 1;
    const vh = window.innerHeight;
    return (
      <div id='distribution-graph'>
        <svg
          id={`graph-arte-da-articulacao${this.props.id}`}
          width='100%'
          height={this.getGraphHeight(vh, yMax)}
        ></svg>
      </div>
    );
  }
}
