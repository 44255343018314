function allFieldsNumeric(obj) {
  const notNumbers = Object.entries(obj)
    .filter(([_key, value]) => (
        value === null || 
        value === '' ||
        isNaN(value)))
    .length;

  if (notNumbers > 0) return false;
  else return true;
}

export default allFieldsNumeric;