import React, { Component } from 'react';
import { Line, Chart } from "react-chartjs-2";

/**
 * @author Gustavo Carvalho Silva
 * @since 30/11/2020
 * 
 * @props
 * 
 * - series
 * 
 * @description gráfico que exibe a relação entre os acertos e erros do participante e a dificuldade das questões
 */
export default class Questoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            display: false
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 1,
                max: 5,
                stepSize: 1
              },
              scaleLabel: {
                labelString: "Nível",
                display: true
              }
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                labelString: "Questão",
                display: true
              },
              ticks: {}
            }
          ]
        },
        legend: {
          display: false
        },
        annotation: {
          annotations: [
            {
              drawTime: "afterDatasetsDraw",
              borderColor: "red",
              borderDash: [2, 2],
              borderWidth: 2,
              mode: "vertical",
              type: "line",
              value: 10,
              scaleID: "x-axis-0"
            }
          ]
        },
        maintainAspectRation: false,
        tooltips: {
          enabled: false
        }
      },
      
    }
  }

  render() {
    return (
      <>
        <Line data={this.props.series} 
          width={100} 
          height={30} 
          options={this.state.options}
          plugins={[]} />
        </>
    )
  }
}