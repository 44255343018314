import orderHierarchies from './orderHierarchies';

const defaultTempoPreditivo = 10;
const defaultCargos = [
  {
    _id: 1,
    cargo: 'CARGO ATUAL',
    tempoPreditivo: defaultTempoPreditivo,
    superiorImediato: 2,
    type: 'fill'
  },
  {
    _id: 2,
    cargo: 'PRÓXIMO CARGO',
    tempoPreditivo: defaultTempoPreditivo,
    superiorImediato: 3,
    type: 'fill'
  },
  {
    _id: 3,
    cargo: 'CARGO SEGUINTE',
    tempoPreditivo: defaultTempoPreditivo,
    superiorImediato: null,
    type: 'fill'
  },
];

function getParticipantNextHierarchies({ hierarchies, currentCargo }) {
  hierarchies = orderHierarchies({ hierarchies, currentCargo });
  const indexParticipantHierarchy = hierarchies.findIndex(hierarchy => (
    hierarchy.cargo.toLowerCase().trim() ===
    currentCargo.toLowerCase().trim()
  ));
  let nextHierarchies = hierarchies
    .filter((_, index) => index >= indexParticipantHierarchy);

  new Array(3).fill(0).map((_, i) => {
    if (!nextHierarchies[i]) nextHierarchies[i] = { ...defaultCargos[i] };
    else if (!nextHierarchies[i].superiorImediato) {
      if (i === 2) return;
      nextHierarchies[i].superiorImediato = defaultCargos[i + 1]._id;
      nextHierarchies[i].type = 'fill';
    }
    if (!nextHierarchies[i].tempoPreditivo) {
      nextHierarchies[i].tempoPreditivo = defaultTempoPreditivo;
      nextHierarchies[i].type = 'fill';
    }
  });

  return nextHierarchies;
}

export default getParticipantNextHierarchies;