import apiCalls from 'config/apiCalls';
import React, { Component } from 'react';
import Loading from 'Services/Loading';
import { notifyError } from 'Services/Notificacoes';
import Folder from 'views/Pages/Assets/Folder';

// Instrumentos
import InfoCase from './Case';
import InfoEstilosDeLideranca from './EstilosDeLideranca';
import InfoStoryTelling from './StoryTelling';
import Sociometria from './Sociometria';

 /**
 * ### Componente de Resultados da Sociometria
 * 
 * /**
 * @author Gustavo Carvalho Silva
 * @since 19/11/2020
 * @props
 * - rodadas
 * - idProjeto
 * - idParticipante
 * 
 * @desc Monta os folders de rodadas e instrumentos através dos ids recebidos na prop rodadas
 */
export default class ResultadoDinamicas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rodadas: [],

      loading: false,
    }
  }

  getRodadasInstrumentosDinamica() {
    const { rodadas } = this.props;
    this.setState({ loading: true });
    try {
      this.setState({
        rodadas,
        loading: false,
      });
    } catch (err) {
      notifyError('Algo deu errado, por favor recarregue a página!');
    }
  }

  componentDidMount() {
    this.getRodadasInstrumentosDinamica();
  }

  render() {
    const { idProjeto, idParticipante, idDinamica } = this.props;

    return (
      <div>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
              {
                this.state.rodadas.length > 0
                  ?
                  this.state.rodadas.map((rodada, indexRodada) => (
                    <Folder
                      key={`rodada-${indexRodada}`}
                      nome={`Rodada ${indexRodada + 1}`}
                      show={false}
                    >
                      {
                        rodada.instrumentos && rodada.instrumentos.length > 0
                          ?
                          rodada.instrumentos.map((instrumento, index) => (
                            <React.Fragment key={index}>

                              {
                                instrumento.content == 'storytelling' &&
                                <Folder
                                  key={instrumento._id}
                                  show={false}
                                  nome={"StoryTelling"}
                                >
                                  <InfoStoryTelling
                                    idInstrumento={instrumento._id}
                                    idProjeto={idProjeto}
                                    idParticipante={idParticipante}
                                    idDinamica={idDinamica}
                                    rodada={indexRodada}
                                  />
                                </Folder>
                              }

                              {
                                instrumento.content == 'sociometria' &&
                                <Folder
                                  key={instrumento._id}
                                  nome={"Sociometria"}
                                >
                                  <Sociometria
                                    idInstrumento={instrumento._id}
                                    idProjeto={idProjeto}
                                    idParticipante={idParticipante}
                                    idDinamica={idDinamica}
                                    rodada={indexRodada}                                  />
                                </Folder>
                              }

                              {
                                instrumento.content == 'Estilos de liderança' &&
                                <Folder
                                  key={instrumento._id}
                                  nome={"Estilos de Liderança"}
                                >
                                  <InfoEstilosDeLideranca 
                                    idInstrumento={instrumento._id}
                                    idProjeto={idProjeto}
                                    idParticipante={idParticipante}
                                    idDinamica={idDinamica}
                                    rodada={indexRodada}
                                  />
                                </Folder>
                              }

                              {
                                instrumento.content == 'case' &&
                                <Folder
                                  key={instrumento._id}
                                  nome={"Case"}
                                >
                                  <InfoCase
                                    idInstrumento={instrumento._id}
                                    idProjeto={idProjeto}
                                    idParticipante={idParticipante}
                                    idDinamica={idDinamica}
                                    rodada={indexRodada}
                                  />
                                </Folder>
                              }
                            </React.Fragment>
                          ))
                          :
                          <div>
                            Não há instrumentos nessa rodada.
                          </div>
                      }
                    </Folder>
                  ))
                  :
                  <div>
                    Não há rodadas nessa dinâmica.
                  </div>
              }
            </>
        }
      </div>
    )
  }
}
