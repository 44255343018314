import React, { Component } from 'react';
import './css/TituloInput.css';
/**
 * O título padrão dos inputs.
 *
 * @export
 * @class TituloInput
 * @extends {Component}
 */
export default class TituloInput extends Component {
  render() {
    return (
      <h3 {...this.props} className={`titulo-input ${this.props.className}`}>
        {this.props.children}
      </h3>
    );
  }
}
