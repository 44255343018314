import React, { Component } from 'react';
import {
  Table,
} from 'reactstrap';
import TableRow from './TableRow';

export default class TableResultados extends Component {
  render() {
    const {
      respostas
    } = this.props;

    return (
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Questão</th>
            <th>Dificuldade</th>
            <th>Alternativa</th>
            <th>Mostrada</th>
            <th>Respondida</th>
            <th>Tempo</th>
            <th>Add tempo</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '12px' }}>
          {
            respostas.map((resposta, index) => (
              <TableRow
                key={index}
                index={index}
                correta={resposta.resposta && resposta.resposta.correta || false}
                questao={resposta.questao ? resposta.questao.enunciado : '-'}
                nivel={(resposta.nivel + 1)}
                resposta={resposta.resposta && resposta.resposta.texto || 'Não Respondida'}
                tempoIniciado={resposta.tempoIniciado}
                tempoQuestaoDuracao={resposta.tempoQuestaoDuracao}
                adicionarTempo={this.props.adicionarTempo}
                excluirQuestao={this.props.excluirQuestao}
              />
            ))
          }
        </tbody>
      </Table>
    )
  }
}