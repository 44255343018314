import api from "Services/api";

export default async function fetchUnifiedProofPhrases({
  idResult,
  idUser,
  idProvaUnificada
}) {
  const userResposta = await api(
    '/v3/provas/unified-proof-phrases',
    {
      params: {
        idResult,
        idUser,
        idProvaUnificada,
      },
    },
  );

  return userResposta.data;
}