import React from 'react';
import Chart from "chart.js";

import * as ProofGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import ProofGraphUtils from './ProofGraphUtils';

export default class ProofGraph extends React.Component {

  state = {
    userResult: null
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, ProofGraphUtils.graphConfig());
  }

  drawParticipant(state) {
    const datasets = ProofGraphUtils.drawParticipant({
      result: state.userResult,
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  componentDidMount() {
    let state = {};

    ProofGraphService.loadUserResult({
      projectId: this.props.projectId,
      proofId: this.props.proofId,
    })
    .then(res => {
      let participantRk = {}
      if (res.ranking) {
        const finded = res.ranking.find(p => p.id === this.props.participantId)

        if (finded) {
          participantRk = finded
        }
      }

      state.userResult = participantRk
    })
    .catch(err => {
      this.handleError(err);
      notifyError('Não foi possível montar o gráfico individual. Por favor, recarregue a página e tente novamente');
    }).finally(_ => {
      this.setState({ state });
      var canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };
      this.draw(state);
      this.drawParticipant(state);
    });
  }

  render() {
    return <canvas
      id={this.props.id}
      height={200}
      width={450}
      />;
  }
}