import { normalizarPorMaximo } from 'Services/utilsFn';

export default function normalizeResultValues(result) {
  result['E'] = Math.round(100 * normalizarPorMaximo(10, result['E'])) / 100;
  result['S'] = Math.round(100 * normalizarPorMaximo(20, result['S'])) / 100;
  result['T'] = Math.round(100 * normalizarPorMaximo(20, result['T'])) / 100;
  result['J'] = Math.round(100 * normalizarPorMaximo(20, result['J'])) / 100;
  result['I'] = Math.round(100 * normalizarPorMaximo(10, result['I'])) / 100;
  result['N'] = Math.round(100 * normalizarPorMaximo(20, result['N'])) / 100;
  result['F'] = Math.round(100 * normalizarPorMaximo(20, result['F'])) / 100;
  result['P'] = Math.round(100 * normalizarPorMaximo(20, result['P'])) / 100;

  return result;
}