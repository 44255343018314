import React from 'react';
import Chart from "chart.js";

import * as HvGraphService from './services';
import { notifyError } from 'Services/Notificacoes';
import HvGraphUtils from './HvGraphUtils';

export default class EstiloDeComunicacaoGraph extends React.Component {

  state = {
    userResult: null
  }

  constructor(props) {
    super(props);
  }

  handleError(err) {
    console.error(err);
  }

  draw() {
    var ctx = document.getElementById(this.props.id).getContext("2d");
    this.chart = new Chart(ctx, HvGraphUtils.graphConfig());
  }

  drawParticipant(state) {
    const datasets = HvGraphUtils.drawParticipant({
      result: state.userResult,
    });

    this.chart.data.datasets = [
      ...this.chart.data.datasets,
      ...datasets
    ];
    this.chart.update();
  }

  componentDidMount() {
    let state = {};

    HvGraphService.loadUserResult({
      participantId: this.props.participantId,
      assincId: this.props.assincId,
    })
    .then(res => {
      state.userResult = res.results && res.results[0] ? res.results[0] : {};
    })
    .catch(err => {
      this.handleError(err);
      notifyError('Não foi possível montar o gráfico individual. Por favor, recarregue a página e tente novamente');
    }).finally(_ => {
      this.setState({ state });
      var canvas = document.getElementById(this.props.id);
      if (!canvas) {
        console.info('canvas element removed.');
        return;
      };
      this.draw(state);
      this.drawParticipant(state);
    });
  }

  render() {
    return <canvas
      id={this.props.id}
      height={450}
      width={450}
      />;
  }
}