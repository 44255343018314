import React, { useRef, useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import UserAvatar from "components/UserAvatar";
import {
  pt
} from 'date-fns/locale';
import {
  Progress,
} from 'reactstrap';

import {
  ClientsTable,
} from '../styles';

import styled from 'styled-components';

const moduleEndpoints = {
  assíncrono: {
    endpoint: 'assincronos'
  },
  prova: {
    endpoint: 'provas'
  },
  dinâmica: {
    endpoint: 'dinamicas'
  },
  assessment: {
    endpoint: 'assessments'
  },
  relatório: {
    endpoint: 'reports'
  },
  resultados: {
    endpoint: 'resultados'
  }
}

const Table = styled.table`
  width: 100%;
  min-width: 900px;
  font-size: 12px;
  overflow: auto;

  ::-webkit-scrollbar {
    height: 8px !important;
  }

  th {
    font-size: 12px;
    padding:10px;
    border-bottom: 2px solid #e4e5e6;
  }

  td {
    font-size: 12px;
    padding:10px;
    border-bottom: 1px solid #e4e5e6;
  }
`;

function formatDate(isoString) {
  if (!isoString) return '';

  const parsed = parseISO(isoString);
  return format(parsed, 'dd/MM/yyyy HH:mm', {
    locale: pt
  });
}

function getModuleEndPoint(module) {
  if (!module) return;

  return `/clientes/projetos/${moduleEndpoints[module.toLowerCase()].endpoint}`;
}

const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: top;
  object-fit: cover;
`;

const ClientAvatar = ({foto}) => {
  const awsURL = 'https://files-storage.s3.amazonaws.com';
  return (
    <Image
        src={
          foto
            ? `${awsURL}/${foto}`
            : `${awsURL}/enterprise_default.png`
        }
      />
  )
}

function FeaturedProjectsTable(props) {

  const { featuredProjects, orderProjectsBy, sortField, sortOrder } = props;
  const intersectionRef = useRef(null);
  const history = useHistory();

  function handleArrow(field) {
    return (
      <>
        {sortField === field ? (
          <span style={{ marginRight: 5 }}>
              {sortOrder === 1 ? <FaChevronDown /> : <FaChevronUp />}
          </span>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (

    <div id="featured-projects" style={{ height: '100%', overflow: 'auto'}}>
      <Table>
        <thead>
          <tr style={{ borderTop: 'none' }}>
            <th width='70px'></th>
            <th style={{ cursor: 'pointer' }} onClick={async() => await orderProjectsBy('clientName') } width='100px'>{handleArrow("clientName")} Clientes</th>
            <th style={{ cursor: 'pointer' }} onClick={async() => await orderProjectsBy('project.nome') } width='100px'>{handleArrow("project.nome")} Projeto</th>
            {/* <th style={{ cursor: 'pointer' }} onClick={async() => await orderProjectsBy('creditsConsumed') } width='250px'>{handleArrow("creditsConsumed")} Consumo</th> */}
            <th style={{ cursor: 'pointer' }} onClick={async() => await orderProjectsBy('project.participantes.length') } width='70px'>{handleArrow("project.participantes.length")} Participantes</th>
            <th style={{ cursor: 'pointer' }} onClick={async() => await orderProjectsBy('project.lastModified.date') } width='100px'>{handleArrow("project.lastModified.date")} Último acesso</th>
          </tr>
        </thead>
        <tbody>
          {featuredProjects.map((client, index) => (
            <>
              {
                (client.project.lastModified && client.project.lastModified.date)
                ?
                <tr key={index}>
              <td width='70px' style={{ }}>
                <ClientAvatar foto={client.logo ? client.logo : undefined } />
              </td>
              <td width='100px' style={{ }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <span><strong>{client.clientName}</strong></span>
                  <span style={{
                    fontSize: '10px',
                    fontWeight: '400',
                  }}>{client.RhResponsible}</span>
                </div>
              </td>
              <td width='100px' style={{ }}>
                {client.project.nome}
              </td>
              {/* <td width='250px' style={{ }}>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <p>
                    <span>
                      R$ {client.creditsConsumed},00</span> | <span style={{ fontWeight: '400' }}>
                      {client.consumptionStartDate} - {client.consumptionFinishDate}
                    </span>
                  </p>
                  <Progress className="progress-xs"
                    color="danger"
                    value="5" />
                </div>
              </td> */}
              <td width='70px'>
                {client.project.participantes.length}
              </td>
              <td width='100px'>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>

                    <span onClick={() => {
                      if (!client.project.lastModified || !client.project.lastModified.module) return;
                      const { module } = client.project.lastModified;
                      const clientLogo = client.logo || '';
                      sessionStorage.setItem('clienteLogo', clientLogo);
                      sessionStorage.setItem('clienteNome', client.clientName);
                      sessionStorage.setItem('cliente', client.clientId);
                      sessionStorage.setItem('projeto', client.project._id);
                      const moduleEndPoint = getModuleEndPoint(module);
                      if (!moduleEndPoint) return;

                      history.push(moduleEndPoint);
                    }} style={!client.project.lastModified || !client.project.lastModified.module ? {width: 'fit-content'} :{
                      fontWeight: 'bolder',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      width: 'fit-content'
                    }}>{client.project.lastModified && client.project.lastModified.module ? <Tooltip
                      title={'Acesse o módulo'}
                      position="top"
                      arrow="true"
                      size="small"
                    > {client.project.lastModified.module} </Tooltip> : '-'}</span>

                  <span style={{
                    fontSize: '10px',
                    fontWeight: '400',
                  }}>
                    {client.project.lastModified && client.project.lastModified.date ? `${new Date(client.project.lastModified.date).toLocaleDateString()}` : '-'}
                  </span>
                </div>
              </td>
            </tr>
            :
            <>
            </>
              }
            </>
          ))}
        </tbody>
      </Table>
      <div style={{color: 'transparent'}} ref={intersectionRef}> - </div>
    </div>
  )
}

export default FeaturedProjectsTable;