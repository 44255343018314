import React, { Component } from "react";
import api from "Services/api";
import * as draftToHtml from "draftjs-to-html";
import {
  Col,
  Row,
  Container
} from "reactstrap";
import styled from "styled-components";

import {
  Base,
  Icon,
  Inst,
  Contador,
  Button,
  Footer,
} from "./Assets/Assets";
import Loading from "../../../Services/Loading";
import fetchInstrumentoCase from './Services/fetch-instrumento-case';

import Timer from "./Services/Timer";
import { Constantes } from "./Services/Constantes";
import { withRouter } from 'react-router'

import './styles.css'

// Imagens
const spinner = Constantes.imagemSpinner;

// Estilos
const Criterios = styled.div`
  padding: 10px;
  background: #ffd800;
  color: #fff;
  font-size: 14px;
  margin: 10px;
  border-radius: 20px;
`;

const P = styled.p`
  font-size: 13px;
`;

class CaseGrupo extends Component {

  state = {
    loading: false,
    template: {
      nomeCase: ''
    },
    textoGrupo: ''
  }

  configTemplate = async () => {
    const textIndex = new URLSearchParams(this.props.location.search).get("textIndex");
    const idAtividade = new URLSearchParams(this.props.location.search).get("idAtividade");
    const rodada = new URLSearchParams(this.props.location.search).get("roundIndex");
    const idInstrumento = new URLSearchParams(this.props.location.search).get("instrumentId");

    const instrumento = await fetchInstrumentoCase({ idAtividade, rodada, idInstrumento });
    const caseTemplateConfigId = instrumento.info;
    const caseConfigResponse = await api.get(`/v3/case-config/${caseTemplateConfigId}`);
    const infoCase = caseConfigResponse.data;
    const { textos } = infoCase;

    let textoGrupo = textos[textIndex].grupo;

    if(typeof (textoGrupo) !== 'string') textoGrupo = draftToHtml(textoGrupo);

    this.setState({ textoGrupo, template: infoCase });
  }

  componentDidMount = async () => {
    await this.configTemplate();
  }

  render() {
    if (this.state.loading) return <Loading />;
    return (
      <div style={{ width: '100%' }} className="text-left back" tabIndex="0">
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Case</h1>
              </Col>
            </Row>
            <br />
            <br />

            <h1>{this.state.template.nomeCase}</h1>

            <div
              className='responsive-image'
              dangerouslySetInnerHTML={{
                __html: (this.state.textoGrupo)
              }}
            />

            {/* <div
              dangerouslySetInnerHTML={{
                __html: (this.state.textoGrupo)
              }}
            /> */}

            <br />
            <br />
            <h2>Mesa: "nome da mesa"</h2>
            <br />
            <h2>
              Secretário: "nome do participante"
            </h2>
            <br />
            <br />
            <Row>
              <Col sm="6">
                {" "}
                {/* Participante */}
                <div>
                  <h4>Critérios do Grupo</h4>
                  {[0, 0, 0, 0].map((criterio, indice) => (
                    <div key={indice}>
                      <select value={'-'} className="form-control" disabled id={"select-sec-"+indice}>
                        <option selected disabled value="-">-</option>
                      </select>
                      <br />
                      <br />
                    </div>
                  ))}
                </div>
              </Col>
              <Col sm="6">
                {" "}
                <h4>Defina os Critérios Individuais:</h4>
                {[0, 0, 0, 0].map((criterio, index) => (
                  <div key={index}>
                    <select
                      key={index}
                      className="form-control"
                      onChange={()=>{}}
                      disabled={true}
                      value={'-'}
                      id={"select-par-"+index}
                    >
                      <option
                        key={Constantes.mensagemSelecionar}
                        value="-"
                        selected
                        disabled
                      >
                        -
                      </option>
                    </select>
                    <br />
                    <br />
                  </div>
                ))}
                <br />
                <br />
              </Col>
              <Col sm="6" className="text-center">
                <h4>Escolha em grupo</h4>
                {[0, 0].map((escolhasGrupo, index) => (
                      <div key={index}>
                        <select
                          className="form-control"
                          name="escolhaGrupo"
                          key={index}
                          onChange={()=>{}}
                          value={'-'}
                          disabled={true}
                          id={"select-grupo-"+index}
                        >
                          <option value='-' selected disabled>-</option>
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
              </Col>
              <br />
              <br />
              <Col sm="6" className="text-center">
                <h4>Escolha individual</h4>
                {

                new Array(2).fill(0).map((_, choiceIndex) => {
                  return (
                    <div key={choiceIndex}>
                      <select
                        className="form-control"
                        key={choiceIndex}
                        name="escolhaIndividual"
                        value={'-'}
                        onChange={()=>{}}
                        disabled={true}
                        id={"select-individual-" + choiceIndex}
                      >
                        <option
                          key={Constantes.mensagemSelecionar}
                          value="-"
                          disabled
                          selected
                        >
                          -
                        </option>
                      </select>
                      <br />
                      <br />
                    </div>
                  )
                })}
              </Col>
              <br />
              <br />
              <Col sm="12" className="text-center">
                <Button onClick={()=>{}} color="success">
                  Enviar
                </Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(CaseGrupo);
