import React, { useEffect, useState } from 'react';
import { JSCharting } from 'jscharting-react';
import _ from 'lodash'

import './styles.css'

const palette = [
  '#7f5fa9',
  '#ed5f60',
  '#face3a',
  '#8B8386',
  '#56c5d5',
  '#f17542',
  '#006400',
  '#B8860B',
  '#00FF7F',
  '#363636',
  '#cddd84',
  '#cdc5bb',
  '#a14443',
  '#FF1493',
  '#1c136c',
  '#CD6090',
  '#32CD32',
  '#FF6A6A',
  '#556B2F',
];

const lighterPalette = [
  '#b4a1cd',
  '#f6abac',
  '#fce38d',
  '#B4AFB1',
  '#9adce6',
  '#f7af91',
  '#75ff75',
  '#F5C756',
  '#9FC',
  '#9c9c9c',
  '#f3f7e1',
  '#f1efec',
  '#cf8d8c',
  '#FF9CD2',
  '#3e2dda',
  '#E1A1BD',
  '#84E184',
  '#FFBFBF',
  '#8DB14F',
]

export default function AnswerersEstiloDeComunicacaoGraph(props) {
  const [options, setOptions] = useState({
      title: {
        label: {
          text: props.title,
          align: 'center',
          style: { fontSize: 12, fontWeight: 'bold' }
        },
        position: 'center'
      },
      legend: {
        template: '%icon,%name'
      },
      debug: false,
      legend_position: 'bottom',
      // legend_visible: false,
      xAxis: {
          scale_invert: false,
          spacingPercentage: props.results && Object.keys(props.results).length > 1 ? 0.3 : 0.5
      },
      yAxis: {
          line: {
            visible: false
          },
          customTicks: [0, 100],
          defaultTick: {
            enabled: false,
            placement: 'inside',
            padding: props.results && Object.keys(props.results).length > 1 ? -13 : 0
          },
          scale: {
            range: [0, 100]
          },
          scale_interval: 25,
      },
      defaultSeries: {
          type: 'gauge column',
          minValue: 0,
          maxValue: 100,
          defaultPoint_tooltip:
              '<b>%name</b> <span>%yRange</span>',

          defaultPoint: {
              label: { visible: true, color: 'black', style_fontWeight: 'bold', align: 'center' }
          },
      },
      series: []
  })
  const [mutable, setMutable] = useState(false)

  useEffect(() => {
    const { results } = props;

    const series = [
        {
          name: 'Afetivo',
          shape_label: {
              text: '%name',
              style_fontSize: 12,
              verticalAlign: 'top',
          },
          angle: { start: -180, sweep: 90 },

          palette: palette,
          points: []
      },
      {
        name: 'Racional',
        shape_label: {
            text: '%name',
            style_fontSize: 12,
            verticalAlign: 'top',
        },
        angle: { start: -90, sweep: 90 },


        palette: palette,
        points: []
      },
      {
        name: 'Pragmático',
        shape_label: {
            text: '%name',
            style_fontSize: 12,
            verticalAlign: 'bottom',
        },
        angle: { start: 90, sweep: 90 },

        palette: palette,
        points: []
    },
      {
        name: 'Reflexivo',
        shape_label: {
            text: '%name',
            style_fontSize: 12,
            verticalAlign: 'bottom',
        },
        angle: { start: 0, sweep: 90 },

        palette: palette,
        points: []
      },
  ];

    Object.keys(results).map((key, index) => {
      props.setAnsGroupColor(key, palette[index])

      const attrResults = []

      if (results[key][`s${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] || results[key][`s${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] <= 0) {
        attrResults.push({ name: 'Afetivo', points: results[key][`s${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] })
      }

      if (results[key][`pn${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] || results[key][`pn${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] <= 0) {
        attrResults.push({ name: 'Racional', points: results[key][`pn${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] })
      }

      if (results[key][`pr${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] || results[key][`pr${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] <= 0) {
        attrResults.push({ name: 'Pragmático', points: results[key][`pr${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] })
      }

      if (results[key][`i${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] || results[key][`i${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] <= 0) {
        attrResults.push({ name: 'Reflexivo', points: results[key][`i${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`] })
      }

      const orderedResults = _.orderBy(attrResults, ['points'], ['desc'])

      const bestResult = {
        name: [orderedResults[0].name],
        points: orderedResults[0].points,
        legendVisible: orderedResults[0].name
      }

      orderedResults.map((r, i) => {
        if (i > 0 && r.points >= bestResult.points) {
          bestResult.name.push(r.name)
          bestResult.points = r.points
          bestResult.legendVisible = r.name
        }
      })

      const afetivo = series.find(a => a.name === 'Afetivo')
      afetivo.points.push({
        name: key,
        y: results[key][`s${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`],
        color: bestResult.name.find(r => r.includes('Afetivo')) ? palette[index] : lighterPalette[index],

        attributes_target: 100,
        legendEntry_visible: !!bestResult.legendVisible.includes('Afetivo')
      })

      const racional = series.find(a => a.name === 'Racional')
      racional.points.push({
        name: key,
        y: results[key][`pn${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`],
        color: bestResult.name.find(r => r.includes('Racional')) ? palette[index] : lighterPalette[index],

        attributes_target: 100,
        legendEntry_visible: !!bestResult.legendVisible.includes('Racional')
      })

      const prag = series.find(a => a.name === 'Pragmático')
      prag.points.push({
        name: key,
        y: results[key][`pr${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`],
        color: bestResult.name.find(r => r.includes('Pragmático')) ? palette[index] : lighterPalette[index],

        attributes_target: 100,
        legendEntry_visible: !!bestResult.legendVisible.includes('Pragmático')
      })

      const reflexivo = series.find(a => a.name === 'Reflexivo')
      reflexivo.points.push({
        name: key,
        y: results[key][`i${props.title && props.title.toLowerCase().includes('estresse') ? 'S' : ''}`],
        color: bestResult.name.find(r => r.includes('Reflexivo')) ? palette[index] : lighterPalette[index],

        attributes_target: 100,
        legendEntry_visible: !!bestResult.legendVisible.includes('Reflexivo')
      })
    })

    setOptions({
      ...options,
      series
    })
  }, [])

  if (options && options.series && options.series.length) return <div className='answerers-estilo-comunicacao-chart-wrapper'><JSCharting options={options} /></div>

  return(
    <p>Não há resultados de respondentes para ser exibido</p>
  )
}