import React from 'react';
import Folder from 'views/Pages/Assets/Folder';
import EstilosDeLideranca from './EstilosDeLideranca';
import Storytelling from './Storytelling';
import Case from './Case';
import Sociometria from './Sociometria';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';

class ResultadosDinamica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instrumentos: [],
    }
  }

  componentDidMount() {
    const { rodadas } = this.props;
    let instrumentos = [];
    if (rodadas) {
      rodadas.map(r => {
        r.instrumentos.map(i => {
          switch (i.content) {
            case 'storytelling':
              instrumentos.push('storytelling');
              break;
            case 'Estilos de liderança':
              instrumentos.push('Estilos de liderança');
              break;
            case 'sociometria':
              instrumentos.push('sociometria');
              break;
            case 'case':
              instrumentos.push('case');
              break;
            default:
              break;
          }
        })
      });
    }

    instrumentos = instrumentos.filter((instru, i) => instrumentos.indexOf(instru) === i)

    this.setState({
      instrumentos
    })
  }

  render() {
    const { instrumentos } = this.state;

    return (
      <div>
        {
          instrumentos.length ? instrumentos.map((instrumento, index) => (
            <>
              {
                instrumento === 'storytelling' &&
                <Folder
                  nome={`Pensamento Analítico e Nível de Abstração`}
                  key={`${instrumento}-${index}`}
                >
                  <Storytelling
                    idDinamica={this.props.idDinamica}
                    idParticipante={this.props.idParticipante}
                  />
                </Folder>
              }

              {
                instrumento === 'Estilos de liderança' &&
                <Folder
                  nome={`Liderança e Gestão de Equipe`}
                  key={`${instrumento}-${index}`}
                >
                  <EstilosDeLideranca
                    idProjeto={this.props.idProjeto}
                    idParticipante={this.props.idParticipante}
                    idDinamica={this.props.idDinamica}
                  />
                </Folder>
              }

              {
                instrumento === 'sociometria' &&
                <Folder
                  nome={`Influência e Persuasão`}
                  key={`${instrumento}-${index}`}
                >
                  <Sociometria
                    idProjeto={this.props.idProjeto}
                    idParticipante={this.props.idParticipante}
                    idDinamica={this.props.idDinamica}
                  />
                </Folder>
              }
              {
                instrumento === 'case' &&
                <Folder
                  nome={`Visão Sistêmica e de Negócios`}
                  key={`${instrumento}-${index}`}
                >
                  <Case
                    idProjeto={this.props.idProjeto}
                    idParticipante={this.props.idParticipante}
                    idDinamica={this.props.idDinamica}
                  />
                </Folder>
              }
            </>
          ))
          :
          <NoResultsFound />
        }
      </div>
    )
  }
}

export default ResultadosDinamica;