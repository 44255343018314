import React, { useState, useEffect } from "react";
import { FiX } from "react-icons/fi";

import { SearchWrapper } from "./styles";
import { Spinner } from "reactstrap";

function Search(props) {
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingToUse, setLoadingToUse] = useState("default");

  useEffect(() => {
    if (props.searchLoading !== undefined) setLoadingToUse("prop");
  }, []);

  useEffect(() => {
    if (props.resetValue) {
      setSearchValue('');
      props.resetValueFunction(false);
    }
  }, [props.resetValue]);

  return (
    <SearchWrapper style={props.style}>
      <span>
        <i
          style={{ fontSize: 17, marginLeft: 8, marginRight: 4 }}
          className="fa fa-search"
          aria-hidden="true"
        ></i>
      </span>{" "}
      <input
        onChange={async (e) => {
          e.preventDefault();
          if (searchLoading) return;

          const text = e.target.value;
          if (typingTimeout) clearTimeout(typingTimeout);

          setSearchValue(text);

          if (e.target.value && e.target.value.length < 3) return;

          setTypingTimeout(
            setTimeout(async () => {
              setSearchLoading(true);
              await props.searchFunction(text);
              setSearchLoading(false);
            }, 2000)
          );
        }}
        onKeyUp={async (e) => {
          e.preventDefault();

          if (searchLoading) return;

          if (e.key === "Enter") {
            setSearchLoading(true);
            if (typingTimeout) clearTimeout(typingTimeout);
            await props.searchFunction(searchValue);
            setSearchLoading(false);
          }
        }}
        placeholder={props.placeholder}
        value={searchValue}
      />
      {(loadingToUse === "prop" && props.searchLoading) ||
      (loadingToUse === "default" && searchLoading) ? (
        <div>
          <Spinner style={{ width: 17, height: 17, marginRight: 8 }} />
        </div>
      ) : (
        <FiX
          onClick={async () => {
            if (typingTimeout) clearTimeout(typingTimeout);
            if (!searchValue) return;

            setSearchLoading(true);

            setSearchValue("");

            await props.searchFunction("");
            setSearchLoading(false);
          }}
          style={{ fontSize: 20, cursor: "pointer", marginRight: 8 }}
          aria-hidden="true"
        />
      )}
    </SearchWrapper>
  );
}

export default Search;
