import apiCalls from 'config/apiCalls';

async function loadFeedbackText({ userId, assincronoId }) {
  const res = await apiCalls.autoEficacia.getResultTexts({ userId, assincronoId });
  return { 
    feedbackTexts: res.data.participant_modal_texts,
    whatsBeingMeasuredText: res.data.whats_being_measured_text
  };
}

export default loadFeedbackText;