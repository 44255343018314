export default function getResultsMaisPontuados(answer) {
  if (!answer) return;

  const obj = {
    E: answer['E'] === answer['I'] ? 0 : answer['E'],
    S: answer['S'] === answer['N'] ? 0 : answer['S'],
    T: answer['T'] === answer['F'] ? 0 : answer['T'],
    J: answer['J'] === answer['P'] ? 0 : answer['J'],
    I: answer['I'],
    N: answer['N'],
    F: answer['F'],
    P: answer['P'],
  }

  const newAnswer = { ...answer }

  newAnswer['E'] = obj['E'];
  newAnswer['S'] = obj['S'];
  newAnswer['T'] = obj['T'];
  newAnswer['J'] = obj['J'];

  return newAnswer;
}