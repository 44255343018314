import React, { Component } from "react";
import { Row } from "reactstrap";
import InputMask from "react-input-mask";
import TituloInput from "./TituloInput";
import "./css/newCampoNome.css";
/**
 * Campo padrão para nome
 *
 * @export
 * @class NewCampoNome
 * @extends {Component}
 */
export default class NewCampoNome extends Component {
  render() {

    let styleRow = {};
    let styleInput = {};
    if (this.props.noBorderBottom) {
      styleInput.marginLeft = '10px';
      styleInput.borderBottom = '1px solid #c9c9c9';

      styleRow.borderBottom = 'none';
    }

    if (this.props.unsetJustifyContentRow) {
      styleRow.justifyContent = 'unset';
    }

    return (
      <div style={this.props.style} className="campo-nome">
        <Row className="row-centered without-margin"
          style={styleRow}
        >
          <TituloInput>{this.props.label}</TituloInput>
          <InputMask
            type="text"
            name={this.props.name}
            size={this.props.size || undefined}
            style={this.props.inputStyle}
            className={this.props.inputClassName}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            maxLength={this.props.maxLength || undefined}
            mask={this.props.mask}
            value={this.props.value}
            required={this.props.required}
            disabled={this.props.disabled}
            tabIndex={this.props.tabIndex || 0}
            style={styleInput}
          />
        </Row>
      </div>
    );
  }
}
