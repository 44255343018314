import fetchFeedbackText from './fetchFeedbackText';
import fetchUnifiedProofPhrases from './fetchUnifiedProofPhrases';

export async function loadFeedbackText({ provaId, userId }) {
  return await fetchFeedbackText({ provaId, userId });
}

export async function getUnifiedProofPhrases({
  idResult,
  idUser,
  idProvaUnificada
}) {
  return await fetchUnifiedProofPhrases({
    idResult,
    idUser,
    idProvaUnificada
  });
}