export function calcularPontuacao(escolhas) {
  let escolhasRespondidas = escolhas.filter((e) => e.resposta);
  let acertoXnivel = escolhasRespondidas.map((e) => ({
    correta: e.resposta.correta,
    nivel: e.nivel
  }));
  let pontuacao = 0;
  acertoXnivel.forEach((x) => {
    if (x.correta) pontuacao += x.nivel + 1;
  });

  return pontuacao;
}

export function calculoPosicao(infoParticipantes) {
  // Consolidando ranking
  let ranking = infoParticipantes.map((user) => {
    return { id: user.id, pontuacao: user.pontuacao };
  });

  // Ordenando ranking
  ranking.sort((a, b) => {
    let aPont = a.pontuacao;
    let bPont = b.pontuacao;
    if (typeof aPont === 'string') {
      aPont = -1;
    }
    if (typeof bPont === 'string') {
      bPont = -1;
    }
    if (aPont === bPont) {
      return 0;
    }
    return aPont > bPont ? -1 : 1;
  });

  // Realizando ranking
  ranking.forEach((part, index) => {
    if (index) {
      if (part.pontuacao === ranking[index - 1].pontuacao)
        part.posicao = ranking[index - 1].posicao;
      else part.posicao = index + 1;
    } else part.posicao = index + 1;
  });

  // Atualizando posição do participante
  infoParticipantes.forEach((part) => {
    let rankingPart = ranking.find((r) => r.id === part.id);
    if (rankingPart) part.posicao = rankingPart.posicao;
    else part.posicao = '-';
  });

  // Ordenando participante final
  infoParticipantes.sort((a, b) => {
    if (a.posicao === b.posicao) {
      return 0;
    }
    return a.posicao < b.posicao ? -1 : 1;
  });
}

export function normalizarRanking(ranking, peso) {
  let min = ranking.reduce(
    (min, p) => (p.pontuacao < min ? p.pontuacao : min),
    ranking[0].pontuacao
  );
  let max = ranking.reduce(
    (max, p) => (p.pontuacao > max ? p.pontuacao : max),
    ranking[0].pontuacao
  );
  if (max !== min) {
    ranking.forEach((rank) => {
      if (max === 0) {
        rank.normalizado = 0;
      }
      rank.normalizado = Math.round((100 * (100 * rank.pontuacao)) / max) / 100;
    });
  }
}

/**
 * Normaliza o resultado ponderado da prova usando normalização por máximo
 * @author LucasSzavara
 *
 * @param {double} max O maior resultado da atividade
 * @param {double} playerResult O valor para ser normalizado
 * @param {number} topValue O maior valor possível, por padrão 100
 * @returns {double} O valor normalizado
 */
export function normalizarRankingPorMaximo(max, playerResult, topValue = 100) {
  if (max === 0) {
    return 0;
  }
  return (topValue * playerResult) / max;
}
function isObject(obj) {
  var type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
}
export function iterationCopy(src) {
  let target = {};
  for (let prop in src) {
    if (src.hasOwnProperty(prop)) {
      // if the value is a nested object, recursively copy all it's properties
      if (isObject(src[prop])) {
        target[prop] = iterationCopy(src[prop]);
      } else {
        target[prop] = src[prop];
      }
    }
  }
  return target;
}
/**
 * Retorna `true` se os objetos forem iguais
 * @author Lucas Szavara
 * @param {object} obj1
 * @param {object} obj2
 * @returns {boolean} Retorna `true` se e somente se ambos os objetos forem exatamente iguais ou se ambos os parâmetros não forem objetos, Se um for um objeto, mas o outro não, será retornado `false`
 */
export function objectsAreEqual(obj1, obj2) {
  for (let prop in obj1) {
    if (
      obj1 &&
      obj1.hasOwnProperty(prop) &&
      obj2 &&
      obj2.hasOwnProperty(prop)
    ) {
      if (isObject(obj1[prop])) {
        if (!objectsAreEqual(obj1[prop], obj2[prop])) {
          return false;
        }
      } else if (obj1[prop] != obj2[prop]) {
        return false;
      }
    } else {
      return false;
    }
  }
  for (let prop in obj2) {
    if (obj1.hasOwnProperty(prop) && obj2.hasOwnProperty(prop)) {
      if (isObject(obj1[prop])) {
        if (!objectsAreEqual(obj1[prop], obj2[prop])) {
          return false;
        }
      } else if (obj1[prop] != obj2[prop]) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
}

export const orderByName = (a, b) => {
  if (a && b) {
    if (a.nome < b.nome) {
      return -1;
    } else {
      return 1;
    }
  }
  return 0;
};