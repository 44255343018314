import React, { Component } from 'react';
import './css/CustomCheckbox.css';
/**
 * Componente padrão de checkbox do IAPP
 *
 * @export
 * @class CustomCheckbox
 * @extends {Component}
 */
export default class CustomCheckbox extends Component {
  render() {
    return (
      <div
        {...this.props}
        onClick={() =>
          this.props.onClick({
            target: { checked: !this.props.checked }
          })
        }
        className={`input-wrapper-checkbox centered ${this.props.className}`}
        style={{

          // lógica necessária pois este componente está acoplado com outras classes
          display: this.props.invisible ? 'none' : 'show'
        }}
      >
        <input
          type="checkbox"
          // onChange={this.props.onClick}
          // onClick={this.props.onClick}
          checked={this.props.checked}
          onChange={() => {}}
        />
        <span className="checkmark-checkbox small"></span>
      </div>
    );
  }
}
