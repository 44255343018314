import React, { Component } from 'react';
import DefaultTitle from 'components/default-title';
import { Row, Col } from 'reactstrap';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import EstilosDeLiderancaAssincGraph from 'views/Pages/Assets/ModalResultado/Resultados/components/EstilosDeLiderancaAssincGraph';
import Folder from 'views/Pages/Assets/Folder';
import * as EstilosService from './services';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import { FaCheck } from 'react-icons/fa';

import './styles.css'

const createDOMPurify = require("dompurify");
const DOMPurify = createDOMPurify(window);

const NoResponse = styled.div`
  color: #F00;
`;

const TextHMTL = styled.div`
  text-align: justify;
  font-size: 12px;

  p {
    margin-bottom: 5px;
  }
`;

export default class EstilosDeLideranca extends Component {
  constructor (props) {
    super(props);
    this.state = {
      answererFinished: false,
      answerers: null,
      resposta: "",
      userResults: null,
      loading: false,
      whatsBeingMeasuredText: ''
    }
  }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);

      let resposta = response.data;

      let answererResp = await apiCalls.resultados.assincronos.getInfoInstrumentoAnswerer(idProjeto, idParticipante, idAssincrono, idInstrumento);

      const userResults = await apiCalls.assincronos.getParticipantEstilosLiderancaResults({ participantId: idParticipante, assincId: idAssincrono, projectId: idProjeto, instrumentId: idInstrumento });
      const { grafico, answerers } = userResults.data;

      const results = await EstilosService.getFeedbackText({
        userId: this.props.idParticipante,
        assincronoId: this.props.idAssincrono
      });

      this.setState({ answererFinished: answererResp.data.answererFinished, whatsBeingMeasuredText: results.whatsBeingMeasuredText, resposta: resposta, userResults: grafico ? grafico : null, answerers });

    } catch (err) {
      // notifyError('Algo deu errado, por favor recarregue a página!');
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getInfoInstrumento();
  }

  render() {
    const {
      resposta
    } = this.state;

    return (
      <>
        {
          this.state.loading
          ?
          <Loading />
          :
          <>

              <>
                {
                  this.state.userResults || this.state.answererFinished
                  ?
                  <>
                    <EstilosDeLiderancaAssincGraph
                      participantId={this.props.idParticipante}
                      assincId={this.props.idAssincrono}
                      instrumentId={this.props.idInstrumento}
                      projectId={this.props.idProjeto}
                      id={`es-graph-${this.props.idInstrumento}-${this.props.idAssincrono}`}
                    />
                    <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} />
                  </>
                  :
                  <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                }

              {
                <Folder
                key={'estilos-lideranca-assinc-results-texts'}
                show={true}
                nome={'Resultado'}>
                {this.state.userResults ?
                <>
                <DefaultTitle style={{ marginTop: 10 }}>Exemplo de resultado</DefaultTitle>
                <div className='result-img-estilos'></div>
                <p>Segue breve explicação de como você deve interpretar o gráfico e seus resultados.</p><br/>
                <p>A partir das cores presentes na legenda é possível distinguir como você se
                autoavaliou e como foi a avaliação do grupo a seu respeito.</p><br/>
                <p>Caso seu gráfico se encontre mais preenchido à esquerda, você foi identificado/se
                identificou como adotando estilos voltados às pessoas, com a atenção mais voltada
                aos membros do grupo e aos resultados alcançados enquanto time.</p><br/>
                <p>Se você observa seu gráfico mais ocupado à direita, você foi visto/se viu adotando estilos
                voltados à tarefa, com o olhar mais voltado à execução.</p><br/>
                <p>E na hipótese de o gráfico concentrar as cores na parte de cima, você foi percebido/se
                percebeu adotando uma postura isenta, no sentido de se abster das decisões e
                discussões.</p><br/>
                <p>Abaixo, a descrição de cada estilo e o contexto em que seu uso é mais conveniente.</p><br/>
                <p>Os Estilos de Liderança <strong>Modelador</strong>, <strong>Diretivo</strong> e <strong>Autoritário</strong> são estilos voltados à
                tarefa/entrega, oportunos de serem utilizados em grupos de menor maturidade, sendo
                eficazes nas situações expostas a seguir:</p><br/>
                <p><strong>• Estilo Modelador:</strong> busca assegurar que as tomadas de decisão sejam guiadas pela
                cultura e modus operandi atual da organização. Indicado para treinar e preparar
                times mais jovens que estejam em processo de apreender e absorver os valores
                organizacionais, para que no futuro não necessitem de supervisão tão direta.</p><br/>
                <p><strong>• Estilo Diretivo:</strong> procura direcionar de maneira a obter resultados melhores em menor
                tempo. Indicado para acelerar times, contudo, é necessário deixar claro as razões da
                aplicação desse estilo de liderança e os critérios da orientação.</p><br/>

                <p><strong>• Estilo Autoritário:</strong> utilizado em situações mais críticas e com times de baixíssima
                maturidade. Deve ser utilizado com muita cautela e de forma pontual, visando
                acelerar o amadurecimento do time evitando, assim, repetir o uso do estilo.</p><br/>

                <p>Os Estilos de Liderança <strong>Desenvolvedor</strong>, <strong>Afetivo</strong> e <strong>Participativo</strong> são estilos voltados às
                pessoas, oportunos de serem utilizados em grupos de maior maturidade, sendo eficazes
                nas situações expostas a seguir:</p><br/>

                <p><strong>• Estilo Desenvolvedor:</strong> visa garantir iniciativas que preparem o time para lidar com
                maiores níveis de complexidade. Demanda comprometimento e alta maturidade dos
                membros, e tem o propósito de formar uma equipe capaz de exercer a autonomia
                consciente do grau de responsabilidade exigido.</p><br/>

                <p><strong>• Estilo Afetivo:</strong> busca gestão pela proximidade e cuidado com os membros do time,
                estabelecendo vínculos que fortaleçam o engajamento. Necessário observar se o
                exercício desse estilo, em médio e longo prazos, gera uma leitura de dependência
                entre líder e liderados, o que pode comprometer a própria liderança do grupo.</p><br/>

                <p><strong>• Estilo Participativo:</strong> privilegia o envolvimento do líder com os liderados e de todo o
                time. Ao atuar sob esse estilo, faz-se necessário reforçar com membros a importância
                da contribuição de cada um, contudo, mantendo a posição de um líder, para que não
                se gere a conotação de uma liderança que “delarga”, em vez de delegar ao grupo as
                tomadas de decisão.</p><br/>

                <p><strong>• Estilo Laissez-Faire:</strong> traduzido como “deixar fazer” é também adotado com o
                significado de anti-estilo. Ou seja, aquele profissional que evita posicionar-se, ou se
                abstém recorrentemente, adotando uma postura de conformidade, o que pode gerar
                a percepção de alienação ou desinteresse na discussão, no assunto ou nas pessoas
                envolvidas. Nesse sentido, é importante que procure participar, posicionando-se,
                mesmo que em concordância com a posição majoritária dos pares e/ou superiores.</p><br/></> : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>}
                </Folder>
              }

              { this.props.isConsultant ? <Folder
              show={false}
              nome={'Respondentes'}
              >
                {
                   this.state.answerers ? Object.keys(this.state.answerers).map(key => (
                    <>
                    <div>
                      <div style={{ marginBottom: 10 }}>
                        <span style={{ fontSize: 14, textTransform: 'capitalize' }}>{key} - {this.state.answerers[key].filter(r => r.finished).length} / {this.state.answerers[key].length}</span>
                      </div>
                      {
                        this.state.answerers[key].map(r => (
                          <>
                            <span style={{ textTransform: 'capitalize', fontSize: 12 }}>{r.name}</span>
                            { r.finished ? <FaCheck style={{ marginLeft: 5 }} size={11}/> : null }
                            <br />
                          </>
                        ))
                      }
                    </div>
                    <hr />
                    </>
                  ))
                  :
                  <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                }
              </Folder> : null}
              </>
          </>
        }
      </>
    )
  }
}