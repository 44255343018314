import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ButtonCancel, ButtonSave } from "views/Pages/Assets/Assets";

export default function Crop(props) {
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 200,
    aspect: 16/9
  });
  const [imageRef, setImageRef] = useState(null);
  const [img, setImg] = useState('');
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if(props.crop) setCrop(props.crop);
  }, [imageRef])

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(
        imageRef,
        crop,
        props.fileName || 'file.jpeg'
      );
      setImg(croppedImage)
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }

          resolve(new File([blob], fileName, { type: 'image/jpeg' }));
        },
        'image/jpeg',
        1
      );
    });
  }

  return (
    <Modal isOpen={props.showCropModal}>
      <ModalHeader>
        <h5>Redimensionar imagem</h5>
      </ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ReactCrop
            minWidth={props.minWidth}
            minHeight={props.minHeight}
            onImageLoaded={(image) => setImageRef(image)}
            circularCrop={props.circularCrop}
            locked={props.locked}
            src={props.src}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}
            onComplete={makeClientCrop}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel disabled={props.loading || loading} onClick={async() => {
          setLoading(true);
          await props.onCancelCrop();
          setLoading(false);
        }}>Cancelar</ButtonCancel>
        <ButtonSave disabled={props.loading || loading} onClick={async() => {
          setLoading(true);
          await props.onSubmitCrop(img);
          setLoading(false);
        }}>Salvar</ButtonSave>
      </ModalFooter>
    </Modal>
  );
}