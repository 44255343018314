import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Modal, Col, Row, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from 'views/Participante/components/Buttons';

import { notifySuccess, notifyError } from "../../Services/Notificacoes";
import { validatePassword } from "Services/validatePassword";
import api from "../../Services/api";

const InputLabel = styled(Col)`
  padding-top: 15px;
  padding-bottom: 10px;
  color: #000;
`;

const InputText = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
`;
class ModalAlterarSenha extends Component {
  static propTypes = {
    cpf: PropTypes.string.isRequired,
    senhaAntiga: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,

    toggle: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  static defaultProps = {
    isOpen: false,
    cpf: null
  };

  state = {
    passwordErrors: [],
    senhaNova: "",
    senhaNova2: "",
    senhaAntiga: "",
    loadingSpinner: false
  };

  validar = () => {
    const { senhaNova, senhaNova2, passwordErrors } = this.state;

    if (passwordErrors.length) {
      notifyError("Senha não atende os requisitos.");
      this.setState({ loadingSpinner: false });
    } else if (senhaNova !== senhaNova2) {
      notifyError("As senhas precisam ser iguais!");
      this.setState({ loadingSpinner: false });
    } else return true;
  };

  handleForm = async e => {
    try {
      e.preventDefault();
      this.setState({ loadingSpinner: true });

      const cpf = this.props.cpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");

      const { senhaNova } = this.state;
      const { senhaAntiga } = this.props;

      if (!senhaAntiga) return notifyError("senha antiga não informada");
      if (!senhaNova) return notifyError("senha nova não informada");

      if (this.validar()) {
        api
          .post("api/auth/modify-password", {
            cpf,
            senhaAntiga,
            senhaNova
          })
          .then(response => {
            if (response.data.token) {
              sessionStorage.setItem("token", response.data.token);
              sessionStorage.setItem("username", response.data.user.nome);
              sessionStorage.setItem("user", response.data.user._id);
              notifySuccess("Senha alterada com sucesso");
              this.setState({
                modalAlterarSenha: false
              });
              this.props.toggle();
            }
          })
          .catch(err => {
            console.error(err);
            if (err.response && err.response.data.error)
            // erro de autorização
              notifyError("Não foi possível salvar as alterações. Por favor, recarregue a página e tente novamente");
            else
              notifyError(
                "Não foi possível salvar as alterações. Por favor, recarregue a página e tente novamente"
              );
            this.setState({ loadingSpinner: false });
          });
      }
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível salvar as alterações. Por favor, recarregue a página e tente novamente");
    } finally {
      this.setState({ loadingSpinner: false });
    }
  };

  sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  toogle = () => {
    this.setState({
      loadingSpinner: false
    });
  };

  handleChange = e => {
    if (e.target.name.toLowerCase() === 'senhanova') {
      const errors = validatePassword(e.target.value);

      this.setState({ passwordErrors: errors });
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Modal
        size="lg"
        toggle={this.props.toggle}
        isOpen={this.props.isOpen}
        onClosed={this.props.handleClose}
      >

        <ModalHeader
          toggle={this.props.toggle}
        >
          Alterar senha
        </ModalHeader>
        <ModalBody style={{ fontSize: 12 }}>
          <form onSubmit={this.handleForm}>
            <Row>
              <Col sm={6} style={{ padding: 0 }}>
                <InputLabel sm={12}>
                  <strong>Senha Antiga*</strong>
                </InputLabel>
                <Col sm={12}>
                  <InputText
                    type="password"
                    placeholder="******"
                    name="senhaAntiga"
                    value={this.props.senhaAntiga}
                    onChange={this.props.handleChange}
                    required
                  />
                </Col>
              </Col>
            </Row>

            <Row>
              <Col sm={6} style={{ padding: 0 }}>
                <InputLabel sm={12}>
                  <strong>Nova Senha*</strong>
                </InputLabel>
                <Col sm={12}>
                  <InputText
                    type="password"
                    placeholder="******"
                    name="senhaNova"
                    onChange={this.handleChange}
                    required
                  />
                  {
                    this.state.passwordErrors.map(error => {
                      return (
                        <>
                          <span style={{ fontSize: 11, color: '#f00', fontStyle: 'italic', whiteSpace: 'nowrap' }}>{error}</span> <br />
                        </>
                      )
                    })
                  }
                </Col>
              </Col>
              <Col sm={6} style={{ padding: 0 }}>
                <InputLabel sm="12">
                  <strong>Confirmar Nova Senha*</strong>
                </InputLabel>

                <Col sm={12}>
                  <InputText
                    type="password"
                    placeholder="******"
                    name="senhaNova2"
                    onChange={this.handleChange}
                    required
                  />
                </Col>
              </Col>
            </Row>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={this.state.loadingSpinner}
            color={'success'}
            onClick={this.handleForm}
          >
            Alterar
        </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalAlterarSenha;
