import axios from "axios";

export default async function calculateResult(projectId, modules, participantsIds, unify, proofsToUnify, assessmentsToUnify, asyncsToUnify) {
  try {

    let url = `${process.env.REACT_APP_RESULTS_API_URL}/results-api/cards`;

    if (unify && (unify.unifyProofs || unify.unifyAssessments || unify.unifyAsyncs)) {
      url += '?';
      if (unify.unifyProofs) url += 'unifyProofs=true&';
      if (unify.unifyAssessments) url += 'unifyAssessments=true&';
      if (unify.unifyAsyncs) url += 'unifyAsyncs=true';
    }

    return await axios.post(
      url,
      {
        modules,
        participantsIds,
        projectId,
        proofsToUnify,
        assessmentsToUnify,
        asyncsToUnify
      }
    );
  } catch (err) {
    console.error(err);
  }
}
