export function twoDecimalPoints(floatValue) {
  return Number(floatValue.toFixed(2))
}

export function defineLinearFunc(points) {
  const point1 = points[0];
  const point2 = points[1];

  // if (point1.x === point2.x) return [0, point2.y];

  // ax + b = 0
  // a * point1.x + b = point1.y
  // a * point2.x + b = point2.y
  // ---------------------------
  // (point1.x - point2.x)*a = (point1.y-point2.y)
  let a = twoDecimalPoints(
    twoDecimalPoints((point1.y - point2.y)) / 
    twoDecimalPoints((point1.x - point2.x))
  )
  
  // (a * point1.x) + b = point1.y
  let b = twoDecimalPoints(point1.y - (a * point1.x))

  return [twoDecimalPoints(a), twoDecimalPoints(b)]
}

export const func = (a, b) => (x) => {
  return twoDecimalPoints(a * x + b)
};

export default {
  func,
  defineLinearFunc,
  twoDecimalPoints
}