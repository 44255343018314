import apiCalls from 'config/apiCalls';
import { getDimensao } from 'models/escuta-ativa/getDimensao';

async function fetchUserResult({ idProjeto, idParticipante, idAssincrono, idInstrumento }) {
  const response = await apiCalls.resultados.assincronos
    .getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);
  const resultado = response.data[0];
  if (!resultado.dimensao) resultado.dimensao = getDimensao(resultado);
  return resultado;
}

export default fetchUserResult;