import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  text-decoration: underline;
  color: #0070f3;
`

function AsyncParticipantInviteTemplate() {
  return (
    <>
      <p><span><strong>Título do e-mail:</strong> FormareTech - Convite para preencher os instrumentos de autoconhecimento.</span><br />
      <span><strong>Remetente:</strong> no-reply@formaretech.com.br</span><br /></p>
      <img src="https://files-storage.s3.amazonaws.com/mail_assets/formaretech-logo-mail.png" alt="FormareTech Logo" /><br />
      <p>Olá, João Goulard</p> <br />
      <p>Você foi convidado a preencher instrumentos de autoconhecimento no sistema da Formare Tech.
      Não esqueça de enviar todos os instrumentos antes do prazo final.</p> <br />
      <p>- Instrumento 1 - Até 01/01/2030</p>
      <p>- Instrumento 2 - Até 01/02/2030</p>
      <p>- Instrumento 3 - Até 01/03/2030</p> <br />
      <p>Para poder efetuar sua entrega, entre na <StyledSpan>área do participante</StyledSpan>, insira suas credenciais e realize as
      atividades que estiverem na coluna “a serem realizadas”.</p>
      <p>Para acessar, utilize como login o seu CPF e a senha já configurada anteriormente.</p>
      <p>Se este é o seu primeiro acesso, utilize as credenciais abaixo:</p>
      <p>Site: <StyledSpan>https://iapp.formaretech.com.br/</StyledSpan></p>
      <p>Usuário: 000.000.000-00</p>
      <p>Senha provisória: 123456</p>
      <p>Após o primeiro acesso será solicitado que você altere sua senha.</p>
      <p>Qualquer dúvida ou problema, entre em contato conosco através do E-mail:
      meu_email@consultor.com.br</p> <br />
      <p>Por favor, não responda este e-mail.</p>
      <p>Obrigado!</p>
      <strong>FormareTech</strong>
    </>
  )
}

export default AsyncParticipantInviteTemplate