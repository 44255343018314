import React, { Component } from 'react';
import apiCalls from 'config/apiCalls';
import { notifyError } from 'Services/Notificacoes';
import TiposPersonalidadeGraph from 'views/Pages/Modulos/Assincronos/Components/TiposPersonalidadeGraph';
import TiposPersonalidadeAnswererGraph from 'views/Pages/Modulos/Assincronos/Components/TiposPersonalidadeAnswererGraph';
import Folder from "views/Pages/Assets/Folder";
import { assincronosApi } from 'Services/api';
import { Col, Row } from 'reactstrap';

import './scss/styles.scss';
import Loading from 'Services/Loading';
import styled from 'styled-components';
import NoResultsFound from '../Components/NoResultsFound';
import * as TiposPersonalidadeService from './services';
import Messages from 'static/Messages';
import formatAnswer from 'models/tipos-personalidade/formatAnswer';
import normalizeResultValues from 'models/tipos-personalidade/normalizeResultValues';
import getDimensoesMaisPontuadas from 'models/tipos-personalidade/getDimensoesMaisPontuadas';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';

const TiposDePersonalidade = styled.div``;

const FeedbackText = styled.p`
p {
  font-size: 12px;
  margin-bottom: 5px;

  strong {
    font-size: 14px;
  }

  & ~ p {
    margin-left: 0px;
  }
}
`

/**
* ### Componente de Resultados de Tipos De Personalidade
*
* /**
* @author Gustavo Carvalho Silva
* @since 01/12/2020
* @props
* - idProjeto
* - idParticipante
* - idAssincrono
* - idInstrumento
*
* @desc Monta os resultados de um instrumento do tipo Tipos De Personalidade
*/
export default class TipoDePersonalidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answererFinished: false,
      respostas: [],
      respostasFormatadas: {},
      loading: false,
      personalidade: {},
      feedbackText: '',
      whatsBeingMeasuredText: ''
    }
  }

  // async fetchTextoAncora(dimensao) {
  //   return await assincronosApi.get(
  //     `/resultado/tipos-de-personalidade/texto-consultor/${dimensao}`,
  //   );
  // }

  /**
   * @description Faz a montagem dos resultados do instrumento
   */
  async getInfoInstrumento() {
    const { idProjeto, idParticipante, idAssincrono, idInstrumento } = this.props;
    this.setState({ loading: true });

    try {
      let response = await apiCalls.resultados.assincronos.getInfoInstrumento(idProjeto, idParticipante, idAssincrono, idInstrumento);

      const respostas = response.data;

      if (respostas && respostas.length) {
        let format = formatAnswer(respostas[0]);
        this.setState({ respostas });
        this.setState({ respostasFormatadas: normalizeResultValues(format) });

        const dimensao = getDimensoesMaisPontuadas(format)[0];
        let responseDimensao = {};

        if (this.props.participantViewResults) {
          responseDimensao = await assincronosApi.get(`/resultado/tipos-de-personalidade/texto-participante/${dimensao}`);
        } else {
          responseDimensao = await assincronosApi.get(`/resultado/tipos-de-personalidade/texto-consultor/${dimensao}`);
        }

        const personalidade = responseDimensao.data;

        const res = await apiCalls.resultados.assincronos.getInfoInstrumentoAnswererTipos(idProjeto, idParticipante, idAssincrono, idInstrumento);

        const { answererFinished } = res.data

        this.setState({ personalidade, answererFinished });

        return respostas;
      }

    } catch (err) {
      console.error(err);
      notifyError(Messages.Geral.msgDataFetchError);
    } finally {
      this.setState({ loading: false });
    }
  }

  async getTextos() {
    const { feedbackText, whatsBeingMeasuredText } = await TiposPersonalidadeService
      .getFeedbackText({
        userId: this.props.idParticipante,
        assincronoId: this.assincId
       });
    return { feedbackText, whatsBeingMeasuredText };
  }

  componentDidMount() {
    this.assincId = this.props.idAssincrono;

    const loadTexts = async () => {
      await this.getTextos()
        .then(({ feedbackText, whatsBeingMeasuredText }) => {
          this.setState({
            feedbackText,
            whatsBeingMeasuredText
          });
        })
        .catch(err => {
          console.error(err);
          notifyError(Messages.Assincronos.msgFeedbackTextFetchFailed);
        })
    }

    this.getInfoInstrumento()
      .then(async (respostas) => {
        if (!respostas || !respostas.length) {
          return; // Results not found message.
        } else {
          await loadTexts();
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const {
      respostas,
      personalidade,
      respostasFormatadas
    } = this.state;

    return (
      <TiposDePersonalidade>
        {
          this.state.loading
            ?
            <Loading />
            :
            <>
                {
                  respostas.length > 0
                    ?
                      <TiposPersonalidadeGraph id={`tipos-personalidade-graph-${this.props.idAssincrono}`}
                      participantId={this.props.idParticipante}
                      assincronoId={this.props.idAssincrono}
                      instrumentId={this.props.idInstrumento}
                      projectId={this.props.idProjeto} />
                    :
                    <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>
                }

                {
                  this.state.answererFinished ?
                    <TiposPersonalidadeAnswererGraph id={`tipos-personalidade-graph-answerer-${this.props.idAssincrono}`}
                    participantId={this.props.idParticipante}
                    assincronoId={this.props.idAssincrono}
                    instrumentId={this.props.idInstrumento}
                    projectId={this.props.idProjeto} />
                  : null
                }
              {
                respostas.length > 0 ?
                  <>
                    <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} />
                        <br />
                  </>
                : null
              }

              <Folder
              question={true}
              title={'O resultado da sua autoavaliação não considera a avaliação dos respondentes.'}
              key={'tipos-personalidade-results-texts'}
              show={true}
              nome={'Autoavaliação'}>
                {respostas.length > 0 ? <><Row>
                  <Col>
                    <p>
                      <h3 style={{ fontSize: 18, fontWeight: 'bold' }}>Resultado - {personalidade.tipo}</h3>
                    </p>
                  </Col>
                </Row>

                <br />

                <Row>
                  <Col style={{
                    fontSize: '12px',
                    marginBottom: 15
                  }}>
                    <FeedbackText dangerouslySetInnerHTML={{
                      __html: this.state.feedbackText
                    }}
                  ></FeedbackText>
                  </Col>
                </Row></> : <NoResultsFound>Não há resultados para serem exibidos.</NoResultsFound>}
              </Folder>
            </>
        }


      </TiposDePersonalidade>
    )
  }
}