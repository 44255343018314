export default function getDimensoesMaisPontuadas(answer) {
  try {
    Object.keys(answer).map(key => {
      const textoEI =
      answer[key]["E"] > answer[key]["I"]
        ? ["E"]
        : answer[key]["E"] === answer[key]["I"]
          ? ["I", "E"]
          : ["I"];
      const textoNS =
        answer[key]["N"] > answer[key]["S"]
          ? ["N"]
          : answer[key]["N"] === answer[key]["S"]
            ? ["N", "S"]
            : ["S"];
      const textoTF =
        answer[key]["T"] > answer[key]["F"]
          ? ["T"]
          : answer[key]["T"] === answer[key]["F"]
            ? ["F", "T"]
            : ["F"];
      const textoJP =
        answer[key]["J"] > answer[key]["P"]
          ? ["J"]
          : answer[key]["J"] === answer[key]["P"]
            ? ["P", "J"]
            : ["P"];
      let textos = [];
      textoEI.forEach(letraEI =>
        textoNS.forEach(letraNS =>
          textoTF.forEach(letraTF =>
            textoJP.forEach(letraJP =>
              textos.push(`${letraEI}${letraNS}${letraTF}${letraJP}`)
            )
          )
        )
      );

      answer[key].bestResults = textos[0];
    });
  } catch (err) {
    console.error(err);
    return [];
  }

}