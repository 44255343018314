import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from 'react-router-dom';

import { ButtonCancel, ButtonSave } from "views/Pages/Assets/Assets";
import ReportProgressAPI from "views/Reports/ReportsList/ReportProgressAPI";
import { notifyError } from 'Services/Notificacoes';
import Messages from 'static/Messages';

const { PRE_VISUALIZATION_DONE } = ReportProgressAPI.channels;

class ReportNotificationModal extends React.Component {

  state = {
    showModal: false,
    report_id: null,
  }

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.configSocket = this.configSocket.bind(this);
    this.handlePreVisualization = this.handlePreVisualization.bind(this);
  }

  toggleModal() {
    this.setState(prev => ({ showModal: !prev.showModal }));
  }

  async openModal(report_id) {
    if (!report_id) throw new Error('report_id not defined');

    this.setState({
      showModal: true,
      report_id
    });
  }

  closeModal() {
    this.setState({ 
      showModal: false,
      report_id: null
    });
  }

  handlePreVisualization(data) {
    try {
      if (data.consultant_id !== this.consultant_id) {
        return;
      }
      if (data.error) {
        this.closeModal();
        return notifyError(Messages.Reports.msgCannotGenerateReport);
      }

      this.openModal(data.report_id);
    } catch (err) {
      console.error(err);
      notifyError(Messages.Geral.msgSocketProcessingError);
    }
  }

  async configSocket() {
    ReportProgressAPI.socket.on(PRE_VISUALIZATION_DONE, this.handlePreVisualization);
  }

  componentDidMount() {
    this.consultant_id = sessionStorage.getItem('user');
    this.configSocket();
  }

  componentWillUnmount() {
    ReportProgressAPI.socket.off(PRE_VISUALIZATION_DONE, this.handlePreVisualization);
  }

  render() {

    const { showModal } = this.state;

    return <Modal
      isOpen={showModal}
      toggle={this.toggleModal}
    >
      <ModalHeader
        toggle={this.toggleModal}
      >
        Atenção!
    </ModalHeader>
      <ModalBody style={{ fontSize: 12 }}>
        <p>Relatório Gerado. Visualizar?</p>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel
          onClick={() => {
            this.closeModal()
          }}
        >
          Não
    </ButtonCancel>

        <Link to={`/clientes/projetos/report/${this.state.report_id}`}>
          <ButtonSave>Sim</ButtonSave>
        </Link>
      </ModalFooter>
    </Modal>
  }
}

export default ReportNotificationModal;