import React, {useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from "reactstrap";
import { ButtonCancel } from '../../Pages/Assets/Assets';
import { notifyError, notifySuccess } from 'Services/Notificacoes';

import axios from 'axios';

import './styles.css';

function AssessmentRegisterModal(props) {
  const [scriptTag, setScriptTag] = useState(null);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [escreva_sua_duvida_para_q, setDuvida] = useState('');

  function resetState() {
    setNome('');
    setSobrenome('');
    setEmail('');
    setWhatsapp('');
    setDuvida('');
  }

  const mask = (o, f, erased) => {
    setTimeout(function () {
      var v = f(o.value, erased);
      if (v != o.value) {
        o.value = v;
      }
    }, 1);
  }

  const mphone = (v, erased) => {

    if (erased) return v;
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  return(
    <Modal
      isOpen={props.isOpen}
      toggle={() => props.toggle()}
      onOpened={() => {
          const script        = document.createElement('script');
          script.type         = 'text/javascript';
          script.async        = true;
          script.src          = 'https://mautic.formaretech.com.br/media/js/mautic-form.js';
          script.className    = 'form-script';

          document.body.appendChild(script);

          setScriptTag(script);
      }}
      size='xl'
      onClosed={() => {
        document.body.removeChild(scriptTag);

        resetState();
        setScriptTag(null);
      }}
    >

      <ModalHeader toggle={() => props.toggle()}>
        Assessment
      </ModalHeader>
      <ModalBody>
        <div style={{ fontSize: 12 }}>
          <p>Para acessar o módulo de Assessment é necessário formação específica na metodologia desenvolvida pela <strong>FormareTech</strong> em mapeamento de prontidão e potencial.</p>
          <p>Venha fazer parte dessa revolução, use o formulário abaixo e se inscreva.</p>
          <Row style={{ marginTop: 50 }}>

        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} sm={6}>
          <img className='image-form' style={{ height: 512, top: '31px', position: 'absolute' }} src='https://files-storage.s3.amazonaws.com/iapp-assets/consultor-3-ajustado-1-531x1024.png'/>
        </Col>

        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} sm={6}>
        <span style={{ fontWeight: 'bold', fontSize: 14 }}>Venha fazer parte dessa revolução!</span>
          <div id="mauticform_wrapper_contatoformaretech" className="mauticform_wrapper">
          <form onSubmit={async(e) => {
            try {
              e.preventDefault();

              if (!nome) return notifyError("Campo nome é requerido.");
              if (!email) return notifyError("Campo email é requerido.");

              const formData = new FormData();

              const currentURL = window.location.href

              formData.append('mauticform[nome]', nome);
              formData.append('mauticform[sobrenome]', sobrenome);
              formData.append('mauticform[email]', email);
              formData.append('mauticform[whatsapp]', whatsapp);
              formData.append('mauticform[escreva_sua_duvida_para_q]', escreva_sua_duvida_para_q);
              formData.append('mauticform[formId]', '9');
              formData.append('mauticform[return]', currentURL);
              formData.append('mauticform[formName]', 'contatoformaretech');
              formData.append('mauticform[messenger]', '1');


              await axios.post('https://mautic.formaretech.com.br/form/submit?formId=9', formData, {
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                }
              });

              props.toggle();

              notifySuccess("Seu contato foi enviado com sucesso. Assim que possível responderemos sua mensagem");
            } catch (err) {
              console.error(err);
              notifyError("Ocorreu um erro ao enviar o formulário");
            }
          }} autoComplete="false" role="form" id="mauticform_contatoformaretech" data-mautic-form="contatoformaretech" enctype="multipart/form-data">
              <div className="mauticform-error" id="mauticform_contatoformaretech_error"></div>
              <div className="mauticform-message" id="mauticform_contatoformaretech_message"></div>
              <div className="mauticform-innerform">


                <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                  <div id="mauticform_contatoformaretech_nome" data-validate="nome" data-validation-type="text" className="mauticform-row mauticform-text mauticform-field-1 mauticform-required">
                      <label id="mauticform_label_contatoformaretech_nome" for="mauticform_input_contatoformaretech_nome" className="mauticform-label">Nome</label>
                      <input onChange={(e) => setNome(e.target.value)} value={nome} id="mauticform_input_contatoformaretech_nome" name="mauticform[nome]" className="mauticform-input" type="text" />
                      <span className="mauticform-errormsg" style={{ display: 'none' }}>Isso é obrigatório.</span>
                  </div>

                  <div id="mauticform_contatoformaretech_sobrenome" className="mauticform-row mauticform-text mauticform-field-2">
                      <label id="mauticform_label_contatoformaretech_sobrenome" for="mauticform_input_contatoformaretech_sobrenome" className="mauticform-label">Sobrenome</label>
                      <input onChange={(e) => setSobrenome(e.target.value)} value={sobrenome} id="mauticform_input_contatoformaretech_sobrenome" name="mauticform[sobrenome]" className="mauticform-input" type="text" />
                      <span className="mauticform-errormsg" style={{ display: 'none' }}></span>
                  </div>

                  <div id="mauticform_contatoformaretech_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-3 mauticform-required">
                      <label id="mauticform_label_contatoformaretech_email" for="mauticform_input_contatoformaretech_email" className="mauticform-label">E-mail</label>
                      <input onChange={(e) => setEmail(e.target.value)} value={email} id="mauticform_input_contatoformaretech_email" name="mauticform[email]" className="mauticform-input" type="email" />
                      <span className="mauticform-errormsg" style={{ display: 'none' }}>Isso é obrigatório.</span>
                  </div>

                  <div id="mauticform_contatoformaretech_whatsapp" className="mauticform-row mauticform-tel mauticform-field-4">
                      <label id="mauticform_label_contatoformaretech_whatsapp" for="mauticform_input_contatoformaretech_whatsapp" className="mauticform-label">Whatsapp</label>
                      <span className="mauticform-helpmessage">Insira seu número de celular (xx) xxxxx-xxxx</span>
                      <input onChange={(e) => {

                        let erased = false;
                        if (e.target.value.length < whatsapp.length) erased = true;
                        mask(e.target, mphone, erased);
                        setWhatsapp(e.target.value);

                      }} maxLength={15} value={whatsapp} id="mauticform_input_contatoformaretech_whatsapp" name="mauticform[whatsapp]" className="mauticform-input" type="tel" />
                      <span className="mauticform-errormsg" style={{ display: 'none' }}></span>
                  </div>

                  <div id="mauticform_contatoformaretech_escreva_sua_duvida_para_q" className="mauticform-row mauticform-text mauticform-field-5">
                      <label id="mauticform_label_contatoformaretech_escreva_sua_duvida_para_q" for="mauticform_input_contatoformaretech_escreva_sua_duvida_para_q" className="mauticform-label">Escreva sua mensagem</label>
                      <textarea onChange={(e) => setDuvida(e.target.value)} value={escreva_sua_duvida_para_q} id="mauticform_input_contatoformaretech_escreva_sua_duvida_para_q" name="mauticform[escreva_sua_duvida_para_q]" className="mauticform-textarea"></textarea>
                      <span className="mauticform-errormsg" style={{ display: 'none' }}></span>
                  </div>




          <div id="mauticform_contatoformaretech_captcha" className="mauticform-row mauticform-div-wrapper mauticform-field-6">
                      <input id="mauticform_input_contatoformaretech_captcha" name="mauticform[captcha]" value="" className="mauticform-input" type="hidden" />
              <span className="mauticform-errormsg" style={{ display: 'none' }}></span>
          </div>

                  <div id="mauticform_contatoformaretech_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-7">
                      <button type="submit" name="mauticform[submit]" id="mauticform_input_contatoformaretech_submit" value="" className="mauticform-button btn btn-default">Enviar</button>
                  </div>
                  </div>
              </div>

              <input type="hidden" name="mauticform[formId]" id="mauticform_contatoformaretech_id" value="9" />
              <input type="hidden" name="mauticform[return]" id="mauticform_contatoformaretech_return" value="" />
              <input type="hidden" name="mauticform[formName]" id="mauticform_contatoformaretech_name" value="contatoformaretech" />

              </form>
            </div>
      </Col>
      </Row>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonCancel onClick={ () => {
        props.toggle()
      } }>Cancelar</ButtonCancel>
      </ModalFooter>
    </Modal>
  );
}

export default AssessmentRegisterModal;