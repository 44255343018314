import React from 'react';
import styled from 'styled-components';

const color = {
  success: 'linear-gradient( to bottom, #18b3c0 0%, #10949d 100% );',
  primary: 'linear-gradient( to bottom, #18b3c0 0%, #10949d 100% );',
  danger: 'linear-gradient( to bottom, #ca4747 0%, #b63b3b 100% );',
  secondary: 'linear-gradient( to bottom, #153b50 0%, #04191b 100% );',
}

export const Button = styled.button`
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;

  border-radius: 3px;

  border: none;
  background: ${props => color[props.color]};
`;

