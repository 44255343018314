import TextButton from 'assets/TextButton';
import React, { useState } from 'react';

export default function ShowArchived({ children, handleClick, dataTour }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
    {
      isOpen
      ?
      <div data-tour={dataTour}>
        <TextButton onClick={() => setIsOpen(!isOpen)} style={{marginTop: 20, marginBottom: 20}}>
          Esconder Arquivados
        </TextButton>
        <div style={{ width: '100%' }}>
          {children}
        </div>
      </div>
      :
      <div data-tour={dataTour}>
        <TextButton onClick={async () => {
            setIsOpen(!isOpen)
            if (handleClick) await handleClick();
          }} style={{marginTop: 20, marginBottom: 20}}>
          Visualizar Arquivados
        </TextButton>
      </div>
    }
    </>
  )
}