import React from 'react';

function InvalidBrowser() {
    return (
        <div style={{ width: '100%', height: 'fit-content', background: '#f00', textAlign: 'center', top: 0, left: 0, position: 'sticky', zIndex: 1000 }}>
          <span style={{ color: '#fff', textAlign: 'center', fontWeight: 'bold' }}>Este navegador ou esta versão não é compatível com o Iapp. Por favor, use um dos navegadores: Chrome, Edge, Firefox ou Safari com versão atualizada</span>
        </div>
    )
}

export default InvalidBrowser;