export default function formatDate(date) {
  let data = new Date(date),
    dia = data.getDate().toString(),
    diaF = dia.length == 1 ? "0" + dia : dia,
    mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
    mesF = mes.length == 1 ? "0" + mes : mes,
    anoF = data.getFullYear();
  
  if (isNaN(data.getDate())) return date;

  return diaF + "/" + mesF + "/" + anoF;
}
