import React, { Component } from "react";

class Timer extends Component {
  segundo = 1000;

  state = {
    time: 0,
    isOn: false,
    start: 0
  };

  componentDidMount = () => {
    this.setState({
      time: this.props.tempo,
      isOn: true,
      start: this.props.tempo
    });
    this.startTimer();
  };

  pararTimer = () => {
    clearInterval(this.timer);
    this.props.tempoParado();
  };

  tempoAcabou = () => {
    if (this.props.onFinished) this.pararTimer();
    else clearInterval(this.timer);
  };

  startTimer = () => {
    this.timer = setInterval(this.updateTimer, this.segundo);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.intervalResetRequest !== false) {
      
      clearInterval(this.timer);
      this.startTimer();
      this.props.intervalResetFeedback();
      return false;
    }
    return null;
  }

  resetTimer = () => {
    this.setState({ time: this.props.tempo });
  };

  updateTimer = () => {
    // console.log('timer: ', this.state.timer)
    // Checar se é pra parar timer
    if (this.props.pararTimer) this.pararTimer();
    else {
      // Checar se é pra resetar
      if (this.props.resetar) {
        this.setState({
          time: this.props.tempo
        });
        this.props.resetFeedback();
      } else {
        // Checar sentido
        if (this.props.crescente) {
          this.setState(previousState => ({
            time: previousState.time + this.segundo
          }));
        } else {
          // Checar se acabou
          if (this.props.tempo <= 0) {
            // Checar se tem reset automatico
            if (this.props.resetAutomatico) {
              this.setState({
                time: this.props.tempo
              });
            } else {
              this.tempoAcabou();
            }
          } else {

            this.setState({
              time: this.props.tempo - this.segundo
            });
            }
        }
      }
      // Atualizando o tempo para parent
      this.props.tempoAtualizado(this.state.time);
    }
  };

  format = time => {
    // Pad to 2 or 3 digits, default is 2
    function pad(n, z) {
      z = z || 2;
      return ("00" + n).slice(-z);
    }
    var ms = time % 1000;
    time = (time - ms) / 1000;
    var secs = time % 60;
    time = (time - secs) / 60;
    var mins = time % 60;
    var hrs = (time - mins) / 60;

    return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
  };

  render() {
    return this.format(this.state.time);
  }
}
export default Timer;
