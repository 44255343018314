import React, { useState, useEffect } from 'react';
import {
  AppFooter,
} from "@coreui/react";
import styled from 'styled-components'
import NewCampoNome from "../../Pages/Assets/NewCampoNome";
import Input from '../ResultsConfig/components/Input/Input.jsx'
import { Row, Col } from "reactstrap";
import ResultsTable from "../ResultsConfig/components/ResultsTable/ResultsTable.jsx";
import { ButtonSave, Base } from '../../Pages/Assets/Assets'
import Footer from '../../../views/Participante/components/Footer';
import { Barra } from "../../../views/Pages/Resultados/Graficos/Barra";
import Loading from "../../../Services/Loading";

import { getCalculatedResult, getParticipants } from '../ResultsConfig/services';
import getResultData from './services/getResult';
import changeName from './services/changeName';

const Container = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

function ViewResults(props) {
  const [nome, setNome] = useState('');
  const [canChangeName, setCanChangeName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState('');
  const [projectId, setProjectId] = useState('');
  const [id, setId] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [usersByRanking, setUsersByRanking] = useState([]);
  const [valores, setValores] = useState([]);
  const [participantsResults, setParticipantsResults] = useState([]);

  async function getModuleData(projectId, moduleQuery, idQuery) {
    const moduleLowered = moduleQuery.toLowerCase();


    const obj = {
      moduleName: '',
      data: {}
    }

    const modules = {
      assíncronos: {
        getResult: async function () {
          const res = await getParticipants(projectId);
          const participantsIds = res.data.users.map(p => p._id);
          const modules = {
            asyncs: [{ _id: idQuery }]
          }
          const result = await getCalculatedResult(projectId, modules, participantsIds);

          if (result && result.data) {
            obj.moduleName = new URLSearchParams(props.location.search).get("moduleName");
            obj.data = result.data;
          }
        }
      },
      provas: {
        getResult: async function () {
          const res = await getParticipants(projectId);
          const participantsIds = res.data.users.map(p => p._id);
          const modules = {
            proofs: [{ _id: idQuery }]
          }
          const result = await getCalculatedResult(projectId, modules, participantsIds);

          if (result && result.data) {
            obj.moduleName = new URLSearchParams(props.location.search).get("moduleName");
            obj.data = result.data;
          }
        }
      },
      dinâmicas: {
        getResult: async function () {
          const res = await getParticipants(projectId);
          const participantsIds = res.data.users.map(p => p._id);
          const modules = {
            dynamics: [{ _id: idQuery }]
          }
          const result = await getCalculatedResult(projectId, modules, participantsIds);

          if (result && result.data) {
            obj.moduleName = new URLSearchParams(props.location.search).get("moduleName");
            obj.data = result.data;
          }
        }
      },
      assessments: {
        getResult: async function () {
          const res = await getParticipants(projectId);
          const participantsIds = res.data.users.map(p => p._id);
          const modules = {
            assessments: [{ _id: idQuery }]
          }
          const result = await getCalculatedResult(projectId, modules, participantsIds);

          if (result && result.data) {
            obj.moduleName = new URLSearchParams(props.location.search).get("moduleName");
            obj.data = result.data;
          }
        }
      },
      resultados: {
        getResult: async function () {
          const result = await getResultData(idQuery);

          if (result && result.data) {
            obj.moduleName = result.data.name;
            obj.data = result.data;
          }
        }
      }
    }

    await modules[moduleLowered].getResult(projectId, idQuery);

    return obj;
  }

  function getChart(data) {
    const participants = data.participants || data.participantes || [];
    const todasPontuacoes = participants.map(participant => {
      return participant.finalTotal
    });

    const conjuntoDePontuacoes = Array.from(
      new Set(
        todasPontuacoes
          .sort((a, b) => {
            if (a === b) {
              return 0;
            }
            return a < b ? -1 : 1;
          })
      )
    );

    const quantidades = conjuntoDePontuacoes.map((pont) =>
      todasPontuacoes.reduce(
        (acc, curr) =>
          Math.floor(curr) === Math.floor(pont) ? acc + 1 : acc,
        0
      )
    );

    function range(from, to, step) {
      let list = [];
      for (let i = from; i < to; i += step | 1) {
        list.push(i);
      }
      return list;
    }

    const usersByRanking = range(0, 101, 1).map(pont => {
      const usersByRk = todasPontuacoes.filter(
        rank => Math.floor(rank) === pont,
      );
      if (usersByRk.length) {
        return usersByRk;
      }
      return [];
    });

    setCategorias(conjuntoDePontuacoes.map(p => p.toString()));
    setValores(quantidades);
    setUsersByRanking(usersByRanking);
  }

  useEffect(()=>{
    async function loadModule() {
      try {
        setLoading(true);
        const moduleQuery = new URLSearchParams(props.location.search).get("module");
        const idQuery = new URLSearchParams(props.location.search).get("id");
        const projectIdQuery = new URLSearchParams(props.location.search).get("projectId");

        const isResultsModule = moduleQuery && moduleQuery.toLowerCase().includes('result');

        const moduleData = await getModuleData(projectIdQuery, moduleQuery, idQuery);

        getChart(moduleData.data);

        const participantsData = moduleData.data.participants || moduleData.data.participantes || [];


        setNome(moduleData.moduleName);
        setParticipantsResults(participantsData);
        setModule(moduleQuery);
        setId(idQuery);
        setCanChangeName(isResultsModule);
        setProjectId(projectIdQuery);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false)
      }
    }

    loadModule();
  },[]);


  if (loading) return <div style={{ width: '100%', height: '100vh', margin: '0 auto' }}><Loading /></div>;

  return (
    <>
      <Base className='app' style={{background: '#e4e5e6' }}>
        <Container>
          <div style={{ width: '80%' }}>

            <Row>
              <p style={{ fontWeight: "bold", fontSize: 18 }}>
                Visualização do resultado
              </p>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Input style={{ marginLeft: 0 }} label={'Nome:'} changeInput={changeName} value={nome} disabled={true} moduleId={id} />
            </Row>
            <Row>
              <Barra
              valores={valores}
              users={[]}
              categorias={categorias}
              usersByRanking={usersByRanking}
              />
            </Row>
            <Row style={{ marginTop: 40 }}>
              <div style={{ background: '#fff', width: '100%', padding: 10, }}>
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>Ranking Geral</span>
                <ResultsTable participantes={participantsResults} disableInputs={true} />
              </div>
            </Row>
            <Row style={{ flexDirection: 'row-reverse' }}>
              <ButtonSave style={{ paddingLeft: 30, paddingRight: 30, marginTop: 20 }} onClick={() => window.close()}>Fechar</ButtonSave>
            </Row>
          </div>

        </Container>


      </Base>
      <Footer />
    </>
  );
}

export default ViewResults