import axios from "axios";

export default async function getReport(results, resultName) {
  try {
    return await axios.post(`${process.env.REACT_APP_RESULTS_API_URL}/results-api/get-results-report`, {
      results,
      resultName
    }, { responseType: 'arraybuffer' })
  } catch (err) {
    console.error(err);
  }
}
