import React from "react";
import Loadable from "react-loadable";

import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <div>Carregando...</div>;
}

const ParticipanteDashboard = Loadable({
  loader: () => import("./views/Participante/Dashboard/Dashboard"),
  loading: Loading
});
const ParticipanteProjeto = Loadable({
  loader: () => import("./views/Participante/Projeto/Projeto"),
  loading: Loading
});

const Dashboard = Loadable({
  loader: () => import("./views/Pages/Dashboard"),
  loading: Loading
});

const Graficos = Loadable({
  loader: () => import("./views/Pages/Resultados/Graficos"),
  loading: Loading
});

const Binarios = Loadable({
  loader: () => import("./views/Pages/Modulos/Dinamicas/DinamicaCriterios"),
  loading: Loading
});

const Calendar = Loadable({
  loader: () => import("./views/Pages/Calendar"),
  loading: Loading
});

const Clientes = Loadable({
  loader: () => import("./views/Pages/Clientes"),
  loading: Loading
});

const Projetos = Loadable({
  loader: () => import("./views/Pages/Projetos"),
  loading: Loading
});

const Participantes = Loadable({
  loader: () => import("./views/Pages/Participantes"),
  loading: Loading
});

const Projeto = Loadable({
  loader: () => import("./views/Pages/Projeto"),
  loading: Loading
});

const ProjetoResumo = Loadable({
  loader: () => import("./views/Pages/Projetos/ProjetoResumo"),
  loading: Loading
});

const Assincronos = Loadable({
  loader: () => import("./views/Pages/Modulos/Assincronos/Assincronos"),
  loading: Loading
});

const AssincronoConfigurar = Loadable({
  loader: () =>
    import("./views/Pages/Modulos/Assincronos/AssincronoConfigurar/index.jsx"),
  loading: Loading
});
const AssincronoAcompanhar = Loadable({
  loader: () =>
    import("./views/Pages/Modulos/Assincronos/AssincronoAcompanhar"),
  loading: Loading
});
const AssincronoResults = Loadable({
  loader: () =>
    import("./views/Pages/Modulos/Assincronos/AssincronoResults"),
  loading: Loading
});
const AssincronoParticipantes = Loadable({
  loader: () =>
    import("./views/Pages/Modulos/Assincronos/AssincronoParticipantes"),
  loading: Loading
});

const Dinamicas = Loadable({
  loader: () => import("./views/Pages/Modulos/Dinamicas/Dinamicas"),
  loading: Loading
});

const DinamicaConfigurar = Loadable({
  loader: () => import("./views/Pages/Modulos/Dinamicas/DinamicaConfigurar"),
  loading: Loading
});

const DinamicaParticipantes = Loadable({
  loader: () => import("./views/Pages/Modulos/Dinamicas/DinamicaParticipantes"),
  loading: Loading
});

const DinamicaRodadas = Loadable({
  loader: () => import("./views/Pages/Modulos/Dinamicas/DinamicaRodadas"),
  loading: Loading
});
const DinamicaGrupos = Loadable({
  loader: () => import("./views/Pages/Modulos/Dinamicas/DinamicaGrupos"),
  loading: Loading
});

const DinamicaAcompanhamento = Loadable({
  loader: () =>
    import("./views/Pages/Modulos/Dinamicas/Acompanhamento/Acompanhamento"),
  loading: Loading
});
const DinamicaResultados = Loadable({
  loader: () => import("./views/Resultados"),
  loading: Loading
});
const Relatorios = Loadable({
  loader: () => import("./views/Pages/Relatorios/Relatorios"),
  loading: Loading
});
const ConfigRelatorio = Loadable({
  loader: () => import("./views/Pages/Relatorios/Configura"),
  loading: Loading
});
const ParticipantsRelatorio = Loadable({
  loader: () => import("./views/Pages/Relatorios/Participantes"),
  loading: Loading
});
const RevisoresRelatorio = Loadable({
  loader: () => import("./views/Pages/Relatorios/Revisores"),
  loading: Loading
});

const Suporte = Loadable({
  loader: () => import("./views/Pages/Suporte"),
  loading: Loading
});

const Assessment = Loadable({
  loader: () => import("./views/Pages/Modulos/Assessment/Assessment"),
  loading: Loading
});
const AssessmentSessoes = Loadable({
  loader: () => import("./views/Pages/Modulos/Assessment/AssessmentSessoes"),
  loading: Loading
});
const NewAssessmentConfigurar = Loadable({
  loader: () => import("./views/Pages/Modulos/Assessment/NewAssessmentConfigurar"),
  loading: Loading
});
const AssessmentParticipantes = Loadable({
  loader: () =>
    import("./views/Pages/Modulos/Assessment/AssessmentParticipantes"),
  loading: Loading
});

const AssessmentAcompanhar = Loadable({
  loader: () =>
    import(
      "./views/Pages/Modulos/Assessment/Acompanhar/AssessmentAcompanhamento"
    ),
  loading: Loading
});

const AssessmentResultados = Loadable({
  loader: () =>
    import(
      "./views/Pages/Modulos/Assessment/Resultados"
    ),
  loading: Loading
});

const Provas = Loadable({
  loader: () => import("./views/Pages/Modulos/Provas/Router"),
  loading: Loading
});

const ResultadosProvas = Loadable({
  loader: () => import("./views/Pages/Resultados/ResultadosProvas"),
  loading: Loading
});

const ResultadosSincronos = Loadable({
  loader: () => import("./views/Pages/Resultados/ResultadosSincronos"),
  loading: Loading
});

const ConfigurarAsync = Loadable({
  loader: () => import("./views/Pages/ConfigurarAsync/ConfigurarAsync"),
  loading: Loading
});

const ReportCreate = Loadable({
  loader: () => import("views/Reports/Report/components/CreateReport"),
  loading: Loading
});

const ReportEdit = Loadable({
  loader: () => import("views/Reports/Report"),
  loading: Loading
});

const Mail = Loadable({
  loader: () => import("./views/Pages/Email/Inbox/Inbox"),
  loading: Loading
});

const EstiloDeComunicacao = Loadable({
  loader: () =>
    import("./views/Participante/Instrumentos/EstilosDeComunicacao"),
  loading: Loading
});

const AncoraDeCarreira = Loadable({
  loader: () => import("./views/Participante/Instrumentos/AncorasDaCarreira"),
  loading: Loading
});

const TiposDePersonalidade = Loadable({
  loader: () =>
    import("./views/Participante/Instrumentos/TiposDePersonalidade"),
  loading: Loading
});

const ResultadosAncora = Loadable({
  loader: () => import("./views/Participante/Resultados/AncorasDaCarreira"),
  loading: Loading
});

const ResultadosTiposDePersonalidade = Loadable({
  loader: () => import("./views/Participante/Resultados/TiposDePersonalidade"),
  loading: Loading
});

const ResultadosAutoconceitoNoTrabalho = Loadable({
  loader: () =>
    import("./views/Participante/Resultados/AutoconceitoNoTrabalho"),
  loading: Loading
});

const ResultadosArteDaArticulacao = Loadable({
  loader: () => import("./views/Participante/Resultados/ArteDaArticulacao"),
  loading: Loading
});

const ResultadosLocus = Loadable({
  loader: () => import("./views/Participante/Resultados/LocusDeControle"),
  loading: Loading
});

const ResultadosEstilosDeComunicacao = Loadable({
  loader: () => import("./views/Participante/Resultados/EstilosDeComunicacao"),
  loading: Loading
});

const ResultadosAspectosDeIdentidade = Loadable({
  loader: () => import("./views/Participante/Resultados/AspectosDeIdentidade"),
  loading: Loading
});

const ResultadosHVePA = Loadable({
  loader: () => import("./views/Participante/Resultados/HVePA"),
  loading: Loading
});

const ResultadosFeedback = Loadable({
  loader: () => import("./views/Participante/Resultados/Feedback"),
  loading: Loading
});

const ResultadosAutoEficacia = Loadable({
  loader: () => import("./views/Participante/Resultados/AutoEficacia"),
  loading: Loading
});

const ArteDaArticulacao = Loadable({
  loader: () => import("./views/Participante/Instrumentos/ArteDaArticulacao"),
  loading: Loading
});
const AspectosIdentidades = Loadable({
  loader: () => import("./views/Participante/Instrumentos/AspectosIdentidades"),
  loading: Loading
});
const TiposDeLideranca_assincrono = Loadable({
  loader: () => import("./views/Participante/Instrumentos/TiposDeLideranca_assincrono"),
  loading: Loading
});
const AutoconceitoNoTrabalho = Loadable({
  loader: () =>
    import("./views/Participante/Instrumentos/AutoconceitoNoTrabalho"),
  loading: Loading
});
const Autoeficacia = Loadable({
  loader: () => import("./views/Participante/Instrumentos/Autoeficacia"),
  loading: Loading
});
const HistoriaDeVida = Loadable({
  loader: () => import("./views/Participante/Instrumentos/HistoriaDeVida"),
  loading: Loading
});
const ColetaDeFeedback = Loadable({
  loader: () => import("./views/Participante/Instrumentos/ColetaDeFeedback"),
  loading: Loading
});
const Feedback = Loadable({
  loader: () => import("./views/Participante/Instrumentos/Feedback"),
  loading: Loading
});
const EscutaAtiva = Loadable({
  loader: () => import("./views/Participante/Instrumentos/EscutaAtiva"),
  loading: Loading
});
const LocusDeControle = Loadable({
  loader: () => import("./views/Participante/Instrumentos/LocusDeControle"),
  loading: Loading
});

const Hierarquias = Loadable({
  loader: () => import("./views/Pages/Hierarquias"),
  loading: Loading
})

const Projects = Loadable({
  loader: () => import("./views/Pages/Projects"),
  loading: Loading
})
const MeuCalendario = Loadable({
  loader: () => import("./views/Participante/Dashboard/components/Calendario"),
  loading: Loading
});

// Estilos de Liderança Assícrono
const EstiloDeLideranca = Loadable({
  loader: () =>
    import("./views/Participante/Instrumentos/EstilosDeLideranca"),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/participante/dashboard",
    exact: true,
    name: "Participante - Dashboard",
    component: ParticipanteDashboard
  },
  {
    path: "/participante/projeto",
    exact: true,
    name: "Participante - Projeto",
    component: ParticipanteProjeto
  },

  /* --- CONSULTOR --- */
  { path: "/", exact: true, name: "Home", component: DefaultLayout },
  { path: "/dashboard", name: "Dashboard", exact: true, component: Dashboard },

  /* CLIENTES */

  { path: "/clientes", exact: true, name: "Clientes", component: Clientes },

  /* PROJETOS */
  {
    path: "/clientes/projetos",
    exact: true,
    name: "Projetos",
    component: Projetos
  },

  /* PROJETO */
  {
    path: "/projeto",
    exact: true,
    name: "Projeto",
    component: Projeto
  },
  {
    path: "/clientes/projetos/resumo",
    exact: true,
    name: "Resumo do projeto",
    component: ProjetoResumo
  },
  {
    path: "/clientes/projetos/participantes",
    // exact: true,
    name: "Paricipantes",
    component: Participantes
  },
  {
    path: "/clientes/projetos/hierarquias",
    exact: true,
    name: "Hierarquias",
    component: Hierarquias
  },
  {
    path: "/clientes/projetos/configurar",
    exact: true,
    name: "Projetos",
    component: Projects
  },

  /* ASSINCRONOS */
  {
    path: "/clientes/projetos/assincronos",
    exact: true,
    name: "Assincronos",
    component: Assincronos
  },
  {
    path: "/clientes/projetos/assincronos/configurar",
    exact: true,
    name: "Assincrono - Configurar",
    component: AssincronoConfigurar
  },
  {
    path: "/clientes/projetos/assincronos/acompanhamento",
    exact: true,
    name: "Assincrono - Acompanhar",
    component: AssincronoAcompanhar
  },
  {
    path: "/clientes/projetos/assincronos/resultados",
    exact: true,
    name: "Assincrono - Resultados",
    component: AssincronoResults
  },
  {
    path: "/clientes/projetos/assincronos/acompanhar",
    exact: true,
    name: "Assincrono - Acompanhar",
    component: AssincronoAcompanhar
  },
  {
    path: "/clientes/projetos/assincronos/participantes",
    exact: true,
    name: "Assincrono - Participantes",
    component: AssincronoParticipantes
  },

  {
    path: "/participante/estilo-de-comunicacao",
    exact: true,
    name: "Estilos de comunicacao",
    component: EstiloDeComunicacao
  },
  {
    path: "/participante/ancoras-da-carreira",
    exact: true,
    name: "Ancora de carreira",
    component: AncoraDeCarreira
  },
  {
    path: "/participante/tipos-de-personalidade",
    exact: true,
    name: "Tipos de Personalidade",
    component: TiposDePersonalidade
  },
  {
    path: "/participante/resultados/ancoras-da-carreira",
    exact: true,
    name: "Ancora de carreira",
    component: ResultadosAncora
  },
  {
    path: "/participante/resultados/historia-de-vida",
    exact: true,
    name: "Resultado história de vida",
    component: ResultadosHVePA
  },
  {
    path: "/participante/resultados/projetos-atuais",
    exact: true,
    name: "Resultado projetos atuais",
    component: ResultadosHVePA
  },
  {
    path: "/participante/resultados/coleta-de-feedback",
    exact: true,
    name: "Resultado Coleta de Feedback",
    component: ResultadosFeedback
  },
  {
    path: "/participante/resultados/aspectos-de-identidade",
    exact: true,
    name: "Aspectos de identidade",
    component: ResultadosAspectosDeIdentidade
  },
  {
    path: "/participante/resultados/autoconceito-no-trabalho",
    exact: true,
    name: "Resultado autoconceito no trabalho",
    component: ResultadosAutoconceitoNoTrabalho
  },
  {
    path: "/participante/resultados/autoeficacia",
    exact: true,
    name: "Resultado autoeficácia",
    component: ResultadosAutoEficacia
  },
  {
    path: "/participante/resultados/estilo-de-comunicacao",
    exact: true,
    name: "Resultado estilos de comunicação",
    component: ResultadosEstilosDeComunicacao
  },
  {
    path: "/participante/resultados/arte-da-articulacao",
    exact: true,
    name: "Resultado arte da articulacao",
    component: ResultadosArteDaArticulacao
  },
  {
    path: "/participante/resultados/locus-de-controle",
    exact: true,
    name: "Resultado locus de controle",
    component: ResultadosLocus
  },
  {
    path: "/participante/resultados/tipos-de-personalidade",
    exact: true,
    name: "Resultado tipos de personalidade",
    component: ResultadosTiposDePersonalidade
  },
  {
    path: "/participante/arte-da-articulacao",
    exact: true,
    name: "Arte da articulação",
    component: ArteDaArticulacao
  },
  {
    path: "/participante/aspectos-de-identidade",
    exact: true,
    name: "Aspectos identidade",
    component: AspectosIdentidades
  },
  {
    path: "/participante/autoconceito-no-trabalho",
    exact: true,
    name: "Autoconceito no trabalho",
    component: AutoconceitoNoTrabalho
  },
  {
    path: "/participante/autoeficacia",
    exact: true,
    name: "Autoeficácia",
    component: Autoeficacia
  },
  {
    path: "/participante/historia-de-vida",
    exact: true,
    name: "História de vida",
    component: HistoriaDeVida
  },
  {
    path: "/participante/projetos-atuais",
    exact: true,
    name: "Projetos atuais",
    component: HistoriaDeVida
  },
  {
    path: "/participante/coleta-de-feedback",
    exact: true,
    name: "Coleta de feedback",
    component: ColetaDeFeedback
  },
  {
    path: "/participante/coleta-de-feedback",
    exact: true,
    name: "Feedback",
    component: Feedback
  },
  {
    path: "/participante/escuta-ativa",
    exact: true,
    name: "Escuta ativa",
    component: EscutaAtiva
  },
  {
    path: "/participante/locus-de-controle",
    exact: true,
    name: "Locus de controle",
    component: LocusDeControle
  },
  {
    path: "/participante/calendario",
    exact: true,
    name: "Meu calendário",
    component: MeuCalendario
  },
  {
    path: '/participante/estilos-de-lideranca---assincrono',
    exact: true,
    name: 'Tipos de liderança',
    component: TiposDeLideranca_assincrono,
  },

  // Estilos de Liderança Assíncrono

  /* PROVAS */
  {
    path: "/clientes/projetos/provas",
    name: "Provas",
    exact: false,
    component: Provas
  },

  /* BINARIOS */

  {
    path: "/clientes/projetos/criterios",
    exact: true,
    name: "Critérios",
    component: Binarios
  },

  /* DINAMICAS */
  {
    path: "/clientes/projetos/dinamicas",
    exact: true,
    name: "Dinâmicas",
    component: Dinamicas
  },

  {
    path: "/clientes/projetos/dinamicas/participantes",
    exact: true,
    name: "Dinâmica - Participantes",
    component: DinamicaParticipantes
  },
  {
    path: "/clientes/projetos/dinamicas/configurar",
    exact: true,
    name: "Dinâmica - Configurar",
    component: DinamicaConfigurar
  },
  {
    path: "/clientes/projetos/dinamicas/rodadas",
    exact: true,
    name: "Dinâmica - Rodadas",
    component: DinamicaRodadas
  },
  {
    path: "/clientes/projetos/dinamicas/grupos",
    name: "Dinâmica - Grupos",
    component: DinamicaGrupos
  },
  {
    path: "/clientes/projetos/dinamicas/acompanhamento",
    exact: true,
    name: "Dinâmica - Acompanhamento",
    component: DinamicaAcompanhamento
  },
  {
    path: "/clientes/projetos/dinamicas/acompanhar",
    exact: true,
    name: "Dinâmica - Acompanhar",
    component: DinamicaAcompanhamento
  },
  {
    path: "/clientes/projetos/dinamicas/resultados",
    exact: true,
    name: "Dinâmica - Acompanhar",
    component: DinamicaResultados
  },

  /* ASSESSMENT */
  {
    path: "/clientes/projetos/assessments",
    exact: true,
    name: "Assessment",
    moduleAuth: 'assessments',
    component: Assessment
  },
  {
    path: "/clientes/projetos/assessments/sessões",
    exact: true,
    name: "Assessment",
    moduleAuth: 'assessments',
    component: AssessmentSessoes
  },
  {
    path: "/clientes/projetos/assessments/configurar",
    exact: true,
    name: "Assessment",
    moduleAuth: 'assessments',
    component: NewAssessmentConfigurar
  },
  {
    path: "/clientes/projetos/assessments/participantes",
    exact: true,
    name: "Assessment",
    moduleAuth: 'assessments',
    component: AssessmentParticipantes
  },
  {
    path: "/clientes/projetos/assessments/acompanhar",
    exact: true,
    name: "Assessment",
    moduleAuth: 'assessments',
    component: AssessmentAcompanhar
  },
  {
    path: "/clientes/projetos/assessments/resultados",
    exact: true,
    name: "Assessment - Resultados",
    moduleAuth: 'assessments',
    component: AssessmentResultados
  },

  /* RESULTADOS */
  {
    path: "/clientes/projetos/resultados/sincronos",
    name: "Resultado - Síncrono",
    exact: true,
    component: ResultadosSincronos
  },
  {
    path: "/clientes/projetos/resultados/provas",
    name: "Resultado - Provas",
    exact: true,
    component: ResultadosProvas
  },
  {
    path: "/clientes/resultados/graficos",
    name: "Gráficos",
    exact: true,
    component: Graficos
  },
  {
    path: "/clientes/projetos/report",
    exact: true,
    name: "ReportCreate",
    component: ReportCreate
  },

  {
    path: "/clientes/projetos/report/:reportId",
    exact: true,
    name: "ReportEdit",
    component: ReportEdit
  },

  /* OUTROS */

  { path: "/calendar", exact: true, name: "Calendar", component: Calendar },

  {
    path: "/clientes/projetos/relatorios",
    exact: true,
    name: "Relatorios",
    component: Relatorios
  },
  {
    path: "/clientes/projetos/relatorios/configuracoes",
    name: "Configuração",
    component: ConfigRelatorio
  },
  {
    path: "/relatorios/participantes",
    name: "Participantes",
    component: ParticipantsRelatorio
  },
  {
    path: "/relatorios/revisores",
    name: "Revisores",
    component: RevisoresRelatorio
  },
  { path: "/suporte", name: "Suporte", component: Suporte },
  {
    path: "/projeto/async/instrumento",
    name: "Configurar Assíncronos",
    component: ConfigurarAsync
  },
  { path: "/mail", name: "Mensagens", component: Mail }
];

export default routes;
