import Chart from "chart.js";

const graphConfig = ({
	title
}) => ({
	type: 'radar',
	data: {},
	options: {
		
		tooltips: {
			enabled: true,
			callbacks: {
				title: (item, data) => {
					return '';
				},
				label: (item, data) => {
					const nota = data.datasets[item.datasetIndex].data[item.index];
					const avaliando = data.datasets[item.datasetIndex].label;
					const estilo = data.labels[item.index];
					return `${avaliando}: ${estilo}. Nota: ${nota}`;
				}
			}
		},

		plugins: {
			datalabels: {
				display: false,
			}
		},
		legend: {
			position: 'bottom',
		},
		title: {
			display: title ? true : false,
			text: title,
			color: '#23282c',
			font: {
				size: 12,
				weight: 'bold',
				family: 'Montserrat, sans-serif'
			},
		},
		scale: {
			ticks: {
				beginAtZero: true,
				max: 100,
				min: 0,
				stepSize: 10,
				backdropColor: 'rgba(0, 0, 0, 0)'
			}
		}
	},
});

function getData({ rodadasData }) {
  let data = [];

	data.labels = [
		'IP',
		'IR',
		'IS',
		'SP',
		'IC'
	];

	if (!rodadasData || !rodadasData.length) return {};

	let chartColors = [
		'#7f5fa9',
		'#ed5f60',
		'#face3a',
		'#8B8386',
		'#56c5d5',
		'#f17542',
		'#006400',
		'#B8860B',
		'#00FF7F',
		'#363636',

		'#cddd84',
		'#cdc5bb',
		'#a14443',
		'#FF1493',
		'#1c136c',
		'#CD6090',
		'#32CD32',
		'#FF6A6A',
		'#556B2F',
		'#FFFF00',
	];

	chartColors = chartColors.reverse();

	const color = Chart.helpers.color;
  let colorIndex = 20;
		
	const datasets = rodadasData.map((result) => {
		if (colorIndex === 0){
			colorIndex = 20;
		}

		colorIndex += -1;

		return {
			label: result.name,
			backgroundColor: color(chartColors[colorIndex]).alpha(0.2).rgbString(),
			borderColor: chartColors[colorIndex],
			pointBackgroundColor: chartColors[colorIndex] ,
			data: result.data
		}
	})

  data.datasets = datasets;

  return data;
}

export default {

  graphConfig,
  getData
}