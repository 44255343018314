import styled from "styled-components";

export const Table = styled.table`
  /* width: 100%; */
  font-size: 12px;
  /* overflow-x: auto; */

  ::-webkit-scrollbar {
    height: 8px !important;
  }

  tbody {
    background-color: #fff;
  }

  tr:nth-child(even) {
    background-color: ${props => props.striped ? '#f7f8f8' : '#fff'};
  }

  th {
    background-color: #f7f8f8;
    font-size: 12px;
    padding: 10px;
    border-bottom: 2px solid #e4e5e6;
  }

  td {
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid #e4e5e6;
    background-color: ${props => props.striped ? 'transparent' : '#fff'} ;

    vertical-align: middle;
  }

  .capitalized-text {
    text-transform: capitalize;
  }
`;
