import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
`;

const StyledLoading = styled.div`
  width: ${props => props.width || '50px'};
  height: ${props => props.height || '50px'};
  border: ${ props => props.innerBorderPx ||'5px' } solid #c1c1c1;
  border-top: ${ props => props.outterBorderPx ||'5px' } solid var(--blue);
  border-radius: 50%;
  animation: ${spinAnimation} 0.7s infinite linear;
`;

const Loading = ({ width, height, innerBorderPx, outterBorderPx, wrapperStyle }) => {
  return (
    <LoadingWrapper style={wrapperStyle}>
      <StyledLoading width={width} height={height} innerBorderPx={innerBorderPx} outterBorderPx={outterBorderPx} />
    </LoadingWrapper>
  );
};

export default Loading;
