function orderHierarchies({ hierarchies, currentCargo }) {
  let finalArray = [];
  let current = hierarchies.find(h => (
    h.cargo.toLowerCase().trim() === currentCargo.toLowerCase().trim()
  ));
  finalArray.push(current);

  do {
      current = hierarchies.find(h => h._id === current.superiorImediato);
      if (!current) break;
      finalArray.push(current);
  } while (current);

  return finalArray;
}

export default orderHierarchies;