export const headerBackgroundImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/header-bg.png'

export const estiloComunicacaoFavoravelImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/comunicacao_favoravel.png'

export const estiloComunicacaoStressImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/comunicacao_estresse.png'

export const tiposPersonalidadeImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/tipos_personalidade.png'

export const estilosLiderancaRadarImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/estilos_chart1.png'

export const estilosLiderancaEficaciaImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/estilos_chart2.png'

export const estilosLiderancaVelocimetroImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/estilos_chart3.png'

export const assessmentPotencialImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/potencial.png'

export const assessmentProntidaoImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/prontidao.png'

export const assessmentGeralImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/potencial_prontidao.png'

export const dinamicaRankingGeralGraficoImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/rk_dinamica.png'

export const dinamicaRankingGeralTabelaImageUrl = 'https://files-storage.s3.amazonaws.com/iapp-img/rk_geral.png'
