import React from 'react';
import {
  Row,
  Col,
} from "reactstrap";
import apiCalls from "config/apiCalls";
import _ from 'lodash';

import * as ResultadoProvasService from './services';
import Grafico from 'views/Pages/Resultados/ModalResultados/ResultadosDinamica/Case/Grafico'
import { notifyError } from 'Services/Notificacoes';
import Loading from 'Services/Loading';
import NoResultsFound from 'views/Pages/Resultados/ModalResultados/ResultadoAssincronos/Components/NoResultsFound.js';
import DefaultTitle from 'components/default-title';
import WhatsBeingMeasured from 'components/whats-being-measured/WhatsBeingMeasured';
import ProofGraph from 'views/Pages/Assets/ModalResultado/Resultados/components/ProofGraph';

const FeedbackText = ({ text, rp, acertos, notaMercado, normalizado }) => (
  <>
    <Row>
      <Col>
        <p>
          <DefaultTitle>Resultado</DefaultTitle>
        </p>
      </Col>
    </Row>
    <Row>
      <Col style={{
        marginTop: '20px',
        fontSize: '12px',
        marginBottom: 15
      }}>
        { _.isNumber(Number(notaMercado)) && _.isNumber(acertos) && _.isNumber(normalizado) ?
        <>
          <span>Nota referente ao Grupo: {normalizado !== 'S/V' ? Number(normalizado).toFixed(2).replace('.', ',') : normalizado}</span><br/>
          <span>Nota referente ao Mercado: {notaMercado !== 'S/V' ? Number(notaMercado).toFixed(2).replace('.', ',') : notaMercado}</span><br/>
          <span>Acertos: {acertos}</span> <br /><br/>
        </> : null }
        <p dangerouslySetInnerHTML={{
          __html: text
        }}>
        </p>
      </Col>
    </Row>
  </>
)


class ResultadosDinamica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackText: '',
      normalizedTotal: null,
      loading: false,
      whatsBeingMeasuredText: '',
      acertos: null,
      rp: null,
      notaMercado: null,
      normalizado: null,
    }
  }

  handleError(err, msg) {
    if (!msg) return console.error({err});
    console.error(err);
    notifyError(msg);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const proofResults = await apiCalls.provas.getProofResults({
      provaId: this.props.idProva,
      idProjeto: this.props.idProjeto,
      provasIds: [this.props.idProva]
    });
    if (proofResults && proofResults.data) {
      const { ranking } = proofResults.data;
      const participantRk = ranking.find(r => r.id === this.props.idParticipante);
      if (participantRk) this.setState({ acertos: participantRk.acertos, rp: participantRk.pontuacao, notaMercado: participantRk.notaMercado, normalizado: participantRk.normalizado })
    }
    ResultadoProvasService.loadFeedbackText({
      provaId: this.props.idProva,
      userId: this.props.idParticipante
    })
      .then(({ feedbackText, normalizedTotal, whatsBeingMeasuredText }) => this.setState({ feedbackText, normalizedTotal, whatsBeingMeasuredText }))
      .catch(err => this.handleError(err))
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { feedbackText, normalizedTotal, loading } = this.state;

    if (loading) return  <Loading />;

    return (
      <div>
        {
          feedbackText && (normalizedTotal !== undefined) && feedbackText.length ? (
            <>
              <Row>
                <Col>
                  <ProofGraph
                    participantId={this.props.idParticipante}
                    projectId={this.props.idProjeto}
                    proofId={this.props.idProva}
                    id={`proof-graph-${this.props.idProjeto}-${this.props.idProva}`}
                  />
                </Col>
              </Row>
              <br />
              <WhatsBeingMeasured text={this.state.whatsBeingMeasuredText} />
              <br />
              <FeedbackText normalizado={this.state.normalizado} notaMercado={this.state.notaMercado} acertos={this.state.acertos} rp={this.state.rp} text={this.state.feedbackText} />
            </>
          )
            :
            <NoResultsFound />
        }
      </div>
    )
  }
}

export default ResultadosDinamica;