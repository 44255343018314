import getCoordinates from './get-coordinates-2';

export function findCoordinates({
  name,
  pointX,
  pointY,
  datasets
}) {
  return getCoordinates({
    name,
    pointX,
    pointY,
    datasets
  });
}